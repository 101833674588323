import React from "react";

export default function CloseSvgIcon(Props) {
  return Props.component === "commentsEdit" ? (
    <svg
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
      onClick={Props.onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#F04438"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M23 13L13 23M13 13L23 23"
      />
    </svg>
  ) : Props.component === "codingOutput" ? (
    <svg
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_637_10342)">
        <path
          fill="#F04438"
          d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
        />
        <path
          stroke="white"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M7.5 4.5L4.5 7.5M4.5 4.5L7.5 7.5M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_637_10342">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      fill="none"
      onClick={Props.onClick}
      xmlns="http://www.w3.org/2000/svg"
      width={
        Props.component === "comments" || Props.component === "score"
          ? "12"
          : "16"
      }
      height={
        Props.component === "comments" || Props.component === "score"
          ? "12"
          : "16"
      }
      viewBox={
        Props.component === "comments" || Props.component === "score"
          ? "0 0 12 12"
          : "0 0 16 16"
      }
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width={
          Props.component === "comments" || Props.component === "score"
            ? "2"
            : "1.33"
        }
        stroke={
          Props.component === "comments" || Props.component === "score"
            ? "#344054"
            : "#667085"
        }
        d={
          Props.component === "comments" || Props.component === "score"
            ? "M11 1L1 11M1 1L11 11"
            : "M12 4L4 12M4 4L12 12"
        }
      />
    </svg>
  );
}
