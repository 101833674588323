import React, { useContext, useEffect } from "react";
import _ from "lodash";
import FormTitle from "./FormTitle";
import InputGroupField from "../../CommonComponents/InputGroupField";
import Button from "../../Button";
import { ProfileContext } from "../../../context/ProfileContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import constants from "../../../constants";
import AlertContext from "../../../context/AlertContext";
import ProfilesData from "./profiles.json";

const SocialProfiles = ({ role, from, learnerId }) => {
  const axios = useAxiosPrivate();
  const { profileDetails, setProfileDetails, navigate, onSaveProfileDetails } =
    useContext(ProfileContext);
  const adminProfiles = ["github", "linkedin"];
  const { setIsLoaded, isAdmin, setShowNotify } = useContext(AlertContext);

  const Profiles =
    isAdmin && from !== "onpopup"
      ? _.filter(ProfilesData, (profile) =>
          adminProfiles.includes(profile.title.toLowerCase())
        )
      : ProfilesData;

  useEffect(() => {
    // const abortController = new AbortController();
    const handleGetSocialProfile = async () => {
      try {
        setIsLoaded(true);
        let response = await axios.get(
          `node/learner/profile/${learnerId}/socialprofile`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            // signal: abortController.signal,
          }
        );
        console.log("response", response);
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          let details = response.data.data.result;
          if (details && details !== null) {
            setProfileDetails((prev) => ({
              ...prev,
              socialprofile: JSON.parse(details),
            }));
          }
        } else
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
      } catch (error) {
        console.error("error in get social profiles", error);
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
        }
      } finally {
        setIsLoaded(false);
      }
    };
    handleGetSocialProfile();
    // return () => abortController.abort();
  }, [axios, setIsLoaded, setShowNotify, setProfileDetails, learnerId]);

  const handleFinish = async () => {
    onSaveProfileDetails(true);
  };

  const handlePrevious = () => {
    if (role === constants.Roles.learner) {
      navigate("/profile/academic");
    } else {
      navigate("/profile/personalinfo");
    }
  };

  return (
    <React.Fragment>
      <FormTitle
        icon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 12L11 14L15.5 9.5M7.33377 3.8187C8.1376 3.75455 8.90071 3.43846 9.51447 2.91542C10.9467 1.69486 13.0533 1.69486 14.4855 2.91542C15.0993 3.43846 15.8624 3.75455 16.6662 3.8187C18.5421 3.96839 20.0316 5.45794 20.1813 7.33377C20.2455 8.1376 20.5615 8.90071 21.0846 9.51447C22.3051 10.9467 22.3051 13.0533 21.0846 14.4855C20.5615 15.0993 20.2455 15.8624 20.1813 16.6662C20.0316 18.5421 18.5421 20.0316 16.6662 20.1813C15.8624 20.2455 15.0993 20.5615 14.4855 21.0846C13.0533 22.3051 10.9467 22.3051 9.51447 21.0846C8.90071 20.5615 8.1376 20.2455 7.33377 20.1813C5.45794 20.0316 3.96839 18.5421 3.8187 16.6662C3.75455 15.8624 3.43846 15.0993 2.91542 14.4855C1.69486 13.0533 1.69486 10.9467 2.91542 9.51447C3.43846 8.90071 3.75455 8.1376 3.8187 7.33377C3.96839 5.45794 5.45794 3.96839 7.33377 3.8187Z"
              stroke="#101828"
              strokeWidth="1.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        title="Social Profiles"
      />
      <div className="socialProfilesContainer">
        <div className="socialProfilesSec">
          {_.map(Profiles, (profile, index) => (
            <InputGroupField
              disabled={from === "onpopup"}
              key={index}
              title={profile.title}
              titleIcon={profile.titleIcon}
              groupText="https://"
              placeholder={from !== "onpopup" ? "www.gradious.com" : ""}
              value={profileDetails.socialprofile[profile.name]}
              onChange={(event) =>
                setProfileDetails((prev) => ({
                  ...prev,
                  socialprofile: {
                    ...prev.socialprofile,
                    [event.target.name]: event.target.value,
                  },
                }))
              }
              name={profile.name}
            />
          ))}
        </div>
        {from !== "onpopup" && (
          <div className="socialProfilesBtnSec">
            <Button
              onClick={handlePrevious}
              size="sm"
              hierarchy={{ type: "secondaryGrey", buttonText: "Previous" }}
            />
            <Button
              onClick={handleFinish}
              size="sm"
              hierarchy={{ type: "primary", buttonText: "Finish" }}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SocialProfiles;
