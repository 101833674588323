import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { AlertProvider } from "./context/AlertContext";
import { registerServiceWorker } from "./api/serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// import 'bootstrap/dist/css/bootstrap.min.css';

// replace console.* for disable log on production

// if (process.env.NODE_ENV === 'production') {
//   console.log = () => {}
//   console.error = () => {}
//   console.debug = () => {}
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    ></link>
    <AlertProvider>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </AlertProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

registerServiceWorker();
reportWebVitals();
