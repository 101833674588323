import React from "react";

export default function ArrowSvg(Props) {
  return (
    <svg
      width={Props.height? Props.height :"17"}
      height={Props.width? Props.width :"26"}
      viewBox="0 0 17 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={Props.handleFunction}
    >
      <path
        d="M0.5 3.08917L10.3893 13.0001L0.5 22.911L3.54453 25.9556L16.5 13.0001L3.54453 0.0446358L0.5 3.08917Z"
        fill={Props.pathfill? Props.pathfill :"#6267A1"}
      />
    </svg>
  );
}
