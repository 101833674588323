import { nanoid } from "nanoid";
import constants from "../constants";
import useLogout from "../Hooks/useLogout";
import { ZoomMtg } from "@zoom/meetingsdk";
import { getDataFromStorage } from "../util";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../Hooks/useAxiosPrivate";
import { createContext, useRef, useState } from "react";
import ModuleHooks from "../Hooks/ModuleHooks";

const AlertContext = createContext({});

export const AlertProvider = ({ children }) => {
  const { handlePopupStates, clearPopupStates } = ModuleHooks();
  const logout = useLogout();
  const navigate = useNavigate();
  const axios = useAxiosPrivate();

  let register = useRef(1);
  let filccld = useRef([]);

  const countPicture = useRef(0);
  const webCamRef = useRef(null);
  const numberOfPicsToTake = useRef(0);

  const [userProfilePic, setUserProfilePic] = useState(
    localStorage.getItem("picture")
  );
  const [batchGridView, setBatchGridView] = useState(true);
  const [leaderboardTestDetail, setLeaderBoardTestDetail] = useState();
  // const [sectionWithQuestions, setSectionWithQuestions] = useState([]);
  const [lpGridView, setLpGridView] = useState(true);
  const assignmentSubmissionFiles = useRef([]);
  const [startVideo, setStartVideo] = useState(false);
  const [svg, setSvg] = useState("");
  const [msg, setMsg] = useState("");
  const [qnNo, setQnNo] = useState(0);
  const [lpId, setLpId] = useState(0);
  const [auth, setAuth] = useState({});
  const [libId, setLibId] = useState(0);
  const [topic, setTopic] = useState({});
  const [title, setTitle] = useState("");
  const [lists, setLists] = useState([]);
  const [testId, setTestId] = useState(0);
  const [show, setShow] = useState(false);
  const [module, setModule] = useState({});
  const [libType, setLibType] = useState();
  const [status, setStatus] = useState("");
  const [lpData, setLpData] = useState({});
  const [state, setState] = useState(false);
  const [batchId, setBatchId] = useState(0);
  const [show1, setShow1] = useState(false);
  const [profile, setProfile] = useState([]);
  const [comment, setComment] = useState("");
  const [btnText, setBtnText] = useState("");
  const [btnText2, setBtnText2] = useState("");
  const [testData, setTestData] = useState({});
  const [isScore, setIsScore] = useState(false);
  const [navhome, setNavhome] = useState(false);
  const [topicList, setTopicList] = useState([]);
  const [assignQns, setAssignQns] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [showMain, setShowMain] = useState(false);
  const [isUnAuth, setIsUnAuth] = useState(false);
  const [resultCode, setResultCode] = useState(0);
  const [lpConfigId, setLpConfigId] = useState(0);
  const [content2, setContent2] = useState(false);
  const [rejectData, setRejectData] = useState({});
  const [reportdata, setreportdata] = useState({});
  const [isComment, setIsComment] = useState(false);
  const [isFixedQn, setIsFixedQn] = useState(false);
  const [approveData, setApproveData] = useState({});
  const [contentData, setContentData] = useState({});
  const [learnername, setLearnerName] = useState("");
  const [fromReport, setFromReport] = useState(false);
  const [isTakeTest, setIsTakeTest] = useState(false);
  const [lpmtopicqnno, setlpmtopicqnno] = useState(0);
  const [testActive, setTestActive] = useState(false);
  const [learnerNotes, setLearnerNotes] = useState("");
  const [currentBatch, setCurrentBatch] = useState({});
  const [showPrevBtn, setShowPrevBtn] = useState(false);
  const [showNextBtn, setShowNextBtn] = useState(false);
  const [Loginstatus, setLoginStatus] = useState(false);
  const [reportfilters, setreportfilters] = useState([]);
  const [testReqDetail, setTestReqDetail] = useState({});
  const [reportColumns, setReportColumns] = useState([]);
  const [camAvailable, setCamAvailable] = useState(true);
  const [curaccessor, setcuraccessor] = useState("hello");
  const [moduleActive, setModuleActive] = useState(false);
  const [downloadWidget, setDownloadWidget] = useState(0);
  const [codingTestData, setCodingTestData] = useState({});
  const [isstableState, setIsStableState] = useState(true);
  const [sessionDetails, setSessionDetails] = useState({});
  const [updateLeftNav, setUpdateLeftNav] = useState(false);
  const [showSaqLaqAns, setShowSaqLaqAns] = useState(false);
  const [submissionFiles, setSubmissionFiles] = useState([]);
  const [captureBtnHide, setCaptureBtnHide] = useState(true);
  const [registerNotify, setRegisterNotify] = useState(false);
  const [fromCodeEditor, setFromCodeEditor] = useState(false);
  const [isFullScreensp, setIsFullScreensp] = useState(false);
  const [instructionDetail, setInstructionPage] = useState({});
  const [showNotify, setShowNotify] = useState({ show: false });
  const [showInstruction, setShowInstruction] = useState(false);
  const [isAddSubmission, setIsAddSubmission] = useState(false);
  const [breadCrumbsOption, setBreadCrumbsOption] = useState([]);
  const [isEditAttendance, setIsEditAttendance] = useState(false);
  const [isEditSubmission, setIsEditSubmission] = useState(false);
  const [assignmentTestData, setAssignmentTestData] = useState({});
  const [takeMcqAndDesTest, setTakeMcqAndDesTest] = useState(false);
  const [changeHeaderValue, setChangeHeaderValue] = useState(false);
  const [showConfirmNotify, setShowConfirmNotify] = useState(false);
  const [notificationPayload, setNotificationPayload] = useState({});
  const [stablePageforBackbtn, setStablePageforBackbtn] = useState(1);
  const [stableTextforBackbtn, setStableTextforBackbtn] = useState("");
  const [selectedLearningPath, setSelectedLearningPath] = useState({});
  const [stableStateforBackbtn, setStableStateforBackbtn] = useState("");
  const [prevreportfiltertypes, setprevreportfiltertypes] = useState([]);
  const [currentEditDashboardMetaData, setCurrentEditDashboardMetaData] =
    useState({});
  const [isDashBoardEdit, setIsDashBoardEdit] = useState(false);
  const [batchNames, setBatchNames] = useState([]);

  const [ learnerVideoLink,setLearnerVideoLink] = useState(null);

  const [topicId, setTopicId] = useState("");

  // Button

  const [hierarchy, setHierarchy] = useState({});
  const [buttonSize, setButtonSize] = useState("");
  const [buttonIcon, setButtonIcon] = useState({});
  const [destructive, setDestructive] = useState(false);
  const [buttonFunction, setButtonFunction] = useState();
  const [buttonDisable, setButtonDisable] = useState(false);

  // content

  const [selectque, setselectque] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isqueReport, setisqueReport] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [referenceLinks, setReferenceLinks] = useState([]);
  const [selectedLibType, setSelectedLibType] = useState(0);
  const [tableShowStatus, setTableShowStatus] = useState(0);

  // content end

  //coding

  const testCasesRef = useRef([]);
  const tabSwitchCount = useRef(0);
  const solutionLanguageId = useRef(0);
  const isInputVariable = useRef(false);
  const isLearnerQuitQns = useRef(false);
  const callAllTestCases = useRef(false);
  const isAdminSubmitTimeout = useRef(false);

  let [breadCrumbsData, setBreadCrumbsData] = useState([]);

  const [score, setScore] = useState(0);
  const [count, setCount] = useState(0);
  const [output, setOutput] = useState("");
  const [theme, setTheme] = useState(false);
  const [language, setLanguage] = useState([]);
  const [tsCoBkup, setTsCoBkup] = useState({});
  const [timeLimit, setTimeLimit] = useState(0);
  const [learnerId, setLearnerId] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [learnerCode, setLearnerCode] = useState("");
  const [codeDetails, setCodeDetails] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [showReject, setShowReject] = useState(false);
  const [commentsData, setCommentsData] = useState([]);
  const [showApprove, setShowApprove] = useState(false);
  const [inputVariable, setInputVariable] = useState("");
  const [showComments, setShowComments] = useState(false);
  const [editorTheme, setEditorTheme] = useState("tomorrow");
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [isShowTimeAlert, setIsShowTimeAlert] = useState(false);
  const [makeRunTestCase, setMakeRunTestCase] = useState(false);
  const [commentAttachment, setCommentAttachment] = useState([]);
  const [runTestResultCode, setRunTestResultCode] = useState([]);
  const [outputWindowHeight, setOutputWindowHeight] = useState(8);
  const [showBreadCrumbsData, setShowBreadCrumbsData] = useState({});
  const [problemWindowHeight, setProblemWindowHeight] = useState(25);

  //coding end

  // Mcq

  const duration = useRef();
  const interval = useRef();
  const hasRendered = useRef(false);
  const backupTestDataq = useRef({});

  const [ansStats, setAnsStats] = useState({});
  const [testStats, setTestStats] = useState({});
  const [descScores, setDescScores] = useState({});
  const [apiLoaded, setApiLoaded] = useState(false);
  const [totalSecScore, setTotalSecScore] = useState(0);
  const [totalTestScore, setTotalTestScore] = useState(0);
  const [backupTestData, setBackupTestData] = useState({});
  const [totalLearnerScore, setTotalLearnerScore] = useState(0);

  const [queInd, setQueInd] = useState(0);
  const [secInd, setSecInd] = useState(0);
  const [answers, setAnswers] = useState({});
  const [testMode, setTestMode] = useState(0);
  const [action, setAction] = useState(false);
  let [comingObj, updateComingObj] = useState({});
  const [mcqTestData, setMcqTestData] = useState({});
  const [isTestData, setIsTestData] = useState(false);
  const [prevButton, setPrevButton] = useState(false);
  const [nextButton, setNextButton] = useState(false);
  const [testPageShow, setTestPageShow] = useState(false);
  const [filterQuestionCount, setFilterQuestionCount] = useState(null);
  const [currentDashboardMetaData, setCurrentDashboardMetaData] = useState({});

  const [questionContainerSingle, updateQuestionContainerSingle] =
    useState(false);
  const [queLen, setQueLen] = useState(
    comingObj.hasOwnProperty("sections")
      ? comingObj.sections[secInd].questions.length
      : null
  );
  const [secLen, setSecLen] = useState(
    comingObj.hasOwnProperty("sections") ? comingObj.sections.length : null
  );
  const [secObj, setSecObj] = useState(
    comingObj.hasOwnProperty("sections") ? comingObj.sections[secInd] : null
  );
  const [queObj, setQueObj] = useState(
    comingObj.hasOwnProperty("sections")
      ? comingObj.sections[secInd].questions[queInd]
      : null
  );
  const [optObj, setOptObj] = useState(
    status !== constants.QUESTION_STATUS.SUBMITTED
      ? comingObj.hasOwnProperty("sections") &&
        filterQuestionCount != null &&
        filterQuestionCount > 0
        ? comingObj.sections[secInd].questions[queInd]?.options
        : null
      : null
  );

  // MCQ End

  /* changing learner */
  const [changelearner, setChangeLearner] = useState(false);

  /* moved from app.js */
  const [seconds, setSeconds] = useState(null);
  const [moduleList, setModuleList] = useState([]);
  const [isFirstLoggedIn, setIsFirstLoggedIn] = useState(false);

  /* state for dashboard */
  const [dashBoardDetails, setDashBoardDetails] = useState([]);
  const [leaderBoardData, setLeaderBoardData] = useState([]);
  const [batchName, setBatchName] = useState();

  /* common popup states */

  const [isCommonPopup, setIsCommonPopup] = useState(false);
  const [isWobtnPopup, setIsWoBtnPopup] = useState(false);
  const [isCommonPopupCloseIcon, setIsCommonPopupCloseIcon] = useState(false);
  const [commonPopupTitle, setCommonPopupTitle] = useState("");
  const [commonPopupTitleDesc, setCommonPopupTitleDesc] = useState("");
  const [iscommonPopupBodyComponent, setIsCommonPopupBodyComponent] =
    useState(false);
  const [commonPopupBodyComponent, setCommonPopupBodyComponent] = useState();
  const [commonPopupBodyDesc, setCommonPopupBodyDesc] = useState("");
  const [iscommonPopupSingleBtn, setIsCommonPopupSingleBtn] = useState(false);
  const [commonPopupBtnSize, setCommonPopupBtnSize] = useState("");
  const [commonPopupSingleBtnTxt, setCommonPopupSingleBtnTxt] = useState("");
  const [commonPopupSingleBtnFunc, setCommonPopupSingleBtnFunc] = useState();
  const [commonPopupPrimaryBtnTxt, setCommonPopupPrimaryBtnTxt] = useState("");
  const [commonPopupSecBtnTxt, setCommonPopupSecBtnTxt] = useState("");
  const [commonPopupPrimaryBtnFunc, setCommonPopupPrimaryBtnFunc] = useState();
  const [commonPopupSecBtnFunc, setCommonPopupSecBtnFunc] = useState();

  // const [testopened, settestopened] = useState(false);
  // const [prevreporttypes, setprevreporttypes] = useState([]);
  // const [prevreportstatus, setprevreportstatus] = useState([]);
  // const [prevreporttopics, setprevreporttopics] = useState([]);
  // const [prevhiddencolumns, setprevhiddencolumns] = useState([]);
  // const [prevreportlearnerdata, setprevreportlearnerdata] = useState([]);

  const [isAdmin, setIsAdmin] = useState(
    getDataFromStorage("role") === "admin" ||
      getDataFromStorage("role") === "trainer"
      ? true
      : false
    // getDataFromStorage("role") !== "learner" ? true : false
  );
  const [assignQnDetailsForLearner, setAssignQnDetailsForLearner] = useState(
    {}
  );
  const [batchLearnerListForEvaluation, setBatchLearnerListForEvaluation] =
    useState([]);

  // const [isSubmitted, setIsSubmitted] = useState(
  //   status === constants.PROGRESS_STATUS.UNLOCKED ||
  //     status === constants.PROGRESS_STATUS.REATTEMPT
  //     ? false
  //     : true
  // );

  const [isSubmitted, setIsSubmitted] = useState(false);

  let postViewTest = useRef(1);

  /* transition Box */

  const [showTimer, setShowTimer] = useState(false);
  const [showtransition, setshowtransition] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // video qn
  const videoQn = useRef();
  const questionListObj = useRef([]);
  const sectionWithQuestions = useRef();
  const [disableNxtBtn, setDisableNxtBtn] = useState(false);
  const showSubmitBtn = useRef(false);
  const [hideSubmitVideoQn, setHideSubmitVideoQn] = useState(true);
  const videoQnNo = useRef(0);
  const [showTranscript, setShowTranscript] = useState(false);
  const [clcikedMenu, setClickedMenu] = useState(1);
  const exitVdoSubmit = useRef(false);
  const timeVdoSubmit = useRef(false);
  const [updatingStates, setUpdatingStates] = useState(false);

  const showTranscriptFunc = () => {
    setShowTranscript(!showTranscript);
  };

  /* Zoom content*/
  const ZoomBatchStudents = useRef({});
  // const [currentBatch, setCurrentBatch] = useState({});

  let [participants, setparticipants] = useState([]);

  const [showzoommodal, setshowzoommodal] = useState(false);
  const [showwarnmodal, setshowwarnmodal] = useState(false);

  const [showremovefromtestmodal, setshowremovefromtestmodal] = useState(false);
  const [showremovefrombatchmodal, setshowremovefrombatchmodal] =
    useState(false);

  const setZoomBatchStudents = (batchdata) => {
    ZoomBatchStudents.current = batchdata;
  };

  const setshowzoommodalfunction = (value) => {
    setshowzoommodal(value);
  };

  function setshowwarnmodalfunction(value) {
    setshowwarnmodal(value);
  }

  function setshowremovefromtestmodalfunction(value) {
    setshowremovefromtestmodal(value);
  }

  function setshowremovefrombatchmodalfunction(value) {
    setshowremovefrombatchmodal(value);
  }

  /* admin  states */

  const [lpData2, setLpData2] = useState({
    lpDes: "",
    lpName: "",
    moduleId: "",
    lpContent: "",
    lpIcon: "",
    lpShortDes: "",
    status: "",
    iconFileName: "",
    id: nanoid(),
    libTypeId: 2,
    isAddTest: false,
    modules: [
      {
        id: 1,
        key: nanoid(),
        status: "new",
        moduleName: "",
        moduleData: [],
      },
    ],
  });

  const [isAddLp, setIsAddLp] = useState(false);
  const [isFromLp, setIsFromLp] = useState(false);
  const [batchDetails, setBatchDetails] = useState({ learningpath: [] });
  const [isFromBatch, setIsFromBatch] = useState(false);
  const [userReportData, setUserReportData] = useState([]);
  const [isAddLpModule, setIsAddLpModule] = useState(false);
  const [isOldLpDetail, setIsOldLpDetail] = useState(false);

  const [moduleExpandKey, setModuleExpandKey] = useState(
    lpData2.modules.length > 0 ? [lpData2.modules[0].key] : []
  );
  const [oldTestConfig, setOldTestConfig] = useState({
    oldTestData: {},
    isOldTestConfig: false,
  });

  const [cellobj, setcellobj] = useState({});
  const [fromMenu, setFromMenu] = useState(false);
  const [isBatchDetails, setIsBatchDetails] = useState(false);
  const [fromMenuToEditTest, setFromMenuToEditTest] = useState(false);
  const [openSandpackEditor, setOpenSandpackEditor] = useState(false);
  const [filterUserReportData, setFilterUserReportData] = useState([]);
  const [mcqAndDesTestAndLpId, setMcqAndDesTestAndLpId] = useState({});
  const [fromList, setFromList] = useState({ isFromList: false, libType: "" });

  // const [prevlpid, setprevlpid] = useState(0);
  // const [prevglobalfilter, setprevglobalfilter] = useState("");

  const [reportSortState, setReportSortState] = useState([]);

  const screenType = useRef("desktop");
  const screenWidth = useRef(window.innerWidth);
  const screenHeight = useRef(window.innerHeight);

  let prevlpid = useRef(0);
  let prevctdata = useRef([]);
  let prevtypesobj = useRef([]);
  let testopened = useRef(false);
  let prevmodulescol = useRef([]);
  let learnerdataref = useRef([]);
  let prevreporttypes = useRef([]);
  let prevreportstatus = useRef([]);
  let prevreporttopics = useRef([]);
  let prevglobalfilter = useRef([]);
  let prevhiddencolumns = useRef([]);
  let prevvisiblecolumns = useRef([]);
  let prevreporttypevalues = useRef([]);
  let prevreportlearnerdata = useRef([]);

  const setlearnerdataref = (studentdata) => {
    learnerdataref.current = studentdata;
  };

  const settestopened = (topened) => {
    testopened.current = topened;
  };

  const setprevctdata = (ctdata) => {
    prevctdata.current = ctdata;
  };

  const setprevtypesobj = (typesobj) => {
    prevtypesobj.current = typesobj;
  };
  const showAnswer = () => {
    setShowSaqLaqAns(!showSaqLaqAns);
  };

  const setprevreporttypevalues = (preporttypevalues) => {
    prevreporttypevalues.current = preporttypevalues;
  };

  const setprevreportlearnerdata = (preportlearnerdata) => {
    prevreportlearnerdata.current = preportlearnerdata;
  };

  const setprevlpid = (plpid) => {
    prevlpid.current = plpid;
  };

  const setprevreportstatus = (preportstatus) => {
    prevreportstatus.current = preportstatus;
  };

  const setprevreporttypes = (preporttypes) => {
    prevreporttypes.current = preporttypes;
  };

  const setprevreporttopics = (preporttopics) => {
    prevreporttopics.current = preporttopics;
  };

  const setprevhiddencolumns = (phiddencolumns) => {
    prevhiddencolumns.current = phiddencolumns;
  };

  const setprevglobalfilter = (pglobalfilter) => {
    prevglobalfilter.current = pglobalfilter;
  };

  const setprevvisiblecolumns = (pcolumns) => {
    prevvisiblecolumns.current = pcolumns;
  };

  const setprevmodulescol = (modcol) => {
    prevmodulescol.current = modcol;
  };

  const LoadDeviceDetails = () => {
    screenWidth.current = window.innerWidth;
    screenHeight.current = window.innerHeight;

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      screenType.current = "mobile";
      return;
    }

    //   const userAgent = navigator.userAgent.toLowerCase();

    if (
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        navigator.userAgent.toLowerCase()
      )
    ) {
      screenType.current = "tablet";

      return;
    } else {
      screenType.current = "desktop";

      return;
    }
  };

  const setPostViewTest = (Setting) => {
    postViewTest.current = Setting;
  };

  /* Report new states */

  const [searchText, setSearchText] = useState("");
  const [reportTopics, setReportTopics] = useState([]);
  const [hiddenColumnsInTable, setHiddenColumnsInTable] = useState([]);
  const [isReportClick, setIsReportClick] = useState(false);
  const [reportStatus, setReportStatus] = useState([
    {
      checked: false,
      disabled: false,
      label: "Yet To Start",
      value: constants.PROGRESS_STATUS.LOCKED,
    },
    {
      checked: false,
      disabled: false,
      label: "Submitted",
      value: constants.PROGRESS_STATUS.TO_BE_EVALUATED,
    },
    {
      checked: false,
      disabled: false,
      label: "Yet To Submit",
      value: constants.PROGRESS_STATUS.UNLOCKED,
    },
    {
      checked: false,
      disabled: false,
      label: "Resubmission Granted",
      value: constants.PROGRESS_STATUS.REATTEMPT,
    },
    {
      checked: false,
      disabled: false,
      label: "Graded",
      value: constants.PROGRESS_STATUS.EVALUATED,
    },
  ]);
  const [typesAndModules, setTypesAndModules] = useState([
    {
      checked: false,
      disabled: false,
      label: "Active",
      value: "active",
    },
    {
      value: "assi",
      checked: false,
      disabled: false,
      label: "Assignment",
    },
    {
      value: "ccq",
      checked: false,
      label: "Coding",
      disabled: false,
    },
    {
      value: "cct",
      checked: false,
      disabled: false,
      label: "Coding Totals",
    },
    {
      checked: false,
      disabled: true,
      label: "Confidence",
      value: "confidence",
    },
    {
      value: "des",
      checked: false,
      disabled: false,
      label: "Description",
    },
    {
      checked: false,
      disabled: false,
      label: "Inactive",
      value: "inactive",
    },
    {
      label: "MCQ",
      value: "mcq",
      checked: false,
      disabled: false,
    },
    {
      checked: false,
      disabled: false,
      value: "moduletotals",
      label: "Module Totals",
    },
    {
      checked: false,
      disabled: true,
      label: "Performance",
      value: "performance",
    },
    {
      checked: false,
      disabled: false,
      label: "Practice",
      value: "practice",
    },
  ]);

  const viewColumns = useRef([]);
  const viewLearnerData = useRef([]);
  const selectedColumns = useRef([]);
  const reportTableRef = useRef(null);
  const currentTypeAndModules = useRef([]);

  const [performance, setPerformance] = useState(false);

  /* sandpack editor state */

  const [sandpackFiles, setSandpackFiles] = useState({});
  const [selectedTemplate, setSeletedTemplate] = useState("React");
  const [showSandpackOptions, setShowSandpackOptions] = useState(false);

  const handleClose = () => {
    setShow(false);
    setShowConfirmNotify(false);
    setSvg("");
    setMsg("");
    setBtnText("");
    setBtnText2("");
    setResultCode("");
  };

  const handleNavigateToHome = () => {
    setLpId(lpId);
    setLpData({});
    setTheme(false);
    setCodeDetails({});
    setIsFullScreen(false);
    setEditorTheme("tomorrow");
    setFromCodeEditor(true);
    setMsg("");
    setSvg("");
    setShow(false);
    setShowConfirmNotify(false);
    setBtnText("");
    setBtnText2("");

    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    navigate("/home");
  };

  const handleRegistration = () => {
    setShowConfirmNotify(false);
    navigate("/registration");
  };

  const handleUnAuthAlert = async () => {
    setAuth({});
    try {
      await logout();
    } catch (err) {
    } finally {
      setSvg("");
      setBtnText("");
      setShow(false);
      setBtnText2("");
      setResultCode("");
      setIsUnAuth(false);
      setIsBatchDetails(false);
      // localStorage.clear();
      localStorage.removeItem("tokenKey");
      localStorage.removeItem("refreshToken");
      navigate("/");
      window.location.reload();
    }
  };

  /* for admin learning path */

  const handleGetLibIdFromLp = (data) => {
    setOldTestConfig({ isOldTestConfig: false, oldTestData: {} });
    setFromMenu(false);
    setLpData2(data);
    navigate("/library/testlist");
  };

  const handleNavigateToLpAfterAddTest = (data) => {
    if (isOldLpDetail && isFromBatch) {
      setLpData2(data);
      navigate(`/batches/${localStorage.getItem("abatchid")}/courses`);
    } else {
      if (!isOldLpDetail) {
        setIsAddLp(true);
        setIsAddLpModule(true);
      }

      setLpData2(data);

      if (oldTestConfig.mockLp) {
        navigate("mock/create/learningpath");
      } else {
        navigate("learningpath");
      }
    }
  };

  const handleEditTestConfig = (
    oldTestConfigData,
    moduleId,
    isEdited,
    status,
    published,
    testStatus
  ) => {
    setFromMenu(false);

    if (!isEdited) {
      let data = oldTestConfigData[0],
        count = 0,
        uniqueId = 1;

      data.isOld = true;
      data.status = status;
      data.published = published;
      data.testStatus = testStatus;
      data.libTypeId = data.libType;
      data.testDurationUnit = "min/s";
      data.testDurationNum = data.testDuration;
      data.testDuration = data.testDuration + "m";
      data.testType = { label: data.libName, value: data.libType };

      for (let i = 0; i < data.sections.length; i++) {
        count++;
        data.sections[i].id = uniqueId;
        data.sections[i].key = nanoid();
        data.sections[i].status = status;
        data.sections[i].testStatus = testStatus;
        data.sections[i].type = testStatus === "new" ? "insert" : testStatus;
        data.sections[i].isOld = testStatus === "new" ? false : true;
        uniqueId++;

        for (let j = 0; j < data.sections[i].secData.length; j++) {
          data.sections[i].secData[j].id = uniqueId;
          data.sections[i].secData[j].key = nanoid();
          data.sections[i].secData[j].type =
            testStatus === "new" ? "insert" : testStatus;
          data.sections[i].secData[j].isOld =
            testStatus === "new" ? false : true;
          data.sections[i].secData[j].topic = {
            label: data.sections[i].secData[j].topicName,
            value: data.sections[i].secData[j].topicId,
          };
          data.sections[i].secData[j].status = status;
          data.sections[i].secData[j].desType = {
            label: data.sections[i].secData[j].configLibName,
            value: data.sections[i].secData[j].configLibType,
          };
          data.sections[i].secData[j].complexity = {
            label: data.sections[i].secData[j].complexityName,
            value: data.sections[i].secData[j].complexityId,
          };
          data.sections[i].secData[j].testStatus = testStatus;
          if (
            data.libTypeId === constants.libTypeIds.DESCRIPTIVE &&
            data.videoQn
          ) {
            data.sections[i].secData[j].durationNum =
              data.sections[i].secData[j].duration /
              (data.sections[i].secData[j].randomQnCount +
                data.sections[i].secData[j].fixedQnCount);
            data.sections[i].secData[j].durationUnit = "min/s";
          }

          if (data.libTypeId === constants.libTypeIds.CODING_CHALLENGE) {
            let languages = [];

            for (
              let k = 0;
              k < data.sections[i].secData[j].languages.length;
              k++
            ) {
              languages.push({
                value: data.sections[i].secData[j].languages[k].id,
                label: data.sections[i].secData[j].languages[k].name,
                key: nanoid(),
              });
            }

            data.sections[i].secData[j].languages = languages;
            data.sections[i].secData[j].durationNum =
              data.sections[i].secData[j].duration /
              (data.sections[i].secData[j].randomQnCount +
                data.sections[i].secData[j].fixedQnCount);
            data.sections[i].secData[j].durationUnit = "min/s";
          }

          uniqueId += 8;
        }

        setOldTestConfig({
          oldTestData: data,
          moduleId: moduleId,
          isOldTestConfig: true,
        });

        // setLpData2(data);

        if (count === data.sections.length) navigate("/library/testlist");
      }
    } else {
      setOldTestConfig({
        moduleId: moduleId,
        isOldTestConfig: true,
        oldTestData: oldTestConfig.oldTestData,
      });
      navigate("/library/testlist");
    }
  };

  const handleEditedTestConfig = (data) => {
    setLpData2(data);

    if (isOldLpDetail && isFromBatch) {
      navigate(`/batches/${localStorage.getItem("abatchid")}/courses`);
    } else navigate("/learningpath");
  };

  const assignQuestionToLearner = (qnData) => {
    setAssignQnDetailsForLearner(qnData);
    navigate("/coding");
  };

  const handleMcq = (
    testid,
    lpid,
    lpconfigid,
    status,
    learnerid,
    proctored,
    testmode,
    configdata,
    learnerlist,
    fromreport,
    cellobj,
    libtypeid,
    viewTest,
    autoEval
  ) => {
    if (isAdmin) {
      setLearnerId(learnerid);
      setFromReport(fromreport);
      setTestId(testid);
    }

    setMcqAndDesTestAndLpId({
      lpid: lpid,
      testid: testid,
      status: status,
      cellobj: cellobj,
      autoEval: autoEval,
      testmode: testmode,
      viewTest: viewTest,
      learnerid: learnerid,
      proctored: proctored,
      libtypeid: libtypeid,
      configdata: configdata,
      lpconfigid: lpconfigid,
    });

    setTakeMcqAndDesTest(true);
    navigate("/mcq");
  };

  const handleWebCamError = () => {
    setCamAvailable(false);
  };

  const handleContent = (
    data,
    lpId,
    type,
    lpConfigId,
    libId,
    status,
    learnerlist,
    fromreport,
    lid,
    cellobj
  ) => {
    setBatchLearnerListForEvaluation(learnerlist);
    setFromReport(fromreport);
    setLearnerId(lid);

    if (data.name === null) {
      setShow(true);
      setTitle("Info");
      setMsg("There is no data to see. Contact your trainer.");
    } else {
      if (
        status === constants.PROGRESS_STATUS.REATTEMPT ||
        status === constants.PROGRESS_STATUS.UNLOCKED
      ) {
        setIsSubmitted(false);
      } else {
        setIsSubmitted(true);
      }
      setLpId(lpId);
      setLibId(libId);
      setLibType(type);
      setStatus(status);
      setContentData(data);
      setLpConfigId(lpConfigId);
      setcellobj(cellobj);

      if (type === 1) {
        try {
          if (
            data.description !== null &&
            typeof JSON.parse(data.description) === "object"
          ) {
            navigate("/content");
          } else if (
            data.description === null ||
            typeof data.description === "string"
          ) {
            navigate("/newcontent");
          } else {
            navigate("/content");
          }
        } catch (error) {
          navigate("/newcontent");
        }
      } else {
        // if (status === 1) {
        setAssignmentTestData(data);
        navigate("/assignment");
        setTestData({
          testmode: cellobj.testMode,
          testtype: cellobj.type,
          testname: cellobj.testName,
        });

        // } else {
        //   navigate("/assignment/content");
        // }
      }
    }
  };

  const handleNavContentOrAssignment = (
    data,
    lpId,
    type,
    lpConfigId,
    libId,
    status,
    learnerlist,
    fromreport,
    lid,
    cellobj
  ) => {
    setFromReport(fromreport);
    setLearnerId(lid);

    if (data.name === null) {
      setShow(true);
      setTitle("Info");
      setMsg("There is no data to see. Contact your trainer.");
    } else {
      if (
        status === constants.PROGRESS_STATUS.REATTEMPT ||
        status === constants.PROGRESS_STATUS.UNLOCKED
      ) {
        setIsSubmitted(false);
      } else {
        setIsSubmitted(true);
      }

      if (type === 1) {
        try {
          if (
            data.description !== null &&
            typeof JSON.parse(data.description) === "object"
          ) {
            data.type = "contentEditor";
          } else if (
            data.description === null ||
            typeof data.description === "string"
          ) {
            data.type = "oldContent";
          } else {
            data.type = "contentEditor";
          }
        } catch (error) {
          data.type = "oldContent";
        }
      }

      setLpId(lpId);
      setLibId(libId);
      setLibType(type);
      setStatus(status);
      setContentData(data);
      setLpConfigId(lpConfigId);
      setcellobj(cellobj);
      setShowMain(true);

      // else {
      // navigate("/assignment");
      // }
    }
  };

  const selectedQuestion = (
    question,
    module,
    topic,
    learnerId,
    status,
    lname,
    qnno,
    lpid,
    order,
    learnerList,
    fromreport,
    libtypefromreport,
    lpconfigid1,
    tld,
    accessor,
    cellobj
  ) => {
    setcuraccessor(accessor);
    filccld.current = tld;
    setFromReport(fromreport);
    setLpConfigId(lpconfigid1);
    setLibType(libtypefromreport);
    setQnNo(order);
    setTopic(topic);
    setStatus(status);
    setModule(module);
    setLearnerName(lname);
    setlpmtopicqnno(qnno);
    setAssignQns(question);
    setLearnerId(learnerId);
    setTestId(cellobj.testId);
    setShow(false);
    setcellobj(cellobj);
    navigate("/coding");
  };

  const handlePopupOkFunc = () => {
    handleUnAuthAlert();
    clearPopupStates();
    // leaveMeeting();
  };

  const handleResponse = (title, msg, isUnAuth) => {
    // Prepare the popup data
    const popupdata = {
      showpopup: true,
      title,
      bodydesc: msg,
      singlebtn: true,
      singlebtntxt: "Ok",
      singlebtnsize: "small",
      singlebtnfunt: isUnAuth ? handlePopupOkFunc : clearPopupStates,
    };

    // Call the function to handle the popup state
    handlePopupStates(popupdata);
  };

  const handleDisableUser = async () => {
    try {
      await axios.post(
        "node/zoom/batch/user/status",
        JSON.stringify({
          userIds: [getDataFromStorage("learnerid")],
          batchId: localStorage.getItem("batchid"),
          active: false,
        }),
        {
          timeout: 60000,
          headers: {
            "Content-type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("err of disable user", error);
      if (error.message.includes("403")) {
        handleResponse("Error !", "The session token is invalid", true);
      } else {
        handleResponse(
          "Error !",
          "Something went wrong… Please, try again",
          false
        );
      }
    }
  };

  const leaveMeeting = () => {
    // ZoomMtg.leaveMeeting({
    //   success: () => {
    //   },
    //   error: (error) => {
    //     console.error("Error leaving the meeting:", error);
    //   },
    // });
  };

  return (
    <AlertContext.Provider
      value={{
        msg,
        svg,
        auth,
        show,
        lpId,
        qnNo,
        title,
        libId,
        show1,
        topic,
        state,
        lists,
        lpData,
        setSvg,
        setMsg,
        status,
        module,
        testId,
        lpData2,
        setQnNo,
        filccld,
        setAuth,
        comment,
        setLpId,
        setShow,
        btnText,
        isAdmin,
        isAddLp,
        libType,
        cellobj,
        isScore,
        navhome,
        batchId,
        setLists,
        setShow1,
        setState,
        register,
        setTitle,
        setLibId,
        isLoaded,
        isUnAuth,
        navigate,
        isFromLp,
        fromList,
        testData,
        content2,
        fromMenu,
        btnText2,
        showMain,
        prevlpid,
        setTopic,
        setModule,
        topicList,
        handleMcq,
        setLpData,
        setTestId,
        setStatus,
        isComment,
        isFixedQn,
        assignQns,
        webCamRef,
        setLpData2,
        setComment,
        setBatchId,
        lpConfigId,
        testActive,
        setBtnText,
        setNavhome,
        setIsAddLp,
        setIsScore,
        setIsAdmin,
        resultCode,
        fromReport,
        setLibType,
        isTakeTest,
        screenType,
        reportdata,
        prevctdata,
        showNotify,
        testopened,
        searchText,
        learnername,
        showPrevBtn,
        curaccessor,
        setShowMain,
        setTestData,
        setIsLoaded,
        setBtnText2,
        contentData,
        handleClose,
        setIsUnAuth,
        setIsFromLp,
        setFromList,
        screenWidth,
        isFromBatch,
        setFromMenu,
        setContent2,
        setprevlpid,
        viewColumns,
        performance,
        showNextBtn,
        Loginstatus,
        isSubmitted,
        countPicture,
        setAssignQns,
        setIsComment,
        setIsFixedQn,
        prevtypesobj,
        lpmtopicqnno,
        batchDetails,
        setTopicList,
        postViewTest,
        learnerNotes,
        screenHeight,
        reportTopics,
        reportStatus,
        currentBatch,
        moduleActive,
        camAvailable,
        handleContent,
        setResultCode,
        setLpConfigId,
        setTestActive,
        setFromReport,
        setIsTakeTest,
        isAddLpModule,
        setreportdata,
        isstableState,
        settestopened,
        isOldLpDetail,
        oldTestConfig,
        sandpackFiles,
        setSearchText,
        setShowNotify,
        reportColumns,
        reportfilters,
        setprevctdata,
        testReqDetail,
        updateLeftNav,
        codingTestData,
        sessionDetails,
        setShowNextBtn,
        setShowPrevBtn,
        registerNotify,
        setcuraccessor,
        setLoginStatus,
        setIsFromBatch,
        setContentData,
        setLearnerName,
        prevmodulescol,
        learnerdataref,
        isBatchDetails,
        isFullScreensp,
        setIsSubmitted,
        setPerformance,
        reportTableRef,
        userReportData,
        fromCodeEditor,
        setModuleActive,
        showInstruction,
        setlpmtopicqnno,
        setLearnerNotes,
        setCurrentBatch,
        setBatchDetails,
        setprevtypesobj,
        reportSortState,
        prevreporttypes,
        setPostViewTest,
        isAddSubmission,
        moduleExpandKey,
        setReportTopics,
        setReportStatus,
        submissionFiles,
        viewLearnerData,
        typesAndModules,
        selectedColumns,
        setCamAvailable,
        selectedQuestion,
        prevreporttopics,
        setTestReqDetail,
        setIsOldLpDetail,
        setreportfilters,
        setUpdateLeftNav,
        setIsStableState,
        setReportColumns,
        prevglobalfilter,
        setSandpackFiles,
        selectedTemplate,
        setOldTestConfig,
        prevreportstatus,
        isEditSubmission,
        setIsAddLpModule,
        isEditAttendance,
        showConfirmNotify,
        setSessionDetails,
        setFromCodeEditor,
        setRegisterNotify,
        setUserReportData,
        setCodingTestData,
        handleUnAuthAlert,
        setIsBatchDetails,
        handleWebCamError,
        changeHeaderValue,
        instructionDetail,
        takeMcqAndDesTest,
        setprevmodulescol,
        setlearnerdataref,
        setIsFullScreensp,
        LoadDeviceDetails,
        prevhiddencolumns,
        setModuleExpandKey,
        setInstructionPage,
        assignmentTestData,
        setSeletedTemplate,
        setReportSortState,
        setprevreporttypes,
        prevvisiblecolumns,
        setIsAddSubmission,
        openSandpackEditor,
        setTypesAndModules,
        numberOfPicsToTake,
        fromMenuToEditTest,
        setSubmissionFiles,
        setShowInstruction,
        handleRegistration,
        showSandpackOptions,
        setprevglobalfilter,
        setprevreportstatus,
        setIsEditSubmission,
        notificationPayload,
        setprevreporttopics,
        setIsEditAttendance,
        setprevhiddencolumns,
        setShowConfirmNotify,
        setTakeMcqAndDesTest,
        handleNavigateToHome,
        handleGetLibIdFromLp,
        handleEditTestConfig,
        filterUserReportData,
        mcqAndDesTestAndLpId,
        setChangeHeaderValue,
        stablePageforBackbtn,
        prevreporttypevalues,
        hiddenColumnsInTable,
        stableTextforBackbtn,
        selectedLearningPath,
        setFromMenuToEditTest,
        setprevvisiblecolumns,
        prevreportfiltertypes,
        setAssignmentTestData,
        currentTypeAndModules,
        prevreportlearnerdata,
        setOpenSandpackEditor,
        stableStateforBackbtn,
        setShowSandpackOptions,
        setNotificationPayload,
        handleEditedTestConfig,
        setSelectedLearningPath,
        setMcqAndDesTestAndLpId,
        setFilterUserReportData,
        setStablePageforBackbtn,
        setStableTextforBackbtn,
        setHiddenColumnsInTable,
        assignQuestionToLearner,
        setprevreporttypevalues,
        setStableStateforBackbtn,
        setprevreportfiltertypes,
        setprevreportlearnerdata,
        assignQnDetailsForLearner,
        assignmentSubmissionFiles,
        setAssignQnDetailsForLearner,
        handleNavContentOrAssignment,
        batchLearnerListForEvaluation,
        handleNavigateToLpAfterAddTest,
        setBatchLearnerListForEvaluation,
        dashBoardDetails,
        setDashBoardDetails,
        leaderBoardData,
        setLeaderBoardData,
        batchName,
        setBatchName,
        seconds,
        setSeconds,
        moduleList,
        setModuleList,
        isFirstLoggedIn,
        setIsFirstLoggedIn,
        showAnswer,
        setShowSaqLaqAns,
        showSaqLaqAns,
        changelearner,
        setChangeLearner,
        /* popup states */
        isCommonPopup,
        setIsCommonPopup,
        isWobtnPopup,
        setIsWoBtnPopup,
        isCommonPopupCloseIcon,
        setIsCommonPopupCloseIcon,
        commonPopupTitle,
        setCommonPopupTitle,
        commonPopupTitleDesc,
        setCommonPopupTitleDesc,
        iscommonPopupBodyComponent,
        setIsCommonPopupBodyComponent,
        commonPopupBodyComponent,
        setCommonPopupBodyComponent,
        commonPopupBodyDesc,
        setCommonPopupBodyDesc,
        iscommonPopupSingleBtn,
        setIsCommonPopupSingleBtn,
        commonPopupBtnSize,
        setCommonPopupBtnSize,
        commonPopupSingleBtnTxt,
        setCommonPopupSingleBtnTxt,
        commonPopupSingleBtnFunc,
        setCommonPopupSingleBtnFunc,
        commonPopupPrimaryBtnTxt,
        setCommonPopupPrimaryBtnTxt,
        commonPopupSecBtnTxt,
        setCommonPopupSecBtnTxt,
        commonPopupPrimaryBtnFunc,
        setCommonPopupPrimaryBtnFunc,
        commonPopupSecBtnFunc,
        setCommonPopupSecBtnFunc,
        /* -- popup states -- */
        // button
        hierarchy,
        buttonSize,
        buttonIcon,
        destructive,
        setHierarchy,
        setButtonSize,
        setButtonIcon,
        buttonDisable,
        setDestructive,
        buttonFunction,
        setButtonDisable,
        setButtonFunction,
        // button end
        // mcq
        duration,
        hasRendered,
        backupTestDataq,
        queInd,
        setQueInd,
        secInd,
        setSecInd,
        answers,
        setAnswers,
        testMode,
        setTestMode,
        action,
        setAction,
        comingObj,
        updateComingObj,
        mcqTestData,
        setMcqTestData,
        isTestData,
        setIsTestData,
        prevButton,
        setPrevButton,
        nextButton,
        setNextButton,
        testPageShow,
        setTestPageShow,
        filterQuestionCount,
        setFilterQuestionCount,
        questionContainerSingle,
        updateQuestionContainerSingle,
        queLen,
        setQueLen,
        secLen,
        setSecLen,
        secObj,
        setSecObj,
        queObj,
        setQueObj,
        optObj,
        setOptObj,
        interval,
        ansStats,
        setAnsStats,
        testStats,
        setTestStats,
        descScores,
        setDescScores,
        apiLoaded,
        setApiLoaded,
        totalSecScore,
        setTotalSecScore,
        totalTestScore,
        setTotalTestScore,
        backupTestData,
        setBackupTestData,
        totalLearnerScore,
        setTotalLearnerScore,
        // mcq end

        //content
        selectque,
        isqueReport,
        setselectque,
        currentIndex,
        isFullScreen,
        setisqueReport,
        referenceLinks,
        selectedLibType,
        setCurrentIndex,
        setIsFullScreen,
        tableShowStatus,
        setReferenceLinks,
        setSelectedLibType,
        setTableShowStatus,
        //content end

        //coding
        theme,
        count,
        score,
        output,
        profile,
        setScore,
        setCount,
        language,
        setTheme,
        showScore,
        learnerId,
        setOutput,
        timeLimit,
        setProfile,
        showReject,
        rejectData,
        approveData,
        setLanguage,
        codeDetails,
        editorTheme,
        learnerCode,
        showApprove,
        setShowScore,
        setLearnerId,
        showComments,
        setTimeLimit,
        testCasesRef,
        commentsData,
        setRejectData,
        setShowReject,
        inputVariable,
        captureBtnHide,
        setApproveData,
        setShowApprove,
        tabSwitchCount,
        setEditorTheme,
        setLearnerCode,
        setCodeDetails,
        setShowComments,
        isShowTimeAlert,
        setCommentsData,
        isInputVariable,
        makeRunTestCase,
        breadCrumbsData,
        isLearnerQuitQns,
        callAllTestCases,
        setInputVariable,
        selectedLanguage,
        breadCrumbsOption,
        setCaptureBtnHide,
        commentAttachment,
        runTestResultCode,
        setBreadCrumbsData,
        setIsShowTimeAlert,
        setMakeRunTestCase,
        outputWindowHeight,
        solutionLanguageId,
        showBreadCrumbsData,
        problemWindowHeight,
        setSelectedLanguage,
        setCommentAttachment,
        setBreadCrumbsOption,
        isAdminSubmitTimeout,
        setRunTestResultCode,
        setOutputWindowHeight,
        setProblemWindowHeight,
        setShowBreadCrumbsData,
        //coding end

        //zoom start
        participants,
        setparticipants,
        ZoomBatchStudents,
        setZoomBatchStudents,
        showzoommodal,
        setshowzoommodal,
        setshowzoommodalfunction,
        showwarnmodal,
        setshowwarnmodal,
        setshowwarnmodalfunction,
        showremovefromtestmodal,
        showremovefrombatchmodal,
        setshowremovefrombatchmodal,
        setshowremovefrombatchmodalfunction,
        setshowremovefromtestmodal,
        setshowremovefromtestmodalfunction,
        currentDashboardMetaData,
        setCurrentDashboardMetaData,
        currentEditDashboardMetaData,
        setCurrentEditDashboardMetaData,
        isDashBoardEdit,
        setIsDashBoardEdit,
        downloadWidget,
        setDownloadWidget,
        tsCoBkup,
        setTsCoBkup,
        batchGridView,
        setBatchGridView,
        lpGridView,
        setLpGridView,
        questionListObj,
        showTimer,
        setShowTimer,
        showtransition,
        setshowtransition,
        handleDisableUser,
        leaveMeeting,
        currentPage,
        setCurrentPage,
        batchNames,
        setBatchNames,
        topicId,
        setTopicId,
        searchValue,
        setSearchValue,
        // video qn
        startVideo,
        setStartVideo,
        videoQn,
        disableNxtBtn,
        setDisableNxtBtn,
        sectionWithQuestions,
        hideSubmitVideoQn,
        setHideSubmitVideoQn,
        isReportClick,
        setIsReportClick,
        videoQnNo,
        showTranscript,
        setShowTranscript,
        showTranscriptFunc,
        showSubmitBtn,
        leaderboardTestDetail,
        setLeaderBoardTestDetail,
        handleResponse,
        clcikedMenu,
        setClickedMenu,
        exitVdoSubmit,
        userProfilePic,
        setUserProfilePic,
        timeVdoSubmit,
        updatingStates, setUpdatingStates,
        learnerVideoLink,setLearnerVideoLink
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};
export default AlertContext;
