import React, { useContext, useEffect, useRef, useState } from "react";
import Modules from "./Modules";
import { nanoid } from "nanoid";
import ButtonMedium from "../../Buttons/ButtonMedium/ButtonMedium";
import Form from "react-bootstrap/Form";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import AlertContext from "../../../context/AlertContext";
const NewLearningPath = (props) => {
  const {lpData2,setLpData2} = useContext(AlertContext)
  // const [isNext, setIsNext] = useState(false);
  const axios = useAxiosPrivate();
  const MAX_IMAGE_SIZE = 150000;
  const [newLpDetails, setNewLpDetails] = useState(lpData2);
  const [isUpload, setIsUploaded] = useState(false);
  const [maxCharForDes, setMaxCharForDes] = useState(300);
  const [height, setHeight] = useState("100px");
  const lpContentRef = useRef(null);
  const [lpArchived, setLpArchived] = useState(lpData2.archive);

  useEffect(() => {
    setNewLpDetails(JSON.parse(JSON.stringify(lpData2)));
    setLpArchived(lpData2.archive);
  }, [lpData2]);
 
  const handleLpDetails = (event) => {
    let MAXCHAR = 300;
    let newDetails = JSON.parse(JSON.stringify(newLpDetails));
    if (event.target.name === "lpIcon") {
      let reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result.length > MAX_IMAGE_SIZE) {
          props.setShow(true);
          props.setMsg("File size is too large.");
          props.setTitle("Warning !");
          event.target.value = "";
          return;
        }
        newDetails[event.target.name] = reader.result;
        if (props.isOldLpDetail) newDetails.status = "update";
        setNewLpDetails(newDetails);
      };
      reader.readAsDataURL(event.target.files[0]);

      // newDetails["iconData"] = event.target.files[0].name;
    } else newDetails[event.target.name] = event.target.value;

    if (event.target.name === "lpDes") {
      setMaxCharForDes(MAXCHAR - event.target.value.length);
    }
    if (props.isOldLpDetail) newDetails.status = "update";
    setNewLpDetails(newDetails);
  };

  const handleLpContent = (event) => {
    let newDetails = JSON.parse(JSON.stringify(newLpDetails));
    if (props.isOldLpDetail) newDetails.status = "update";
    newDetails[event.target.name] = event.target.value;
    if (event.target.value.length === 0) setHeight("100px");
    else setHeight(`${event.target.scrollHeight}px`);
    setNewLpDetails(newDetails);
  };

  const handleClickNext = () => {
    if (newLpDetails.lpName === "") {
      props.setShow(true);
      props.setMsg("Please enter the path name.");
      props.setTitle("Warning !");
    } /* else if (newLpDetails.lpIcon === "") {
      props.setShow(true);
      props.setMsg("Please upload thumbnail.");
      props.setTitle("Warning !");
    } else if (newLpDetails.lpContent === "") {
      props.setShow(true);
      props.setMsg("Please give table of content.");
      props.setTitle("Warning !");
    }  */else props.setIsAddLpModule(true);
    
    if (props.isOldLpDetail) {
      newLpDetails.status='update';
      props.setLpData(newLpDetails);
    }
  };

  const handleCancel = () => {
    props.setLpData({
      lpDes: "",
      lpName: "",
      moduleId: "",
      lpContent: "",
      lpIcon: "",
      lpShortDes: "",
      id: nanoid(),
      libTypeId: 2,
      isAddTest: false,
      modules: [
        {
          id: 1,
          key: nanoid(),
          status: "new",
          moduleName: "",
          moduleData: [],
        },
      ],
    });
       props.setIsAddLp(false);
    props.setIsOldLpDetail(false);
  };
  // useEffect(() => {
    const handleLpArchive = async () => {
      console.log(lpData2.lpId);
      let status =0;
      if(lpArchived){
        status=0;

      }
      else{
        status=1;

      }
      const response = await axios.put(
        "node/admin/lp/settings/update/" + lpData2.lpId,
        { lpArchived:status },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setLpArchived(!lpArchived);
      console.log("response>>>",response.data);
      
    };

    
  // }, []);

  return (
    <div id="adminAddLpContainer">
      {!props.isAddLpModule ? (
        <div style={{ marginTop: "20px", width: "50%" }}>
          <div className="adminLpInputSection">
            <label className="adminNewLpNameLabel" htmlFor="adminNewLpName">
              Name{" "}
            </label>
            <input
              type="text"
              name="lpName"
              className="adminNewLpName"
              value={newLpDetails.lpName}
              onChange={handleLpDetails}
              maxLength="100"
              id="adminNewLpName"
              placeholder="Enter new path name"
            />
          </div>
          <div className="adminLpShortDesSection adminLpDesSection">
            <label
              className="adminLpShortDesLabel adminLpDesLabel"
              htmlFor="adminLpShortDes"
            >
              Short description{" "}
              <span className="adminOptionalText">(Optional)</span>{" "}
            </label>
            <textarea
              className="adminLpDes"
              name="lpShortDes"
              id="adminLpShortDes"
              value={
                newLpDetails.lpShortDes === undefined
                  ? ""
                  : newLpDetails.lpShortDes === null
                  ? ""
                  : newLpDetails.lpShortDes
              }
              onChange={handleLpDetails}
              placeholder="Enter short description"
              maxLength="300"
            ></textarea>
          </div>
          <div className="adminLpDesSection">
            <label className="adminLpDesLabel" htmlFor="adminLpDes">
              Description <span className="adminOptionalText">(Optional)</span>{" "}
              {/* <span className='adminLpDesMaxChar'>Max character : {maxCharForDes}</span> */}
            </label>
            {/* <ReactQuill type='text' className='adminLpDes' name="lpDes" id='adminLpDes' value={newLpDetails.lpDes} onChange={handleLpDetails} placeholder='Description'  maxLength="300"/> */}
            <textarea
              className="adminLpDes"
              name="lpDes"
              id="adminLpDes"
              value={
                newLpDetails.lpDes === undefined
                  ? ""
                  : newLpDetails.lpDes === null
                  ? ""
                  : newLpDetails.lpDes
              }
              onChange={handleLpDetails}
              placeholder="Enter long description"
              // maxLength="300"
            ></textarea>
          </div>
          <div className="adminLpIconSection">
            <label className="adminLpIconLabel" htmlFor="adminLpIcon">
              Thumbnail
            </label>
            <input
              accept="image/*"
              // className={classes.input}
              // value={newLpDetails.iconData}
              id="adminLpIcon"
              name="lpIcon"
              type="file"
              onChange={handleLpDetails}
            />
            {newLpDetails.lpIcon&&newLpDetails.lpIcon !== "" && (
              <img
                className="adminLpIconPreview"
                alt="icon"
                src={newLpDetails.lpIcon}
              />
            )}
          </div>
          <div className="adminLpContentSection adminLpDesSection">
            <label
              className="adminLpContentLabel adminLpDesLabel"
              htmlFor="adminLpContent"
            >
              Content
            </label>
            <textarea
              ref={lpContentRef}
              className="adminLpContent"
              name="lpContent"
              id="adminLpContent"
              style={{ height, minHeight: "100px" }}
              value={
                newLpDetails.lpContent === undefined
                  ? ""
                  : newLpDetails.lpContent === null
                  ? ""
                  : newLpDetails.lpContent
              }
              onChange={handleLpContent}
              placeholder="Enter table of content using single hyphen for topic and double hyphen for sub topic ex:&#10;-Topic1&#10;--SubTopic1.1&#10;--SubTopic1.2&#10;-Topic2&#10;--SubTopic2.1"
            ></textarea>
          </div>
          {!props.isFromBatch && props.isOldLpDetail&&<div className="archivebatchswitch-div">
            <Form.Check
              type="switch"
              className="archivebatchswitch"
              id="archive-batch-switch"
              name="archived"
              checked={Boolean(lpArchived)}
              onChange={handleLpArchive}
              style={{ boxShadow: "none" }}
            />
            Archive
          </div>}
          <div className="adminLpBtnSec">
            {/* <button className="adminLpCancelBtn" onClick={handleCancel}>Cancel</button>
					<button className='adminLpNextBtn' onClick={handleClickNext}>Next</button> */}
            {!props.isFromBatch && (
              <div className="adminTestSecondaryBtn">
                <ButtonMedium
                  btnText2="Cancel"
                  secondaryBtn={true}
                  onHideSecondary={handleCancel}
                />
              </div>
            )}
            <div>
              <ButtonMedium
                btnText="Next"
                primaryBtn={true}
                onHide={handleClickNext}
              />
            </div>
          </div>
        </div>
      ) : (
        <Modules
          setIsAddLp={props.setIsAddLp}
          setIsAddLpModule={props.setIsAddLpModule}
          newLpDetails={newLpDetails}
          setLpData={setLpData2}
          lpData={lpData2}
          setNewLpDetails={setNewLpDetails}
          show={props.show}
          setShow={props.setShow}
          msg={props.msg}
          setMsg={props.setMsg}
          title={props.title}
          setTitle={props.setTitle}
          isLoaded={props.isLoaded}
          setIsLoaded={props.setIsLoaded}
          isUnAuth={props.isUnAuth}
          setIsUnAuth={props.setIsUnAuth}
          setIsOldLpDetail={props.setIsOldLpDetail}
          isOldLpDetail={props.isOldLpDetail}
          setModuleExpandKey={props.setModuleExpandKey}
          moduleExpandKey={props.moduleExpandKey}
          quereport={props.quereport}
          isFromBatch={props.isFromBatch}
          setisqueReport={props.setisqueReport}
          setTableShowStatus={props.setTableShowStatus}
          setIsFromLp={props.setIsFromLp}
        />
      )}
    </div>
  );
};

export default NewLearningPath;
