import React, { useState } from "react";
import { Autocomplete, Chip, FormHelperText, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import InputGroupField from "../../CommonComponents/InputGroupField";

const ProjectForm = ({ detail, setDetail }) => {
  const [focusStates, setFocusStates] = useState(false);

  return (
    <form className="projectFormContainer">
      <TextField
        label="Project Title"
        id="projectFormTitle"
        className="projectFormField"
        name="title"
        placeholder="Project Title"
        variant="outlined"
        size="small"
        value={detail.title}
        onChange={(event) =>
          setDetail({
            ...detail,
            [event.target.name]: event.target.value,
          })
        }
        InputLabelProps={{
          shrink: focusStates || Boolean(detail["title"]),
        }}
        InputProps={{
          onFocus: (event) => setFocusStates(true),
          onBlur: (event) => setFocusStates(false),
        }}
        fullWidth
      />
      <div className="projectFormDateSec">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Start Date"
            className="projectFormField"
            id="projectFormStartdate"
            name="startdate"
            value={dayjs(detail.startddate)}
            minDate={dayjs("1900-01-01")}
            maxDate={dayjs()}
            onChange={(value) => setDetail({ ...detail, startddate: value })}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="End Date"
            className="projectFormField"
            id="projectFormEnddate"
            name="enddate"
            value={dayjs(detail.enddate)}
            minDate={dayjs(detail.startddate)}
            maxDate={dayjs()}
            onChange={(value) => setDetail({ ...detail, enddate: value })}
          />
        </LocalizationProvider>
      </div>
      <Autocomplete
        multiple
        freeSolo
        id="free-solo-autocomplete"
        options={[]}
        componentname="technologies"
        value={detail.technologies}
        onChange={(event, newValue) =>
          setDetail({
            ...detail,
            technologies: newValue,
          })
        }
        size="small"
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            InputLabelProps={{ shrink: true }}
            {...params}
            label="Technologies"
            placeholder="Press enter to add multiple technologies"
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              key={index}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
      />
      <div>
        <TextField
          InputLabelProps={{ shrink: true }}
          id="projectFormExplaination"
          className="projectFormField"
          name="explaination"
          multiline
          placeholder="Explain your Project in 250 characters"
          variant="outlined"
          inputProps={{ maxLength: 250 }}
          size="small"
          value={detail.explaination}
          onChange={(event) =>
            setDetail({
              ...detail,
              [event.target.name]: event.target.value,
            })
          }
          fullWidth
        />
        <FormHelperText id="projectFormExplainationHText">
          Describe your Project in 250 characters or less.
        </FormHelperText>
      </div>
      <div>
        <TextField
          id="projectFormContribution"
          className="projectFormField"
          name="contribution"
          multiline
          placeholder="What was your contribution to the Project?"
          variant="outlined"
          size="small"
          inputProps={{ maxLength: 250 }}
          value={detail.contribution}
          onChange={(event) =>
            setDetail({
              ...detail,
              [event.target.name]: event.target.value,
            })
          }
          fullWidth
        />
        <FormHelperText id="projectFormContributionHText">
          Maximum 250 characters
        </FormHelperText>
      </div>
      <div>
        <TextField
          InputLabelProps={{ shrink: true }}
          id="projectFormLearn"
          className="projectFormField"
          name="learn"
          placeholder="What did you learn from this Project?"
          variant="outlined"
          size="small"
          multiline
          value={detail.learn}
          inputProps={{ maxLength: 250 }}
          onChange={(event) =>
            setDetail({
              ...detail,
              [event.target.name]: event.target.value,
            })
          }
          fullWidth
        />
        <FormHelperText id="projectFormLearnHText">
          Maximum 250 characters
        </FormHelperText>
      </div>
      <InputGroupField
        disabled={false}
        title="Project Explanation"
        groupText="http://"
        placeholder="www.gradious.com"
        name="projectLink"
        value={detail.projectLink}
        onChange={(event) =>
          setDetail({
            ...detail,
            [event.target.name]: event.target.value,
          })
        }
      />
      <InputGroupField
        disabled={false}
        title="Github Link"
        groupText="http://"
        placeholder="www.gradious.com"
        name="githubLink"
        value={detail.githubLink}
        onChange={(event) =>
          setDetail({
            ...detail,
            [event.target.name]: event.target.value,
          })
        }
      />
      <InputGroupField
        disabled={false}
        title="Demo URL"
        groupText="http://"
        placeholder="www.gradious.com"
        name="hostLink"
        value={detail.hostLink}
        onChange={(event) =>
          setDetail({
            ...detail,
            [event.target.name]: event.target.value,
          })
        }
      />
    </form>
  );
};

export default ProjectForm;
