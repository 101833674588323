import { useState } from "react";

function useAddGrade() {
  const [score, setScore] = useState(0);
  const [PHtext, setPHtext] = useState("0");
  const [scoreValid, setScoreValid] = useState(true);
  const [showReject, setShowReject] = useState(false);
  const [slideScore, setSlideScore] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  // const [scoreChanged, setScoreChanged] = useState(false);
  const [extensionDate, setExtensionDate] = useState();
  const [minExtenDate,setMinExtenDate] = useState()
  const [minExtenDateValue,setMinExtenDateValue] = useState(true)

  const changeScore = (event) => {
    // if (event.target.value == "") {
    //     setScoreValid(false)
    //     // setPHtext(props.question.actualscore)
    // }

    if (isNaN(event.target.value) || event.target.value === "") {
      // setscorevalid(false);
      setScore("0");
    } else {
      setScore(Number(event.target.value));
      // if (
      //     Number(event.target.value) >= 0 &&
      //     Number(event.target.value) <= props.contentData.marks
      // ) {
      //     // props.setIsScore(true);
      //     if (Number(event.target.value) != props.contentData.actualscore) {
      //         setScoreChanged(true);
      //     } else {
      //         setScoreChanged(false);
      //     }
      //     // setScoreValid(true);
      // } else {
      //     // props.setIsScore(false);
      //     // setScoreChanged(true);
      //     setScoreValid(false);
      // }
    }
  };

  const toggleDrawerScore = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setSlideScore(!slideScore);
  };

  return {
    score,
    PHtext,
    setScore,
    setPHtext,
    showReject,
    scoreValid,
    slideScore,
    changeScore,
    showApprove,
    setSlideScore,
    setShowReject,
    setScoreValid,
    extensionDate,
    setShowApprove,
    setExtensionDate,
    toggleDrawerScore,
    minExtenDate,
    setMinExtenDate,
    minExtenDateValue,
    setMinExtenDateValue,
  };
}

export default useAddGrade;
