import { useContext } from "react";
import constants from "../constants";
import BreadCrumbsHooks from "./BreadCrumbsHooks";
import StudentListHooks from "./StudentListHooks";
import AlertContext from "../context/AlertContext";
import MultiLevelDropDownHooks from "./MultiLevelDropDownHooks";
import StudentTestAdminHooks from "./StudentTestPage/StudentTestAdminHooks";

export default function ReportHooks() {
  const Alert = useContext(AlertContext);

  const { getStudentList } = StudentListHooks();
  const { handleMcq } = StudentTestAdminHooks();
  const { getCurrentPageDetails } = BreadCrumbsHooks();
  const { getDataForBreadCrumbsData } = MultiLevelDropDownHooks();

  const capitalizeFirstLetterOfEachWord = (str) => {
    if (str !== null) {
      return str
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    }
  };

  const handleOpenTestOrAssignment = (row, name) => {
    let data = {
      moduleId: row.mid,
      type: row.libtype,
      testId: row.testid,
      status: row.status,
      topicId: row.topicid,
      testName: row.testname,
      testMode: row.testmode,
      studentName: row.lname,
      moduleName: row.modName,
      learnerId: row.learnerid,
      topicName: row.topicname,
      lpConfigId: row.lpconfigid,
      learnerDueDate: row.duedate,
      testDueDate: row.testduedate,
      lpId: row.apiattributes.lpid,
      testConfigId: row.testConfigId,
      questionNo: row.apiattributes.testqnno,
      questionId: row.apiattributes.questionid,
    };

    Alert.setIsLoaded(true);
    Alert.setShowBreadCrumbsData(data);
    getDataForBreadCrumbsData(data);

    if (
      data.type === constants.libTypeIds.ASSIGNMENT ||
      data.type === constants.libTypeIds.CODING_CHALLENGE
    ) {
      getCurrentPageDetails(data);
    } else {
      getStudentList(data);

      // Alert.handleMcq(
      //   data.testId,
      //   data.lpId,
      //   data.lpConfigId,
      //   data.status,
      //   data.learnerId,
      //   0,
      //   constants.PROGRESS_STATUS.REATTEMPT,
      //   null,
      //   [],
      //   true,
      //   data,
      //   data.type
      // );
      handleMcq(
        data.testId,
        data.lpId,
        data.lpConfigId,
        data.status,
        data.learnerId,
        0,
        constants.PROGRESS_STATUS.REATTEMPT,
        null,
        [],
        true,
        data,
        data.type
      );
    }
  };

  return { handleOpenTestOrAssignment, capitalizeFirstLetterOfEachWord };
}
