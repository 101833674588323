import React, { useContext } from "react";
import constants from "../../../constants";
import { getPreSignedUrl } from "../../../util";
import TestDueHooks from "../../../Hooks/TestDueHooks";
import WarningSvgIcon from "../../../Svg/WarningSvgIcon";
import AlertContext from "../../../context/AlertContext";
import TestDueDate from "../../CommonComponents/TestDueDate";

export default function AssignmentSubmissionTable() {
  const {
    status,
    isAdmin,
    testData,
    approveData,
    learnerNotes,
    learnerVideoLink,
    submissionFiles,
    assignmentTestData,
  } = useContext(AlertContext);
  console.log("submissionFiles------>>>>>",learnerVideoLink);
  const { currentDueLabel } = TestDueHooks();

  const handleDownloadReferenceFileForLearner = (fileLink, fileName) => {
    let anchorElement = document.createElement("a");

    anchorElement.download = fileName;
    anchorElement.href = fileLink;
    anchorElement.setAttribute("target", "_blank");
    anchorElement.click();

    // window.URL.revokeObjectURL(anchorElement.href);
  };

  const handleDownloadLearnerSubmissionFiles = async (filePath) => {
    let URL = "",
      fileName = filePath.substring(filePath.lastIndexOf("/") + 1);

    if (process.env.REACT_APP_DEPLOYMENT === "LOCAL")
      URL = process.env.REACT_APP_NODE_API + filePath;
    else {
      let response = await getPreSignedUrl(filePath);

      URL = response;
    }

    handleDownloadReferenceFileForLearner(URL, fileName);
  };

  return (
    <div className="assignmentSubmissionMainContainer">
      <div className="assignmentSubmissionTable">
        <div className="assignmentSubmissionTableHeader">
          <div className="assignmentSubmissionTableHeaderField">Score</div>
          <div
            className={
              !assignmentTestData.hasOwnProperty("template")
                ? "assignmentSubmissionTableHeaderField"
                : "assignmentSubmissionTableHeaderLastField"
            }
          >
            Last modified
          </div>
          {submissionFiles.length > 0 && submissionFiles[0].link != null && !assignmentTestData.hasOwnProperty("template") && (
            <div className="assignmentSubmissionTableHeaderField">
              Submitted File
            </div>
          )}
          {(learnerVideoLink?.trim() != "" || learnerVideoLink != null || learnerVideoLink != "null") && !assignmentTestData.hasOwnProperty("template") && (
            <div className="assignmentSubmissionTableHeaderField">
              Submitted Link
            </div>
          )}
          {!assignmentTestData.hasOwnProperty("template") && (
            <div className="assignmentSubmissionTableHeaderLastField">
              Notes
            </div>
          )}
        </div>
        <div className="assignmentSubmissionTableBody">
          <div className="assignmentSubmissionTableBodyValue">
            {approveData.hasOwnProperty("actualscore") &&
            approveData.actualscore !== null &&
            approveData.actualscore !== undefined
              ? approveData?.actualscore + " "
              : "- "}
            / {approveData.marks}
          </div>
          <div
            className={
              !assignmentTestData.hasOwnProperty("template")
                ? "assignmentSubmissionTableBodyValue"
                : "assignmentSubmissionTableBodyLastValue"
            }
          >
            {submissionFiles?.length >  0 || learnerVideoLink ? (
              <TestDueDate component="assignmentSubmission" />
            ) : (
              "-"
            )}
          </div>
          {submissionFiles.length > 0 && submissionFiles[0].link != null && !assignmentTestData.hasOwnProperty("template") && (
            <div className="assignmentSubmissionTableBodyValue">
              { submissionFiles.length > 0 &&
                submissionFiles?.map(
                  (file) =>
                    file.fileStatus === "old" &&
                    (file.hasOwnProperty("file") ? (
                      <>
                        {file.file.name}{" "}
                        {isAdmin && (
                          <svg
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                            className="assignmentSubmissionStatusDownloadIcon"
                            onClick={() =>
                              handleDownloadLearnerSubmissionFiles(file.link)
                            }
                          >
                            <path
                              fill="#0671E0"
                              d="M14.75 9.5C14.5511 9.5 14.3603 9.57902 14.2197 9.71967C14.079 9.86032 14 10.0511 14 10.25V13.25C14 13.4489 13.921 13.6397 13.7803 13.7803C13.6397 13.921 13.4489 14 13.25 14H2.75C2.55109 14 2.36032 13.921 2.21967 13.7803C2.07902 13.6397 2 13.4489 2 13.25V10.25C2 10.0511 1.92098 9.86032 1.78033 9.71967C1.63968 9.57902 1.44891 9.5 1.25 9.5C1.05109 9.5 0.860322 9.57902 0.71967 9.71967C0.579018 9.86032 0.5 10.0511 0.5 10.25V13.25C0.5 13.8467 0.737053 14.419 1.15901 14.841C1.58097 15.2629 2.15326 15.5 2.75 15.5H13.25C13.8467 15.5 14.419 15.2629 14.841 14.841C15.2629 14.419 15.5 13.8467 15.5 13.25V10.25C15.5 10.0511 15.421 9.86032 15.2803 9.71967C15.1397 9.57902 14.9489 9.5 14.75 9.5ZM7.4675 10.7825C7.53883 10.8508 7.62294 10.9043 7.715 10.94C7.80478 10.9797 7.90185 11.0002 8 11.0002C8.09815 11.0002 8.19522 10.9797 8.285 10.94C8.37706 10.9043 8.46117 10.8508 8.5325 10.7825L11.5325 7.7825C11.6737 7.64127 11.7531 7.44973 11.7531 7.25C11.7531 7.05027 11.6737 6.85873 11.5325 6.7175C11.3913 6.57627 11.1997 6.49693 11 6.49693C10.8003 6.49693 10.6087 6.57627 10.4675 6.7175L8.75 8.4425V1.25C8.75 1.05109 8.67098 0.860322 8.53033 0.71967C8.38968 0.579018 8.19891 0.5 8 0.5C7.80109 0.5 7.61032 0.579018 7.46967 0.71967C7.32902 0.860322 7.25 1.05109 7.25 1.25V8.4425L5.5325 6.7175C5.46257 6.64757 5.37955 6.5921 5.28819 6.55426C5.19682 6.51641 5.09889 6.49693 5 6.49693C4.90111 6.49693 4.80318 6.51641 4.71181 6.55426C4.62045 6.5921 4.53743 6.64757 4.4675 6.7175C4.39757 6.78743 4.3421 6.87045 4.30426 6.96181C4.26641 7.05318 4.24693 7.15111 4.24693 7.25C4.24693 7.34889 4.26641 7.44682 4.30426 7.53819C4.3421 7.62955 4.39757 7.71257 4.4675 7.7825L7.4675 10.7825Z"
                            />
                          </svg>
                        )}
                      </>
                    ) : (
                      <>
                        {file.name}{" "}
                        {isAdmin && (
                          <svg
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                            className="assignmentSubmissionStatusDownloadIcon"
                            onClick={() =>
                              handleDownloadLearnerSubmissionFiles(file.link)
                            }
                          >
                            <path
                              fill="#0671E0"
                              d="M14.75 9.5C14.5511 9.5 14.3603 9.57902 14.2197 9.71967C14.079 9.86032 14 10.0511 14 10.25V13.25C14 13.4489 13.921 13.6397 13.7803 13.7803C13.6397 13.921 13.4489 14 13.25 14H2.75C2.55109 14 2.36032 13.921 2.21967 13.7803C2.07902 13.6397 2 13.4489 2 13.25V10.25C2 10.0511 1.92098 9.86032 1.78033 9.71967C1.63968 9.57902 1.44891 9.5 1.25 9.5C1.05109 9.5 0.860322 9.57902 0.71967 9.71967C0.579018 9.86032 0.5 10.0511 0.5 10.25V13.25C0.5 13.8467 0.737053 14.419 1.15901 14.841C1.58097 15.2629 2.15326 15.5 2.75 15.5H13.25C13.8467 15.5 14.419 15.2629 14.841 14.841C15.2629 14.419 15.5 13.8467 15.5 13.25V10.25C15.5 10.0511 15.421 9.86032 15.2803 9.71967C15.1397 9.57902 14.9489 9.5 14.75 9.5ZM7.4675 10.7825C7.53883 10.8508 7.62294 10.9043 7.715 10.94C7.80478 10.9797 7.90185 11.0002 8 11.0002C8.09815 11.0002 8.19522 10.9797 8.285 10.94C8.37706 10.9043 8.46117 10.8508 8.5325 10.7825L11.5325 7.7825C11.6737 7.64127 11.7531 7.44973 11.7531 7.25C11.7531 7.05027 11.6737 6.85873 11.5325 6.7175C11.3913 6.57627 11.1997 6.49693 11 6.49693C10.8003 6.49693 10.6087 6.57627 10.4675 6.7175L8.75 8.4425V1.25C8.75 1.05109 8.67098 0.860322 8.53033 0.71967C8.38968 0.579018 8.19891 0.5 8 0.5C7.80109 0.5 7.61032 0.579018 7.46967 0.71967C7.32902 0.860322 7.25 1.05109 7.25 1.25V8.4425L5.5325 6.7175C5.46257 6.64757 5.37955 6.5921 5.28819 6.55426C5.19682 6.51641 5.09889 6.49693 5 6.49693C4.90111 6.49693 4.80318 6.51641 4.71181 6.55426C4.62045 6.5921 4.53743 6.64757 4.4675 6.7175C4.39757 6.78743 4.3421 6.87045 4.30426 6.96181C4.26641 7.05318 4.24693 7.15111 4.24693 7.25C4.24693 7.34889 4.26641 7.44682 4.30426 7.53819C4.3421 7.62955 4.39757 7.71257 4.4675 7.7825L7.4675 10.7825Z"
                            />
                          </svg>
                        )}
                      </>
                    ))
                )}
            </div>
          )}
          { ((learnerVideoLink?.trim() != "" || learnerVideoLink != null  || learnerVideoLink != "null") && !assignmentTestData.hasOwnProperty("template")) && (
            <div className="assignmentSubmissionTableBodyValue">
              <a
                href={learnerVideoLink === "" ? "#" : learnerVideoLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {learnerVideoLink}
              </a>
              
            </div>
          )}
          {!assignmentTestData.hasOwnProperty("template") && (
            <div className="assignmentSubmissionTableBodyLastValue">
              {learnerNotes === "" ? "-" : learnerNotes}
            </div>
          )}
        </div>
      </div>
      {!isAdmin &&
        currentDueLabel(testData.duedate) !== "Past Due" &&
        status !== constants.PROGRESS_STATUS.EVALUATED && (
          <div className="assignmentSubmissionTableFooter">
            <WarningSvgIcon component="assignmentSubmission" /> You can edit
            your submission till your due date
          </div>
        )}
    </div>
  );
}
