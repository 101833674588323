import { nanoid } from "nanoid";
import constants from "../../constants";
import { useContext, useEffect } from "react";
import AlertContext from "../../context/AlertContext";

export default function CodingPageDetailsHooks() {
  const Alert = useContext(AlertContext);

  let details = JSON.parse(JSON.stringify(Alert.assignQnDetailsForLearner));

  useEffect(() => {
    if (Alert.assignQnDetailsForLearner.length !== 0) {
      const handleAssignDetailsToState = () => {
        Alert.setTimeLimit(details?.timelimit);
        Alert.setCodeDetails(details !== null ? details : {});
        Alert.setLanguage(
          Alert.status === constants.QUESTION_STATUS.ASSIGNED ||
            Alert.status === constants.QUESTION_STATUS.CONTINUE ||
            Alert.status === constants.QUESTION_STATUS.RESUBMIT
            ? details?.hasOwnProperty("templatecode") &&
              details.templatecode !== null &&
              details.templatecode.length > 0 &&
              details.templatecode !== undefined
              ? Alert.status === constants.QUESTION_STATUS.CONTINUE
                ? JSON.parse(details.templatecode).map((language) => ({
                    value: language.langid,
                    label: language.langname,
                  }))
                : details.templatecode.map((language) => ({
                    value: language.langid,
                    label: language.langname,
                  }))
              : []
            : [{ value: details.langid, label: details.langname }]
        );

        Alert.setSelectedLanguage(
          Alert.status === constants.QUESTION_STATUS.ASSIGNED ||
            (!Alert.isAdmin &&
              Alert.status === constants.QUESTION_STATUS.RESUBMIT)
            ? details?.hasOwnProperty("templatecode") &&
                details.templatecode !== null &&
                details.templatecode.length > 0 &&
                details.templatecode !== undefined && {
                  value: details.templatecode[0].langid,
                  label: details.templatecode[0].langname,
                }
            : { value: details.langid, label: details.langname }
        );

        let testCases =
          details?.hasOwnProperty("testcases") &&
          details.testcases !== null &&
          details.testcases !== undefined
            ? JSON.parse(details.testcases)
            : [];

        if (!Alert.isAdmin) {
          testCases = testCases.filter((example) => example.isexample === 1);
        }

        for (let testCase = 0; testCase < testCases.length; testCase++) {
          if (testCase === 0) testCases[testCase].isActive = true;
          testCases[testCase].isSystemTestCase = true;
          testCases[testCase].id = nanoid();
        }

        Alert.testCasesRef.current = testCases;

        Alert.isInputVariable.current =
          Object.values(details).length !== 0 &&
          details.hasOwnProperty("inputvars") &&
          details.inputvars !== "" &&
          details.inputvars !== null &&
          details.inputvars !== undefined &&
          true;

        Alert.setInputVariable(
          Object.values(details).length !== 0 &&
            details.hasOwnProperty("inputvars") &&
            details.inputvars !== null &&
            details.inputvars !== undefined
            ? details.inputvars.split(",")
            : details.testcases !== "[]" &&
                details.testcases !== null &&
                JSON.parse(details.testcases)[0].input.split("\n")
        );

        Alert.solutionLanguageId.current =
          Object.values(details).length !== 0 &&
          details.solutionlangids?.split(",")[0];

        Alert.setInputVariable(
          details.hasOwnProperty("inputvars") &&
            details.inputvars !== null &&
            details.inputvars !== undefined
            ? details.inputvars.split(",")
            : details.testcases !== "[]" &&
                details.testcases !== null &&
                JSON.parse(details.testcases)[0].input.split("\n")
        );

        Alert.setLearnerCode(
          Alert.status === constants.QUESTION_STATUS.ASSIGNED ||
            (!Alert.isAdmin &&
              Alert.status === constants.QUESTION_STATUS.RESUBMIT)
            ? details?.hasOwnProperty("templatecode")
              ? details.templatecode[0].userCode
              : ""
            : details.answer !== null
            ? details.answer
            : ""
        );

        Alert.setCommentsData(
          Alert.codeDetails?.hasOwnProperty("commentsdata")
            ? Alert.codeDetails.commentsdata
            : []
        );

        Alert.setScore(Alert.isAdmin ? details.actualscore : 0);
        Alert.setMakeRunTestCase(true);

        /* update testcase */

        Alert.setApproveData({
          qnId: details.qnid,
          marks: details.marks,
          testId: details.testid,
          actualscore: details.actualscore,
        });

        if (!Alert.isAdmin) {
          if (
            details.status === constants.QUESTION_STATUS.SUBMITTED ||
            details.status === constants.QUESTION_STATUS.TO_BE_EVALUATED
          ) {
            Alert.setBreadCrumbsData(details.breadCrumbsData);
          }

          let testCases =
            details?.hasOwnProperty("testcases") &&
            details.testcases !== null &&
            details.testcases !== undefined
              ? JSON.parse(details.testcases)
              : [];

          testCases = testCases.filter((example) => example.isexample === 1);

          for (let i = 0; i < testCases.length; i++) {
            if (i === 0) testCases[i].isActive = true;
            testCases[i].id = nanoid();
          }

          Alert.testCasesRef.current = testCases;
        }

        if (Alert.isAdmin) {
          Alert.setRejectData({
            duration: details.duration,
            timeleft: details.timeleft,
            topicName: details.topicName,
            isFixedQn: details.isFixedQn,
            complexityid: details.complexityid,
            replacedQnTimes: details.replacedQnTimes,
            remainingQnLibrary: details.remainingQnLibrary,
          });

          Alert.callAllTestCases.current = true;

          if (details?.hasOwnProperty("commentsdata").length !== 0) {
            Alert.setCommentsData(details.commentsdata);
          }
        }
      };

      handleAssignDetailsToState();
    }
  }, [Alert.assignQnDetailsForLearner]);
}
