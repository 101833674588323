import Button from "../../Button";
import React, { useContext } from "react";
import constants from "../../../constants";
import TestDueHooks from "../../../Hooks/TestDueHooks";
import AlertContext from "../../../context/AlertContext";
import TestDueDate from "../../CommonComponents/TestDueDate";
import McqAndDescHooks from "../../../Hooks/McqAndDescHooks";
import RightArrowSvgIcon from "../../../Svg/RightArrowSvgIcon";
import WarningNoticeHooks from "../../../Hooks/WarningNoticeHooks";
import WarningNoticeSvgIcon from "../../../Svg/WarningNoticeSvgIcon";
import CompleteSvgIcon from "../../CommonComponents/CompleteSvgIcon";
import WarningNoticeBodySvgIcon from "../../../Svg/WarningNoticeBodySvgIcon";

export default function WarningNotice() {
  const { status, testData, buttonDisable } = useContext(AlertContext);

  const { viewTest } = McqAndDescHooks();
  const { currentDueLabel } = TestDueHooks();
  const { goToNextTest } = WarningNoticeHooks();

  const currentTestDue = (type, name) => {
    if (type === constants.libTypeIds.MCQ) {
      return name === "name"
        ? "MCQ"
        : name === "test"
        ? "MCQ test"
        : "MCQ Assessment Past Due";
    } else if (type === constants.libTypeIds.DESCRIPTIVE) {
      return name === "name"
        ? "Descriptive"
        : name === "test"
        ? "Descriptive test"
        : "Descriptive Assessment Past Due";
    } else if (type === constants.libTypeIds.CODING_CHALLENGE) {
      return name === "name"
        ? "Coding"
        : name === "test"
        ? "Coding test"
        : "Coding Assessment Past Due";
    } else {
      return name === "name" || name === "test"
        ? "Assignment"
        : "Assignment Past Due";
    }
  };

  return (
    <div
      className={
        status !== constants.PROGRESS_STATUS.EVALUATED &&
        currentDueLabel(testData.duedate) === "Past Due" &&
        status !== constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
        status !== constants.PROGRESS_STATUS.PARTIAL_EVALUATED
          ? "warningNoticeForPastDue"
          : status === constants.PROGRESS_STATUS.QN_CONTINUE
          ? "warningNoticeForResume"
          : status === constants.PROGRESS_STATUS.REATTEMPT
          ? "warningNoticeForRetake"
          : status === constants.PROGRESS_STATUS.IN_PROGRESS
          ? "warningNoticeForConductTrainer"
          : "warningNoticeSubmission"
      }
    >
      {status === constants.PROGRESS_STATUS.EVALUATED ||
      status === constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
      status === constants.PROGRESS_STATUS.PARTIAL_EVALUATED ? (
        <CompleteSvgIcon />
      ) : (
        <WarningNoticeSvgIcon />
      )}
      <div className="warningNoticeInfo">
        <p className="warningNoticeInfoHeader">
          {status !== constants.PROGRESS_STATUS.EVALUATED &&
          currentDueLabel(testData.duedate) === "Past Due" &&
          status !== constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
          status !== constants.PROGRESS_STATUS.PARTIAL_EVALUATED
            ? currentTestDue(
                testData.libtype !== null ? testData.libtype : testData.testtype
              )
            : status === constants.PROGRESS_STATUS.IN_PROGRESS
            ? "Contact Trainer"
            : status === constants.PROGRESS_STATUS.QN_CONTINUE
            ? "The test had a problem, you can resume it."
            : status === constants.PROGRESS_STATUS.REATTEMPT
            ? "The trainer has asked you to retake the test"
            : (status === constants.PROGRESS_STATUS.EVALUATED ||
                status === constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
                status === constants.PROGRESS_STATUS.PARTIAL_EVALUATED) && (
                <div>
                  Completed
                  <p className="warningNoticeInfoHeaderSubText">
                    You've completed this test, you can go to the next available
                    test/chapter.
                  </p>
                </div>
              )}
        </p>
        <div className="warningNoticeInfoBody">
          {status !== constants.PROGRESS_STATUS.EVALUATED &&
          currentDueLabel(testData.duedate) === "Past Due" &&
          status !== constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
          status !== constants.PROGRESS_STATUS.PARTIAL_EVALUATED ? (
            <>
              The due date for the{" "}
              {currentTestDue(
                testData.libtype !== null
                  ? testData.libtype
                  : testData.testtype,
                "name"
              )}{" "}
              has crossed. Request your trainer to extend the due date.
              <div className="warningNoticeInfoBodyDivText">
                If your trainer extends, you can complete the{" "}
                {currentTestDue(
                  testData.libtype !== null
                    ? testData.libtype
                    : testData.testtype,
                  "test"
                )}
                .
              </div>
            </>
          ) : status === constants.PROGRESS_STATUS.QN_CONTINUE ? (
            <>
              There has been an issue in your test. This can be for multiple
              reasons. You can talk to our team for the exact issue.
              <div className="warningNoticeInfoBodyDivText">
                You will be allowed to take the test from where you left.
              </div>
            </>
          ) : status === constants.PROGRESS_STATUS.REATTEMPT ? (
            <>
              You need to take the test again as the trainer has asked to. This
              can be for multiple reasons. You can talk to our team for the
              exact issue.
              <div className="warningNoticeInfoBodyDivText">
                Your older attempt will be deleted and this new attempt will be
                considered.
              </div>
            </>
          ) : status === constants.PROGRESS_STATUS.IN_PROGRESS ? (
            <>
              We encountered an issue in your test. It can be for multiple
              reasons:
              <ul className="warningNoticeInfoBodyUnorderedList">
                <li>You left the test page by mistake.</li>
                <li>You were glancing away from this screen.</li>
                <li>
                  We couldn't proctor you because your face wasn't visible.
                </li>
              </ul>
              <div className="warningNoticeInfoBodyDivText">
                You can talk to our team for the exact issue. If your trainer
                extends, you can complete the test.
              </div>
            </>
          ) : (
            (status === constants.PROGRESS_STATUS.EVALUATED ||
              status === constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
              status === constants.PROGRESS_STATUS.PARTIAL_EVALUATED) && (
              <div className="submissionDetail">
                <div className="submissionDetailHeader">
                  <div className="submissionDetailHeaderField">
                    Status
                    <span className="submissionDetailHeaderFieldColon">:</span>
                  </div>
                  {testData.viewtest ===
                    constants.DISPLAY_TEST_RESULT.SCORE && (
                    <div className="submissionDetailHeaderSubField">
                      Score
                      <span className="submissionDetailHeaderFieldColon">
                        :
                      </span>
                    </div>
                  )}
                  <div className="submissionDetailHeaderSubField">
                    Submission Date
                    <span className="submissionDetailHeaderFieldColon">:</span>
                  </div>
                </div>
                <div className="submissionDetailValue">
                  {testData.viewtest !== constants.DISPLAY_TEST_RESULT.SCORE
                    ? "Submitted"
                    : "Evaluated"}
                  {testData.viewtest ===
                    constants.DISPLAY_TEST_RESULT.SCORE && (
                    <div className="submissionDetailFieldValue">
                      {testData.actualscore}/{testData.configdata.marks}
                    </div>
                  )}
                  <div className="submissionDetailFieldValue">
                    <TestDueDate component="warningNotice" />
                  </div>
                  {(testData.viewtest ===
                    constants.DISPLAY_TEST_RESULT.SUBMISSION ||
                    testData.viewtest ===
                      constants.DISPLAY_TEST_RESULT.ALL) && (
                    <div className="submissionDetailValueButton">
                      <Button
                        size={"sm"}
                        onClick={viewTest}
                        disable={buttonDisable}
                        hierarchy={{
                          type: "secondaryGrey",
                          buttonText: "View Answers",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
      {status !== constants.PROGRESS_STATUS.EVALUATED &&
      status !== constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
      status !== constants.PROGRESS_STATUS.PARTIAL_EVALUATED ? (
        <WarningNoticeBodySvgIcon />
      ) : (
        <div className="warningNoticeSubmissionButton">
          <Button
            size={"sm"}
            onClick={goToNextTest}
            icon={{ type: "trailing", icon: <RightArrowSvgIcon /> }}
            hierarchy={{
              type: "primary",
              buttonText: "Next  ",
            }}
          />
        </div>
      )}
    </div>
  );
}
