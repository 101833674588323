import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from '@mui/material/CircularProgress';

export default function BackdropCounter(Props) {
        const [count, setCount] = useState(5);
        const [show, setShow] = useState(true);
      
        useEffect(() => {
          const interval = setInterval(() => {
            setCount(count - 1);
          }, 1000);
          if (count === 0) {
            clearInterval(interval);
            setShow(false);
            Props.setShowTimer(false);
            Props.setshowtransition(false);
          }
          return () => clearInterval(interval);
        }, [count]);

        useEffect(()=>{
          setShow(true)
        },[])
      
        return (
            <div className="backdrop-container">
            {show && (
                <Backdrop
                    sx={{ backgroundColor: "rgba(0, 0, 0, 0)", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={show}
                     className="custom-backdrop"
                >
                    <div className="countdownSec" >
                    <CircularProgress
                        variant="determinate"
                        thickness={3}
                        value={(count / 5) * 100} // Adjust the value to match the countdown
                        size={120} // Adjust size as needed
                        sx={{ color: "#F55533" }} // Color of the CircularProgress
                        />
                    <div className="countdownCount">{count}</div>
                    </div>
                </Backdrop>
            )}
                </div>
        );
      };