import React, { useRef, useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { getPreSignedUrl } from "../../../util";
import PdfViewer from "../../CommonComponents/PdfViewer";

function FileUpload({ url }) {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const assignmentReferenceLinkRef = useRef([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    const fetchUrl = async () => {
      const response = await getPreSignedUrl(url);
      setPdfUrl(response);
      setLoading(false);
    };
    fetchUrl();
  }, [url]);

  useEffect(() => {
    const handleExitFullscreen = () => {
      if (!document.fullscreenElement) {
        setIsFullscreen(false);
      }
    };

    document.addEventListener("fullscreenchange", handleExitFullscreen);

    return () => {
      document.removeEventListener("fullscreenchange", handleExitFullscreen);
    };
  }, []);

  if (loading) {
    return <h1>Loading...</h1>;
  }

  const handleRequestFullscreenMode = (index) => {
    setLoading(true);
    const iframeRef = assignmentReferenceLinkRef.current[index];
    iframeRef.requestFullscreen();
    setIsFullscreen(true);
  };

  return (
    <div className={isFullscreen ? "pdfWrapperfullscreen" : "pdfWrapper"}>
      {loading && isFullscreen && (
        <div className="pdfWrapperfullscreen">
          <h1>Loading...</h1>
        </div>
      )}
      <PdfViewer file={pdfUrl} />
    </div>
  );
}

export default class InstantPdfTool {
  constructor({ data, config, api }) {
    this.api = api;
    this.config = config;
    this.data = {
      url: data?.url || "",
    };

    this.CSS = {
      wrapper: "pdf",
    };

    this.nodes = {
      holder: null,
    };
  }

  static get toolbox() {
    return {
      title: "Pdf",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="28" viewBox="0 0 18 28" fill="none">
        <path d="M10.75 0.875H0.25V27.125H17.75V7.875L10.75 0.875ZM16.875 26.25H1.125V1.75H9.875V8.75H16.875V26.25Z" fill="#373739" fill-opacity="0.7"/>
      </svg>`,
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  render() {
    this.wrapper = document.createElement("div");
    this.wrapper.classList.add("instant-pdf-tool");

    if (this.data.url) {
      const rootnode = document.createElement("div");
      rootnode.classList.add("instant-pdf-tool");
      createRoot(rootnode).render(<FileUpload url={this.data.url} />);
      this.wrapper.appendChild(rootnode);
    } else {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.title = "Click to upload PDF";
      fileInput.addEventListener("change", () =>
        this.handleImageSelection(fileInput.files[0])
      );
      this.wrapper.appendChild(fileInput);
    }

    return this.wrapper;
  }

  handleImageSelection(file) {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const pdfUrl = event.target.result;
      this.data.file = file;
      const url = URL.createObjectURL(file);
      this.api.blocks.update(this.data);
      this.displayPdf(pdfUrl, url);
      this.data.url = "library/content/" + file.name;
    };
    reader.readAsDataURL(file);
  }

  displayPdf(pdfUrl, url) {
    const pdfElement = document.createElement("iframe");
    pdfElement.src = url;
    pdfElement.width = "80%";
    pdfElement.height = "400px";
    pdfElement.classList.add("uploaded-pdf");

    this.wrapper.innerHTML = "";
    this.wrapper.appendChild(pdfElement);
  }

  save() {
    return {
      url: this.data.url,
      file: this.data.file,
    };
  }
}
