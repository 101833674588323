import React, { useEffect, useState, useContext, useCallback } from "react";
// import "antd/dist/antd.css";
import { useNavigate } from "react-router-dom";
import Button from "../../Button";
// import dragula from "dragula";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import update from "immutability-helper";
// import "dragula/dist/dragula.css";
import { HTML5Backend } from "react-dnd-html5-backend";
import { nanoid } from "nanoid";
import AlertContext from "../../../context/AlertContext";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faEdit,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Input, Table } from "antd";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import WidgetConfiguration from "./WidgetConfiguration";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import CommonBtn from "../../CommonComponents/CommonBtn";
import ModuleHooks from "../../../Hooks/ModuleHooks";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import { getDataFromStorage } from "../../../util";
import Popup from "../../CommonComponents/Popup";
import constants from "../../../constants";
const WidgetOptions = [
  { label: "ATTENDANCE", value: 1 },
  { label: "LEADERBOARD", value: 2 },
  { label: "PERCENTILE", value: 3 },
  { label: "ASSIGNMENT STATUS", value: 4 },
  { label: "SCORES", value: 5 },
];

const type = "DraggableRow"; // unique identifier for dnd

const DraggableRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isDragging }, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.5 : 1
    }),
  });

  const [, drop] = useDrop({
    accept: type,
    hover: (item, monitor) => {
      if (item.index !== index) {
        console.log("HIII")
        moveRow(item.index, index);
        item.index = index;
      }
    },
  });

  drag(drop(ref));

  return (
    <tr
      ref={ref}
      className={className}
      style={{ ...style, opacity: isDragging ? 0.5 : 1 }}
      {...restProps}
    />
  );
};

const components = {
  body: {
    row: DraggableRow,
  },
};

const DashboardForm = () => {
  const navigate = useNavigate();
  const {
    isCommonPopup,
    currentEditDashboardMetaData,
    setCurrentEditDashboardMetaData,
    isDashBoardEdit,
    setIsDashBoardEdit,
    setIsUnAuth
  } = useContext(AlertContext);
  const { handlePopupStates, clearPopupStates } = ModuleHooks();
  const axios = useAxiosPrivate();

  const [dashboardName, setDashboardName] = useState(
    isDashBoardEdit ? currentEditDashboardMetaData?.boardDetails[0]?.name : ""
  );
  const [dashboardDescription, setDashboardDescription] = useState(
    isDashBoardEdit
      ? currentEditDashboardMetaData?.boardDetails[0]?.description
      : ""
  );

  const [selectedWidgets, setSelectedWidgets] = useState(
    isDashBoardEdit
      ? currentEditDashboardMetaData?.widgets?.map((i) => {
          return {
            widgetname: i.name,
            wtype: i.widget_id,
            orderno: i.orderno,
            wid: i.id,
          };
        })
      : []
  );
  const [currentWidget, setCurrentWidget] = useState(0);
  const [editedWidget, setEditedWidget] = useState();
  const [viewers, setViewers] = useState(
    isDashBoardEdit
      ? currentEditDashboardMetaData.boardUser.data.map((i) => {
          return { label: i.userName, value: i.userId };
        })
      : []
  );
  const [showConfigPopUp, setShowConfigPopUp] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [saveTriggered, setSaveTriggered] = useState(false);
  const [updateTriggered, setUpdateTriggered] = useState(false);
  const [dataOfCurrentWidget, setDataofCurrentWidget] = useState({});
  const [dashBoardId, setDashboardId] = useState(
    isDashBoardEdit ? currentEditDashboardMetaData?.boardDetails[0]?.id : 0
  );
  const [adminList, setAdminList] = useState([]);

  const [status, setStatus] = useState(
    isDashBoardEdit
      ? currentEditDashboardMetaData?.boardDetails[0]?.status
      : null
  );

  const [widgetSaveDisable, setWidgetSaveDisable] = useState(true);

  const handleCloseResponsePopup = () => {
    clearPopupStates();
    setIsDashBoardEdit(false);
    navigate("/dashboards");
  };

  const handleWarningResponsePopupClose = () => {
    clearPopupStates();
  };

  


  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = selectedWidgets[dragIndex];
      const updatedWidgets = update(selectedWidgets, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      }).map((widget, index) => ({
        ...widget,
        orderno: index + 1, // Update orderno based on new position
      }));

      setSelectedWidgets(updatedWidgets);
    },
    [selectedWidgets]
  );

  const handleResponse = (title, msg) => {
    let popupdata = {};
    popupdata.showpopup = true;
    popupdata.title = title;
    popupdata.bodydesc = msg;
    popupdata.singlebtn = true;
    popupdata.singlebtntxt = "Ok";
    popupdata.singlebtnsize = "small";
    popupdata.singlebtnfunt =
      title == "Success"
        ? handleCloseResponsePopup
        : handleWarningResponsePopupClose;
    handlePopupStates(popupdata);
  };

  const hanldeViewersChange = (e) => {
    setViewers(e);
  };

  const handleWidgetChange = (selectedOptions) => {
    setCurrentWidget(selectedOptions);
  };

  const handleShowPopUp = () => {
    // let popupdata = {};
    // popupdata.showpopup = true;
    // popupdata.title = `Widget Configuration - ${currentWidget.label} `;
    // popupdata.closebtn = true;
    // popupdata.iscomponent = true;
    // popupdata.component = (
    //   <WidgetConfiguration
    //     id="scroll-dialog-description"
    //     type={isEdit ? editedWidget.value : currentWidget.value}
    //     isEdit={isEdit}
    //     setShowConfigPopUp={setShowConfigPopUp}
    //     setSelectedWidgets={setSelectedWidgets}
    //     selectedWidgets={selectedWidgets}
    //     updateTriggered={updateTriggered}
    //     setUpdateTriFggered={setUpdateTriggered}
    //     saveTriggered={saveTriggered}
    //     setSaveTriggered={setSaveTriggered}
    //     // setWidgetData={setWidgetData}
    //     // widgetData={widgetData}
    //     setDataofCurrentWidget={setDataofCurrentWidget}
    //     dataOfCurrentWidget={dataOfCurrentWidget}
    //     dashBoardId={dashBoardId}
    //     widgetSaveDisable={widgetSaveDisable}
    //     setWidgetSaveDisable={setWidgetSaveDisable}
    //   />
    // );
    // popupdata.singlebtnsize = "small";
    // popupdata.primbtntxt = "Save";
    // popupdata.secbtntxt = "Cancel";
    // popupdata.primbtnfunt = handleSaveWidget;
    // popupdata.secbtnfunt = handleClose;

    setShowConfigPopUp(true);
  };

  const handleClose = () => {
    setShowConfigPopUp(false);
    setIsEdit(false);
    //clearPopupStates();
  };

  const handleUpdateDashboard = async (status) => {
    try {
      console.log("selectedWidgets", selectedWidgets);
      let widgets = selectedWidgets.map((i, ind) => {
        return { id: i.wid, name: i.widgetname, orderno: ind + 1 };
      });
      let name = dashboardName;
      let description = dashboardDescription;
      let userIds = viewers.map((i) => i.value);
      const upadtePayload = {
        name,
        description,
        userIds,
        widgets,
      };
      if (dashBoardId != null) {
        const response = await axios.put(
          "node/admin/dashboard/" + dashBoardId,
          upadtePayload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (status !== constants.BOARD_STATUS.PUBLISHED) {
          setStatus(constants.BOARD_STATUS.DRAFT);
        }
        console.log("response.data", response.data);
        if (response.data.resultCode == constants.RESULT_STATUS.SUCCESS) {
          handleResponse("Success", response.data.msg);
        } else {
          handleResponse("Warning", response.data.msg);
        }
      }
    } catch (err) {
      if (err.message.includes("403")) {
        handleResponse("Error", "You have been logged-out due to inactivity. Login again.");
        setIsUnAuth(true);
      } else {
        handleResponse("Error", err.message);
      }
    }
  };

  const handlePublishDashboard = async () => {
    try {
      const response = await axios.patch(
        "node/admin/dashboard/" + dashBoardId + "/status",
        { status: constants.BOARD_STATUS.PUBLISHED },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setStatus(2);
      if (response.data.resultCode == constants.RESULT_STATUS.SUCCESS) {
        handleResponse("Success", response.data.msg);
      } else {
        handleResponse("Warning", response.data.msg);
      }
    } catch (err) {
      if (err.message.includes("403")) {
        handleResponse("Error", "You have been logged-out due to inactivity. Login again.");
        setIsUnAuth(true);
      } else {
        handleResponse("Error", err.message);
      }
    }
  };

  const handleSaveDashboard = async () => {
    try {
      let dashBoardData = {};
      dashBoardData.name = dashboardName;
      dashBoardData.description = dashboardDescription;
      dashBoardData.viewers = viewers.map((user) => user.value);
      dashBoardData.userId = getDataFromStorage("learnerid");

      if (dashBoardId == 0 && !dashboardName == "") {
        const response = await axios.post(
          "node/admin/dashboard",
          dashBoardData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setDashboardId(response?.data?.data?.id);
        setStatus(0);
      }
    } catch (err) {
      if (err.message.includes("403")) {
        handleResponse("Error", "You have been logged-out due to inactivity. Login again.");
        setIsUnAuth(true);
      } else {
        handleResponse("Error", err.message);
      }
    }
  };

  const handleDeleteWidget = async (wid) => {
    try {
      let response = await axios.delete("node/admin/dashboard/widget/" + wid, {
        headers: {
          "Content-type": "application/json",
        },
      });
      let widgets = selectedWidgets.filter((i) => i.wid != wid);
      setSelectedWidgets(widgets);
      if (response.data.resultCode == constants.RESULT_STATUS.SUCCESS) {
        handleResponse("DeletedSuccess", response.data.msg);
      } else {
        handleResponse("Warning", response.data.msg);
      }
    } catch (err) {
      if (err.message.includes("403")) {
        handleResponse("Error", "You have been logged-out due to inactivity. Login again.");
        setIsUnAuth(true);
      } else {
        handleResponse("Error", err.message);
      }
    }
  };

  const getIndexInParent = (el) =>
    Array.from(el.parentNode.children).indexOf(el);

  const handleEditSpecificWidget = async (id, type) => {
    try {
      console.log("specificWidget+++++", id, "type---", type);
      let response = await axios.get("node/admin/dashboard/widget/" + id, {
        headers: {
          "Content-type": "application/json",
        },
      });
      console.log("response^^^^^------", response.data);
      setIsEdit(true);
      setDataofCurrentWidget(response.data.data);
      let wid = WidgetOptions.find((i) => i.value == type);
      console.log("slectttt>>>>", wid);
      setEditedWidget(wid);
      setShowConfigPopUp(true);
    } catch (err) {
      if (err.message.includes("403")) {
        handleResponse("Error", "You have been logged-out due to inactivity. Login again.");
        setIsUnAuth(true);
      } else {
        handleResponse("Error", err.message);
      }
    }
  };

  const defaultColumns = [
    {
      width: "300px",
      editable: false,
      title: "Widget Name",
      dataIndex: "widgetname",
    },
    {
      width: "100px",
      title: "Actions",
      dataIndex: "Actions",
      render: (_, record) =>
        selectedWidgets.length >= 1 ? (
          <>
            <FontAwesomeIcon
              style={{ cursor: "pointer", marginRight: "16px" }}
              onClick={() => handleDeleteWidget(record.wid)}
              className="faTrashCan"
              icon={faTrashCan}
            />
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              className="faEdithCan"
              icon={faEdit}
              onClick={() => handleEditSpecificWidget(record.wid, record.wtype)}
            />
          </>
        ) : null,
    },
  ];

  useEffect(() => {
    console.log("selectedWidgets>>>>>", selectedWidgets);
  }, [selectedWidgets]);

  const handleReorder = (dragIndex, draggedIndex) => {
    setSelectedWidgets((oldState) => {
      const newState = [...oldState];
      const item = newState.splice(dragIndex, 1)[0];
      newState.splice(draggedIndex, 0, item);
      return newState;
    });
  };

  // useEffect(() => {
  //   let start;
  //   let end;
  //   const container = document.querySelector(".ant-table-tbody");
  //   const drake = dragula([container], {
  //     moves: (el) => {
  //       start = getIndexInParent(el);
  //       return true;
  //     },
  //   });

  //   drake.on("drop", (el) => {
  //     end = getIndexInParent(el);
  //     handleReorder(start, end);
  //   });
  // }, []);

  const Columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        title: col.title,
        editable: col.editable,
        dataIndex: col.dataIndex,
      }),
    };
  });

  const customStylesForPendingTests = {
    control: (base, state) => ({
      ...base,
      width: "246px",
      height: "20px !important",
      color: "000000",
      textAlign: "left",
      borderRadius: "4px",
      paddingLeft: "10px",
      marginBottom: "-5px",
      backgroundColor: "#ffffff",
      border: "1px solid #BDC1C6",
      innerHeight: "20px !important",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        padding: "8px",
        fontSize: "12px",
        fontWeight: "600",
        textAlign: "left",
        lineHeight: "15px",
        fontFamily: "Inter",
        color: isSelected ? "#000000" : undefined,
        backgroundColor: isSelected
          ? "#ffffff"
          : isFocused
          ? "#e9ecef"
          : undefined,
      };
    },
    singleValue: (styles) => ({
      ...styles,
      color: "#000000",
      fontSize: "12px",
      fontWeight: "600",
      lineHeight: "15px",
      fontFamily: "Inter",
      fontStyle: "normal",
      textAlign: "center",
      width: "max-content",
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "14px",
      color: "#BDC1C6",
      fontWeight: "400",
      lineHeight: "20px",
      fontFamily: "Inter",
      fontStyle: "normal",
    }),
    menuList: (base) => ({
      ...base,
      height: "150px",
    }),
  };

  useEffect(() => {
    try {
      const adminsResponse = async () => {
        let response = await axios.get("node/admin/users", {
          params: {
            role: "admin",
          },
          headers: {
            "Content-type": "application/json",
          },
        });
        console.log("response<<<<>>>>", response.data.data);
        let transformedData = response?.data?.data.map((item) => {
          return {
            label: `${item.firstname} ${item.lastname}`,
            value: item.id,
          };
        });
        setAdminList(transformedData);
        console.log("transformedData*******", transformedData);
      };
      adminsResponse();
    } catch (err) {
      if (err.message.includes("403")) {
        handleResponse("Error", "You have been logged-out due to inactivity. Login again.");
        setIsUnAuth(true);
      } else {
        handleResponse("Error", err.message);
      }
    }
  }, []);

  const handleSaveWidget = () => {
    setSaveTriggered(true);
  };

  const handleUpdateWidget = () => {
    setUpdateTriggered(true);
  };

  const handleBacktoDashboards = () => {
    setIsDashBoardEdit(false);
    navigate("/dashboards");
  };

  return (
    <div className="dashBoardForm">
      <div
        className="dashbaordFormback"
        style={{
          textAlign: "left",
          position: "relative",
          right: "20px",
          marginBottom: "10px",
        }}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="adminTestBackBtn"
          onClick={handleBacktoDashboards}
        />
        &nbsp;
        <span className="adminTestBack" onClick={handleBacktoDashboards}>
          {" "}
          Back{" "}
        </span>
      </div>
      {isCommonPopup && (
        <div className="popup-overlay">
          <Popup />
        </div>
      )}
      <div className="dashBoardNameContainer">
        <label htmlFor="dashboardName" className="dashBoardName">
          Dashboard Name <span style={{ color: "red", width: "4px" }}>*</span> :
        </label>
        <input
          type="text"
          id="dashboardName"
          value={dashboardName}
          onChange={(e) => setDashboardName(e.target.value)}
          onBlur={handleSaveDashboard}
          required
        />
      </div>
      <div className="dashBoardDescriptionContainer">
        <label htmlFor="dashboardDescription" className="dashboarddescription">
          Dashboard Description:
        </label>
        <textarea
          id="dashboardDescription"
          value={dashboardDescription}
          onChange={(e) => setDashboardDescription(e.target.value)}
          required
        />
      </div>
      <div className="selectWidgetContainer">
        <label htmlFor="widgetSelect" className="widgetSelect">
          Shared With:
        </label>
        <div className="selectViewers">
          <Select
            isMulti
            isClearable
            options={adminList}
            placeholder={"Choose Viewers"}
            value={viewers}
            onChange={hanldeViewersChange}
          />
        </div>
        {viewers.length > 0 && (
          <div className="viewersList">
            <ul>
              {viewers.map((i) => (
                <li> {i.label} </li>
              ))}
            </ul>
          </div>
        )}
        <label htmlFor="widgetSelect" className="widgetSelect">
          Select Widgets:
        </label>
        <div className="addAndSelect">
          <Select
            id="widgetSelect"
            options={WidgetOptions}
            value={currentWidget}
            onChange={handleWidgetChange}
            styles={customStylesForPendingTests}
            // isMulti
            required
          />
          {/* <button
            disabled={currentWidget == 0}
            className="btnSmall"
            onClick={handleShowPopUp}
          >
            Add
          </button> */}
          <Button
                size={"sm"}
                onClick={handleShowPopUp}
                hierarchy={{
                  buttonText: "Add",
                  type: "primary",
                }}
                disable={currentWidget == 0}
              />
        </div>

        <div className="tableForWidgets">
          <DndProvider backend={HTML5Backend}>
            <Table
              key={nanoid()}
              bordered={true}
              columns={Columns}
              pagination={false}
              components={components}
              dataSource={selectedWidgets}
              onRow={(record, index) => ({
                index,
                moveRow,
              })}
            />
          </DndProvider>
        </div>
      </div>
      {/* {isCommonPopup && <Popup />} */}
      {showConfigPopUp && (
        <div className="widgetForm1">
          <Dialog
            open={showConfigPopUp}
            onClose={handleClose}
            scroll={"paper"}
            className="widgetForm"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle
              sx={{ m: 0, p: 2 }}
              id="customized-dialog-title"
              style={{ color: "#f55533" }}
            >{`WIDGET CONFIGURATION - ${
              isEdit ? editedWidget.label : currentWidget.label
            } `}</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

            <DialogContent dividers={true}>
              <WidgetConfiguration
                id="scroll-dialog-description"
                type={isEdit ? editedWidget.value : currentWidget.value}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                setShowConfigPopUp={setShowConfigPopUp}
                setSelectedWidgets={setSelectedWidgets}
                selectedWidgets={selectedWidgets}
                saveTriggered={saveTriggered}
                setSaveTriggered={setSaveTriggered}
                updateTriggered={updateTriggered}
                setUpdateTriggered={setUpdateTriggered}
                setDataofCurrentWidget={setDataofCurrentWidget}
                dataOfCurrentWidget={dataOfCurrentWidget}
                dashBoardId={dashBoardId}
                widgetSaveDisable={widgetSaveDisable}
                setWidgetSaveDisable={setWidgetSaveDisable}
              />
            </DialogContent>
            <DialogActions>
              {/* <button
                disabled={widgetSaveDisable}
                className="btnSmall"
                onClick={isEdit ? handleUpdateWidget : handleSaveWidget}
              >
                {isEdit ? "Update" : "Save"}
              </button> */}
               <Button
                size={"sm"}
                onClick={isEdit ? handleUpdateWidget : handleSaveWidget}
                hierarchy={{
                  buttonText: isEdit ? "Update" : "Save" ,
                  type: "primary",
                }}
                disable={widgetSaveDisable}
              />
              {/* <button className="btnSmall" onClick={handleClose}>
                Cancel
              </button> */}

             <Button
                size={"sm"}
                onClick={handleClose}
                hierarchy={{
                  buttonText:  "Cancel" ,
                  type: "secondaryGrey",
                }}
                disable={false}
              />

            </DialogActions>
          </Dialog>
        </div>
      )}
      <div className="dashBoardSave">
        <button
          onClick={() => {
            handleUpdateDashboard(status);
          }}
          className="btnMedium"
          disabled={dashBoardId == 0}
        >
          Save
        </button>
        {dashBoardId != null && status == 1 ? (
          <button
            onClick={() => {
              handlePublishDashboard(status);
            }}
            className="btnMedium"
          >
            Publish
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default DashboardForm;
