import "./LandingPage.scss";
import Home from "../Home/Home";
import Profile from "./Profile";
import Card from "@mui/material/Card";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import constants from "../../../../constants";
import { CardActionArea } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { React, useContext, useRef } from "react";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Batch from "../../../CommonComponents/Batch";
import { getDataFromStorage } from "../../../../util";
import AlertContext from "../../../../context/AlertContext";
import Dashboard from "../../../CommonComponents/Dashboard";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import Notify from "../../../Notification-Loading/Notify/Notify";
import Loading from "../../../Notification-Loading/Loading//Loading";
import useCommonFunctionHooks from "../../../../Hooks/useCommonFunctionHooks";

export default function LandingPage(Props) {
  const { handleUnAuthAlert } = useCommonFunctionHooks();

  let mList;

  let lpList = useRef([]);

  let [learningPath, setLearningPath] = useState([]);
  let [learningPathList, setLearningPathList] = useState([]);

  const {
    msg,
    lpId,
    show,
    title,
    setMsg,
    module,
    setLpId,
    setShow,
    navhome,
    batchId,
    navigate,
    isUnAuth,
    setTitle,
    isLoaded,
    setModule,
    setBatchId,
    setNavhome,
    setIsUnAuth,
    setIsLoaded,
    handleClose,
    registerNotify,
    setCurrentBatch,
    setCamAvailable,
    handleRegistration,
    selectedLearningPath,
    setSelectedLearningPath,
    dashBoardDetails,
    setDashBoardDetails,
    batchName,
    setBatchName,
    fromCodeEditor,
    setFromCodeEditor,
    isTakeTest,
    setIsTakeTest,
    seconds,
    setSeconds,
    moduleList,
    setModuleList,
    isFirstLoggedIn,
    setIsFirstLoggedIn,
    setShowNotify
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();

  const [userbatch, setuserbatch] = useState([]);

  // const [isProfileUpdate, setIsProfileUpdate] = useState(false);

  const navigatehome = (batchid) => {
    setBatchId(batchid);
    setNavhome(true);
  };
  const handleSetLocalStorage = (batch) => {
    localStorage.setItem("batchid", batch.id);
    localStorage.setItem("batchname", batch.name);
    localStorage.setItem("favicon", batch.favicon);
    localStorage.setItem("logo", batch.logo);
  };

  // const handleCloseForProfile = () => {
  //   setShow(false);
  //   profileUpdate();
  // }

  // const profileUpdate = () => {
  //   const request_config = {
  //     headers: {
  //       "Content-type": "application/json",
  //     },
  //   };
  //          axios.post(process.env.REACT_APP_PYTHON_API+"model/update", request_config)
  //          .then((res)=> {
  //           if(res.data.resultCode===1000){
  //                 setIsFirstLoggedIn(false)
  //               }else{
  //                 setShow(true);
  //                 setTitle(<b>Info :-</b>);
  //                 setMsg(res.data.msg);
  //               }
  //          })
  //          .catch((err)=> {
  //          });
  // }

  const learningPathLoad = (e) => {
    setLpId(e.value);
    setSelectedLearningPath(e);

    mList = learningPath
      .filter((learningPath) => learningPath.lpid === e.value)[0]
      .modules.map((module) => module);

    setModuleList(mList);
  };

  useEffect(() => {
    // if (navhome && !isFirstLoggedIn) {
    //   setIsLoaded(true);
    //   axios
    //     .post(
    //       "node/learner/course/get",
    //       JSON.stringify({
    //         batchid: batchId,
    //         learnerid: getDataFromStorage("learnerid"),
    //       }),
    //       {
    //         timeout: 60000,
    //         headers: {
    //           "Content-type": "application/json",
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       if (res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR) {
    //         setIsLoaded(false);
    //         setShow(true);
    //         setTitle("Info");
    //         setMsg(res.data.msg);
    //       } else {
    //         if (res.data.resultCode === constants.RESULT_STATUS.NO_DATA) {
    //           setShow(true);
    //           setTitle("Info");
    //           setMsg("Learningpath is not published");
    //           setIsLoaded(false);
    //         } else {
    //           setCurrentBatch({
    //             id: res.data.data.batchid,
    //             name: res.data.data.batchname,
    //           });
    //           res.data.serverDate = res.headers.date;
    //           setModule(res.data);
    //           setBatchName(res.data.data.batchname)
    //           learningPath = res.data.data.courses;
    //           setLearningPath(res.data.data.courses);
    //           lpList.current = res.data.data.courses.map((course) => ({
    //             value: course.lpid,
    //             label: course.lpname,
    //           }));
    //           setLearningPathList(lpList.current);
    //           if (fromCodeEditor === false) {
    //             learningPathLoad(lpList.current[0]);
    //           } else {
    //             for (let i = 0; i < lpList.current.length; i++) {
    //               if (lpList.current[i].value === lpId) {
    //                 learningPathLoad(lpList.current[i]);
    //               }
    //             }
    //           }
    //           setIsLoaded(false);
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       setIsLoaded(false);
    //       setModule(error.message);
    //       setShow(true);
    //       setTitle("Error");

    //       if (error.message === "timeout of 60000ms exceeded") {
    //         setMsg(
    //           "Something went wrong… program is taking too long to finish. Please, try again"
    //         );
    //       } else if (error.message.includes("403")) {
    //         setIsUnAuth(true);
    //         setMsg("You have been logged-out due to inactivity. Login again.");
    //       } else {
    //         setMsg(error.message);
    //       }
    //     });
    // }

    if (navhome && !isFirstLoggedIn) {
      setIsLoaded(true);
      axios
        .post(
          "node/learner/dashboard",
          JSON.stringify({
            batchId: batchId,
            learnerId: getDataFromStorage("learnerid"),
          }),
          {
            timeout: 60000,
            headers: {
              "Content-type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR) {
            setIsLoaded(false);
            setShow(true);
            setTitle("Info");
            setMsg(res.data.msg);
          } else {
            if (res.data.resultCode === constants.RESULT_STATUS.NO_DATA) {
              setShow(true);
              setTitle("Info");
              setMsg("Learningpath is not published");
              setIsLoaded(false);
            } else {
              setDashBoardDetails(res.data.data);
              localStorage.setItem("batchid", batchId);
              let isTestBatch = res.data.data.batchDetails.some(
                (data) =>
                  data.id == batchId &&
                  data.type == constants.BATCH_TYPE.TESTING
              );
              let issingleLP = res.data.data.lpData.length > 1 ? false : true;
              localStorage.setItem("issinglelp", issingleLP);
              localStorage.setItem(
                "issingleBatch",
                res.data.data.batchDetails.length > 1 ? false : true
              );
              if (isTestBatch) {
                localStorage.setItem("isTestBatch", true);
                if (res.data.data.lpData.length > 1) {
                  navigate("/landingpage");
                } else {
                  localStorage.setItem("lpId", res.data.data.lpData[0].lpId);
                  setLpId(res.data.data.lpData[0].lpId);
                  navigate("/home");
                }
              } else {
                localStorage.setItem("isTestBatch", false);
                navigate("/landingpage");
              }
              setIsLoaded(false);
            }
          }
        })
        .catch((error) => {
          setIsLoaded(false);
          setModule(error.message);
          setShow(true);
          setTitle("Error");

          if (error.message === "timeout of 60000ms exceeded") {
            setMsg(
              "Something went wrong… program is taking too long to finish. Please, try again"
            );
          } else if (error.message.includes("403")) {
            setIsUnAuth(true);
            setMsg("You have been logged-out due to inactivity. Login again.");
          } else {
            setMsg(error.message);
          }
        });
    }

    if (!navhome && !isFirstLoggedIn) {
      setIsLoaded(true);

      const id = getDataFromStorage("learnerid");

      axios
        .get("node/learner/batch/" + id, {
          timeout: 60000,
          headers: {
            "Content-type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
            setIsLoaded(false);
            setShow(true);
            setTitle("Error");
            setMsg(res.data.msg);
          } else {
            if (res.data.data.length === 1) {
              for (let i = 0; i < res.data.data.length; i++) {
                localStorage.setItem("batchname", res.data.data[i]?.name);
                localStorage.setItem("batchid", res.data.data[i]?.id);
                localStorage.setItem("issingleBatch", true);
                if (res.data.data[i].type == constants.BATCH_TYPE.TESTING) {
                  let courseNav =
                    typeof res.data.data[i].courses == "string"
                      ? JSON.parse(res.data.data[i].courses)
                      : res.data.data[i].courses;
                  localStorage.setItem(
                    "issinglelp",
                    courseNav.length > 1 ? false : true
                  );
                  localStorage.setItem("isTestBatch", true);
                  if (courseNav.length > 1) {
                    navigatehome(res.data.data[i].id);
                    handleSetLocalStorage(res.data.data[i]);
                  } else {
                    localStorage.setItem("lpId", courseNav[0].lpId);
                    setLpId(courseNav[0].lpId);
                    navigate("/home");
                    handleSetLocalStorage(res.data.data[i]);
                  }
                } else {
                  localStorage.setItem("isTestBatch", false);
                  navigatehome(res.data.data[i].id);
                  handleSetLocalStorage(res.data.data[i]);
                }
              }
            } else if (res.data.data.length === 0) {
              setShowNotify({
                show: true,
                title: "Warning",
                primaryButtonFunction: handleUnAuthAlert,
                msg: "You doesn't belong to any batch. Please contact Gradious Team."
              });
            } else {
              if (localStorage.getItem("batchid") != null) {
                let batch = res.data.data.filter(
                  (data) => data.id == localStorage.getItem("batchid")
                );

                if (batch && batch.length === 1) {
                  handleSetLocalStorage(batch[0]);
                  navigate(
                    batch[0].type === constants.BATCH_TYPE.TESTING
                      ? "/home"
                      : "/landingpage"
                  );
                } else {
                  setuserbatch(res.data);
                }
                // if(isNavigate){
                //   localStorage.setItem("issingleBatch",false);
                //   navigate("/landingpage")
                // } else {
                //   setuserbatch(res.data);
                //   setIsLoaded(false);
                // }
              } else {
                setuserbatch(res.data);
                setIsLoaded(false);
              }
            }
          }
        })
        .catch((error) => {
          setIsLoaded(false);
          setShow(true);
          setTitle("Error");

          if (error.message === "timeout of 60000ms exceeded") {
            setMsg(
              "Something went wrong… program is taking too long to finish. Please, try again"
            );
          } else if (error.message.includes("403")) {
            setIsUnAuth(true);
            setMsg("You have been logged-out due to inactivity. Login again.");
          } else {
            setMsg(error.message);
          }
        })
        .finally(() => setIsLoaded(false));
    }
  }, [navhome, isFirstLoggedIn]);

  return (
    <div>
      {/* {!navhome && <div style={{ height: "60px" }}></div>} */}
      {isLoaded && <Loading />}
      <>
        {!navhome &&
          !isFirstLoggedIn &&
          userbatch.resultCode === constants.RESULT_STATUS.SUCCESS && (
            // <>
            //   {userbatch.data.map((data) => (
            <Batch
              batch={userbatch}
              navigatehome={navigatehome}
              handleSetLocalStorage={handleSetLocalStorage}
            />
            // <div className="flex-containerLandingPage" key={data.id}>
            //   <Card
            //     key={data.id}
            //     style={{
            //       width: "18rem",
            //       height: "16rem",
            //       cursor: "pointer",
            //       borderRadius: "20px",
            //       background: "#202124",
            //     }}
            //     onClick={() => navigatehome(data.id)}
            //   >
            //     <CardActionArea>
            //       <CardMedia
            //         height="140"
            //         id="batchCard"
            //         component="img"
            //         alt="batch image"
            //         image={data.thumbnail}
            //       />
            //       <CardContent>
            //         <Typography
            //           gutterBottom
            //           variant="h6"
            //           component="div"
            //           className="titleLandingPage"
            //         >
            //           {data.name}
            //         </Typography>
            //         <Typography
            //           variant="body2"
            //           className="descriptionLandingPage"
            //         >
            //           {data.description}
            //         </Typography>
            //       </CardContent>
            //     </CardActionArea>
            //   </Card>
            // </div>
            //   ))}
            // </>
          )}
      </>
      {/* {navhome && !isFirstLoggedIn && Object.keys(dashBoardDetails).length > 0 && (
        <Home
          module={module}
          setModule={setModule}
          setNavhome={setNavhome}
          moduleList={Props.moduleList}
          setTotalCount={Props.setTotalCount}
          learningPathLoad={learningPathLoad}
          learningPathList={learningPathList}
          selectedLearningPath={selectedLearningPath}

          // msg={msg}
          // show={show}
          // title={title}
          // show1={show1}
          // state={state}
          // setMsg={setMsg}
          // status={status}
          // setShow={setShow}
          // setLpId={setLpId}
          // isLoaded={isLoaded}
          // setTitle={setTitle}
          // setShow1={setShow1}
          // setState={setState}
          // isUnAuth={isUnAuth}
          // setStatus={setStatus}
          // handleMcq={handleMcq}
          // register={Props.register}
          // handleClose={handleClose}
          // setIsLoaded={setIsLoaded}
          // setIsUnAuth={setIsUnAuth}
          // camAvailable={camAvailable}
          // registerNotify={registerNotify}
          // setIsTakeTest={Props.setIsTakeTest}
          // handleUnAuthAlert={handleUnAuthAlert}
          // setFromCodeEditor={setFromCodeEditor}
          // setRegisterNotify={setRegisterNotify}
          // handleRegistration={handleRegistration}
        />
        <Dashboard dashBoardDetails={dashBoardDetails} batchName={batchName} lpId={lpId} />
      )} */}

      {isFirstLoggedIn && (
        <Profile
          type="profilepic"
          setMsg={setMsg}
          setShow={setShow}
          seconds={seconds}
          isTakeTest={isTakeTest}
          setIsUnAuth={setIsUnAuth}
          setCamAvailable={setCamAvailable}
          setIsFirstLoggedIn={setIsFirstLoggedIn}
        />
      )}
      {isLoaded && <Loading />}
      <Notify
        show={show}
        title={title}
        message={msg}
        onHide={isUnAuth ? handleUnAuthAlert : handleClose}
      />
      {!registerNotify ? (
        <Notify
          show={show}
          title={title}
          message={msg}
          onHide={isUnAuth ? handleUnAuthAlert : handleClose}
        />
      ) : (
        <Modal
          centered
          show={show}
          onHide={Props.onHide}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {msg}
            <>
              <br />
              <br />
              <b>Note </b> <br />
              <ol>
                <li>Click continue to go to registration page.</li>
                <li>Click cancel to stay in home page.</li>
              </ol>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleClose}
              style={{ background: "white", color: "black", border: "none" }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleRegistration}
              style={{ background: "#F55533", border: "none" }}
            >
              Continue
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
