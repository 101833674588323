import constants from "../constants";
import AngleRightSvg from "../Svg/AngleRightSvg";
import AlertContext from "../context/AlertContext";
import "react-dropdown-tree-select/dist/styles.css";
import React, { useContext, useEffect } from "react";
import BreadCrumbsHooks from "../Hooks/BreadCrumbsHooks";
import StudentListHooks from "../Hooks/StudentListHooks";
import CodingTableHooks from "../Hooks/CodingTableHooks";
import DropdownTreeSelect from "react-dropdown-tree-select";
import MultiLevelDropDownHooks from "../Hooks/MultiLevelDropDownHooks";

export default function MultiLevelDropDown() {
  const {
    isAdmin,
    handleMcq,
    breadCrumbsData,
    breadCrumbsOption,
    showBreadCrumbsData,
    setShowBreadCrumbsData,
  } = useContext(AlertContext);

  const { viewCode } = CodingTableHooks();
  const { getStudentList } = StudentListHooks();

  const { getCurrentPageDetails, getCurrentQuestionIdForCurrentLearner } =
    BreadCrumbsHooks();

  const { generateBreadCrumbsData, generateBreadCrumbsDataForLearnerSide } =
    MultiLevelDropDownHooks();

  const handleChange = (currentNode, selectedNodes) => {
    let showBreadCrumbsData = currentNode.value;

    setShowBreadCrumbsData(showBreadCrumbsData);

    if (isAdmin) {
      if (
        showBreadCrumbsData.type === constants.libTypeIds.ASSIGNMENT ||
        showBreadCrumbsData.type === constants.libTypeIds.CODING_CHALLENGE
      ) {
        getCurrentPageDetails(showBreadCrumbsData);
      } else {
        getStudentList(showBreadCrumbsData);

        handleMcq(
          showBreadCrumbsData.testId,
          showBreadCrumbsData.lpId,
          showBreadCrumbsData.lpConfigId,
          showBreadCrumbsData.status,
          showBreadCrumbsData.learnerId,
          0,
          showBreadCrumbsData.testMode,
          showBreadCrumbsData.configData,
          [],
          true,
          showBreadCrumbsData,
          showBreadCrumbsData.type
        );
      }
    } else {
      viewCode(
        showBreadCrumbsData.test,
        "single",
        showBreadCrumbsData.questions
      );
    }
  };

  useEffect(() => {
    if (isAdmin && breadCrumbsData.length !== 0) {
      generateBreadCrumbsData();

      if (showBreadCrumbsData.component === "studentList") {
        getCurrentQuestionIdForCurrentLearner();
      }
    } else {
      generateBreadCrumbsDataForLearnerSide();
    }
  }, [isAdmin, breadCrumbsData]);

  return (
    <div
      className={
        isAdmin
          ? "multiLevelDropDownContainer"
          : "multiLevelDropDownContainerForLearner"
      }
    >
      <DropdownTreeSelect
        onChange={handleChange}
        data={breadCrumbsOption}
        texts={{
          placeholder: isAdmin
            ? showBreadCrumbsData.moduleName
            : showBreadCrumbsData.topicName,
        }}
      />
      <AngleRightSvg component="codingPageLearnerSideBreadCrumbs" />
      <div className="multiLevelDropDownBreadCrumbsDiv">
        <div>{showBreadCrumbsData.testName}</div>
        {showBreadCrumbsData.type === constants.libTypeIds.CODING_CHALLENGE && (
          <>
            {isAdmin && (
              <>
                <AngleRightSvg component="codingPageLearnerSideBreadCrumbs" />
                <div>{showBreadCrumbsData.topicName}</div>
                <AngleRightSvg component="codingPageLearnerSideBreadCrumbs" />
              </>
            )}
            <div>Question {showBreadCrumbsData.questionNo}</div>
          </>
        )}
      </div>
    </div>
  );
}
