import React, { useContext, useEffect } from 'react';
import { Document, Page, StyleSheet, View, Text, Link, Image } from '@react-pdf/renderer';

//--data
import AlertContext from '../../../../context/AlertContext';
import ResumeHooks from './ResumeHooks';
import VideoSvg from '../../../../Svg/VideoSvg';
import Mail from '../../../../Svg/Resume/Mail';
import PhoneSvg from '../../../../Svg/Resume/PhoneSvg';
import Linkedin from '../../../../Svg/Resume/Linkedin';
import Git from '../../../../Svg/Resume/Git';
import Github from '../../../../Svg/Resume/Github';
import Leetcode from '../../../../Svg/Resume/Leetcode';
import Codechef from '../../../../Svg/Resume/Codechef';
import Hackerrank from '../../../../Svg/Resume/Hackerrank';
import Hackerearth from '../../../../Svg/Resume/Hackerearth';
import { Avatar } from '@mui/material';
import constants from "../../../../constants";
import GlopeSvg from '../../../../Svg/GlopeSvg';
import GrammerSvg from '../../../../Svg/GrammerSvg';
import Coding_R_Svg from '../../../../Svg/Coding_R_Svg';
import Progressbar from '../../../CommonComponents/Progressbar';
import QuotesSvg from '../../../../Svg/QuotesSvg';
import TestSvgIcon from '../../../../Svg/TestSvgIcon';
import moment from "moment";

// Styles
const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      padding: 20,
    },
    header: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      borderRadius: '8px',
      padding: '12px 14px',
      margin: '0 0 24px 0',
      backgroundColor: '#F2F4F7'
    },
    personalDetail: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    nameRole: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 5,
    },
    name: {
      fontFamily: 'Poppins',
      fontSize: 24,
      fontWeight: 600,
      marginRight: '12px',
      color: '#344054',
      lineHeight: '36px'
    },
    role: {
        fontFamily: 'Poppins',
        fontStyle: 'italic',
        fontSize: 20,
        fontWeight: 300,
        color: '#344054',
        lineHeight: '30px'
    },
    socialDetails: {
      fontFamily: 'Poppins',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginTop: 5,
    },
    socialLink: {
      display: 'block',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
      marginRight: 10,
      color: '#344054', // Make sure link color is visible
    },
    icon: {
      width: 16,
      height: 16,
      marginRight: 5,
    },
    avatar: {
        height: '92px',
        width: '92px'
    },
    profileImage: {
      width: 50,
      height: 50,
      borderRadius: '50%',
    },
    
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: 'Poppins',
        fontSize: 12,
        fontWeight: 300,
        lineHeight: '18px',
        color: '#667085',
        padding: '10px',
    },

      // left
      pane: {
        display: "flex",
        flexDirection: "column",
        width: "50%",
        padding: 10,
      },
    paneContainer: {
        display: "flex",
        flexDirection: "row",
        // justifyContent: "space-between",
        width: "100%",
        gap: "24px",
    },
    contentHeader: {
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "18px",
        textAlign: "left",
        color: "#F96343",
      },
      contentBlock: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        marginBottom: '16px',
      },
      contentText: {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontStyle: "italic",
        fontWeight: 400,
        lineHeight: "21px",
        textAlign: "left",
        color: '#344054',
      },
      educationBlock: {
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      },
      educationDetailsContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "6px",
      },
      educationDetailsLine: {
        display: "flex",
        justifyContent: "space-between",
        textAlign: "center",
      },
      schoolName: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textAlign: "left",
        color: "#344054",
        fontFamily: "Poppins",
      },
      schoolDuration: {
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "18px",
        textAlign: "left",
        color: "#667085",
        fontFamily: "Poppins",
      },
      qualification: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        textAlign: "left",
        color: "#667085",
        fontFamily: "Poppins",
      },
      cgpa: {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "21px",
        textAlign: "left",
        color: "#667085",
        fontFamily: "Poppins",
      },
      projectContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        marginBottom: '12px',
      },
      projectHeader: {
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "18px",
        textAlign: "left",
        color: "#F96343",
      },
      projectDetailsSec: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      },
      projectTitleSec: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: '4px',
      },
      projectTitleLeftSec: {
        display: "flex",
        gap: "6px",
        alignItems: "center",
      },
      projectTitle: {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textAlign: "left",
        color: "#344054",
      },
      projectDate: {
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "18px",
        textAlign: "left",
        display: "flex",
        alignItems: "center",
        color: "#667085",
      },
      projectLinks: {
        display: "flex",
        gap: "6px",
        alignItems: "center",
      },
      projectLink: {
        display: "block",
      },
      projectHashTagsSec: {
        display: "flex",
        gap: "8px",
        marginBottom: '4px',
      },
      projectHashTag: {
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: 500,
        fontStyle: "italic",
        lineHeight: "18px",
        textAlign: "left",
        color: "#F96343",
      },
      projectDetails: {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        textAlign: "left",
        color: "#344054",
        display: "block",
      },

    // right_pane_section

    bodyRightPane: {
      width: "37%",
      display: "contents"
    },

    rightPaneTitle: {
      fontSize: 16,
      // lineHeight: 24,
      display: "flex",
      fontWeight: 600,
      textAlign: "left",
      fontFamily: "Poppins",
    },

    wrapGevalBox: {
      gap: "10px",
      display: "flex",
      flexWrap: "wrap",
    },

    gevalBox: {
      flex: 1,
      borderRadius: "8px",
      margin: "12px 0 0 6px",
      border: "1px solid #D0D5DD",
    },

    gevalHeader: {
      height: "40px",
      padding: "8px",
      display: "flex",
      background: "#F2F4F7",
      borderRadius: "8px 8px 0 0",
    },

    headerTitle: {
      gap: "10px",
      display: "flex",
      fontWeight: 600,
      color: "#667085",
      fontSize: 14,
      textAlign: "left",
      lineHeight: "20px",
      fontFamily: "Poppins",
      alignItems: "center",
      justifyContent: "center",
    },
    
    gevalBody: {
      display: "flex",
      fontWeight: 600,
      fontSize: 20,
      color: "#344054",
      lineHeight: "30px",
      fontFamily: "Poppins",
      fontStyle: "italic",
      margin: "13px 7px 7px 0",
      justifyContent: "flex-end",
    },

    skillSection: {
      margin: "28px 0 0 0",
    },

    skillSectionTitle: {
      display: 'flex',
      fontWeight: 600,
      fontSize: "16px",
      color: "#344054",
      textAlign: "left",
      lineHeight: "24px",
      fontFamily: "Poppins",
      marginBottom: '8px',
    },

    skillBox: {
      padding: "8px",
      display: "flex",
      borderRadius: "6px",
      margin: "0 0 20px 0",
      flexDirection: "column",
      border: "1px solid #D0D5DD",
    },

    skillHeader: {
      display: "flex",
      padding: "0 8px",
      margin: "0 0 8px 0",
      justifyContent: "space-between",
    },

    skillName: {
      fontSize: 16,
      fontWeight: 600,
      color: "#101828",
      textAlign: "left",
      lineHeight: "24px",
      fontFamily: "Poppins",
    },

    skillPercentage: {
      fontSize: 16,
      fontWeight: 600,
      color: "#F96343",
      textAlign: "left",
      lineHeight: "24px",
      fontFamily: "Poppins",
      fontStyle: "italic",
    },

    skillTestDetails: {
      padding: "0 8px",
    },

    skillTests: {
      fontSize: 14,
      fontWeight: 400,
      color: "#344054",
      textAlign: "left",
      lineHeight: "20px",
      marginLeft: "10px",
      fontFamily: "Poppins",
    },

    skillRemark: {
      margin: "10px 0 0 0",
    },

    remark: {
      fontSize: 12,
      fontWeight: 600,
      color: "#344054",
      padding: "0 8px",
      lineHeight: "18px",
      fontFamily: "Poppins",
      textAlign: "justify",
    },

    skillEndline: {
      width: "100%",
      display: "block",
      margin: "12px 0 12px 0",
      border: "1px solid #D0D5DD",
    },
    overall: {
        display: 'block',
        borderRadius: '8px',
        padding: '12px 16px',
        backgroundColor: '#FCEBE8',
        marginBottom: '0 0 6px 0',
        padding: '16px',
        borderRadious: '8px',
        position: 'relative',
    },

    quote: {
        position: 'absolute',
        right: '30px',
        top: '-10px',
    },
    remark: {
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 600,
        margin:'0 0 8px 0',
        lineHeight: '24px',
        color:'#344054',
    },
    remarkpoints: {
        fontFamily: 'Poppins',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '20px',
        color:'#344054',
    }

  });

export default function ResumePdf(props) {

    const { isLoaded } = useContext(AlertContext);
    const {resumeData, setResumeData, getResumeData, getlistFromParagraph, getYear, getSkillPercentage, handleProject, getTodayDate, getValue, handleVideoClick} = ResumeHooks();

    useEffect(()=>{
        if(resumeData){
            setResumeData();
            getResumeData(props.from,props.learnerId, props.role);
        } else {
            getResumeData(props.from,props.learnerId, props.role);
        }
    },[props.learnerId]);

    let PersonalDetail = resumeData && typeof resumeData?.personalData?.personalinfo == "string" ? JSON.parse(resumeData?.personalData?.personalinfo) : resumeData?.personalData?.personalinfo;
    let socialprofile = resumeData && typeof resumeData?.personalData?.socialprofile == "string" ? JSON.parse(resumeData?.personalData?.socialprofile) : resumeData?.personalData?.socialprofile;
    let academic = resumeData && typeof resumeData?.personalData?.academic == "string" ? JSON.parse(resumeData?.personalData?.academic) : resumeData?.personalData?.academic;
    let educationDetails = resumeData && typeof resumeData?.personalData?.education == "string"? JSON.parse(resumeData?.personalData?.education) :resumeData?.personalData?.education;

    const order = [
        "pgDetail",
        "ugDetail",
        "class12Detail",
        "class10Detail",
    ];
    const sortedEntries =  resumeData?.personalData?.education && order
                            .filter(key => educationDetails && educationDetails[key]) // Check that educationDetails exists and has the key
                            .map(key => [key, educationDetails[key]]);

    const profileImageBase64 = resumeData?.personalData?.picture;

  return (
    <div>
        <Document>
            <Page size="A4" style={styles.page}>
            {/* Common Header */}
            <View style={styles.header}>
                <View style={styles.personalDetail}>
                    <View style={styles.nameRole}>
                        <Text style={styles.name}>
                        {`${PersonalDetail?.firstName} ${
                            PersonalDetail?.lastName || ''
                        }`}
                        </Text>
                        {PersonalDetail?.intro && (
                        <Link href={PersonalDetail?.intro} style={styles.socialLink}>
                            <Text style={styles.role}><VideoSvg /></Text>
                        </Link>
                        )}
                    </View>
                    {resumeData?.roleTitle && (
                        <Text style={styles.role}>{resumeData?.roleTitle}</Text>
                    )}
                    <View style={styles.socialDetails}>
                    {PersonalDetail?.phone && (
                        <Link href={`https://wa.me/${PersonalDetail?.phone}`} style={styles.socialLink}>
                            <Text><PhoneSvg /></Text> {PersonalDetail?.phone}
                        </Link>
                        )}
                        {PersonalDetail?.email && (
                        <Link href={`mailto:${PersonalDetail?.email}`} style={styles.socialLink}>
                            <Text ><Mail /></Text> {PersonalDetail?.email}
                        </Link>
                    )}
                        {socialprofile?.linkedIn && (
                        <Link href={socialprofile["linkedIn"]} style={styles.socialLink}>
                            <Text style={styles.socialLink}><Linkedin/></Text>
                        </Link>
                        )}
                        {socialprofile?.git && (
                        <Link href={socialprofile["git"]} style={styles.socialLink}>
                            <Text style={styles.socialLink}><Git /></Text>
                        </Link>
                        )}
                        {socialprofile?.github && (
                        <Link href={socialprofile["github"]} style={styles.socialLink}>
                            <Text style={styles.socialLink}><Github /></Text>
                        </Link>
                        )}
                        {socialprofile?.leetCode && (
                        <Link href={socialprofile["leetCode"]} style={styles.socialLink}>
                            <Text style={styles.socialLink}><Leetcode /></Text>
                        </Link>
                        )}
                        {socialprofile?.codeChef && (
                        <Link href={socialprofile["codeChef"]} style={styles.socialLink}>
                            <Text style={styles.socialLink}><Codechef/></Text>
                        </Link>
                        )}
                        {socialprofile?.hackerRank && (
                        <Link href={socialprofile["hackerRank"]} style={styles.socialLink}>
                            <Text style={styles.socialLink}><Hackerrank /></Text>
                        </Link>
                        )}
                        {socialprofile?.hackerEarth && (
                        <Link href={socialprofile["hackerEarth"]} style={styles.socialLink}>
                            <Text style={styles.socialLink}><Hackerearth /></Text>
                        </Link>
                        )}
                    </View>
                </View>
                <View style={styles.avatar}>
                    {/* <Image
                    src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAAoCAYAAAA/Mn0mAAABHklEQVRoge2YsQ3CMBCGPwsM//9KYYOBaWCK6DRcxl2zSiOjV4EL3Q1ktGEWxdd9lA4mf3ZIHlYDDMUA1GiHnMnQ2GjyDk+j99lvW7mN8X7kM3+gcfhP+MsSdyiY2MN4GDBcMlws2CGC8iRgMNyKXMmUb4QgkK7idDBn4CYm02vFO4TClMx3mwbMc9xTGcdyTGHskhxzxyb9BG0whXHzJUzHucdyTKE+jRSzDwMXX8gheAZ4fOYh+B5yDfOZ8mjA5wbMfcsz1YDsGvcMF9dgEM7c3P4SnPQ0IvR8GheAz/z/wqXxu68hWjjKDyFl4/xb+Vdv9NvevjCNr78Rz0ViWYeY5Dfjd8XvOnz2h0D/Q+ELFv0TEXdVqQAAAABJRU5ErkJggg=='}
                    style={styles.profileImage}
                    /> */}
                    <Avatar src={resumeData?.personalData?.picture} style={{ width: "100%", height: "100%", padding: 0 }}/>
                </View>
            </View>

            {/* Left and Right Panes */}
            <View style={styles.paneContainer}>
                <View style={styles.pane}>
                {/* career objective */}
                {PersonalDetail &&
                    PersonalDetail?.careerObj !== "" &&
                    PersonalDetail?.careerObj !== null &&
                    PersonalDetail?.careerObj !== undefined && (
                    <View style={styles.contentBlock}>
                        <Text style={styles.contentHeader}>CAREER OBJECTIVE</Text>
                        <Text style={styles.contentText}>
                        {PersonalDetail?.careerObj}
                        </Text>
                    </View>
                    )}
                    {/* Education */}
              {sortedEntries?.length > 0 && (
                <View style={styles.contentBlock}>
                  <Text style={styles.contentHeader}>EDUCATION</Text>
                  <View style={styles.educationBlock}>
                    {sortedEntries.map(
                      ([key, data]) =>
                        data &&
                        Object.keys(data).length > 0 && (
                          <View style={styles.educationDetailsContainer}>
                            <View style={styles.educationDetailsLine}>
                              <Text style={styles.schoolName}>
                                {data.schoolName}
                              </Text>
                              <Text style={styles.schoolDuration}>
                                {`${
                                  data.startdate
                                    ? getYear(data.startdate, "year")
                                    : ""
                                } - ${
                                  data.enddate
                                    ? getYear(data.enddate, "year")
                                    : ""
                                }`}
                              </Text>
                            </View>

                            <View style={styles.educationDetailsLine}>
                              <Text style={styles.qualification}>
                                {data.branch}
                              </Text>
                              <Text
                                style={styles.cgpa}
                              >{`CGPA : ${data.cgpa}`}</Text>
                            </View>
                          </View>
                        )
                    )}
                  </View>
                </View>
              )}

            {academic
                ?.filter(data => data.id === 1)
                .map(data => {
                    if (data.details && data.details.length > 0) {
                    return (
                <View key={data.id} style={styles.projectContainer}>
                  <Text style={styles.projectHeader}>PROJECTS</Text>
                  {data.details.map(detail => (
                    <View style={styles.projectDetailsSec} key={detail.id}>
                      <View style={styles.projectTitleSec}>
                        <View style={styles.projectTitleLeftSec}>
                          <Text style={styles.projectTitle}>
                            {detail.title}
                          </Text>
                          <View style={styles.projectLinks}>
                            {detail.projectLink && (
                              <Link
                                href={detail.projectLink}
                                style={styles.projectLink}
                              >
                                {" "}
                                <Text>
                                  <VideoSvg />
                                </Text>
                              </Link>
                            )}
                            {detail.githubLink && (
                              <Link
                                href={detail.githubLink}
                                style={styles.projectLink}
                              >
                                <Text>
                                  <TestSvgIcon libType={5} disabled={true} />
                                </Text>
                              </Link>
                            )}
                            {detail.hostLink && (
                              <Link
                                href={detail.hostLink}
                                style={styles.projectLink}
                              >
                                <Text>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_6330_12720)">
                                      <path
                                        d="M6.6668 8.66697C6.9531 9.04972 7.31837 9.36642 7.73783 9.5956C8.1573 9.82477 8.62114 9.96105 9.0979 9.99519C9.57466 10.0293 10.0532 9.96055 10.501 9.79349C10.9489 9.62643 11.3555 9.36502 11.6935 9.02697L13.6935 7.02697C14.3007 6.3983 14.6366 5.55629 14.629 4.6823C14.6215 3.80831 14.2709 2.97227 13.6529 2.35424C13.0348 1.73621 12.1988 1.38565 11.3248 1.37806C10.4508 1.37046 9.60881 1.70644 8.98013 2.31364L7.83347 3.45364M9.33347 7.33364C9.04716 6.95088 8.68189 6.63418 8.26243 6.40501C7.84297 6.17584 7.37913 6.03956 6.90237 6.00541C6.4256 5.97127 5.94708 6.04006 5.49924 6.20711C5.0514 6.37417 4.64472 6.63559 4.3068 6.97364L2.3068 8.97364C1.69961 9.60231 1.36363 10.4443 1.37122 11.3183C1.37881 12.1923 1.72938 13.0283 2.3474 13.6464C2.96543 14.2644 3.80147 14.615 4.67546 14.6226C5.54945 14.6301 6.39146 14.2942 7.02013 13.687L8.16013 12.547"
                                        stroke="#667085"
                                        stroke-width="1.6"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_6330_12720">
                                        <rect
                                          width="16"
                                          height="16"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </Text>
                              </Link>
                            )}
                          </View>
                        </View>
                        {detail?.startddate && (
                          <Text style={styles.projectDate}>
                            {moment(detail.startddate).format("MMM YYYY")}
                          </Text>
                        )}
                      </View>
                      {detail.technologies && (
                        <View style={styles.projectHashTagsSec}>
                          {detail.technologies.map((tech) => (
                            <Text style={styles.projectHashTag}>{tech}</Text>
                          ))}
                        </View>
                      )}
                      <View>
                        <View style={styles.projectDetails}>
                          <Text>{detail.explaination}</Text>
                        </View>
                        <View style={styles.projectDetails}>
                          <Text>{detail.contribution}</Text>
                        </View>
                        <View style={styles.projectDetails}>
                          <Text>{detail.learn}</Text>
                        </View>
                      </View>
                    </View>
                   ))}
                </View>
               );
            }
            return null; // Return null if details are empty
        })}

              {/*  */}

              {academic
                ?.filter((data) => data.id === 4)
                .map((data) => {
                  if (data.details && data.details.length > 0) {
                    return (
                      <View style={styles.contentBlock}>
                        <Text style={styles.contentHeader}>INTERNSHIPS</Text>
                        {data.details.map((detail) => (
                          <View style={styles.educationBlock}>
                            <View style={styles.educationDetailsContainer}>
                              <View style={styles.educationDetailsLine}>
                                <Text style={styles.schoolName}>
                                  {detail.title}
                                </Text>
                                <Text style={styles.schoolDuration}>{`${
                                  data.startdate
                                    ? getYear(data.startdate, "month")
                                    : ""
                                } - ${
                                  data.startdate
                                    ? getYear(data.startdate, "month")
                                    : ""
                                }`}</Text>
                              </View>
                              <View style={styles.educationDetailsLine}>
                                <Text style={styles.qualification}>
                                  {detail.organisation}
                                </Text>
                                <Text style={styles.cgpa}>{detail.city}</Text>
                              </View>
                              <View>
                                <Text>{detail.description}</Text>
                              </View>
                            </View>
                          </View>
                        ))}
                      </View>
                    );
                  }
                  return null;
                })}

              {academic
                ?.filter((data) => data.id === 2)
                .map((data) => {
                  if (data.details && data.details.length > 0) {
                    return (
                      <View style={styles.contentBlock}>
                        <Text style={styles.contentHeader}>CERTIFICATES</Text>
                        {data.details.map((detail) => (
                          <View style={styles.educationBlock}>
                            <View style={styles.educationDetailsContainer}>
                              <View style={styles.educationDetailsLine}>
                                <Text style={styles.schoolName}>
                                  {detail.title}
                                </Text>
                                <Text style={styles.schoolDuration}>
                                  {detail.year}
                                </Text>
                              </View>
                              <View style={styles.educationDetailsLine}>
                                <Text style={styles.qualification}>
                                  {detail.organisation}
                                </Text>
                              </View>
                            </View>
                          </View>
                        ))}
                      </View>
                    );
                  }
                  return null;
                })}

              {academic
                ?.filter((data) => data.id === 3)
                .map((data) => {
                  if (data.details && data.details.length > 0) {
                    return (
                      <View style={styles.contentBlock}>
                        <Text style={styles.contentHeader}>ACHIEVEMENTS</Text>
                        {data.details.map((detail) => (
                          <View style={styles.educationBlock}>
                            <View style={styles.educationDetailsContainer}>
                              <View style={styles.educationDetailsLine}>
                                <Text style={styles.schoolName}>
                                  {detail.title}
                                </Text>
                                <Text style={styles.schoolDuration}>
                                  {detail.year}
                                </Text>
                              </View>
                              <View style={styles.educationDetailsLine}>
                                <Text style={styles.qualification}>
                                  {detail.organisation}
                                </Text>
                              </View>
                            </View>
                          </View>
                        ))}
                      </View>
                    );
                  }
                  return null;
                })}
                </View>
                <View style={styles.pane}>
                <View style={styles.bodyRightPane}>
                <View>
                    {resumeData?.GCAT &&
                      Object.keys(
                        typeof resumeData?.GCAT == "string"
                          ? JSON.parse(resumeData?.GCAT)
                          : resumeData?.GCAT
                      ).length > 0 && (
                        <View>
                          <View style={styles.rightPaneTitle}>
                            GRADIOUS EVALUATION
                          </View>
                          <View style={styles.wrapGevalBox}>
                            {Object.entries(
                              typeof resumeData?.GCAT == "string"
                                ? JSON.parse(resumeData?.GCAT)
                                : resumeData?.GCAT
                            ).map(([skill, data]) => (
                              <View style={styles.gevalBox}>
                                <View style={styles.gevalHeader}>
                                  <View style={styles.headerTitle}>
                                    <Text>
                                      {skill ==
                                      constants.PROFILE_ATTRIBUTES
                                        .APTITUDE_SCORE ? (
                                        <Text> <GlopeSvg /></Text>
                                      ) : skill ==
                                        constants.PROFILE_ATTRIBUTES
                                          .GRAMMAR_SCORE ? (
                                            <Text><GrammerSvg /></Text>
                                      ) : skill ==
                                        constants.PROFILE_ATTRIBUTES
                                          .CODING_SCORE ? (
                                            <Text><Coding_R_Svg /></Text>
                                      ) : (
                                        ""
                                      )}
                                    </Text>
                                    {skill ==
                                    constants.PROFILE_ATTRIBUTES.APTITUDE_SCORE
                                      ? "  Aptitude"
                                      : skill ==
                                        constants.PROFILE_ATTRIBUTES
                                          .GRAMMAR_SCORE
                                      ? "Grammer"
                                      : skill ==
                                        constants.PROFILE_ATTRIBUTES
                                          .CODING_SCORE
                                      ? "Coding"
                                      : ""}
                                  </View>
                                </View>
                                <View style={styles.gevalBody}>
                                  <Text>{`${
                                    data.overrideValue != null &&
                                    data.overrideValue != "" &&
                                    data.overrideValue != undefined
                                      ? data.overrideValue
                                      : data.value != null &&
                                        data.value != undefined &&
                                        data.value != ""
                                      ? data.value
                                      : 0
                                  }%`}</Text>
                                </View>
                                <View>
                                  <Progressbar
                                    from={"resume"}
                                    percentage={
                                      data.overrideValue != null &&
                                      data.overrideValue != "" &&
                                      data.overrideValue != undefined
                                        ? data.overrideValue
                                        : data.value != null &&
                                          data.value != undefined &&
                                          data.value != ""
                                        ? data.value
                                        : 0
                                    }
                                  />
                                </View>
                                
                              </View>
                            ))}
                          </View>
                        </View>
                        
                      )}
                  </View>
                  <View style={styles.skillSection}>
                    {resumeData?.skills &&
                      Object.keys(resumeData?.skills).length > 0 && (
                        <View>
                          <View style={styles.skillSectionTitle}>
                            SKILL LEARNED
                          </View>

                          <View style={styles.skillBox}>
                            {Object.entries(resumeData?.skills).map(
                              ([skillid, skillData], index, arr) => {
                                let hasRelevantData =
                                  skillData.attributes[
                                    constants.PROFILE_ATTRIBUTES
                                      .ASSIGNMENT_SCORE
                                  ] ||
                                  skillData.attributes[
                                    constants.PROFILE_ATTRIBUTES
                                      .ASSESSMENT_SCORE
                                  ];

                                if (hasRelevantData) {
                                  return (
                                    <View>
                                      <View style={styles.skillHeader}>
                                        <View style={styles.skillName}>
                                          {skillData.skillname}{" "}
                                          {
                                            skillData.videoLink && (
                                              <Text
                                                onClick={() =>
                                                  handleVideoClick(
                                                    skillData.videoLink
                                                  )
                                                }
                                              >
                                                <VideoSvg />
                                              </Text>
                                            ) 
                                          }
                                        </View>
                                        <Text style={styles.skillPercentage}>{`${getSkillPercentage(
                                          skillData.attributes
                                        ).toFixed(2)}%`}</Text>
                                      </View>
                                      <View /* style={styles.skillTestDetails} */>
                                        <li style={styles.skillTests}>{`Submitted ${
                                          getValue(
                                            skillData.attributes,
                                            constants.PROFILE_ATTRIBUTES
                                              .ASSIGNMENT_COMPLETED
                                          ) +
                                          "/" +
                                          getValue(
                                            skillData.attributes,
                                            constants.PROFILE_ATTRIBUTES
                                              .ASSIGNMENT_COMPLETED,
                                            "totalCount"
                                          )
                                        } assignments & Scored ${
                                          getValue(
                                            skillData.attributes,
                                            constants.PROFILE_ATTRIBUTES
                                              .ASSIGNMENT_SCORE
                                          ) +
                                          "/" +
                                          getValue(
                                            skillData.attributes,
                                            constants.PROFILE_ATTRIBUTES
                                              .ASSIGNMENT_SCORE,
                                            "targetScore"
                                          )
                                        }.`}</li>
                                        <li style={styles.skillTests}>{`Score ${
                                          getValue(
                                            skillData.attributes,
                                            constants.PROFILE_ATTRIBUTES
                                              .ASSESSMENT_SCORE
                                          ) +
                                          "/" +
                                          getValue(
                                            skillData.attributes,
                                            constants.PROFILE_ATTRIBUTES
                                              .ASSESSMENT_SCORE,
                                            "targetScore"
                                          )
                                        } in all assessments combined.`}</li>
                                      {skillData.attributes[
                                          constants.PROFILE_ATTRIBUTES
                                            .SKILL_FEEDBACK
                                        ] && (
                                            <li style={styles.skillTests}>
                                              {
                                                skillData.attributes[
                                                  constants.PROFILE_ATTRIBUTES
                                                    .SKILL_FEEDBACK
                                                ]
                                              }
                                            </li>
                                        )}
                                      </View>
                                      {index !== arr.length - 1 && (
                                        <View style={styles.skillEndline}></View>
                                      )}
                                    </View>
                                  );
                                }
                                
                                return null;
                              }
                            )}
                          </View>
                        </View>
                      )}
                  </View>
                </View>

                {(resumeData?.overallFeedback !== "" && resumeData?.overallFeedback !== null && resumeData?.overallFeedback !== undefined) && <View style={styles.overall}>
                    <View style={styles.quote} ><QuotesSvg/></View>
                    <View style={styles.remark}>Remark</View>
                        {getlistFromParagraph(resumeData?.overallFeedback).map((data,index)=>(<li style={styles.remarkpoints} key={index} className='overall_remark_points'>{data.trim()}</li>))}
                </View>}

                </View>
            </View>
            <View style={styles.footer}>
                <Text fixed >{"Copyright Gradious Technologies (P) Ltd - 2024"}</Text>
                <Text fixed >Download Date : {getTodayDate()}</Text>
            </View>
            </Page>
        </Document>
    </div>
  )
}
