import React, { createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AlertContext from "./AlertContext";
import useAxiosPrivate from "../Hooks/useAxiosPrivate";
import { getDataFromStorage } from "../util";
import dayjs from "dayjs";
import constants from "../constants";

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const { setShowNotify, setIsLoaded, setUserProfilePic } =
    useContext(AlertContext);
  const axios = useAxiosPrivate();
  const { pathname } = useLocation();

  const [profileDetails, setProfileDetails] = useState({
    personalinfo: {},
    parentinfo: {},
    education: {
      class10Detail: {},
      class12Detail: {},
      ugDetail: {},
      pgDetail: {},
    },
    academic: [
      {
        id: 1,
        title: "Projects",
        btnText: "Add Project",
        details: [],
      },
      {
        id: 2,
        title: "Certificates",
        btnText: "Add certificate",
        details: [],
      },
      {
        id: 3,
        title: "Achievements",
        btnText: "Add achievement",
        details: [],
      },
    ],
    socialprofile: {},
  });
  const [profilePic, setProfilePic] = useState(localStorage.getItem("picture"));

  const navigate = useNavigate();

  const checkDateValidity = (startDate, endDate, message) => {
    if (dayjs(endDate).diff(dayjs(startDate)) < 0) {
      setShowNotify({
        show: true,
        title: "Warning!",
        msg: message,
      });
      return true;
    }
    return false;
  };

  const onSaveProfileDetails = async (isFinish) => {
    const id = getDataFromStorage("learnerid"),
      source = pathname.substring(pathname.lastIndexOf("/") + 1);
    if (source !== "viewresume") {
      if (source === "education") {
        const class10Message = "Check the start date and end date of class 10.";
        const class12Message =
          "Check the start date and end date of class 12/diploma.";
        const ugMessage =
          "Check the start date and end date of undergraduate degree.";
        const pgMessage =
          "Check the start date and end date of postgraduate degree.";

        if (
          checkDateValidity(
            profileDetails.education.class10Detail.startdate,
            profileDetails.education.class10Detail.enddate,
            class10Message
          )
        )
          return;
        if (
          checkDateValidity(
            profileDetails.education.class12Detail.startdate,
            profileDetails.education.class12Detail.enddate,
            class12Message
          )
        )
          return;
        if (
          checkDateValidity(
            profileDetails.education.ugDetail.startdate,
            profileDetails.education.ugDetail.enddate,
            ugMessage
          )
        )
          return;
        if (
          checkDateValidity(
            profileDetails.education.pgDetail.startdate,
            profileDetails.education.pgDetail.enddate,
            pgMessage
          )
        )
          return;
      }
      let payload = {
        data: profileDetails[source],
        userId: id,
      };
      if (source === "personalinfo") payload.picture = profilePic;
      console.log("payload", payload);
      const URL =
        source === "parentinfo"
          ? "node/learner/parent"
          : `node/learner/profile/${id}/${source}`;
      try {
        setIsLoaded(true);
        let response = await axios.post(URL, payload, {
          headers: {
            "Content-type": "application/json",
          },
        });
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          if (source === "personalinfo") setUserProfilePic(profilePic);
          if (source === "socialprofile" && isFinish)
            setShowNotify({
              show: true,
              title: "Info",
              msg: "The profile details are added successfully.",
            });
        }
        if (
          response.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR
        ) {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
        }
      } catch (error) {
        console.log("save api",error)
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
        }
      } finally {
        setIsLoaded(false);
      }
    }
  };

  return (
    <ProfileContext.Provider
      value={{
        profileDetails,
        setProfileDetails,
        profilePic,
        setProfilePic,
        navigate,
        onSaveProfileDetails,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
