import { useContext } from "react";
import useLogout from "./useLogout";
import constants from "../constants";
import AlertContext from "../context/AlertContext";

export default function useCommonFunctionHooks() {
  const {
    setAuth,
    navigate,
    testData,
    setTestData,
    setIsUnAuth,
    setShowNotify,
    setCamAvailable,
    setIsBatchDetails,
    setIsFirstLoggedIn,
  } = useContext(AlertContext);

  const logout = useLogout();

  const handleWebCamError = () => {
    let testData2 = testData;

    testData2.proctored = constants.PROCTORING_MODE.TAB;

    if (testData.mockuse) {
      setTestData(testData2);
    }

    setCamAvailable(false);
  };

  const handleClose = () => {
    setShowNotify({ show: false });
  };

  const handleNavigate = () => {
    if (testData.mockuse) {
      navigate("/mock/library");
    } else {
      navigate("/home");
    }
  };

  const checkBatchType = () => {
    if (localStorage.getItem("isTestBatch") === true) {
      return true;
    } else {
      return false;
    }
  };

  const handleFirstLogin = () => {
    setShowNotify({
      show: true,
      titleSvg: "info",
      msg: "In our effort to improve the user experience, our LMS platform got a new look. Please raise an issue if you face any difficulty.",
      primaryButtonFunction: () => {
        setIsFirstLoggedIn(false);
        setShowNotify({ show: false });
      },
    });
  };

  const handleRegistration = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    setShowNotify({ show: false });
    navigate("/registration");
  };

  const handleUnAuthAlert = async () => {
    setAuth({});
    try {
      await logout();
    } catch (err) {
    } finally {
      setIsUnAuth(false);
      setIsBatchDetails(false);
      setShowNotify({ show: false });
      localStorage.removeItem("tokenKey");
      localStorage.removeItem("refreshToken");
      navigate("/");
      window.location.reload();
    }
  };

  const getHeaderDetailsForAxios = (file) => {
    return {
      timeout: 60000,
      mode: "no-cors",
      headers: {
        "Content-Type":
          file === "multipart" ? "multipart/form-data" : " application/json",
      },
    };
  };

  const getCatchBlockDetails = (error, component) => {
    console.log("error", error);

    if (error.message === "timeout of 60000ms exceeded") {
      setShowNotify({
        show: true,
        title: "Error",
        msg: "Something went wrong. Please try again.",
      });
    } else if (error.message.includes("403")) {
      setShowNotify({
        show: true,
        title: "Error",
        isUnAuth: true,
        msg: "You have been logged-out due to inactivity. Login again.",
      });
    } else if (
      component === "proctor" &&
      error.message === "Cannot read properties of undefined (reading 'length')"
    ) {
      setShowNotify({
        show: false,
      });
    } else {
      setShowNotify({
        show: true,
        msg: error.message,
        title: error.title ? error.title : "Error",
      });
    }
  };

  return {
    handleClose,
    handleNavigate,
    checkBatchType,
    handleFirstLogin,
    handleUnAuthAlert,
    handleWebCamError,
    handleRegistration,
    getCatchBlockDetails,
    getHeaderDetailsForAxios,
  };
}
