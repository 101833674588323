import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { useCallback, useRef,useEffect } from "react";
import "./AttendanceReport.scss";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import MultiRangeSlider from "multi-range-slider-react";
import AlertContext from "../../../../../context/AlertContext";
import {
  faArrowUpFromBracket,
  faFileAlt,
  faTrash,
  faArrowLeft,
  faPlus,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


// import AddIcon from "@mui/icons-material/Add";
// import ClearIcon from "@mui/icons-material/Clear";

export default function AttendanceReport() {
  const navigate = useNavigate();

  const {id} = useParams();

  const { setMsg, setShow, setTitle, setIsUnAuth, sessionDetails,setSessionDetails,setIsLoaded } =
    useContext(AlertContext);
  const axios = useAxiosPrivate()

  if (!sessionDetails) {
    setShow(true);
    setTitle("Warning !");
    setMsg("No session selected");
  }
  const sliderRef = useRef(null);
  const [data, setData] = useState({
    sessiondatda: sessionDetails.name,
    dataANDtime: sessionDetails.startdate,
    organizedby: sessionDetails.organizedBy,
    mode: sessionDetails.mode === 0 ? "Online" : "Offline",
  });

  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState({
    show: false,
    msg: "",
    title: "",
  });

  

  const [selectedOption, setSelectedOption] = useState("csv");

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const [minValue, setMinValue] = useState(25);
  const [maxValue, setMaxValue] = useState(75);
  

  const resethandler = () => {
    console.log("reset the values changed");

    setMinValue(25);
    setMaxValue(75);

    
  };
  
  const handle = (e) => {
    console.log("values changed",e);

    setMinValue(e.minValue);
    setMaxValue(e.maxValue);
  };
  const handleBackBtn = () => {

    navigate("/batches/attendance");
    setSessionDetails({});
 
   }

  const [dragActive, setDragActive] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const inputRef = useRef(null);
  const progressBarRef = useRef(null);
  const initialSliderValues = {
    min: 0,
    max: 100,
    minValue: 25,
    maxValue: 75,
  };
  const [sliderValues, setSliderValues] = useState(initialSliderValues);

  const handleSliderChange = ({ minValue, maxValue }) => {
    console.log("minValue, maxValue",minValue, maxValue);
    setSliderValues({ ...sliderValues, minValue, maxValue });
  };
  const handleReset = () => {
    setSliderValues(initialSliderValues);
  };
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileUpload(e.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleChange = (e) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      handleFileUpload(e.target.files[0]);
    }
  };

  const handleFileUpload = (file) => {
    console.log("file handleFileUpload", file);

    if (
      file.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      file.type !== "text/csv"
    ) {
      alert("Invalid file type. Please upload a .csv or .xlsx file.");

      if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      return;
    }

    setUploadedFile(file);
    setUploadProgress(0);

    const uploadInterval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        const newProgress = prevProgress + 5;

        if (newProgress >= 100) {
          clearInterval(uploadInterval);
        }

        return newProgress;
      });
    }, 50);
  };

  const handleDeleteFile = () => {
    setUploadedFile(null);
    setUploadProgress(0);
  };

  const fileInputRef = useRef();

  let val = sessionDetails.id;
  let createdby = data.sessiondatda;

  const handlePrintFile = async () => {
    setIsLoaded(true);
    setIsModalLoading(true);
    console.log("uploadedFile", uploadedFile);
    const file = uploadedFile;
    // const file = fileInputRef.current.files[0];
    const formData = new FormData();

    formData.append("file", file);
    formData.append("sessionid", val);
    formData.append("minValue", minValue);
    formData.append("maxValue", maxValue);
    formData.append("createdby", createdby);
    formData.append("selectedOption", selectedOption);

    console.log("success upload");

    

    axios({
      method: "post",
      url: "node/admin/attendance/record",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
      .then((response) => {
         setIsLoaded(false);
        if (response.data.resultCode === 2050) {
          
          setShow(true);
          setTitle("Warning !");
          setMsg(response.data.msg);
        } else if (response.status === 200) {
          setShow(true);
          setMsg("Data posted Success");
          setTitle("Success");
          console.log("response.data%%%",response.data);
          if(response.data.fileData !=="ok"){
            const url = window.URL.createObjectURL(
              new Blob([response.data.fileData])
            );
            const link = document.createElement("a");

            link.href = url;
            link.setAttribute("download", `${Date.now()}.csv`);
            document.body.appendChild(link);
            link.click();
            navigate(`/batches/${id}/event`);
          }
          // response.data.notFoundUsers.map((data) => {
          //   console.log(
          //     "user not found : " + data["Name (Original Name)"] + "\n"
          //   );
          // });

          
        } else {
          setShow(true);
          setTitle("Success");
          setMsg("Data posted Success");
        }
        setIsModalLoading(false);
        setSessionDetails({});
      })
      .catch((err) => {
        setIsLoaded(false);
        setIsModalLoading(false);
        if (err.message.includes("403")) {
          setShow(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
          setTitle("Error !");
          setIsUnAuth(true);
        }
        if (err.resultCode === 2050) {
          setIsUnAuth(true);
          setShow(true);
          setMsg(err.msg);
          setTitle("Error !");
        }
        setSessionDetails({});
      })
      .finally(() => {
        setIsModalLoading(false);
        setSessionDetails({});
      });
  };

  const handleSubmit = () => {
    if (uploadProgress === 100) {
      handlePrintFile();
    } else {
    }
  };

  return (
    <>
      <div className="attendanceRecordBack" style={{ textAlign: "left",paddingBottom:"10px" }}>
            <FontAwesomeIcon
              className="adminTestBackBtn"
              onClick={handleBackBtn}
              icon={faArrowLeft}
            />
            &nbsp;
            <span className="adminTestBack" onClick={handleBackBtn}>
              {" "}
              Back{" "}
            </span>
      </div>
      
      <h6 className="attendanceReportSessionName">{data.sessiondatda}</h6>
      <div className="attendanceReportSessionField">
        <div>Org by : {data.organizedby}</div>
        <div>Mode : {data.mode} </div>
        <div>Date & Time : {data.dataANDtime} </div>
        <div>
          Add Attendence
          <select
            value={selectedOption}
            className="selectFileFormat"
            onChange={handleSelectChange}
          >
            {/* <option value="xlsx">xlsx file</option> */}
            <option value="csv">CSV file</option>
          </select>
        </div>
      </div>
      <h6 className="sessionAttendanceConfig">Attendance Configuration</h6>
      <div className="sessionAttendanceConfigInstruction">
        Select the minimum percentage for marking a candidate Absent or
        partially present.
      </div>
      {!uploadedFile && (
        <div className="MultiRangeSlider">
          <MultiRangeSlider
            
            min={0}
            max={100}
            label={false}
            ruler={false}
            
           
            onChange={(e)=>handle(e)}
            barLeftColor="#F55533"
            barInnerColor="#FF9899"
            thumbLeftColor="#F55533"
            barRightColor="#77DD77B2"
            thumbRightColor="#FF9899"
            baseClassName="multi-range-slider-black"
            style={{
              border: "none",
              padding: "0px 5px 30px",
              boxShadow: "none !important",
            }}
          />
       
          {/* <button className="resetAttendanceBtn" onClick={resethandler}>
            Reset
          </button> */}
          <div className="attendanceLabel">
            <li className="li">
              <span
                className="dot"
                style={{ backgroundColor: "#F55533" }}
              ></span>
              &nbsp;&nbsp; Absent
            </li>
            <li className="li">
              {" "}
              <span
                className="dot"
                style={{ backgroundColor: "#FF9899" }}
              ></span>
              &nbsp;&nbsp; Partialy Present
            </li>
            <li className="li">
              <span
                className="dot"
                style={{ backgroundColor: "#77DD77B2" }}
              ></span>
              &nbsp;&nbsp; Present
            </li>
          </div>
        </div>
      )}

      <div className="attendanceUploadWrapper">
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className="attendanceUploadWrapper2"
        >
          <div className="attendanceUploadFileBtnSec">
            <div className="attendanceUploadFileBtn">
              <svg
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2799_38150)">
                  <path
                    fill="white"
                    d="M19 11H13V5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H11V19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19V13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2799_38150">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              {/* <AddIcon id="attendanceUploadFileBtnIcon" /> */}
            </div>
          </div>
          <input
            type="file"
            onChange={handleChange}
            accept=".xlsx, .csv"
            id="attendanceUploadFileInput"
          />
          <Typography id="attendanceUploadDragText">
            Drag and drop your files here{" "}
          </Typography>
          <Typography variant="caption" id="attendanceUploadDragTextCaption">
            Max file size 50 MB
          </Typography>
        </div>
        {uploadedFile && (
          <div className="attendanceFileUploadStatus">
            <button onClick={handleDeleteFile} className="bi">
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.6276 21.2126L18.3849 16.97L22.6276 12.7273C22.8151 12.5398 22.9205 12.2854 22.9205 12.0202C22.9205 11.755 22.8151 11.5006 22.6276 11.3131C22.44 11.1256 22.1857 11.0202 21.9205 11.0202C21.6552 11.0202 21.4009 11.1256 21.2133 11.3131L16.9707 15.5557L12.7281 11.3131C12.5405 11.1256 12.2862 11.0202 12.021 11.0202C11.7557 11.0202 11.5014 11.1256 11.3138 11.3131C11.1263 11.5006 11.021 11.755 11.021 12.0202C11.021 12.2854 11.1263 12.5398 11.3138 12.7273L15.5565 16.97L11.3138 21.2126C11.1263 21.4001 11.021 21.6545 11.021 21.9197C11.021 22.1849 11.1263 22.4393 11.3138 22.6268C11.5014 22.8143 11.7557 22.9197 12.021 22.9197C12.2862 22.9197 12.5405 22.8143 12.7281 22.6268L16.9707 18.3842L21.2133 22.6268C21.4009 22.8143 21.6552 22.9197 21.9205 22.9197C22.1857 22.9197 22.44 22.8143 22.6276 22.6268C22.8151 22.4393 22.9205 22.1849 22.9205 21.9197C22.9205 21.6545 22.8151 21.4001 22.6276 21.2126Z"
                  fill="#373739"
                />
              </svg>

              {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-trash"
              viewBox="0 0 16 16"
            >
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
            </svg> */}
            </button>
            <div className="attendanceFileUploadMain">
              <li className="li">{uploadedFile?.name}</li>
              <li
                className="li"
                style={{ color: "rgba(55, 55, 57, 0.70)", fontWeight: "400" }}
              >
                {(uploadedFile?.size / (1024 * 1024)).toFixed(2)} MB
              </li>
            </div>

            <div>
              <progress
                ref={progressBarRef}
                max="100"
                value={uploadProgress}
              ></progress>
            </div>
          </div>
        )}

        <div className="attendanceFileUploadBtn">
          {uploadProgress === 100 ? (
            <>
              <button
                onClick={handlePrintFile}
                className="attendanceFileUploadSubmitBtn"
              >
                Submit
              </button>{" "}
              &nbsp;
              <button
                className="attendanceFileUploadCancelBtn"
                onClick={handleDeleteFile}
              >
                Cancel
              </button>
            </>
          ) : (
            <button style={{ display: "none" }} onClick={handleSubmit} disabled>
              Uploading...
            </button>
          )}
        </div>
        {/* {props.submissionFiles.length > 0
          ? props.submissionFiles.map(
              (file) =>
                (file.fileStatus === "new" ||
                  file.fileStatus === "old") && (
                  <div
                    className="assignmentSubmissionUploadedFileSec2"
                    key={file.id}
                  >
                    <div className="assignmentSubmissionUploadedFileTitleSec">
                      <div className="assignmentSubmissionUploadedFileDetailSec">
                        <Typography id="assignmentSubmissionUploadedFileName2">
                          {file.hasOwnProperty("file")
                            ? file.file.name
                            : file.name}
                        </Typography>
                        <Typography
                          variant="caption"
                          id="assignmentSubmissionUploadedFileSize"
                        >
                          {file.hasOwnProperty("file") &&
                          file.file.size !== undefined
                            ? (file.file.size / 1e6).toFixed(2) > 1
                              ? `${(file.file.size / 1e6).toFixed(2)} MB`
                              : `${(file.file.size / 1024).toFixed(2)} KB`
                            : ""}
                        </Typography>
                      </div>

                      <ClearIcon
                        id="assignmentSubmissionUploadedFileClearIcon"
                        // onClick={() => handlClearUploadedFile(file.id)}
                      />
                    </div>
                  </div>
                )
            )
          : null} */}
      </div>

      <div>
        {/* <form
          id="form-file-upload"
          onDragEnter={handleDrag}
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            ref={fileInputRef}
            type="file"
            id="input-file-upload"
            multiple={false}
            accept=".xlsx, .csv"
            onChange={handleChange}
          />
          <label
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={dragActive ? "drag-active" : ""}
          >
            <div>
              <div className="outer-circle">
                <div className="inner-circle">
                  <div className="plus-symbol">&#43;</div>
                </div>
              </div>
              <br />
              <b>Drag and drop your file here</b>
              <br />
              <button
                className="upload-button"
                onClick={() => inputRef.current.click()}
              ></button>
              <br />
              <span style={{ color: "rgba(0,0,0,0.5)" }}>
                Max file size 50 MB :*.csv,.xlsx
              </span>
            </div>
          </label>
          {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </form>

        {uploadedFile && (
          <div className="file-uploadstatus">
            <button onClick={handleDeleteFile} className="bi">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
              </svg>
            </button>
            <div className="file-upload-main">
              <li className="li" style={{ color: "blue" }}>
                {uploadedFile.name}
              </li>
              <li className="li">
                {(uploadedFile.size / (1024 * 1024)).toFixed(2)} MB
              </li>
            </div>

            <div>
              <progress
                ref={progressBarRef}
                max="100"
                value={uploadProgress}
              ></progress>
            </div>
          </div>
        )} */}

        {/* <div className="btns">
          {uploadProgress === 100 ? (
            <>
              <button className="btn btn-primary" onClick={handlePrintFile}>
                Submit
              </button>{" "}
              &nbsp;
              <button className="btn btn-secondary" onClick={handleDeleteFile}>
                Cancel
              </button>
            </>
          ) : (
            <button style={{ display: "none" }} onClick={handleSubmit} disabled>
              Uploading...
            </button>
          )}
        </div> */}
      </div>
    </>
  );
}
