import "./AdminHeader.scss";
import moment from "moment";
import Logo from "./Svg/Logo";
import { nanoid } from "nanoid";
import { Typography } from "antd";
import Modal from "react-bootstrap/Modal";
import constants from "../../../constants";
import Button from "react-bootstrap/Button";
import useLogout from "../../../Hooks/useLogout";
import { getDataFromStorage } from "../../../util";
import TestSvgIcon from "../../../Svg/TestSvgIcon";
import React, { useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import MenuDropDown from "../Dashboard/MenuDropDown";
import Overlay from "../../CommonComponents/Overlay";
import AdminHeaderHooks from "./Hooks/AdminHeaderHooks";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import Notify from "../../Notification-Loading/Notify/Notify";
import ProfileAvatar from "../../CommonComponents/ProfileAvatar";

// import bell from "./icon.jpg";
// import Notifications from "./react-notifications-menu";

function AdminHeader(Props) {
  const {
    msg,
    show,
    title,
    setMsg,
    setShow,
    seconds,
    setLists,
    isUnAuth,
    setTitle,
    navigate,
    setNavhome,
    setLpData2,
    setIsAddLp,
    setIsFromLp,
    setIsUnAuth,
    setFromList,
    setFromMenu,
    handleClose,
    setIsLoaded,
    batchDetails,
    setIsFromBatch,
    setBatchDetails,
    setIsAddLpModule,
    setIsOldLpDetail,
    setOldTestConfig,
    handleUnAuthAlert,
    setIsBatchDetails,
    setChangeHeaderValue,
    setFromMenuToEditTest,
    setCurrentPage,
  } = useContext(AlertContext);

  const {
    ref,
    details,
    libType,
    setLibType,
    setIsNotifyOpened,
    clearQuestionNotify,
    setClearQuestionNotify,
    setNotificationDetails,
  } = AdminHeaderHooks();

  const logout = useLogout();
  const axios = useAxiosPrivate();

  const location = useLocation();
  const { pathname } = useLocation();

  const notificationReport = async (row, lid, notificationid, isread) => {
    if (row.tag === "ACTION" && isread === 0) {
      setClearQuestionNotify({
        isClear: true,
        notificationid: notificationid,
        lpid: row.notificationData.lpid,
        testid: row.notificationData.testid,
        topicid: row.notificationData.topicid,
        libType: row.notificationData.libtype,
        sectionid: row.notificationData.secid,
        moduleid: row.notificationData.moduleid,
        learnerid: row.notificationData.learnerid,
        learnerName: `${
          row.notificationData.firstname + " " + row.notificationData.lastname
        }`,
      });
    } else if (row.tag === "INFO" /* &&isread===0 */) {
      await handleMarkAllAsRead(notificationid, true);

      // Props.viewUserSubmittedTestQnFromNotification(row.notificationData, pathname)
    }

    //  Props.notificationReport(row, lid, true, true);
    else
      document.querySelector(".notifications .container").style.visibility =
        "hidden";
  };

  const handleMarkAllAsRead = (notificationId, istrue) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "node/learner/notification/read",
          istrue
            ? JSON.stringify({
                notificationid: notificationId,
                userid: getDataFromStorage("learnerid"),
              })
            : JSON.stringify({ userid: getDataFromStorage("learnerid") }),
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          let jsonArr = [],
            count = 0;

          for (let i = 0; i < details.current.length; i++) {
            if (notificationId === "") details.current[i].isread = 1;
            else {
              if (notificationId === details.current[i].notificationid) {
                details.current[i].isread = 1;
              }
            }

            let data = JSON.parse(details.current[i].data);
            let json = {
              id: nanoid(),
              key: nanoid(),
              message: (
                <div key={nanoid()}>
                  <span
                    className="adminNotificationUnReadDot"
                    style={
                      details.current[i].isread === 1
                        ? { display: "none" }
                        : {
                            width: "5px",
                            float: "left",
                            height: "5px",
                            borderRadius: "50%",
                            background: "#0D6EFD",
                            margin: "15px 5px 0 0",
                          }
                    }
                  ></span>
                  <div className="adminNotificationUserImg">
                    {data.hasOwnProperty("firstname") &&
                    data.hasOwnProperty("lastname")
                      ? data.firstname.charAt(0).toUpperCase() +
                        data.lastname.charAt(0).toUpperCase()
                      : ""}
                  </div>
                  <p
                    className="adminNotificationMsg"
                    onClick={() => {
                      notificationReport(
                        {
                          notificationData: data,
                          tag: details.current[i].tag,
                        },
                        details.current[i].userid,
                        details.current[i].notificationid,
                        details.current[i].isread
                      );
                    }}
                  >
                    {details.current[i].message}
                  </p>
                </div>
              ),

              // detailPage: "/events",

              receivedTime: moment(details.current[i].timestamp).fromNow(),
            };

            jsonArr.push(json);
          }

          jsonArr = jsonArr.reverse();
          setNotificationDetails(jsonArr);
          resolve("Success");
        })
        .catch((err) => {
          reject("Failure");
        });
    });
  };

  //   const handleClearAll = () => {
  //     setIsLoaded(true);
  //     axios
  //       .post(
  //         process.env.REACT_APP_NODE_API + "node/learner/notification/delete",
  //         JSON.stringify({ userid: getDataFromStorage("learnerid") }),
  //         {
  //           headers: {
  //             "Content-type": "application/json",
  //             Authorization: localStorage.getItem("tokenKey"),
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         setIsLoaded(false);
  //         setNotificationDetails([]);
  //       })
  //       .catch((err) => {
  //         if (err.message.includes("403")) {
  //           setIsUnAuth(true);
  //           setShow(true);
  //           setTitle("Error");
  //           setMsg("You have been logged-out due to inactivity. Login again.");
  //         }
  //         setIsLoaded(false);
  //       });
  //   };

  const handleLearnigpathClick = () => {
    setLpData2({
      lpDes: "",
      lpName: "",
      id: nanoid(),
      lpContent: "",
      lpIcon: "",
      lpShortDes: "",
      status: "",
      iconFileName: "",
      moduleId: "",
      libTypeId: 2,
      isAddTest: false,
      archived: 0,
      modules: [
        {
          id: 1,
          key: nanoid(),
          status: "new",
          moduleName: "",
          moduleData: [],
        },
      ],
    });
    setIsOldLpDetail(false);
    setIsAddLp(false);
    setIsAddLpModule(false);
    setIsFromBatch(false);
    Props.setselectque([]);
    setIsFromLp(false);
  };

  const gettingSelectedDd = (name, id, type) => {
    setChangeHeaderValue(true);

    if (type === "test") {
      setIsAddLpModule(false);
      setIsFromBatch(false);
      setIsOldLpDetail(false);
      setOldTestConfig({
        oldTestData: {},
        isOldTestConfig: false,
      });
      setLpData2({
        lpDes: "",
        lpName: "",
        moduleId: "",
        lpContent: "",
        lpIcon: "",
        lpShortDes: "",
        status: "",
        iconFileName: "",
        id: nanoid(),
        libTypeId: id,
        isAddTest: false,
        modules: [
          {
            id: 1,
            key: nanoid(),
            status: "new",
            moduleName: "",
            moduleData: [],
          },
        ],
      });
      setFromMenu(true);
      setFromMenuToEditTest(true);
      navigate("/library/testlist");
    } else {
      // if (type === "CONTENT 2") {
      //   setContent2(true);
      // } else {
      //   setContent2(false);
      // }

      setFromList({ isFromList: false, libType: "" });
      setIsFromBatch(false);
      Props.setSelectedLibType(name);
      Props.setTableShowStatus(id);
      setCurrentPage(1);
      Props.setselectque([]);
      setIsFromLp(false);
      setIsAddLpModule(false);
      setIsFromBatch(false);
      setIsOldLpDetail(false);
      setIsAddLp(false);
      if (
        parseInt(getDataFromStorage("accountType")) ===
        constants.ACCOUNT_TYPE.CLIENT_EVAL
      )
        navigate(`/library/${id}/shared`);
      else navigate(`/library/${id}/questionlist`);
    }
  };

  const handleMockTest = () => {
    setLists([]);
    setChangeHeaderValue(true);

    setIsAddLpModule(false);
    setIsFromBatch(false);
    setIsOldLpDetail(false);
    setOldTestConfig({
      oldTestData: {},
      isOldTestConfig: false,
    });
    setLpData2({
      lpDes: "",
      lpName: "",
      lpIcon: "",
      status: "",
      moduleId: "",
      id: nanoid(),
      lpContent: "",
      lpShortDes: "",
      isAddTest: false,
      iconFileName: "",
      modules: [
        {
          id: 1,
          key: nanoid(),
          status: "new",
          moduleName: "",
          moduleData: [],
        },
      ],
    });
    setFromMenu(true);
    setFromMenuToEditTest(true);
    navigate("/mock/test");
  };

  const handleMockLearnigPath = () => {
    setLists([]);
    setLpData2({
      lpDes: "",
      lpName: "",
      id: nanoid(),
      lpContent: "",
      lpIcon: "",
      lpShortDes: "",
      status: "",
      iconFileName: "",
      moduleId: "",
      libTypeId: 2,
      isAddTest: false,
      archived: 0,
      modules: [
        {
          id: 1,
          key: nanoid(),
          status: "new",
          moduleName: "",
          moduleData: [],
        },
      ],
    });
    setIsOldLpDetail(false);
    setIsAddLp(false);
    setIsAddLpModule(false);
    setIsFromBatch(false);
    Props.setselectque([]);
    setIsFromLp(false);

    navigate("mock/learningpath");
  };

  const handleClearQuestion = () => {
    setIsLoaded(true);
    axios
      .post(
        process.env.REACT_APP_NODE_API + "node/admin/test/clearQuestions",
        JSON.stringify({
          lpid: clearQuestionNotify.lpid,
          testid: clearQuestionNotify.testid,
          topicid: clearQuestionNotify.topicid,
          moduleid: clearQuestionNotify.moduleid,
          libtypeid: clearQuestionNotify.libType,
          learnerid: clearQuestionNotify.learnerid,
          sectionid: clearQuestionNotify.sectionid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("tokenKey"),
          },
        }
      )
      .then(async (res) => {
        setIsLoaded(false);

        if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          await handleMarkAllAsRead(clearQuestionNotify.notificationid, true);
          setShow(true);
          setTitle("Success");
          setMsg(res.data.data);
          setClearQuestionNotify({ isClear: false, learnerName: "" });
        } else {
          setShow(true);
          setTitle("Warning !");
          setMsg("Network error");
        }
      })
      .catch((err) => {
        setIsLoaded(false);
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsNotifyOpened(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setIsNotifyOpened]);

  useEffect(() => {
    setIsLoaded(true);

    // axios
    //   .get(
    //       "node/learner/notification/get/" +
    //       getDataFromStorage("learnerid"),
    //     {
    //       headers: {
    //         "Content-type": "application/json"
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     setIsLoaded(false);

    //     if (response.data.resultCode === 1000) {
    //       let res = response.data.msg;

    //       details.current = res;

    //       let count = 0;
    //       let jsonArr = [];

    //       for (let i = 0; i < res.length; i++) {
    //         if (res[i].isread == 0) {
    //           count++;
    //         }

    //         let data = JSON.parse(res[i].data);

    //         let json = {
    //           key: nanoid(),
    //           id: res[i].notificationid,
    //           message: (
    //             <div key={nanoid()}>
    //               <span
    //                 className="adminNotificationUnReadDot"
    //                 style={
    //                   res[i].isread == 1
    //                     ? { display: "none" }
    //                     : {
    //                         width: "5px",
    //                         float: "left",
    //                         height: "5px",
    //                         borderRadius: "50%",
    //                         background: "#0D6EFD",
    //                         margin: "15px 10px 0 0",
    //                       }
    //                 }
    //               ></span>
    //               <div className="adminNotificationUserImg">
    //                 {data.hasOwnProperty("firstname") &&
    //                 data.hasOwnProperty("lastname")
    //                   ? data.firstname.charAt(0).toUpperCase() +
    //                     data.lastname.charAt(0).toUpperCase()
    //                   : ""}
    //               </div>
    //               <p
    //                 className="adminNotificationMsg"
    //                 onClick={() => {
    //                   notificationReport(
    //                     {
    //                       tag: res[i].tag,
    //                       notificationData: JSON.parse(res[i].data),
    //                     },
    //                     res[i].userid,
    //                     res[i].notificationid,
    //                     res[i].isread
    //                   );
    //                 }}
    //               >
    //                 {res[i].message}
    //               </p>
    //             </div>
    //           ),

    //           // detailPage: "/events",

    //           receivedTime: moment(res[i].timestamp).fromNow(),
    //         };

    //         jsonArr.push(json);
    //       }
    //       setNotificationDetails(jsonArr.reverse());

    //       if (count > 0) {
    //         document.querySelector(".count").innerHTML = count;
    //       }
    //     } else {
    //       setShow(true);
    //       setTitle("Error");
    //       setMsg("Network error");
    //     }
    //   })
    //   .catch((err) => {
    //     // if (err.message.includes("403")) {
    //       setIsUnAuth(true);
    //       setShow(true);
    //       setTitle("Error");
    //       setMsg("You have been logged-out due to inactivity. Login again.");
    //     // }

    //     setIsLoaded(false);
    //   });

    axios
      .get("node/admin/library/gettypes", {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        setIsLoaded(false);

        let json = res.data.filter((data) => data.parenttype === 0);

        Props.setSelectedLibType(json[0].name);
        Props.setTableShowStatus(json[0].id);
        setLibType(json);
      })
      .catch((error) => {
        setIsLoaded(false);
      });
  }, []);

  return (
    <>
      <div className="Header">
        <div id="adminHeaderLogo">
          <div className="adminHeaderLogoWithVersion">
            <div>
              <Logo
                setBatchDetails={setBatchDetails}
                setIsBatchDetails={setIsBatchDetails}
              />
            </div>
            {/* <div className="appVersion">{process.env.REACT_APP_VERSION}</div> */}
          </div>

          {pathname.includes("/batches") &&
            batchDetails?.name !== null &&
            batchDetails.name !== undefined &&
            batchDetails.name !== "" && (
              <Overlay
                className="adminHeaderBatchName"
                title={batchDetails.name}
                component={
                  <span>
                    <span className="adminHeaderBatchNameSlash">/</span>{" "}
                    {batchDetails.name}
                  </span>
                }
              />
            )}
        </div>
        {pathname !== "/sandpack" && (
          <div className="Pages">
            <div className="Page">
              {getDataFromStorage("role") === constants.Roles.hr ? (
                <Link
                  id="dashboard"
                  to="/dashboards"
                  className={`dashboard ${
                    pathname.includes("/dashboard") ? "active" : ""
                  }`}
                >
                  DASHBOARD
                </Link>
              ) : (
                <div
                  id="dashboard"
                  className={`dashboard ${
                    pathname.includes("dashboard") ? "active" : ""
                  }`}
                >
                  DASHBOARD
                  <MenuDropDown />
                </div>
              )}
            </div>

            <div className="Page">
              <Link
                id="live"
                to={
                  // getDataFromStorage("role") == constants.Roles.hr
                  //   ? "/hr/batches"
                  // :
                  "/batches"
                }
                onClick={() => {
                  setShow(false);
                  setIsBatchDetails(false);
                  setBatchDetails([]);
                }}
                className={`live ${
                  pathname.includes("/batches") ? "active" : ""
                }`}
              >
                Batches
              </Link>
            </div>

            {getDataFromStorage("role") !== constants.Roles.hr && (
              <>
                <div className="Page">
                  <Link
                    id="learningPaths"
                    to="/learningpath"
                    onClick={handleLearnigpathClick}
                    className={`learningPaths ${
                      pathname === "/learningpath" ? "active" : ""
                    }`}
                  >
                    PATHS
                  </Link>
                </div>
                <div
                  className={`mock ${
                    pathname.includes("/mock") ? "active" : ""
                  }`}
                >
                  Mock
                  <div className="mockDropDown">
                    <p className="mockTypeDiv" onClick={handleMockTest}>
                      <TestSvgIcon
                        active={true}
                        disable={false}
                        libType={constants.libTypeIds.TEST}
                      />
                      Mock Test
                    </p>
                    <p className="mockTypeDiv" onClick={handleMockLearnigPath}>
                      <TestSvgIcon
                        active={true}
                        disable={false}
                        libType={constants.libTypeIds.TEST}
                      />
                      Mock Learningpath
                    </p>
                  </div>
                </div>
                <div className="Page">
                  <div
                    id="questions"
                    className={`questions ${
                      pathname.includes("/library") ? "active" : ""
                    }`}
                  >
                    LIBRARY
                    <div className="headerDropdownContent">
                      {libType.map((data) => (
                        <p
                          className="libtypediv"
                          key={nanoid()}
                          value={data.name}
                          onClick={() => gettingSelectedDd(data.name, data.id)}
                        >
                          <TestSvgIcon
                            active={true}
                            disable={true}
                            libType={data.id}
                          />
                          {"  "}

                          {data.name == "Content"
                            ? "Content Editor"
                            : data.name}
                        </p>
                      ))}
                      {/* <p
                    onClick={() => gettingSelectedDd("Content", 1, "CONTENT 2")}
                  >
                    CONTENT EDITOR
                  </p> */}
                      <Typography id="libraryTestTitle">Tests</Typography>
                      <p
                        className="libtypediv"
                        onClick={() =>
                          gettingSelectedDd(
                            "",
                            constants.libTypeIds.MCQ,
                            "test"
                          )
                        }
                      >
                        <TestSvgIcon
                          active={true}
                          disable={false}
                          libType={constants.libTypeIds.MCQ}
                        />
                        {"  "}
                        Multiple Choice Question{" "}
                      </p>
                      <p
                        className="libtypediv"
                        onClick={() =>
                          gettingSelectedDd(
                            "",
                            constants.libTypeIds.DESCRIPTIVE,
                            "test"
                          )
                        }
                      >
                        <TestSvgIcon
                          active={true}
                          disable={false}
                          libType={constants.libTypeIds.DESCRIPTIVE}
                        />
                        Descriptive{" "}
                      </p>
                      <p
                        className="libtypediv"
                        onClick={() =>
                          gettingSelectedDd(
                            "",
                            constants.libTypeIds.CODING_CHALLENGE,
                            "test"
                          )
                        }
                      >
                        <TestSvgIcon
                          active={true}
                          disable={false}
                          libType={constants.libTypeIds.CODING_CHALLENGE}
                        />
                        Coding Challenge
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {/* <div className="adminNotificationSec" key={nanoid()}>
                    <Notifications
                        data={notificationDetails}
                        icon={bell}
                        key={nanoid()}
                        header={{
                            title: "Notifications",
                            option: {
                                text: (
                                    <div className="adminNotificationActionSec" key={nanoid()}>
                                        <p
                                            key={nanoid()}
                                            className="adminNotificationClear"
                                            onClick={handleClearAll}
                                        >
                                            Clear all
                                        </p>
                                        <p
                                            key={nanoid()}
                                            className="adminNotificationMark"
                                            onClick={() => handleMarkAllAsRead("", false)}
                                        >
                                            Mark all as read
                                        </p>
                                    </div>
                                ),
                            },
                        }}
                    />
                </div> */}

        {!Props.isAdmin && Props.status !== 2
          ? Props.hideTimer.includes(pathname) && (
              <span className="timer">
                {`Time Left :${Math.floor(seconds / 3600)}h:${Math.floor(
                  (seconds % 3600) / 60
                )}m:${Math.floor((seconds % 3600) % 60)}s`}
              </span>
            )
          : null}
        <div style={{ marginRight: "5px" }}>
          <ProfileAvatar />
        </div>
        {/* <NavDropdown title={userImage}>
          <li>
            <img
              alt="logInProfile1"
              className="logInProfile1"
              src={localStorage.getItem("picture")}
            />
          </li>
          <li className="logoutUserName">{getDataFromStorage("name")}</li>
          <hr style={{ margin: "0", padding: "0" }}></hr>
          <Typography
            className="viewProfile"
            onClick={() => {
              navigate("/profile/personalinfo");
            }}
            sx={{ p: 1 }}
          >
            View Profile
          </Typography>

          <li className="logout" onClick={handleLogOut}>
            Logout
          </li>
        </NavDropdown> */}
      </div>
      <Notify
        show={show}
        title={title}
        message={msg}
        onHide={isUnAuth ? handleUnAuthAlert : handleClose}
      />
      <Modal
        centered
        show={clearQuestionNotify.isClear}
        style={{
          fontSize: "14px",
          fontWeight: "700",
          lineHeight: "16px",
          fontFamily: "Inter",
          fontstyle: "normal",
        }}
        onHide={() =>
          setClearQuestionNotify({ isClear: false, learnerName: "" })
        }
      >
        <Modal.Body style={{ height: "50px" }}>
          Do you want to clear the questions for{" "}
          {clearQuestionNotify.learnerName}
        </Modal.Body>
        <Modal.Footer style={{ margin: "10px", padding: "0px" }}>
          <Button
            variant="primary"
            onClick={handleClearQuestion}
            style={{ margin: "10px 10px 0 0" }}
          >
            Yes
          </Button>
          <Button
            variant="danger"
            style={{ margin: "10px 10px 0 0" }}
            onClick={() =>
              setClearQuestionNotify({ isClear: false, learnerName: "" })
            }
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminHeader;
