import { useContext } from "react";
import constants from "../constants";
import { getDataFromStorage } from "../util";
import useAxiosPrivate from "./useAxiosPrivate";
import AlertContext from "../context/AlertContext";
import useCommonFunctionHooks from "./useCommonFunctionHooks";
import useUploadLargeFileToS3 from "./useUploadLargeFileToS3";
import WarningNoticeSvgIcon from "../Svg/WarningNoticeSvgIcon";

export default function RegistrationPageHooks() {
  const {
    register,
    navigate,
    webCamRef,
    setBtnText,
    setIsLoaded,
    countPicture,
    setShowNotify,
    setCaptureBtnHide,
    numberOfPicsToTake,
    setIsFirstLoggedIn,
  } = useContext(AlertContext);

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const axios = useAxiosPrivate();
  const { uploadLargeFileToS3 } = useUploadLargeFileToS3();

  const videoConstraints = {
    width: 230,
    height: 120,
    facingMode: "user",
  };

  const handleNavigate = () => {
    setShowNotify({ show: false });
    setIsFirstLoggedIn(false);
    navigate("/batch");
  };

  const handleSkip = () => {
    setShowNotify({
      show: true,
      titleSvg: "warning",
      primaryButtonFunction: handleNavigate,
      title:
        "You need to register yourself if you want to take proctored tests:",
      msg: `You've skipped this step for now, but if you want to take proctored tests, you will be asked to register before taking the 
            test. Otherwise, you cannot attend the proctored tests.`,
    });
  };

  const takePicture = async () => {
    setIsLoaded(true);

    //Change the number of pics to be taken here

    numberOfPicsToTake.current = 1;

    let picWait;

    while (countPicture.current < 1) {
      let pictureSrc = webCamRef.current.getScreenshot();

      picWait = await handleSavePicture(pictureSrc);

      if (picWait.msg === "success") {
        countPicture.current++;
      } else {
        break;
      }
    }

    if (countPicture.current === 1) {
      setBtnText("Retake");
      setCaptureBtnHide(false);
      profileUpdate(picWait.data);
    }
  };

  const profileUpdate = (picBlob) => {
    let form = new FormData();
    let url = "node/learner/verifypic/save";

    form.append("learnerid", getDataFromStorage("learnerid"));
    form.append("register", "true");

    if (process.env.REACT_APP_DEPLOYMENT === "LOCAL")
      form.append("images", picBlob);

    axios
      .post(url, form, getHeaderDetailsForAxios("multipart"))
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          setCaptureBtnHide(true);
          setIsFirstLoggedIn(false);
          register.current = 1;
        } else {
          setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.msg,
          });
          setCaptureBtnHide(true);
        }
      })
      .catch((error) => {
        getCatchBlockDetails(error);
        setCaptureBtnHide(true);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const b64toBlob = (dataURI) => {
    return new Promise((resolve, reject) => {
      try {
        let byteString = atob(dataURI.split(",")[1]);
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        let blobdata = new Blob([ab], { type: "image/png" });

        resolve(blobdata);
      } catch (err) {
        reject(err);
      }
    });
  };

  const handleSavePicture = async (pic) => {
    let blob = await b64toBlob(pic);

    if (countPicture.current === 0) {
      let filePath = `Proctoring/Registration/${getDataFromStorage(
        "learnerid"
      )}/${getDataFromStorage("learnerid")}.png`;
      if (process.env.REACT_APP_DEPLOYMENT === "AWS")
        await uploadLargeFileToS3(blob, filePath, "png");
    }

    //comment this if face recognition required

    return new Promise((resolve, reject) => {
      resolve({ msg: "success", data: blob });
    });
  };

  return { handleSkip, videoConstraints, handleNavigate, takePicture };
}
