import "./Content.scss";
import { nanoid } from "nanoid";
import "react-quill/dist/quill.snow.css";
import constants from "../../../../constants";
import { Editor } from "@tinymce/tinymce-react";
import { getPreSignedUrl } from "../../../../util";
import { getDataFromStorage } from "../../../../util";
import AlertContext from "../../../../context/AlertContext";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import {
  faBook,
  faLink,
  faVideo,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import PdfViewer from "../../../CommonComponents/PdfViewer";

let numPages = [];
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

export default function Content(Props) {
  const {
    status,
    setMsg,
    setShow,
    setTitle,
    setIsLoaded,
    setIsUnAuth,
    fromCodeEditor,
    setFromCodeEditor,
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();

  const [viewFile, setViewFile] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [referenceLinks, setReferenceLinks] = useState([]);

  const assignmentReferenceLinkRef = useRef([]);

  const handleGetLinks = useCallback(async () => {
    try {
      let contentData = JSON.parse(JSON.stringify(Props.contentData));
      let links =
        contentData !== "" &&
        contentData?.links !== null &&
        contentData?.links !== undefined &&
        contentData?.links.length > 0
          ? JSON.parse(contentData.links)
          : [];

      for (let link = 0; link < links.length; link++) {
        let fileName = links[link].link.substring(
          links[link].link.lastIndexOf("/") + 1
        );

        links[link].id = nanoid();
        links[link].name = fileName;
        links[link].extension = fileName.replace(/^.*\./, "");

        if (links[link].type !== constants.LINK_TYPE.EMBEDED_LINK) {
          if (process.env.REACT_APP_DEPLOYMENT === "LOCAL")
            links[link].link =
              process.env.REACT_APP_NODE_API + links[link].link;
          else {
            let getPreSignedUrlRes = await getPreSignedUrl(links[link].link);
            links[link].link = getPreSignedUrlRes;
          }
        } else links[link].name = links[link].link;
      }

      setReferenceLinks(links);
    } catch (err) {
      setReferenceLinks([]);
    }
  }, [Props.contentData]);

  useEffect(() => {
    handleGetLinks();
  }, [handleGetLinks]);

  const onDocumentLoadSuccess = (numPageDetail, index) =>
    (numPages[index] = numPageDetail._pdfInfo.numPages);

  const handleRequestfullScreenMode = (index) => {
    const iframeRef = assignmentReferenceLinkRef.current[index];
    iframeRef.requestFullscreen();

    // setViewFile(true);

    setIsFullscreen(true);
    setCurrentIndex(index);
  };

  const handleCancelFullScreenMode = (index) => {
    const iframeRef = assignmentReferenceLinkRef.current[index];

    iframeRef.exitFullscreen();
    setViewFile(false);
  };

  const handleExitFullscreen = () => {
    if (document.fullscreenElement === null) {
      // setViewFile(false);

      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleExitFullscreen);

    return () => {
      document.removeEventListener("fullscreenchange", handleExitFullscreen);
    };
  }, []);

  const handleSubmit = async () => {
    setIsLoaded(true);

    try {
      let res = await axios.post(
        "node/learner/course/content/status",
        JSON.stringify({
          lpid: Props.lpId,
          lpconfigid: Props.lpConfigId,
          learnerid: getDataFromStorage("learnerid"),
        }),
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );

      if (res.data.resultCode !== 1000) {
        setShow(true);
        setTitle("Info :-");
        setMsg(res.data.message);
      } else {
        Props.handleNavigate();
        Props.setLpId(Props.lpId);
      }
    } catch (error) {
      setShow(true);
      setTitle("Error");
      setMsg(error.message);

      if (error.message.includes("403")) {
        setIsUnAuth(true);
        setMsg("You have been logged-out due to inactivity. Login again.");
      } else setMsg(error.message);
    } finally {
      setIsLoaded(false);
    }
  };

  var isAdmin;

  function role() {
    isAdmin = getDataFromStorage("role") === "learner" ? false : true;
    return isAdmin;
  }

  isAdmin = role();

  const disableRightClick = (e) => {
    if (!isAdmin) {
      e.preventDefault();
    }
  };

  return (
    <div className="contentPage" onContextMenu={disableRightClick}>
      {/* {!viewFile && ( */}
      <div className="content-name-btn">
        <h1 className="contentName">
          <FontAwesomeIcon
            className="arrowLeftLongIcon"
            icon={faArrowLeftLong}
            onClick={() => {
              setFromCodeEditor(true);
              Props.handleNavigate();
              Props.setLpId(Props.lpId);
            }}
          />
          <FontAwesomeIcon className="bookIcon" icon={faBook} />
          {Props.contentData?.name}
        </h1>

        {Props.status === 1 && (
          <button
            className="nextBtn"
            onClick={() => {
              setFromCodeEditor(true);
              handleSubmit();
            }}
          >
            Complete & continue
          </button>
        )}
      </div>
      {/* )} */}

      <div className="the-content">
        <div className="content-description">
          {!viewFile && (
            <div className="content-first-div">
              {/* <h1 className="contentName">
                            <FontAwesomeIcon
                                className="arrowLeftLongIcon"
                                icon={faArrowLeftLong}
                                onClick={() => {
                                    Props.setFromCodeEditor(true);
                                    Props.handleNavigate();
                                    Props.setLpId(Props.lpId);
                                }}
                            />
                            <FontAwesomeIcon className="bookIcon" icon={faBook} />
                            {Props.contentData.name}
                        </h1> */}

              {Props.contentData.description&&Props.contentData.description!==""&&Props.contentData.description!==null?
              <Editor
              tinymceScriptSrc='/tinymce/tinymce.min.js'
                disabled={true}
                toolbar={false}
                menubar={false}
                statusbar={false}
                branding={false}
                value={Props.contentData.description}
                init={{
                  license_key: 'gpl',
                  selector: 'textarea',
                  readonly: true,
                  menubar: false,
   
                  statusbar: false,
                  resize: false,
                  plugins: 'autoresize'
                }}
              />:null}

            </div>
          )}
        </div>

        {referenceLinks.map((link, index) =>
          link.type === constants.LINK_TYPE.CONTENT_FILE_LINK &&
          link.extension !== "mp4" ? (
            <React.Fragment key={link.id}>
              {/* <h1 className="pdfTitle">
                {link.extension === 'pdf' ? <img className="pdfTitleImage" src={pdfImageIcon} alt="PDF" /> : null}
                {link.name}
              </h1> */}
              <div className="content-iframe-div">
                {/* <div className="imageDiv">
                            {link.extension === 'pdf' ? <img className="pdfImage" src={pdfImageIcon} alt="PDF" /> : null}
                            {!viewFile ? (
                                <button className="viewPdfBtn" onClick={() => handleRequestfullScreenMode(index)}>
                                    View
                                </button>
                            ) : (
                                <button className="backBtn" onClick={() => handleCancelFullScreenMode(index)}>Back</button>
                            )}
                        </div> */}
                {/* <h1 className="pdfTitle">
                            {link.extension === 'pdf' ? <img className="pdfTitleImage" src={pdfImageIcon} alt="PDF" /> : null}
                            {link.name}
                        </h1> */}
                {/* <iframe
                            title={"pdf" + index} 
                            oncontextmenu="return false;"
                            className={!viewFile?"beforeFullScreen":""}
                            ref={(ref) => (assignmentReferenceLinkRef.current[index] = ref)}
                            controlsList="nodownload"
                            src={`${link.link}#toolbar=0&view=FitH`}
                        ></iframe> */}
                <PdfViewer file={link.link} name={link.name}/>
              </div>
            </React.Fragment>
          ) : link.type === constants.LINK_TYPE.CONTENT_FILE_LINK ? (
            <div className="imageDiv" key={link.id}>
              <FontAwesomeIcon className="videoIcon" icon={faVideo} />{" "}
              <p className="mp4Title">{link.name}</p>
              <video
                controls
                className="videoPlay"
                controlsList="nodownload"
                src={`${link.link}#t=2.0`}
              ></video>
            </div>
          ) : (
            <React.Fragment>
              <FontAwesomeIcon className="linkIconView" icon={faLink} />{" "}
              <div className="imageDiv">
                <FontAwesomeIcon className="linkIcon" icon={faLink} />{" "}
                <p className="mp4Title">{link.link}</p>
                <button
                  className="viewEmbeddedBtn"
                  onClick={() => handleRequestfullScreenMode(index)}
                >
                  View
                </button>
              </div>
              <iframe
                width="500"
                height="300"
                src={link.link}
                frameborder="0"
                allowfullscreen
                title={"embedded" + index}
                ref={(ref) => (assignmentReferenceLinkRef.current[index] = ref)}
                className={!viewFile ? "beforeFullScreen" : ""}
              ></iframe>
            </React.Fragment>
          )
        )}
      </div>
    </div>
  );
}
