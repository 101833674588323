import _ from "lodash";
import { nanoid } from "nanoid";
import { makeStyles } from "@mui/styles";
// import constants from "../../../../../constants";
import { createRef, useContext, useRef, useState } from "react";
export default function SandpackEditorSplitContext() {
    const [problemWindowHeight, setProblemWindowHeight] = useState(0);
    const [outputWindowHeight, setOutputWindowHeight] = useState(9);

    const handleProblemDragFinished = (size) => {
        const [widthPercentage, heightPercentage] = size;
        const parsedWidth = parseFloat(widthPercentage.replace("%", ""));
    
        setProblemWindowHeight(parsedWidth);
    
        const newSize = `${widthPercentage},${heightPercentage}`;
    
        localStorage.setItem("splitWidthPos", newSize);
      };

      const handleDragFinished = (size) => {
        const [widthPercentage, heightPercentage] = size; // Extract the width and height percentage values
        const parsedHeight = parseFloat(heightPercentage.replace("%", "")); // Convert the height value to a float
    
        setOutputWindowHeight(parsedHeight);
    
        const newSize = `${widthPercentage},${heightPercentage}`; // Store the new size in localStorage
         
         const parsedNewSize = parseFloat(newSize.split(",")[1].replace("%", ""));
         const editorHeightdiv = document.getElementById("sandpackCodeEditor");
         if(editorHeightdiv){
          // console.log("editorHeightdiv.style.height",editorHeightdiv.style.height);
          editorHeightdiv.style.height = `${100-parsedNewSize}vh`;
         }
        //  console.log("newSize$$$$$$$$$$,outputWindowHeight",editorHeightdiv.style.height,outputWindowHeight);
         localStorage.setItem("splitPos", newSize);
      };
    return{
        handleProblemDragFinished,
        handleDragFinished,
        problemWindowHeight,
        setProblemWindowHeight,
        outputWindowHeight,
        setOutputWindowHeight

    }
}

