import React, { useContext } from "react";
import constants from "../../../../constants";
import BackSvgIcon from "../../../../Svg/BackSvgIcon";
import CodingTable from "../../CodingPage/CodingTable";
import TestDueHooks from "../../../../Hooks/TestDueHooks";
import AlertContext from "../../../../context/AlertContext";
import StatusLabel from "../../../CommonComponents/StatusLabel";
import TestDueDate from "../../../CommonComponents/TestDueDate";
import WarningNotice from "../../InstructionPage/WarningNotice";
import AssignmentPage from "../../AssignmentPage/AssignmentPage";
import InstructionPage from "../../InstructionPage/InstructionPage";
import useCommonFunctionHooks from "../../../../Hooks/useCommonFunctionHooks";
import WarningNoticeBodySvgIcon from "../../../../Svg/WarningNoticeBodySvgIcon";

export default function RightMain() {
  const { status, testData, showInstruction, isAdmin, navigate } =
    useContext(AlertContext);

  const { currentDueLabel } = TestDueHooks();
  const { checkBatchType } = useCommonFunctionHooks();

  const handleBackFromAssignment = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    if (testData.mockuse) {
      navigate("/mock/library");
    } else {
      navigate(`/batches/${localStorage.getItem("abatchid")}/report#`);
    }
  };

  return (
    <div className="rightMainContainer">
      {(testData.mockuse ||
        (isAdmin && testData.testtype === constants.libTypeIds.ASSIGNMENT)) && (
        <div className="rightMainBack">
          <BackSvgIcon onClick={handleBackFromAssignment} />
        </div>
      )}
      <div className="rightMainHeader">
        <div className="rightMainHeaderInnerDiv">
          <span className="rightMainHeaderTestLabel">
            {testData.testname !== null ? testData.testname : testData.libname}
          </span>
          <StatusLabel component={"rightMain"} />
        </div>
        {!isAdmin &&
          !checkBatchType() &&
          !localStorage.getItem("mockLp") &&
          !testData.hasOwnProperty("mockuse") && <TestDueDate />}
      </div>
      {(!localStorage.getItem("mockLp") &&
        !isAdmin &&
        currentDueLabel(testData.duedate) === "Past Due" &&
        status !== constants.PROGRESS_STATUS.EVALUATED &&
        status !== constants.PROGRESS_STATUS.TO_BE_EVALUATED) ||
      ((status === constants.PROGRESS_STATUS.EVALUATED ||
        status === constants.PROGRESS_STATUS.IN_PROGRESS ||
        status === constants.PROGRESS_STATUS.TO_BE_EVALUATED) &&
        (testData.testtype === constants.libTypeIds.MCQ ||
          testData.testtype === constants.libTypeIds.DESCRIPTIVE)) ? (
        <div>
          <WarningNotice />
          {/* {(status === constants.PROGRESS_STATUS.EVALUATED ||
            status === constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
            status === constants.PROGRESS_STATUS.PARTIAL_EVALUATED) &&
            (testData.testtype === constants.libTypeIds.MCQ ||
              testData.testtype === constants.libTypeIds.DESCRIPTIVE) && (
              <WarningNoticeBodySvgIcon component="rightMain" />
            )} */}
        </div>
      ) : (
        <>
          {showInstruction &&
            status !== constants.PROGRESS_STATUS.EVALUATED && (
              <InstructionPage />
            )}
          {!showInstruction &&
          testData.testtype === constants.libTypeIds.CODING_CHALLENGE ? (
            <CodingTable />
          ) : (
            (isAdmin
              ? testData.testtype === constants.libTypeIds.ASSIGNMENT
              : testData.libtype === constants.libTypeIds.ASSIGNMENT) && (
              <AssignmentPage />
            )
          )}
        </>
      )}
    </div>
  );
}
