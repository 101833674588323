import React, { useContext } from "react";
import AngleLeftSvg from "../../../Svg/AngleLeftSvg";
import { Outlet, useLocation } from "react-router-dom";
import SearchSvgIcon from "../../../Svg/SearchSvgIcon";
import AlertContext from "../../../context/AlertContext";
import Loading from "../../Notification-Loading/Loading/Loading";
import { MockTestPageContext } from "../../../context/MockTestPageContext";

export default function MockTestPage() {
  const Alert = useContext(AlertContext);

  const { searchText, setMockTest, setSearchText, setSearchMockTest } =
    useContext(MockTestPageContext);

  const { pathname } = useLocation();

  return (
    <div
      className={
        Alert.isAdmin
          ? "mockTestMainContainerForAdmin"
          : "mockTestMainContainer"
      }
    >
      {Alert.isLoaded && <Loading />}
      {!Alert.isAdmin && (
        <>
          <div
            className="mockTestBackDiv"
            onClick={() => {
              Alert.navigate("/landingpage");
            }}
          >
            <AngleLeftSvg /> Back
          </div>
          <div className="mockTestLabel">Mock Test</div>
          <div className="mockTestNavBar">
            <div className="mockTestNavBarPath">
              <div
                onClick={() => {
                  setMockTest([]);
                  setSearchText("");
                  setSearchMockTest([]);
                  Alert.navigate("/mock/library");
                }}
                className={`mockTestNavBarLabel${
                  pathname.includes("/library") ? "Active" : ""
                }`}
              >
                Library
              </div>
              <div
                onClick={() => {
                  setMockTest([]);
                  setSearchText("");
                  setSearchMockTest([]);
                  Alert.navigate("/mock/results");
                }}
                className={`mockTestNavBarLabel${
                  pathname.includes("/results") ? "Active" : ""
                }`}
              >
                Completed Tests
              </div>
            </div>
            <div className="mocktestSearchBox">
              <SearchSvgIcon />
              <input
                type="text"
                name="search"
                value={searchText}
                placeholder="Search"
                className="mockTestLibrarySearch"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
          </div>
          <hr />
        </>
      )}
      <Outlet />
    </div>
  );
}
