// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDataFromStorage } from "./util.js";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  projectId: "pwa-app-8a279",
  measurementId: "G-PPD7YCLE3E",
  messagingSenderId: "322822227807",
  storageBucket: "pwa-app-8a279.appspot.com",
  authDomain: "pwa-app-8a279.firebaseapp.com",
  apiKey: "AIzaSyBA--13UUuMEheDDSi34sCdpVGlPYyAbEw",
  appId: "1:322822227807:web:a988b439160dbbf3d9d8ea",
};

// Initialize Firebase

const firebaseclient = initializeApp(firebaseConfig);

// export const requestPermission = async () => {

//   try {
//     const permission = await Notification.requestPermission();

//     if (permission === "granted") {

//       const currentToken = await getToken(messaging);
//       const userId = getDataFromStorage("learnerid");

//       if (currentToken) {
//         const response = await axios.post(
//           process.env.REACT_APP_NODE_API + "node/learner/save/usertoken",
//           JSON.stringify({ currentToken, userId }),
//           {
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: localStorage.getItem("tokenKey"),
//             },
//           }
//         );
//       }
//   } catch (error) {
//   }
// };

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const messaging = getMessaging(firebaseclient);

export default firebaseclient;
