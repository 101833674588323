import { nanoid } from "nanoid";
import constants from "../constants";
import { useContext, useState } from "react";
import { getDataFromStorage } from "../util";
import useAxiosPrivate from "./useAxiosPrivate";
import McqAndDescHooks from "./McqAndDescHooks";
import AlertContext from "../context/AlertContext";
import NotifyBody from "../Components/Notify/NotifyBody";
import useCommonFunctionHooks from "./useCommonFunctionHooks";

export default function InstructionPageHooks() {
  const {
    lpId,
    status,
    register,
    testData,
    setIsLoaded,
    camAvailable,
    setResultCode,
    setIsTakeTest,
    setShowNotify,
    codingTestData,
    setButtonDisable,
    assignQuestionToLearner,
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { startTest } = McqAndDescHooks();

  const { handleRegistration, getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const [audioAvailable, setAudioAvailable] = useState(true);

  const videoConstraints = {
    width: 181,
    height: 101,
    facingMode: "user",
  };

  const getButtonText = () => {
    if (status === constants.PROGRESS_STATUS.UNLOCKED) {
      return "Take Test";
    } else if (status === constants.PROGRESS_STATUS.QN_CONTINUE) {
      return "Resume Test";
    } else {
      return "Retake Test";
    }
  };

  if (testData.videoqn === 1) {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .catch(() => setAudioAvailable(false));
  }

  const checkRefreshTokenIsValid = async (payload) => {
    try {
      let res = await axios.post(
        "node/auth/refreshtoken/check",
        { ...payload, refreshToken: localStorage.getItem("refreshToken") },
        getHeaderDetailsForAxios()
      );

      if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
        if (testData.testtype === constants.libTypeIds.CODING_CHALLENGE) {
          return takeTest(codingTestData);
        } else {
          return startTest();
        }
      } else if (
        res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR
      ) {
        setShowNotify({
          show: true,
          title: "Warning",
          msg: res.data.msg,
          isUnAuth: res.data.msg.includes("session is expiring") && true,
        });
      } else {
        setShowNotify({
          show: true,
          title: "Warning",
          msg: "Something went wrong. Please try again.",
        });
      }
    } catch (error) {
      getCatchBlockDetails(error);
    } finally {
      setIsLoaded(false);
    }
  };

  const handleCheck = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }

    if (
      register.current === 0 &&
      testData.testmode === constants.MODE.TEST_MODE &&
      testData.proctored === constants.PROCTORING_MODE.TAB_CAM
    ) {
      setShowNotify({
        show: true,
        size: "lg",
        primaryButton: "Continue",
        secondaryButton: "Cancel",
        primaryButtonFunction: handleRegistration,
        title: "First time taking a proctored test.",
        msg: <NotifyBody component="instructionPageNotifyMsgForRegistration" />,
      });
    } else if (
      !camAvailable &&
      (testData.videoqn === 1 ||
        testData.proctored === constants.PROCTORING_MODE.TAB_CAM)
    ) {
      setShowNotify({
        show: true,
        titleSvg: "warning",
        msg: <NotifyBody component="instructionPageNotifyMsgForCameraAccess" />,
      });
    } else if (!audioAvailable && testData.videoqn === 1) {
      setShowNotify({
        show: true,
        titleSvg: "warning",
        msg: <NotifyBody component="instructionPageNotifyMsgForAudioAccess" />,
      });
    } else if (testData.testtype === constants.libTypeIds.CODING_CHALLENGE) {
      takeTest(codingTestData);
    } else {
      let payload = {
        libtypeid: testData.testtype,
      };

      if (testData.testtype === constants.libTypeIds.CODING_CHALLENGE) {
        payload.testconfigid = codingTestData.testconfigid;
      } else {
        payload.testId = testData.testid;
      }

      return checkRefreshTokenIsValid(payload);
    }
  };

  const takeTest = (codingTestData) => {
    setIsLoaded(true);
    setButtonDisable(true);
    axios
      .post(
        "node/learner/codingassignment/add",
        JSON.stringify({
          lpid: lpId,
          lmpid: testData.lmpid,
          lmtid: testData.lmtid,
          mockuse: testData.mockuse,
          topicid: codingTestData.topicid,
          mocklp: localStorage.getItem("mockLp"),
          testconfigid: codingTestData.testconfigid,
          learnerid: getDataFromStorage("learnerid"),
          completedqncount: codingTestData.completedcount,
        }),
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
          setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.msg,
          });
          setResultCode(res.data.resultCode);
        } else {
          let totalCount = 0;

          for (let i = 0; i < testData.data.topics.length; i++) {
            totalCount += testData.data.topics[i].totalcount;
          }

          let response = res.data.data;
          let testCases =
            response.testcases !== null ? JSON.parse(response.testcases) : [];
          testCases = testCases.filter((example) => example.isexample === 1);

          for (let j = 0; j < testCases.length; j++) {
            if (j === 0) testCases[j].isActive = true;
            testCases[j].id = nanoid();
          }

          response.testcases = JSON.stringify(testCases);

          if (
            codingTestData.status === constants.QUESTION_STATUS.ASSIGNED ||
            codingTestData.status === constants.QUESTION_STATUS.RESUBMIT
          ) {
            for (let k = 0; k < response.templatecode.length; k++) {
              response.templatecode[k].userCode = response.templatecode[k].code;
            }
          }

          response.lpId = lpId;
          response.isChangeCode = false;
          response.topics = codingTestData;
          response.secData = codingTestData;
          response.testname = testData.testname;
          response.status = codingTestData.status;
          response.secData.totalCount = totalCount;
          response.lpConfigId = testData.lpconfigid;
          response.secData.testId = testData.testid;
          response.secData.testMode = testData.testmode;
          response.secData.proctored = testData.proctored;
          response.secData.completedcount = codingTestData.completedcount;
          assignQuestionToLearner(response);

          if (
            testData.testmode === constants.MODE.TEST_MODE &&
            testData.proctored === constants.PROCTORING_MODE.TAB_CAM
          ) {
            setIsTakeTest(true);
          }
        }
      })
      .catch((error) => {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        }

        getCatchBlockDetails(error);
      })
      .finally(() => {
        setIsLoaded(false);
        setButtonDisable(false);
      });
  };

  return { handleCheck, takeTest, videoConstraints, getButtonText };
}
