import React, { useContext, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import Button from "../../Button";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import { getDataFromStorage } from "../../../util";
import FormTitle from "./FormTitle";
import { ProfileContext } from "../../../context/ProfileContext";
import AlertContext from "../../../context/AlertContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
function ParentInfo({ from, learnerId }) {
  const axios = useAxiosPrivate();
  const { profileDetails, setProfileDetails, navigate, onSaveProfileDetails } =
    useContext(ProfileContext);
  const { setIsLoaded, setShowNotify } = useContext(AlertContext);
  const [isInfoEditable, setIsInfoEditable] = useState(false);
  const [focusStates, setFocusStates] = useState(() =>
    Object.keys(profileDetails.parentinfo).reduce(
      (acc, key) => ({ ...acc, [key]: false }),
      {}
    )
  );

  useEffect(() => {
    // const abortController = new AbortController();
    const responseOfParentData = async (userid) => {
      setIsLoaded(true);
      try {
        let responseOfParent = await axios.get(
          "node/learner/parent/" + userid,
          {
            headers: {
              "Content-type": "application/json",
            },
            // signal: abortController.signal,
          }
        );

        let res = responseOfParent.data.data;
        if (typeof res == "object") {
          setIsInfoEditable(true);
          setProfileDetails((prev) => ({ ...prev, parentinfo: res }));
        }
      } catch (error) {
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
        }
      } finally {
        setIsLoaded(false);
      }
    };

    let id = getDataFromStorage("learnerid");
    if (from === "onpopup") {
      id = learnerId;
    }
    responseOfParentData(id);
    // return () => abortController.abort();
  }, [axios, learnerId, from, setIsLoaded, setShowNotify, setProfileDetails]);

  const handleFieldFocus = (name) => {
    setFocusStates({ ...focusStates, [name]: true });
  };

  const handleFieldBlur = (name) => {
    setFocusStates({ ...focusStates, [name]: false });
  };

  const onChangeFields = (event) => {
    setProfileDetails((prev) => ({
      ...prev,
      parentinfo: {
        ...prev.parentinfo,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const onchangeMobile = (ph) => {
    setProfileDetails((prev) => ({
      ...prev,
      parentinfo: {
        ...prev.parentinfo,
        mobile: ph,
      },
    }));
  };

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleNext = async () => {
    if (!isInfoEditable) {
      if (validateEmail(profileDetails.parentinfo.email)) {
        onSaveProfileDetails();
        navigate("/profile/education");
      } else if (profileDetails.parentinfo.email) {
        setShowNotify({
          show: true,
          msg: "The Email Address is not valid",
          title: "Warning !",
        });
      } else {
        navigate("/profile/education");
      }
    } else {
      navigate("/profile/education");
    }
  };

  const handlePrevious = () => {
    navigate("/profile/personalinfo");
  };

  return (
    <React.Fragment>
      <FormTitle
        icon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 21C20 19.6044 20 18.9067 19.8278 18.3389C19.44 17.0605 18.4395 16.06 17.1611 15.6722C16.5933 15.5 15.8956 15.5 14.5 15.5H9.5C8.10444 15.5 7.40665 15.5 6.83886 15.6722C5.56045 16.06 4.56004 17.0605 4.17224 18.3389C4 18.9067 4 19.6044 4 21M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z"
              stroke="#101828"
              strokeWidth="1.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        title="Parent Info"
      />
      <form className="parentInfoForm">
        <div className="parentInfoNameSec">
          <TextField
            disabled={from === "onpopup" || isInfoEditable}
            label="First Name"
            name="firstname"
            id="parentInfoFName"
            fullWidth
            InputLabelProps={{
              shrink:
                focusStates["firstname"] ||
                Boolean(profileDetails.parentinfo["firstname"]),
            }}
            variant="outlined"
            value={profileDetails.parentinfo.firstname}
            InputProps={{
              onFocus: (event) => handleFieldFocus(event.target.name),
              onBlur: (event) => handleFieldBlur(event.target.name),
            }}
            onChange={onChangeFields}
            size="small"
          />
          <TextField
            disabled={from === "onpopup" || isInfoEditable}
            label="Last Name"
            name="lastname"
            id="parentInfoLName"
            variant="outlined"
            InputLabelProps={{
              shrink:
                focusStates["lastname"] ||
                Boolean(profileDetails.parentinfo["lastname"]),
            }}
            fullWidth
            value={profileDetails.parentinfo.lastname}
            InputProps={{
              onFocus: (event) => handleFieldFocus(event.target.name),
              onBlur: (event) => handleFieldBlur(event.target.name),
            }}
            onChange={onChangeFields}
            size="small"
          />
        </div>
        <TextField
          disabled={from === "onpopup" || isInfoEditable}
          label="Email Address"
          name="email"
          id="parentInfoEmail"
          value={profileDetails.parentinfo.email}
          InputLabelProps={{
            shrink:
              focusStates["email"] ||
              Boolean(profileDetails.parentinfo["email"]),
          }}
          inputProps={{
            onFocus: (event) => handleFieldFocus(event.target.name),
            onBlur: (event) => handleFieldBlur(event.target.name),
            type: "email",
          }}
          fullWidth
          onChange={onChangeFields}
          variant="outlined"
          size="small"
        />
        <div className="parentInfoPhoneDOBSec">
          <div style={{ width: "47%" }}>
            {/* <InputGroupField
              disabled={from === "onpopup" || isInfoEditable}
              groupText="+91"
              placeholder="Phone Number"
              name="mobile"
              value={profileDetails.parentinfo.mobile}
              onChange={onChangeFields}
            /> */}
            <PhoneInput
              placeholder="Enter Number"
              onlyCountries={["us", "in"]}
              value={profileDetails.parentinfo.mobile}
              onChange={(ph) => onchangeMobile(ph)}
              disabled={from === "onpopup" || isInfoEditable}
            />
          </div>
        </div>
      </form>
      {from !== "onpopup" && (
        <div className="parentInfoBtnContainer">
          <Button
            onClick={handlePrevious}
            size="sm"
            hierarchy={{ type: "secondaryGrey", buttonText: "Previous" }}
          />
          <Button
            onClick={handleNext}
            size="sm"
            hierarchy={{ type: "primary", buttonText: "Next" }}
          />
        </div>
      )}
    </React.Fragment>
  );
}

export default ParentInfo;
