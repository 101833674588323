import constants from "../../constants";
import React, { useContext } from "react";
import AlertContext from "../../context/AlertContext";
import CodingPageSuccessNotify from "./CodingPageSuccessNotify";
import CodingPageTestCasesFailedNotify from "./CodingPageTestCasesFailedNotify";

export default function NotifyBody(Props) {
  const Alert = useContext(AlertContext);

  return (
    <div className="notifyBodyMainContainer">
      {Props.component ===
      "codingPageNotifyMsgForInfiniteLoopWhileSubmitting" ? (
        <ul className="modalInstructionBodyunorderedListWithTestCasesFailed">
          <div>Something went wrong! program is taking too long to finish.</div>
          <div className="modalInstructionBodyTestCasesFailedTextList">
            Your code did not pass our test cases
          </div>
          <li className="modalInstructionBodyTestCasesFailedTextList">
            Try checking your code with all the test cases.
          </li>
          <li className="modalInstructionBodyTestCasesFailedTextList">
            Ensure you create new test cases and cover all the scenarios.
          </li>
          <li className="modalInstructionBodyTestCasesFailedTextList">
            Click on “Submit” if you want to still submit.
          </li>
          <li className="modalInstructionBodyTestCasesFailedTextList">
            Click on “Try Again” to continue with the current test.
          </li>
        </ul>
      ) : Props.component ===
        "mcqAndDescPageNotifyMsgForSuccessWhileSubmitting" ? (
        <div className={Props.component}>{Props.msg}</div>
      ) : Props.component === "codingPageNotifyMsgForSuccessWhileSubmitting" ? (
        <CodingPageSuccessNotify {...Props} />
      ) : Props.component ===
        "codingPageNotifyMsgForFailedTestCasesWhileSubmitting" ? (
        <CodingPageTestCasesFailedNotify {...Props} />
      ) : Props.component ===
        "codingPageNotifyMsgForFailedTestCasesStaticWhileSubmitting" ? (
        <ul className="modalInstructionBodyunorderedListWithTestCasesFailed">
          <div>Your code did not pass our testcases.</div>
          <div className="modalInstructionBodyTestCasesFailedTextList">
            As a next step
          </div>
          <li className="modalInstructionBodyTestCasesFailedTextList">
            Try checking your code with all the test cases.
          </li>
          <li className="modalInstructionBodyTestCasesFailedTextList">
            Ensure you create new test cases and cover all the scenarios.
          </li>
          <li className="modalInstructionBodyTestCasesFailedTextList">
            Click on “Submit” if you want to still submit.
          </li>
          <li className="modalInstructionBodyTestCasesFailedTextList">
            Click on “Try Again” to continue with the current test.
          </li>
        </ul>
      ) : Props.component ===
        "codingPageNotifyMsgForTimeLapsedsWhileSubmitting" ? (
        <ul>
          <li className="modalInstructionBodyTextList">
            You took too long for this{" "}
            {Alert.testData.testtype === constants.libTypeIds.CODING_CHALLENGE
              ? "coding challenge"
              : Alert.testData.testtype === constants.libTypeIds.MCQ
              ? "Multiple Choice Question"
              : "Descriptive Test"}
            . You were given a fixed time and it lapsed.
          </li>
          <li className="modalInstructionBodyTextList">
            You can close this challenge now, and you will be taken back to the
            course home page. You will be given another challenge of same
            difficulty.
          </li>
          <li className="modalInstructionBodyTextList">
            If you have tried all the test, you will need to wait for trainer to
            help.
          </li>
        </ul>
      ) : Props.component ===
        "codingPageNotifyMsgForTabSwitchesWhileSubmitting" ? (
        <ul>
          <li className="modalInstructionBodyTextList">
            We have detected you have been switching tabs. Your submission is
            flagged and has been auto-submitted.
          </li>
          <li className="modalInstructionBodyTextList">
            As you are taking a coding challenge, we will give you another
            attempt to clear this challenge, but the problem statement will be
            replaced with another problem of similar complexity.
          </li>
          <li className="modalInstructionBodyTextList">
            If you have exhausted maximum number of attempts you will loose
            chance to take up this challenge level.
          </li>
        </ul>
      ) : Props.component === "instructionPageNotifyMsgForRegistration" ? (
        <div>
          <ul>
            <li>
              Looks like this is the first time, you are taking a proctored test
              in the Gradious LEAP platform.
            </li>
            <br />
            <li>
              As a first step, we would need to capture a photo of you so that
              we know who is taking the test.
            </li>
            <br />
            <li>
              Note, tests in the Gradious LEAP platform are proctored. That
              means, we know when{" "}
            </li>
          </ul>
          <ol style={{ marginLeft: "30px" }}>
            <li>
              A candidate is switching windows. In fact, your test will be
              automatically invalidated if the system finds this happening.{" "}
            </li>
            <br />
            <li>
              Captures your photo, while you are taking the test. And checks for
              the candidate photo matching, multiple people in the frame, use of
              other electronic devices, and also candidates eye movements to
              detect any kind of cheating.
            </li>
          </ol>
          <ul>
            <li>Please click on continue to move forward and take the test.</li>
          </ul>
        </div>
      ) : (
        (Props.component === "instructionPageNotifyMsgForCameraAccess" ||
          Props.component === "instructionPageNotifyMsgForAudioAccess") && (
          <div className={Props.component}>
            Your
            {Props.component === "instructionPageNotifyMsgForCameraAccess"
              ? " camera "
              : " microphone "}
            is not accessible. This test has to be taken in proctoring mode by
            enabling the{" "}
            {Props.component === "instructionPageNotifyMsgForCameraAccess"
              ? " camera."
              : " microphone."}
            <p>
              Please ensure your
              {Props.component === "instructionPageNotifyMsgForCameraAccess"
                ? " camera "
                : " microphone "}
              is accessible.
            </p>
          </div>
        )
      )}
    </div>
  );
}
