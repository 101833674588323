import "./LoginPage.scss";
import axios from "../../api/axios";
import jwt_decode from "jwt-decode";
import useAuth from "../../Hooks/useAuth.js";
import constants from "../../constants.js";
import { useLocation } from "react-router-dom";
import { getDataFromStorage } from "../../util";
import AlertContext from "../../context/AlertContext.js";
import Notify from "../Notification-Loading/Notify/Notify";
import React, { useContext, useEffect, useRef } from "react";
import Loading from "../Notification-Loading/Loading/Loading";
// import useRequestPermissionForNotification from "../../Hooks/useRequestPermissionForNotification.js";

// const messaging = getMessaging(app);
// import app from '../../firebaseclent';
// import Header from "../Header/Header";
// import { getMessaging, getToken } from "firebase/messaging";

const LoginPage = () => {
  const {
    msg,
    show,
    setMsg,
    setShow,
    isLoaded,
    register,
    navigate,
    setIsAdmin,
    setIsLoaded,
    handleClose,
    setIsFirstLoggedIn,
    setUserProfilePic,
  } = useContext(AlertContext);

  let role = getDataFromStorage("role");

  const {  setAuth } = useAuth();

  // const { requestPermissionForNotification } =
  //   useRequestPermissionForNotification();
  // const [openPopup, setOpenPopup] = useState(false);
  let location = useLocation();

  const from = useRef(location?.state?.from?.pathname || "/");

  // async function sendTokenToServer(token) {
  //   let userId = getDataFromStorage('learnerid')
  //   const response = await axios.post(process.env.REACT_APP_NODE_API + 'node/learner/pushtoken/save', JSON.stringify({ token, permissionStatus: Notification.permission === 'granted', userId }), {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': localStorage.getItem('tokenKey')
  //     }
  //   });
  // }

  // const checkNotificationPermission = () => {
  //   // Request permission for notifications
  //   if (!("Notification" in window)) {
  //     // Check if the browser supports notifications
  //     alert("This browser does not support desktop notification");
  //   } else {
  //     Notification.requestPermission()
  //       .then(permission => {
  //         if (permission === 'granted') {
  //           // Permission granted, now get the FCM token
  //           return getToken(messaging);
  //         } else {
  //           sendTokenToServer("")
  //         }
  //       })
  //       .then(token => {
  //         if (token) {
  //           sendTokenToServer(token).catch((err) => console.error(err));
  //         }
  //       })
  //       .catch(error => {
  //         console.error('Error:', error);
  //       });
  //   }
  // }

  useEffect(() => {
    if (
      !localStorage.tokenKey
      // !auth.hasOwnProperty('token') || auth.token === null || auth.token === undefined
    ) {
      /* global google*/

      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
      });
      google.accounts.id.renderButton(
        document.getElementById("signInBtn"),
        { theme: "outline", size: "large" } // customization attributes
      );
      google.accounts.id.prompt(); // also display the One Tap dialog

      async function handleCredentialResponse(response) {
        setIsLoaded(true);

        try {
          const validateResponse = await axios.post(
            "node/auth/validate",
            {
              jwtcredential: response.credential,
              refreshToken: localStorage.getItem("refreshToken"),
            },
            {
              headers: {
                Authorization: "abdjcdifouvn",
                "Content-type": "application/json",
              },
              // withCredentials: true,
            }
          );

          let token = validateResponse.data.accessToken;
          let refreshToken = validateResponse.data.refreshToken;
          localStorage.setItem("picture", validateResponse.data.picture);
          setUserProfilePic(validateResponse.data.picture);

          if (token && refreshToken) {
            setAuth({ token, refreshToken });
            localStorage.setItem("tokenKey", token);
            localStorage.setItem("refreshToken", refreshToken);

            //decoding the jwt access token comming from server

            let result = jwt_decode(token);

            if (result.role === constants.Roles.learner) {
              setIsAdmin(false);
              if (result.lastlogin === null) {
                setIsFirstLoggedIn(true);
                from.current = "/registration";
                navigate("/registration", { replace: true });
              } else if (result.lastlogin !== null) {
                register.current = result.registered;
                setIsFirstLoggedIn(false);
                from.current = "/batch";
                navigate("/batch", { replace: true });
              }

              // setLoginStatus(true);
            } else if (
              result.role === constants.Roles.admin ||
              result.role === constants.Roles.trainer 
             
            ) {
              setIsAdmin(true);
              from.current = "/batches";
              navigate("/batches", { replace: true });

              // setLoginStatus(true);
            } else if( result.role === constants.Roles.hr){
              setIsAdmin(false);
              from.current = "/batches";
              navigate("/batches", { replace: true });
            }else if (result.role=== constants.Roles.parent){
              from.current = "/batch";
              navigate("/batch",{replace:true})

            }
            else {
              setShow(true);
              setMsg("Email does not exist");
              navigate("/");
            }
          } else {
            setShow(true);
            setMsg(validateResponse.data.message);
          }
        } catch (err) {
          setShow(true);
          setMsg(
            "The email id does not exit. Please contact our gradious team"
          );
        } finally {
          setIsLoaded(false);
        }
      }
    } else if (localStorage.getItem("tokenKey")) {
      //GetDataFromStorage("role")

      if (role === constants.Roles.learner|| role === constants.Roles.parent) {
        setIsAdmin(false);
        register.current = 1;
        navigate("/batch", { replace: true });

        // setLoginStatus(true);
      } else if (
        role === constants.Roles.admin ||
        role === constants.Roles.trainer 
       
      ) {
        setIsAdmin(true);
        navigate("/batches", { replace: true });

        // setLoginStatus(true);
      }else if( role === constants.Roles.hr){
        setIsAdmin(false);
        navigate("/dashboards", { replace: true });

      }
    }

    // }
  }, []);

  return (
    <>
      <div className="loginpg"></div>
      <form className="loginForm">
        <div className="image">
          <img
            alt="Gradious"
            className="logo1"
            style={{ width: "200px" }}
            src="https://gradious.com/wp-content/uploads/2021/09/Final-Logo-2.svg"
          />
        </div>
        <div className="tabs">Sign In</div>
        <div className="signInForm">
          <button id="signInBtn"> SignIn with google </button>
        </div>
      </form>
      <Notify
        show={show}
        message={msg}
        title="Warning !"
        onHide={handleClose}
      />
      {isLoaded && <Loading />}
     
    </>
  );
};

export default LoginPage;

{
  /* <div className="body"> */
}
{
  /* <div className="shifting"> */
}
{
  /* <svg
            width="60%"
              height="100vh"
              viewBox="0 0 921 768"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_75)">
                <rect width="921" height="768" fill="url(#paint0_linear_1_75)" />
                <g clip-path="url(#clip1_1_75)">
                  <rect
                    width="1466"
                    height="1061"
                    transform="matrix(0.928914 -0.370296 0.582397 0.812904 -820.931 267.135)"
                    fill="url(#paint1_linear_1_75)"
                  />
                  <path
                    d="M-441.063 573.866C-550.025 651.946 -675.353 570.519 -724.397 520.045L-822.497 559.151L-388.109 1165.47L1362.39 467.659L789.878 -331.444C591.534 -319.637 527.414 -169.692 500.99 -16.3466C474.566 136.999 388.225 300.409 68.8178 274.788C-250.59 249.168 -304.861 476.267 -441.063 573.866Z"
                    fill="url(#paint2_linear_1_75)"
                  />
                  <path
                    d="M-140.25 862.583C-241.445 927.183 -339.323 874.192 -375.613 839.621L-463.347 874.595L-159.149 1299.19L1406.39 675.114L1005.47 115.509C835.355 136.221 764.559 251.548 725.483 367.135C686.407 482.722 595.24 609.396 326.12 609.569C57.0007 609.741 -13.7557 781.833 -140.25 862.583Z"
                    fill="url(#paint3_linear_1_75)"
                  />
                </g>
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_1_75"
                  x1="-460.5"
                  y1="384"
                  x2="294.998"
                  y2="1290.01"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FF512C" />
                  <stop offset="1" stop-color="#FC5457" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_1_75"
                  x1="529.5"
                  y1="507.5"
                  x2="592.5"
                  y2="1037.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F55533" />
                  <stop offset="1" stop-color="#F55533" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_1_75"
                  x1="658.577"
                  y1="437.375"
                  x2="-190.922"
                  y2="410.741"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" stop-opacity="0.6" />
                  <stop offset="1" stop-color="white" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_1_75"
                  x1="810.561"
                  y1="694.95"
                  x2="118.63"
                  y2="569.773"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" stop-opacity="0.6" />
                  <stop offset="1" stop-color="white" />
                </linearGradient>
                <clipPath id="clip0_1_75">
                  <rect width="921" height="768" fill="white" />
                </clipPath>
                <clipPath id="clip1_1_75">
                  <rect
                    width="2181.5"
                    height="1636.72"
                    fill="white"
                    transform="matrix(0.928914 -0.370296 0.582397 0.812904 -689.455 367.305)"
                  />
                </clipPath>
              </defs>
            </svg> */
}

{
  /* <div className="bg-gradient-yellow"></div>
  
            <div className="bg">
            <div className="vector10"></div>
  
            <div className="rectangle"></div>
  
            <div className="vector12"></div>
  
  
  
  
              
              
            </div> */
}
{
  /* </div> */
}
{
  /* <div> */
}
