import React from "react";
import {
    createTheme,
    ThemeProvider
} from "@mui/material/styles";
import {
    Container,
    Box,
    Paper,
    Typography,
    Grid,
    Tabs,
    Tab,
    Divider,
    Stack,
    Chip
} from "@mui/material";
import { ViewStream, ViewHeadline } from "@mui/icons-material/";

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}
const theme = createTheme({
    typography: {
        fontFamily: "Inter, sans-serif" // desired font family
    }
});

function Hr() {
    const [value, setValue] = React.useState(0);
    const [expanded, setExpanded] = React.useState("panel1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const [checked, setChecked] = React.useState([0]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        <ThemeProvider theme={theme}>
            <div
                style={{
                    background: "#F2F3F5",
                    minHeight: "100vh",
                    paddingBottom: "5px"
                }}
            >
                <Box
                    sx={{
                        display: "grid",
                        width: "100%"
                        // gridTemplateColumns: { md: "1fr" },
                        // gap: 2
                    }}
                >
                    <Paper elevation={2}>
                        <Container>
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography variant="h4" component="h3">
                                        Applications
                                    </Typography>
                                </Grid>
                                <Grid container item justifyContent="space-between">
                                    <Grid item xs={8}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="nav tabs example"
                                        >
                                            <LinkTab label="All applies 112" href="/drafts" />
                                            <LinkTab label="Shortlisted  0" href="/trash" />
                                            <LinkTab label="Rejected" href="/spam" />
                                        </Tabs>
                                    </Grid>
                                    <Grid item marginTop="15px">
                                        <ViewStream />
                                        <ViewHeadline />
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Grid>
                            <Grid item>
                                <Stack direction="row" spacing={1} my={2}>
                                    <Chip label="All 112" variant="outlined" />
                                    <Chip label="New applies 3" variant="outlined" />
                                    <Chip label="Not applied 112" variant="outlined" />
                                    <Chip label="Action pending 112" variant="outlined" />
                                </Stack>
                            </Grid>
                        </Container>
                    </Paper>
                    <Container>
                        <Box
                            sx={{
                                width: "100%"
                            }}
                        >
                            
                        </Box>
                    </Container>
                </Box>
            </div>
        </ThemeProvider>
    );
}

export default Hr;
