import constants from "../constants";
import TestDueHooks from "./TestDueHooks";
import { getDataFromStorage } from "../util";
import useAxiosPrivate from "./useAxiosPrivate";
import ContentPageHooks from "./ContentPageHooks";
import AlertContext from "../context/AlertContext";
import { useContext, useRef, useState } from "react";
import AssignmentPageHooks from "./AssignmentPageHooks";
import useCommonFunctionHooks from "./useCommonFunctionHooks";

export default function LeftNavBarHooks() {
  const {
    lpId,
    testId,
    module,
    setLpId,
    navigate,
    testData,
    setLpData,
    setTestId,
    setStatus,
    setModule,
    setTestData,
    setIsLoaded,
    setShowMain,
    setShowNotify,
    setTestActive,
    setContentData,
    setModuleActive,
    setUpdateLeftNav,
    setShowInstruction,
    setNavhome,
    setBatchId,
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { currentDueLabel } = TestDueHooks();
  const { handleContent } = ContentPageHooks();
  const { handleAssignment } = AssignmentPageHooks();
  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const activeTestRef = useRef(null);

  const [collapsed, setCollapsed] = useState(false);

  const openModule = (module) => {
    setModuleActive(true);
    setModule({ id: module.modid, name: module.modname });
  };

  const openTest = (test) => {
    setLpId(parseInt(localStorage.getItem("lpId")));

    if (
      document.fullscreenElement &&
      (test.testtype !== constants.libTypeIds.MCQ ||
        test.testtype !== constants.libTypeIds.DESCRIPTIVE) &&
      test.status !== constants.PROGRESS_STATUS.UNLOCKED &&
      test.status !== constants.PROGRESS_STATUS.REATTEMPT &&
      test.status !== constants.PROGRESS_STATUS.QN_CONTINUE
    ) {
      document.exitFullscreen();
    }

    setTestData(test);
    setContentData({});
    setShowMain(false);
    setTestActive(true);
    setStatus(test.status);
    setShowInstruction(false);
    setTestId(test.testid !== null ? test.testid : test.libid);

    if (test.libtype === constants.libTypeIds.CONTENT) {
      handleContent(
        test.libtype,
        test.lpconfigid,
        test.libid,
        test.status,
        test.duedate
      );
    } else if (test.libtype === constants.libTypeIds.ASSIGNMENT) {
      if (document.fullscreenElement) {
        document.exitFullscreen();

        setShowInstruction(false);
      }

      handleAssignment(
        test.libtype,
        test.lpconfigid,
        test.libid,
        test.status,
        test.duedate
      );
    } else if (test.testtype === constants.libTypeIds.CODING_CHALLENGE) {
      if (document.fullscreenElement) {
        document.exitFullscreen();

        setShowInstruction(false);
      }
    } else {
      if (
        (localStorage.getItem("mockLp") ||
          currentDueLabel(test.duedate) !== "Past Due") &&
        test.status !== constants.PROGRESS_STATUS.EVALUATED &&
        test.status !== constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
        test.status !== constants.PROGRESS_STATUS.PARTIAL_EVALUATED
      ) {
        document.documentElement.requestFullscreen().catch((err) => err);
        setShowInstruction(true);
      }
    }

    setShowMain(true);
  };

  const calculatePercentage = (completedItems, totalItems) => {
    if (totalItems !== 0) {
      let percentage = Math.round((completedItems / totalItems) * 100);

      return percentage;
    } else {
      return 0;
    }
  };

  const gotToDashboard = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    setLpData({});
    setTestData({});
    navigate(
      localStorage.getItem("mockLp")
        ? "mock/library"
        : localStorage.getItem("isTestBatch")
        ? "/batch"
        : "/landingpage"
    );

    if (localStorage.getItem("mockLp")) {
      localStorage.removeItem("mockLp");
    }

    if (localStorage.getItem("isTestBatch") === "true") {
      localStorage.setItem("isTestBatch", false);
      localStorage.removeItem("batchid");
      localStorage.removeItem("batchname");
      setBatchId(0);
      setNavhome(false);
    }
  };

  const getTestForOrderMandate = (moduleData) => {
    for (let i = 0; i < moduleData.length; i++) {
      for (let j = 0; j < moduleData[i].data.length; j++) {
        let test = moduleData[i].data[j];

        if (
          test?.status === constants.PROGRESS_STATUS.UNLOCKED ||
          test?.status === constants.PROGRESS_STATUS.REATTEMPT ||
          test?.status === constants.PROGRESS_STATUS.QN_CONTINUE ||
          test?.status === constants.PROGRESS_STATUS.IN_PROGRESS
        ) {
          setModuleActive(true);
          setModule({
            id: moduleData[i].modid,
            name: moduleData[i].modname,
          });

          return openTest(test);
        } else if (
          moduleData[i].modid === module.id &&
          test.testid === testId
        ) {
          return openTest(test);
        }
      }
    }
  };

  const getTest = (lpData) => {
    for (let i = 0; i < lpData.modules.length; i++) {
      setModuleActive(true);
      setModule({
        id: lpData.modules[i].modid,
        name: lpData.modules[i].modname,
      });

      for (let j = 0; j < lpData.modules[i].data.length; j++) {
        if (
          lpData.modules[i].data[j].status ===
            constants.PROGRESS_STATUS.UNLOCKED ||
          lpData.modules[i].data[j].status ===
            constants.PROGRESS_STATUS.REATTEMPT ||
          lpData.modules[i].data[j].status ===
            constants.PROGRESS_STATUS.QN_CONTINUE ||
          lpData.modules[i].data[j].status ===
            constants.PROGRESS_STATUS.IN_PROGRESS
        ) {
          return lpData.modules[i].data[j];
        }
      }
    }

    setModuleActive(true);
    setModule({
      id: lpData.modules[0].modid,
      name: lpData.modules[0].modname,
    });

    return lpData.modules[0].data[0];
  };

  const handleGetDataForHome = () => {
    if (lpId === 0) {
      setLpId(parseInt(localStorage.getItem("lpId")));
    }

    setUpdateLeftNav(false);
    setIsLoaded(true);
    axios
      .post(
        "node/learner/course/get",
        JSON.stringify({
          lpid: localStorage.getItem("lpId"),
          learnerid: getDataFromStorage("learnerid"),
        }),
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR) {
          setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.msg,
          });
        } else {
          if (res.data.resultCode === constants.RESULT_STATUS.NO_DATA) {
            setShowNotify({
              show: true,
              title: "Info",
              msg: "Learningpath is not published",
            });
          } else {
            setLpId(res.data.data.courses[0].lpid);

            res.data.data.courses[0].modules.sort(
              (module1, module2) =>
                module1.moduleorderno - module2.moduleorderno
            );

            res.data.data.courses[0].modules.forEach((module) => {
              module.data.sort((item1, item2) => item1.orderno - item2.orderno);
            });

            if (res.data.data.courses[0].useorder !== 0) {
              getTestForOrderMandate(res.data.data.courses[0].modules);
            } else {
              if (testData.pathName === "dashBoard") {
                res.data.data.courses[0].modules.map((moduleData) =>
                  moduleData.data.filter(
                    (test) =>
                      test.testid === testData.testId &&
                      moduleData.modid === testData.moduleId &&
                      (setModuleActive(true),
                      setModule({
                        id: moduleData.modid,
                        name: moduleData.modname,
                      }),
                      openTest(test))
                  )
                );
              } else {
                openTest(getTest(res.data.data.courses[0]));
              }
            }

            setLpData(res.data.data.courses[0]);
          }
        }
      })
      .catch((error) => {
        getCatchBlockDetails(error);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  return {
    openTest,
    collapsed,
    openModule,
    setCollapsed,
    activeTestRef,
    gotToDashboard,
    calculatePercentage,
    handleGetDataForHome,
  };
}
