import React, { useContext, useEffect, useState } from "react";
import Badge from "../../../CommonComponents/Badge";
import Button from "../../../Button";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import AlertContext from "../../../../context/AlertContext";
import _ from "lodash";
import constants from "../../../../constants";
import Container from "../Report/DropdownContainer";

const badgeColor = { 1: "#2E90FA", 2: "#e54b2a", 3: "#c11574" };

const findNameById = (id, transformedData) => {
  for (const item of transformedData) {
    for (const child of item.children) {
      if (child.value === id) {
        return child.label;
      }
    }
  }
  return null;
};

const mapIdsToNames = (sidObject, transformedData) => {
  const assessments = JSON.parse(sidObject.assessments)?.map((id) => ({
    id,
    name: findNameById(id, transformedData),
  }));
  const assignments = JSON.parse(sidObject.assignments)?.map((id) => ({
    id,
    name: findNameById(id, transformedData),
  }));
  const video = sidObject.videoid && [
    {
      id: sidObject.videoid,
      name: findNameById(sidObject.videoid, transformedData),
    },
  ];
  return { 2: assessments, 1: assignments, 3: video };
};

const markCheckedNodes = (data, idsToCheck) => {
  return data.map((item) => ({
    ...item,
    children: item.children.map((child) => ({
      ...child,
      checked: idsToCheck.includes(child.value),
    })),
  }));
};

const collectSelectedNodes = (nodes, collected = [], transformedData) => {
  nodes.forEach((node) => {
    if (!node.hasOwnProperty("isModule"))
      collected.push({ id: node.value, name: node.label });
    if (node._children && node._children.length > 0) {
      const filterNode = _.filter(
        transformedData,
        (item) => item.value === node.value
      );
      collectSelectedNodes(
        filterNode[0]?.children ?? [],
        collected,
        transformedData
      );
    }
  });
  return collected;
};

const SkillBody = ({
  bSkillId,
  open,
  setSkillsCollapse,
  batchTests,
  setBatchTests,
  setTestOptions,
  testOptions,
  allTests,
}) => {
  const { id } = useParams();
  const axios = useAxiosPrivate();
  const { setIsLoaded, setShowNotify } = useContext(AlertContext);
  const [skillTests, setSkillTests] = useState({});
  const [tests, setTests] = useState({
    1: [],
    2: [],
    3: [],
  });

  useEffect(() => {
    if (!open || !bSkillId) return;
    const fetchSkillTests = async () => {
      setIsLoaded(true);
      try {
        let response = await axios.get(
          `node/admin/batch/${id}/skills/${bSkillId}/tests`,
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        );
        // console.log("response of fetch skill tests", response);
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          if (response.data.data) {
            setSkillTests(JSON.parse(response.data.data));
          }
        } else if (
          response.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR
        ) {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: response.data.msg,
          });
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
        }
      } catch (error) {
        console.error("error while fetch batch skill tests", error);
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
      } finally {
        setIsLoaded(false);
      }
    };
    fetchSkillTests();
  }, [id, axios, setIsLoaded, bSkillId, open, setShowNotify]);

  useEffect(() => {
    if (skillTests && Object.keys(skillTests).length > 0) {
      const mapped = mapIdsToNames(skillTests, allTests.current);
      const assessments = JSON.parse(skillTests.assessments || "[]");
      const assignments = JSON.parse(skillTests.assignments || "[]");
      const idsToCheck = [skillTests.videoid, ...assessments, ...assignments];

      const updatedTransformedData = markCheckedNodes(
        allTests.current,
        idsToCheck
      );
      // console.log("updatedTransformedData", updatedTransformedData);
      setBatchTests(updatedTransformedData);
      setTestOptions((prev) => ({
        1: markCheckedNodes(prev[1], assignments),
        2: markCheckedNodes(prev[2], assessments),
        3: markCheckedNodes(
          prev[3],
          skillTests.videoid ? [skillTests.videoid] : []
        ),
      }));
      setTests(mapped);
    }
  }, [skillTests, allTests, setBatchTests, setTestOptions]);

  const onSelectTest = (currentNode, selectedNodes, index) => {
    const selected = collectSelectedNodes(selectedNodes, [], batchTests);
    if (index === 3 && selected.length > 1) {
      const updatedBatchTests = markCheckedNodes(batchTests, tests[index]);
      setBatchTests(updatedBatchTests);
      setTestOptions((prev) => ({
        ...prev,
        [index]: markCheckedNodes(prev[index], tests[index]),
      }));
      setShowNotify({
        show: true,
        title: "Warning !",
        msg: "You can only map one video assessment to a skill.",
      });
    } else setTests((prev) => ({ ...prev, [index]: [...selected] }));
  };

  const onDeleteTest = (index, id) => {
    let newDetails = tests[index];
    newDetails = _.filter(newDetails, (item) => item.id !== id);
    const updatedBatchTests = markCheckedNodes(batchTests, newDetails);
    setBatchTests(updatedBatchTests);
    setTestOptions((prev) => ({
      ...prev,
      [index]: markCheckedNodes(prev[index], newDetails),
    }));
    allTests.current = updatedBatchTests;
    setTests((prev) => ({ ...prev, [index]: newDetails }));
  };

  const onSave = async (event) => {
    event.preventDefault();
    if (bSkillId) {
      const payload = {
        assignments: tests[1],
        assessments: tests[2],
        video: tests[3],
      };
      setIsLoaded(true);
      try {
        let response = await axios.post(
          `node/admin/batch/${id}/skills/${bSkillId}/tests`,
          payload,
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        );
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          setShowNotify({
            show: true,
            title: "Info",
            msg: "Tests are mapped successfully.",
          });
          setSkillsCollapse((prev) => ({ ...prev, [bSkillId]: false }));
        } else
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
      } catch (error) {
        setShowNotify({
          show: true,
          title: error.message.includes("403") ? "Warning !" : "Error !",
          msg: error.message.includes("403")
            ? "You have been logged-out due to inactivity. Login again."
            : "Something went wrong. Please try again later or contact gradious team.",
          isUnAuth: error.message.includes("403"),
        });
      } finally {
        setIsLoaded(false);
      }
    }
  };

  return (
    <div className="skillBodyContainer">
      <div className="skillBodyMapContainer">
        <div className="skillBodyMapSec">
          <div className="skillBodyAssignmentContainer">
            {Object.keys(tests).map((data, index) => (
              <div key={index} className="skillBodyAssignmentSec">
                <div className="skillBodyAssignmentTitle">
                  Select{" "}
                  {index === 0
                    ? "Assignments"
                    : index === 1
                    ? "Assessments"
                    : "Video Test"}{" "}
                  related to skill{" "}
                  <Badge
                    size="sm"
                    label={tests[index + 1]?.length ?? 0}
                    color="grey"
                  />
                </div>
                <div className="skillBodyAssignmentInputSec">
                  <Container
                    id={index}
                    className="skillBodyAssignmentInput"
                    keepTreeOnSearch={true}
                    keepOpenOnSelect={false}
                    onChange={(currentNode, selectedNodes) =>
                      onSelectTest(currentNode, selectedNodes, index + 1)
                    }
                    data={testOptions[index + 1]}
                    showPartiallySelected={true}
                    texts={{
                      placeholder: `Select ${
                        index === 0
                          ? "Assignments"
                          : index === 1
                          ? "Assessments"
                          : "Video Test"
                      }`,
                    }}
                  />
                </div>
                <div className="skillBodyAssignments">
                  {_.map(tests[index + 1], (item, testIndex) => (
                    <Badge
                      key={testIndex}
                      label={item.name}
                      icon={{
                        icon: (
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => onDeleteTest(index + 1, item.id)}
                          >
                            <path
                              d="M9 3L3 9M3 3L9 9"
                              stroke={badgeColor[index + 1]}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ),
                        position: "end",
                      }}
                      color={
                        index === 0 ? "blue" : index === 1 ? "primary" : "pink"
                      }
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="skillBodyMapBtnSec">
            <Button
              size="sm"
              hierarchy={{ type: "primary", buttonText: "Save" }}
              onClick={onSave}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SkillBody);
