import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import constants from "../../../constants";
import { FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { Editor } from "@tinymce/tinymce-react";
import IconButton from "@mui/material/IconButton";
import { getDataFromStorage } from "../../../util";
import BackSvgIcon from "../../../Svg/BackSvgIcon";
import GridViewSvg from "../../../Svg/GridViewSvg";
import ListViewSvg from "../../../Svg/ListViewSvg";
import BookmarkSvg from "../../../Svg/BookmarkSvg";
import RightSvgIcon from "../../../Svg/RightSvgIcon";
import Checkbox from "../../CommonComponents/Checkbox";
import BookmarkedSvg from "../../../Svg/BookmarkedSvg";
import AlertContext from "../../../context/AlertContext";
import WobackCloseSvg from "../../../Svg/WobackCloseSvg";
import React, { useContext, useEffect, useRef } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import ButtonMedium from "../../Buttons/ButtonMedium/ButtonMedium";
import BackdropCounter from "../../CommonComponents/BackdropCounter";
import { StudentTestPageContext } from "../../../context/StudentTestPageContext";
import StudentTestPageHooks from "../../../Hooks/StudentTestPage/StudentTestPageHooks";
import { useParams } from "react-router-dom";

const parse = require("html-react-parser");

const PrettoSlider = styled(Slider)({
  color: "#F55533",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#EAECF0",
  },
  "& .MuiSlider-thumb": {
    width: 24,
    height: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    width: 32,
    padding: 0,
    height: 32,
    fontSize: 12,
    lineHeight: 1.2,
    background: "unset",
    backgroundColor: "#101828",
    borderRadius: "50% 50% 50% 0",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

export default function StudentTestBodyPage(Props) {
  const Alert = useContext(AlertContext);
  const StudentTestPageCxt = useContext(StudentTestPageContext);

  const StudentTestPageHks = StudentTestPageHooks();

  const { id } = useParams();

  /* for vdo qn */

  const textRef = useRef(null);

  /* for tiny mce error */

  const editorRef = useRef(null);

  useEffect(() => {
    return () => {
      if (editorRef.current) {
        // Clean up the editor instance

        editorRef.current.destroy();
      }
    };
  }, []);

  const showTranscriptFunc = () => {
    StudentTestPageCxt.setShowTranscript(!StudentTestPageCxt.showTranscript);
  };

  /* move to previous question */

  const traversePrevious = () => {
    let tempQueInd = Props.queInd;
    let tempSecInd = Props.secInd;

    if (
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].answered === -1
    ) {
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "not";
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "not";
    }

    if (
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].answered === 0
    ) {
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "completed";
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "completed";
    }

    if (Props.queLen > 1 && Props.queInd > 0) {
      tempQueInd--;

      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited;
      Props.setQueInd(Props.queInd - 1);
      StudentTestPageCxt.setNextButton(true);
    } else {
      tempSecInd--;
      Props.setSecInd(Props.secInd - 1);
      StudentTestPageCxt.setNextButton(true);
      tempQueInd = Alert.comingObj.sections[tempSecInd].questions.length - 1;

      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited;
      Props.setQueInd(tempQueInd);
    }

    if (tempSecInd === 0 && tempQueInd === 0) {
      StudentTestPageCxt.setPrevButton(false);
    }

    StudentTestPageHks.questionUpdate(tempSecInd, tempQueInd, Alert.comingObj);

    if (Props.status === constants.PROGRESS_STATUS.EVALUATED) {
      Props.setFilters(null);
    }
  };

  /* move to next question */

  const traverseNext = (key) => {
    let tempQueInd = Props.queInd;
    let tempSecInd = Props.secInd;

    if (
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].answered === -1
    ) {
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "not";
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "not";
    }

    if (
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].answered === 0
    ) {
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "completed";
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "completed";
    }

    if (
      Alert.comingObj.sections[tempSecInd].questions.length ===
        tempQueInd + 1 &&
      Alert.comingObj.sections?.length >= tempSecInd + 1
    ) {
      tempSecInd++;
      tempQueInd = 0;

      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited;

      StudentTestPageHks.questionUpdate(
        tempSecInd,
        tempQueInd,
        Alert.comingObj
      );
      StudentTestPageCxt.setPrevButton(true);
    } else {
      tempQueInd++;

      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited;

      StudentTestPageHks.questionUpdate(
        tempSecInd,
        tempQueInd,
        Alert.comingObj
      );
      StudentTestPageCxt.setPrevButton(true);
    }

    if (
      Alert.comingObj.sections?.length === tempSecInd + 1 &&
      Alert.comingObj.sections[tempSecInd].questions.length === tempQueInd + 1
    ) {
      StudentTestPageCxt.setPrevButton(true);
      StudentTestPageCxt.setNextButton(false);
    }

    if (Props.status === constants.PROGRESS_STATUS.EVALUATED) {
      Props.setFilters(null);
    }

    Props.setSecInd(tempSecInd);
    Props.setQueInd(tempQueInd);
  };

  /* collect score */

  const collectScores = (qid, scor, maxscore, e) => {
    if (isNaN(scor) === false) {
      let score = Number(scor);
      let tScore = StudentTestPageCxt.currentScore;
      let tempScores = StudentTestPageCxt.descScores;

      tempScores = { ...tempScores, [qid]: score };

      let tempErrors = StudentTestPageCxt.scoreErrors;

      if (score > maxscore || score === "" || score < 0) {
        if (Object.keys(tempErrors).length > 0) {
          StudentTestPageCxt.setHasScoreErrors(true);
        } else {
          StudentTestPageCxt.setHasScoreErrors(false);
        }

        if (score === "") {
          if (Object.keys(tempScores).map(Number)?.includes(qid)) {
            tScore = tScore - Alert.tsCoBkup[qid];

            if (Alert.ansStats.evalids?.includes(parseInt(qid))) {
              tScore = tScore + Alert.ansStats.idscore[qid];
            }
          } else {
            if (Alert.ansStats.evalids?.includes(parseInt(qid))) {
              tScore = tScore + Alert.ansStats.idscore[qid];
            }
          }

          StudentTestPageCxt.setDescScores(tempScores);
          delete tempScores[qid];

          tempErrors = { ...tempErrors, [qid]: true };
          StudentTestPageCxt.setScoreErrors(tempErrors);
        }

        if (score > maxscore) {
          tempScores = { ...tempScores, [qid]: maxscore };
          StudentTestPageCxt.setDescScores(tempScores);

          if (Object.keys(Alert.tsCoBkup).map(Number)?.includes(qid)) {
            tScore = tScore - Alert.tsCoBkup[qid];
          } else if (
            Alert.ansStats.evalids?.includes(Number(qid))
          ) {
            tScore = tScore - Alert.ansStats.idscore[qid];
          }

          tScore = tScore + maxscore;
          StudentTestPageCxt.setDescScores(tempScores);
        }

        if (score < 0) {
          tempScores = { ...tempScores, [qid]: 0 };
          StudentTestPageCxt.setDescScores(tempScores);
        }
      } else {
        if (tempErrors.hasOwnProperty(qid)) {
          delete tempErrors[qid];
        }

        if (Object.keys(tempScores).map(Number)?.includes(qid)) {
          if (Object.keys(Alert.tsCoBkup).map(Number)?.includes(qid)) {
            tScore = tScore - Alert.tsCoBkup[qid];
          } else if (
            Alert.ansStats?.evalids?.includes(Number(qid))
          ) {
            tScore = tScore - Alert.ansStats.idscore[qid];
          }

          tScore = tScore + score;
        } else {
          if (Alert.ansStats.evalids?.includes(parseInt(qid))) {
            tScore = tScore - Alert.ansStats.idscore[qid];
            tScore = tScore + score;
          } else {
            tScore = tScore + score;
          }
        }

        StudentTestPageCxt.setDescScores(tempScores);
      }

      StudentTestPageCxt.setCurrentScore(tScore);
      StudentTestPageCxt.setEvalComp(true);

      for (const property in tempScores) {
        if (tempScores[property] == null) {
          StudentTestPageCxt.setEvalComp(false);
        }
      }

      Alert.setTsCoBkup(tempScores);

      for (let i = 0; i < Alert.comingObj.sections?.length; i++) {
        for (let j = 0; j < Alert.comingObj.sections[i].questions.length; j++) {
          if (
            Alert.comingObj.sections[i].questions[j].answer === "" &&
            Alert.comingObj.sections[i].questions[j].id === qid
          ) {
            StudentTestPageCxt.setGiveZeros(false);
            break;
          }
        }
      }
    }
  };

  /* Collect ans and showing error msg */

  const collectAnswers = (
    queid,
    ansid,
    libtypeid,
    secindex,
    queindex,
    datatype
  ) => {
    if (datatype === 1) {
      if (/^[\d.\s\n]*$/.test(ansid)) {
        StudentTestPageCxt.setErrorMessage(false);
      } else {
        StudentTestPageCxt.setErrorMessage(true);
        StudentTestPageCxt.setQuestionIdOffError(queid);
      }
    }

    let tempData = Alert.comingObj;

    /* need to see secindex */

    if (libtypeid === constants.libTypeIds.MCQ_SINGLE) {
      let temp = Alert.answers;

      temp = { ...temp, [queid]: ansid };
      Alert.setAnswers(temp);

      if (Alert.questionContainerSingle) {
        Alert.queObj.visited = "completed";
        Alert.queObj.previsited = "completed";
        Alert.queObj.answered = 0;
      } else {
        tempData.sections[Props.secInd].questions[queindex].answered = 0;
        tempData.sections[Props.secInd].questions[queindex].visited =
          "completed";
        tempData.sections[Props.secInd].questions[queindex].previsited =
          "completed";
      }
    }

    if (libtypeid === constants.libTypeIds.MCQ_MULTI) {
      let temp = Alert.answers;
      let checkedAnswers = [];

      if (temp[queid] !== "") {
        let trimmedAnswer =
          temp[queid]?.length > 0
            ? temp[queid][0] === ","
              ? temp[queid].substring(1)
              : temp[queid]
            : "";

        temp[queid] = trimmedAnswer;
        checkedAnswers = temp[queid].split(",");

        const index = checkedAnswers.indexOf(ansid);

        if (index > -1) {
          // only splice array when item is found

          checkedAnswers.splice(index, 1); // 2nd parameter means remove one item only
        } else {
          checkedAnswers.push(ansid);
        }
      } else {
        checkedAnswers.push(ansid);
      }

      if (checkedAnswers.length === 0) {
        temp = { ...temp, [queid]: "" };
        Alert.setAnswers(temp);

        if (Alert.questionContainerSingle) {
          Alert.queObj.visited = "seen";
          Alert.queObj.previsited = "seen";
          Alert.queObj.answered = -1;
        } else {
          tempData.sections[Props.secInd].questions[queindex].visited = "seen";
          tempData.sections[Props.secInd].questions[queindex].previsited =
            "seen";
          Alert.queObj.answered = -1;
        }
      } else {
        temp = { ...temp, [queid]: checkedAnswers.toString() };
        Alert.setAnswers(temp);

        if (Alert.questionContainerSingle) {
          Alert.queObj.visited = "completed";
          Alert.queObj.previsited = "completed";
          Alert.queObj.answered = 0;
        } else {
          tempData.sections[Props.secInd].questions[queindex].visited =
            "completed";
          tempData.sections[Props.secInd].questions[queindex].previsited =
            "completed";
          tempData.sections[Props.secInd].questions[queindex].answered = 0;
        }
      }
    }

    if (
      libtypeid === constants.libTypeIds.SAQ ||
      libtypeid === constants.libTypeIds.LAQ
    ) {
      let temp = Alert.answers;

      temp = { ...temp, [queid]: ansid };
      Alert.setAnswers(temp);

      if (ansid.length === 0) {
        if (Alert.questionContainerSingle) {
          Alert.queObj.visited = "not";
          Alert.queObj.previsited = "not";
          Alert.queObj.answered = -1;
        } else {
          tempData.sections[Props.secInd].questions[queindex].visited = "not";
          tempData.sections[Props.secInd].questions[queindex].previsited =
            "not";
          tempData.sections[Props.secInd].questions[queindex].answered = -1;
        }
      } else {
        if (Alert.questionContainerSingle) {
          Alert.queObj.visited = "completed";
          Alert.queObj.previsited = "completed";
          Alert.queObj.answered = 0;
        } else {
          tempData.sections[Props.secInd].questions[queindex].visited =
            "completed";
          tempData.sections[Props.secInd].questions[queindex].previsited =
            "completed";
          tempData.sections[Props.secInd].questions[queindex].answered = 0;
        }
      }

      Alert.updateComingObj(tempData);
    }
  };

  /* Book mark questions */

  const reviewLater = (from, current, obj) => {
    let tempPropObj = Alert.comingObj;
    let temp = obj;

    if (current === "review") {
      StudentTestPageCxt.setShowReview(false);
      tempPropObj.sections[Props.secInd].questions[
        from === "single" ? Props.queInd : obj
      ].visited =
        tempPropObj.sections[Props.secInd].questions[
          from === "single" ? Props.queInd : obj
        ].previsited;
      tempPropObj.sections[Props.secInd].questions[
        from === "single" ? Props.queInd : obj
      ].review = -1;
    } else {
      StudentTestPageCxt.setShowReview(true);
      tempPropObj.sections[Props.secInd].questions[
        from === "single" ? Props.queInd : obj
      ].visited = "review";
      tempPropObj.sections[Props.secInd].questions[
        from === "single" ? Props.queInd : obj
      ].review = 0;
    }

    if (from === "single") {
      StudentTestPageHks.questionUpdate(
        Props.secInd,
        Props.queInd,
        tempPropObj
      );
      Alert.setQueObj(temp);
      Props.questionObjUpdate(Alert.queObj);
    }

    if (from === "multi") {
      StudentTestPageCxt.setSRender(obj);
      StudentTestPageHks.questionUpdate(
        Props.secInd,
        Props.queInd,
        tempPropObj
      );
      StudentTestPageCxt.setMulRevch(!StudentTestPageCxt.mulRevch);
    }

    Alert.updateComingObj(tempPropObj);
  };

  /* change section  */

  const handleSecChange = (event) => {
    let newEve;

    if (event.target.value === "all") {
      newEve = 0;
      StudentTestPageCxt.setPrevSec(false);
      StudentTestPageCxt.setNextSec(true);
    } else {
      newEve = event.target.value;
    }

    StudentTestPageCxt.setSelSec(event.target.value);
    StudentTestPageCxt.setSectionObj(Alert.comingObj.sections[newEve]);

    if (event.target.value === 0) {
      if (Alert.comingObj.sections.length === 1) {
        StudentTestPageCxt.setPrevSec(false);
        StudentTestPageCxt.setNextSec(false);
      } else {
        StudentTestPageCxt.setPrevSec(false);
        StudentTestPageCxt.setNextSec(true);
      }
    } else if (event.target.value === Alert.comingObj.sections?.length - 1) {
      StudentTestPageCxt.setPrevSec(true);
      StudentTestPageCxt.setNextSec(false);
    } else {
      if (Alert.comingObj.sections.length === 1) {
        StudentTestPageCxt.setPrevSec(false);
        StudentTestPageCxt.setNextSec(false);
      } else {
        StudentTestPageCxt.setPrevSec(true);
        StudentTestPageCxt.setNextSec(true);
      }
    }

    StudentTestPageHks.calcStats(Alert.comingObj, [event.target.value]);

    if (Props.status === constants.PROGRESS_STATUS.EVALUATED) {
      Props.setFilters(null);
    }
  };

  const bacttoHomepage = () => {
    Alert.isAdmin
      ? Alert.navigate(`/batches/${localStorage.getItem("abatchid")}/report`)
      : Alert.backupTestData.mockuse
      ? Alert.navigate("/mock/results")
      : Alert.navigate("/home");

    if (StudentTestPageCxt.showTranscript)
      StudentTestPageCxt.setShowTranscript(!StudentTestPageCxt.showTranscript);
    if (StudentTestPageCxt.showSaqLaqAns)
      StudentTestPageCxt.setShowSaqLaqAns(!StudentTestPageCxt.showSaqLaqAns);

    if (Alert.backupTestData.testmode === constants.MODE.PRACTICE_MODE) {
      Alert.setLpData({});
    }
  };

  /* each qn timelimit */

  useEffect(() => {
    if (!StudentTestPageCxt.showtransition) {
      const handleqnTimeLimit = setInterval(() => {
        if (StudentTestPageCxt.vdoQnTimelimit === 0) {
          Props.handlesingleVdoQnSubmit();
          clearInterval(handleqnTimeLimit);
        } else {
          StudentTestPageCxt.setVdoQnTimelimit((seconds) => seconds - 1);
        }
      }, 1000);

      return () => {
        clearInterval(handleqnTimeLimit);
      };
    }
  }, [StudentTestPageCxt.vdoQnTimelimit, StudentTestPageCxt.showtransition]);

  /* dynamic font change */

  useEffect(() => {
    if (StudentTestPageCxt.showtransition) {
      const element = textRef.current;
      const container = element?.parentElement;
      const containerWidth = container?.clientWidth;
      const minFontSize = 18;
      const maxFontSize = 50;
      const step = 1.5;

      if (element && containerWidth) {
        let currentFontSize = maxFontSize;
        element.style.fontSize = `${currentFontSize}px`;

        // Reduce font size until the text fits within the container

        while (
          element.scrollWidth > containerWidth &&
          currentFontSize > minFontSize
        ) {
          currentFontSize -= step;
          element.style.fontSize = `${currentFontSize}px`;
        }

        let height = element.scrollHeight;

        while (height >= 500 && currentFontSize > minFontSize) {
          currentFontSize -= step;
          height -= 30;
          element.style.fontSize = `${currentFontSize}px`;
        }

        // Ensure the font size is not less than the minimum font size
        if (currentFontSize < minFontSize) {
          currentFontSize = minFontSize;
        }
      }
    }
  }, [StudentTestPageCxt.showtransition]);

  /* vdo qn getting the sectionname  */

  const getSectionName = (qnid) => {
    for (let sec of Alert.comingObj.sections) {
      if (sec.questions.find((que) => que.id === qnid)) {
        return sec.sectionname;
      }
    }

    return null;
  };

  /* vdo qn getting the qn no  */

  const getQuestionNo = (qnid) => {
    for (let sec of Alert.comingObj.sections) {
      const questionIndex = sec.questions.findIndex((que) => que.id === qnid);

      if (questionIndex !== -1) {
        return questionIndex + 1;
      }
    }

    return null;
  };

  const showAnswer = () => {
    StudentTestPageCxt.setShowSaqLaqAns(!StudentTestPageCxt.showSaqLaqAns);
  };

  return (
    <>
      <div className="component-one">
        {StudentTestPageCxt.showtransition &&
          Props.showTimer &&
          ((!Alert.isAdmin &&
            Props.status === constants.PROGRESS_STATUS.IN_PROGRESS) ||
            (!Alert.isAdmin &&
              Props.status === constants.PROGRESS_STATUS.UNLOCKED) ||
            (!Alert.isAdmin &&
              Props.status === constants.PROGRESS_STATUS.QN_CONTINUE) ||
            (!Alert.isAdmin &&
              Props.status === constants.PROGRESS_STATUS.REATTEMPT)) &&
          Alert.comingObj.videoqn === 1 && (
            <BackdropCounter
              setShowTimer={Props.setShowTimer}
              setshowtransition={StudentTestPageCxt.setshowtransition}
            />
          )}
      </div>
      <div className="commontest-mainpage-div">
        {Props.status === constants.PROGRESS_STATUS.EVALUATED ||
        Props.status === constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
        Props.status === constants.PROGRESS_STATUS.PARTIAL_EVALUATED ||
        (Alert.isAdmin &&
          Props.status === constants.PROGRESS_STATUS.IN_PROGRESS) ||
        (Alert.isAdmin &&
          Props.status === constants.PROGRESS_STATUS.QN_CONTINUE) ||
        (Alert.isAdmin &&
          Props.status === constants.PROGRESS_STATUS.REATTEMPT) ? (
          <div className="commontest-mainpage-header">
            <div className="commontest-mainpage-header-change-section">
              <div className="commontest-mainpage-header-back-btn-dropdown">
                <div>
                  <BackSvgIcon onClick={bacttoHomepage} />
                </div>
                <FormControl className="header-change-section-form">
                  <Select
                    value={StudentTestPageCxt.selSec}
                    onChange={handleSecChange}
                    labelId="learnerSelectLabel"
                    className="header-change-section-select"
                  >
                    <MenuItem value={"all"} key={"selectall"}>
                      {"All"}
                    </MenuItem>

                    {Alert.hasOwnProperty("comingObj") &&
                      Alert.comingObj.hasOwnProperty("sections") &&
                      Alert.comingObj.sections.map((sec, ind) => (
                        <MenuItem
                          className="header-change-section-menuitem"
                          value={ind}
                          key={sec.sectionname + "" + ind}
                        >
                          {sec.sectionname}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            {(Alert.isAdmin ||
              Alert.isTrainer ||
              (Alert.isLearner && StudentTestPageCxt.viewTest === constants.DISPLAY_TEST_RESULT.ALL) ||
              Alert.backupTestData.testmode === constants.MODE.PRACTICE_MODE) &&
              (Alert.backupTestData.sections[0].questions[0]?.libtypeid ===  constants.libTypeIds.SAQ ||
                Alert.backupTestData.sections[0].questions[0]?.libtypeid === constants.libTypeIds.LAQ ||
                Alert.backupTestData.sections[0].questions[0]?.libtypeid === constants.libTypeIds.MCQ_SINGLE ||
                Alert.backupTestData.sections[0].questions[0]?.libtypeid === constants.libTypeIds.MCQ_MULTI) && (
                <>
                  <div className="Show-answer-div">
                    <Checkbox
                      onChange={showAnswer}
                      text={"Show explanation"}
                      checked={StudentTestPageCxt.showSaqLaqAns}
                    />
                  </div>
                </>
              )}

            {(Alert.isAdmin ||
              Alert.isTrainer ||
              Alert.backupTestData.testmode === constants.MODE.PRACTICE_MODE) &&
              Alert.comingObj.videoqn === 1 && (
                <div className="Show-answer-div">
                  <Checkbox
                    text={"Show Text"}
                    onChange={showTranscriptFunc}
                    checked={StudentTestPageCxt.showTranscript}
                  />
                </div>
              )}
          </div>
        ) : (
          <div className="commontest-mainpage-header">
            <div className="commontest-mainpage-header-section">
              <div className="commontest-mainpage-header-section-title">
                Section:
              </div>
              <div className="commontest-mainpage-header-section-name">
                {Alert.comingObj.videoqn !== 1
                  ? Props.secObj?.sectionname
                  : getSectionName(
                      Alert.videoQn.current?.filter((data) => data?.selected)[0]
                        ?.id
                    )}
              </div>
              {Alert.comingObj.videoqn === 1 && (
                <>
                  <div className="commontest-mainpage-header-section-title">
                    Question No:
                  </div>
                  <div className="commontest-mainpage-header-section-name">
                    {getQuestionNo(
                      Alert.videoQn.current?.filter((data) => data?.selected)[0]
                        ?.id
                    )}
                  </div>
                </>
              )}
            </div>
            {Alert.comingObj.videoqn !== 1 &&
              Alert.comingObj.listview === 1 && (
                <div className="commontest-mainpage-header-test-view-mode">
                  <div className="commontest-mainpage-header-test-view-mode-gridbtn">
                    <IconButton
                      onClick={() => Props.changeQuestionVisible("single")}
                    >
                      <GridViewSvg />
                    </IconButton>
                  </div>
                  <div className="commontest-mainpage-header-test-view-mode-listbtn">
                    <IconButton
                      onClick={() => Props.changeQuestionVisible("multiple")}
                    >
                      <ListViewSvg />
                    </IconButton>
                  </div>
                </div>
              )}
            {Alert.comingObj.videoqn === 1 && (
              <div className="video-qn-timer-div">
                <div className="video-qn-timer-label">Time Left:</div>
                <div className="video-qn-timer">
                  <span>
                    {Math.floor(
                      (StudentTestPageCxt.vdoQnTimelimit % 3600) / 60
                    ).toString().length === 1
                      ? "0" +
                        Math.floor(
                          (StudentTestPageCxt.vdoQnTimelimit % 3600) / 60
                        )
                      : Math.floor(
                          (StudentTestPageCxt.vdoQnTimelimit % 3600) / 60
                        )}
                  </span>
                  {" : "}
                  <span>
                    {Math.floor(
                      (StudentTestPageCxt.vdoQnTimelimit % 3600) % 60
                    ).toString().length === 1
                      ? "0" +
                        Math.floor(
                          (StudentTestPageCxt.vdoQnTimelimit % 3600) % 60
                        )
                      : Math.floor(
                          (StudentTestPageCxt.vdoQnTimelimit % 3600) % 60
                        )}
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
        <div
          className={`commontest-mainpage-body${
            (!Alert.isAdmin && (Alert.comingObj.videoqn === 1 &&
            Props.status !== constants.PROGRESS_STATUS.EVALUATED &&
            Props.status !== constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
            Props.status !== constants.PROGRESS_STATUS.PARTIAL_EVALUATED))
              ? "-videoqn"
              : ""
          }`}
        >
          {Props.status !== constants.PROGRESS_STATUS.EVALUATED &&
          Alert.questionContainerSingle ? (
            <div className="commontest-mainpage-single-question-div">
              <div className="commontest-mainpage-single-question-box">
                <div className="commontest-mainpage-single-question-header-div">
                  <div className="commontest-mainpage-single-question-title-div">
                    {Object.keys(StudentTestPageCxt.curObj)?.length > 0 &&
                      StudentTestPageCxt.curObj.hasOwnProperty("review") &&
                      StudentTestPageCxt.curObj.review === -1 && (
                        <IconButton
                          className="commontest-mainpage-single-question-title-reviewlater"
                          onClick={() =>
                            reviewLater("single", "noreview", Alert.queObj)
                          }
                        >
                          <BookmarkSvg />
                        </IconButton>
                      )}
                    {Object.keys(StudentTestPageCxt.curObj)?.length > 0 &&
                      StudentTestPageCxt.curObj.hasOwnProperty("review") &&
                      StudentTestPageCxt.curObj.review === 0 && (
                        <IconButton
                          className="commontest-mainpage-single-question-title-reviewlater"
                          onClick={() =>
                            reviewLater("single", "review", Alert.queObj)
                          }
                        >
                          <BookmarkedSvg />
                        </IconButton>
                      )}

                    {(Alert.comingObj.videoqn !== 1 || Alert.isAdmin) && (
                      <div className="commontest-mainpage-single-question-title-qnno">
                        {Alert.comingObj.videoqn === 1
                          ? StudentTestPageCxt.videoQnNo.current
                          : Props.queInd + 1}
                        .
                      </div>
                    )}
                    <div
                      className="commontest-mainpage-single-question-title-qnname"
                      ref={textRef}
                      id="dynamicFont"
                    >
                      {Alert.comingObj.videoqn === 1
                        ? Alert.videoQn.current?.filter(
                            (data) => data?.selected
                          )[0]?.name
                        : Alert.queObj?.name}
                    </div>

                    {(Alert.comingObj.videoqn !== 1 || Alert.isAdmin) && (
                      <div className="commontest-mainpage-single-question-title-mark">
                        {Alert.comingObj.videoqn === 1
                          ? "(" +
                            Alert.videoQn.current?.filter(
                              (data) => data?.selected
                            )[0]?.targetscore +
                            " Marks)"
                          : "(" + Alert.queObj?.targetscore + " Marks)"}
                      </div>
                    )}
                  </div>
                </div>
                <div className="commontest-mainpage-single-question-detail-box">
                  {/* if desc is there to show */}
                  {((Alert.comingObj.videoqn !== 1 &&
                    Alert.queObj?.hasOwnProperty("description") &&
                    Alert.queObj?.description != null &&
                    Alert.queObj?.description.length > 0) ||
                    (Alert.comingObj.videoqn === 1 &&
                      Alert.videoQn.current
                        ?.filter((data) => data?.selected)[0]
                        ?.hasOwnProperty("description") &&
                      Alert.videoQn.current?.filter((data) => data?.selected)[0]
                        ?.description !== null &&
                      Alert.videoQn.current?.filter((data) => data?.selected)[0]
                        ?.description.length > 0)) && (
                    <div className="singleQuestionContainerDescription">
                      <br></br>
                      {Alert.comingObj.videoqn === 1
                        ? parse(
                            Alert.videoQn.current?.filter(
                              (data) => data?.selected
                            )[0]?.description
                          )
                        : parse(Alert.queObj?.description)}
                    </div>
                  )}
                  {/* if img is there to show */}
                  {(Alert.comingObj.videoqn !== 1 &&
                    Alert.queObj?.links.length > 0) ||
                  (Alert.comingObj.videoqn === 1 &&
                    Alert.videoQn.current?.filter((data) => data?.selected))[0]
                    ?.links.length > 0 ? (
                    <div>
                      {Alert.comingObj.videoqn === 1
                        ? Alert.videoQn.current
                            ?.filter((data) => data?.selected)[0]
                            ?.links.map((link, index) => (
                              <span
                                className="questionLinks"
                                key={link + "" + Props.secObj.sectionid + index}
                              >
                                <img
                                  width="400"
                                  height="300"
                                  alt="userImage"
                                  key={link + "img"}
                                  src={
                                    process.env.REACT_APP_DEPLOYMENT !== "AWS"
                                      ? process.env.REACT_APP_NODE_API +
                                        link.link
                                      : link.link
                                  }
                                />
                              </span>
                            ))
                        : Alert.queObj?.links.map((link, index) => (
                            <span
                              className="questionLinks"
                              key={link + "" + Props.secObj.sectionid + index}
                            >
                              <img
                                width="400"
                                height="300"
                                alt="userImage"
                                key={link + "img"}
                                src={
                                  process.env.REACT_APP_DEPLOYMENT !== "AWS"
                                    ? process.env.REACT_APP_NODE_API + link.link
                                    : link.link
                                }
                              />
                            </span>
                          ))}
                    </div>
                  ) : null}

                  {Alert.comingObj.videoqn !== 1 ? (
                    Alert.queObj.libtypeid === constants.libTypeIds.SAQ ||
                    Alert.queObj.libtypeid === constants.libTypeIds.LAQ ? (
                      <div
                        className="text-area-AnswerDiv"
                        key={"textareadiv" + Alert.queObj.id}
                      >
                        <div
                          className="desInputWrapper"
                          data-required={
                            Alert.comingObj.videoqn !== 1
                              ? Alert.queObj.libtypeid ===
                                constants.libTypeIds.SAQ
                                ? 1024 -
                                  (Alert.answers[Alert.queObj.id] == null
                                    ? 0
                                    : Alert.answers[Alert.queObj.id].length) +
                                  " characters left"
                                : 10000 -
                                  (Alert.answers[Alert.queObj.id] == null
                                    ? 0
                                    : Alert.answers[Alert.queObj.id].length) +
                                  " characters left"
                              : ""
                          }
                        >
                          {StudentTestPageCxt.errorMessage &&
                            Alert.queObj.id ===
                              StudentTestPageCxt.questionIdOffError && (
                              <label style={{ color: "red" }}>
                                only numbers and decimals are allowed
                              </label>
                            )}

                          <TextareaAutosize
                            minRows={7}
                            placeholder=""
                            id={"inputs" + 1}
                            key={Alert.queObj.id}
                            className="textareaAnswer"
                            onCut={StudentTestPageHks.handleCPC}
                            onCopy={StudentTestPageHks.handleCPC}
                            onPaste={StudentTestPageHks.handleCPC}
                            input={Alert.answers[Alert.queObj.id]}
                            value={Alert.answers[Alert.queObj.id]}
                            oncontextmenu={StudentTestPageHks.handleCPC}
                            name={Alert.queObj.id + "" + Props.secObj.sectionid}
                            onChange={(e) => {
                              collectAnswers(
                                Alert.queObj.id,
                                e.target.value,
                                Alert.queObj.libtypeid,
                                Props.secInd,
                                Props.queInd,
                                Alert.queObj?.datatype
                              );
                            }}
                            style={{
                              width: "100%",
                              resize: "none",
                              // minWidth: "57vW",
                              borderRadius: "5px",
                              marginBottom: "10px",
                              border:
                                StudentTestPageCxt.errorMessage &&
                                Alert.queObj.id ===
                                  StudentTestPageCxt.questionIdOffError
                                  ? "1px solid red"
                                  : Alert.answers[Alert.queObj.id] == null ||
                                    Alert.answers[Alert.queObj.id].length === 0
                                  ? "1px solid rgba(55, 55, 57, 0.2)"
                                  : "1px solid #D0D5DD",
                            }}
                            maxLength={
                              Alert.queObj.libtypeid ===
                              constants.libTypeIds.SAQ
                                ? 1024
                                : 10000
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      Props.optObj.map((option, index) => (
                        <>
                          <div
                            key={option.optionid + "" + index}
                            className={
                              Alert.isAdmin
                                ? "singleViewQuestionBoxDetailsOptionsDivEachOptionAdmin"
                                : "commontest-mainpage-single-question-options"
                            }
                            onClick={() => {
                              collectAnswers(
                                Alert.queObj.id,
                                option.optionid + "",
                                Alert.queObj.libtypeid
                              );
                            }}
                            style={{
                              borderColor: Alert.answers[
                                Alert.queObj.id
                              ]?.includes(option.optionid + "")
                                ? Props.status !==
                                  constants.PROGRESS_STATUS.EVALUATED
                                  ? "#F96343"
                                  : "green"
                                : "",
                              border: Alert.answers[Alert.queObj.id]?.includes(
                                option.optionid + ""
                              )
                                ? Props.status !==
                                  constants.PROGRESS_STATUS.EVALUATED
                                  ? "2px solid #F96343"
                                  : "2px solid green"
                                : "",
                            }}
                          >
                            <div
                              className={
                                Alert.isAdmin
                                  ? "singleViewQuestionBoxDetailsOptionsDivEachOptionAdmin"
                                  : "commontest-mainpage-single-question-options-input-label-div"
                              }
                            >
                              <input
                                className="commontest-mainpage-single-question-options-input"
                                id={option.optionid}
                                value={option.optionid}
                                name={Alert.queObj.id + "" + Props.secInd}
                                key={
                                  option.optionid +
                                  "" +
                                  Props.secInd +
                                  Props.queInd
                                }
                                type={
                                  Alert.queObj.libtypeid ===
                                  constants.libTypeIds.MCQ_SINGLE
                                    ? "radio"
                                    : "checkbox"
                                }
                                onChange={() => {
                                  collectAnswers(
                                    Alert.queObj.id,
                                    option.optionid + "",
                                    Alert.queObj.libtypeid
                                  );
                                }}
                                checked={
                                  Alert.answers[Alert.queObj.id]?.includes(
                                    option.optionid + ""
                                  )
                                    ? true
                                    : false
                                }
                                style={{
                                  color: Alert.answers[
                                    Alert.queObj.id
                                  ]?.includes(option.optionid + "")
                                    ? "#f55533"
                                    : "#D0D5DD",
                                }}
                              />
                              <label
                                className="commontest-mainpage-single-question-option-label"
                                style={{
                                  color: Alert.answers[
                                    Alert.queObj.id
                                  ]?.includes(option.optionid + "")
                                    ? "#f55533"
                                    : "#101828",
                                }}
                              >
                                {option.optiontext}
                              </label>
                            </div>
                          </div>
                        </>
                      ))
                    )
                  ) : null}
                </div>
              </div>

              <div className="commontest-mainpage-single-question-button-group">
                {Alert.comingObj.videoqn !== 1 &&
                  Alert.questionContainerSingle && (
                    <div
                      className={
                        StudentTestPageCxt.prevButton &&
                        StudentTestPageCxt.nextButton
                          ? "commontest-mainpage-single-question-button-group-div"
                          : "commontest-mainpage-single-question-button-group-div"
                      }
                    >
                      <div>
                        <ButtonMedium
                          btnText2="Previous"
                          secondaryBtn={StudentTestPageCxt.prevButton}
                          onHideSecondary={() => traversePrevious("previous")}
                        />{" "}
                      </div>
                      <div>
                        <ButtonMedium
                          btnText="Next"
                          onHide={() => traverseNext("next")}
                          primaryBtn={StudentTestPageCxt.nextButton}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          ) : (
            <div className={"commontest-mainpage-multiple-question-options"}>
              <div className="commontest-mainpage-multiple-question-container">
                {Alert.hasOwnProperty("comingObj") &&
                Alert.comingObj.hasOwnProperty("sections") &&
                StudentTestPageCxt.sectionObj !== null &&
                typeof StudentTestPageCxt.sectionObj !== "undefined" &&
                Object.keys(StudentTestPageCxt.sectionObj).length > 0 &&
                StudentTestPageCxt.sectionObj.hasOwnProperty("questions") ? (
                  <>
                    {
                      <div className="commontest-mainpage-multiple-question-container-loop">
                        {StudentTestPageCxt.fSections.map((fsec, fsecind) => (
                          <div className="commontest-mainpage-multiple-question-container-block">
                            {(Props.status ===
                              constants.PROGRESS_STATUS.EVALUATED ||
                              Props.status ===
                                constants.PROGRESS_STATUS.IN_PROGRESS ||
                              Props.status ===
                                constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
                              Props.status ===
                                constants.PROGRESS_STATUS.PARTIAL_EVALUATED ||
                              Props.status ===
                                constants.PROGRESS_STATUS.QN_CONTINUE) &&
                            StudentTestPageCxt.selSec === "all" &&
                            fsec.questions.length > 0 ? (
                              <div className="testContainerChildLeftHeaderSectionTitle2">
                                <span className="testContainerChildLeftHeaderSectionTitleLabel">
                                  Section :&nbsp;{" "}
                                </span>{" "}
                                <span className="testContainerChildLeftHeaderSectionTitleName">
                                  {fsec.sectionname}
                                </span>
                                <span className="singleViewQuestionBoxTitleMarks">
                                  {" ( " +
                                    StudentTestPageHks.getSectionTotal(fsec) +
                                    " Marks )"}
                                </span>
                              </div>
                            ) : null}
                            {fsec.questions.map((questionobj, queindex) => (
                              <>
                                <div
                                  className="commontest-mainpage-multiple-question-box"
                                  id={
                                    "que" +
                                    (Props.secInd === "all"
                                      ? 0
                                      : Props.secInd) +
                                    queindex
                                  }
                                >
                                  <>
                                    <div class="commontest-mainpage-multiple-question-box-title">
                                      {questionobj.review === -1 && (
                                        <IconButton
                                          className="commontest-mainpage-multiple-question-title-reviewlater"
                                          onClick={() =>
                                            reviewLater(
                                              "multi",
                                              "noreview",
                                              queindex
                                            )
                                          }
                                        >
                                          <BookmarkSvg />
                                        </IconButton>
                                      )}
                                      {questionobj.review === 0 && (
                                        <IconButton
                                          className="commontest-mainpage-multiple-question-title-reviewlater"
                                          onClick={() =>
                                            reviewLater(
                                              "multi",
                                              "review",
                                              queindex
                                            )
                                          }
                                        >
                                          <BookmarkedSvg />
                                        </IconButton>
                                      )}

                                      <div className="commontest-mainpage-single-question-title-qnno">
                                        {queindex + 1}.
                                      </div>
                                      <div className="commontest-mainpage-single-question-title-qnname">
                                        {questionobj.name}
                                      </div>

                                      <div className="commontest-mainpage-single-question-title-mark">
                                        {getDataFromStorage("role") ===
                                          "learner" &&
                                          "(" +
                                            (Alert.isLearner &&
                                            StudentTestPageCxt.viewTest ===
                                              constants.DISPLAY_TEST_RESULT
                                                .ALL &&
                                            Alert.testMode ===
                                              constants.MODE.TEST_MODE &&
                                            Props.status ===
                                              constants.PROGRESS_STATUS
                                                .EVALUATED
                                              ? "" +
                                                questionobj.actualscore +
                                                "/"
                                              : "") +
                                            questionobj.targetscore +
                                            " Marks)"}
                                      </div>
                                    </div>
                                    <div className="commontest-mainpage-single-question-option-detail-box">
                                      {questionobj.hasOwnProperty(
                                        "description"
                                      ) &&
                                        questionobj.description != null &&
                                        questionobj.description.length > 0 && (
                                          <div className="multiQuestionContainerDescription">
                                            <br />
                                            {parse(questionobj.description)}
                                          </div>
                                        )}

                                      {questionobj.links.length > 0 ? (
                                        <div className="singleViewQuestionBoxTitleQuestionLinks">
                                          {questionobj.links.map((link) => (
                                            <span className="questionLinks">
                                              <img
                                                width="400"
                                                height="400"
                                                alt="userImage"
                                                key={
                                                  link +
                                                  "" +
                                                  StudentTestPageCxt.secIndex
                                                }
                                                src={
                                                  process.env
                                                    .REACT_APP_DEPLOYMENT !==
                                                  "AWS"
                                                    ? process.env
                                                        .REACT_APP_NODE_API +
                                                      link.link
                                                    : link.link
                                                }
                                              />
                                            </span>
                                          ))}
                                        </div>
                                      ) : null}
                                      <div className="commontest-mainpage-single-question-option-details-div">
                                        {questionobj.libtypeid ===
                                          constants.libTypeIds.SAQ ||
                                        questionobj.libtypeid ===
                                          constants.libTypeIds.LAQ ? (
                                          <div
                                            className="text-area-AnswerDiv"
                                            key={
                                              questionobj.libtypeid +
                                              questionobj.id
                                            }
                                          >
                                            <div
                                              className="desInputWrapper"
                                              data-required={
                                                Alert.comingObj.videoqn !== 1
                                                  ? Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .UNLOCKED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .REATTEMPT ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .QN_CONTINUE
                                                    ? questionobj.libtypeid ===
                                                      constants.libTypeIds.SAQ
                                                      ? 1024 -
                                                        (Alert.isAdmin
                                                          ? questionobj.answer !=
                                                            null
                                                            ? questionobj.answer
                                                                .length
                                                            : 0
                                                          : Alert.answers[
                                                              questionobj.id
                                                            ]?.length) +
                                                        " characters left"
                                                      : 10000 -
                                                        (Alert.isAdmin
                                                          ? questionobj.answer !=
                                                            null
                                                            ? questionobj.answer
                                                                .length
                                                            : 0
                                                          : Alert.answers[
                                                              questionobj.id
                                                            ]?.length) +
                                                        " characters left"
                                                    : questionobj.libtypeid ===
                                                      constants.libTypeIds.SAQ
                                                    ? 1024 -
                                                      (Alert.isAdmin
                                                        ? questionobj.answer !=
                                                          null
                                                          ? questionobj.answer
                                                              .length
                                                          : 0
                                                        : questionobj.answer
                                                            ?.length) +
                                                      " characters left"
                                                    : 10000 -
                                                      (Alert.isAdmin
                                                        ? questionobj.answer !=
                                                          null
                                                          ? questionobj.answer
                                                              .length
                                                          : 0
                                                        : questionobj.answer
                                                            ?.length) +
                                                      " characters left"
                                                  : ""
                                              }
                                            >
                                              {StudentTestPageCxt.errorMessage &&
                                                questionobj.id ===
                                                  StudentTestPageCxt.questionIdOffError && (
                                                  <label
                                                    style={{
                                                      color: "red",
                                                      display: "flex",
                                                    }}
                                                  >
                                                    only numbers and decimals
                                                    are allowed
                                                  </label>
                                                )}
                                              {Alert.comingObj.videoqn === 1 ? (
                                                questionobj.answer !== "" && (
                                                  <video
                                                    className="videoqn-admin-answer"
                                                    controls
                                                    controlsList="nodownload"
                                                    src={questionobj.answer}
                                                  ></video>
                                                )
                                              ) : (
                                                <TextareaAutosize
                                                  minRows={7}
                                                  id={questionobj.id}
                                                  key={questionobj.id}
                                                  className="textareaAnswer"
                                                  onCut={
                                                    StudentTestPageHks.handleCPC
                                                  }
                                                  onCopy={
                                                    StudentTestPageHks.handleCPC
                                                  }
                                                  onPaste={
                                                    StudentTestPageHks.handleCPC
                                                  }
                                                  oncontextmenu={
                                                    StudentTestPageHks.handleCPC
                                                  }
                                                  name={
                                                    questionobj.id +
                                                    "" +
                                                    StudentTestPageCxt
                                                      .sectionObj.sectionid
                                                  }
                                                  value={
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .EVALUATED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .TO_BE_EVALUATED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .PARTIAL_EVALUATED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .IN_PROGRESS ||
                                                    (Alert.isAdmin &&
                                                      Props.status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .QN_CONTINUE)
                                                      ? questionobj.answer
                                                      : Alert.answers[
                                                          questionobj.id
                                                        ]
                                                  }
                                                  disabled={
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .EVALUATED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .TO_BE_EVALUATED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .PARTIAL_EVALUATED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .IN_PROGRESS ||
                                                    (Alert.isAdmin &&
                                                      Props.status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .QN_CONTINUE)
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    collectAnswers(
                                                      questionobj.id,
                                                      e.target.value,
                                                      questionobj.libtypeid,
                                                      StudentTestPageCxt.secIndex,
                                                      queindex,
                                                      questionobj?.datatype
                                                    );
                                                  }}
                                                  style={{
                                                    width: "100%",
                                                    resize: "none",
                                                    // minWidth: "57vW",
                                                    borderRadius: "5px",
                                                    marginBottom: "10px",
                                                    border:
                                                      StudentTestPageCxt.errorMessage &&
                                                      questionobj.id ===
                                                        StudentTestPageCxt.questionIdOffError
                                                        ? "1px solid red"
                                                        : Props.status ===
                                                            constants
                                                              .PROGRESS_STATUS
                                                              .UNLOCKED ||
                                                          Props.status ===
                                                            constants
                                                              .PROGRESS_STATUS
                                                              .REATTEMPT ||
                                                          Props.status ===
                                                            constants
                                                              .PROGRESS_STATUS
                                                              .QN_CONTINUE
                                                        ? Alert.answers[
                                                            questionobj.id
                                                          ]?.length === 0
                                                          ? "1px solid rgba(55, 55, 57, 0.2)"
                                                          : "1px solid #D0D5DD"
                                                        : questionobj.answer
                                                            ?.length === 0
                                                        ? "1px solid rgba(55, 55, 57, 0.2)"
                                                        : "1px solid #D0D5DD",
                                                  }}
                                                  maxLength={
                                                    questionobj.libtypeid ===
                                                    constants.libTypeIds.SAQ
                                                      ? 1024
                                                      : 10000
                                                  }
                                                />
                                              )}
                                            </div>

                                            {StudentTestPageCxt.showSaqLaqAns && (
                                              <div className="desc-admin-answer-div">
                                                <div className="desc-admin-answer-label-div">
                                                  Explanation
                                                </div>
                                                <textarea
                                                  rows={5}
                                                  disabled={true}
                                                  id={
                                                    questionobj.id +
                                                    "showanswer"
                                                  }
                                                  key={
                                                    questionobj.id +
                                                    "showanswer"
                                                  }
                                                  className="desc-admin-answer"
                                                  name={
                                                    questionobj.id +
                                                    "" +
                                                    StudentTestPageCxt
                                                      .sectionObj.sectionid +
                                                    "showanswer"
                                                  }
                                                  value={questionobj.options
                                                    .map(
                                                      (obj) => obj.optiontext
                                                    )
                                                    .join("/")}
                                                />
                                              </div>
                                            )}
                                            {StudentTestPageCxt.showTranscript && (
                                              <div className="desc-admin-answer-div">
                                                <div className="desc-admin-answer-label-div">
                                                  Transcript
                                                </div>
                                                <textarea
                                                  rows={5}
                                                  disabled={true}
                                                  id={
                                                    questionobj.id +
                                                    "showanswer"
                                                  }
                                                  key={
                                                    questionobj.id +
                                                    "showanswer"
                                                  }
                                                  className="desc-admin-answer"
                                                  name={
                                                    questionobj.id +
                                                    "" +
                                                    StudentTestPageCxt
                                                      .sectionObj.sectionid +
                                                    "showanswer"
                                                  }
                                                  value={
                                                    questionobj?.transcript
                                                  }
                                                />
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          questionobj.options.map(
                                            (option, optindex) =>
                                              questionobj.libtypeid ===
                                              constants.libTypeIds
                                                .MCQ_SINGLE ? (
                                                <div
                                                  key={
                                                    option.optionid +
                                                    "" +
                                                    optindex +
                                                    StudentTestPageCxt.secIndex +
                                                    questionobj.libtypeid
                                                  }
                                                  className={
                                                    "commontest-mainpage-single-question-option-each-div"
                                                  }
                                                  onClick={
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .UNLOCKED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .REATTEMPT ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .QN_CONTINUE
                                                      ? () => {
                                                          collectAnswers(
                                                            questionobj.id,
                                                            option.optionid +
                                                              "",
                                                            questionobj.libtypeid,
                                                            StudentTestPageCxt.secIndex,
                                                            queindex,
                                                            0
                                                          );
                                                        }
                                                      : null
                                                  }
                                                  style={{
                                                    borderColor:
                                                      Props.status !==
                                                      constants.PROGRESS_STATUS
                                                        .EVALUATED
                                                        ? Alert.answers[
                                                            questionobj.id
                                                          ]?.includes(
                                                            option.optionid + ""
                                                          )
                                                          ? "#F96343"
                                                          : (Alert.isAdmin ||
                                                              Alert.isTrainer) &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? "#F96343"
                                                          : ""
                                                        : (Alert.isAdmin ||
                                                            Alert.isTrainer ||
                                                            (Alert.isLearner &&
                                                              StudentTestPageCxt.viewTest ===
                                                                constants
                                                                  .DISPLAY_TEST_RESULT
                                                                  .ALL)) &&
                                                          option.iscorrectoption ===
                                                            1 &&
                                                          questionobj.answer?.includes(
                                                            option.optionid + ""
                                                          )
                                                        ? "#12B76A"
                                                        : (Alert.isAdmin ||
                                                            Alert.isTrainer ||
                                                            (Alert.isLearner &&
                                                              StudentTestPageCxt.viewTest ===
                                                                constants
                                                                  .DISPLAY_TEST_RESULT
                                                                  .ALL)) &&
                                                          option.iscorrectoption ===
                                                            0 &&
                                                          questionobj.answer?.includes(
                                                            option.optionid + ""
                                                          )
                                                        ? "#F04438"
                                                        : "",
                                                    border:
                                                      Props.status !==
                                                      constants.PROGRESS_STATUS
                                                        .EVALUATED
                                                        ? Alert.answers[
                                                            questionobj.id
                                                          ]?.includes(
                                                            option.optionid + ""
                                                          )
                                                          ? "2px solid #F96343"
                                                          : (Alert.isAdmin ||
                                                              Alert.isTrainer) &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? "2px solid #F96343"
                                                          : ""
                                                        : (Alert.isAdmin ||
                                                            Alert.isTrainer ||
                                                            (Alert.isLearner &&
                                                              StudentTestPageCxt.viewTest ===
                                                                constants
                                                                  .DISPLAY_TEST_RESULT
                                                                  .ALL)) &&
                                                          option.iscorrectoption ===
                                                            1 &&
                                                          questionobj.answer?.includes(
                                                            option.optionid + ""
                                                          )
                                                        ? "2px solid #12B76A"
                                                        : (Alert.isAdmin ||
                                                            Alert.isTrainer ||
                                                            (Alert.isLearner &&
                                                              StudentTestPageCxt.viewTest ===
                                                                constants
                                                                  .DISPLAY_TEST_RESULT
                                                                  .ALL)) &&
                                                          option.iscorrectoption ===
                                                            0 &&
                                                          questionobj.answer?.includes(
                                                            option.optionid + ""
                                                          )
                                                        ? "2px solid #F04438"
                                                        : "",
                                                  }}
                                                >
                                                  <div
                                                    className={
                                                      "commontest-mainpage-multiple-question-options-input-label-div"
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        "options-input-label-div"
                                                      }
                                                    >
                                                      <input
                                                        type="radio"
                                                        className="commontest-mainpage-multiple-question-options-input"
                                                        key={
                                                          option.optionid +
                                                          "" +
                                                          optindex +
                                                          StudentTestPageCxt.secIndex +
                                                          questionobj.libtypeid
                                                        }
                                                        name={
                                                          questionobj.id +
                                                          "" +
                                                          StudentTestPageCxt
                                                            .sectionObj
                                                            .sectionid
                                                        }
                                                        id={
                                                          option.optionid +
                                                          "" +
                                                          optindex +
                                                          StudentTestPageCxt.secIndex +
                                                          questionobj.libtypeid
                                                        }
                                                        value={
                                                          option.optionid +
                                                          "" +
                                                          optindex +
                                                          StudentTestPageCxt.secIndex +
                                                          questionobj.libtypeid
                                                        }
                                                        onChange={() => {
                                                          collectAnswers(
                                                            questionobj.id,
                                                            option.optionid +
                                                              "",
                                                            questionobj.libtypeid,
                                                            StudentTestPageCxt.secIndex,
                                                            queindex,
                                                            0
                                                          );
                                                        }}
                                                        checked={
                                                          Props.status !==
                                                          constants
                                                            .PROGRESS_STATUS
                                                            .EVALUATED
                                                            ? Alert.answers[
                                                                questionobj.id
                                                              ]?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                              ? true
                                                              : (Alert.isAdmin ||
                                                                  Alert.isTrainer) &&
                                                                questionobj.answer?.includes(
                                                                  option.optionid +
                                                                    ""
                                                                )
                                                              ? true
                                                              : false
                                                            : option.iscorrectoption ===
                                                                1 &&
                                                              questionobj.answer?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                            ? true
                                                            : option.iscorrectoption ===
                                                                0 &&
                                                              questionobj.answer?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                            ? true
                                                            : false
                                                        }
                                                        style={{
                                                          accentColor:
                                                            Props.status !==
                                                            constants
                                                              .PROGRESS_STATUS
                                                              .EVALUATED
                                                              ? Alert.answers[
                                                                  questionobj.id
                                                                ]?.includes(
                                                                  option.optionid +
                                                                    ""
                                                                )
                                                                ? "#F96343"
                                                                : (Alert.isAdmin ||
                                                                    Alert.isTrainer) &&
                                                                  questionobj.answer?.includes(
                                                                    option.optionid +
                                                                      ""
                                                                  )
                                                                ? "#F96343"
                                                                : ""
                                                              : (Alert.isAdmin ||
                                                                  Alert.isTrainer ||
                                                                  (Alert.isLearner &&
                                                                    StudentTestPageCxt.viewTest ===
                                                                      constants
                                                                        .DISPLAY_TEST_RESULT
                                                                        .ALL)) &&
                                                                option.iscorrectoption ===
                                                                  1 &&
                                                                questionobj.answer?.includes(
                                                                  option.optionid +
                                                                    ""
                                                                )
                                                              ? "green"
                                                              : (Alert.isAdmin ||
                                                                  Alert.isTrainer ||
                                                                  (Alert.isLearner &&
                                                                    StudentTestPageCxt.viewTest ===
                                                                      constants
                                                                        .DISPLAY_TEST_RESULT
                                                                        .ALL)) &&
                                                                option.iscorrectoption ===
                                                                  0 &&
                                                                questionobj.answer?.includes(
                                                                  option.optionid +
                                                                    ""
                                                                )
                                                              ? "#F04438"
                                                              : "#F96343",
                                                        }}
                                                      />
                                                      <label className="commontest-mainpage-multiple-question-options-label">
                                                        {option.optiontext}
                                                      </label>
                                                    </div>
                                                    <div>
                                                      {(Alert.isAdmin ||
                                                        Alert.isTrainer ||
                                                        (Alert.isLearner &&
                                                          StudentTestPageCxt.viewTest ===
                                                            constants
                                                              .DISPLAY_TEST_RESULT
                                                              .ALL)) &&
                                                      option.iscorrectoption ===
                                                        1 ? (
                                                        <RightSvgIcon />
                                                      ) : (Alert.isAdmin ||
                                                          Alert.isTrainer ||
                                                          (Alert.isLearner &&
                                                            StudentTestPageCxt.viewTest ===
                                                              constants
                                                                .DISPLAY_TEST_RESULT
                                                                .ALL)) &&
                                                        option.iscorrectoption ===
                                                          0 &&
                                                        questionobj.answer?.includes(
                                                          option.optionid + ""
                                                        ) ? (
                                                        <WobackCloseSvg
                                                          stoke={"#F04438"}
                                                        />
                                                      ) : (
                                                        ""
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : questionobj.libtypeid ===
                                                constants.libTypeIds
                                                  .MCQ_MULTI ? (
                                                <div
                                                  key={
                                                    option.optionid +
                                                    "" +
                                                    optindex +
                                                    StudentTestPageCxt.secIndex +
                                                    questionobj.libtypeid
                                                  }
                                                  className={
                                                    Alert.isAdmin
                                                      ? "singleViewQuestionBoxDetailsOptionsDivEachOptionAdmin"
                                                      : "commontest-mainpage-single-question-option-each-div"
                                                  }
                                                  onClick={
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .UNLOCKED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .REATTEMPT ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .QN_CONTINUE
                                                      ? () => {
                                                          collectAnswers(
                                                            questionobj.id,
                                                            option.optionid +
                                                              "",
                                                            questionobj.libtypeid,
                                                            StudentTestPageCxt.secIndex,
                                                            queindex,
                                                            0
                                                          );
                                                        }
                                                      : null
                                                  }
                                                  style={{
                                                    borderColor:
                                                      Props.status !==
                                                      constants.PROGRESS_STATUS
                                                        .EVALUATED
                                                        ? Alert.answers[
                                                            questionobj.id
                                                          ]?.includes(
                                                            option.optionid + ""
                                                          )
                                                          ? "#F96343"
                                                          : (Alert.isAdmin ||
                                                              Alert.isTrainer) &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? "#F96343"
                                                          : ""
                                                        : (Alert.isAdmin ||
                                                            Alert.isTrainer ||
                                                            (Alert.isLearner &&
                                                              StudentTestPageCxt.viewTest ===
                                                                constants
                                                                  .DISPLAY_TEST_RESULT
                                                                  .ALL)) &&
                                                          option.iscorrectoption ===
                                                            1 &&
                                                          questionobj.answer?.includes(
                                                            option.optionid + ""
                                                          )
                                                        ? "#12B76A"
                                                        : (Alert.isAdmin ||
                                                            Alert.isTrainer ||
                                                            (Alert.isLearner &&
                                                              StudentTestPageCxt.viewTest ===
                                                                constants
                                                                  .DISPLAY_TEST_RESULT
                                                                  .ALL)) &&
                                                          option.iscorrectoption ===
                                                            0 &&
                                                          questionobj.answer?.includes(
                                                            option.optionid + ""
                                                          )
                                                        ? "#F04438"
                                                        : "",
                                                    border:
                                                      Props.status !==
                                                      constants.PROGRESS_STATUS
                                                        .EVALUATED
                                                        ? Alert.answers[
                                                            questionobj.id
                                                          ]?.includes(
                                                            option.optionid + ""
                                                          )
                                                          ? "2px solid #F96343"
                                                          : (Alert.isAdmin ||
                                                              Alert.isTrainer) &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? "2px solid #F96343"
                                                          : ""
                                                        : (Alert.isAdmin ||
                                                            Alert.isTrainer ||
                                                            (Alert.isLearner &&
                                                              StudentTestPageCxt.viewTest ===
                                                                constants
                                                                  .DISPLAY_TEST_RESULT
                                                                  .ALL)) &&
                                                          option.iscorrectoption ===
                                                            1 &&
                                                          questionobj.answer?.includes(
                                                            option.optionid + ""
                                                          )
                                                        ? "2px solid #12B76A"
                                                        : (Alert.isAdmin ||
                                                            Alert.isTrainer ||
                                                            (Alert.isLearner &&
                                                              StudentTestPageCxt.viewTest ===
                                                                constants
                                                                  .DISPLAY_TEST_RESULT
                                                                  .ALL)) &&
                                                          option.iscorrectoption ===
                                                            0 &&
                                                          questionobj.answer?.includes(
                                                            option.optionid + ""
                                                          )
                                                        ? "2px solid #F04438"
                                                        : "",
                                                  }}
                                                >
                                                  <div
                                                    className={
                                                      "commontest-mainpage-multiple-question-options-input-label-div"
                                                    }
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      className="commontest-mainpage-multiple-question-options-input"
                                                      key={
                                                        option.optionid +
                                                        "" +
                                                        optindex +
                                                        StudentTestPageCxt.secIndex +
                                                        questionobj.libtypeid
                                                      }
                                                      name={
                                                        questionobj.id +
                                                        "" +
                                                        StudentTestPageCxt
                                                          .sectionObj.sectionid
                                                      }
                                                      id={
                                                        option.optionid +
                                                        "" +
                                                        optindex +
                                                        StudentTestPageCxt.secIndex +
                                                        questionobj.libtypeid
                                                      }
                                                      value={
                                                        option.optionid +
                                                        "" +
                                                        optindex +
                                                        StudentTestPageCxt.secIndex +
                                                        questionobj.libtypeid
                                                      }
                                                      onChange={() => {
                                                        collectAnswers(
                                                          questionobj.id,
                                                          option.optionid + "",
                                                          questionobj.libtypeid,
                                                          StudentTestPageCxt.secIndex,
                                                          queindex,
                                                          0
                                                        );
                                                      }}
                                                      checked={
                                                        Props.status !==
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .EVALUATED
                                                          ? Alert.answers[
                                                              questionobj.id
                                                            ]?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                            ? true
                                                            : (Alert.isAdmin ||
                                                                Alert.isTrainer) &&
                                                              questionobj.answer?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                            ? true
                                                            : false
                                                          : option.iscorrectoption ===
                                                              1 &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? true
                                                          : option.iscorrectoption ===
                                                              0 &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? true
                                                          : false
                                                      }
                                                      style={{
                                                        accentColor:
                                                          Props.status !==
                                                          constants
                                                            .PROGRESS_STATUS
                                                            .EVALUATED
                                                            ? Alert.answers[
                                                                questionobj.id
                                                              ]?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                              ? "#F96343"
                                                              : (Alert.isAdmin ||
                                                                  Alert.isTrainer) &&
                                                                questionobj.answer?.includes(
                                                                  option.optionid +
                                                                    ""
                                                                )
                                                              ? "#F96343"
                                                              : ""
                                                            : (Alert.isAdmin ||
                                                                Alert.isTrainer ||
                                                                (Alert.isLearner &&
                                                                  StudentTestPageCxt.viewTest ===
                                                                    constants
                                                                      .DISPLAY_TEST_RESULT
                                                                      .ALL)) &&
                                                              option.iscorrectoption ===
                                                                1 &&
                                                              questionobj.answer?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                            ? "green"
                                                            : (Alert.isAdmin ||
                                                                Alert.isTrainer ||
                                                                (Alert.isLearner &&
                                                                  StudentTestPageCxt.viewTest ===
                                                                    constants
                                                                      .DISPLAY_TEST_RESULT
                                                                      .ALL)) &&
                                                              option.iscorrectoption ===
                                                                0 &&
                                                              questionobj.answer?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                            ? "#F04438"
                                                            : "#F96343",
                                                      }}
                                                    />
                                                    <label className="commontest-mainpage-multiple-question-options-label">
                                                      {option.optiontext}
                                                    </label>
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )
                                          )
                                        )}
                                      </div>
                                    </div>
                                    {Alert.isAdmin &&
                                      (Props.status ===
                                        constants.PROGRESS_STATUS.EVALUATED ||
                                        Props.status ===
                                          constants.PROGRESS_STATUS
                                            .TO_BE_EVALUATED ||
                                        Props.status ===
                                          constants.PROGRESS_STATUS
                                            .PARTIAL_EVALUATED) &&
                                      (Alert.backupTestData.sections[0]
                                        .questions[0]?.libtypeid ===
                                        constants.libTypeIds.SAQ ||
                                        Alert.backupTestData.sections[0]
                                          .questions[0]?.libtypeid ===
                                          constants.libTypeIds.LAQ) && (
                                        <div className="admin-Marks-Input-Div">
                                          <div className="admin-Marks-Input-DivItems">
                                            <div
                                              key={questionobj.id}
                                              className="admin-Marks-Input"
                                            >
                                              <input
                                                min="0"
                                                className="saq-Laq-Marks-Entry"
                                                max={
                                                  questionobj.targetscore + ""
                                                }
                                                placeholder={
                                                  questionobj.hasOwnProperty(
                                                    "actualscore"
                                                  )
                                                    ? questionobj.actualscore
                                                    : ""
                                                }
                                                value={
                                                  StudentTestPageCxt.descScores.hasOwnProperty(
                                                    questionobj.id
                                                  ) &&
                                                  StudentTestPageCxt.descScores[
                                                    questionobj.id
                                                  ] != null
                                                    ? StudentTestPageCxt
                                                        .descScores[
                                                        questionobj.id
                                                      ]
                                                    : questionobj.hasOwnProperty(
                                                        "actualscore"
                                                      ) &&
                                                      questionobj.actualscore !=
                                                        null
                                                    ? questionobj.actualscore
                                                    : ""
                                                }
                                                style={{
                                                  borderColor:
                                                    StudentTestPageCxt.scoreErrors.hasOwnProperty(
                                                      questionobj.id
                                                    )
                                                      ? "red"
                                                      : "black",
                                                }}
                                                onChange={(e) => {
                                                  collectScores(
                                                    questionobj.id,
                                                    e.target.value,
                                                    questionobj.targetscore,
                                                    e
                                                  );
                                                }}
                                                onKeyDown={(e) => {
                                                  StudentTestPageHks.handleScoreKeyDown(
                                                    e
                                                  );
                                                }}
                                                onWheel={(e) => e.target.blur()}
                                              />

                                              <div className="approveScoreDivTotalMarks">
                                                /{" "}
                                                {questionobj.targetscore +
                                                  " Marks"}
                                              </div>
                                            </div>

                                            {Alert.isAdmin && (
                                              <div>
                                                <Box sx={{ width: 250 }}>
                                                  <PrettoSlider
                                                    valueLabelDisplay="auto"
                                                    aria-label="pretto slider"
                                                    step={0.5}
                                                    value={
                                                      StudentTestPageCxt.descScores.hasOwnProperty(
                                                        questionobj.id
                                                      )
                                                        ? StudentTestPageCxt
                                                            .descScores[
                                                            questionobj.id
                                                          ]
                                                        : questionobj.hasOwnProperty(
                                                            "actualscore"
                                                          ) &&
                                                          questionobj.actualscore ==
                                                            null
                                                        ? ""
                                                        : questionobj.actualscore
                                                    }
                                                    onChange={(e) => {
                                                      collectScores(
                                                        questionobj.id,
                                                        e.target.value,
                                                        questionobj.targetscore,
                                                        e
                                                      );
                                                    }}
                                                    disabled={
                                                      StudentTestPageCxt.giveZeros &&
                                                      (questionobj.answer ===
                                                        "" ||
                                                        questionobj.answer ==
                                                          null)
                                                    }
                                                    max={
                                                      questionobj.targetscore
                                                    }
                                                  />
                                                </Box>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                  </>
                                </div>
                                <div className="commontest-mainpage-single-multiple-question-answer-score-div">
                                  {StudentTestPageCxt.showSaqLaqAns &&
                                    (questionobj.libtypeid ===
                                      constants.libTypeIds.MCQ_SINGLE ||
                                      questionobj.libtypeid ===
                                        constants.libTypeIds.MCQ_MULTI) && (
                                      <div className="mcq-admin-answer-div">
                                        <div className="mcq-admin-answer-label-div">
                                          Explanation
                                        </div>

                                        {/* Normal text area for mcq explanation */}
                                        {/* <textarea
                                      rows={5}
                                      disabled={true}
                                      className="mcq-admin-answer"
                                      key={questionobj.id + "showanswer"}
                                      name={
                                        questionobj.id +
                                        "" +
                                        StudentTestPageCxt.sectionObj.sectionid +
                                        "showanswer"
                                      }
                                      id={questionobj.id + "showanswer"}
                                      value={questionobj.answerexplanation}
                                    /> */}

                                        <Editor
                                          toolbar=""
                                          disabled={true}
                                          menubar={false}
                                          branding={false}
                                          statusbar={false}
                                          className="mcq-admin-answer"
                                          value={questionobj?.answerexplanation}
                                          tinymceScriptSrc="/tinymce/tinymce.min.js"
                                          onInit={(evt, editor) =>
                                            (editorRef.current = editor)
                                          }
                                          init={{
                                            resize: false,
                                            readonly: true,
                                            menubar: false,
                                            statusbar: false,
                                            license_key: "gpl",
                                            plugins: "autoresize",
                                          }}
                                        />
                                      </div>
                                    )}
                                  <div className="commontest-mainpage-single-multiple-question-divider-div"></div>
                                </div>
                              </>
                            ))}{" "}
                          </div>
                        ))}
                      </div>
                    }
                  </>
                ) : null}
              </div>
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
