import React from "react";
import { useState, useContext } from "react";
import AlertContext from "../../../../context/AlertContext";
import UserTable from "./UserTable.js";
const Users = () => {
  const { filterUserReportData, setFilterUserReportData} =useContext(AlertContext);
  const [selectedLearners, setSelectedLearners] = useState([]);
  const [showInActiveUsers, setShowInActiveUsers] = useState(false);
  return (
    <UserTable
      data={filterUserReportData}
      status={showInActiveUsers}
      selectedLearners={selectedLearners}
      setSelectedLearners={setSelectedLearners}
      setFilterUserReportData={setFilterUserReportData}
    />
  );
};
export default Users;
