import constants from "../../../constants";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../../context/AlertContext";
import StudentTestContainer from "./StudentTestContainer";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import Notify from "../../Notification-Loading/Notify/Notify";
import { getPreSignedUrl, getDataFromStorage } from "../../../util";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";
import { StudentTestPageContext } from "../../../context/StudentTestPageContext";
import StudentTestPageHooks from "../../../Hooks/StudentTestPage/StudentTestPageHooks";
import CodingHeaderLearnerSideHooks from "../../../Hooks/CodingPage/CodingHeaderLearnerSideHooks";
import StudentTestPageAnswerHooks from "../../../Hooks/StudentTestPage/StudentTestPageAnswerHooks";

export default function StudentTestPage() {
  const Alert = useContext(AlertContext);
  const StudentTestPageCxt = useContext(StudentTestPageContext);

  const axios = useAxiosPrivate();
  const StudentTestPageHks = StudentTestPageHooks();
  const useCommonFunctionHks = useCommonFunctionHooks();
  const { submitAnswers } = CodingHeaderLearnerSideHooks();
  const { handleShowAnsforPrac } = StudentTestPageAnswerHooks();

  let uRole = getDataFromStorage("role");

  const handleSaveTest = () => {
    useCommonFunctionHks.handleClose();
    submitAnswers();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!Alert.isAdmin) StudentTestPageHks.preventCopyAndCut(event);
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [Alert.isAdmin, StudentTestPageHks]);

  // useEffect(() => {
  //   if (
  //     Alert.isAdmin &&
  //     StudentTestPageCxt.mcqAndDesTestAndLpId.status !==
  //       constants.PROGRESS_STATUS.UNLOCKED
  //   ) {
  //     if (Alert.isAdmin) {
  //       Alert.hasRendered.current = false;
  //     }

  //     if (!Alert.hasRendered.current) {
  //       Alert.hasRendered.current = true;

  //       Alert.setIsLoaded(true);
  //       axios
  //         .post(
  //           "node/learner/course/test/report",
  //           JSON.stringify({
  //             lpid: StudentTestPageCxt.mcqAndDesTestAndLpId.lpid,
  //             testid: StudentTestPageCxt.mcqAndDesTestAndLpId.testid,
  //             learnerid: StudentTestPageCxt.mcqAndDesTestAndLpId.learnerid,
  //             libtypeid: StudentTestPageCxt.mcqAndDesTestAndLpId.libtypeid,
  //             lpconfigid: StudentTestPageCxt.mcqAndDesTestAndLpId.lpconfigid,
  //           }),
  //           useCommonFunctionHks.getHeaderDetailsForAxios()
  //         )
  //         .then((res) => {
  //           Alert.backupTestDataq.current = res.data.data;

  //           (async () => {
  //             try {
  //               if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
  //                 Alert.setShowNotify({
  //                   show: true,
  //                   title: "Info",
  //                   msg: res.data.message,
  //                 });
  //               } else {
  //                 StudentTestPageHks.tempReport = res.data.data;

  //                 if (StudentTestPageHks.tempReport.videoqn === 1) {
  //                   StudentTestPageHks.handleVideoQuestions(
  //                     StudentTestPageHks.tempReport.sections
  //                   );
  //                 }

  //                 Alert.setTsCoBkup({});

  //                 for (let i = 0; i < res.data.data.sections.length; i++) {
  //                   for (
  //                     let j = 0;
  //                     j < res.data.data.sections[i].questions.length;
  //                     j++
  //                   ) {
  //                     if (process.env.REACT_APP_DEPLOYMENT === "AWS") {
  //                       let question = res.data.data.sections[i].questions[j];

  //                       if (uRole === "learner") {
  //                         question.review = -1;
  //                       }

  //                       for (let k = 0; k < question.links.length; k++) {
  //                         let linkRes = await getPreSignedUrl(
  //                           question.links[k].link
  //                         );
  //                         question.links[k].link = linkRes;
  //                       }

  //                       if (StudentTestPageHks.tempReport.videoqn === 1) {
  //                         if (
  //                           res.data.data.sections[i].questions[j].answer !==
  //                             "" &&
  //                           res.data.data.sections[i].questions[j].answer !=
  //                             null
  //                         ) {
  //                           let videoRes = await getPreSignedUrl(
  //                             res.data.data.sections[i].questions[j].answer
  //                           );
  //                           res.data.data.sections[i].questions[j].answer =
  //                             videoRes;
  //                         }
  //                       }
  //                     }
  //                   }
  //                 }

  //                 Alert.setApiLoaded(true);
  //                 Alert.setMcqTestData(res.data.data);
  //                 StudentTestPageHks.calcStats(StudentTestPageHks.tempReport, [
  //                   "all",
  //                 ]);
  //                 Alert.updateComingObj(res.data.data);
  //                 clearInterval(Alert.interval.current);
  //                 Alert.setBackupTestData(res.data.data);
  //                 Alert.setTestMode(res.data.data.testmode);
  //                 Alert.updateQuestionContainerSingle(false);
  //                 Alert.backupTestDataq.current = res.data.data;
  //                 Alert.setCommentsData(res.data.data.commentsdata);

  //                 Alert.setRejectData({
  //                   duration: res.data.data.duration,
  //                   timeleft: res.data.data.timeleft,
  //                   remaingQnCount: res.data.data.remaingQnCount,
  //                 });

  //                 Alert.setTestPageShow(true);
  //               }
  //             } catch (err) {
  //               useCommonFunctionHks.getCatchBlockDetails(err);
  //             } finally {
  //               Alert.setIsLoaded(false);
  //             }
  //           })();
  //         })
  //         .catch((error) => {
  //           useCommonFunctionHks.getCatchBlockDetails(error);
  //         })
  //         .finally(() => Alert.setIsLoaded(false));
  //     }
  //   }
  // }, [
  //   Alert,
  //   axios,
  //   uRole,
  //   StudentTestPageCxt,
  //   StudentTestPageHks,
  //   useCommonFunctionHks,
  // ]);

  return (
    <>
      {(!Alert.isAdmin || (Alert.apiLoaded && Alert.isAdmin)) && (
        <StudentTestContainer />
      )}
      {
        <Notify
          svg={Alert.svg}
          show={Alert.show}
          title={Alert.title}
          message={Alert.msg}
          btnText={Alert.btnText}
          resultCode={Alert.resultCode}
          onHide={
            Alert.action === "submitscores"
              ? useCommonFunctionHks.handleClose
              : Alert.resultCode === constants.RESULT_STATUS.NETWORK_ISSUE &&
                !Alert.isAdmin
              ? handleSaveTest
              : Alert.action === "resubmit"
              ? () => {
                  Alert.setShow(false);
                  Alert.navigate("/batches/report");
                }
              : Alert.isAdmin
              ? () => {
                  Alert.setIsLoaded(false);
                  Alert.setShow(false);
                }
              : constants.MODE.TEST_MODE === Alert.testMode
              ? Alert.handleNavigateToHome
              : handleShowAnsforPrac
          }
        />
      }
    </>
  );
}
