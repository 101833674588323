import Webcam from "react-webcam";
import Button from "../../Button";
import TestDetail from "./TestDetail";
import React, { useContext } from "react";
import constants from "../../../constants";
import WarningNotice from "./WarningNotice";
import { Editor } from "@tinymce/tinymce-react";
import ReviewSvgIcon from "../../../Svg/ReviewSvgIcon";
import AlertContext from "../../../context/AlertContext";
import RightArrowSvgIcon from "../../../Svg/RightArrowSvgIcon";
import InstructionPageHooks from "../../../Hooks/InstructionPageHooks";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";

export default function InstructionPage() {
  const { status, testData, buttonDisable } = useContext(AlertContext);

  const { handleWebCamError } = useCommonFunctionHooks();
  const { handleCheck, videoConstraints, getButtonText } =
    InstructionPageHooks();

  return (
    <div>
      {testData.libtype !== constants.libTypeIds.ASSIGNMENT && <TestDetail />}
      <div className="instructionPageMainContainer">
        <div className="instructionPage">
          {status !== constants.PROGRESS_STATUS.UNLOCKED ||
            (status === constants.PROGRESS_STATUS.IN_PROGRESS &&
              testData.libtype === constants.libTypeIds.ASSIGNMENT && (
                <WarningNotice />
              ))}
          {status !== constants.PROGRESS_STATUS.IN_PROGRESS && (
            <>
              <p className="instructionPageHeader">
                {testData.testtype === constants.libTypeIds.CODING_CHALLENGE
                  ? `Please read the instructions carefully before starting the Coding
            Challenge:`
                  : `Please read the instructions carefully before restarting the ${testData.testtype === constants.libTypeIds.MCQ
                    ? "MCQ"
                    : "Descriptive"
                  } test:`}
              </p>
              <div className="instructionPageWebCamDiv">
                <ul className="instructionPageBodyList">
                  {testData.testtype ===
                    constants.libTypeIds.CODING_CHALLENGE && (
                      <li>
                        The coding challenge is proctored, an invigilator will be
                        monitoring your activity throughout the test.
                      </li>
                    )}
                  <li>
                    The countdown timer in the top right displays the remaining
                    time available.
                  </li>
                  <li>
                    When the countdown timer reaches zero, the test will be
                    auto-submitted.
                  </li>
                  <li
                    className={
                      testData.testtype !==
                      constants.libTypeIds.CODING_CHALLENGE &&
                      "instructionPageBodyListInnerText"
                    }
                  >
                    {testData.testtype === constants.libTypeIds.CODING_CHALLENGE
                      ? `If you switch tabs from this window, the assignment will be auto
                submitted. Every time you do this, your marks against this
                challenge will be reduced.`
                      : `The question selector on the right side of the screen will show the status of each question using these specific status:`}
                  </li>
                  {testData.testtype !==
                    constants.libTypeIds.CODING_CHALLENGE && (
                      <ul className="instructionPageBodyListUnordered">
                        <li className="instructionPageBodyUnorderedList">
                          <div className="instructionPageBodyUnorderedListNotViewLegend"></div>
                          <span className="instructionPageBodyUnorderedListLegendText">
                            You are yet to view this question.
                          </span>
                        </li>
                        <li className="instructionPageBodyUnorderedList">
                          <div className="instructionPageBodyUnorderedListViewLegend"></div>
                          <span className="instructionPageBodyUnorderedListLegendText">
                            You viewed the question, but did not answer.
                          </span>
                        </li>
                        <li className="instructionPageBodyUnorderedList">
                          <div className="instructionPageBodyUnorderedListReviewLegend"></div>
                          <span className="instructionPageBodyUnorderedListLegendText">
                            You have marked the question for review.
                          </span>
                        </li>
                        <li className="instructionPageBodyUnorderedList">
                          <div className="instructionPageBodyUnorderedListAnsweredLegend"></div>
                          <span className="instructionPageBodyUnorderedListLegendAnsweredText">
                            You have answered the question.
                          </span>
                        </li>
                      </ul>
                    )}
                </ul>
                {testData.testmode === constants.MODE.TEST_MODE && testData.proctored === constants.PROCTORING_MODE.TAB_CAM && (
                  <Webcam
                    screenshotFormat="image/jpeg"
                    className="instructionPageWebCam"
                    videoConstraints={videoConstraints}
                    onUserMediaError={handleWebCamError}
                  />
                )}
              </div>
              {testData.testtype === constants.libTypeIds.CODING_CHALLENGE ? (
                <ul className="instructionPageBodyList">
                  <li>
                    The next time you engage in this challenge, you will be
                    presented with a problem of similar level of difficulty to
                    solve. You will not encounter the same problem again as it
                    will be a fresh opportunity to test your skills.
                  </li>
                  <li>
                    If you exhaust the challenges under this category of similar
                    difficulty, your account will be locked and your score
                    against this will be marked as "0".
                  </li>
                  <li>
                    If you continue navigating away from this page multiple
                    times, your account will be locked.
                  </li>
                </ul>
              ) : (
                <ul className="instructionPageBodyList">
                  <li className="instructionPageBodyListInnerText">
                    You can bookmark a question to review later, by clicking on
                    the icon next to the question:
                  </li>
                  <ul className="instructionPageBodyListUnordered">
                    <li className="instructionPageBodyUnorderedList">
                      <ReviewSvgIcon />
                      <span className="instructionPageBodyUnorderedListLegendText">
                        Question not marked for review
                      </span>
                    </li>
                    <li className="instructionPageBodyUnorderedList">
                      <ReviewSvgIcon mark={"mark"} />
                      <span className="instructionPageBodyUnorderedListLegendText">
                        Question marked for review
                      </span>
                    </li>
                  </ul>
                </ul>
              )}
              {testData.instruction !== null && (
                <div className="additionalInstructionDiv">
                  <p className="instructionPageSubHeader">
                    Additional instructions:
                  </p>
                  <Editor
                    disabled={true}
                    toolbar={false}
                    menubar={false}
                    branding={false}
                    statusbar={false}
                    value={testData.instruction}
                    tinymceScriptSrc="/tinymce/tinymce.min.js"
                    init={{
                      license_key: "gpl",
                      resize: false,
                      readonly: true,
                      menubar: false,
                      statusbar: false,
                      selector: "textarea",
                      plugins: "autoresize",
                    }}
                  />
                </div>
              )}

              <div className="instructionPageFooter">
                <Button
                  size={"lg"}
                  onClick={handleCheck}
                  disable={buttonDisable}
                  icon={{ type: "trailing", icon: <RightArrowSvgIcon /> }}
                  hierarchy={{
                    type: "primary",
                    buttonText: getButtonText(),
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
