import React, { useEffect, useState, useContext, useCallback } from "react";
import Trainers from "../../../CommonComponents/Trainers";
import SettingsRightHeader from "./SettingsRightHeader";
import Button from "../../../Button";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import AlertContext from "../../../../context/AlertContext";
import { useParams } from "react-router-dom";
import constants from "../../../../constants";
import { emailBody } from "../../../../util";
import UserDetails from "../Users/UserDetails";
import Modal from "../../../CommonComponents/Modal";
import ModalBody from "../../../CommonComponents/ModalBody";

export const WarningFeatureIcon = (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEF0C7" />
    <rect
      x="4"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#FFFAEB"
      strokeWidth="8"
    />
    <path
      d="M27.9998 24V28M27.9998 32H28.0098M26.2898 18.86L17.8198 33C17.6451 33.3024 17.5527 33.6453 17.5518 33.9945C17.5508 34.3437 17.6413 34.6871 17.8142 34.9905C17.9871 35.2939 18.2365 35.5467 18.5375 35.7238C18.8385 35.9009 19.1806 35.9961 19.5298 36H36.4698C36.819 35.9961 37.1611 35.9009 37.4621 35.7238C37.7631 35.5467 38.0124 35.2939 38.1854 34.9905C38.3583 34.6871 38.4488 34.3437 38.4478 33.9945C38.4468 33.6453 38.3544 33.3024 38.1798 33L29.7098 18.86C29.5315 18.5661 29.2805 18.3231 28.981 18.1544C28.6814 17.9858 28.3435 17.8972 27.9998 17.8972C27.656 17.8972 27.3181 17.9858 27.0186 18.1544C26.7191 18.3231 26.468 18.5661 26.2898 18.86Z"
      stroke="#DC6803"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const BatchTrainers = () => {
  const { id } = useParams();
  const axios = useAxiosPrivate();
  const { setShowNotify, setIsLoaded } = useContext(AlertContext);
  const [trainers, setTrainers] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [userInput, setUserInput] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [trainerId, setTrainerId] = useState(null);

  const fetchTrainers = useCallback(async () => {
    try {
      setIsLoaded(true);
      let response = await axios.get(
        `node/admin/batch/${id}/users/${constants.Roles.trainer}`,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (response.data.resultCode === 1000) {
        if (response.data.data !== null) {
          let res = response.data.data;
          setTrainers(res);
        }
      } else
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later or contact gradious team",
        });
    } catch (error) {
      console.log("error in while fetch trainers", error);
      if (error.message.includes("403")) {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "You have been logged-out due to inactivity. Login again.",
          isUnAuth: true,
        });
      } else
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later or contact gradious team",
        });
    } finally {
      setIsLoaded(false);
    }
  }, [axios, id, setShowNotify, setIsLoaded]);

  useEffect(() => {
    fetchTrainers();
  }, [fetchTrainers]);

  const removeTrainer = async (userId) => {
    if (userId) {
      setOpenModal(true);
      setTrainerId(userId);
    } else {
      setShowNotify({
        title: "Warning !",
        msg: "Sorry!. Unable to find the trainer. please contact gradious team",
        show: true,
      });
    }
  };

  const onCloseModal = (event) => {
    event.preventDefault();
    setOpenModal(false);
    setTrainerId(null);
  };

  const onRemoveTrainer = async (event) => {
    event.preventDefault();
    setIsLoaded(true);
    try {
      let res = await axios.post(
        "node/admin/batch/users/status",
        JSON.stringify({
          userIds: [trainerId],
          active: false,
          batchId: id,
          removeTrainer: true,
        }),
        { headers: { "Content-type": "application/json" } }
      );

      if (res.data.resultCode === 1000) {
        setShowNotify({
          show: true,
          title: "Info",
          msg: res.data.msg,
        });
        setTrainers(res.data.data);
      } else {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: res.data.message,
        });
      }
    } catch (error) {
      setIsLoaded(false);
      if (error.message.includes("403")) {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "You have been logged-out due to inactivity. Login again.",
          isUnAuth: true,
        });
      } else
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later or contact gradious team.",
        });
    } finally {
      setIsLoaded(false);
    }
  };

  const onAddTrainer = (event) => {
    event.preventDefault();
    setShowForm(true);
    setUserInput({
      emailBody: emailBody,
      batchid: parseInt(id),
      role: "trainer",
    });
  };

  return (
    <div className="batchTrainersContainer">
      <SettingsRightHeader
        title="Trainers"
        titleIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 21V19C22 17.1362 20.7252 15.5701 19 15.126M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
              stroke="#344054"
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        button={
          <Button
            size="sm"
            hierarchy={{ type: "secondaryGrey", buttonText: "Add Trainer" }}
            icon={{
              icon: (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0001 4.16666V15.8333M4.16675 10H15.8334"
                    stroke="#344054"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ),
              type: "leading",
            }}
            onClick={onAddTrainer}
          />
        }
      />
      <div className="batchTrainersSec">
        <Trainers
          trainerData={trainers}
          icon={{
            icon: (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 5.00001H4.16667M4.16667 5.00001H17.5M4.16667 5.00001V16.6667C4.16667 17.1087 4.34226 17.5326 4.65482 17.8452C4.96738 18.1577 5.39131 18.3333 5.83333 18.3333H14.1667C14.6087 18.3333 15.0326 18.1577 15.3452 17.8452C15.6577 17.5326 15.8333 17.1087 15.8333 16.6667V5.00001H4.16667ZM6.66667 5.00001V3.33334C6.66667 2.89131 6.84226 2.46739 7.15482 2.15483C7.46738 1.84227 7.89131 1.66667 8.33333 1.66667H11.6667C12.1087 1.66667 12.5326 1.84227 12.8452 2.15483C13.1577 2.46739 13.3333 2.89131 13.3333 3.33334V5.00001"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
            onClick: removeTrainer,
          }}
        />
      </div>
      {showForm && (
        <UserDetails
          showUserFormPopup={showForm}
          newUserInput={userInput}
          setNewUserInput={setUserInput}
          setShowUserFormPopup={setShowForm}
          refetch={fetchTrainers}
        />
      )}
      {openModal && (
        <Modal isOpen={openModal} onClose={onCloseModal} showCloseIcon={false}>
          <ModalBody
            header={WarningFeatureIcon}
            content={
              <>
                <p className="contentTitle">Remove Trainer</p>
                <p className="content">
                  Are you sure you want to remove the trainer from the batch?
                </p>
              </>
            }
            footer={
              <>
                <Button
                  size="lg"
                  hierarchy={{ type: "secondaryGrey", buttonText: "Cancel" }}
                  onClick={onCloseModal}
                />
                <Button
                  size="lg"
                  hierarchy={{
                    type: "primary",
                    buttonText: "Remove",
                  }}
                  onClick={onRemoveTrainer}
                  destructive={true}
                />
              </>
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default BatchTrainers;
