import React, { useState, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MainDropDown from "./WidgetMenu/MainDropDown";
import DownloadOptions from "./WidgetMenu/DownloadOptions";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import { makeStyles } from "@mui/styles";
import Loading from "../../../Notification-Loading/Loading/Loading";
import constants from "../../../../constants";
import PaginationCustom from "./PaginationCustom";
function LeaderBoardndPercentile(Props) {
  const axios = useAxiosPrivate();
  const [page, setPage] = useState(1);
  const [showDownloadOptionsDropDown, setShowDownloadOptionsDropDown] =
    useState(false);
  const [widgetColumns, setWidgetColumns] = useState([]);
  const [widgetData, setWidgetData] = useState([]);
  const [totalDataCount, setDataTotalCount] = useState(0);
  const [orientation, setOrientation] = useState("landscape");
  const [render, setRender] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);
  const [sortBy, setSortBy] = useState(
    Props.data.type == constants.BOARD_WIDGETS.LEADERBOARD
      ? "rank"
      : "percentile"
  );
  const [orderBy, setOrderBy] = useState("ASC");
  const getStyles = makeStyles({
    Name: {
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
      textAlign: "left",
      color: "#101828",
      width: `${1140 / widgetColumns.length}px`,
      // height: "60px",
      padding: "9px",
      boxShadow:
        "inset -1px 0 0 0 rgb(224, 224, 224)",
    },
    Email_address: {
      width: `${1140 / widgetColumns.length}px`,

      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      textAlign: "left",
      color: "#667085",
      // height: "60px",
      padding: "9px",
      boxShadow:
        "inset -1px 0 0 0 rgb(224, 224, 224)",
    },
    College: {
      width: `${1140 / widgetColumns.length}px`,
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      textAlign: "left",
      color: "#667085",
      // height: "60px",
      padding: "9px",
      boxShadow:
        "inset -1px 0 0 0 rgb(224, 224, 224)",
    },
    Branch: {
      width: `${1140 / widgetColumns.length}px`,

      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      textAlign: "left",
      color: "#667085",
      // height: "60px",
      padding: "9px",
      boxShadow:
        "inset -1px 0 0 0 rgb(224, 224, 224)",
    },
    Rank: {
      width: `${1140 / widgetColumns.length}px`,
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      textAlign: "left",
      color: "#101828",
      // height: "60px",
      padding: "9px",
      boxShadow:
        "inset -1px 0 0 0 rgb(224, 224, 224)",
    },
    totalscore: {
      width: `${1140 / widgetColumns.length}px`,
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      textAlign: "left",
      color: "#344054",
      // height: "60px",
      padding: "9px",
      boxShadow:
        "inset -1px 0 0 0 rgb(224, 224, 224)",
    },
    Percentile: {
      width: `${1140 / widgetColumns.length}px`,
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      textAlign: "left",
      color: "#344054",
      // height: "60px",
      padding: "9px",
      boxShadow:
        "inset -1px 0 0 0 rgb(224, 224, 224)",
    },
  });

  const classes = getStyles();

  useEffect(() => {
    setRender(false);
    const responseOfWidget = async () => {
      let response = await axios.get(
        `node/admin/dashboard/${Props.dashboardId}/widget/${Props.data.wid}/report`,
        {
          params: {
            type: Props.data.type,
            // offset:  1,
          },
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (response?.data?.data != undefined) {
        setWidgetData(response?.data?.data?.data);
        setFilteredData(response?.data?.data?.data);
        setDataTotalCount(response?.data?.data?.learnersTotalCount);
        let typeOfOrientation =
          response?.data?.data?.orientation == constants.ORIENTATION.LANDSCAPE
            ? "landscape"
            : "portrait";

        setOrientation(typeOfOrientation);
        setMinIndex(0);
        setMaxIndex(10);

        const columns = [];

        let columnfromResponse = response?.data?.data?.data[0];

        if (Props.data.type == constants.BOARD_WIDGETS.LEADERBOARD) {
          columns.push("Rank");
        }

        if (columnfromResponse?.hasOwnProperty("name")) {
          columns.push("Name");
        }

        if (columnfromResponse?.hasOwnProperty("email")) {
          columns.push("Email address");
        }
        if (columnfromResponse?.hasOwnProperty("branch")) {
          columns.push("Branch");
        }
        if (columnfromResponse?.hasOwnProperty("college")) {
          columns.push("College");
        }
        if (Props.data.type == constants.BOARD_WIDGETS.PERCENTILE) {
          columns.push("Percentile");
        }
        if (columnfromResponse?.hasOwnProperty("totalPointsEarned")) {
          columns.push(`Total points`);
        }
        setWidgetColumns(columns);
        setRender(true);
        if (Props.refresh) {
          Props.setRefresh(false);
        }
      }
    };

    responseOfWidget();
  }, [Props.data.wid, Props.refresh]);

  const handleNext = () => {
    let totalPageCount = Math.ceil(totalDataCount / 10);
    let newmin = page * 10;
    let newmax = (page + 1) * 10;
    setMinIndex(newmin);
    setMaxIndex(newmax);
    setPage((prev) => Math.min(prev + 1, totalPageCount));
  };

  const handlePrevious = () => {
    let newmin = (page - 2) * 10;
    let newmax = (page - 1) * 10;
    setMinIndex(newmin);
    setMaxIndex(newmax);
    setPage((prev) => Math.max(prev - 1, 1));
  };

  const getName = (c) => {
    if (c == "Name") return "name";
    else if (c == "Email address") return "email";
    else if (c == "Branch") return "branch";
    else if (c == "College") return "college";
    else if (c == "Percentile") return "percentile";
    else if (c == "Rank") return "ranking";
    else if (c == "Total points") return "totalPointsEarned";
  };

  const sortByselectedColumn = (col) => {
    let column = getName(col);
    let orderby = "ASC";
    if (column == sortBy) {
      orderby = orderBy == "ASC" ? "DESC" : "ASC";
    }
    let dataTobeSorted;
    if (orderby == "ASC") {
      dataTobeSorted = [...widgetData].sort((a, b) => {
        if (typeof a[column] === "number") {
          return a[column] - b[column];
        } else {
          return a[column]?.trim().localeCompare(b[column]?.trim());
        }
      });
    } else {
      dataTobeSorted = [...widgetData].sort((a, b) => {
        if (typeof a[column] === "number") {
          return b[column] - a[column];
        } else {
          return b[column]?.trim().localeCompare(a[column]?.trim());
        }
      });
    }
    setSortBy(column);
    setFilteredData(dataTobeSorted);
    setOrderBy(orderby);
  };
  return (
    <div className="leaderBoardContainer">
      <div className="boardHeader">
        <div className="leaderBoardwidgetNamendDescription">
          <text id="leaderboardText1"> {Props.data.name}</text>
          {Props.data.description && (
            <text id="leaderboardText2"> {Props.data.description}</text>
          )}
        </div>

        <div className="downloadDropdown">
          {showDownloadOptionsDropDown && (
            <div
              onMouseLeave={() => {
                setShowDownloadOptionsDropDown(false);
              }}
            >
              <DownloadOptions
                name={Props?.data?.name}
                data={widgetData}
                orientation={orientation}
              />{" "}
            </div>
          )}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setShowDownloadOptionsDropDown(true)}
            className="boarddownloadicon"
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.6577 12.5V15.8333C17.6577 16.2754 17.4821 16.6993 17.1696 17.0118C16.857 17.3244 16.4331 17.5 15.991 17.5H4.32438C3.88235 17.5 3.45843 17.3244 3.14587 17.0118C2.83331 16.6993 2.65771 16.2754 2.65771 15.8333V12.5M5.99105 8.33333L10.1577 12.5M10.1577 12.5L14.3244 8.33333M10.1577 12.5V2.5"
                stroke="#344054"
                stroke-width="1.67"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <svg
              width="7"
              height="6"
              viewBox="0 0 7 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.78205 0.799874L4.04042 5.53542C3.8889 5.79714 3.81313 5.928 3.71429 5.97195C3.62807 6.01029 3.52964 6.01029 3.44342 5.97195C3.34458 5.928 3.26882 5.79714 3.1173 5.53542L0.375669 0.799876C0.2236 0.537211 0.147566 0.40588 0.158804 0.298091C0.168606 0.204075 0.217862 0.118642 0.294315 0.0630541C0.381968 -0.000677109 0.533722 -0.000677109 0.837229 -0.000677109L6.32049 -0.000677109C6.62399 -0.000677109 6.77575 -0.000677109 6.8634 0.0630541C6.93985 0.118642 6.98911 0.204075 6.99891 0.298091C7.01015 0.40588 6.93412 0.537211 6.78205 0.799874Z"
                fill="#667085"
              />
            </svg>
          </span>
          {/* <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowWidgetDropDown(true);
            }}
            className="columnoptions"
          >
            <svg
              width="4"
              height="16"
              viewBox="0 0 4 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.5 2C0.5 1.60218 0.658035 1.22064 0.93934 0.93934C1.22064 0.658035 1.60218 0.5 2 0.5C2.39782 0.5 2.77936 0.658035 3.06066 0.93934C3.34196 1.22064 3.5 1.60218 3.5 2C3.5 2.39782 3.34196 2.77936 3.06066 3.06066C2.77936 3.34196 2.39782 3.5 2 3.5C1.60218 3.5 1.22064 3.34196 0.93934 3.06066C0.658035 2.77936 0.5 2.39782 0.5 2ZM0.5 8C0.5 7.60218 0.658035 7.22064 0.93934 6.93934C1.22064 6.65804 1.60218 6.5 2 6.5C2.39782 6.5 2.77936 6.65804 3.06066 6.93934C3.34196 7.22064 3.5 7.60218 3.5 8C3.5 8.39782 3.34196 8.77936 3.06066 9.06066C2.77936 9.34196 2.39782 9.5 2 9.5C1.60218 9.5 1.22064 9.34196 0.93934 9.06066C0.658035 8.77936 0.5 8.39782 0.5 8ZM0.5 14C0.5 13.6022 0.658035 13.2206 0.93934 12.9393C1.22064 12.658 1.60218 12.5 2 12.5C2.39782 12.5 2.77936 12.658 3.06066 12.9393C3.34196 13.2206 3.5 13.6022 3.5 14C3.5 14.3978 3.34196 14.7794 3.06066 15.0607C2.77936 15.342 2.39782 15.5 2 15.5C1.60218 15.5 1.22064 15.342 0.93934 15.0607C0.658035 14.7794 0.5 14.3978 0.5 14Z"
                fill="black"
              />
            </svg>
          </span> */}
        </div>
      </div>
      {!render ? (
        <div className="dummyLoad">
          <div className="loadinfileexp">
            <Loading />
          </div>
        </div>
      ) : (
        <div className="tableContainer">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: "#f9fafb" }}>
                <TableRow style={{ height: "60px" }}>
                  {widgetColumns.map((column) => (
                    <TableCell
                      onClick={() => {
                        sortByselectedColumn(column);
                      }}
                      style={{
                        //width: `${1140 / widgetColumns.length}px`,
                        //styleName: Text sm/Medium;
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "20px",
                        // textAlign: "left",
                        color: "#344054",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        padding: "9px",
                        boxShadow:
                          "inset -1px 0 0 0 rgb(224, 224, 224),inset 0 1px 0 0 rgb(224, 224, 224)",
                        // maxWidth: `${1140 / widgetColumns.length}px`
                      }}
                      align={
                        column == "Name" ||
                        column == "Branch" ||
                        column == "College" ||
                        column == "Email address"
                          ? "left"
                          : "center"
                      }
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(minIndex, maxIndex)
                  ?.map((row, rowIndex) => (
                    <TableRow key={rowIndex} style={{ height: "40px" }}>
                      {widgetColumns?.map((column, colIndex) => (
                        <TableCell
                          align={
                            column == "Name" ||
                            column == "Branch" ||
                            column == "College" ||
                            column == "Email address"
                              ? "left"
                              : "center"
                          }
                          // className={classes[getName(column,"y")]}
                          className={
                            column == "Name"
                              ? classes.Name
                              : column == "Email address"
                              ? classes.Email_address
                              : column == "Branch"
                              ? classes.Branch
                              : column == "College"
                              ? classes.College
                              : column == "Rank"
                              ? classes.Rank
                              : column == "Total points"
                              ? classes.totalscore
                              : classes.Percentile
                          }
                          key={colIndex}
                        >
                          {row[getName(column)]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      <div className="boardFooter">
        {render && (
          <PaginationCustom
            from="leaderboard"
            setMaxIndex={setMaxIndex}
            setMinIndex={setMinIndex}
            totalPages={Math.ceil(totalDataCount / 10)}
            currentPage={page}
            setCurrentPage={setPage}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        )}
      </div>
    </div>
  );
}

export default LeaderBoardndPercentile;
