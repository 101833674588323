import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import "./CreateSession.scss";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import "react-datepicker/dist/react-datepicker.css";
import StudentList from "../StudentList/StudentList";
import AlertContext from "../../../../../context/AlertContext";
import React, { useEffect, useState, useContext, useRef } from "react";
import constants from "../../../../../constants";
import moment from "moment";

export default function CreateSession() {
  const { id } = useParams();
  const {
    setMsg,
    sessionDetails,
    setSessionDetails,
    setShow,
    setTitle,
    setIsLoaded,
    setIsUnAuth,
    batchDetails,
    isEditAttendance,
    setIsEditAttendance,
  } = useContext(AlertContext);
  const axios = useAxiosPrivate();
  const [showUser, setShowUser] = useState(false);
  const [filterdData, setFilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const navigate = useNavigate();
  const datePickerRef = useRef();

  const openDatePicker = () => {
    datePickerRef.current.setOpen(true);
  };
  //moment(date).format("YYYY-MM-DD HH:mm:ss")
  const [data, setData] = useState(
    isEditAttendance
      ? {
          id: sessionDetails.id,
          mode: sessionDetails.mode,
          name: sessionDetails.name,
          status: sessionDetails.status,
          duration: sessionDetails.duration,
          startdate: moment(new Date(sessionDetails.startdate)).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          expiryTime: sessionDetails.expiryTime,
          organizedBy: sessionDetails.organizedBy,
          invitees: [],
          batchId: id,
          sessionLink:sessionDetails.sessionLink
        }
      : {
          mode: constants.SESSION_MODE.ONLINE_SESSION,
          name: "",
          status: "0",
          duration: "",
          startdate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          expiryTime: "",
          organizedBy: "",
          invitees: [],
          batchId: id,
          sessionLink:""

        }
  );

  const handleMode = (e) => {
    const newMode = Number(e.target.value);
    const newExpiryTime =
      newMode === constants.SESSION_MODE.ONLINE_SESSION ? 0 : data.expiryTime;
    setData({
      ...data,
      mode: newMode,
      expiryTime: newExpiryTime,
    });
  };

  const handleCancel = () => {
    setIsEditAttendance(false);
    setData({
      mode: constants.SESSION_MODE.ONLINE_SESSION,
      name: "",
      status: "0",
      duration: "",
      startdate: new Date(),
      expiryTime: "",
      organizedBy: "",
      invitees: [],
    });
    setSessionDetails({});
    navigate(`/batches/${id}/event`);
  };

  const handleClick = (e) => {
    setIsLoaded(true);
    e.preventDefault();
    let found = 0;
    if (data.name === "") {
      found = 1;
      setShow(true);
      setTitle("Warning !");
      setMsg("Please Give a Session Name");
      setIsLoaded(false);
      return;
    } else if (data.organizedBy === "") {
      found = 1;
      setShow(true);
      setTitle("Warning !");
      setMsg("Please Give a OrganizedBy Name");
      setIsLoaded(false);
      return;
    } else if (
      data.mode === constants.SESSION_MODE.OFFLINE_SESSION &&
      (data.expiryTime === "" || data.expiryTime <= 0)
    ) {
      found = 1;
      setShow(true);
      setTitle("Warning !");
      setMsg("Please Enter Valid Time");
      setIsLoaded(false);
      return;
    } else if (data.startdate === "" || data.startdate === null) {
      found = 1;
      setShow(true);
      setTitle("Warning !");
      setMsg("Please Enter Valid Date");
      setIsLoaded(false);
      return;
    } else if (data.duration === "" || data.duration < 1) {
      found = 1;
      setShow(true);
      setTitle("Warning !");
      setMsg("Please Enter Valid Duration");
      setIsLoaded(false);
      return;
    } else if (data.invitees.length === 0) {
      found = 1;
      setShow(true);
      setTitle("Warning !");
      setMsg("Please Select Session Invitees");
      setIsLoaded(false);
      return;
    }

    if (found === 0) {
      axios({
        method: isEditAttendance ? "put" : "post",
        url: "node/admin/attendance/session",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.data.resultCode === 1000) {
            setIsEditAttendance(false);
            setSessionDetails({});
            setSelectedRows([]);
            setData({
              mode: constants.SESSION_MODE.ONLINE_SESSION,
              name: "",
              status: "0",
              duration: "",
              startdate: new Date(),
              expiryTime: "",
              organizedBy: "",
              invitees: [],
            });
            setShow(true);
            setTitle("Sucess");
            if (isEditAttendance) {
              setMsg("Session Updated Sucessfully");
            } else {
              setMsg("Session Created Sucessfully");
            }

            navigate(`/batches/${id}/event`);
          } else {
            setShow(true);
            setMsg("Error Creating Session");
            setTitle("Error");
          }
          setIsLoaded(false);
        })
        .catch((err) => {
          if (err.message.includes("403")) {
            setShow(true);
            setIsUnAuth(true);
            setTitle("Error !");
            setMsg("You have been logged-out due to inactivity. Login again.");
          } else {
            setShow(true);
            setTitle("Error !");
            setMsg("Network Error");
          }
          setIsLoaded(false);
        });
    }
  };

  const handleAddStudents = () => {
    setData({ ...data, invitees: selectedRows });
    setShowUser(false);
  };

  useEffect(() => {
    setIsLoaded(true);
    axios
      .get(
        `node/admin/batch/${id}/users/${constants.Roles.learner}`,
        {
          timeout: 60000,
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.resultCode !== 1000) {
          setIsLoaded(false);
          setShow(true);
          setTitle("Info");
          setMsg(res.data.msg);
        } else {
          setIsLoaded(false);
          setFilteredData(res.data.data);
        }
      })
      .catch((error) => {
        setIsLoaded(false);
        setShow(true);
        setTitle("Error");

        if (error.message.includes("403")) {
          setIsUnAuth(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
        } else {
          setMsg(error.message);
        }
      });

    if (sessionDetails.id != undefined) {
      axios({
        method: "get",
        url: "node/admin/session/users/" + sessionDetails.id,
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (res.data.resultCode === 1000) {
            const userIds = res.data.data.map((obj) => obj.userid);
            //console.log(userIds);
            setData({ ...data, invitees: userIds });
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <>
      <h3 className="addEventTitle">
        {isEditAttendance ? "Edit Event" : "Add Event"}
      </h3>
      <Form className="createSessionForm" onSubmit={handleClick}>
        <Form.Group className="mb-3">
          <Form.Label className="formLabelForSession">
            Session Name <span style={{ color: "red" }}> *</span>
          </Form.Label>
          <Form.Control
            type="text"
            id="sessionName"
            defaultValue={data.name}
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
              // console.log("e.target.value___name", e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="formLabelForSession">
            Organized BY<span style={{ color: "red" }}> *</span>
          </Form.Label>
          <Form.Control
            type="text"
            id="sessionOrgBy"
            defaultValue={data.organizedBy}
            onChange={(e) => {
              setData({ ...data, organizedBy: e.target.value });
              // console.log("e.target.value___organizedBy", e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group className="mb-4 ">
          <Form.Label className="formLabelForSession">
            Date & Time <span style={{ color: "red" }}> *</span>
          </Form.Label>
          <div className="datePickerforSession">
            <DatePicker
              ref={datePickerRef}
              name="startDate"
              //  minDate={moment().toDate()}
              showTimeInput
              autoComplete="off"
              selected={new Date(data.startdate)}
              placeholderText="Pick the date"
              dateFormat="dd/MMM/yyyy h:mm aa"
              onChange={(date) => {
                console.log(
                  "data.startdate>>>>>>>>>>",
                  data.startdate,
                  "<<<<<<<<<<<<<<<<date",
                  date
                );
                let newdate = moment(date).format("YYYY-MM-DD HH:mm:ss");
                setData({ ...data, startdate: newdate });
                // console.log(
                //   "e.target.value_______date",
                //   moment(date).format("YYYY-MM-DD HH:mm:ss")
                // );
              }}
            />
            <div className="calendarIcon">
              <FontAwesomeIcon
                icon={faCalendar}
                className="calendar-icon"
                onClick={openDatePicker}
              />
            </div>
          </div>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="formLabelForSession">
            Duration (Mins)<span style={{ color: "red" }}> *</span>
          </Form.Label>
          <Form.Control
            type="number"
            id="sessionDuration"
            defaultValue={data.duration}
            onChange={(e) => {
              setData({ ...data, duration: e.target.value });
              // console.log("e.target.value___duration", e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3 ">
          <Form.Label className="formLabelForSession">
            Students<span style={{ color: "red" }}> *</span>
          </Form.Label>
          <Form.Control
            readOnly
            type="text"
            id="addStudentForSession"
            value={
              data.invitees.length > 0
                ? "Selected students  " + data.invitees.length
                : "Select students"
            }
            onClick={() => {
              // console.log("data", data.invitees);
              setSelectedRows(data.invitees);
              setShowUser(true);
            }}
          ></Form.Control>
        </Form.Group>

        <Form.Group className="radioBtnforSession">
          <Form.Label className="formLabelForSession">
            Mode<span style={{ color: "red" }}> *</span>
          </Form.Label>
          <br />
          <Form.Check
            inline
            value={constants.SESSION_MODE.ONLINE_SESSION}
            name="mode"
            type="radio"
            label="online"
            onChange={handleMode}
            defaultChecked={data.mode == constants.SESSION_MODE.ONLINE_SESSION}
            className="radioBtnForSessionValue"
          />
          <Form.Check
            inline
            value={constants.SESSION_MODE.OFFLINE_SESSION}
            name="mode"
            type="radio"
            label="offline"
            onChange={handleMode}
            defaultChecked={data.mode == constants.SESSION_MODE.OFFLINE_SESSION}
            className="radioBtnForSessionValue2"
          />
        </Form.Group>

        <Form.Group
          className={
            data.mode === constants.SESSION_MODE.ONLINE_SESSION
              ? "d-none"
              : "mb3"
          }
        >
          <Form.Label className="formLabelForSession">
            Expiry Time (Mins) <span style={{ color: "red" }}> *</span>
          </Form.Label>
          <Form.Control
            type="number"
            id="addExpiryTimeForSession"
            defaultValue={data.expiryTime}
            onChange={(e) => {
              setData({ ...data, expiryTime: e.target.value });
              // console.log("e.target.value___expiryTime", e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group
          className={
            data.mode === constants.SESSION_MODE.OFFLINE_SESSION
              ? "mb3"
              : "mb3"
          }
        >
          <Form.Label className="formLabelForSession">
            {data.mode === constants.SESSION_MODE.OFFLINE_SESSION ? "Location" :"Session Link"}<span style={{ color: "red" }}> *</span>
          </Form.Label>
          <Form.Control
            type="text"
            id="sessionLink"
            defaultValue={data.sessionLink}
            onChange={(e) => {
              setData({ ...data, sessionLink: e.target.value });
              // console.log("e.target.value___organizedBy", e.target.value);
            }}
          />
        </Form.Group>

        <div className="d-flex ">
          {" "}
          <Button className="btnMedium" variant="primary" type="submit">
            Submit
          </Button>
          <Button
            type="button"
            className="btnMedium"
            variant="secondary"
            onClick={handleCancel}
            style={{ marginLeft: "40px" }}
          >
            Cancel
          </Button>
        </div>
      </Form>

      <Offcanvas
        show={showUser}
        placement="end"
        id="viewUserList"
        onHide={() => setShowUser(false)}
      >
        <Offcanvas.Body>
          <h3 className="selectStudentTitle">Select Students</h3>
          <StudentList
            data={filterdData}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
          />
          <div className="buttonsTwo">
            <Button
              className="btnMedium"
              variant="primary"
              onClick={handleAddStudents}
            >
              Add Students
            </Button>
            <Button
              variant="secondary"
              style={{ marginLeft: "35px" }}
              className="btnMedium"
              onClick={() => setShowUser(false)}
            >
              Cancel
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
