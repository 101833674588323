import moment from "moment";
import { nanoid } from "nanoid";
import constants from "../constants";
import { getPreSignedUrl } from "../util";
import { getDataFromStorage } from "../util";
import useAxiosPrivate from "./useAxiosPrivate";
import AlertContext from "../context/AlertContext";
import { useContext, useMemo, useRef, useState } from "react";
import CodingHeaderHooks from "./CodingPage/CodingHeaderHooks";
import useCommonFunctionHooks from "./useCommonFunctionHooks";

export default function CommentsHooks() {
  const {
    comment,
    testData,
    setComment,
    setIsLoaded,
    commentsData,
    showComments,
    setIsComment,
    setShowNotify,
    setCommentsData,
    setShowComments,
    commentAttachment,
    showBreadCrumbsData,
    setCommentAttachment,
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { getLearnerId } = CodingHeaderHooks();
  
  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const inputRef = useRef(null);

  const [editedComment, setEditedComment] = useState("");
  const [cursorPosition, setCursorPosition] = useState(0);
  const [editingCommentIndex, setEditingCommentIndex] = useState(null);

  const changeComments = (event) => {
    setComment(event.target.value);
  };

  const editComment = (index) => {
    setEditingCommentIndex(index);
    setEditedComment(commentsData[index].comments);
    setCursorPosition(commentsData[index].comments.length);
    focusInput();
  };

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const ignoreEditedComment = () => {
    setCursorPosition(0);
    setEditingCommentIndex(null);
    setEditedComment("");
  };

  const handleCommentAttachment = (event) => {
    let commentedDate = String(new Date());
    let attachments = [...commentAttachment];
    let files = Array.from(event.target.files);

    for (let i = 0; i < files.length; i++) {
      let attachment = { id: nanoid(), file: files[i], date: commentedDate };
      attachments.push(attachment);
    }

    setCommentAttachment(attachments);
    event.target.value = "";
  };

  const handleDeleteCommentAttachment = (attachmentId) => {
    setCommentAttachment((prevAttachment) =>
      prevAttachment.filter((attachment) => attachment.id !== attachmentId)
    );
  };

  const handleCommentChange = (e) => {
    e.preventDefault();

    const { value, selectionStart } = e.target;

    setEditedComment(value);

    // Update the cursor position

    setCursorPosition(selectionStart);
    focusInput();
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    
    setComment("");
    setShowComments(!showComments);
  };

  const attachments = useMemo(() => {
    return testData.libtype === constants.libTypeIds.ASSIGNMENT &&
      commentsData?.commentAttachment &&
      commentsData?.commentAttachment !== "" &&
      commentsData?.commentAttachment !== null &&
      commentsData?.commentAttachment !== undefined
      ? typeof commentsData?.commentAttachment === "string"
        ? JSON.parse(commentsData?.commentAttachment)
        : commentsData?.commentAttachment
      : [];
  }, [commentsData?.commentAttachment, testData.libtype]);

  const handleAddComments = async (event, button) => {
    if (
      button ||
      button === "score" ||
      (event.ctrlKey && event.key === "Enter")
    ) {
      if (comment !== "" && comment !== undefined && comment !== null) {
        try {
          setIsLoaded(true);

          let picType = [];
          let form = new FormData();

          form.append("comments", comment);
          form.append("contentType", picType);
          form.append("lpid", showBreadCrumbsData.lpId);
          form.append("libtype", showBreadCrumbsData.type);
          form.append("learnerid", showBreadCrumbsData.learnerId);
          form.append("creatorid", getDataFromStorage("learnerid"));

          if (showBreadCrumbsData.type === constants.libTypeIds.ASSIGNMENT) {
            form.append("questionid", showBreadCrumbsData.testId);
          } else if (
            showBreadCrumbsData.type === constants.libTypeIds.MCQ ||
            showBreadCrumbsData.type === constants.libTypeIds.DESCRIPTIVE
          ) {
            form.append("testid", showBreadCrumbsData.testId);
          } else {
            form.append("testid", showBreadCrumbsData.testId);
            form.append("questionid", showBreadCrumbsData.questionId);
          }

          if (commentAttachment.length > 0) {
            for (let i = 0; i < commentAttachment.length; i++) {
              form.append(
                commentAttachment[i].file.name,
                commentAttachment[i].file
              );
              picType.push(commentAttachment[i].file.name.replace(/^.*\./, ""));
            }
          }

          let response = await axios({
            data: form,
            method: "post",
            url: "node/admin/test/comment",
            headers: getHeaderDetailsForAxios(),
          });

          if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
            setShowComments(false);

            if (button === "score") {
              setIsComment(true);
              setComment(comment);
              setShowNotify({
                show: true,
                title: "Info",
                msg: "Score added successfully",
              });
            } else {
              setComment("");
              setShowNotify({
                show: true,
                title: "Info",
                msg: "Comment added successfully",
              });
            }

            let getLinkCount = 0;

            for (let i = 0; i < commentAttachment.length; i++) {
              if (process.env.REACT_APP_DEPLOYMENT === "LOCAL")
                commentAttachment[i].link =
                  process.env.REACT_APP_NODE_API +
                  "/library/submission/" +
                  getLearnerId() +
                  "/" +
                  commentAttachment[i].file.name;
              else {
                let res = await getPreSignedUrl(
                  "/library/submission/" +
                    getLearnerId() +
                    "/" +
                    commentAttachment[i].file.name
                );

                commentAttachment[i].link = res;
              }

              getLinkCount++;
            }

            if (getLinkCount === commentAttachment.length) {
              commentsData.push({
                comments: comment,
                date: String(new Date()),
                id: response.data.data.insertId,
                commentAttachment: commentAttachment,
                createdby: getDataFromStorage("name"),
                creatorid: getDataFromStorage("learnerid"),
              });

              setCommentAttachment([]);
            }
          }
        } catch (error) {
          getCatchBlockDetails(error);
        } finally {
          setIsLoaded(false);
        }
      } else {
        setShowNotify({
          show: true,
          title: "Warning",
          msg: "Please enter the comments",
        });
      }
    }
  };

  const saveEditedComment = async () => {
    const updatedComments = [...commentsData];

    updatedComments[editingCommentIndex].comments = editedComment;
    updatedComments[editingCommentIndex].date = moment().format(
      "YYYY-MM-DD HH:mm:ss"
    );

    // Perform any necessary API call to update the comment on the backend

    let picType = [];
    let form = new FormData();

    form.append("isupdate", true);
    form.append("contentType", picType);
    form.append("lpid", showBreadCrumbsData.lpId);
    form.append("libtype", showBreadCrumbsData.type);
    form.append("learnerid", showBreadCrumbsData.learnerId);
    form.append("creatorid", getDataFromStorage("learnerid"));
    form.append("id", updatedComments[editingCommentIndex].id);
    form.append("comments", updatedComments[editingCommentIndex].comments);

    if (showBreadCrumbsData.type === constants.libTypeIds.ASSIGNMENT) {
      // form.append("questionid", props.libId);
    } else if (
      showBreadCrumbsData.type === constants.libTypeIds.MCQ ||
      showBreadCrumbsData.type === constants.libTypeIds.DESCRIPTIVE
    ) {
      form.append("testid", showBreadCrumbsData.testId);
    } else {
      form.append("testid", showBreadCrumbsData.testId);
      form.append("questionid", showBreadCrumbsData.questionId);
    }

    if (commentAttachment.length > 0) {
      for (let i = 0; i < commentAttachment.length; i++) {
        form.append(commentAttachment[i].file.name, commentAttachment[i].file);
        picType.push(commentAttachment[i].file.name.replace(/^.*\./, ""));
      }
    }

    await axios({
      data: form,
      method: "post",
      url: "node/admin/test/comment",
      headers: {
        "Content-type": "multipart/form-data",
      },
    });

    // Clear editing state and setcursor position

    setCursorPosition(0);
    setEditedComment("");
    setEditingCommentIndex(null);
  };

  const deleteComment = async (index) => {
    let temp = [...commentsData];

    temp.splice(index, 1);
    setCommentsData(temp);

    let picType = [];
    let form = new FormData();

    form.append("isdelete", true);
    form.append("contentType", picType);
    form.append("id", commentsData[index].id);
    form.append("libtype", showBreadCrumbsData.type);
    form.append("learnerid", showBreadCrumbsData.learnerId);
    form.append("creatorid", getDataFromStorage("learnerid"));

    if (showBreadCrumbsData.type === constants.libTypeIds.ASSIGNMENT) {
      // form.append("questionid", props.libId);
    } else if (
      showBreadCrumbsData.type === constants.libTypeIds.MCQ ||
      showBreadCrumbsData.type === constants.libTypeIds.DESCRIPTIVE
    ) {
      form.append("testid", showBreadCrumbsData.testId);
    } else {
      form.append("testid", showBreadCrumbsData.testId);
      form.append("questionid", showBreadCrumbsData.questionId);
    }

    if (commentAttachment.length > 0) {
      for (let i = 0; i < commentAttachment.length; i++) {
        form.append(commentAttachment[i].file.name, commentAttachment[i].file);
        picType.push(commentAttachment[i].file.name.replace(/^.*\./, ""));
      }
    }

    await axios({
      data: form,
      method: "post",
      url: "node/admin/test/comment",
      headers: {
        "Content-type": "multipart/form-data",
      },
    });

    setEditedComment("");
    setEditingCommentIndex(null);
  };

  return {
    inputRef,
    focusInput,
    editComment,
    attachments,
    toggleDrawer,
    deleteComment,
    editedComment,
    cursorPosition,
    changeComments,
    setEditedComment,
    saveEditedComment,
    handleAddComments,
    editingCommentIndex,
    handleCommentChange,
    ignoreEditedComment,
    setEditingCommentIndex,
    handleCommentAttachment,
    handleDeleteCommentAttachment,
  };
}
