import React, { useContext, useEffect, useState } from "react";
import { TextField, FormHelperText } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Button from "../../Button";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import { getDataFromStorage } from "../../../util";
import FormTitle from "./FormTitle";
import InputGroupField from "../../CommonComponents/InputGroupField";
import { ProfileContext } from "../../../context/ProfileContext";
import constants from "../../../constants";
import AlertContext from "../../../context/AlertContext";
import ProfileUpload from "./ProfileUpload";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const PersonalInfoForm = ({ role, from, learnerId }) => {
  const axios = useAxiosPrivate();
  const {
    profileDetails,
    setProfileDetails,
    profilePic,
    setProfilePic,
    onSaveProfileDetails,
    navigate,
  } = useContext(ProfileContext);
  const { setIsLoaded, setShowNotify } = useContext(AlertContext);

  const [focusStates, setFocusStates] = useState(() =>
    Object.keys(profileDetails.personalinfo).reduce(
      (acc, key) => ({ ...acc, [key]: false }),
      {}
    )
  );

  useEffect(() => {
    // const abortController = new AbortController();
    const handleGetPersonalInfo = async () => {
      try {
        setIsLoaded(true);
        let id = getDataFromStorage("learnerid");
        if (from === "onpopup") {
          id = learnerId;
        }
        let response = await axios.get(
          `node/learner/profile/${id}/personalinfo`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            // signal: abortController.signal,
          }
        );

        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          let details = response.data.data.result;
          if (details && details !== null) {
            setProfileDetails((prev) => ({
              ...prev,
              personalinfo: JSON.parse(details),
            }));
          }
          if (from === "onpopup") {
            setProfilePic(response.data.data.picture);
          } else {
            setProfilePic(
              response.data.data.picture
                ? response.data.data.picture
                : localStorage.getItem("picture")
            );
          }
        } else
          setShowNotify({
            show: true,
            msg: response.data.msg,
            title: "Warning !",
          });
      } catch (error) {
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else 
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later or contact gradious team.",
        });
      } finally {
        setIsLoaded(false);
      }
    };
    handleGetPersonalInfo();
    // return () => abortController.abort();
  }, [
    axios,
    setIsLoaded,
    setShowNotify,
    from,
    learnerId,
    setProfileDetails,
    setProfilePic,
  ]);

  const handleResponse = (title, msg) => {
    setShowNotify({
      show: true,
      title,
      msg,
    });
  };

  const handleFieldFocus = (name) => {
    setFocusStates({ ...focusStates, [name]: true });
  };

  const handleFieldBlur = (name) => {
    setFocusStates({ ...focusStates, [name]: false });
  };

  const onBlurEmailField = (event) => {
    // console.log(emailRegex.test(event.target.value))
    // if (!emailRegex.test(event.target.value)) {
    //   setShowNotify({
    //     show: true,
    //     msg: "The Email Address is not valid",
    //     title: "Warning !",
    //   });
    // }
  };

  const handleNext = async () => {
    onSaveProfileDetails();
    if (getDataFromStorage("role") === constants.Roles.learner)
      navigate("/profile/parentinfo");
    else navigate("/profile/socialprofile");
  };

  return (
    <React.Fragment>
      <FormTitle
        icon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 21C20 19.6044 20 18.9067 19.8278 18.3389C19.44 17.0605 18.4395 16.06 17.1611 15.6722C16.5933 15.5 15.8956 15.5 14.5 15.5H9.5C8.10444 15.5 7.40665 15.5 6.83886 15.6722C5.56045 16.06 4.56004 17.0605 4.17224 18.3389C4 18.9067 4 19.6044 4 21M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z"
              stroke="#101828"
              strokeWidth="1.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        title="Personal Info"
      />
      <form className="personalInfoForm">
        <div className="personalInfoLeftForm">
          <div className="personalInfoNameSec">
            <TextField
              disabled={from === "onpopup"}
              label="First Name"
              name="firstName"
              id="personalInfoFName"
              fullWidth
              InputLabelProps={{
                shrink:
                  focusStates["firstName"] ||
                  Boolean(profileDetails.personalinfo["firstName"]),
              }}
              variant="outlined"
              value={profileDetails.personalinfo.firstName}
              InputProps={{
                onFocus: (event) => handleFieldFocus(event.target.name),
                onBlur: (event) => handleFieldBlur(event.target.name),
              }}
              onChange={(event) =>
                setProfileDetails((prev) => ({
                  ...prev,
                  personalinfo: {
                    ...prev.personalinfo,
                    [event.target.name]: event.target.value,
                  },
                }))
              }
              size="small"
            />
            <TextField
              disabled={from === "onpopup"}
              label="Last Name"
              name="lastName"
              id="personalInfoLName"
              variant="outlined"
              InputLabelProps={{
                shrink:
                  focusStates["lastName"] ||
                  Boolean(profileDetails.personalinfo["lastName"]),
              }}
              fullWidth
              value={profileDetails.personalinfo.lastName}
              InputProps={{
                onFocus: (event) => handleFieldFocus(event.target.name),
                onBlur: (event) => handleFieldBlur(event.target.name),
              }}
              onChange={(event) =>
                setProfileDetails((prev) => ({
                  ...prev,
                  personalinfo: {
                    ...prev.personalinfo,
                    [event.target.name]: event.target.value,
                  },
                }))
              }
              size="small"
            />
          </div>
          <TextField
            disabled={from === "onpopup"}
            label="Email Address"
            name="email"
            id="personalInfoEmail"
            value={profileDetails.personalinfo.email}
            InputLabelProps={{
              shrink:
                focusStates["email"] ||
                Boolean(profileDetails.personalinfo["email"]),
            }}
            inputProps={{
              onFocus: (event) => handleFieldFocus(event.target.name),
              onBlur: (event) => {
                onBlurEmailField(event);
                handleFieldBlur(event.target.name);
              },
              type: "email",
            }}
            fullWidth
            onChange={(event) =>
              setProfileDetails((prev) => ({
                ...prev,
                personalinfo: {
                  ...prev.personalinfo,
                  [event.target.name]: event.target.value,
                },
              }))
            }
            variant="outlined"
            size="small"
          />

          <div className="personalInfoPhoneDOBSec">
            <div style={{ width: "47%" }}>
              {/* <InputGroupField
                disabled={from === "onpopup"}
                groupText="+91"
                placeholder="Phone Number"
                name="phone"
                value={profileDetails.personalinfo.phone}
                onChange={(event) =>
                  setProfileDetails((prev) => ({
                    ...prev,
                    personalinfo: {
                      ...prev.personalinfo,
                      [event.target.name]: event.target.value,
                    },
                  }))
                }
              /> */}

              <PhoneInput
                placeholder="Enter Number"
                onlyCountries={['us', 'in']}
                value={profileDetails.personalinfo.phone}
                onChange={(ph) =>
                  setProfileDetails((prev) => ({
                    ...prev,
                    personalinfo: {
                      ...prev.personalinfo,
                      phone: ph,
                    },
                  }))
                }
              />
            </div>
            {role === constants.Roles.learner && (
              <div className="personalInfoDOBSec">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    disabled={from === "onpopup"}
                    //  disableOpenPicker={from=="onpopup"}
                    label="Date of Birth"
                    id="personalInfoDOB"
                    name="dob"
                    sx={{
                      "& .MuiInputBase-input": {
                        height: "40px",
                        padding: "8px 14px", // Adjust padding as necessary
                      },
                    }}
                    minDate={dayjs("1900-01-01")}
                    maxDate={dayjs()}
                    value={
                      profileDetails.personalinfo.dob
                        ? dayjs(profileDetails.personalinfo.dob)
                        : null
                    }
                    onChange={(newValue) =>
                      setProfileDetails((prev) => ({
                        ...prev,
                        personalinfo: {
                          ...prev.personalinfo,
                          dob: newValue,
                        },
                      }))
                    }
                  />
                </LocalizationProvider>
              </div>
            )}
          </div>
          {role === constants.Roles.learner && (
            <>
              <TextField
                disabled={from === "onpopup"}
                label="Address"
                name="address"
                id="personalInfoAddress"
                variant="outlined"
                InputLabelProps={{
                  shrink:
                    focusStates["address"] ||
                    Boolean(profileDetails.personalinfo["address"]),
                }}
                size="small"
                multiline={true}
                fullWidth
                value={profileDetails.personalinfo.address}
                inputProps={{
                  onFocus: (event) => handleFieldFocus(event.target.name),
                  onBlur: (event) => handleFieldBlur(event.target.name),
                  maxLength: 250,
                }}
                onChange={(event) =>
                  setProfileDetails((prev) => ({
                    ...prev,
                    personalinfo: {
                      ...prev.personalinfo,
                      [event.target.name]: event.target.value,
                    },
                  }))
                }
              />
              <div className="personalInfoIntroSec">
                <InputGroupField
                  disabled={from === "onpopup"}
                  title="Introduction Video"
                  groupText="http://"
                  placeholder="www.gradious.com"
                  name="intro"
                  value={profileDetails.personalinfo.intro}
                  onChange={(event) =>
                    setProfileDetails((prev) => ({
                      ...prev,
                      personalinfo: {
                        ...prev.personalinfo,
                        [event.target.name]: event.target.value,
                      },
                    }))
                  }
                />
              </div>
              <div className="personalInfoCareerObjSec">
                <TextField
                  disabled={from === "onpopup"}
                  id="personalInfoCareerObj"
                  label="Career Objective"
                  name="careerObj"
                  InputLabelProps={{
                    shrink:
                      focusStates["careerObj"] ||
                      Boolean(profileDetails.personalinfo["careerObj"]),
                  }}
                  variant="outlined"
                  size="small"
                  multiline={true}
                  fullWidth
                  value={profileDetails.personalinfo.careerObj}
                  inputProps={{
                    onFocus: (event) => handleFieldFocus(event.target.name),
                    onBlur: (event) => handleFieldBlur(event.target.name),
                    maxLength: 1000,
                  }}
                  onChange={(event) =>
                    setProfileDetails((prev) => ({
                      ...prev,
                      personalinfo: {
                        ...prev.personalinfo,
                        [event.target.name]: event.target.value,
                      },
                    }))
                  }
                />
                <FormHelperText id="personalInfoCareerObjHText">
                  Write a short summary of your career goals.
                </FormHelperText>
              </div>
            </>
          )}
        </div>
        <div className="personalInfoRightForm">
          <ProfileUpload
            from={from}
            profilePic={profilePic}
            setProfilePic={setProfilePic}
            handleResponse={handleResponse}
            buttonText="Upload a new Photo"
            size="800 * 800"
          />
        </div>
      </form>
      {from !== "onpopup" && (
        <div className="personalInfoBtnContainer">
          <div className="personalInfoBtnSec">
            <Button
              onClick={handleNext}
              size="sm"
              hierarchy={{ type: "primary", buttonText: "Next" }}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PersonalInfoForm;
