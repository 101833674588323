import Button from "../Button";
import constants from "../../constants";
import React, { useContext } from "react";
import ProfileAvatar from "./ProfileAvatar";
import { useLocation } from "react-router-dom";
import { getDataFromStorage } from "../../util";
import HeaderHooks from "../../Hooks/HeaderHooks";
import Overlay from "../CommonComponents/Overlay";
import ReportHooks from "../../Hooks/ReportHooks";
import AlertContext from "../../context/AlertContext";
import CodingHeader from "../Student/CodingPage/CodingHeader";
import HeaderRightNavSvgIcon from "../../Svg/HeaderRightNavSvgIcon";
import CodingPageLearnerSideBreadCrumbs from "../../Hooks/CodingPage/CodingPageLearnerSideBreadCrumbs";
import CompanyLogo from "./CompanyLogo";

export default function Header(Props) {
  const { isAdmin, navigate } = useContext(AlertContext);

  const { hideCompanyName } = HeaderHooks();
  const { capitalizeFirstLetterOfEachWord } = ReportHooks();

  const { pathname } = useLocation();

  return (
    <div
      className={
        pathname === "/registration"
          ? "headerDivForRegistration"
          : pathname === "/sandpack"
          ? "headerDivSandPack"
          : Props.component === "leftNav"
          ? "headerDivLeftNav"
          : "headerDiv"
      }
    >
      <div className="headerDivLogo">
        {Props.component === "leftNav" && !Props.collapsed ? (
          ""
        ) : (
          <div className="headerDivLogoInnerDiv">
            <Overlay
              component={<CompanyLogo hideCompanyName={hideCompanyName()} />}
              title={
                isAdmin && (
                  <div>
                    Batch Name :{" "}
                    {capitalizeFirstLetterOfEachWord(
                      localStorage.getItem("reportLpName")
                    )}
                    <div>
                      LearningPath Name :{" "}
                      {capitalizeFirstLetterOfEachWord(
                        localStorage.getItem("reportBatchName")
                      )}
                    </div>
                  </div>
                )
              }
            />
            {hideCompanyName() && <CodingPageLearnerSideBreadCrumbs />}
          </div>
        )}
      </div>

      {pathname === "/coding" ||
      pathname === "/mcq" ||
      pathname === "/assignment" ? (
        <CodingHeader />
      ) : (
        pathname !== "/sandpack" && (
          <div className="headerDivBadges">
            {(pathname === "/landingpage" && getDataFromStorage("role")===constants.Roles.learner) && (
              <Button
                size={"sm"}
                onClick={() => navigate("/mock/library")}
                hierarchy={{
                  type: "secondaryGrey",
                  buttonText: "Mock test",
                }}
              />
            )}
            {getDataFromStorage("role") === constants.Roles.learner&&
              localStorage.getItem("isTestBatch") !== "true" && <HeaderRightNavSvgIcon />}
            <ProfileAvatar />
          </div>
        )
      )}
    </div>
  );
}
