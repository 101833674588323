import moment from "moment";
import Button from "./Button";
import Box from "@mui/material/Box";
import constants from "../constants";
import InfoSvg from "../Svg/InfoSvg";
import DatePicker from "react-datepicker";
import { Typography } from "@mui/material";
import Popover from "@mui/material/Popover";
// import Checkbox from "@mui/material/Checkbox";
import RejectHooks from "../Hooks/RejectHooks";
import CloseSvgIcon from "../Svg/CloseSvgIcon";
import FormGroup from "@mui/material/FormGroup";
import AlertContext from "../context/AlertContext";
import CommentsHooks from "../Hooks/CommentsHooks";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useContext, useEffect, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Radio from "./CommonComponents/Radio";
import Checkbox from "./CommonComponents/Checkbox";
import ImageLogoSvgIcon from "../Svg/ImageLogoSvgIcon";
import PinSvgIcon from "../Svg/PinSvgIcon";

export default function Reject() {
  const {
    comment,
    rejectData,
    setIsLoaded,
    setShowReject,
    commentAttachment,
    showBreadCrumbsData,
  } = useContext(AlertContext);

  const {
    newTime,
    getTime,
    rejectOption,
    setGivenTime,
    minExtendDate,
    rejectConfirm,
    extensionDate,
    getDueDateLogic,
    setRejectConfirm,
    minExtendDateValue,
    rejectOptionChange,
    extensionDateChange,
    handleResubmissionReject,
  } = RejectHooks();

  const {
    changeComments,
    handleCommentAttachment,
    handleDeleteCommentAttachment,
  } = CommentsHooks();

  let [conf, setconf] = useState("");
  let [mapcopy, setmapcopy] = useState(null);
  let [canretake, setcanretake] = useState(false);
  let [extension, setExtension] = useState(false);

  useEffect(() => {
    if (minExtendDateValue) {
      getDueDateLogic();
    }
  }, [minExtendDateValue]);

  useEffect(() => {
    let canretake = true;

    const map1 = new Map();

    let str = "";

    if (
      showBreadCrumbsData.type === constants.libTypeIds.MCQ ||
      showBreadCrumbsData.type === constants.libTypeIds.SAQ ||
      showBreadCrumbsData.type === constants.libTypeIds.LAQ ||
      showBreadCrumbsData.type === constants.libTypeIds.MCQ_MULTI ||
      showBreadCrumbsData.type === constants.libTypeIds.MCQ_SINGLE ||
      showBreadCrumbsData.type === constants.libTypeIds.DESCRIPTIVE
    ) {
      for (let i = 0; i < rejectData.remaingQnCount.length; i++) {
        if (
          map1.has(
            rejectData.remaingQnCount[i].tname +
              rejectData.remaingQnCount[i].complexityid
          )
        ) {
          let temp = map1.get(
            rejectData.remaingQnCount[i].tname +
              rejectData.remaingQnCount[i].complexityid,
            {
              count: rejectData.remaingQnCount[i].qnCount,
              avaliable: rejectData.remaingQnCount[i].qnAvailable,
            }
          );

          temp.count = temp.count + rejectData.remaingQnCount[i].qnCount;

          if (temp.avaliable >= temp.count) {
            temp.comment = "yes";
          } else {
            temp.comment = "no";
          }

          map1.set(
            rejectData.remaingQnCount[i].cname +
              rejectData.remaingQnCount[i].complexityid,
            temp
          );
        } else {
          let comm;

          if (
            rejectData.remaingQnCount[i].qnAvailable >=
            rejectData.remaingQnCount[i].qnCount
          ) {
            comm = "yes";
          } else {
            comm = "no";
          }

          map1.set(
            rejectData.remaingQnCount[i].tname +
              rejectData.remaingQnCount[i].cname,
            {
              comment: comm,
              topic: rejectData.remaingQnCount[i].tname,
              count: rejectData.remaingQnCount[i].qnCount,
              complexity: rejectData.remaingQnCount[i].cname,
              avaliable: rejectData.remaingQnCount[i].qnAvailable,
            }
          );
        }
      }

      for (const [, value] of map1) {
        if (value.comment === "yes") {
          canretake = true;
        } else {
          canretake = false;
        }
      }
    }

    if (showBreadCrumbsData.type === constants.libTypeIds.CODING_CHALLENGE) {
      map1.set(rejectData.topicName + rejectData.complexityid, {
        count: 1,
        topic: rejectData.topicName,
        avaliable: rejectData.remainingQnLibrary,
        comment: rejectData.remainingQnLibrary > 0 ? "yes" : "no",
        complexity:
          rejectData.complexityid === 1
            ? "Easy"
            : rejectData.complexityid === 2
            ? "Medium"
            : "Difficult",
      });

      if (rejectData.isFixedQn === 1) {
        canretake = true;
      } else if (
        rejectData.isFixedQn === 0 &&
        rejectData.remainingQnLibrary > 0
      ) {
        canretake = true;
      } else {
        canretake = false;
      }
    }

    setmapcopy(map1);
    setconf(str);
    setcanretake(canretake);
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  return (
    <div className="rejectMainContainer">
      <Box
        sx={{
          p: 4,
          boxShadow: 2,
          height: "85vh",
          borderRadius: 3,
          bgcolor: "#FFFFFF",
          overflow: "scroll",
          width:
            showBreadCrumbsData.type === constants.libTypeIds.ASSIGNMENT
              ? "40vw"
              : "60vw",
        }}
      >
        {showBreadCrumbsData.type === constants.libTypeIds.ASSIGNMENT && (
          <>
            <div className="rejectHeaderForAssignment">
              <InfoSvg component="rejectForAssignment" />
              <div className="rejectInstructionForAssignment">
                If the assignment is rejected by you, the learner can resubmit
                this assignment before the due date.
                <p>
                  You can also add a comment stating the reason for rejection
                  and resubmission.
                </p>
              </div>
            </div>

            <div className="rejectCommentsForAssignment">
              <div className="rejectCommentsForAssignmentTextAreaDiv">
                <TextareaAutosize
                  minRows={7}
                  input={comment}
                  value={comment}
                  id={"inputs" + 1}
                  key={"inputs" + 1}
                  onChange={changeComments}
                  className="rejectCommentsForAssignmentTextArea"
                  placeholder="Enter your comment for the trainee."
                />
                {showBreadCrumbsData.type === constants.libTypeIds.ASSIGNMENT &&
                  commentAttachment.map((attachment) => (
                    <div
                      key={attachment.id}
                      className="rejectCommentsForAssignmentFileUpload"
                    >
                      <div className="rejectCommentsForAssignmentFileUploadInnerDiv">
                        <ImageLogoSvgIcon />
                        <div className="rejectCommentsForAssignmentFileUploadFileName">
                          {attachment.file.name}
                        </div>

                        <div className="rejectCommentsForAssignmentFileUploadFileSize">
                          {attachment.hasOwnProperty("file") &&
                          attachment.file.size !== undefined &&
                          (attachment.file.size / 1e6).toFixed(2) > 1
                            ? `${(attachment.file.size / 1e6).toFixed(2)} MB`
                            : `${(attachment.file.size / 1024).toFixed(2)} KB`}
                        </div>
                      </div>
                      <div className="rejectCommentsForAssignmentCloseSvgIcon">
                        <CloseSvgIcon
                          onClick={() =>
                            handleDeleteCommentAttachment(attachment.id)
                          }
                        />
                      </div>
                    </div>
                  ))}

                {showBreadCrumbsData.type ===
                  constants.libTypeIds.ASSIGNMENT && (
                  <div className="rejectCommentsForAssignmentAttachmentDiv">
                    <PinSvgIcon />
                    <input
                      multiple
                      type="file"
                      accept="image/*"
                      onChange={handleCommentAttachment}
                      className="rejectCommentsForAssignmentAttachmentInput"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="rejectCommentsForAssignmentFooter">
              <div className="rejectCommentsForAssignmentFooterCheckBoxDiv">
                <Checkbox
                  checked={extension}
                  onChange={() => setExtension(!extension)}
                />
                <span className="rejectCommentsForAssignmentFooterCheckBoxLabel">
                  Extend deadline
                </span>
              </div>
              <div className="rejectTestExtendDateDivLabel">
                Set extended deadline:
              </div>
              <DatePicker
                showIcon
                showTimeInput
                disabled={!extension}
                timeInputLabel="Time:"
                className="rejectTestDatePicker"
                dateFormat="dd/MMM/yyyy h:mm aa"
                minDate={new Date(minExtendDate)}
                selected={new Date(extensionDate)}
                onChange={(date) => extensionDateChange(date)}
              />
            </div>
            <div className="rejectTestFooterButtonDiv">
              <Button
                size={"sm"}
                onClick={() => {
                  setIsLoaded(false);
                  setShowReject(false);
                }}
                hierarchy={{
                  buttonText: "Cancel",
                  type: "secondaryGrey",
                }}
              />

              <Button
                size={"sm"}
                destructive={true}
                disable={!extension}
                onClick={handleResubmissionReject}
                hierarchy={{
                  type: "primary",
                  buttonText: "Reject",
                }}
              />
            </div>
          </>
        )}

        {showBreadCrumbsData.type !== constants.libTypeIds.ASSIGNMENT && (
          <div className="rejectTestMainContainer">
            <div className="rejectTestMainContainerInnerDiv">
              <table>
                <th colSpan={2}>Timing Summary</th>
                <tr>
                  <td className="rejectTestTableLabel">Due Date </td>
                  <td className="rejectTestTableValue">
                    {moment(showBreadCrumbsData.learnerDueDate).format(
                      "MMMM Do YYYY, h:mm a"
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="rejectTestTableLabel">Test Duration </td>
                  <td className="rejectTestTableValue">
                    {getTime("duration")}
                  </td>
                </tr>
                <tr>
                  <td className="rejectTestTableLabel">Time Left</td>
                  <td className="rejectTestTableValue">{getTime()}</td>
                </tr>
                {showBreadCrumbsData.type ===
                  constants.libTypeIds.CODING_CHALLENGE && (
                  <tr>
                    <td className="rejectTestTableLabel">No.of Retakes </td>
                    <td className="rejectTestTableValue">
                      {rejectData.replacedQnTimes}
                    </td>
                  </tr>
                )}
              </table>
            </div>
            <div className="rejectTestInnerDiv">
              <div className="rejectTestOptionDiv">
                <div className="rejectTestOptionInnerDiv">
                  <div className="rejectTestOptionInnerDivHeader">
                    Reject or Extend
                  </div>
                  <div className="rejectTestOptionLabel">
                    <Radio
                      size="sm"
                      text="Reject and score NIL"
                      value="scrnil"
                      checked={rejectOption === "scrnil"}
                      onChange={(value) => rejectOptionChange(value)}
                    />
                    {/* <input
                      type="radio"
                      value="scrnil"
                      name="rejectopt"
                      className="rejectTextRadioOption"
                      checked={rejectOption === "scrnil"}
                      onChange={(e) => rejectOptionChange(e)}
                    />
                    Reject and score NIL */}
                  </div>
                  <div className="rejectTestOptionLabelForGivenTime">
                    <Radio
                      size="sm"
                      value="givetime"
                      text="Extend with given time"
                      checked={rejectOption === "givetime"}
                      onChange={(value) => rejectOptionChange(value)}
                    />
                    {/* <input
                      type="radio"
                      value="givetime"
                      name="rejectopt"
                      className="rejectTextRadioOption"
                      checked={rejectOption === "givetime"}
                      onChange={(e) => rejectOptionChange(e)}
                    />
                    Extend with given time &nbsp; */}
                    <input
                      type="number"
                      value={newTime}
                      onChange={(e) => setGivenTime(e)}
                      className="rejectTestWithGivenTime"
                      disabled={rejectOption !== "givetime"}
                    />{" "}
                    minutes
                  </div>
                  {showBreadCrumbsData.type !==
                    constants.libTypeIds.ASSIGNMENT && (
                    <>
                      <div className="rejectTestOptionLabel">
                        <Radio
                          size="sm"
                          value="extsam"
                          text="Extend with remaining time"
                          checked={rejectOption === "extsam"}
                          disabled={
                            rejectData.timeleft === 0 ||
                            rejectData.timeleft == null
                          }
                          onChange={(value) => rejectOptionChange(value)}
                        />
                        {/* <input
                          type="radio"
                          value="extsam"
                          name="rejectopt"
                          className="rejectTextRadioOption"
                          checked={rejectOption === "extsam"}
                          onChange={(e) => rejectOptionChange(e)}
                          disabled={
                            rejectData.timeleft === 0 ||
                            rejectData.timeleft == null
                          }
                        />
                        Extend with remaining time{" "} */}
                        <span className="rejectTestWithRemainingTime">
                          {rejectData.timeleft == null
                            ? "( 00 )"
                            : "( " +
                              Math.trunc(rejectData.timeleft / 60) +
                              " )" +
                              " mins (" +
                              (rejectData.timeleft % 60) +
                              ") s"}
                        </span>
                      </div>

                      {canretake && (
                        <div className="rejectTestOptionLabel">
                          <Radio
                            size="sm"
                            text={
                              showBreadCrumbsData.type ===
                                constants.libTypeIds.CODING_CHALLENGE &&
                              rejectData.isFixedQn === 1
                                ? "Retake same question ( fixed question )"
                                : "Retake with new questions"
                            }
                            value="restart"
                            checked={rejectOption === "restart"}
                            disabled={!canretake}
                            onChange={(value) => rejectOptionChange(value)}
                          />
                          {/* <input
                            type="radio"
                            value="restart"
                            name="rejectopt"
                            disabled={!canretake}
                            className="rejectTextRadioOption"
                            checked={rejectOption === "restart"}
                            onChange={(e) => rejectOptionChange(e)}
                          /> */}
                          {/* {showBreadCrumbsData.type ===
                            constants.libTypeIds.CODING_CHALLENGE &&
                          rejectData.isFixedQn === 1
                            ? "Retake same question ( fixed question )"
                            : "Retake with new questions"} */}
                          <>
                            <InfoSvg component="reject" onClick={handleClick} />
                            <Popover
                              id={id}
                              open={open}
                              anchorEl={anchorEl}
                              disableRestoreFocus
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <table className="popup-table">
                                <tr>
                                  <th>Topic</th>
                                  <th>Complexity</th>
                                  <th>Required</th>
                                  <th>Avaliable</th>
                                </tr>
                                {mapcopy !== null &&
                                  [...mapcopy.keys()].map((key) => (
                                    <tr>
                                      <td>{mapcopy.get(key).topic}</td>
                                      <td>{mapcopy.get(key).complexity}</td>
                                      <td>{mapcopy.get(key).count}</td>
                                      <td>
                                        {" "}
                                        <span
                                          className={mapcopy.get(key).comment}
                                        >
                                          {mapcopy.get(key).avaliable}
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                              </table>
                            </Popover>
                          </>
                        </div>
                      )}

                      {!canretake && (
                        <div className="rejectTestOptionLabel">
                          <Radio
                            size="sm"
                            text={
                              showBreadCrumbsData.type ===
                                constants.libTypeIds.CODING_CHALLENGE &&
                              rejectData.isFixedQn === 1
                                ? "Retake same question ( fixed question )"
                                : "Retake with new questions"
                            }
                            value="restart"
                            checked={rejectOption === "restart"}
                            disabled={!canretake}
                            onChange={(value) => rejectOptionChange(value)}
                          />
                          {/* <input
                            type="radio"
                            value="restart"
                            name="rejectopt"
                            disabled={!canretake}
                            className="rejectTextRadioOption"
                            onChange={(e) => rejectOptionChange(e)}
                          />
                          {showBreadCrumbsData.type ===
                            constants.libTypeIds.CODING_CHALLENGE &&
                          rejectData.isFixedQn === 1
                            ? "Retake same question ( fixed question )"
                            : "Retake with new questions"} */}
                          {conf === "" && (
                            <>
                              {" "}
                              <ErrorOutlineIcon
                                onClick={handleClick}
                                onMouseEnter={handleClick}
                              />
                              <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                              >
                                <table className="popup-table">
                                  <tr>
                                    <th>Topic</th>
                                    <th>Complexity</th>
                                    <th>Required</th>
                                    <th>Avaliable</th>
                                  </tr>
                                  {mapcopy !== null &&
                                    [...mapcopy.keys()].map((key) => (
                                      <tr>
                                        <td>{mapcopy.get(key).topic}</td>
                                        <td>{mapcopy.get(key).complexity}</td>
                                        <td>{mapcopy.get(key).count}</td>
                                        <td>
                                          {" "}
                                          <span
                                            className={mapcopy.get(key).comment}
                                          >
                                            {mapcopy.get(key).avaliable}
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                </table>
                              </Popover>
                            </>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
                {showBreadCrumbsData.type ===
                  constants.libTypeIds.ASSIGNMENT && (
                  <div className="rejectTestOptionLabel">
                    <Radio
                      size="sm"
                      text="Extend Duedate"
                      value="extend"
                      checked={rejectOption === "extend"}
                      onChange={(value) => rejectOptionChange(value)}
                    />
                    {/* <input
                      type="radio"
                      value="extend"
                      name="rejectopt"
                      className="rejectTextRadioOption"
                      checked={rejectOption === "extend"}
                      onChange={(e) => rejectOptionChange(e)}
                    />
                    &nbsp;Extend Duedate */}
                  </div>
                )}
              </div>

              <div className="rejectTestExtendDateDiv">
                <div className="rejectTestExtendDateDivLabel">
                  Select extended Deadline
                </div>
                <div className="comments-score-section-score-reject-box-score-extend-datetime-des-mcq">
                  <div className="codingDateLabel">
                    <div className="rejectTestDatePickerDiv">
                      <DatePicker
                        showIcon
                        showTimeInput
                        timeInputLabel="Time:"
                        className="rejectTestDatePicker"
                        dateFormat="dd/MMM/yyyy h:mm aa"
                        minDate={new Date(minExtendDate)}
                        selected={new Date(extensionDate)}
                        onChange={(date) => extensionDateChange(date)}
                        disabled={
                          (rejectOption === "" ||
                            rejectOption === "scrnil" ||
                            rejectOption !== "extend") &&
                          rejectOption !== "restart" &&
                          rejectOption !== "extsam" &&
                          rejectOption !== "givetime"
                        }
                        placeholderText={
                          rejectOption === "" || rejectOption === "scrnil"
                            ? "Disabled"
                            : "Select date"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="rejectTestFooter">
                <div className="rejectTestFooterConfirmationDiv">
                  <Checkbox
                    size="sm"
                    text="Confirm selection"
                    checked={rejectConfirm}
                    disabled={rejectOption === ""}
                    onChange={() => setRejectConfirm(!rejectConfirm)}
                  />
                  {/* <Checkbox
                    checked={rejectConfirm}
                    disabled={rejectOption === ""}
                    className="rejectTestConfirmationCheckBox"
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={() => setRejectConfirm(!rejectConfirm)}
                  />
                  Confirm selected option */}
                </div>

                <div className="rejectTestFooterButtonDiv">
                  <Button
                    size={"sm"}
                    onClick={() => {
                      setShowReject(false);
                    }}
                    hierarchy={{
                      buttonText: "Cancel",
                      type: "secondaryGrey",
                    }}
                  />
                  <Button
                    size={"sm"}
                    destructive={true}
                    disable={!rejectConfirm}
                    onClick={handleResubmissionReject}
                    hierarchy={{
                      type: "primary",
                      buttonText: "Reject",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Box>
    </div>
  );
}
