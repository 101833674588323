import _ from "lodash";
import { nanoid } from "nanoid";
import constants from "../../constants";
import { makeStyles } from "@mui/styles";
import { useContext, useState } from "react";
import { getDataFromStorage } from "../../util";
import useAxiosPrivate from "../useAxiosPrivate";
import AlertContext from "../../context/AlertContext";
import useCommonFunctionHooks from "../useCommonFunctionHooks";
import Profile from "../../Components/Student/Home/LandingPage/Profile";

export default function CodingPageHooks() {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();
  
  const { handleClose, getHeaderDetailsForAxios, getCatchBlockDetails } =
    useCommonFunctionHooks();

  let templateCodes = JSON.parse(
    JSON.stringify(
      Alert.codeDetails?.hasOwnProperty("templatecode")
        ? Alert.codeDetails.templatecode
        : []
    )
  );

  const [cameraPopup, setCameraPopup] = useState(false);
  const [showingDelay, setShowingDelay] = useState(false);
  const [delayCompleted, setDelayCompleted] = useState(true);
  const [isNextQuestion, setIsNextQuestion] = useState(false);
  const [cameraWarningMsg, setCameraWarningMsg] = useState("");
  const [isFromReportData, setIsFromReportData] = useState(true);
  const [testcaseForAdmin, setTestcaseForAdmin] = useState(true);

  /* comment state */

  const useStyles = makeStyles({
    root: {
      border: 0,
      height: 48,
      color: "white",
      borderRadius: 3,
      padding: "0 30px",
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    },

    select: {
      "&:after": {
        borderBottomColor: "#AEB0B2",
      },
      "& .MuiSvgIcon-root": {
        color: "#AEB0B2",
      },
    },
  });

  const classes = useStyles();

  const videoConstraints = {
    width: 1280,
    height: 690,
    facingMode: "user",
  };

  if (Alert.isAdmin && testcaseForAdmin) {
    let temp = [...Alert.testCasesRef.current];

    for (let i = 0; i < temp.length; i++) {
      if (i === 0) temp[i].isActive = true;
      temp[i].id = nanoid();
    }

    Alert.testCasesRef.current = temp;
    setTestcaseForAdmin(false);
  }

  if (Alert.fromReport && isFromReportData) {
    setIsFromReportData(false);
    Alert.callAllTestCases.current = true;
  }

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    Alert.setShowComments(!Alert.showComments);
  };

  const handleProblemDragFinished = (size) => {
    const [widthPercentage, heightPercentage] = size;
    const parsedWidth = parseFloat(widthPercentage.replace("%", ""));

    Alert.setProblemWindowHeight(parsedWidth);

    const newSize = `${widthPercentage},${heightPercentage}`;

    localStorage.setItem("splitWidthPos", newSize);
  };

  const preventCopyAndCut = (event) => {
    if (
      (event.ctrlKey || event.metaKey) &&
      (event.key === "c" || event.key === "x")
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const ProfileReturn = () => {
    if (
      delayCompleted &&
      Alert.testData.proctored === constants.PROCTORING_MODE.TAB_CAM
    ) {
      setTimeout(() => {
        setDelayCompleted(false);

        return (
          <Profile
            type="verfypic"
            setMsg={Alert.setMsg}
            setShow={Alert.setShow}
            setTitle={Alert.setTitle}
            details={Alert.codeDetails}
            isTakeTest={Alert.isTakeTest}
            setCameraPopup={setCameraPopup}
            isNextQuestion={isNextQuestion}
            camAvailable={Alert.camAvailable}
            questionid={Alert.codeDetails.qnid}
            setCamAvailable={Alert.setCamAvailable}
            setIsNextQuestion={setIsNextQuestion}
            setCameraWarningMsg={setCameraWarningMsg}
            libtypeid={constants.libTypeIds.CODING_CHALLENGE}
          />
        );
      }, 5000);
    } else {
      return (
        <Profile
          type="verfypic"
          setMsg={Alert.setMsg}
          setShow={Alert.setShow}
          setTitle={Alert.setTitle}
          details={Alert.codeDetails}
          isTakeTest={Alert.isTakeTest}
          setCameraPopup={setCameraPopup}
          isNextQuestion={isNextQuestion}
          camAvailable={Alert.camAvailable}
          questionid={Alert.codeDetails.qnid}
          setIsNextQuestion={setIsNextQuestion}
          setCamAvailable={Alert.setCamAvailable}
          setCameraWarningMsg={setCameraWarningMsg}
          libtypeid={constants.libTypeIds.CODING_CHALLENGE}
        />
      );
    }
  };

  const handleAssignNextQnToLearner = async () => {
    handleClose();

    try {
      Alert.setIsLoaded(true);

      let response = await axios.post(
        "node/learner/codingassignment/add",
        JSON.stringify({
          lpid: Alert.codeDetails.lpId,
          topicid: Alert.codeDetails.topics.topicid,
          learnerid: getDataFromStorage("learnerid"),
          testconfigid: Alert.codeDetails.topics.testconfigid,
          completedqncount: Alert.codeDetails.secData.completedcount,
        }),
        getHeaderDetailsForAxios()
      );

      let data = response.data.data;

      if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
        Alert.leaveMeeting();

        if (Alert.codeDetails.secData.testMode === constants.MODE.TEST_MODE) {
          Alert.setIsTakeTest(true);
        }

        let testCases = JSON.parse(data.testcases);

        Alert.testCasesRef.current = testCases;

        for (let i = 0; i < testCases.length; i++) {
          if (i === 0) testCases[i].isActive = true;
          testCases[i].id = nanoid();
        }

        data.testcases = JSON.stringify(testCases);

        if (typeof data.templatecode === "string") {
          data.templatecode = JSON.parse(data.templatecode);
        }

        for (let i = 0; i < data.templatecode.length; i++) {
          data.templatecode[i].userCode = data.templatecode[i].code;
        }

        data.status = 0;
        data.isChangeCode = false;
        data.topics = Alert.codeDetails.topics;
        data.secData = Alert.codeDetails.secData;
        data.lpConfigId = Alert.codeDetails.lpConfigId;
        data.lpId = Alert.assignQnDetailsForLearner.lpId;
        data.testname = Alert.assignQnDetailsForLearner.testname;
        data.secData.testId = Alert.codeDetails.secData.testId;
        data.secData.testMode = Alert.codeDetails.secData.testMode;
        data.secData.proctored = Alert.codeDetails.secData.proctored;
        data.secData.totalCount = Alert.codeDetails.secData.totalCount;
        data.secData.completedcount = Alert.codeDetails.secData.completedcount;
        Alert.setAssignQnDetailsForLearner(data);
      } else {
        Alert.setResultCode(response.data.resultCode);
        Alert.setShowNotify({
          show: true,
          title: "Info",
          msg: response.data.msg,
        });
      }
    } catch (err) {
      Alert.codeDetails.secData.completedcount =
        Alert.codeDetails.secData.completedcount - 1;

      getCatchBlockDetails(err);
    } finally {
      Alert.setIsLoaded(false);
    }
  };

  return {
    classes,
    useStyles,
    cameraPopup,
    showingDelay,
    toggleDrawer,
    templateCodes,
    ProfileReturn,
    setCameraPopup,
    isNextQuestion,
    setShowingDelay,
    cameraWarningMsg,
    videoConstraints,
    setIsNextQuestion,
    handleContextMenu,
    preventCopyAndCut,
    setCameraWarningMsg,
    handleProblemDragFinished,
    handleAssignNextQnToLearner,
  };
}
