import { useContext } from "react";
import constants from "../../../constants";
import { getDataFromStorage } from "../../../util";
import useAxiosPrivate from "../../useAxiosPrivate";
import AlertContext from "../../../context/AlertContext";
import useCommonFunctionHooks from "../../useCommonFunctionHooks";
import { MockTestPageContext } from "../../../context/MockTestPageContext";

export default function MockTestLibraryHooks() {
  const Alert = useContext(AlertContext);

  const {
    lpId,
    navigate,
    setStatus,
    setIsLoaded,
    setTestData,
    setShowInstruction,
  } = useContext(AlertContext);

  const MockTestPageCxt = useContext(MockTestPageContext);

  const axios = useAxiosPrivate();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const handleSearch = () => {
    MockTestPageCxt.setSearchMockTest(
      MockTestPageCxt.mockTest.filter((test) =>
        test.name
          .toLowerCase()
          .includes(MockTestPageCxt.searchText.toLowerCase())
      )
    );
  };

  const getTime = (seconds) => {
    const min = Math.floor(seconds / 60) % 60;
    const hrs = Math.floor(seconds / (60 * 60));

    if (hrs !== 0) {
      return " " + hrs + " hrs " + (min !== 0 ? min + " mins" : "");
    } else {
      return " " + (min !== 0 ? min + " mins" : "0 mins");
    }
  };

  const handleTest = (test) => {
    if (test.hasOwnProperty("toc")) {
      Alert.setLpId(test.id);
      localStorage.setItem("mockLp", true);
      localStorage.setItem("lpId", test.id);
      Alert.navigate("home");
    } else {
      let testData = {
        mockuse: true,
        testid: test.id,
        testname: test.name,
        testmode: test.mode,
        testtype: test.libtype,
        viewtest: test.viewtest,
        status: constants.PROGRESS_STATUS.UNLOCKED,
        proctored: constants.PROCTORING_MODE.TAB_CAM,
        instruction: test.instruction !== "" ? test.instruction : null,
      };

      setStatus(testData.status);

      if (test.libtype === constants.libTypeIds.CODING_CHALLENGE) {
        testData.lmpid =
          test.status === constants.PROGRESS_STATUS.EVALUATED
            ? null
            : test.lmpid;
        testData.lmtid =
          test.status === constants.PROGRESS_STATUS.EVALUATED
            ? null
            : test.lmtid;

        handleCodingInfo(testData);
      } else {
        testData.autoeval = test.autoeval === 1;
        testData.configdata = {
          marks: test.marks,
          duration: test.testduration,
          questionscount: test.questionscount,
        };

        setTestData(testData);
        setShowInstruction(true);
        navigate("/instruction");
      }
    }
  };

  const handleCodingInfo = (test) => {
    setIsLoaded(true);

    axios
      .post(
        "node/learner/course/test/config",
        JSON.stringify({
          lpid: lpId,
          mockuse: true,
          lmtid: test.lmtid,
          lmpid: test.lmpid,
          testid: test.testid,
          learnerid: getDataFromStorage("learnerid"),
        }),
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        test.data = res.data.data;
        setShowInstruction(false);
        setTestData(test);
        navigate("/instruction");
      })
      .catch((error) => {
        getCatchBlockDetails(error);
      })
      .finally(() => setIsLoaded(false));
  };

  return {
    getTime,
    handleTest,
    handleSearch,
    handleCodingInfo,
  };
}
