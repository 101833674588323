import { nanoid } from "nanoid";
import Button from "../../Button.js";
import ReactQuill from "react-quill";
import Editor from "../../Editor/Content.js";
import constants from "../../../constants.js";
import AlertContext from "../../../context/AlertContext.js";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate.js";
import Notify from "../../Notification-Loading/Notify/Notify.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentPageHooks from "../../../Hooks/ContentPageHooks.js";
import useAddOrUpdateLp from "../../../Hooks/useAddOrUpdateLp.js";
import RightArrowSvgIcon from "../../../Svg/RightArrowSvgIcon.js";
import Modules from "../../Admin/QuestionEntry/Modules/Modules.js";
import Loading from "../../Notification-Loading/Loading/Loading.js";
import ButtonMedium from "../../Buttons/ButtonMedium/ButtonMedium.js";
import WarningNoticeHooks from "../../../Hooks/WarningNoticeHooks.js";
import React, { useState, useEffect, useContext, useRef } from "react";
import useUploadLargeFileToS3 from "../../../Hooks/useUploadLargeFileToS3.js";
import { getDataFromStorage } from "../../../util.js";

import {
  faLink,
  faVideo,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import PdfViewer from "../../CommonComponents/PdfViewer.js";

export default function ContentPage() {
  const {
    msg,
    show,
    title,
    setMsg,
    status,
    lpData2,
    isAdmin,
    setShow,
    isFromLp,
    isLoaded,
    setTitle,
    isUnAuth,
    fromList,
    navigate,
    setLpData,
    selectque,
    isqueReport,
    setIsLoaded,
    setIsUnAuth,
    contentData,
    setFromList,
    setIsFromLp,
    isFromBatch,
    handleClose,
    setselectque,
    currentIndex,
    isFullScreen,
    isOldLpDetail,
    setisqueReport,
    referenceLinks,
    selectedLibType,
    setIsFullScreen,
    setCurrentIndex,
    tableShowStatus,
    setFromCodeEditor,
    handleUnAuthAlert,
    lpData,
    testData,
    handleNavigateToLpAfterAddTest,
    lpConfigId
  } = useContext(AlertContext);

  const { goToNextTest } = WarningNoticeHooks();
  const { handleSubmit, handleGetLinks } = ContentPageHooks();

  lpData.modules.filter(
    (module, index) =>
      // test.orderno === testData.orderno + 1 &&
      // (setModule({ id: module.modid, name: module.modname }),
      // openTest(test),
      module.data.length > index &&
      module.data[index].libid === testData.libid &&
      ""
    // console.log(
    //   "test",
    //   index,
    //   testData,
    //   module.data[index],
    //   module.data[index + 1]
    // )
    // : console.log("else", module)
  );

  const axios = useAxiosPrivate();

  const assignmentReferenceLinkRef = useRef([]);

  let [showNotification, setShowNotification] = useState(false);

  const [isNext, setIsNext] = useState(false);
  const [topicKey, setTopicKey] = useState("");
  const [viewFile, setViewFile] = useState(false);
  const [newContent, setNewContent] = useState({});
  const [startTime, setStartTime] = useState(null);
  // const [currentIndex, setCurrentIndex] = useState(0);
  // const numPages = useRef([]);

  const [topic, setTopic] = useState(isqueReport ? selectque.name : "");

  const { handleAddOrUpdateLp } = useAddOrUpdateLp();
  const { uploadLargeFileToS3 } = useUploadLargeFileToS3();

  const cancelClickedInConfirmBox = () => {
    navigate(`/library/${constants.libTypeIds.CONTENT}/questionlist`);
    setShowNotification(false);
  };

  // const onDocumentLoadSuccess = (numPageDetail, index) => {
  //   numPages.current[index] = numPageDetail._pdfInfo.numPages;
  // };

  const handlenavigate = () => {
    if (resultCode === constants.RESULT_STATUS.SUCCESS) {
      setShow(false);
      navigate(`/library/${constants.libTypeIds.CONTENT}/questionlist`);
    }
  };

  const handleRequestfullScreenMode = (index) => {
    const iframeRef = assignmentReferenceLinkRef.current[index];
    setCurrentIndex(index);
    iframeRef.requestFullscreen();
    setIsFullScreen(true);
  };

  const handleExitFullscreen = () => {
    if (document.fullscreenElement === null) {
      // setViewFile(false);

      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleExitFullscreen);

    return () => {
      document.removeEventListener("fullscreenchange", handleExitFullscreen);
    };
  }, []);
  const [UserInput, setuserinp] = useState({});
  const [value, setValue] = useState(isqueReport ? UserInput.name : "");

  let [preview, setPreview] = useState(false);
  let [resultCode, setResultCode] = useState("");
  let [previewDisable, setPreviewDisable] = useState(true);
  let [alreadySelected, setalreadySelected] = useState(null);
  let [isSelectedTopic, setisSelectedTopic] = useState(true);

  let obj = UserInput.description;

  const navigateNext = (e) => {
    if (value === "") {
      setShow(true);
      setTitle("Warning");
      setMsg("Please give name!");
    } else if (topic === "" || topic === null || topic === undefined) {
      setShow(true);
      setTitle("Warning");
      setMsg("Please give topic!");
    } else {
      setIsNext(!isNext);
    }
  };

  const changePreviewMode = (e) => {
    setPreview(!preview);
  };

  const addContent = async (e) => {
    setIsLoaded(true);

    let contentdata;
    let form = new FormData();

    let fileUploadCount = 0,
      fileNames = [];

    if (isqueReport) {
      UserInput.id = selectque.id;

      if (isSelectedTopic) {
        UserInput.topic = alreadySelected;
      }

      let fileCount = newContent?.blocks?.length;

      for (let i = 0; i < fileCount; i++) {
        if (
          newContent.blocks[i].type === "image" ||
          newContent.blocks[i].type === "video" ||
          newContent.blocks[i].type === "pdf"
        ) {
          fileNames.push(newContent.blocks[i].data.url.substring(16));

          await uploadLargeFileToS3(
            newContent.blocks[i].data.file,
            newContent.blocks[i].data.url,
            newContent.blocks[i].data.url.substring(16).replace(/^.*\./, "")
          );

          fileUploadCount++;
        }
      }

      try {
        contentdata = JSON.stringify({
          qdata: {
            ...UserInput,
            ["description"]: JSON.stringify(JSON.parse(UserInput.description)),
          },
        });
      } catch (error) {
        contentdata = JSON.stringify({
          qdata: {
            ...UserInput,
            ["description"]: JSON.stringify(UserInput.description),
          },
        });
      }
    } else {
      for (let i = 0; i < newContent.blocks.length; i++) {
        if (
          newContent.blocks[i].type === "pdf" ||
          newContent.blocks[i].type === "image" ||
          newContent.blocks[i].type === "video"
        ) {
          fileNames.push(newContent.blocks[i].data.url.substring(16));

          let filePath =
            "library/content/" + newContent.blocks[i].data.url.substring(16);

          await uploadLargeFileToS3(
            newContent.blocks[i].data.file,
            filePath,
            newContent.blocks[i].data.url.substring(16).replace(/^.*\./, "")
          );

          newContent.blocks[i].data.url = filePath;
          fileUploadCount++;
        }
      }

      contentdata = JSON.stringify({
        qdata: {
          name: value,
          [topicKey]: topic,
          description: JSON.stringify(newContent),
        },
      });
    }

    form.append("qdata", contentdata);
    form.append("libtypeid", tableShowStatus);
    form.append("libtypename", selectedLibType);
    form.append("fileNames", JSON.stringify(fileNames));

    axios
      .post("node/admin/library/add", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setIsLoaded(false);
        if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          if (fromList.isFromList) {
            setFromList({ isFromList: false, libType: "" });

            let newDetails = JSON.parse(JSON.stringify(lpData2)),
              uniqueId = 1,
              libraryData = {
                isNew: true,
                published: 0,
                isEdited: false,
                id: res.data.data.libid,
                libTypeId: lpData2.libTypeId,
                title: isqueReport ? JSON.parse(contentdata).qdata.name : value,
                topics: [
                  {
                    topicid: isqueReport
                      ? JSON.parse(contentdata).qdata.topic
                      : topic,
                  },
                ],
              };

            if (isOldLpDetail) libraryData.status = "new";

            for (let i = 0; i < newDetails.modules.length; i++) {
              if (newDetails.modules[i].id === newDetails.moduleId)
                newDetails.modules[i].moduleData.push(libraryData);

              for (
                let j = 0;
                j < newDetails.modules[i].moduleData.length;
                j++
              ) {
                newDetails.modules[i].moduleData[j].moduleDataId = uniqueId;
                newDetails.modules[i].moduleData[j].key = nanoid();
                uniqueId++;
              }

              newDetails.modules[i].id = uniqueId;
              uniqueId++;
            }

            newDetails.isAddTest = false;
            handleNavigateToLpAfterAddTest(newDetails);
          } else if (isOldLpDetail) {
            let newDetails = JSON.parse(JSON.stringify(lpData2));

            for (let i = 0; i < newDetails.modules.length; i++) {
              if (newDetails.modules[i].id === newDetails.moduleId) {
                for (
                  let j = 0;
                  j < newDetails.modules[i].moduleData.length;
                  j++
                ) {
                  if (
                    newDetails.modules[i].moduleData[j] &&
                    UserInput.id === newDetails.modules[i].moduleData[j].libId
                  ) {
                    newDetails.modules[i].moduleData[j].libId =
                      res.data.data.libid;
                    newDetails.modules[i].moduleData[j].title = UserInput.name;
                    newDetails.modules[i].moduleData[j].testDuration =
                      UserInput.maxtime;
                    newDetails.modules[i].moduleData[j].moved = "yes";
                  }
                }
              }
            }

            setLpData(newDetails);

            return (async () => {
              try {
                let res = await handleAddOrUpdateLp({}, newDetails.modules);

                if (res.title === "success") {
                  setShow(true);
                  setTitle(res.title);
                  setMsg(res.message);
                  handleNavigateToLpAfterAddTest(newDetails);
                }
              } catch (err) {
                setIsUnAuth(err.hasOwnProperty("isUnAuth"));
                setShow(true);
                setTitle(err.title);
                setMsg(err.message);
              }
            })();
          } else {
            setResultCode(res.data.resultCode);
            setShow(true);
            setTitle("Success");
            setMsg(res.data.message);
          }
        } else {
          setShow(true);
          setTitle("Error !");
          setMsg(res.data.message);
        }
      })
      .catch((error) => {
        setIsLoaded(false);

        if (error.message.includes("403")) {
          setShow(true);
          setIsUnAuth(true);
          setTitle("Error !");
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      });
  };

  document.addEventListener("load", (e) => {
    navigate("/home");
  });

  const HandleInputChange = (e) => {
    setValue(e.target.value);
    setuserinp({ ...UserInput, ["name"]: e.target.value });
  };

  const handleInputChange = (event, name) => {
    if (event.name === "topicname") {
      let tempusrinp = UserInput;

      if (tempusrinp.hasOwnProperty("topic")) {
        delete tempusrinp.topic;
      }

      setTopic(event.value);
      setTopicKey(event.name);
      setuserinp({
        ...tempusrinp,
        [event.name]: event.value,
        result: "",
        success: true,
      });
    } else if (event.name === "topic") {
      let tempusrinp = UserInput;

      if (tempusrinp.hasOwnProperty("topicname")) {
        delete tempusrinp.topicname;
      }

      setTopic(event.value);
      setTopicKey(event.name);
      setuserinp({
        ...tempusrinp,
        [event.name]: event.value,
        result: "",
        success: true,
      });
    }
  };

  const handleBackBtn = () => {
    setselectque([]);
    setIsFromLp(false);
    setisqueReport(false);
    setFromList({ isFromList: false, libType: "" });

    if (isNext) {
      setIsNext(!isNext);
    } else {
      if (isFromBatch) {
        navigate(`/batches/${localStorage.getItem('abatchid')}/courses`);
      } else {
        navigate("/learningpath");
      }
    }
  };

  const handleBackFromQuestion = () => {
    if (isNext) {
      setIsNext(!isNext);
    } else {
      navigate(`/library/${constants.libTypeIds.CONTENT}/questionlist`);
    }
  };

  const handleBacktoLearningPath = () => {
    if (isAdmin) {
      setPreview(!preview);
    } else {
      navigate("/home");
    }
  };

  const disableRightClick = (e) => {
    if (!isAdmin) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (contentData.type === "contentEditor")
      setuserinp(
        !isAdmin && Object.keys(contentData).length !== 0
          ? { name: contentData.name, description: contentData.description }
          : isqueReport
          ? {
              name: selectque.name,
              description: JSON.parse(
                selectque?.hasOwnProperty("description")
                  ? selectque?.description
                  : "{}"
              ),
            }
          : {}
      );
  }, [contentData]);

  useEffect(() => {
    if (obj && typeof obj === "object") {
      if (obj["blocks"]?.length > 0) {
        setPreviewDisable(false);
      } else {
        setPreviewDisable(true);
      }
    } else if (obj && typeof obj === "string") {
      let obj1 = JSON.parse(obj);

      if (obj1["blocks"]?.length > 0) {
        setPreviewDisable(false);
      } else {
        setPreviewDisable(true);
      }
    }
  }, [isNext, obj]);

  useEffect(() => {
    handleGetLinks();
  }, [handleGetLinks]);


  useEffect(() => {
    // When component mounts
    let role = getDataFromStorage("role");
    if (role == constants.Roles.learner) {
      const newStartTime = Date.now();
      let storedTime;
      let learnerId = getDataFromStorage("learnerid");
      async function duration() {
        // storedTime = localStorage.getItem('timeSpent');
        let response = await axios.get(
          `node/learner/content/timespent/${learnerId}/${lpConfigId}`,
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        );
        storedTime = response?.data?.data?.duration;
        storedTime = storedTime * 1000 * 60;
        setStartTime(newStartTime);
      }
      duration();
      // When component unmounts
      return () => {
        async function save() {
          const endTime = Date.now();
          let timeSpent =
            endTime - newStartTime + (storedTime ? storedTime : 0);
          timeSpent = Math.round(timeSpent / (60 * 1000));
          let learnerId = getDataFromStorage("learnerid");
          let requestData = {
            duration: timeSpent,
            learnerid: learnerId,
            lpconfigid: lpConfigId,
          };
          // localStorage.setItem('timeSpent', timeSpent.toString());
          let response = await axios.post(
            `node/learner/content/timespent`,
            requestData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        }
        save();
      };
    }
  }, []);

  return (
    <div>
      {contentData.type === "contentEditor" ? (
        <div>
          {isAdmin && !preview && !isNext ? (
            <div className="admincontentpageone">
              {!(
                fromList.isFromList ||
                isFromBatch ||
                isOldLpDetail ||
                isFromLp
              ) &&
              isAdmin &&
              !preview ? (
                <div className="adminTestBackBtn" style={{ textAlign: "left" }}>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="adminTestBackBtn"
                    onClick={handleBackFromQuestion}
                  />
                  &nbsp;
                  <span
                    className="adminTestBack"
                    onClick={handleBackFromQuestion}
                  >
                    {" "}
                    Back{" "}
                  </span>
                </div>
              ) : null}
              {(fromList.isFromList ||
                isFromBatch ||
                isOldLpDetail ||
                isFromLp) &&
              isAdmin &&
              !preview ? (
                <div className="adminTestBackBtn" style={{ textAlign: "left" }}>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    onClick={handleBackBtn}
                    className="adminTestBackBtn"
                  />
                  &nbsp;
                  <span className="adminTestBack" onClick={handleBackBtn}>
                    {" "}
                    Back{" "}
                  </span>
                </div>
              ) : null}
              {isAdmin && !isNext && !preview && (
                <div className="topic">
                  <Modules
                    reset={addContent}
                    fromList={fromList}
                    isFromLp={isFromLp}
                    selectedtop={topic}
                    selectmodule={selectque}
                    isqueReport={isqueReport}
                    isFromBatch={isFromBatch}
                    setvalue={handleInputChange}
                    isOldLpDetail={isOldLpDetail}
                    alreadySelected={alreadySelected}
                    isSelectedTopic={isSelectedTopic}
                    tableShowStatus={tableShowStatus}
                    setalreadySelected={setalreadySelected}
                    setisSelectedTopic={setisSelectedTopic}
                  />
                </div>
              )}
              <div className="languageName">
                <label className="labelName" htmlFor="languageName">
                  Name<span style={{ color: "red", width: "4px" }}> *</span>
                </label>
                <br />
                <input
                  name="name"
                  type="text"
                  value={value}
                  id="languageName"
                  className="form-control"
                  onChange={HandleInputChange}
                />
              </div>
              <div className="nextbutton">
                {" "}
                <ButtonMedium btnText="Proceed" onHide={navigateNext} />{" "}
              </div>
              <div className="previewBtn">
                <ButtonMedium
                  btnText="Preview"
                  disable={previewDisable}
                  onHide={changePreviewMode}
                />
              </div>
            </div>
          ) : null}

          <div
            onContextMenu={disableRightClick}
            className={
              isAdmin && !preview
                ? "AdminContentWrapper"
                : isAdmin && preview
                ? "StudentContentWrapper"
                : "StudentContentWrapper1"
            }
          >
            {isLoaded && <Loading />}

            {showNotification && (
              <Notify
                message={msg}
                title="Result"
                show={setShowNotification}
                onHide={cancelClickedInConfirmBox}
              />
            )}

            <div className="checkTheClss">
              {((isAdmin && !isNext && preview) ||
                (!isAdmin && Object.keys(UserInput).length !== 0)) && (
                <Editor
                  isNext={isNext}
                  UserInput={UserInput}
                  setuserinp={setuserinp}
                  isqueReport={isqueReport}
                  Mode={isAdmin && !preview}
                  setNewContent={setNewContent}
                />
              )}

              {isAdmin && isNext && (
                <>
                  <div
                    className="adminTestBackBtn"
                    style={{
                      top: "50px",
                      textAlign: "left",
                      position: "relative",
                      paddingBottom: "50px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      className="adminTestBackBtn"
                      onClick={handleBackFromQuestion}
                    />
                    &nbsp;
                    <span
                      className="adminTestBack"
                      onClick={handleBackFromQuestion}
                    >
                      {" "}
                      Back{" "}
                    </span>
                  </div>
                  <Editor
                    isNext={isNext}
                    UserInput={UserInput}
                    setuserinp={setuserinp}
                    isqueReport={isqueReport}
                    Mode={isAdmin && !preview}
                    setNewContent={setNewContent}
                  />
                </>
              )}
            </div>

            {!isAdmin && (
              <div className="markAsCompleted">
                <Button
                  size="md"
                  icon={
                    status === constants.PROGRESS_STATUS.EVALUATED && {
                      type: "trailing",
                      icon: <RightArrowSvgIcon />,
                    }
                  }
                  onClick={
                    status === constants.PROGRESS_STATUS.EVALUATED
                      ? goToNextTest
                      : handleSubmit
                  }
                  hierarchy={{
                    type: "primary",
                    buttonText:
                      status === constants.PROGRESS_STATUS.EVALUATED
                        ? "Next"
                        : "Complete & continue",
                  }}
                />
              </div>
            )}

            <Notify
              show={show}
              title={title}
              message={msg}
              onHide={
                isUnAuth
                  ? handleUnAuthAlert
                  : resultCode === constants.RESULT_STATUS.SUCCESS
                  ? handlenavigate
                  : handleClose
              }
            />

            {isAdmin && isNext && (
              <div className="button">
                {" "}
                <ButtonMedium btnText="Save" onHide={addContent} />{" "}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="contentPage" onContextMenu={disableRightClick}>
          <div className="oldContent">
            <div className="content-name-btn"></div>
            <div className="content-description">
              <div className="content-first-div">
                {contentData.description &&
                  contentData.description !== "" &&
                  contentData.description !== null && (
                    <ReactQuill
                      readOnly={true}
                      value={contentData?.description}
                      className="codingPageProblemReactQuill"
                    />
                  )}
              </div>
            </div>

            {referenceLinks.map((link, index) =>
              link.type === constants.LINK_TYPE.CONTENT_FILE_LINK &&
              link.extension === "pdf" ? (
                <React.Fragment key={link.id}>
                  <div className="content-iframe-div">
                    <PdfViewer file={link.link} name={link.name}/>
                  </div>
                </React.Fragment>
              ) : link.type === constants.LINK_TYPE.CONTENT_FILE_LINK &&
                link.extension === "mp4" ? (
                <>
                  <div className="imageDiv" key={link.id}>
                    <FontAwesomeIcon className="videoIcon" icon={faVideo} />{" "}
                    <p className="mp4Title">{link.name}</p>
                  </div>
                  <video
                    controls
                    className="videoPlay"
                    controlsList="nodownload"
                    src={`${link.link}#t=2.0`}
                  ></video>
                </>
              ) : link.type === constants.LINK_TYPE.CONTENT_FILE_LINK &&
                (link.extension === "png" || link.extension === "jpg") ? (
                <React.Fragment>
                  <div className="imageDiv">
                    <FontAwesomeIcon className="linkIcon" icon={faLink} />{" "}
                    <p className="mp4Title">{link.name}</p>
                  </div>
                  <img
                    alt=""
                    src={link.link}
                    className={!viewFile && "beforeFullScreen"}
                    onClick={() => handleRequestfullScreenMode(index)}
                    ref={(ref) =>
                      (assignmentReferenceLinkRef.current[index] = ref)
                    }
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <FontAwesomeIcon className="linkIconView" icon={faLink} />{" "}
                  <div className="imageDiv">
                    <FontAwesomeIcon className="linkIcon" icon={faLink} />{" "}
                    <p className="mp4Title">{link.link}</p>
                    <button
                      className="viewEmbeddedBtn"
                      onClick={() => handleRequestfullScreenMode(index)}
                    >
                      View
                    </button>
                  </div>
                  <iframe
                    width="500"
                    height="300"
                    src={link.link}
                    frameborder="0"
                    allowfullscreen
                    title={"embedded" + index}
                    ref={(ref) =>
                      (assignmentReferenceLinkRef.current[index] = ref)
                    }
                    className={!viewFile ? "beforeFullScreen" : ""}
                  ></iframe>
                </React.Fragment>
              )
            )}
            <div className="markAsCompleted">
              <Button
                size={"md"}
                icon={
                  status === constants.PROGRESS_STATUS.EVALUATED && {
                    type: "trailing",
                    icon: <RightArrowSvgIcon />,
                  }
                }
                onClick={
                  status === constants.PROGRESS_STATUS.EVALUATED
                    ? goToNextTest
                    : () => {
                        setFromCodeEditor(true);
                        handleSubmit();
                      }
                }
                hierarchy={{
                  type: "primary",
                  buttonText:
                    status === constants.PROGRESS_STATUS.EVALUATED
                      ? "Next"
                      : "Complete & continue",
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
