import _ from "lodash";
import CodingZoom from "./CodingZoom";
import CodingSplit from "./CodingSplit";
import constants from "../../../constants";
import Profile from "../Home/LandingPage/Profile";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../../context/AlertContext";
import Notify from "../../Notification-Loading/Notify/Notify";
import Loading from "../../Notification-Loading/Loading/Loading";
import CodingPageHooks from "../../../Hooks/CodingPage/CodingPageHooks";
import ConfirmNotify from "../../Notification-Loading/ConfirmNotify/ConfirmNotify";
import CodingPageDetailsHooks from "../../../Hooks/CodingPage/CodingPageDetailsHooks";
import CodingHeaderLearnerSideHooks from "../../../Hooks/CodingPage/CodingHeaderLearnerSideHooks";

export default function CodingPage() {
  const Alert = useContext(AlertContext);

  const CodingPgHooks = CodingPageHooks();
  const CodingHeaderLearnerHooks = CodingHeaderLearnerSideHooks();

  CodingPageDetailsHooks();

  window.onload = () =>
    Alert.isAdmin
      ? Alert.navigate(`/batches/${localStorage.getItem("abatchid")}/report#`)
      : Alert.navigate("/home");

  !Alert.isAdmin &&
    (window.onpopstate = () => {
      window.history.pushState({}, "", Alert.navigate("/home"));
    });

  useEffect(() => {
    if (Alert.isTakeTest) {
      CodingPgHooks.setShowingDelay(true);
      CodingPgHooks.ProfileReturn();
    }
  }, [Alert.isTakeTest]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!Alert.isAdmin) CodingPgHooks.preventCopyAndCut(event);
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (
      !Alert.isAdmin &&
      Alert.testData.testmode === constants.MODE.TEST_MODE &&
      Alert.status !== constants.QUESTION_STATUS.SUBMITTED &&
      Alert.status !== constants.QUESTION_STATUS.TO_BE_EVALUATED &&
      Alert.resultCode !== constants.RESULT_STATUS.NETWORK_ISSUE
    ) {
      window.addEventListener("blur", CodingHeaderLearnerHooks.onBlur);
      window.addEventListener("focus", CodingHeaderLearnerHooks.onFocus);

      return () => {
        window.removeEventListener("blur", CodingHeaderLearnerHooks.onBlur);
        window.removeEventListener("focus", CodingHeaderLearnerHooks.onFocus);
      };
    }
  }, [Alert.status, CodingHeaderLearnerHooks.onBlur, Alert.resultCode]);

  useEffect(() => {
    if (
      !Alert.isAdmin &&
      Alert.testData.testmode === constants.MODE.TEST_MODE &&
      Alert.status !== constants.QUESTION_STATUS.SUBMITTED &&
      Alert.status !== constants.QUESTION_STATUS.TO_BE_EVALUATED &&
      Alert.resultCode !== constants.RESULT_STATUS.NETWORK_ISSUE
    ) {
      if (!document.fullscreenElement) {
        CodingHeaderLearnerHooks.checkFullScreen();
      }
    }
  }, [
    Alert.isAdmin,
    Alert.resultCode,
    Alert.codeDetails.status,
    document.fullscreenElement,
  ]);  

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (
        e.key === "F5" ||
        (e.ctrlKey && e.key === "r") ||
        (e.ctrlKey && e.shiftKey && e.key === "R")
      ) {
        e.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    Object.keys(Alert.codeDetails).length !== 0 && (
      <div
        onContextMenu={CodingPgHooks.handleContextMenu}
        className={
          !Alert.isFullScreen
            ? "codingPageMainContainer"
            : "codingPageMainContainerFullScreen"
        }
      >
        {/* {!Alert.isAdmin && Alert.isTakeTest && CodingPgHooks.showingDelay && (
          <CodingZoom />
        )} */}
        <CodingSplit />
        {Alert.isTakeTest && CodingPgHooks.showingDelay && (
          <Profile
            type="verfypic"
            setMsg={Alert.setMsg}
            setShow={Alert.setShow}
            setTitle={Alert.setTitle}
            details={Alert.codeDetails}
            isTakeTest={Alert.isTakeTest}
            camAvailable={Alert.camAvailable}
            questionid={Alert.codeDetails.qnid}
            setCamAvailable={Alert.setCamAvailable}
            isNextQuestion={CodingPgHooks.isNextQuestion}
            setCameraPopup={CodingPgHooks.setCameraPopup}
            setCameraWarningMsg={Alert.setCameraWarningMsg}
            libtypeid={constants.libTypeIds.CODING_CHALLENGE}
            setIsNextQuestion={CodingPgHooks.setIsNextQuestion}
          />
        )}
        <Notify
          svg={Alert.svg}
          show={Alert.show}
          title={Alert.title}
          message={Alert.msg}
          btnText={Alert.btnText}
          resultCode={Alert.resultCode}
          onHide={
            Alert.isUnAuth && Alert.isAdmin
              ? Alert.handleUnAuthAlert
              : Alert.resultCode === constants.RESULT_STATUS.NETWORK_ISSUE &&
                !Alert.isAdmin
              ? CodingHeaderLearnerHooks.handleSaveTest
              : Alert.isShowTimeAlert
              ? CodingHeaderLearnerHooks.handleNavigateToHome
              : Alert.handleClose
          }
        />
        {Alert.isLoaded && <Loading />}
        <ConfirmNotify
          svg={Alert.svg}
          title={Alert.title}
          message={Alert.msg}
          btnText={Alert.btnText}
          btnText2={Alert.btnText2}
          resultCode={Alert.resultCode}
          show={Alert.showConfirmNotify}
          onHide2={
            Alert.resultCode === constants.RESULT_STATUS.INCORRECT_ANSWER
              ? CodingHeaderLearnerHooks.handleQuitTest
              : Alert.resultCode === constants.RESULT_STATUS.TIMEOUT
              ? CodingHeaderLearnerHooks.handleTimeOut
              : CodingHeaderLearnerHooks.handleNavigateToHome
          }
          onHide={
            Alert.resultCode === constants.RESULT_STATUS.SUCCESS
              ? CodingPgHooks.handleAssignNextQnToLearner
              : () => {
                  Alert.setMsg("");
                  Alert.setSvg("");
                  Alert.setBtnText("");
                  Alert.setBtnText2("");
                  Alert.setResultCode("");
                  Alert.setShowConfirmNotify(false);
                }
          }
        />
      </div>
    )
  );
}
