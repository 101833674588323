import Pdf from './FileUpload';
import List from "@editorjs/list";
import Embed from '@editorjs/embed';
import CodeEditor from './CodingEditor';
import Table from '@editorjs/table';
import Image from './Image';
import NestedList from '@editorjs/nested-list';

import Video from './Video';
import Alert from 'editorjs-alert';
import Header from "@editorjs/header";
import SandPack from './SandpackTool';

// const CodeTool = require('@editorjs/code');
const Warning = require('@editorjs/warning');
const Delimiter = require('@editorjs/delimiter');
const FontSize = require('editorjs-inline-font-size-tool');
// const FontFamily = require('editorjs-inline-font-family-tool');
const CodeTool = require('@editorjs/code');
const ColorPlugin = require('editorjs-text-color-plugin');

function openModal(linkUrl) {
  // Create a modal element
  const modal = document.createElement('div');
  modal.classList.add('modal');
  
  // Create an iframe to display the linked content
  const iframe = document.createElement('iframe');
  iframe.src = linkUrl;
  
  // Add the iframe to the modal
  modal.appendChild(iframe);
  
  // Add the modal to the document body
  document.body.appendChild(modal);
  
  // Add a close button to the modal
  const closeButton = document.createElement('button');
  closeButton.textContent = 'Close';
  closeButton.addEventListener('click', () => {
    // Remove the modal when the close button is clicked
    document.body.removeChild(modal);
  });
  modal.appendChild(closeButton);
}

export const EDITOR_JS_TOOLS = {
  header: {
    class: Header,
    inlineToolbar: true,
    toolboxDescription : "header",
    config: {
      placeholder: 'Header',
    },
    shortcut: 'CMD+SHIFT+H'
  },


  codeeditor :{
    class: CodeEditor,
  },
  code: {
    class: CodeTool,
    inlineToolbar:true,
    
  },

  
  // embed: {
  //     class: Embed,
  //     config: {
  //       services: {
  //         youtube: true,
  //         vimeo: true,
  //         coub: true,
  //         codepen: {
  //           regex: /https?:\/\/codepen.io\/([^\/\?\&]*)\/pen\/([^\/\?\&]*)/,
  //           embedUrl: 'https://codepen.io/<%= remote_id %>?height=300&theme-id=0&default-tab=css,result&embed-version=2',
  //           html: "<iframe height='300' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='width: 100%;'></iframe>",
  //           height: 300,
  //           width: 600,
  //           id: (groups) => groups.join('/embed/')
  //         },
  //       }
  //     }
  //   },
  // Modify the 'embed' block configuration
embed: {
  class: Embed,
  config: {
    services: {
      youtube: true,
      vimeo: true,
      // Add other services as needed
    },
  },
  render: (data) => {
    // Replace 'embedded-content' with a unique ID or class selector that you use for the embedded content container
    return `<div id="embedded-content">${data.html}</div>`;
  },
  // Attach an event listener to links within the embedded content
  onReady: () => {
    const embeddedContent = document.getElementById('embedded-content');

    if (embeddedContent) {
      embeddedContent.addEventListener('click', (event) => {
        const target = event.target;

        // Check if the clicked element is an anchor (<a>) element within the embedded content
        if (target.tagName === 'A' && target.href) {
          // Prevent the default behavior of the link (opening in a new tab or navigating away)
          event.preventDefault();

          // Implement your logic to handle the link within your application, e.g., open it in a modal
          const linkUrl = target.href;
          openModal(linkUrl);
        }
      });
    }
  },
},

  color: {
    class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
    config: {
       colorCollections: ['#EC7878','#9C27B0','#673AB7','#3F51B5','#0070FF','#03A9F4','#00BCD4','#4CAF50','#8BC34A','#CDDC39', '#FFF'],
       defaultColor: '#FF1300',
       type: 'text', 
       customPicker: true // add a button to allow selecting any colour  
    }     
  },
  fontsize: FontSize,
  // fontfamily: FontFamily,
  marker: {
    class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
    config: {
       defaultColor: '#FFBF00',
       type: 'marker',
       icon: `<svg fill="#000000" height="200px" width="200px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M17.6,6L6.9,16.7c-0.2,0.2-0.3,0.4-0.3,0.6L6,23.9c0,0.3,0.1,0.6,0.3,0.8C6.5,24.9,6.7,25,7,25c0,0,0.1,0,0.1,0l6.6-0.6 c0.2,0,0.5-0.1,0.6-0.3L25,13.4L17.6,6z"></path> <path d="M26.4,12l1.4-1.4c1.2-1.2,1.1-3.1-0.1-4.3l-3-3c-0.6-0.6-1.3-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9L19,4.6L26.4,12z"></path> </g> <g> <path d="M28,29H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h24c0.6,0,1,0.4,1,1S28.6,29,28,29z"></path> </g> </g></svg>`
      }       
  },

  image: {
    class: Image,
    
  },
  video:{
    class:Video,
    
  },
  pdf:{
    class:Pdf,
    
  },
  // sandpack :{
  //   class : SandPack,
    
  // },
  list:{
    class:NestedList,
    inlineToolbar: true,

  },
  uolist: {
    class : List,
    inlineToolbar: true,
    config: {
        defaultStyle: 'unordered',
      },
      shortcut:"CMD+SHIFT+L"
  },
  table : Table,
  delimeter : Delimiter,
  warning : Warning,
 
  alert:{
    class: Alert,
    inlineToolbar: true,
      shortcut: 'CMD+SHIFT+A',
      config: {
        defaultType: 'primary',
        messagePlaceholder: 'Enter something',
      },
  }
};
