import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from "react";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { Box, IconButton, Tooltip } from "@mui/material";
import RejectBulk from "./RejectBulk";
import Popup from "../../../CommonComponents/Popup";
import moment from "moment";
import ModuleHooks from "../../../../Hooks/ModuleHooks";
import constants from "../../../../constants";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MenuItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import Whatsapp from "../../../../Svg/Whatsapp";
import {
  QueryClient,
  QueryClientProvider,
  useInfiniteQuery,
} from "@tanstack/react-query";
import useAddComments from "../../../../Hooks/useAddComments"; //Note: this is TanStack React Query V5
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import AlertContext from "../../../../context/AlertContext";
import UserDetails from "./UserDetails";
import TableWithTotal from "../../../CommonComponents/TableWithTotal";
import UploadSvg from "../../../../Svg/UploadIconsvg";
import Button from "../../../Button";

import ReportHooks from "../../../../Hooks/ReportHooks";
import { size } from "lodash";
import { emailBody, getDataFromStorage } from "../../../../util";
import useCommonFunctionHooks from "../../../../Hooks/useCommonFunctionHooks";
import Resume from "./Resume";
import ResumePdf from "./ResumePdf";
import { useParams } from "react-router-dom";
import { ProfileProvider } from "../../../../context/ProfileContext";
import UploadGcatFb from "./UploadGcatFb";
const fetchSize = 10;
const UsersTable = (props) => {
  const axios = useAxiosPrivate();
  const { capitalizeFirstLetterOfEachWord } = ReportHooks();
  const { id } = useParams();
  const [showUserFormPopup, setShowUserFormPopup] = useState(false);
  const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
  const rowVirtualizerInstanceRef = useRef(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState();
  const [sorting, setSorting] = useState([]);
  const [active, setActive] = useState(true);
  const [showReject, setShowReject] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowSelection, setRowSelection] = useState([]);
  const [userNameClick, setUserNameClick] = useState(false);
  const [newUserInput, setNewUserInput] = useState({
    batchid: id,
    emailBody: emailBody,
    role: "learner",
  });
  const [addUser, setAddUser] = useState(false);
  const [testData, setTestData] = useState([]);
  const [pendingList, setPendingList] = useState([]);
  const [mouseHoveronSvg,setMouseHoveronSvg] = useState(0);
  const [hoveredRecordId,setHoveredRecordId] = useState(0);
  /* For feedback */
  const [showFbTab, setShowFbTab] = useState(false);
  const [cnfrmbtnHide, setCnfrmbtnHide] = useState(false);
  const [gcatFileLoaded, setGcatFileLoaded] = useState("");
  const [fbFileLoaded, setFbFileLoaded] = useState("");
  const {
    setIsUnAuth,
    setMsg,
    setShow,
    setIsLoaded,
    setTitle,
    batchDetails,
    isCommonPopup,
    setShowNotify,
    setClickedMenu,
  } = useContext(AlertContext);

  const { clearPopupStates, handlePopupStates, setCommonPopupBodyComponent } =
    ModuleHooks();

  const commonFunction = useCommonFunctionHooks();

  const handleAddNewLearner = () => {
    setAddUser(true);
    setShowUserFormPopup(true);
  };

  /* popup for  */

  const handleFbUpload = (file, name) => {
    if (!file.size) return;
    console.log("handleFbUpload name ", name);
    let reader = new FileReader();
    reader.onload = async (e) => {
      if (!e.target.result.includes("data:text/csv")) {
        setShow(true);
        setTitle("Warning !");
        setMsg("Wrong file type - CSV only.");
      } else {
        const form = new FormData();
        form.append("csv", file);

        axios
          .post(
            name == "gcat"
              ? `node/admin/batch/profile/upload/:gcat`
              : `node/admin/batch/profile/upload/:feedback`,
            form,
            commonFunction.getHeaderDetailsForAxios("multipart")
          )
          .then((res) => {
            console.log("res ", res);
            if (res.data.resultCode === 1000) {
              setShow(true);
              setTitle("Success");
              setMsg(res.data.msg);
              name == "gcat" ? setGcatFileLoaded("") : setFbFileLoaded("");
            } else if (res.data.resultCode === 2050) {
              setShow(true);
              setTitle("Warning !");
              setMsg(res.data.msg);
            } else {
              setShow(true);
              setTitle("Error");
              setMsg("Something went wrong. Please try again later.");
            }

            if (res.data?.hasOwnProperty("filedata")) {
              const url = window.URL.createObjectURL(
                new Blob([res.data.filedata])
              );
              const link = document.createElement("a");

              link.href = url;
              link.setAttribute(
                "download",
                `${new Date().toISOString().split("T")[0]}.csv`
              );
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch((error) => {
            console.log("err ", error);
            if (error.message.includes("403")) {
              setShow(true);
              setIsUnAuth(true);
              setTitle("Error");
              setMsg(
                "You have been logged-out due to inactivity. Login again."
              );
            }

            if (error.data?.hasOwnProperty("filedata")) {
              const url = window.URL.createObjectURL(
                new Blob([error.data.filedata])
              );
              const link = document.createElement("a");

              link.href = url;
              link.setAttribute(
                "download",
                `${new Date().toISOString().split("T")[0]}.csv`
              );
              document.body.appendChild(link);
              link.click();
            }
          });
      }
    };

    reader.readAsDataURL(file);
  };

  const handleUploadFbfile = (e) => {
    setShowNotify({
      show: true,
      title: "Gradious Evaluation",
      size: "md",
      showClose: true,
      hideButton: true,
      msg: <UploadGcatFb batchId={id} />,
    });

    // console.log("e ", e);
    // const file = e.target?.files[0];
    // if (e.target.name == "fb") {
    //   if (file) {
    //     setFbFileLoaded(file);
    //   }
    //   e.target.value = "";
    // } else {
    //   if (file) {
    //     setGcatFileLoaded(file);
    //   }
    //   e.target.value = "";
    // }
  };

  const handleRejectClick = async () => {
    if (selectedUsers.length > 0) {
      let res = await axios.post(
        "node/admin/batch/pendingtests",
        { batchId: batchDetails.id, users: selectedUsers },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setTestData(res.data.data.tests);
      let testNames = res.data.data.tests.map((item) => {
        return { label: item.testname, value: item.testid };
      });

      setPendingList(testNames);
      setShowReject(true);
    } else {
      setTitle("warning!");
      setMsg("No Learners Selected!");
      setShow(true);
    }
  };

  const handleEdit = (row) => {
    let AllData = data?.pages.flatMap((page) => page.users);
    const user = AllData?.find((user) => user.email === row._valuesCache.email);
    axios
      .get("node/admin/batch/user/" + user?.userId, {
        headers: { "Content-type": "application/json" },
      })
      .then((res) => {
        if (res.data.resultCode === 1000 && res.data.data != null) {
          let userDetails =  { ...res.data.data, role: "learner" };
          setNewUserInput(userDetails);
          setShowUserFormPopup(true);
        } else {
          setShow(true);
          setMsg("No User Exists!");
          setTitle("Warning!");
        }
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          setIsUnAuth(true);
          setShow(true);
          setTitle("Error");
          setMsg("You have been logged-out due to inactivity. Login again.");
        } else {
          setShow(true);
          setTitle("Error");
          setMsg("Something went wrong. Please try again later.");
        }
      });
  };
  const handleUserLpData = (cell) => {
    setUserNameClick(true);
    let learnerId = cell.original.userId;
    let name = cell.original.firstname + " " + cell.original.lastname;
    setShowNotify({
      show: true,
      fullscreen: true,
      hideButton: true,
      backdrop: true,
      component: "leaderBoard",
      msg: (
        <TableWithTotal
          learnerId={learnerId}
          name={name}
          option={0}
          from="popup"
          batchId={id}
        />
      ),
    });
    setClickedMenu(1);
    setUserNameClick(false);
  };

  const ViewResume = (row) => {
    // closeMenu();
    let learnerId = row.original.userId;

    // axios
    //   .get(
    //     `node/admin/batch/profile/${batchDetails.id}/${learnerId}`,
    //     commonFunction.getHeaderDetailsForAxios()
    //   )
    //   .then((res) => {
    //     console.log("res ", res);
    //     if (res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR) {
    //       setShowNotify({
    //         show: true,
    //         title: "Info",
    //         msg: res.data.msg,
    //       });
    //     } else {
    //       if (res.data.resultCode === constants.RESULT_STATUS.NO_DATA) {
    //         setShowNotify({
    //           show: true,
    //           title: "Info",
    //           msg: "No data to display",
    //         });
    //       } else {
            setShowNotify({
              show: true,
              size: "xl",
              showClose: true,
              hideButton: true,
              msg: <Resume from={"onpopup"} learnerId={learnerId} forLoad={true}/>,
              // msg: <ResumePdf from={"onpopup"} learnerId={learnerId} forLoad={true}/>,
            });
      //     }
      //   }
      // })
      // .catch((err) => {});
  };

  const columns = [
    {
      accessorFn: (row) =>
        `${capitalizeFirstLetterOfEachWord(
          row?.firstname + " " + row?.lastname
        )}`, //accessorFn used to join multiple data into a single cell
      id: "name", //id is still required when using accessorFn instead of accessorKey
      header: "Name",
      size: 180,
      // Cell: ({ cell }) => (
      //   <p onClick={() => handleUserLpData(cell)} style={{ cursor: "pointer" }}>
      //     {cell.getValue()}
      //   </p>
      // ),
    },
    {
      accessorKey: "email",
      header: "Email Address",
      enableClickToCopy: true,
      grow: true,
      size: 150,
    },
    {
      accessorKey: "college",
      header: "College",
      size: 75,
    },
    {
      accessorKey: "branch",
      header: "Branch",
      size: 75,
    },
    {
      accessorKey: "mobile",
      header: "Phone Number",
      size: 100,
      Cell: ({ cell }) =>
        cell.getValue() != "" &&
        cell.getValue() != undefined &&
        cell.getValue().length >= 10 && (
          <>
            <Typography component="div">
              <Whatsapp />
            </Typography>{" "}
            <a
              href={`https://wa.me/${cell.getValue()}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* {cell.getValue().includes("+91") ? cell.getValue() : `+91 ${cell.getValue()}`} */}
              {cell.getValue()}
            </a>
          </>
        ),
      enableClickToCopy: true,
    },
    {
      accessorFn: (row) =>
        `${
          row.completedtests == null || row.completedtests == undefined
            ? 0
            : row.completedtests
        }/${row.totaltests}`,
      id: "completedtests",
      header: "Completed Tests",
      size: 100,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "lastsubmittedtest",
      header: "Last Completed",
      size: 180,
    },
    // {
    //   accessorKey: "resume",
    //   header: "Resume",
    //   Cell: ({ cell }) => (
    //     // <a
    //     //   href={`https://wa.me/${
    //     //     cell.getValue().includes("+91")
    //     //       ? cell.getValue()
    //     //       : "+91" + cell.getValue()
    //     //   }`}
    //     //   target="_blank"
    //     //   rel="noopener noreferrer"
    //     // >
    //     //   {cell.getValue()}
    //     // </a>
    //     <p onClick={() => ViewResume(cell)}>View Resume</p>
    //   ),
    // },
    // {
    //   id: "action",
    //   header: "",
    //   size: 60,
    //   Cell: (
    //     { row }
    //   ) => (
    //     <div onClick={() => handleEdit(row)} style={{ cursor: "pointer" }}>
    //       <svg
    //         width="32"
    //         height="32"
    //         viewBox="0 0 32 32"
    //         fill="none"
    //         xmlns="http://www.w3.org/2000/svg"
    //       >
    //         <g clip-path="url(#clip0_35_3042)">
    //           <path
    //             d="M15.3333 10.6671H10.6667C10.313 10.6671 9.97391 10.8076 9.72386 11.0577C9.47381 11.3077 9.33333 11.6469 9.33333 12.0005V21.3338C9.33333 21.6874 9.47381 22.0266 9.72386 22.2766C9.97391 22.5267 10.313 22.6671 10.6667 22.6671H20C20.3536 22.6671 20.6928 22.5267 20.9428 22.2766C21.1929 22.0266 21.3333 21.6874 21.3333 21.3338V16.6671M20.3333 9.66714C20.5986 9.40193 20.9583 9.25293 21.3333 9.25293C21.7084 9.25293 22.0681 9.40193 22.3333 9.66714C22.5986 9.93236 22.7475 10.2921 22.7475 10.6671C22.7475 11.0422 22.5986 11.4019 22.3333 11.6671L16 18.0005L13.3333 18.6671L14 16.0005L20.3333 9.66714Z"
    //             stroke="#667085"
    //             stroke-width="1.4"
    //             stroke-linecap="round"
    //             stroke-linejoin="round"
    //           />
    //         </g>
    //         <defs>
    //           <clipPath id="clip0_35_3042">
    //             <rect
    //               width="16"
    //               height="16"
    //               fill="white"
    //               transform="translate(8 8)"
    //             />
    //           </clipPath>
    //         </defs>
    //       </svg>
    //     </div>
    //   ),
    // },
  ];
  // .concat(
  //   getDataFromStorage("role") !== constants.Roles.hr
  //     ? [
  //         {
  //           id: "action",
  //           header: "",
  //           size: 60,
  //           Cell: ({ row }) => (
  //             <div
  //               onClick={() => handleEdit(row)}
  //               style={{ cursor: "pointer" }}
  //             >
  //               <svg
  //                 width="32"
  //                 height="32"
  //                 viewBox="0 0 32 32"
  //                 fill="none"
  //                 xmlns="http://www.w3.org/2000/svg"
  //               >
  //                 <g clipPath="url(#clip0_35_3042)">
  //                   <path
  //                     d="M15.3333 10.6671H10.6667C10.313 10.6671 9.97391 10.8076 9.72386 11.0577C9.47381 11.3077 9.33333 11.6469 9.33333 12.0005V21.3338C9.33333 21.6874 9.47381 22.0266 9.72386 22.2766C9.97391 22.5267 10.313 22.6671 10.6667 22.6671H20C20.3536 22.6671 20.6928 22.5267 20.9428 22.2766C21.1929 22.0266 21.3333 21.6874 21.3333 21.3338V16.6671M20.3333 9.66714C20.5986 9.40193 20.9583 9.25293 21.3333 9.25293C21.7084 9.25293 22.0681 9.40193 22.3333 9.66714C22.5986 9.93236 22.7475 10.2921 22.7475 10.6671C22.7475 11.0422 22.5986 11.4019 22.3333 11.6671L16 18.0005L13.3333 18.6671L14 16.0005L20.3333 9.66714Z"
  //                     stroke="#667085"
  //                     strokeWidth="1.4"
  //                     strokeLinecap="round"
  //                     strokeLinejoin="round"
  //                   />
  //                 </g>
  //                 <defs>
  //                   <clipPath id="clip0_35_3042">
  //                     <rect
  //                       width="16"
  //                       height="16"
  //                       fill="white"
  //                       transform="translate(8 8)"
  //                     />
  //                   </clipPath>
  //                 </defs>
  //               </svg>
  //             </div>
  //           ),
  //         },
  //       ]
  //     : []
  // );

  const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
    useInfiniteQuery({
      queryKey: [
        "table-data",
        // columnFilters, //refetch when columnFilters changes
        globalFilter, //refetch when globalFilter changes
        sorting, //refetch when sorting changes
      ],
      queryFn: async ({ pageParam }) => {
        const endPoint = "node/admin/batch/course/userreport";
        try {
          const response = await axios.post(
            endPoint,
            { batchId: id, status: active },
            {
              params: {
                start: pageParam * fetchSize,
                size: fetchSize,
                filters: JSON.stringify(columnFilters ?? []),
                globalFilter: globalFilter ?? "",
                sorting: JSON.stringify(sorting ?? []),
              },
              headers: {
                "Content-type": "application/json",
              },
            }
          );
          if (addUser) {
            setAddUser(false);
          }
          return response.data.data;
        } catch (error) {
          console.error("Error fetching data:", error);
          throw error;
        }
      },
      initialPageParam: 0,
      getNextPageParam: (_lastGroup, groups) => groups.length,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    });

  const handleChangeUserStatus = async () => {
    try {
      if (selectedUsers.length > 0) {
        let res = await axios.post(
          "node/admin/batch/users/status",
          JSON.stringify({
            userIds: selectedUsers,
            active: !active,
            removeTrainer: false,
            batchId: id,
          }),
          { headers: { "Content-type": "application/json" } }
        );
        if (res.data.resultCode === 1000) {
          setSelectedUsers([]);
          refetch();
        } else {
          setShow(true);
          setMsg("Something went wrong. Please try again later");
          setTitle("Error !");
        }
      } else {
        setShow(true);
        setMsg("There is no users");
        setTitle("Warning !");
      }
    } catch (error) {
      if (error.message.includes("403")) {
        setShow(true);
        setIsUnAuth(true);
        setMsg("You have been logged-out due to inactivity. Login again.");
        setTitle("Warning !");
      } else {
        console.error(error);
        setShow(true);
        setMsg("Server error");
        setTitle("Error!");
      }
    } finally {
      setIsLoaded(false);
    }
  };

  useEffect(() => {
    refetch();
  }, [active, refetch]);

  const flatData = useMemo(() => {
    return data?.pages.flatMap((page) => page?.users) ?? [];
  }, [data]);

  const totalDBRowCount = data?.pages[0]?.totalUsercount;

  const totalFetched = flatData?.length;

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can

        if (
          scrollHeight - scrollTop - clientHeight < 400 &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  );

  const handleSelect = (e, row) => {
    e.stopPropagation();
    let AllData = data?.pages.flatMap((page) => page.users);

    const user = AllData?.find((user) => user.email === row._valuesCache.email);

    let learnerids = [...selectedUsers];
    let newLearnerids = [];

    if (learnerids.length > 0 && learnerids.includes(user.userId)) {
      newLearnerids = learnerids.filter((item) => item !== user.userId);
    } else {
      newLearnerids = [...selectedUsers, user.userId];
    }

    setSelectedUsers(newLearnerids);
  };

  const handleMouseEnter = (val,id)=>{
    setHoveredRecordId(id);
    setMouseHoveronSvg(val);
  }

  const handleMouseLeave = ()=>{
    setMouseHoveronSvg(0);
    setHoveredRecordId(0);
  }

  const handleSelectAll = (e) => {
    e.stopPropagation();

    let AllData = data?.pages.flatMap((page) => page.users);
    let allUserIds = AllData.map((user) => user.userId);

    if (selectedUsers.length === allUserIds.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(allUserIds);
    }
  };

  //scroll to top of table when sorting or filters change
  // useEffect(() => {
  //   //scroll to the top of the table when the sorting changes

  //   try {
  //     rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, [sorting, columnFilters, globalFilter]);

  //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  // useEffect(() => {
  //   fetchMoreOnBottomReached(tableContainerRef.current);
  // }, [fetchMoreOnBottomReached]);

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
    filename: `${batchDetails.name}`,
  });

  const handleExportRows = (rows) => {
    let AllData = data?.pages.flatMap((page) => page.users);
    // const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(AllData);
    download(csvConfig)(csv);
  };
  const customStylesForTests = {
    control: (base, state) => ({
      ...base,
      border: "none",
      height: "38px",
      paddingLeft: "7px",
      "&:hover": {
        border: state.isFocused ? "1px solid #BDC1C6" : "none",
        outline: "none",
      },
      backgroundColor: "#ffffff",
      cursor: state.isDisabled ? "default" : "pointer",
      overflow: "auto",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        textAlign: "left",
        backgroundColor: isFocused ? "#e9ecef" : undefined,
        color: isSelected ? "#000000" : undefined,
        height: "30px",
        margin: "0px",
        fontFamily: "Inter",
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "15px",
      };
    },
    singleValue: (styles, { data }) => ({
      ...styles,
      color: "#202124",
      borderRadius: "20px",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      textAlign: "center",
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "150px",
    }),
  };

  const table = useMaterialReactTable({
    columns,
    data: flatData,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "0px",ml:"-10px" }}>
        <Tooltip title="View Resume">
          <IconButton color="primary" onClick={() => ViewResume(row)}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onMouseEnter={()=>handleMouseEnter(1,row.original.userId)}
              onMouseLeave={handleMouseLeave}
            >
              <path
                d="M11.6668 1.89128V5.3334C11.6668 5.80011 11.6668 6.03346 11.7577 6.21172C11.8376 6.36853 11.965 6.49601 12.1218 6.5759C12.3001 6.66673 12.5335 6.66673 13.0002 6.66673H16.4423M13.3335 10.8333H6.66683M13.3335 14.1667H6.66683M8.3335 7.50001H6.66683M11.6668 1.66667H7.3335C5.93336 1.66667 5.2333 1.66667 4.69852 1.93916C4.22811 2.17884 3.84566 2.56129 3.60598 3.0317C3.3335 3.56647 3.3335 4.26654 3.3335 5.66667V14.3333C3.3335 15.7335 3.3335 16.4335 3.60598 16.9683C3.84566 17.4387 4.22811 17.8212 4.69852 18.0609C5.2333 18.3333 5.93336 18.3333 7.3335 18.3333H12.6668C14.067 18.3333 14.767 18.3333 15.3018 18.0609C15.7722 17.8212 16.1547 17.4387 16.3943 16.9683C16.6668 16.4335 16.6668 15.7335 16.6668 14.3333V6.66667L11.6668 1.66667Z"
                stroke={mouseHoveronSvg==1 && hoveredRecordId===row.original.userId? "#f55533":"#344054"}
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </IconButton>
        </Tooltip>
        <Tooltip title="View Report">
          <IconButton color="secondary" onClick={() => handleUserLpData(row)}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onMouseEnter={()=>handleMouseEnter(2,row.original.userId)}
              onMouseLeave={handleMouseLeave}
            >
              <path
                d="M11.6668 9.16667H6.66683M8.3335 12.5H6.66683M13.3335 5.83334H6.66683M16.6668 8.75001V5.66667C16.6668 4.26654 16.6668 3.56647 16.3943 3.0317C16.1547 2.56129 15.7722 2.17884 15.3018 1.93916C14.767 1.66667 14.067 1.66667 12.6668 1.66667H7.3335C5.93336 1.66667 5.2333 1.66667 4.69852 1.93916C4.22811 2.17884 3.84566 2.56129 3.60598 3.0317C3.3335 3.56647 3.3335 4.26654 3.3335 5.66667V14.3333C3.3335 15.7335 3.3335 16.4335 3.60598 16.9683C3.84566 17.4387 4.22811 17.8212 4.69852 18.0609C5.2333 18.3333 5.93336 18.3333 7.3335 18.3333H9.5835M18.3335 18.3333L17.0835 17.0833M17.9168 15C17.9168 16.6108 16.611 17.9167 15.0002 17.9167C13.3893 17.9167 12.0835 16.6108 12.0835 15C12.0835 13.3892 13.3893 12.0833 15.0002 12.0833C16.611 12.0833 17.9168 13.3892 17.9168 15Z"
                stroke={mouseHoveronSvg==2 && hoveredRecordId===row.original.userId? "#f55533":"#344054"}
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton
            color="error"
            onClick={() => handleEdit(row)}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"

              onMouseEnter={()=>handleMouseEnter(3,row.original.userId)}
              onMouseLeave={handleMouseLeave}
            >
              <g clip-path="url(#clip0_6374_11614)">
                <path
                  d="M9.1665 3.33333H3.33317C2.89114 3.33333 2.46722 3.50893 2.15466 3.82149C1.8421 4.13405 1.6665 4.55797 1.6665 5V16.6667C1.6665 17.1087 1.8421 17.5326 2.15466 17.8452C2.46722 18.1577 2.89114 18.3333 3.33317 18.3333H14.9998C15.4419 18.3333 15.8658 18.1577 16.1783 17.8452C16.4909 17.5326 16.6665 17.1087 16.6665 16.6667V10.8333M15.4165 2.08333C15.748 1.75181 16.1977 1.56557 16.6665 1.56557C17.1353 1.56557 17.585 1.75181 17.9165 2.08333C18.248 2.41485 18.4343 2.86449 18.4343 3.33333C18.4343 3.80217 18.248 4.25181 17.9165 4.58333L9.99984 12.5L6.6665 13.3333L7.49984 10L15.4165 2.08333Z"
                  stroke={mouseHoveronSvg==3 && hoveredRecordId===row.original.userId? "#f55533":"#344054"}
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_6374_11614">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </IconButton>
        </Tooltip>
      </Box>
    ),
    // [
    //   <div className="rowactionmenucontainer">
    //   <MenuItem key="edit" onClick={() => handleEdit(row, closeMenu)}>
    //   <div className="iconwithaction">
    //    <span>
    //      <svg
    //       width="24"
    //       height="24"
    //       viewBox="0 0 24 24"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58578C2.21071 4.96086 2 5.46956 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13M18.5 2.5C18.8978 2.10217 19.4374 1.87868 20 1.87868C20.5626 1.87868 21.1022 2.10217 21.5 2.5C21.8978 2.89782 22.1213 3.43739 22.1213 4C22.1213 4.56261 21.8978 5.10217 21.5 5.5L12 15L8 16L9 12L18.5 2.5Z"
    //         stroke="#667085"
    //         stroke-width="2"
    //         stroke-linecap="round"
    //         stroke-linejoin="round"
    //       />
    //     </svg>
    //     </span>
    //     <span>Edit</span>
    //     </div>
    //   </MenuItem>
    //   <MenuItem  key="viewResume" onClick={() => ViewResume(row, closeMenu)}>
    //     view Resume
    //   </MenuItem>
    //   <MenuItem  key="viewResume" onClick={() => handleUserLpData(row, closeMenu)}>
    //   view Report
    // </MenuItem>
    // </div>
    // ],
    initialState: {
      columnVisibility: { college: false, branch: false, mobile: false },
      showGlobalFilter: true,
    },
    positionActionsColumn: "last",
    enablePagination: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    getRowId: (row) => row?.userId,
    enableRowNumbers: getDataFromStorage("role") === constants.Roles.hr,
    enableRowVirtualization: true,
    enableRowSelection: getDataFromStorage("role") !== constants.Roles.hr,
    enableMultiRowSelection: getDataFromStorage("role") !== constants.Roles.hr,
    enableStickyFooter: false,
    manualFiltering: true,
    
    positionGlobalFilter:"left",
    positionToolbarAlertBanner: "none",
    manualSorting: true,
    muiSelectCheckboxProps: ({ row }) => ({
      onClick: (e) => {
        handleSelect(e, row);
      },
      checked:
        selectedUsers.includes(row?.id) &&
        flatData.map((r) => r?.userId).includes(row?.id),
      defaultChecked: false,
      rowSelection: false,
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
    muiSelectAllCheckboxProps: ({ table }) => ({
      onClick: handleSelectAll,
      checked: flatData.map((r) => r?.userId)?.length == selectedUsers.length,
      rowSelection: false,
      defaultChecked: false,
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
    muiTableContainerProps: {
      ref: tableContainerRef, //get access to the table container element
      sx: { maxHeight: "600px", paddingBottom: "140px" }, //give the table a max height
      onScroll: (event) => fetchMoreOnBottomReached(event.target), //add an event listener to the table container element
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    // onActiveChange:setActive,

    // renderTopToolbarCustomActions: () => (

    // ),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching || userNameClick,
      sorting,
      active: active,
      rowSelection,
    },
    rowVirtualizerInstanceRef, //get access to the virtualizer instance
    rowVirtualizerOptions: { overscan: 4 },
  });

  return (
    <>
      <div className="noofuserssection">
        <div className="righttools">
          <p className="userstext"> Users </p>
          <div className="noofuserdiv">
            <p className="noofusers">
              {" "}
              {totalFetched} / {data?.pages[0]?.totalUsercount}
            </p>
          </div>
        </div>
        <div className="lefttools">
          { getDataFromStorage("role") !== constants.Roles.hr && (<div className="actvendinactive">
            <p
              className={active ? "activeusers" : "activeusersdisable"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedUsers([]);
                setActive(true);
                // setPageParam(0)
              }}
            >
              Active
            </p>
            <p
              className={!active ? "inactiveusers" : "inactiveusersdisable"}
              style={{ cursor: "pointer" }}
              s
              onClick={() => {
                setSelectedUsers([]);
                setActive(false);
                // setPageParam(0);
              }}
            >
              Inactive
            </p>
          </div>)}

          {getDataFromStorage("role") !== constants.Roles.hr && (
            <div
              className="addusericon"
              style={{ cursor: "pointer" }}
              onClick={handleAddNewLearner}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10.0001 4.16699V15.8337M4.16675 10.0003H15.8334"
                  stroke="#344054"
                  stroke-width="1.67"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          )}
          <div
            className="usersdownloadicon"
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5M5.83333 8.33333L10 12.5M10 12.5L14.1667 8.33333M10 12.5V2.5"
                stroke="#344054"
                stroke-width="1.67"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          {getDataFromStorage("role") !== constants.Roles.hr ? (
            !active ? (
              <div
                className="userenableicon"
                onClick={handleChangeUserStatus}
                style={{ cursor: "pointer" }}
              >
                <svg
                  width="16"
                  height="11"
                  viewBox="0 0 16 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6666 1L5.49992 10.1667L1.33325 6"
                    stroke="#12B76A"
                    stroke-width="1.67"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            ) : (
              <div
                className="userdisableicon"
                onClick={handleChangeUserStatus}
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clip-path="url(#clip0_91_2327)">
                    <path
                      d="M6.66675 10.0003H13.3334M18.3334 10.0003C18.3334 14.6027 14.6025 18.3337 10.0001 18.3337C5.39771 18.3337 1.66675 14.6027 1.66675 10.0003C1.66675 5.39795 5.39771 1.66699 10.0001 1.66699C14.6025 1.66699 18.3334 5.39795 18.3334 10.0003Z"
                      stroke="#D92D20"
                      stroke-width="1.67"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_91_2327">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            )
          ) : null}

          {getDataFromStorage("role") !== constants.Roles.hr && (
            <Button
              size={"sm"}
              onClick={handleRejectClick}
              disable={selectedUsers.length == 0}
              hierarchy={{
                type: "secondaryGrey",
                buttonText: "Reject",
              }}
            ></Button>
          )}

          {getDataFromStorage("role") !== constants.Roles.hr && (<Button
            disable={false}
            icon={{
              type: "leading",
              icon: <UploadSvg stroke="#344054" />,
            }}
            size={"sm"}
            onClick={handleUploadFbfile}
            hierarchy={{
              type: "secondaryGrey",
              buttonText: "Evaluation",
            }}
          ></Button> )}

          {/* feedback and GCAT */}
          {/* <div className="fb-div">
            <Button
              size={"sm"}
              onClick={handleUploadFbfile}
              disable={false}
              icon={{ type: "leading", icon: <UploadSvg /> }}
              hierarchy={{
                type: gcatFileLoaded == "" ? "primary" : "secondaryColor",
                buttonText: gcatFileLoaded == "" ? "GCAT" : gcatFileLoaded.name,
              }}
            ></Button>
            <input
              className="fb-div-input"
              type="file"
              accept=".csv"
              // hidden
              name="gcat"
              onChange={handleUploadFbfile}
            />
          </div> */}

          {/* {gcatFileLoaded != "" && (
            <div
              className="addfbbtn"
              style={{ cursor: "pointer" }}
              onClick={() => handleFbUpload(gcatFileLoaded, "gcat")}
            >
              {" "}
              Upload
            </div>
          )} */}

          {/* <div className="fb-div">
            <Button
              size={"sm"}
              onClick={handleUploadFbfile}
              disable={false}
              icon={{ type: "leading", icon: <UploadSvg /> }}
              hierarchy={{
                type: fbFileLoaded == "" ? "primary" : "secondaryColor",
                buttonText: fbFileLoaded == "" ? "Feedback" : fbFileLoaded.name,
              }}
            ></Button>
            <input
              className="fb-div-input"
              type="file"
              accept=".csv"
              name="fb"
              // hidden
              onChange={handleUploadFbfile}
            />
          </div> */}

          {/* {fbFileLoaded != "" && (
            <div
              className="addfbbtn"
              style={{ cursor: "pointer" }}
              onClick={() => handleFbUpload(fbFileLoaded, "fb")}
            >
              {" "}
              Upload
            </div>
          )} */}
        </div>

        {/* <div className="fb-div"> */}
        {/* <Button
          size={"sm"}
          onClick={ViewResume}
          disable={false}
          // icon={{ type: "leading", icon: <UploadSvg /> }}
          hierarchy={{
            type: "primary",
            buttonText: "View Resume",
          }}
        ></Button> */}
        {/* </div> */}
      </div>

      <MaterialReactTable table={table} />
      {isCommonPopup && !addUser && (
        <div className="popup-overlay">
          <Popup />
        </div>
      )}
      {showReject && (
        <RejectBulk
          pendingTestLists={pendingList}
          setShowReject={setShowReject}
          setSelectedUsers={setSelectedUsers}
          testData={testData}
          selectedUsers={selectedUsers}
        />
      )}
      {showUserFormPopup && (
        <UserDetails
          showUserFormPopup={showUserFormPopup}
          setShowUserFormPopup={setShowUserFormPopup}
          newUserInput={newUserInput}
          setNewUserInput={setNewUserInput}
          addUser={addUser}
          setAddUser={setAddUser}
          refetch={refetch}
        />
      )}
    </>
  );
};

const queryClient = new QueryClient();

const UsersTableQueryProvider = (props) => (
  <QueryClientProvider client={queryClient}>
    <UsersTable {...props} />
  </QueryClientProvider>
);

export default UsersTableQueryProvider;
