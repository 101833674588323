import React from "react";
import constants from "../constants";

export default function TestSvgIcon(Props) {
  return Props.libType === constants.libTypeIds.CONTENT ? (
    <svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={
          Props.component === "learnerDashBoard"
            ? "#344054"
            : Props.component === "resume"
            ? Props.stroke ? Props.stroke : "#344054"
            : Props.disabled
            ? "#667085"
            : Props.active
            ? "#F96343"
            : "#EAECF0"
        }
        d="M11.6666 1.66667H4.99992C4.55789 1.66667 4.13397 1.84226 3.82141 2.15482C3.50885 2.46738 3.33325 2.89131 3.33325 3.33333V16.6667C3.33325 17.1087 3.50885 17.5326 3.82141 17.8452C4.13397 18.1577 4.55789 18.3333 4.99992 18.3333H14.9999C15.4419 18.3333 15.8659 18.1577 16.1784 17.8452C16.491 17.5326 16.6666 17.1087 16.6666 16.6667V6.66667M11.6666 1.66667L16.6666 6.66667M11.6666 1.66667V6.66667H16.6666M13.3333 10.8333H6.66659M13.3333 14.1667H6.66659M8.33325 7.5H6.66659"
      />
    </svg>
  ) : Props.libType === constants.libTypeIds.MCQ ? (
    <svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.66667 5H17.5M6.66667 10H17.5M6.66667 15H17.5M2.5 5H2.50833M2.5 10H2.50833M2.5 15H2.50833"
        stroke={
          Props.component === "learnerDashBoard"
            ? "#344054"
            : Props.disabled
            ? "#667085"
            : Props.active
            ? "#F96343"
            : "#EAECF0"
        }
      />
    </svg>
  ) : Props.libType === constants.libTypeIds.DESCRIPTIVE &&
    Props.autoEval === 0 ? (
    <svg
      width="17"
      height="20"
      fill="none"
      viewBox="0 0 17 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={
          Props.component === "learnerDashBoard"
            ? "#344054"
            : Props.disabled
            ? "#667085"
            : Props.active
            ? "#F96343"
            : "#EAECF0"
        }
        d="M14.6668 7.91667V5.66667C14.6668 4.26654 14.6668 3.56647 14.3943 3.0317C14.1547 2.56129 13.7722 2.17884 13.3018 1.93916C12.767 1.66667 12.067 1.66667 10.6668 1.66667H5.3335C3.93336 1.66667 3.2333 1.66667 2.69852 1.93916C2.22811 2.17884 1.84566 2.56129 1.60598 3.0317C1.3335 3.56647 1.3335 4.26654 1.3335 5.66667V14.3333C1.3335 15.7335 1.3335 16.4335 1.60598 16.9683C1.84566 17.4387 2.22811 17.8212 2.69852 18.0609C3.2333 18.3333 3.93336 18.3333 5.3335 18.3333H9.66683M9.66683 9.16667H4.66683M6.3335 12.5H4.66683M11.3335 5.83334H4.66683M11.7502 12.5019C11.897 12.0845 12.1868 11.7325 12.5683 11.5083C12.9497 11.2841 13.3982 11.2022 13.8343 11.277C14.2704 11.3518 14.666 11.5785 14.9509 11.917C15.2359 12.2555 15.3919 12.6839 15.3912 13.1264C15.3912 14.3755 13.5176 15 13.5176 15M13.5417 17.5H13.5501"
      />
    </svg>
  ) : Props.libType === constants.libTypeIds.DESCRIPTIVE &&
    Props.autoEval === 1 ? (
    <svg
      width="18"
      height="20"
      fill="none"
      viewBox="0 0 18 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.16671 15.4165C3.16671 15.8035 3.16671 15.997 3.1881 16.1595C3.33579 17.2813 4.21856 18.1641 5.34039 18.3118C5.50285 18.3332 5.69636 18.3332 6.08337 18.3332H12.5C13.9002 18.3332 14.6002 18.3332 15.135 18.0607C15.6054 17.821 15.9879 17.4386 16.2276 16.9681C16.5 16.4334 16.5 15.7333 16.5 14.3332V8.32336C16.5 7.71188 16.5 7.40614 16.431 7.11842C16.3697 6.86333 16.2687 6.61947 16.1316 6.39579C15.977 6.1435 15.7608 5.9273 15.3285 5.49493L12.6716 2.83808C12.2392 2.4057 12.023 2.18951 11.7708 2.0349C11.5471 1.89783 11.3032 1.79682 11.0481 1.73558C10.7604 1.6665 10.4547 1.6665 9.84319 1.6665H6.08337C5.69636 1.6665 5.50285 1.6665 5.34039 1.68789C4.21856 1.83558 3.33579 2.71835 3.1881 3.84019C3.16671 4.00265 3.16671 4.19615 3.16671 4.58317M6.50004 12.0832L8.58337 9.99984L6.50004 7.9165M3.16671 7.9165L1.08337 9.99984L3.16671 12.0832"
        stroke={
          Props.component === "learnerDashBoard"
            ? "#344054"
            : Props.disabled
            ? "#667085"
            : Props.active
            ? "#F96343"
            : "#EAECF0"
        }
      />
    </svg>
  ) : Props.libType === constants.libTypeIds.CODING_CHALLENGE ? (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={Props.component === "testDetail" ? "14" : "20"}
      height={Props.component === "testDetail" ? "12" : "18"}
      viewBox={Props.component === "testDetail" ? "0 0 14 12" : "0 0 20 18"}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={Props.component === "testDetail" ? "1.2" : "1.5"}
        d={
          Props.component === "testDetail"
            ? "M9.9165 8.91667L12.8332 6L9.9165 3.08333M4.08317 3.08333L1.1665 6L4.08317 8.91667M8.1665 0.75L5.83317 11.25"
            : "M14.1665 13.1667L18.3332 9L14.1665 4.83333M5.83317 4.83333L1.6665 9L5.83317 13.1667M11.6665 1.5L8.33317 16.5"
        }
        stroke={
          Props.component === "testDetail"
            ? "#E54B2A"
            : Props.component === "learnerDashBoard"
            ? "#344054"
            : Props.disabled
            ? "#667085"
            : Props.active
            ? "#F96343"
            : "#EAECF0"
        }
      />
    </svg>
  ) : Props.libType === constants.libTypeIds.ASSIGNMENT ? (
    <svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={
          Props.component === "learnerDashBoard"
            ? "#344054"
            : Props.disabled
            ? "#667085"
            : Props.active
            ? "#F96343"
            : "#EAECF0"
        }
        d="M16.6666 10.4167V5.66666C16.6666 4.26653 16.6666 3.56647 16.3941 3.03169C16.1544 2.56128 15.772 2.17883 15.3016 1.93915C14.7668 1.66666 14.0667 1.66666 12.6666 1.66666H7.33325C5.93312 1.66666 5.23306 1.66666 4.69828 1.93915C4.22787 2.17883 3.84542 2.56128 3.60574 3.03169C3.33325 3.56647 3.33325 4.26653 3.33325 5.66666V14.3333C3.33325 15.7335 3.33325 16.4335 3.60574 16.9683C3.84542 17.4387 4.22787 17.8212 4.69828 18.0608C5.23306 18.3333 5.93312 18.3333 7.33325 18.3333H9.99992M11.6666 9.16666H6.66659M8.33325 12.5H6.66659M13.3333 5.83333H6.66659M12.0833 15.8333L13.7499 17.5L17.4999 13.75"
      />
    </svg>
  ) : Props.libType === constants.libTypeIds.TEST ? (
    <svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#F96343"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M24.5 20.9999L23.3332 22.2763C22.7144 22.9531 21.8752 23.3333 21.0001 23.3333C20.1251 23.3333 19.2859 22.9531 18.6671 22.2763C18.0474 21.6009 17.2083 21.2217 16.3335 21.2217C15.4588 21.2217 14.6197 21.6009 14 22.2763M3.5 23.3333H5.45363C6.02435 23.3333 6.3097 23.3333 6.57824 23.2688C6.81632 23.2116 7.04393 23.1174 7.2527 22.9894C7.48817 22.8451 7.68995 22.6434 8.0935 22.2398L22.75 7.58327C23.7165 6.61677 23.7165 5.04977 22.75 4.08327C21.7835 3.11677 20.2165 3.11677 19.25 4.08327L4.59347 18.7398C4.18992 19.1434 3.98814 19.3451 3.84384 19.5806C3.71591 19.7894 3.62163 20.017 3.56447 20.2551C3.5 20.5236 3.5 20.809 3.5 21.3797V23.3333Z"
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={
          Props.component === "learnerDashBoard"
            ? "#344054"
            : Props.disabled
            ? "#667085"
            : Props.active
            ? "#F96343"
            : "#EAECF0"
        }
        d="M4.16683 15.4167C4.16683 15.8037 4.16683 15.9972 4.18822 16.1597C4.33591 17.2815 5.21868 18.1643 6.34051 18.312C6.50297 18.3333 6.69648 18.3333 7.0835 18.3333H13.5002C14.9003 18.3333 15.6004 18.3333 16.1351 18.0609C16.6055 17.8212 16.988 17.4387 17.2277 16.9683C17.5002 16.4335 17.5002 15.7335 17.5002 14.3333V8.32353C17.5002 7.71205 17.5002 7.40631 17.4311 7.11859C17.3698 6.8635 17.2688 6.61964 17.1318 6.39596C16.9772 6.14367 16.761 5.92747 16.3286 5.4951L13.6717 2.83824C13.2394 2.40587 13.0232 2.18968 12.7709 2.03507C12.5472 1.898 12.3033 1.79699 12.0482 1.73575C11.7605 1.66667 11.4548 1.66667 10.8433 1.66667H7.0835C6.69648 1.66667 6.50297 1.66667 6.34051 1.68806C5.21868 1.83575 4.33591 2.71852 4.18822 3.84036C4.16683 4.00281 4.16683 4.19632 4.16683 4.58334M7.50016 12.0833L9.5835 10L7.50016 7.91667M4.16683 7.91667L2.0835 10L4.16683 12.0833"
      />
    </svg>
  );
}
