import React, { useMemo, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { getDataFromStorage, getPreSignedUrl } from "../../util";
import DownloadSvgIcon from "../../Svg/DownloadSvgIcon";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const PdfViewer = ({ file, isDownloadable, name }) => {
  const [numPages, setNumPages] = useState(null);
console.log("name",name,name?.substring(0,name?.lastIndexOf(".")))
  const fileData = useMemo(() => ({ url: file }), [file]);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  console.log("file", fileData);

  const handleDownload = async (link) => {
    let getCurrentLink = await getPreSignedUrl(link);

    let anchorElement = document.createElement("a");

    anchorElement.download = name;
    anchorElement.href = getCurrentLink;
    anchorElement.setAttribute("target", "_blank");
    anchorElement.click();
  };

  return (
    <div className="pdfViewerContainer">
      {getDataFromStorage("role") === "learner" && (
        <div className="pdfViewerFileNameSec">
          <p className="pdfViewerFileName">
            {name?.substring(0,name?.lastIndexOf("."))}
          </p>
          {isDownloadable && (
            <DownloadSvgIcon onClick={() => handleDownload(file.data)} />
          )}
        </div>
      )}
      <Document
        file={fileData}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(err) => console.error(err)}
        onSourceError={(err) => console.error(err)}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page pageNumber={index + 1} key={`page_${index + 1}`} />
        ))}
      </Document>
    </div>
  );
};

export default PdfViewer;
