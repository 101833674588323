import React, { useCallback, useContext, useEffect, useState } from "react";
import Trainers from "../../../CommonComponents/Trainers";
import SettingsRightHeader from "./SettingsRightHeader";
import Button from "../../../Button";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import AlertContext from "../../../../context/AlertContext";
import { useParams } from "react-router-dom";
import constants from "../../../../constants";
import { emailBody } from "../../../../util";
import UserDetails from "../Users/UserDetails";
import Modal from "../../../CommonComponents/Modal";
import ModalBody from "../../../CommonComponents/ModalBody";
import { WarningFeatureIcon } from "./BatchTrainers";

const Clients = () => {
  const { id } = useParams();
  const axios = useAxiosPrivate();
  const { setIsLoaded, setShowNotify } = useContext(AlertContext);
  const [clients, setClients] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [userInput, setUserInput] = useState({
    emailBody: emailBody,
    batchid: parseInt(id),
    role: "hr",
  });
  const [openModal, setOpenModal] = useState(false);
  const [clientId, setClientId] = useState(null);

  const fetchClients = useCallback(async () => {
    try {
      setIsLoaded(true);
      let response = await axios.get(
        `node/admin/batch/${id}/users/${constants.Roles.hr}`,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (response.data.resultCode === 1000) {
        if (response.data.data !== null) {
          let res = response.data.data;
          setClients(res);
        }
      } else
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later or contact gradious team.",
        });
    } catch (error) {
      console.log("error in while fetch trainers", error);
      if (error.message.includes("403")) {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "You have been logged-out due to inactivity. Login again.",
          isUnAuth: true,
        });
      } else
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later or contact gradious team.",
        });
    } finally {
      setIsLoaded(false);
    }
  }, [axios, setShowNotify, id, setIsLoaded]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const onCloseModal = (event) => {
    event.preventDefault();
    setOpenModal(false);
    setClientId(null);
  };

  const removeClients = async (userId) => {
    if (userId) {
      setOpenModal(true);
      setClientId(userId);
    }
  };

  const onRemoveClients = async () => {
    setIsLoaded(true);
    try {
      let res = await axios.post(
        "node/admin/batch/users/status",
        JSON.stringify({
          userIds: [clientId],
          active: false,
          batchId: id,
          removeTrainer: true,
        }),
        { headers: { "Content-type": "application/json" } }
      );

      if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
        setShowNotify({
          show: true,
          title: "Info",
          msg: "The client removed from the batch",
        });
        setClients(res.data.data);
      } else {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: res.data.message,
        });
      }
    } catch (error) {
      setIsLoaded(false);
      if (error.message.includes("403")) {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "You have been logged-out due to inactivity. Login again.",
          isUnAuth: true,
        });
      } else
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later or contact gradious team.",
        });
    } finally {
      setIsLoaded(false);
    }
  };

  const onAddClient = (event) => {
    event.preventDefault();
    setShowForm(true);
    setUserInput({
      emailBody: emailBody,
      batchid: parseInt(id),
      role: "hr",
    });
  };

  return (
    <div className="clientsContainer batchTrainersContainer">
      <SettingsRightHeader
        title="Clients"
        titleIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M20 8V14M23 11H17M12.5 7C12.5 9.20914 10.7091 11 8.5 11C6.29086 11 4.5 9.20914 4.5 7C4.5 4.79086 6.29086 3 8.5 3C10.7091 3 12.5 4.79086 12.5 7Z"
              stroke="#344054"
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        button={
          <Button
            size="sm"
            hierarchy={{ type: "secondaryGrey", buttonText: "Add Client" }}
            icon={{
              icon: (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0001 4.16666V15.8333M4.16675 10H15.8334"
                    stroke="#344054"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ),
              type: "leading",
            }}
            onClick={onAddClient}
          />
        }
      />
      <div className="clientsSec batchTrainersSec">
        <Trainers
          trainerData={clients}
          icon={{
            icon: (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 5.00001H4.16667M4.16667 5.00001H17.5M4.16667 5.00001V16.6667C4.16667 17.1087 4.34226 17.5326 4.65482 17.8452C4.96738 18.1577 5.39131 18.3333 5.83333 18.3333H14.1667C14.6087 18.3333 15.0326 18.1577 15.3452 17.8452C15.6577 17.5326 15.8333 17.1087 15.8333 16.6667V5.00001H4.16667ZM6.66667 5.00001V3.33334C6.66667 2.89131 6.84226 2.46739 7.15482 2.15483C7.46738 1.84227 7.89131 1.66667 8.33333 1.66667H11.6667C12.1087 1.66667 12.5326 1.84227 12.8452 2.15483C13.1577 2.46739 13.3333 2.89131 13.3333 3.33334V5.00001"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
            onClick: removeClients,
          }}
        />
      </div>
      {showForm && (
        <UserDetails
          showUserFormPopup={showForm}
          newUserInput={userInput}
          setNewUserInput={setUserInput}
          setShowUserFormPopup={setShowForm}
          refetch={fetchClients}
        />
      )}
      {openModal && (
        <Modal isOpen={openModal} onClose={onCloseModal} showCloseIcon={false}>
          <ModalBody
            header={WarningFeatureIcon}
            content={
              <>
                <p className="contentTitle">Remove Client</p>
                <p className="content">
                  Are you sure you want to remove the client from the batch?
                </p>
              </>
            }
            footer={
              <>
                <Button
                  size="lg"
                  hierarchy={{ type: "secondaryGrey", buttonText: "Cancel" }}
                  onClick={onCloseModal}
                />
                <Button
                  size="lg"
                  hierarchy={{
                    type: "primary",
                    buttonText: "Remove",
                  }}
                  onClick={onRemoveClients}
                  destructive={true}
                />
              </>
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default Clients;
