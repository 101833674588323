import { useContext } from "react";
import constants from "../constants";
import { getDataFromStorage } from "../util";
import { useLocation } from "react-router-dom";
import useAxiosPrivate from "./useAxiosPrivate";
import AlertContext from "../context/AlertContext";

export default function HeaderHooks() {
  const axios = useAxiosPrivate();

  const { setNotificationPayload, status, isAdmin, setShowNotify } =
    useContext(AlertContext);

  const { pathname } = useLocation();

  const hideCompanyName = () => {
    if (
      (pathname === "/coding" &&
        (status === constants.PROGRESS_STATUS.EVALUATED ||
          status === constants.PROGRESS_STATUS.TO_BE_EVALUATED)) ||
      isAdmin
      // getDataFromStorage("role") !== constants.Roles.hr ||
      // getDataFromStorage("role") === constants.Roles.admin
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getLatestSessions = async (learnerid) => {
    let res = await axios.get("node/learner/activesession/" + learnerid, {
      headers: {
        "Content-type": "application/json",
      },
    });

    if (!res.data.data.hasOwnProperty("resolveddata")) {
      let jwtToken = res.data.data.jwtToken;
      let sessionName = res.data.data.sessions[0].sessionName;
      let sessionDate = res.data.data.sessions[0].sessionDate;
      let sessionTime = res.data.data.sessions[0].sessionTime;

      let notificationData = {
        data: {
          notification: {
            title: `Attendance:\n${sessionName}`,
            body: `Date: ${sessionDate}\nTime: ${sessionTime}\nPlease mark correctly as the coordinator will be checking it manually as well.`,
          },
          data: {
            jwtToken: jwtToken,
          },
        },
        open: true,
      };

      setNotificationPayload(notificationData);
    } else {
      setShowNotify({
        show: true,
        title: "Info",
        msg: res.data.data.resolveddata,
      });
    }
  };

  return {
    hideCompanyName,
    getLatestSessions,
  };
}
