import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import DownloadOptions from "./WidgetMenu/DownloadOptions";
import Loading from "../../../Notification-Loading/Loading/Loading";
import AlertContext from "../../../../context/AlertContext";
import constants from "../../../../constants";
import PaginationCustom from "./PaginationCustom";
import Tooltip from "@mui/material/Tooltip";
const Scores = (Props) => {
  const axios = useAxiosPrivate();
  const [page, setPage] = useState(1);
  const {downloadWidget,setDownloadWidet} = useContext(AlertContext)
  const [boardDescription, setBoardDescription] = useState(
    "Description for Scores"
  );
  const [showDownloadOptionsDropDown, setShowDownloadOptionsDropDown] =
    useState(false);
  const [widgetColumns, setWidgetColumns] = useState([]);
  const [widgetData, setWidgetData] = useState([]);
  const [totalDataCount, setDataTotalCount] = useState(0);
  const [orientation,setOrientation] = useState("landscape");
  const [orderby, setOrderby] = useState("ASC");
  const [sortby, setSortby] = useState("name");
  const [render, setRender] = useState(false);

  const getStyles = makeStyles({
    stickyCellname1: {
      maxWidth: "150px",
      minWidth: "150px",
      boxShadow: "5px 2px 5px grey",
      position: "sticky",
      left: 0,
      background: "#f9fafb",
      textOverflow: "ellipsis",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      overflow: "hidden",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
      color: "#344054",
      padding: "9px",
      boxShadow:"inset -1px 0 0 0 rgb(224, 224, 224),inset 0 1px 0 0 rgb(224, 224, 224)"
    },
    stickyCellemail1: {
      boxShadow: "5px 2px 5px grey",
      position: "sticky",
      left: `${widgetData[0]?.hasOwnProperty("name") ? "150px" : 0} `,
      maxWidth: "150px",
      minWidth: "150px",
      background: "#f9fafb",
      textOverflow: "ellipsis",
      overflow: "hidden",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
      color: "#344054",
      padding: "9px",
      boxShadow:"inset -1px 0 0 0 rgb(224, 224, 224),inset 0 1px 0 0 rgb(224, 224, 224)"
    },
    stickyCellbranch1: {
      boxShadow: "5px 2px 5px grey",
      position: "sticky",
      left: `${
        widgetData[0]?.hasOwnProperty("email") &&
        widgetData[0]?.hasOwnProperty("name")
          ? "300px"
          : "150px"
      } `,
      maxWidth: "150px",
      minWidth: "150px",
      background: "#f9fafb",
      textOverflow: "ellipsis",
      overflow: "hidden",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
      color: "#344054",
      padding: "9px",
      boxShadow:"inset -1px 0 0 0 rgb(224, 224, 224),inset 0 1px 0 0 rgb(224, 224, 224)"
    },
    stickyCellcollege1: {
      boxShadow: "5px 2px 5px grey",
      position: "sticky",
      left: `${
        widgetData[0]?.hasOwnProperty("branch") &&
        widgetData[0]?.hasOwnProperty("email") &&
        widgetData[0]?.hasOwnProperty("college")
          ? "450px"
          : widgetData[0]?.hasOwnProperty("email") &&
            widgetData[0]?.hasOwnProperty("name")
          ? "300px"
          : "150px"
      } `,
      maxWidth: "150px",
      minWidth: "150px",
      background: "#f9fafb",
      textOverflow: "ellipsis",
      overflow: "hidden",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
      color: "#344054",
      padding: "9px",
      boxShadow:"inset -1px 0 0 0 rgb(224, 224, 224),inset 0 1px 0 0 rgb(224, 224, 224)"
    },
    stickyCellname: {
      maxWidth: "150px",
      minWidth: "150px",
      boxShadow: "5px 2px 5px grey",
      position: "sticky",
      left: 0,
      background: "#ffffff",
      textOverflow: "ellipsis",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      overflow: "hidden",
      whiteSpace:"nowrap",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
      color: "#101828",
      padding: "9px",
      boxShadow: "inset -1px 0px 0px 0 rgb(224, 224, 224)"
    },
    
    stickyCellemail: {
      boxShadow: "5px 2px 5px grey",
      position: "sticky",
      left: `${widgetData[0]?.hasOwnProperty("name") ? "150px" : 0} `,
      maxWidth: "150px",
      minWidth: "150px",
      background: "#ffffff",
      textOverflow: "ellipsis",
      overflow: "hidden",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      color: "#667085",
       padding: "9px",
      boxShadow: "inset -1px 0px 0px 0 rgb(224, 224, 224)"
    },
    stickyCellbranch: {
      boxShadow: "5px 2px 5px grey",
      position: "sticky",
      left: `${
        widgetData[0]?.hasOwnProperty("email") &&
        widgetData[0]?.hasOwnProperty("name")
          ? "300px"
          : "150px"
      } `,
      maxWidth: "150px",
      minWidth: "150px",
      background: "#ffffff",
      textOverflow: "ellipsis",
      overflow: "hidden",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      color: "#667085",
       padding: "9px",
      boxShadow: "inset -1px 0px 0px 0 rgb(224, 224, 224)"
    },
    stickyCellcollege: {
      boxShadow: "5px 2px 5px grey",
      position: "sticky",
      left: `${
        widgetData[0]?.hasOwnProperty("branch") &&
        widgetData[0]?.hasOwnProperty("email") &&
        widgetData[0]?.hasOwnProperty("college")
          ? "450px"
          : widgetData[0]?.hasOwnProperty("email") &&
            widgetData[0]?.hasOwnProperty("name")
          ? "300px"
          : "150px"
      } `,
      maxWidth: "150px",
      minWidth: "150px",
      background: "#ffffff",
      textOverflow: "ellipsis",
      overflow: "hidden",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      color: "#667085",
       padding: "9px",
      boxShadow: "inset -1px 0px 0px 0 rgb(224, 224, 224)"
    },
    na1: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "150px",
      minWidth: "150px",
      textWrap: "nowrap",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
      color: "#344054",
      padding: "9px",
      boxShadow: "inset -1px 0 0 0 rgb(224, 224, 224),inset 0 1px 0 0 rgb(224, 224, 224)"
    },
    na:{
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "150px",
      minWidth: "150px",
      textWrap: "nowrap",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      color: "#667085",
      padding: "9px !important",
      boxShadow: "inset -1px 0px 0px 0 rgb(224, 224, 224)"

    }
  });
  const classes = getStyles();
  const getCol = (col) => {
    if (col == "Name") {
      return "name";
    } else if (col == "Email address") {
      return "email";
    } else if (col == "Branch") {
      return "branch";
    } else if (col == "College") {
      return "college";
    } else if (col == "Completed") {
      return "completedCount";
    } else if (col == "Pending") {
      return "pendingCount";
    } else if (col == "Overdue") {
      return "dueDateCount";
    } else if (col == "Selected range") {
      return "percentage_selected_duration";
    } else if (col == "From Start") {
      return "percentage_total_duration";
    } else return col;
  };

  const sortByColumn = (col) => {
    if (col == "Name" || col == "Branch" || col == "College") {
      let orderBy = "ASC";
      if (getCol(col) == sortby) {
        orderBy = orderby == "ASC" ? "DESC" : "ASC";
      }
      col == "Name"
        ? setSortby("name")
        : col == "Branch"
        ? setSortby("branch")
        : setSortby("college");

      setOrderby(orderBy);
    }
  };

  useEffect(() => {
    setRender(false);
    const responseOfWidget = async () => {
      let response = await axios.get(
        `node/admin/dashboard/${Props.dashboardId}/widget/${Props.data.wid}/report`,
        {
          params: {
            type: Props.data.type,
            offset: page,
            sortby,
            orderby
          },
          headers: {
            "Content-type": "application/json",
          },
        }
      );

      if (response?.data?.data != undefined) {
        setWidgetData(response?.data?.data?.data);
        setDataTotalCount(response?.data?.data?.learnersTotalCount);
        let typeOfOrientation = response?.data?.data?.orientation == constants.ORIENTATION.LANDSCAPE? "landscape" :"portrait";

        setOrientation(typeOfOrientation);
        const columns = [];

        let columnfromResponse = response?.data?.data?.data[0];

        if (columnfromResponse?.hasOwnProperty("name")) {
          columns.push("Name");
        }
        if (columnfromResponse?.hasOwnProperty("email")) {
          columns.push("Email address");
        }
        if (columnfromResponse?.hasOwnProperty("branch")) {
          columns.push("Branch");
        }
        if (columnfromResponse?.hasOwnProperty("college")) {
          columns.push("College");
        }

        // if (columnfromResponse?.hasOwnProperty("tests")) {
        let testNames = response?.data?.data?.headers;
        console.log("testnames", testNames);
        for (let i = 0; i < testNames.length; i++) {
          columns.push(testNames[i]);
        }
        // }


        setWidgetColumns(columns);

        setRender(true);
        if (Props.refresh) {
          Props.setRefresh(false);
        }
      }
    };

    responseOfWidget();
  }, [page, Props.data.wid, Props.refresh, sortby, orderby]);



  const handleNext = () =>{
    let totalPageCount = Math.ceil(totalDataCount / 10);
    setPage((prev) => Math.min(prev + 1, totalPageCount));
  }

  const handlePrevious = ()=>{
    setPage((prev) => Math.max(prev - 1, 1));
  }

  return  (
    <div className="scoresContainer">
      <div className="boardHeader">
        <div className="leaderBoardwidgetNamendDescription">
          <text id="leaderboardText1"> {Props.data.name}</text>
          {boardDescription && <text id="leaderboardText2"> {Props.data.description}</text>}
        </div>
        {downloadWidget == Props.data.wid &&  <span className="downloadSingleWidgetAlert">Download in Progress,Please Wait...</span>}
        <div className="downloadDropdown">
          {showDownloadOptionsDropDown && (
            <div
              onMouseLeave={() => {
                setShowDownloadOptionsDropDown(false);
              }}
            >
              <DownloadOptions
                name={Props?.data?.name}
                type={Props.data.type}
                dashboardId={Props.dashboardId}
                widgetId={Props.data.wid}
                orientation={orientation}
              />{" "}
            </div>
          )}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setShowDownloadOptionsDropDown(true)}
            className="boarddownloadicon"
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.6577 12.5V15.8333C17.6577 16.2754 17.4821 16.6993 17.1696 17.0118C16.857 17.3244 16.4331 17.5 15.991 17.5H4.32438C3.88235 17.5 3.45843 17.3244 3.14587 17.0118C2.83331 16.6993 2.65771 16.2754 2.65771 15.8333V12.5M5.99105 8.33333L10.1577 12.5M10.1577 12.5L14.3244 8.33333M10.1577 12.5V2.5"
                stroke="#344054"
                stroke-width="1.67"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <svg
              width="7"
              height="6"
              viewBox="0 0 7 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.78205 0.799874L4.04042 5.53542C3.8889 5.79714 3.81313 5.928 3.71429 5.97195C3.62807 6.01029 3.52964 6.01029 3.44342 5.97195C3.34458 5.928 3.26882 5.79714 3.1173 5.53542L0.375669 0.799876C0.2236 0.537211 0.147566 0.40588 0.158804 0.298091C0.168606 0.204075 0.217862 0.118642 0.294315 0.0630541C0.381968 -0.000677109 0.533722 -0.000677109 0.837229 -0.000677109L6.32049 -0.000677109C6.62399 -0.000677109 6.77575 -0.000677109 6.8634 0.0630541C6.93985 0.118642 6.98911 0.204075 6.99891 0.298091C7.01015 0.40588 6.93412 0.537211 6.78205 0.799874Z"
                fill="#667085"
              />
            </svg>
          </span>
          
        </div>
      </div>
      {!render ? (<div className="dummyLoad">
        <div className="loadinfileexp">
          <Loading/>
        </div>
      </div>) :
      <div className="tableContainer">
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ background: "#f9fafb" }}>
              <TableRow >
                {widgetColumns.map((column, ind) => (
                  <TableCell
                    key={ind}
                    title={column}
                    className={
                      column == "Name"
                        ? classes.stickyCellname1
                        : column == "Email address"
                        ? classes.stickyCellemail1
                        : column == "Branch"
                        ? classes.stickyCellbranch1
                        : column == "College"
                        ? classes.stickyCellcollege1
                        : classes.na1
                    }
                    onClick={() => {
                      sortByColumn(column);
                    }}

                    align={column=="Name" || column=="Branch" || column=="College" || column=="Email address" ? "left" :"center"}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {widgetData.map((row) => (
                <TableRow key={row.id} 
                >
                  {widgetColumns.map((column, colIndex) => (
                    <TableCell 
                    title={typeof row[column] === "object"
                    ? row[column].mark
                    : row[getCol(column)]}
                    className={
                      column == "Name"
                        ? classes.stickyCellname
                        : column == "Email address"
                        ? classes.stickyCellemail
                        : column == "Branch"
                        ? classes.stickyCellbranch
                        : column == "College"
                        ? classes.stickyCellcollege
                        
                        : classes.na
                    }
                    align={column=="Name" || column=="Branch" || column=="College" || column=="Email address" ? "left" :"center"}

                    key={colIndex}
                    >
                      {typeof row[column] === "object"
                        ? row[column].mark
                        : row[getCol(column)]}
                    </TableCell>
                  ))}
                 
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div> }

      <div className="boardFooter">
        {render && <PaginationCustom
          totalPages={Math.ceil(totalDataCount / 10)}
          currentPage={page}
          setCurrentPage={setPage}
          handleNext={handleNext}
          handlePrevious ={handlePrevious}
          />
        }
      </div>
    </div>
  );
};

export default Scores;
