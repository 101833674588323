import AlertContext from "../../../context/AlertContext";
import { SandpackFileExplorer } from "sandpack-file-explorer";
import React, { useContext, useEffect, useState } from "react";
import Loading from "../../Notification-Loading/Loading/Loading";

export default function CustomFileExplorer() {
  const {
    navigate,
    isFullScreensp,
    setIsFullScreensp,
    setIsAddSubmission,
    openSandpackEditor,
    setIsEditSubmission,
    setOpenSandpackEditor,
    isAdmin
  } = useContext(AlertContext);

  let [hasred, sethadred] = useState(false);

  useEffect(() => {
    if (!hasred) {
      setTimeout(() => {
        sethadred(true);
      }, 1000);
    }
  }, [hasred]);

  const handleBack = () => {
    if (isFullScreensp) {
      document.exitFullscreen();
      setIsFullScreensp(false);
    }

    setIsAddSubmission(false);
    setIsEditSubmission(false);

    if (!openSandpackEditor) {
      if(isAdmin){
        navigate("/assignment")
      }
      else{
      navigate("/home");
      }
    } else {
      setOpenSandpackEditor(false);
    }
  };

  return (
    <div className="fileexplorercontainer" style={{ background: "#151515" }}>
      <div className="backfromsandpack" title="back">
        <svg
          width="18"
          height="18"
          fill="none"
          viewBox="0 0 18 18"
          onClick={handleBack}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke="#EAECF0"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.25 13.5L6.75 9L11.25 4.5"
          />
        </svg>
      </div>

      <div
        style={{ background: "#151515" }}
        className="fileExplorercustomsandpack"
      >
        {hasred ? (
          <SandpackFileExplorer />
        ) : (
          <div className="loadinfileexp">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
}
