import React from "react";
import { getDataFromStorage } from "../util";
import SettingSvgIcon from "./SettingSvgIcon";
import HeaderHooks from "../Hooks/HeaderHooks";
import IconButton from "@mui/material/IconButton";
import NotificationSvgIcon from "./NotificationSvgIcon";

export default function HeaderRightNavSvgIcon() {
  const { getLatestSessions } = HeaderHooks();

  return (
    <div className="headerRightNavSvgIcon">
      {/* <IconButton className="settingSvgIcon">
        <SettingSvgIcon />
      </IconButton> */}
      <IconButton
        className="notificationSvgIcon"
        onClick={() => getLatestSessions(getDataFromStorage("learnerid"))}
      >
        <NotificationSvgIcon />
      </IconButton>
    </div>
  );
}
