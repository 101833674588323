import constants from "../../constants";
import { useContext, useState } from "react";
import { getPreSignedUrl } from "../../util";
import useAxiosPrivate from "../useAxiosPrivate";
import AlertContext from "../../context/AlertContext";
import useUploadLargeFileToS3 from "../useUploadLargeFileToS3";
import useCommonFunctionHooks from "../useCommonFunctionHooks";
import useApicall from "../useApiCall";

export default function CodingHeaderAdminSideHooks() {
  const {
    setMsg,
    setShow,
    profile,
    setTitle,
    learnerId,
    setProfile,
    setComment,
    showReject,
    setIsLoaded,
    codeDetails,
    setIsUnAuth,
    showApprove,
    setShowReject,
    setShowApprove,
    showBreadCrumbsData,
    isAdminSubmitTimeout,
  } = useContext(AlertContext);

  const { getAPIurl } = useApicall();
  const axios = useAxiosPrivate();
  const { deleteS3Objects } = useUploadLargeFileToS3();
  const { getHeaderDetailsForAxios, getCatchBlockDetails } =
    useCommonFunctionHooks();

  const [showProfile, setShowProfile] = useState(false);
  const [showProctoring, setShowProctoring] = useState(false);

  const [deleteImages, setDeleteImages] = useState({
    withKey: [],
    withOutKey: [],
  });

  const handleProfileShow = () => setShowProfile(true);
  const handleProfileClose = () => setShowProfile(false);

  const handleProctoringClosePopup = () => {
    setShowProctoring(false);
    setProfile([]);
  };

  const handleApprove = () => {
    setComment("");
    setShowReject(false);
    setShowApprove(!showApprove);
  };

  const handleReject = () => {
    setShowApprove(false);
    setShowReject(!showReject);
  };

  const showSubmitButton = () => {
    if (
      codeDetails.status === constants.QUESTION_STATUS.RESUBMIT ||
      codeDetails.status === constants.QUESTION_STATUS.CONTINUE ||
      codeDetails.status === constants.QUESTION_STATUS.IN_PROGRESS
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getPayLoadForProctoring = () => {
    let payLoad = {
      lpid: showBreadCrumbsData.lpId,
      libtypeid: showBreadCrumbsData.type,
      userid: showBreadCrumbsData.learnerId,
    };

    if (showBreadCrumbsData.type === constants.libTypeIds.CODING_CHALLENGE) {
      payLoad.testid = showBreadCrumbsData.testId;
      payLoad.qnid = showBreadCrumbsData.questionId;
      payLoad.qnno = showBreadCrumbsData.questionNo;
      payLoad.testconfigid = showBreadCrumbsData.testConfigId;
    } else {
      payLoad.qnid = showBreadCrumbsData.testId;
    }

    return JSON.stringify(payLoad);
  };

  const handleShowProctoring = () => {
    setShowProctoring(true);
    setIsLoaded(true);
    axios
      .post(
        "node/admin/userproctoring",
        getPayLoadForProctoring(),
        getHeaderDetailsForAxios()
      )
      .then(async (res) => {
        setIsLoaded(false);

        if (res.data === "") {
          setShow(true);
          setTitle("Info");
          setMsg("No data available");
        } else {
          let data = res.data;

          data.registerUrl = await getPreSignedUrl(
            `Proctoring/Registration/${learnerId}/${learnerId}.png`
          );
          if (data.hasOwnProperty("proctoringdatas")) {
            for (let i = 0; i < data.proctoringdatas.length; i++) {
              let filePath = `Proctoring/${showBreadCrumbsData.testId}/`;
              if (
                parseInt(showBreadCrumbsData.type) ===
                constants.libTypeIds.CODING_CHALLENGE
              )
                filePath += `${showBreadCrumbsData.questionId}/${learnerId}/`;
              else filePath += `${learnerId}/`;

              filePath += data.proctoringdatas[i].imagename;

              // let timeStamp=new Date(fileNameDetails[1])
              // fileNameDetails[1]=timeStamp
              // fileNameDetails.join("_");
              // filePath+=fileNameDetails;

              let url = await getPreSignedUrl(filePath);

              data.proctoringdatas[i].imagePresignedUrl = url;

              // getCount++;
            }
          }
          res.data = data;
          // if (getCount === data.proctoringdatas.length)

          setProfile(res.data);

          // let switches = 0;
          // let phonedetect = [];
          // let unmatchedFace = 0;
          // let faceorientation = 0;
          // let externaldevice = [];
          // let multifacedetected = 0;

          // for (let i = 0; i < res.data.proctoringdatas.length; i++) {
          //   if (res.data.proctoringdatas[i].facedetected === 0) {
          //     unmatchedFace++;
          //   }

          //   if (res.data.proctoringdatas[i].multifacedetected === 1) {
          //     multifacedetected++;
          //   }

          //   if (res.data.proctoringdatas[i].externaldevice !== "") {
          //     externaldevice.push(res.data.proctoringdatas[i].externaldevice);
          //   } else {
          //     externaldevice = "  No Data";
          //   }

          //   if (res.data.proctoringdatas[i].phonedetected !== null) {
          //     phonedetect.push(res.data.proctoringdatas[i].phonedetected);
          //   } else {
          //     phonedetect = "  No Data";
          //   }

          //   if (res.data.proctoringdatas[i].switched === 1) {
          //     switches++;
          //   }

          //   if (res.data.proctoringdatas[i].faceorientationnormal === 0) {
          //     faceorientation++;
          //   }
          // }

          // setCredibilityScore({
          //   switched: switches,
          //   facedetection: unmatchedFace,
          //   faceinvisible: faceorientation,
          //   multifacedetection: multifacedetected,
          // });

          // setProctoringData({
          //   switched: switches,
          //   duration: switches,
          //   phonedetec: phonedetect,
          //   externaldev: externaldevice,
          //   facedetection: unmatchedFace,
          // });
        }
      })
      .catch((err) => {
        getCatchBlockDetails(err, "proctor");
      })
      .finally(() => setIsLoaded(false));
  };

  const handleSelectImage = (checked, imageName) => {
    let details = JSON.parse(JSON.stringify(profile));
    let imageNames = JSON.parse(JSON.stringify(deleteImages));

    if (checked) {
      if (imageName === "all") {
        imageNames.withKey = details.proctoringdatas.map((detail) => {
          return {
            Key: `Proctoring/${codeDetails.testid}/${codeDetails.qnid}/${learnerId}/${detail.imagename}`,
          };
        });
        imageNames.withOutKey = details.proctoringdatas.map((detail) => {
          return detail.imagename;
        });
        details.proctoringdatas = details.proctoringdatas.map((detail) => {
          detail.isChecked = true;
          return detail;
        });
        setProfile(details);
        setDeleteImages(imageNames);
      } else {
        details.proctoringdatas = details.proctoringdatas.map((detail) => {
          if (detail.imagename === imageName) detail.isChecked = true;

          return detail;
        });
        imageNames.withKey.push({
          Key: `Proctoring/${codeDetails.testid}/${codeDetails.qnid}/${learnerId}/${imageName}`,
        });
        imageNames.withOutKey.push(imageName);
        setDeleteImages(imageNames);
        setProfile(details);
      }
    } else {
      if (imageName === "all") {
        imageNames.withKey = [];
        imageNames.withOutKey = [];
        details.proctoringdatas = details.proctoringdatas.map((detail) => {
          detail.isChecked = false;

          return detail;
        });
      } else {
        imageNames.withKey = imageNames.withKey.filter(
          (imgName) => !imgName.Key.includes(imageName)
        );
        imageNames.withOutKey = imageNames.withOutKey.filter(
          (imgName) => imgName !== imageName
        );
        details.proctoringdatas = details.proctoringdatas.map((detail) => {
          if (detail.imagename === imageName) detail.isChecked = false;

          return detail;
        });
      }

      setProfile(details);
      setDeleteImages(imageNames);
    }
  };

  const handleDeleteImg = async (event) => {
    event.preventDefault();
    try {
      setIsLoaded(true);
      let res = await axios.post(
        "node/admin/userproctoring/image",
        { imageNames: deleteImages.withOutKey },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
        let result = await deleteS3Objects(deleteImages);

        if (result === "Success") {
          setShow(true);
          setTitle("Success");
          setMsg(res.data.msg);
          let details = JSON.parse(JSON.stringify(profile));

          details.proctoringdatas = details.proctoringdatas?.filter(
            (detail) => !deleteImages.withOutKey.includes(detail.imagename)
          );
          setProfile(details);
          setDeleteImages({ withKey: [], withOutKey: [] });
        }
      } else if (
        res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR
      ) {
        setShow(true);
        setTitle("Error !");
        setMsg(res.data.msg);
      } else {
        setShow(true);
        setTitle("Error");
        setMsg("Something went wrong. Please try again later.");
      }
    } catch (err) {
      setShow(true);
      setTitle("Error");
      if (err.message.includes("403")) {
        setIsUnAuth(true);
        setMsg("You have been logged-out due to inactivity. Login again.");
      } else setMsg("Something went wrong. Please try again later.");
    } finally {
      setIsLoaded(false);
    }
    // let result = await deleteS3Objects(deleteImages);

    // if (result === "Success") {
    //   let details = JSON.parse(JSON.stringify(profile));

    //   details.proctoringdatas = details.proctoringdatas?.filter(
    //     (detail) => !deleteImages.withOutKey.includes(detail.imagename)
    //   );
    //   setProfile(details);
    //   setDeleteImages({ withKey: [], withOutKey: [] });
    // }
  };

  const adminSubmitTest = () => {
    setIsLoaded(true);

    let url = getAPIurl({method: "post",language: codeDetails.langname.toLowerCase(), api:"submitcode"})
    // let url =
    //   parseInt(codeDetails.langid) === constants.LANG_ID.Javascript
    //     ? "node/admin/cc/submit"
    //     : parseInt(codeDetails.langid) === constants.LANG_ID.C
    //       ? "node/admin/c/submit"
    //       : parseInt(codeDetails.langid) === constants.LANG_ID.Cpp
    //         ? "node/admin/cpp/submit"
    //         : parseInt(codeDetails.langid) === constants.LANG_ID.Python
    //           ? "node/admin/python/submit"
    //           : "node/admin/java/submit";
    axios
      .post(
        url,
        JSON.stringify({
          learnerid: learnerId,
          qnid: codeDetails.qnid,
          langid: codeDetails.langid,
          testid: codeDetails.testid,
          timeleft: codeDetails.timeleft,
          lpconfigid: codeDetails.lpconfigid,
          testConfig: codeDetails.testconfigid,
          timedout: isAdminSubmitTimeout.current,
          proctored: codeDetails.proctored === 1 ? true : false,
        }),
        {
          timeout: 30000,
          mode: "no-cors",
          headers: {
            "Content-type": "application/json",
            Authorization: localStorage.getItem("tokenKey"),
          },
        }
      )
      .then((res) => {
        setIsLoaded(false);
        setShow(true);
        setTitle("Info");
        setMsg("Submission successfull!");
      })
      .catch((error) => {
        setIsLoaded(false);
        setShow(true);
        setTitle("Error");

        if (
          error.message === "timeout of 30000ms exceeded" ||
          error.message.includes("504")
        ) {
          setMsg(
            "Something went wrong… program is taking too long to finish. Please, try again"
          );
        } else if (error.message.includes("403")) {
          setMsg("You have been logged-out due to inactivity. Login again.");
          setIsUnAuth(true);
        } else {
          setMsg(error.message);
        }
      });
  };

  return {
    showProfile,
    deleteImages,
    handleReject,
    handleApprove,
    showProctoring,
    handleDeleteImg,
    setDeleteImages,
    adminSubmitTest,
    showSubmitButton,
    handleProfileShow,
    handleSelectImage,
    setShowProctoring,
    handleProfileClose,
    handleShowProctoring,
    handleProctoringClosePopup,
  };
}
