import Box from "@mui/material/Box";
import constants from "../../constants";
import Select from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import { FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import BookmarkSvg from "../../Svg/BookmarkSvg";
import ListViewSvg from "../../Svg/ListViewSvg";
import BackSvgIcon from "../../Svg/BackSvgIcon";
import BackdropCounter from "./BackdropCounter";
import { Editor } from "@tinymce/tinymce-react";
import GridViewSvg from "../../Svg/GridViewSvg";
import RightSvgIcon from "../../Svg/RightSvgIcon";
import IconButton from "@mui/material/IconButton";
import Checkbox from "../CommonComponents/Checkbox";
import BookmarkedSvg from "../../Svg/BookmarkedSvg";
import AlertContext from "../../context/AlertContext";
import WobackCloseSvg from "../../Svg/WobackCloseSvg";
import McqAndDescHooks from "../../Hooks/McqAndDescHooks";
import React, { useContext, useEffect, useRef } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import McqHooks from "../../Hooks/McqAndDescPage/McqHooks";
import ButtonMedium from "../Buttons/ButtonMedium/ButtonMedium";
import { getDataFromStorage, getPreSignedUrl } from "../../util";

// import Slider from "@mui/material/Slider";

const parse = require("html-react-parser");

const PrettoSlider = styled(Slider)({
  color: "#F55533",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#EAECF0",
  },
  "& .MuiSlider-thumb": {
    width: 24,
    height: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    width: 32,
    padding: 0,
    height: 32,
    fontSize: 12,
    lineHeight: 1.2,
    background: "unset",
    backgroundColor: "#101828",
    borderRadius: "50% 50% 50% 0",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

export default function CommonTestMainPage(Props) {
  const {
    isAdmin,
    navigate,
    tsCoBkup,
    setLpData,
    descScores,
    showAnswer,
    setTsCoBkup,
    setDescScores,
    showSaqLaqAns,
    setShowSaqLaqAns,
    backupTestData,

    // videoQn

    videoQnNo,
    showTranscript,
    setShowTranscript,
    showTranscriptFunc,
  } = useContext(AlertContext);

  const {
    ansStats,
    mulRevch,
    handleCPC,
    giveZeros,
    setFilSec,
    setSRender,
    setEvalComp,
    setMulRevch,
    scoreErrors,
    setGiveZeros,
    errorMessage,
    setScoreErrors,
    getSectionTotal,
    setErrorMessage,
    setHasScoreErrors,
    questionIdOffError,
    handleScoreKeyDown,
    setQuestionIdOffError,
  } = McqHooks();

  const { calcStats, questionUpdate } = McqAndDescHooks();

  /* for vdo qn */
  const textRef = useRef(null);
  
  /* for tiny mce error */

  const editorRef = useRef(null);

  useEffect(() => {
    return () => {
      if (editorRef.current) {
        // Clean up the editor instance

        editorRef.current.destroy();
      }
    };
  }, []);

  /* move to previous question */

  const traversePrevious = () => {
    let tempQueInd = Props.queInd;
    let tempSecInd = Props.secInd;

    if (
      Props.comingObj.sections[tempSecInd].questions[tempQueInd].answered === -1
    ) {
      Props.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "not";
      Props.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "not";
    }

    if (
      Props.comingObj.sections[tempSecInd].questions[tempQueInd].answered === 0
    ) {
      Props.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "completed";
      Props.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "completed";
    }

    if (Props.queLen > 1 && Props.queInd > 0) {
      tempQueInd--;

      Props.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        Props.comingObj.sections[tempSecInd].questions[tempQueInd].previsited;
      Props.setQueInd(Props.queInd - 1);
      Props.setNextButton(true);
    } else {
      tempSecInd--;
      Props.setSecInd(Props.secInd - 1);
      Props.setNextButton(true);
      tempQueInd = Props.comingObj.sections[tempSecInd].questions.length - 1;

      Props.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        Props.comingObj.sections[tempSecInd].questions[tempQueInd].previsited;
      Props.setQueInd(tempQueInd);
    }

    if (tempSecInd === 0 && tempQueInd === 0) {
      Props.setPrevButton(false);
    }

    questionUpdate(tempSecInd, tempQueInd, Props.comingObj);

    if (Props.status === constants.PROGRESS_STATUS.EVALUATED) {
      Props.setFilters(null);
    }
  };

  /* move to next question */

  const traverseNext = (key) => {
    let tempQueInd = Props.queInd;
    let tempSecInd = Props.secInd;

    if (
      Props.comingObj.sections[tempSecInd].questions[tempQueInd].answered === -1
    ) {
      Props.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "not";
      Props.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "not";
    }

    if (
      Props.comingObj.sections[tempSecInd].questions[tempQueInd].answered === 0
    ) {
      Props.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "completed";
      Props.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "completed";
    }

    if (
      Props.comingObj.sections[tempSecInd].questions.length ===
        tempQueInd + 1 &&
      Props.comingObj.sections?.length >= tempSecInd + 1
    ) {
      tempSecInd++;
      tempQueInd = 0;

      Props.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        Props.comingObj.sections[tempSecInd].questions[tempQueInd].previsited;

      questionUpdate(tempSecInd, tempQueInd, Props.comingObj);
      Props.setPrevButton(true);
    } else {
      tempQueInd++;

      Props.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        Props.comingObj.sections[tempSecInd].questions[tempQueInd].previsited;

      questionUpdate(tempSecInd, tempQueInd, Props.comingObj);
      Props.setPrevButton(true);
    }

    if (
      Props.comingObj.sections?.length === tempSecInd + 1 &&
      Props.comingObj.sections[tempSecInd].questions.length === tempQueInd + 1
    ) {
      Props.setPrevButton(true);
      Props.setNextButton(false);
    }

    if (Props.status === constants.PROGRESS_STATUS.EVALUATED) {
      Props.setFilters(null);
    }

    Props.setSecInd(tempSecInd);
    Props.setQueInd(tempQueInd);
  };

  /* collect score */

  const collectScores = (qid, scor, maxscore, e) => {
    if (isNaN(scor) === false) {
      let score = Number(scor);
      let tempScores = descScores;
      let tScore = Props.currentScore;

      tempScores = { ...tempScores, [qid]: score };

      let tempErrors = scoreErrors;

      if (score > maxscore || score === "" || score < 0) {
        if (Object.keys(tempErrors).length > 0) {
          setHasScoreErrors(true);
        } else {
          setHasScoreErrors(false);
        }

        if (score === "") {
          if (Object.keys(tempScores).map(Number)?.includes(qid)) {
            tScore = tScore - tsCoBkup[qid];

            if (ansStats.evalids?.includes(parseInt(qid))) {
              tScore = tScore + ansStats.idscore[qid];
            }
          } else {
            if (ansStats.evalids?.includes(parseInt(qid))) {
              tScore = tScore + ansStats.idscore[qid];
            }
          }

          setDescScores(tempScores);
          delete tempScores[qid];

          tempErrors = { ...tempErrors, [qid]: true };
          setScoreErrors(tempErrors);
        }

        if (score > maxscore) {
          tempScores = { ...tempScores, [qid]: maxscore };
          setDescScores(tempScores);

          if (Object.keys(tsCoBkup).map(Number)?.includes(qid)) {
            tScore = tScore - tsCoBkup[qid];
          } else if (ansStats.evalids?.includes(Number(qid))) {
            tScore = tScore - ansStats.idscore[qid];
          }

          tScore = tScore + maxscore;
          setDescScores(tempScores);
        }

        if (score < 0) {
          tempScores = { ...tempScores, [qid]: 0 };
          setDescScores(tempScores);
        }
      } else {
        if (tempErrors.hasOwnProperty(qid)) {
          delete tempErrors[qid];
        }

        if (Object.keys(tempScores).map(Number)?.includes(qid)) {
          if (Object.keys(tsCoBkup).map(Number)?.includes(qid)) {
            tScore = tScore - tsCoBkup[qid];
          } else if (ansStats?.evalids?.includes(Number(qid))) {
            tScore = tScore - ansStats.idscore[qid];
          }

          tScore = tScore + score;
        } else {
          if (ansStats.evalids?.includes(parseInt(qid))) {
            tScore = tScore - ansStats.idscore[qid];
            tScore = tScore + score;
          } else {
            tScore = tScore + score;
          }
        }

        setDescScores(tempScores);
      }

      Props.setCurrentScore(tScore);
      setEvalComp(true);

      for (const property in tempScores) {
        if (tempScores[property] == null) {
          setEvalComp(false);
        }
      }

      setTsCoBkup(tempScores);

      for (let i = 0; i < Props.comingObj.sections?.length; i++) {
        for (let j = 0; j < Props.comingObj.sections[i].questions.length; j++) {
          if (
            Props.comingObj.sections[i].questions[j].answer === "" &&
            Props.comingObj.sections[i].questions[j].id === qid
          ) {
            setGiveZeros(false);
            break;
          }
        }
      }
    }
  };

  /* Collect ans and showing error msg */

  const collectAnswers = (
    queid,
    ansid,
    libtypeid,
    secindex,
    queindex,
    datatype
  ) => {
    if (datatype === 1) {
      if (/^[\d.\s\n]*$/.test(ansid)) {
        setErrorMessage(false);
      } else {
        setErrorMessage(true);
        setQuestionIdOffError(queid);
      }
    }

    let tempData = Props.comingObj;

    /* need to see secindex */

    if (libtypeid === constants.libTypeIds.MCQ_SINGLE) {
      let temp = Props.answers;

      temp = { ...temp, [queid]: ansid };
      Props.setAnswers(temp);

      if (Props.questionContainerSingle) {
        Props.queObj.visited = "completed";
        Props.queObj.previsited = "completed";
        Props.queObj.answered = 0;
      } else {
        tempData.sections[Props.secInd].questions[queindex].answered = 0;
        tempData.sections[Props.secInd].questions[queindex].visited =
          "completed";
        tempData.sections[Props.secInd].questions[queindex].previsited =
          "completed";
      }
    }

    if (libtypeid === constants.libTypeIds.MCQ_MULTI) {
      let temp = Props.answers;
      let checkedAnswers = [];

      if (temp[queid] !== "") {
        let trimmedAnswer =
          temp[queid]?.length > 0
            ? temp[queid][0] === ","
              ? temp[queid].substring(1)
              : temp[queid]
            : "";

        temp[queid] = trimmedAnswer;
        checkedAnswers = temp[queid].split(",");

        const index = checkedAnswers.indexOf(ansid);

        if (index > -1) {
          // only splice array when item is found

          checkedAnswers.splice(index, 1); // 2nd parameter means remove one item only
        } else {
          checkedAnswers.push(ansid);
        }
      } else {
        checkedAnswers.push(ansid);
      }

      if (checkedAnswers.length === 0) {
        temp = { ...temp, [queid]: "" };
        Props.setAnswers(temp);

        if (Props.questionContainerSingle) {
          Props.queObj.visited = "seen";
          Props.queObj.previsited = "seen";
          Props.queObj.answered = -1;
        } else {
          tempData.sections[Props.secInd].questions[queindex].visited = "seen";
          tempData.sections[Props.secInd].questions[queindex].previsited =
            "seen";
          Props.queObj.answered = -1;
        }
      } else {
        temp = { ...temp, [queid]: checkedAnswers.toString() };
        Props.setAnswers(temp);

        if (Props.questionContainerSingle) {
          Props.queObj.visited = "completed";
          Props.queObj.previsited = "completed";
          Props.queObj.answered = 0;
        } else {
          tempData.sections[Props.secInd].questions[queindex].visited =
            "completed";
          tempData.sections[Props.secInd].questions[queindex].previsited =
            "completed";
          tempData.sections[Props.secInd].questions[queindex].answered = 0;
        }
      }
    }

    if (
      libtypeid === constants.libTypeIds.SAQ ||
      libtypeid === constants.libTypeIds.LAQ
    ) {
      let temp = Props.answers;

      temp = { ...temp, [queid]: ansid };
      Props.setAnswers(temp);

      if (ansid.length === 0) {
        if (Props.questionContainerSingle) {
          Props.queObj.visited = "not";
          Props.queObj.previsited = "not";
          Props.queObj.answered = -1;
        } else {
          tempData.sections[Props.secInd].questions[queindex].visited = "not";
          tempData.sections[Props.secInd].questions[queindex].previsited =
            "not";
          tempData.sections[Props.secInd].questions[queindex].answered = -1;
        }
      } else {
        if (Props.questionContainerSingle) {
          Props.queObj.visited = "completed";
          Props.queObj.previsited = "completed";
          Props.queObj.answered = 0;
        } else {
          tempData.sections[Props.secInd].questions[queindex].visited =
            "completed";
          tempData.sections[Props.secInd].questions[queindex].previsited =
            "completed";
          tempData.sections[Props.secInd].questions[queindex].answered = 0;
        }
      }

      Props.updateComingObj(tempData);
    }
  };

  /* Book mark questions */

  const reviewLater = (from, current, obj) => {
    let tempPropObj = Props.comingObj;
    let temp = obj;

    if (current === "review") {
      Props.setShowReview(false);
      tempPropObj.sections[Props.secInd].questions[
        from === "single" ? Props.queInd : obj
      ].visited =
        tempPropObj.sections[Props.secInd].questions[
          from === "single" ? Props.queInd : obj
        ].previsited;
      tempPropObj.sections[Props.secInd].questions[
        from === "single" ? Props.queInd : obj
      ].review = -1;
    } else {
      Props.setShowReview(true);
      tempPropObj.sections[Props.secInd].questions[
        from === "single" ? Props.queInd : obj
      ].visited = "review";
      tempPropObj.sections[Props.secInd].questions[
        from === "single" ? Props.queInd : obj
      ].review = 0;
    }

    if (from === "single") {
      questionUpdate(Props.secInd, Props.queInd, tempPropObj);
      Props.setQueObj(temp);
      Props.questionObjUpdate(Props.queObj);
    }

    if (from === "multi") {
      setSRender(obj);
      questionUpdate(Props.secInd, Props.queInd, tempPropObj);
      setMulRevch(!mulRevch);
    }

    Props.updateComingObj(tempPropObj);
  };

  /* change section */

  const traverseSection = (button) => {
    let tempSecInd = Props.secInd;

    if (button === "next") {
      if (Props.comingObj.sections?.length > tempSecInd + 1) {
        tempSecInd++;
      }

      Props.setSecObj(Props.comingObj.sections[tempSecInd]);
      Props.setSecIndex(tempSecInd);
      Props.setSelSec(tempSecInd);
      Props.setSecInd(tempSecInd);
      Props.calcStats(Props.comingObj, [tempSecInd]);
    } else {
      if (tempSecInd + 1 > 1) {
        tempSecInd--;
      }

      Props.setSecObj(Props.comingObj.sections[tempSecInd]);
      Props.setSecIndex(tempSecInd);
      Props.setSelSec(tempSecInd);
      Props.setSecInd(tempSecInd);
      Props.calcStats(Props.comingObj, [tempSecInd]);
    }

    Props.setSectionObj(Props.comingObj.sections[tempSecInd]);
    Props.setSecInd(tempSecInd);
    Props.setSecIndex(tempSecInd);

    if (Props.comingObj.sections?.length === tempSecInd + 1) {
      Props.setNextSec(false);
      Props.setPrevSec(true);

      return;
    } else if (tempSecInd + 1 === 1) {
      Props.setNextSec(true);
      Props.setPrevSec(false);

      return;
    } else {
      Props.setNextSec(true);
      Props.setPrevSec(true);

      return;
    }
  };

  /* change section  */

  const handleSecChange = (event) => {
    let newEve;

    setFilSec(event.target.value);

    if (event.target.value === "all") {
      newEve = 0;
      Props.setPrevSec(false);
      Props.setNextSec(true);
    } else {
      newEve = event.target.value;
    }

    Props.setSelSec(event.target.value);
    Props.setSectionObj(Props.comingObj.sections[newEve]);

    if (event.target.value === 0) {
      if (Props.comingObj.sections.length === 1) {
        Props.setPrevSec(false);
        Props.setNextSec(false);
      } else {
        Props.setPrevSec(false);
        Props.setNextSec(true);
      }
    } else if (event.target.value === Props.comingObj.sections?.length - 1) {
      Props.setPrevSec(true);
      Props.setNextSec(false);
    } else {
      if (Props.comingObj.sections.length === 1) {
        Props.setPrevSec(false);
        Props.setNextSec(false);
      } else {
        Props.setPrevSec(true);
        Props.setNextSec(true);
      }
    }

    calcStats(Props.comingObj, [event.target.value]);

    if (Props.status === constants.PROGRESS_STATUS.EVALUATED) {
      Props.setFilters(null);
    }
  };

  const bacttoHomepage = () => {
    isAdmin
      ? navigate(`batches/${localStorage.getItem('abatchid')}/report`)
      : backupTestData.mockuse
      ? navigate("/mock/results")
      : navigate("/home");

      if(showTranscript) setShowTranscript(!showTranscript);
      if(showSaqLaqAns) setShowSaqLaqAns(!showSaqLaqAns);

    if (backupTestData.testmode === constants.MODE.PRACTICE_MODE) {
      setLpData({});
    }
  };

  /* each qn timelimit */

  useEffect(() => {
    if (!Props.showtransition) {
      const handleqnTimeLimit = setInterval(() => {
        if (Props.vdoQnTimelimit === 0) {
          // handleSubmitLearnerCodeEvaluation(false, true);

          Props.handlesingleVdoQnSubmit();
          clearInterval(handleqnTimeLimit);
        } else {
          Props.setVdoQnTimelimit((seconds) => seconds - 1);
        }
      }, 1000);

      return () => {
        clearInterval(handleqnTimeLimit);
      };
    }
  }, [Props.vdoQnTimelimit, Props.showtransition]);

  /* dynamic font change */
  useEffect(()=>{
    if (Props.showtransition) {
      const element = textRef.current;
    const container = element?.parentElement;
    const containerWidth = container?.clientWidth;
    const minFontSize = 18;
    const maxFontSize = 50;
    const step = 1.5;

    if (element && containerWidth) {
      let currentFontSize = maxFontSize;
      element.style.fontSize = `${currentFontSize}px`;

      // Reduce font size until the text fits within the container
      while (element.scrollWidth > containerWidth && currentFontSize > minFontSize) {
        currentFontSize -= step;
        element.style.fontSize = `${currentFontSize}px`;
      }

      var height = element.scrollHeight;
      while (height >= 500 && currentFontSize > minFontSize){
        currentFontSize -= step;
        height -= 30;
        element.style.fontSize = `${currentFontSize}px`;
      }

      // Ensure the font size is not less than the minimum font size
      if (currentFontSize < minFontSize) {
        currentFontSize = minFontSize;
      }
      
    }
    }
  },[Props.showtransition])

  /* vdo qn getting the sectionname  */
  const getSectionName = (qnid) => {
    for (let sec of Props.comingObj.sections) {
      if (sec.questions.find(que => que.id === qnid)) {
        return sec.sectionname;
      }
    }
    return null;
  };

  /* vdo qn getting the qn no  */
  const getQuestionNo = (qnid) => {
    for (let sec of Props.comingObj.sections) {
      const questionIndex = sec.questions.findIndex(que => que.id === qnid);
      if (questionIndex !== -1) {
        return questionIndex + 1;
      }
    }
    return null;
  };

  return (
    <>
      <div className="component-one">
        {Props.showtransition &&
          Props.showTimer &&
          ((!isAdmin &&
            Props.status === constants.PROGRESS_STATUS.IN_PROGRESS) ||
            (!isAdmin && Props.status === constants.PROGRESS_STATUS.UNLOCKED) ||
            (!isAdmin &&
              Props.status === constants.PROGRESS_STATUS.QN_CONTINUE) ||
            (!isAdmin &&
              Props.status === constants.PROGRESS_STATUS.REATTEMPT)) &&
          Props.comingObj.videoqn === 1 && (
            <BackdropCounter
              setShowTimer={Props.setShowTimer}
              setshowtransition={Props.setshowtransition}
            />
          )}
      </div>
      <div className="commontest-mainpage-div">
        {Props.status === constants.PROGRESS_STATUS.EVALUATED ||
        Props.status === constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
        Props.status === constants.PROGRESS_STATUS.PARTIAL_EVALUATED ||
        (isAdmin && Props.status === constants.PROGRESS_STATUS.IN_PROGRESS) ||
        (isAdmin && Props.status === constants.PROGRESS_STATUS.QN_CONTINUE) ||
        (isAdmin && Props.status === constants.PROGRESS_STATUS.REATTEMPT) ? (
          <div className="commontest-mainpage-header">
            <div className="commontest-mainpage-header-change-section">
              <div className="commontest-mainpage-header-back-btn-dropdown">
                <div>
                  <BackSvgIcon onClick={bacttoHomepage} />
                </div>
                <FormControl
                  // variant="standard"
                  // sx={{ m: 0, minWidth: 60, color: "#AEB0B2" }}
                  className="header-change-section-form"
                >
                  <Select
                    className="header-change-section-select"
                    value={Props.selSec}
                    // id="reportLearnerSelect"
                    onChange={handleSecChange}
                    labelId="learnerSelectLabel"
                  >
                    <MenuItem value={"all"} key={"selectall"}>
                      {"All"}
                    </MenuItem>

                    {Props.hasOwnProperty("comingObj") &&
                      Props.comingObj.hasOwnProperty("sections") &&
                      Props.comingObj.sections.map((sec, ind) => (
                        <MenuItem
                          className="header-change-section-menuitem"
                          value={ind}
                          key={sec.sectionname + "" + ind}
                        >
                          {sec.sectionname}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            {(isAdmin ||
              Props.isTrainer ||
              (Props.isLearner &&
                Props.viewTest === constants.DISPLAY_TEST_RESULT.ALL) ||
              backupTestData.testmode === constants.MODE.PRACTICE_MODE) &&
              (backupTestData.sections[0].questions[0]?.libtypeid ===
                constants.libTypeIds.SAQ ||
                backupTestData.sections[0].questions[0]?.libtypeid ===
                  constants.libTypeIds.LAQ ||
                backupTestData.sections[0].questions[0]?.libtypeid ===
                  constants.libTypeIds.MCQ_SINGLE ||
                backupTestData.sections[0].questions[0]?.libtypeid ===
                  constants.libTypeIds.MCQ_MULTI) && (
                <>
                  <div className="Show-answer-div">
                    {/* <span> */}
                    {/* <input
                              type="checkbox"
                              onChange={showAnswer}
                              key="showSaqLaqAnswer"
                              name="showSaqLaqAnswer"
                              checked={showSaqLaqAns}
                              value={"showSaqLaqAnswer"}
                              className="filterCheckBoxDivBox"
                            /> */}
                    <Checkbox
                      onChange={showAnswer}
                      checked={showSaqLaqAns}
                      text={"Show explanation"}
                    />
                    {/* </span> */}
                    {/* <span>Show explanation</span> */}
                  </div>
                </>
              )}

            {(isAdmin ||
              Props.isTrainer ||
              /*  Props.viewTest === constants.DISPLAY_TEST_RESULT.ALL || */ backupTestData.testmode ===
                constants.MODE.PRACTICE_MODE) &&
              Props.comingObj.videoqn === 1 && (
                <div className="Show-answer-div">
                  <Checkbox
                    onChange={showTranscriptFunc}
                    checked={showTranscript}
                    text={"Show Transcript"}
                  />
                </div>
              )}
          </div>
        ) : (
          <div className="commontest-mainpage-header">
            <div className="commontest-mainpage-header-section">
              <div className="commontest-mainpage-header-section-title">
                Section:
              </div>
              <div className="commontest-mainpage-header-section-name">
              {Props.comingObj.videoqn !== 1 ? Props.secObj?.sectionname : getSectionName( Props.videoQn.current?.filter(data=>data?.selected)[0]?.id) }
              </div>
              {Props.comingObj.videoqn === 1 && (<><div className='commontest-mainpage-header-section-title'>Question No:</div>
                <div className='commontest-mainpage-header-section-name'>{getQuestionNo( Props.videoQn.current?.filter(data=>data?.selected)[0]?.id) }</div></>)}
            </div>
            {Props.comingObj.videoqn !== 1 &&
              Props.comingObj.listview === 1 && (
                <div className="commontest-mainpage-header-test-view-mode">
                  <div className="commontest-mainpage-header-test-view-mode-gridbtn">
                    <IconButton
                      onClick={() => Props.changeQuestionVisible("single")}
                    >
                      <GridViewSvg />
                    </IconButton>
                  </div>
                  <div className="commontest-mainpage-header-test-view-mode-listbtn">
                    <IconButton
                      onClick={() => Props.changeQuestionVisible("multiple")}
                    >
                      <ListViewSvg />
                    </IconButton>
                  </div>
                </div>
              )}
            {Props.comingObj.videoqn === 1 && (
              <div className="video-qn-timer-div">
                <div className="video-qn-timer-label">Time Left:</div>
                <div className="video-qn-timer">
                  <span>
                    {Math.floor((Props.vdoQnTimelimit % 3600) / 60).toString()
                      .length === 1
                      ? "0" + Math.floor((Props.vdoQnTimelimit % 3600) / 60)
                      : Math.floor((Props.vdoQnTimelimit % 3600) / 60)}
                  </span>
                  {" : "}
                  <span>
                    {Math.floor((Props.vdoQnTimelimit % 3600) % 60).toString()
                      .length === 1
                      ? "0" + Math.floor((Props.vdoQnTimelimit % 3600) % 60)
                      : Math.floor((Props.vdoQnTimelimit % 3600) % 60)}
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
        <div className={`commontest-mainpage-body${(!isAdmin && (Props.comingObj.videoqn === 1 && Props.status !== constants.PROGRESS_STATUS.EVALUATED && Props.status !== constants.PROGRESS_STATUS.TO_BE_EVALUATED && Props.status !== constants.PROGRESS_STATUS.PARTIAL_EVALUATED)) ? '-videoqn' :''}`}>
          {/* <div> */}
          {/* <div><BookmarkSvg/>
              <BookmarkedSvg />
              </div> */}

          {Props.status !== constants.PROGRESS_STATUS.EVALUATED &&
          Props.questionContainerSingle ? (
            <div className="commontest-mainpage-single-question-div">
              <div className="commontest-mainpage-single-question-box">
                <div className="commontest-mainpage-single-question-header-div">
                  <div className="commontest-mainpage-single-question-title-div">
                    {Object.keys(Props.curObj)?.length > 0 &&
                      Props.curObj.hasOwnProperty("review") &&
                      Props.curObj.review === -1 && (
                        <IconButton
                          className="commontest-mainpage-single-question-title-reviewlater"
                          onClick={() =>
                            reviewLater("single", "noreview", Props.queObj)
                          }
                        >
                          <BookmarkSvg />
                        </IconButton>
                      )}
                    {Object.keys(Props.curObj)?.length > 0 &&
                      Props.curObj.hasOwnProperty("review") &&
                      Props.curObj.review === 0 && (
                        <IconButton
                          className="commontest-mainpage-single-question-title-reviewlater"
                          onClick={() =>
                            reviewLater("single", "review", Props.queObj)
                          }
                        >
                          <BookmarkedSvg />
                        </IconButton>
                      )}

{(Props.comingObj.videoqn !== 1 || isAdmin) && <div className="commontest-mainpage-single-question-title-qnno">
                      {Props.comingObj.videoqn === 1
                        ? videoQnNo.current
                        : Props.queInd + 1}
                      .
                    </div>}
                    <div className="commontest-mainpage-single-question-title-qnname" ref={textRef} id='dynamicFont'>
                      {Props.comingObj.videoqn === 1
                        ? Props.videoQn.current?.filter(
                            (data) => data?.selected
                          )[0]?.name
                        : Props.queObj?.name}
                    </div>

                    {(Props.comingObj.videoqn !== 1 || isAdmin) && <div className="commontest-mainpage-single-question-title-mark">
                      {Props.comingObj.videoqn === 1
                        ? "(" +
                          Props.videoQn.current?.filter(
                            (data) => data?.selected
                          )[0]?.targetscore +
                          " Marks)"
                        : "(" + Props.queObj?.targetscore + " Marks)"}
                    </div>}
                  </div>
                </div>
                <div className="commontest-mainpage-single-question-detail-box">
                  {/* if desc is there to show */}
                  {((Props.comingObj.videoqn !== 1 &&
                    Props.queObj?.hasOwnProperty("description") &&
                    Props.queObj?.description != null &&
                    Props.queObj?.description.length > 0) ||
                    (Props.comingObj.videoqn === 1 &&
                      Props.videoQn.current
                        ?.filter((data) => data?.selected)[0]
                        ?.hasOwnProperty("description") &&
                      Props.videoQn.current?.filter((data) => data?.selected)[0]
                        ?.description !== null &&
                      Props.videoQn.current?.filter((data) => data?.selected)[0]
                        ?.description.length > 0)) && (
                    <div className="singleQuestionContainerDescription">
                      <br></br>
                      {Props.comingObj.videoqn === 1
                        ? parse(
                            Props.videoQn.current?.filter(
                              (data) => data?.selected
                            )[0]?.description
                          )
                        : parse(Props.queObj?.description)}
                    </div>
                  )}
                  {/* if img is there to show */}
                  {(Props.comingObj.videoqn !== 1 &&
                    Props.queObj?.links.length > 0) ||
                  (Props.comingObj.videoqn === 1 &&
                    Props.videoQn.current?.filter((data) => data?.selected))[0]
                    ?.links.length > 0 ? (
                    <div>
                      {Props.comingObj.videoqn === 1
                        ? Props.videoQn.current
                            ?.filter((data) => data?.selected)[0]
                            ?.links.map((link, index) => (
                              <span
                                className="questionLinks"
                                key={link + "" + Props.secObj.sectionid + index}
                              >
                                <img
                                  width="400"
                                  height="300"
                                  alt="userImage"
                                  key={link + "img"}
                                  src={
                                    process.env.REACT_APP_DEPLOYMENT !== "AWS"
                                      ? process.env.REACT_APP_NODE_API +
                                        link.link
                                      : link.link
                                  }
                                />
                              </span>
                            ))
                        : Props.queObj?.links.map((link, index) => (
                            <span
                              className="questionLinks"
                              key={link + "" + Props.secObj.sectionid + index}
                            >
                              <img
                                width="400"
                                height="300"
                                alt="userImage"
                                key={link + "img"}
                                src={
                                  process.env.REACT_APP_DEPLOYMENT !== "AWS"
                                    ? process.env.REACT_APP_NODE_API + link.link
                                    : link.link
                                }
                              />
                            </span>
                          ))}
                    </div>
                  ) : null}

                  {Props.comingObj.videoqn !== 1 ? (
                    Props.queObj.libtypeid === constants.libTypeIds.SAQ ||
                    Props.queObj.libtypeid === constants.libTypeIds.LAQ ? (
                      <div
                        className="text-area-AnswerDiv"
                        key={"textareadiv" + Props.queObj.id}
                      >
                        <div
                          className="desInputWrapper"
                          data-required={
                            Props.comingObj.videoqn !== 1
                              ? Props.queObj.libtypeid ===
                                constants.libTypeIds.SAQ
                                ? 1024 -
                                  (Props.answers[Props.queObj.id] == null
                                    ? 0
                                    : Props.answers[Props.queObj.id].length) +
                                  " characters left"
                                : 10000 -
                                  (Props.answers[Props.queObj.id] == null
                                    ? 0
                                    : Props.answers[Props.queObj.id].length) +
                                  " characters left"
                              : ""
                          }
                        >
                          {errorMessage &&
                            Props.queObj.id === questionIdOffError && (
                              <label style={{ color: "red" }}>
                                only numbers and decimals are allowed
                              </label>
                            )}

                          <TextareaAutosize
                            minRows={7}
                            placeholder=""
                            id={"inputs" + 1}
                            onCut={handleCPC}
                            onCopy={handleCPC}
                            onPaste={handleCPC}
                            key={Props.queObj.id}
                            oncontextmenu={handleCPC}
                            className="textareaAnswer"
                            input={Props.answers[Props.queObj.id]}
                            value={Props.answers[Props.queObj.id]}
                            name={Props.queObj.id + "" + Props.secObj.sectionid}
                            onChange={(e) => {
                              collectAnswers(
                                Props.queObj.id,
                                e.target.value,
                                Props.queObj.libtypeid,
                                Props.secInd,
                                Props.queInd,
                                Props.queObj?.datatype
                              );
                            }}
                            style={{
                              width: "100%",
                              resize: "none",
                              // minWidth: "57vW",
                              borderRadius: "5px",
                              marginBottom: "10px",
                              border:
                                errorMessage &&
                                Props.queObj.id === questionIdOffError
                                  ? "1px solid red"
                                  : Props.answers[Props.queObj.id] == null ||
                                    Props.answers[Props.queObj.id].length === 0
                                  ? "1px solid rgba(55, 55, 57, 0.2)"
                                  : "1px solid #D0D5DD",
                            }}
                            maxLength={
                              Props.queObj.libtypeid ===
                              constants.libTypeIds.SAQ
                                ? 1024
                                : 10000
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      Props.optObj.map((option, index) => (
                        <>
                          <div
                            key={option.optionid + "" + index}
                            className={
                              isAdmin
                                ? "singleViewQuestionBoxDetailsOptionsDivEachOptionAdmin"
                                : "commontest-mainpage-single-question-options"
                            }
                            onClick={() => {
                              collectAnswers(
                                Props.queObj.id,
                                option.optionid + "",
                                Props.queObj.libtypeid
                              );
                            }}
                            style={{
                              borderColor: Props.answers[
                                Props.queObj.id
                              ]?.includes(option.optionid + "")
                                ? Props.status !==
                                  constants.PROGRESS_STATUS.EVALUATED
                                  ? "#F96343"
                                  : "green"
                                : "",
                              border: Props.answers[Props.queObj.id]?.includes(
                                option.optionid + ""
                              )
                                ? Props.status !==
                                  constants.PROGRESS_STATUS.EVALUATED
                                  ? "2px solid #F96343"
                                  : "2px solid green"
                                : "",
                            }}
                          >
                            <div
                              className={
                                isAdmin
                                  ? "singleViewQuestionBoxDetailsOptionsDivEachOptionAdmin"
                                  : "commontest-mainpage-single-question-options-input-label-div"
                              }
                            >
                              <input
                                className="commontest-mainpage-single-question-options-input"
                                id={option.optionid}
                                value={option.optionid}
                                name={Props.queObj.id + "" + Props.secInd}
                                key={
                                  option.optionid +
                                  "" +
                                  Props.secInd +
                                  Props.queInd
                                }
                                type={
                                  Props.queObj.libtypeid ===
                                  constants.libTypeIds.MCQ_SINGLE
                                    ? "radio"
                                    : "checkbox"
                                }
                                onChange={() => {
                                  collectAnswers(
                                    Props.queObj.id,
                                    option.optionid + "",
                                    Props.queObj.libtypeid
                                  );
                                }}
                                checked={
                                  Props.answers[Props.queObj.id]?.includes(
                                    option.optionid + ""
                                  )
                                    ? true
                                    : false
                                }
                                style={{
                                  color: Props.answers[
                                    Props.queObj.id
                                  ]?.includes(option.optionid + "")
                                    ? "#f55533"
                                    : "#D0D5DD",
                                }}
                              />
                              <label
                                className="commontest-mainpage-single-question-option-label"
                                style={{
                                  color: Props.answers[
                                    Props.queObj.id
                                  ]?.includes(option.optionid + "")
                                    ? "#f55533"
                                    : "#101828",
                                }}
                              >
                                {option.optiontext}
                              </label>
                            </div>
                          </div>
                        </>
                      ))
                    )
                  ) : null}
                </div>
              </div>

              <div className="commontest-mainpage-single-question-button-group">
                {Props.comingObj.videoqn !== 1 &&
                  Props.questionContainerSingle && (
                    <div
                      className={
                        Props.prevButton && Props.nextButton
                          ? "commontest-mainpage-single-question-button-group-div"
                          : "commontest-mainpage-single-question-button-group-div"
                      }
                    >
                      <div>
                        <ButtonMedium
                          btnText2="Previous"
                          secondaryBtn={Props.prevButton}
                          onHideSecondary={() => traversePrevious("previous")}
                        />{" "}
                      </div>
                      <div>
                        <ButtonMedium
                          btnText="Next"
                          primaryBtn={Props.nextButton}
                          onHide={() => traverseNext("next")}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          ) : (
            <div className={"commontest-mainpage-multiple-question-options"}>
              <div className="commontest-mainpage-multiple-question-container">
                {Props.hasOwnProperty("comingObj") &&
                Props.comingObj.hasOwnProperty("sections") &&
                Props.sectionObj !== null &&
                typeof Props.sectionObj !== "undefined" &&
                Object.keys(Props.sectionObj).length > 0 &&
                Props.sectionObj.hasOwnProperty("questions") ? (
                  <>
                    {
                      <div className="commontest-mainpage-multiple-question-container-loop">
                        {Props.fSections.map((fsec, fsecind) => (
                          <div className="commontest-mainpage-multiple-question-container-block">
                            {(Props.status ===
                              constants.PROGRESS_STATUS.EVALUATED ||
                              Props.status ===
                                constants.PROGRESS_STATUS.IN_PROGRESS ||
                              Props.status ===
                                constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
                              Props.status ===
                                constants.PROGRESS_STATUS.PARTIAL_EVALUATED ||
                              Props.status ===
                                constants.PROGRESS_STATUS.QN_CONTINUE) &&
                            Props.selSec === "all" &&
                            fsec.questions.length > 0 ? (
                              <div className="testContainerChildLeftHeaderSectionTitle2">
                                <span className="testContainerChildLeftHeaderSectionTitleLabel">
                                  Section :&nbsp;{" "}
                                </span>{" "}
                                <span className="testContainerChildLeftHeaderSectionTitleName">
                                  {fsec.sectionname}
                                </span>
                                <span className="singleViewQuestionBoxTitleMarks">
                                  {" ( " + getSectionTotal(fsec) + " Marks )"}
                                </span>
                              </div>
                            ) : null}
                            {fsec.questions.map((questionobj, queindex) => (
                              <>
                                <div
                                  className="commontest-mainpage-multiple-question-box"
                                  id={
                                    "que" +
                                    (Props.secInd === "all"
                                      ? 0
                                      : Props.secInd) +
                                    queindex
                                  }
                                >
                                  <>
                                    <div class="commontest-mainpage-multiple-question-box-title">
                                      {questionobj.review === -1 && (
                                        <IconButton
                                          className="commontest-mainpage-multiple-question-title-reviewlater"
                                          onClick={() =>
                                            reviewLater(
                                              "multi",
                                              "noreview",
                                              queindex
                                            )
                                          }
                                        >
                                          <BookmarkSvg />
                                        </IconButton>
                                      )}
                                      {questionobj.review === 0 && (
                                        <IconButton
                                          className="commontest-mainpage-multiple-question-title-reviewlater"
                                          onClick={() =>
                                            reviewLater(
                                              "multi",
                                              "review",
                                              queindex
                                            )
                                          }
                                        >
                                          <BookmarkedSvg />
                                        </IconButton>
                                      )}

                                      <div className="commontest-mainpage-single-question-title-qnno">
                                        {queindex + 1}.
                                      </div>
                                      <div className="commontest-mainpage-single-question-title-qnname">
                                        {questionobj.name}
                                      </div>

                                      <div className="commontest-mainpage-single-question-title-mark">
                                        {getDataFromStorage("role") ===
                                          "learner" &&
                                          "(" +
                                            (Props.isLearner &&
                                            Props.viewTest ===
                                              constants.DISPLAY_TEST_RESULT
                                                .ALL &&
                                            Props.testMode ===
                                              constants.MODE.TEST_MODE &&
                                            Props.status ===
                                              constants.PROGRESS_STATUS
                                                .EVALUATED
                                              ? "" +
                                                questionobj.actualscore +
                                                "/"
                                              : "") +
                                            questionobj.targetscore +
                                            " Marks)"}
                                      </div>
                                    </div>
                                    <div className="commontest-mainpage-single-question-option-detail-box">
                                      {questionobj.hasOwnProperty(
                                        "description"
                                      ) &&
                                        questionobj.description != null &&
                                        questionobj.description.length > 0 && (
                                          <div className="multiQuestionContainerDescription">
                                            <br />
                                            {parse(questionobj.description)}
                                          </div>
                                        )}

                                      {questionobj.links.length > 0 ? (
                                        <div className="singleViewQuestionBoxTitleQuestionLinks">
                                          {questionobj.links.map((link) => (
                                            <span className="questionLinks">
                                              <img
                                                width="400"
                                                height="400"
                                                alt="userImage"
                                                key={link + "" + Props.secIndex}
                                                src={
                                                  process.env
                                                    .REACT_APP_DEPLOYMENT !==
                                                  "AWS"
                                                    ? process.env
                                                        .REACT_APP_NODE_API +
                                                      link.link
                                                    : link.link
                                                }
                                              />
                                            </span>
                                          ))}
                                        </div>
                                      ) : null}
                                      <div className="commontest-mainpage-single-question-option-details-div">
                                        {questionobj.libtypeid ===
                                          constants.libTypeIds.SAQ ||
                                        questionobj.libtypeid ===
                                          constants.libTypeIds.LAQ ? (
                                          <div
                                            className="text-area-AnswerDiv"
                                            key={
                                              questionobj.libtypeid +
                                              questionobj.id
                                            }
                                          >
                                            <div
                                              className="desInputWrapper"
                                              data-required={
                                                Props.comingObj.videoqn !== 1
                                                  ? Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .UNLOCKED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .REATTEMPT ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .QN_CONTINUE
                                                    ? questionobj.libtypeid ===
                                                      constants.libTypeIds.SAQ
                                                      ? 1024 -
                                                        (isAdmin
                                                          ? questionobj.answer !=
                                                            null
                                                            ? questionobj.answer
                                                                .length
                                                            : 0
                                                          : Props.answers[
                                                              questionobj.id
                                                            ]?.length) +
                                                        " characters left"
                                                      : 10000 -
                                                        (isAdmin
                                                          ? questionobj.answer !=
                                                            null
                                                            ? questionobj.answer
                                                                .length
                                                            : 0
                                                          : Props.answers[
                                                              questionobj.id
                                                            ]?.length) +
                                                        " characters left"
                                                    : questionobj.libtypeid ===
                                                      constants.libTypeIds.SAQ
                                                    ? 1024 -
                                                      (isAdmin
                                                        ? questionobj.answer !=
                                                          null
                                                          ? questionobj.answer
                                                              .length
                                                          : 0
                                                        : questionobj.answer
                                                            ?.length) +
                                                      " characters left"
                                                    : 10000 -
                                                      (isAdmin
                                                        ? questionobj.answer !=
                                                          null
                                                          ? questionobj.answer
                                                              .length
                                                          : 0
                                                        : questionobj.answer
                                                            ?.length) +
                                                      " characters left"
                                                  : ""
                                              }
                                            >
                                              {errorMessage &&
                                                questionobj.id ===
                                                  questionIdOffError && (
                                                  <label
                                                    style={{
                                                      color: "red",
                                                      display: "flex",
                                                    }}
                                                  >
                                                    only numbers and decimals
                                                    are allowed
                                                  </label>
                                                )}
                                              {Props.comingObj.videoqn === 1 ? (
                                                questionobj.answer !== "" && (
                                                  <video
                                                    className="videoqn-admin-answer"
                                                    controls
                                                    controlsList="nodownload"
                                                    src={questionobj.answer}
                                                  ></video>
                                                )
                                              ) : (
                                                <TextareaAutosize
                                                  minRows={7}
                                                  onCut={handleCPC}
                                                  onCopy={handleCPC}
                                                  onPaste={handleCPC}
                                                  id={questionobj.id}
                                                  key={questionobj.id}
                                                  oncontextmenu={handleCPC}
                                                  className="textareaAnswer"
                                                  name={
                                                    questionobj.id +
                                                    "" +
                                                    Props.sectionObj.sectionid
                                                  }
                                                  value={
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .EVALUATED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .TO_BE_EVALUATED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .PARTIAL_EVALUATED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .IN_PROGRESS ||
                                                    (isAdmin &&
                                                      Props.status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .QN_CONTINUE)
                                                      ? questionobj.answer
                                                      : Props.answers[
                                                          questionobj.id
                                                        ]
                                                  }
                                                  disabled={
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .EVALUATED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .TO_BE_EVALUATED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .PARTIAL_EVALUATED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .IN_PROGRESS ||
                                                    (isAdmin &&
                                                      Props.status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .QN_CONTINUE)
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    collectAnswers(
                                                      questionobj.id,
                                                      e.target.value,
                                                      questionobj.libtypeid,
                                                      Props.secIndex,
                                                      queindex,
                                                      questionobj?.datatype
                                                    );
                                                  }}
                                                  style={{
                                                    width: "100%",
                                                    resize: "none",
                                                    // minWidth: "57vW",
                                                    borderRadius: "5px",
                                                    marginBottom: "10px",
                                                    border:
                                                      errorMessage &&
                                                      questionobj.id ===
                                                        questionIdOffError
                                                        ? "1px solid red"
                                                        : Props.status ===
                                                            constants
                                                              .PROGRESS_STATUS
                                                              .UNLOCKED ||
                                                          Props.status ===
                                                            constants
                                                              .PROGRESS_STATUS
                                                              .REATTEMPT ||
                                                          Props.status ===
                                                            constants
                                                              .PROGRESS_STATUS
                                                              .QN_CONTINUE
                                                        ? Props.answers[
                                                            questionobj.id
                                                          ]?.length === 0
                                                          ? "1px solid rgba(55, 55, 57, 0.2)"
                                                          : "1px solid #D0D5DD"
                                                        : questionobj.answer
                                                            ?.length === 0
                                                        ? "1px solid rgba(55, 55, 57, 0.2)"
                                                        : "1px solid #D0D5DD",
                                                  }}
                                                  maxLength={
                                                    questionobj.libtypeid ===
                                                    constants.libTypeIds.SAQ
                                                      ? 1024
                                                      : 10000
                                                  }
                                                />
                                              )}
                                            </div>

                                            {showSaqLaqAns && (
                                              <div className="desc-admin-answer-div">
                                                <div className="desc-admin-answer-label-div">
                                                  Explanation
                                                </div>
                                                <textarea
                                                  rows={5}
                                                  disabled={true}
                                                  id={
                                                    questionobj.id +
                                                    "showanswer"
                                                  }
                                                  key={
                                                    questionobj.id +
                                                    "showanswer"
                                                  }
                                                  className="desc-admin-answer"
                                                  name={
                                                    questionobj.id +
                                                    "" +
                                                    Props.sectionObj.sectionid +
                                                    "showanswer"
                                                  }
                                                  value={questionobj.options
                                                    .map(
                                                      (obj) => obj.optiontext
                                                    )
                                                    .join("/")}
                                                />
                                              </div>
                                            )}
                                            {showTranscript && (
                                              <div className="desc-admin-answer-div">
                                                <div className="desc-admin-answer-label-div">
                                                  Transcript
                                                </div>
                                                <textarea
                                                  rows={5}
                                                  disabled={true}
                                                  id={
                                                    questionobj.id +
                                                    "showanswer"
                                                  }
                                                  key={
                                                    questionobj.id +
                                                    "showanswer"
                                                  }
                                                  className="desc-admin-answer"
                                                  name={
                                                    questionobj.id +
                                                    "" +
                                                    Props.sectionObj.sectionid +
                                                    "showanswer"
                                                  }
                                                  value={
                                                    questionobj?.transcript
                                                  }
                                                />
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          questionobj.options.map(
                                            (option, optindex) =>
                                              questionobj.libtypeid ===
                                              constants.libTypeIds
                                                .MCQ_SINGLE ? (
                                                <div
                                                  key={
                                                    option.optionid +
                                                    "" +
                                                    optindex +
                                                    Props.secIndex +
                                                    questionobj.libtypeid
                                                  }
                                                  className={
                                                    "commontest-mainpage-single-question-option-each-div"
                                                  }
                                                  onClick={
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .UNLOCKED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .REATTEMPT ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .QN_CONTINUE
                                                      ? () => {
                                                          collectAnswers(
                                                            questionobj.id,
                                                            option.optionid +
                                                              "",
                                                            questionobj.libtypeid,
                                                            Props.secIndex,
                                                            queindex,
                                                            0
                                                          );
                                                        }
                                                      : null
                                                  }
                                                  style={{
                                                    borderColor:
                                                      Props.status !==
                                                      constants.PROGRESS_STATUS
                                                        .EVALUATED
                                                        ? Props.answers[
                                                            questionobj.id
                                                          ]?.includes(
                                                            option.optionid + ""
                                                          )
                                                          ? "#F96343"
                                                          : (isAdmin ||
                                                              Props.isTrainer) &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? "#F96343"
                                                          : ""
                                                        : (isAdmin ||
                                                            Props.isTrainer ||
                                                            (Props.isLearner &&
                                                              Props.viewTest ===
                                                                constants
                                                                  .DISPLAY_TEST_RESULT
                                                                  .ALL)) &&
                                                          option.iscorrectoption ===
                                                            1 &&
                                                          questionobj.answer?.includes(
                                                            option.optionid + ""
                                                          )
                                                        ? "#12B76A"
                                                        : (isAdmin ||
                                                            Props.isTrainer ||
                                                            (Props.isLearner &&
                                                              Props.viewTest ===
                                                                constants
                                                                  .DISPLAY_TEST_RESULT
                                                                  .ALL)) &&
                                                          option.iscorrectoption ===
                                                            0 &&
                                                          questionobj.answer?.includes(
                                                            option.optionid + ""
                                                          )
                                                        ? "#F04438"
                                                        : "",
                                                    border:
                                                      Props.status !==
                                                      constants.PROGRESS_STATUS
                                                        .EVALUATED
                                                        ? Props.answers[
                                                            questionobj.id
                                                          ]?.includes(
                                                            option.optionid + ""
                                                          )
                                                          ? "2px solid #F96343"
                                                          : (isAdmin ||
                                                              Props.isTrainer) &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? "2px solid #F96343"
                                                          : ""
                                                        : (isAdmin ||
                                                            Props.isTrainer ||
                                                            (Props.isLearner &&
                                                              Props.viewTest ===
                                                                constants
                                                                  .DISPLAY_TEST_RESULT
                                                                  .ALL)) &&
                                                          option.iscorrectoption ===
                                                            1 &&
                                                          questionobj.answer?.includes(
                                                            option.optionid + ""
                                                          )
                                                        ? "2px solid #12B76A"
                                                        : (isAdmin ||
                                                            Props.isTrainer ||
                                                            (Props.isLearner &&
                                                              Props.viewTest ===
                                                                constants
                                                                  .DISPLAY_TEST_RESULT
                                                                  .ALL)) &&
                                                          option.iscorrectoption ===
                                                            0 &&
                                                          questionobj.answer?.includes(
                                                            option.optionid + ""
                                                          )
                                                        ? "2px solid #F04438"
                                                        : "",
                                                  }}
                                                >
                                                  <div
                                                    className={
                                                      "commontest-mainpage-multiple-question-options-input-label-div"
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        "options-input-label-div"
                                                      }
                                                    >
                                                      <input
                                                        type="radio"
                                                        className="commontest-mainpage-multiple-question-options-input"
                                                        key={
                                                          option.optionid +
                                                          "" +
                                                          optindex +
                                                          Props.secIndex +
                                                          questionobj.libtypeid
                                                        }
                                                        name={
                                                          questionobj.id +
                                                          "" +
                                                          Props.sectionObj
                                                            .sectionid
                                                        }
                                                        id={
                                                          option.optionid +
                                                          "" +
                                                          optindex +
                                                          Props.secIndex +
                                                          questionobj.libtypeid
                                                        }
                                                        value={
                                                          option.optionid +
                                                          "" +
                                                          optindex +
                                                          Props.secIndex +
                                                          questionobj.libtypeid
                                                        }
                                                        onChange={() => {
                                                          collectAnswers(
                                                            questionobj.id,
                                                            option.optionid +
                                                              "",
                                                            questionobj.libtypeid,
                                                            Props.secIndex,
                                                            queindex,
                                                            0
                                                          );
                                                        }}
                                                        checked={
                                                          Props.status !==
                                                          constants
                                                            .PROGRESS_STATUS
                                                            .EVALUATED
                                                            ? Props.answers[
                                                                questionobj.id
                                                              ]?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                              ? true
                                                              : (isAdmin ||
                                                                  Props.isTrainer) &&
                                                                questionobj.answer?.includes(
                                                                  option.optionid +
                                                                    ""
                                                                )
                                                              ? true
                                                              : false
                                                            : option.iscorrectoption ===
                                                                1 &&
                                                              questionobj.answer?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                            ? true
                                                            : option.iscorrectoption ===
                                                                0 &&
                                                              questionobj.answer?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                            ? true
                                                            : false
                                                        }
                                                        style={{
                                                          accentColor:
                                                            Props.status !==
                                                            constants
                                                              .PROGRESS_STATUS
                                                              .EVALUATED
                                                              ? Props.answers[
                                                                  questionobj.id
                                                                ]?.includes(
                                                                  option.optionid +
                                                                    ""
                                                                )
                                                                ? "#F96343"
                                                                : (isAdmin ||
                                                                    Props.isTrainer) &&
                                                                  questionobj.answer?.includes(
                                                                    option.optionid +
                                                                      ""
                                                                  )
                                                                ? "#F96343"
                                                                : ""
                                                              : (isAdmin ||
                                                                  Props.isTrainer ||
                                                                  (Props.isLearner &&
                                                                    Props.viewTest ===
                                                                      constants
                                                                        .DISPLAY_TEST_RESULT
                                                                        .ALL)) &&
                                                                option.iscorrectoption ===
                                                                  1 &&
                                                                questionobj.answer?.includes(
                                                                  option.optionid +
                                                                    ""
                                                                )
                                                              ? "green"
                                                              : (isAdmin ||
                                                                  Props.isTrainer ||
                                                                  (Props.isLearner &&
                                                                    Props.viewTest ===
                                                                      constants
                                                                        .DISPLAY_TEST_RESULT
                                                                        .ALL)) &&
                                                                option.iscorrectoption ===
                                                                  0 &&
                                                                questionobj.answer?.includes(
                                                                  option.optionid +
                                                                    ""
                                                                )
                                                              ? "#F04438"
                                                              : "#F96343",
                                                        }}
                                                      />
                                                      <label className="commontest-mainpage-multiple-question-options-label">
                                                        {option.optiontext}
                                                      </label>
                                                    </div>
                                                    <div>
                                                      {(isAdmin ||
                                                        Props.isTrainer ||
                                                        (Props.isLearner &&
                                                          Props.viewTest ===
                                                            constants
                                                              .DISPLAY_TEST_RESULT
                                                              .ALL)) &&
                                                      option.iscorrectoption ===
                                                        1 ? (
                                                        <RightSvgIcon />
                                                      ) : (isAdmin ||
                                                          Props.isTrainer ||
                                                          (Props.isLearner &&
                                                            Props.viewTest ===
                                                              constants
                                                                .DISPLAY_TEST_RESULT
                                                                .ALL)) &&
                                                        option.iscorrectoption ===
                                                          0 &&
                                                        questionobj.answer?.includes(
                                                          option.optionid + ""
                                                        ) ? (
                                                        <WobackCloseSvg
                                                          stoke={"#F04438"}
                                                        />
                                                      ) : (
                                                        ""
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : questionobj.libtypeid ===
                                                constants.libTypeIds
                                                  .MCQ_MULTI ? (
                                                <div
                                                  key={
                                                    option.optionid +
                                                    "" +
                                                    optindex +
                                                    Props.secIndex +
                                                    questionobj.libtypeid
                                                  }
                                                  className={
                                                    isAdmin
                                                      ? "singleViewQuestionBoxDetailsOptionsDivEachOptionAdmin"
                                                      : "commontest-mainpage-single-question-option-each-div"
                                                  }
                                                  onClick={
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .UNLOCKED ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .REATTEMPT ||
                                                    Props.status ===
                                                      constants.PROGRESS_STATUS
                                                        .QN_CONTINUE
                                                      ? () => {
                                                          collectAnswers(
                                                            questionobj.id,
                                                            option.optionid +
                                                              "",
                                                            questionobj.libtypeid,
                                                            Props.secIndex,
                                                            queindex,
                                                            0
                                                          );
                                                        }
                                                      : null
                                                  }
                                                  style={{
                                                    borderColor:
                                                      Props.status !==
                                                      constants.PROGRESS_STATUS
                                                        .EVALUATED
                                                        ? Props.answers[
                                                            questionobj.id
                                                          ]?.includes(
                                                            option.optionid + ""
                                                          )
                                                          ? "#F96343"
                                                          : (isAdmin ||
                                                              Props.isTrainer) &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? "#F96343"
                                                          : ""
                                                        : (isAdmin ||
                                                            Props.isTrainer ||
                                                            (Props.isLearner &&
                                                              Props.viewTest ===
                                                                constants
                                                                  .DISPLAY_TEST_RESULT
                                                                  .ALL)) &&
                                                          option.iscorrectoption ===
                                                            1 &&
                                                          questionobj.answer?.includes(
                                                            option.optionid + ""
                                                          )
                                                        ? "#12B76A"
                                                        : (isAdmin ||
                                                            Props.isTrainer ||
                                                            (Props.isLearner &&
                                                              Props.viewTest ===
                                                                constants
                                                                  .DISPLAY_TEST_RESULT
                                                                  .ALL)) &&
                                                          option.iscorrectoption ===
                                                            0 &&
                                                          questionobj.answer?.includes(
                                                            option.optionid + ""
                                                          )
                                                        ? "#F04438"
                                                        : "",
                                                    border:
                                                      Props.status !==
                                                      constants.PROGRESS_STATUS
                                                        .EVALUATED
                                                        ? Props.answers[
                                                            questionobj.id
                                                          ]?.includes(
                                                            option.optionid + ""
                                                          )
                                                          ? "2px solid #F96343"
                                                          : (isAdmin ||
                                                              Props.isTrainer) &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? "2px solid #F96343"
                                                          : ""
                                                        : (isAdmin ||
                                                            Props.isTrainer ||
                                                            (Props.isLearner &&
                                                              Props.viewTest ===
                                                                constants
                                                                  .DISPLAY_TEST_RESULT
                                                                  .ALL)) &&
                                                          option.iscorrectoption ===
                                                            1 &&
                                                          questionobj.answer?.includes(
                                                            option.optionid + ""
                                                          )
                                                        ? "2px solid #12B76A"
                                                        : (isAdmin ||
                                                            Props.isTrainer ||
                                                            (Props.isLearner &&
                                                              Props.viewTest ===
                                                                constants
                                                                  .DISPLAY_TEST_RESULT
                                                                  .ALL)) &&
                                                          option.iscorrectoption ===
                                                            0 &&
                                                          questionobj.answer?.includes(
                                                            option.optionid + ""
                                                          )
                                                        ? "2px solid #F04438"
                                                        : "",
                                                  }}
                                                >
                                                  <div
                                                    className={
                                                      "commontest-mainpage-multiple-question-options-input-label-div"
                                                    }
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      className="commontest-mainpage-multiple-question-options-input"
                                                      key={
                                                        option.optionid +
                                                        "" +
                                                        optindex +
                                                        Props.secIndex +
                                                        questionobj.libtypeid
                                                      }
                                                      name={
                                                        questionobj.id +
                                                        "" +
                                                        Props.sectionObj
                                                          .sectionid
                                                      }
                                                      id={
                                                        option.optionid +
                                                        "" +
                                                        optindex +
                                                        Props.secIndex +
                                                        questionobj.libtypeid
                                                      }
                                                      value={
                                                        option.optionid +
                                                        "" +
                                                        optindex +
                                                        Props.secIndex +
                                                        questionobj.libtypeid
                                                      }
                                                      onChange={() => {
                                                        collectAnswers(
                                                          questionobj.id,
                                                          option.optionid + "",
                                                          questionobj.libtypeid,
                                                          Props.secIndex,
                                                          queindex,
                                                          0
                                                        );
                                                      }}
                                                      checked={
                                                        Props.status !==
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .EVALUATED
                                                          ? Props.answers[
                                                              questionobj.id
                                                            ]?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                            ? true
                                                            : (isAdmin ||
                                                                Props.isTrainer) &&
                                                              questionobj.answer?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                            ? true
                                                            : false
                                                          : option.iscorrectoption ===
                                                              1 &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? true
                                                          : option.iscorrectoption ===
                                                              0 &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? true
                                                          : false
                                                      }
                                                      style={{
                                                        accentColor:
                                                          Props.status !==
                                                          constants
                                                            .PROGRESS_STATUS
                                                            .EVALUATED
                                                            ? Props.answers[
                                                                questionobj.id
                                                              ]?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                              ? "#F96343"
                                                              : (isAdmin ||
                                                                  Props.isTrainer) &&
                                                                questionobj.answer?.includes(
                                                                  option.optionid +
                                                                    ""
                                                                )
                                                              ? "#F96343"
                                                              : ""
                                                            : (isAdmin ||
                                                                Props.isTrainer ||
                                                                (Props.isLearner &&
                                                                  Props.viewTest ===
                                                                    constants
                                                                      .DISPLAY_TEST_RESULT
                                                                      .ALL)) &&
                                                              option.iscorrectoption ===
                                                                1 &&
                                                              questionobj.answer?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                            ? "green"
                                                            : (isAdmin ||
                                                                Props.isTrainer ||
                                                                (Props.isLearner &&
                                                                  Props.viewTest ===
                                                                    constants
                                                                      .DISPLAY_TEST_RESULT
                                                                      .ALL)) &&
                                                              option.iscorrectoption ===
                                                                0 &&
                                                              questionobj.answer?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                            ? "#F04438"
                                                            : "#F96343",
                                                      }}
                                                    />
                                                    <label className="commontest-mainpage-multiple-question-options-label">
                                                      {option.optiontext}
                                                    </label>
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )
                                          )
                                        )}
                                      </div>
                                    </div>
                                    {isAdmin &&
                                      (Props.status ===
                                        constants.PROGRESS_STATUS.EVALUATED ||
                                        Props.status ===
                                          constants.PROGRESS_STATUS
                                            .TO_BE_EVALUATED ||
                                        Props.status ===
                                          constants.PROGRESS_STATUS
                                            .PARTIAL_EVALUATED) &&
                                      (backupTestData.sections[0].questions[0]
                                        ?.libtypeid ===
                                        constants.libTypeIds.SAQ ||
                                        backupTestData.sections[0].questions[0]
                                          ?.libtypeid ===
                                          constants.libTypeIds.LAQ) && (
                                        <div className="admin-Marks-Input-Div">
                                          <div className="admin-Marks-Input-DivItems">
                                            <div
                                              key={questionobj.id}
                                              className="admin-Marks-Input"
                                            >
                                              <input
                                                min="0"
                                                className="saq-Laq-Marks-Entry"
                                                max={
                                                  questionobj.targetscore + ""
                                                }
                                                placeholder={
                                                  questionobj.hasOwnProperty(
                                                    "actualscore"
                                                  )
                                                    ? questionobj.actualscore
                                                    : ""
                                                }
                                                value={
                                                  descScores.hasOwnProperty(
                                                    questionobj.id
                                                  ) &&
                                                  descScores[questionobj.id] !=
                                                    null
                                                    ? descScores[questionobj.id]
                                                    : questionobj.hasOwnProperty(
                                                        "actualscore"
                                                      ) &&
                                                      questionobj.actualscore !=
                                                        null
                                                    ? questionobj.actualscore
                                                    : ""
                                                }
                                                style={{
                                                  borderColor:
                                                    scoreErrors.hasOwnProperty(
                                                      questionobj.id
                                                    )
                                                      ? "red"
                                                      : "black",
                                                }}
                                                onChange={(e) => {
                                                  collectScores(
                                                    questionobj.id,
                                                    e.target.value,
                                                    questionobj.targetscore,
                                                    e
                                                  );
                                                }}
                                                onKeyDown={(e) => {
                                                  handleScoreKeyDown(e);
                                                }}
                                                onWheel={(e) => e.target.blur()}
                                              />

                                              <div className="approveScoreDivTotalMarks">
                                                /{" "}
                                                {questionobj.targetscore +
                                                  " Marks"}
                                              </div>
                                            </div>

                                            {isAdmin && (
                                              <div>
                                                <Box sx={{ width: 250 }}>
                                                  <PrettoSlider
                                                    valueLabelDisplay="auto"
                                                    aria-label="pretto slider"
                                                    step={0.5}
                                                    value={
                                                      descScores.hasOwnProperty(
                                                        questionobj.id
                                                      )
                                                        ? descScores[
                                                            questionobj.id
                                                          ]
                                                        : questionobj.hasOwnProperty(
                                                            "actualscore"
                                                          ) &&
                                                          questionobj.actualscore ==
                                                            null
                                                        ? ""
                                                        : questionobj.actualscore
                                                    }
                                                    onChange={(e) => {
                                                      collectScores(
                                                        questionobj.id,
                                                        e.target.value,
                                                        questionobj.targetscore,
                                                        e
                                                      );
                                                    }}
                                                    disabled={
                                                      giveZeros &&
                                                      (questionobj.answer ===
                                                        "" ||
                                                        questionobj.answer ==
                                                          null)
                                                    }
                                                    max={
                                                      questionobj.targetscore
                                                    }
                                                  />
                                                </Box>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                  </>
                                </div>
                                <div className="commontest-mainpage-single-multiple-question-answer-score-div">
                                  {showSaqLaqAns &&
                                    (questionobj.libtypeid ===
                                      constants.libTypeIds.MCQ_SINGLE ||
                                      questionobj.libtypeid ===
                                        constants.libTypeIds.MCQ_MULTI) && (
                                      <div className="mcq-admin-answer-div">
                                        <div className="mcq-admin-answer-label-div">
                                          Explanation
                                        </div>

                                        {/* Normal text area for mcq explanation */}
                                        {/* <textarea
                                  rows={5}
                                  disabled={true}
                                  className="mcq-admin-answer"
                                  key={questionobj.id + "showanswer"}
                                  name={
                                    questionobj.id +
                                    "" +
                                    Props.sectionObj.sectionid +
                                    "showanswer"
                                  }
                                  id={questionobj.id + "showanswer"}
                                  value={questionobj.answerexplanation}
                                /> */}

                                        <Editor
                                          toolbar=""
                                          disabled={true}
                                          menubar={false}
                                          branding={false}
                                          statusbar={false}
                                          className="mcq-admin-answer"
                                          value={questionobj?.answerexplanation}
                                          tinymceScriptSrc="/tinymce/tinymce.min.js"
                                          onInit={(evt, editor) =>
                                            (editorRef.current = editor)
                                          }
                                          init={{
                                            resize: false,
                                            readonly: true,
                                            menubar: false,
                                            statusbar: false,
                                            license_key: "gpl",
                                            plugins: "autoresize",
                                          }}
                                        />
                                      </div>
                                    )}
                                  <div className="commontest-mainpage-single-multiple-question-divider-div"></div>
                                  {/* <div className="bottomContainerForButtonsLeftForTraversal">
                            {(!Props.questionContainerSingle ||
                              Props.status ===
                                constants.PROGRESS_STATUS.UNLOCKED ||
                              Props.status ===
                                constants.PROGRESS_STATUS.REATTEMPT ||
                              Props.status ===
                                constants.PROGRESS_STATUS.QN_CONTINUE) &&
                              (Props.status !==
                              constants.PROGRESS_STATUS.EVALUATED
                                ? Props.selSec === "all"
                                  ? true
                                  : true
                                : Props.selSec === "all"
                                ? false
                                : true) && (
                                <>
                                  <ButtonMedium
                                    btnText="Prev Section"
                                    primaryBtn={Props.prevSec}
                                    onHide={() => traverseSection("previous")}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <ButtonMedium
                                    btnText2="Next Section"
                                    secondaryBtn={Props.nextSec}
                                    onHideSecondary={() =>
                                      traverseSection("next")
                                    }
                                  />
                                </>
                              )}
                          </div> */}
                                </div>
                              </>
                            ))}{" "}
                          </div>
                        ))}
                      </div>
                    }
                  </>
                ) : null}
              </div>
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
