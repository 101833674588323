export const registerServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    const apiKey = process.env.REACT_APP_NODE_API; // Access the environment variable here

    navigator.serviceWorker
      .register("firebase-messaging-sw.js")
      .then((registration) => {
        registration.update().then(() => {
          if (registration.active) {
            registration.active.postMessage({ apiKey });
          }
        });
      })
      .catch((error) => {
        console.error("Service worker registration failed:", error);
      });
  }
};
