import Button from "../../Button";
import constants from "../../../constants";
import { getDataFromStorage } from "../../../util";
import TestSvgIcon from "../../../Svg/TestSvgIcon";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";
import { MockTestPageContext } from "../../../context/MockTestPageContext";
import MockTestLibraryHooks from "../../../Hooks/Mock/Student/MockTestLibraryHooks";

export default function MockTestLibrary() {
  const { setIsLoaded, setShowNotify } = useContext(AlertContext);

  const { setMockTest, searchText, searchMockTest, setSearchMockTest } =
    useContext(MockTestPageContext);

  const axios = useAxiosPrivate();

  const { getTime, handleTest, handleSearch } = MockTestLibraryHooks();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  useEffect(() => {
    handleSearch();
  }, [searchText]);

  useEffect(() => {
    setIsLoaded(true);
    axios
      .post(
        "node/learner/mock/lib",
        JSON.stringify({
          learnerid: getDataFromStorage("learnerid"),
        }),
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
          setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.message,
          });
        } else {
          if (res.data.data.hasOwnProperty("paths")) {
            setMockTest(res.data.data.tests.concat(res.data.data.paths));
            setSearchMockTest(res.data.data.tests.concat(res.data.data.paths));
          } else {
            setMockTest(res.data.data.tests);
            setSearchMockTest(res.data.data.tests);
          }
        }
      })
      .catch((error) => {
        getCatchBlockDetails(error);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  const getTestType = (type) => {
    if (type.hasOwnProperty("toc")) {
      return "Practice";
    } else {
      if (type.libtype === constants.libTypeIds.MCQ) {
        return "MCQ";
      } else if (type.libtype === constants.libTypeIds.DESCRIPTIVE) {
        return "Descriptive";
      } else if (type.libtype === constants.libTypeIds.CODING_CHALLENGE) {
        return "Coding Challenge";
      } else if (
        type.autoEval === 1 &&
        type.libType === constants.libTypeIds.DESCRIPTIVE
      ) {
        return "Coding Snippet";
      } else {
        return "Video Assessment";
      }
    }
  };

  return (
    <div className="mockTestLibraryMainContainer">
      {searchMockTest.map((test) => (
        <div key={test.id} className="mockTestLibraryGrid">
          <div className="mockTestLibraryGridHeader">
            <div className="mockTestLibraryGridHeaderTitle">{test.name}</div>
            {!test.hasOwnProperty("toc") ? (
              <TestSvgIcon autoEval={test.autoeval} libType={test.libtype} />
            ) : (
              ""
            )}
          </div>
          <div className="mockTestLibraryGridSubHeader">
            <div className="mockTestLibraryGridSubHeaderTestTitle">
              {getTestType(test)}
              <div className="mockTestLibraryGridSubHeaderTestQuestionCount">
                ({test.questionscount} Questions)
              </div>
            </div>
            <div className="mockTestLibraryGridSubHeaderTestDuration">
              {getTime(test.testduration)}
            </div>
          </div>
          <div className="mockTestLibraryGridTestDescription">
            {test.description}
          </div>
          <div className="mockTestLibraryGridTestTagsDiv">
            {test.hasOwnProperty("tags") &&
              test.tags !== "" &&
              test.tags !== null &&
              test.tags !== "null" && (
                <div className="mockTestLibraryGridTestTags">{test.tags}</div>
              )}
          </div>
          <div className="mockTestLibraryGridFooter">
            <Button
              size="sm"
              onClick={() => handleTest(test)}
              hierarchy={{
                type: "secondaryColor",
                buttonText:
                  test.status === constants.PROGRESS_STATUS.IN_PROGRESS &&
                  test.libtype === constants.libTypeIds.CODING_CHALLENGE
                    ? "Resume test"
                    : "Take test",
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
