import AlertContext from "./AlertContext";
import { getDataFromStorage } from "../util";
import React, { useRef, createContext, useState, useContext } from "react";

export const StudentTestPageContext = createContext();

export default function StudentTestPageProvider({ children }) {
  const Alert = useContext(AlertContext);

  let viewTest = Alert.mcqAndDesTestAndLpId.viewTest;
  let isStudent = getDataFromStorage("role") === "learner";
  let isTrainer = getDataFromStorage("role") === "trainer";
  let isLearner = getDataFromStorage("role") === "learner";

  const videoQnNo = useRef(0);
  const showSubmitBtn = useRef(false);

  const [sRender, setSRender] = useState(0);
  const [selSec, setSelSec] = useState("all");
  const [secIndex, setSecIndex] = useState(0);
  const [prevSec, setPrevSec] = useState(false);
  const [nextSec, setNextSec] = useState(false);
  const [fSections, setFSections] = useState([]);
  const [mulRevch, setMulRevch] = useState(false);
  const [evalComp, setEvalComp] = useState(false);
  const [showReview, setShowReview] = useState([]);
  const [sectionObj, setSectionObj] = useState({});
  const [descScores, setDescScores] = useState({});
  const [giveZeros, setGiveZeros] = useState(false);
  const [apiLoaded, setApiLoaded] = useState(false);
  const [scoreErrors, setScoreErrors] = useState({});
  const [prevButton, setPrevButton] = useState(false);
  const [nextButton, setNextButton] = useState(false);
  const [startVideo, setStartVideo] = useState(false);
  const [curObj, setCurObj] = useState({ review: -1 });
  const [cameraPopup, setCameraPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showSaqLaqAns, setShowSaqLaqAns] = useState(false);
  const [disableNxtBtn, setDisableNxtBtn] = useState(false);
  const [showtransition, setshowtransition] = useState(false);
  const [hasScoreErrors, setHasScoreErrors] = useState(false);
  const [showTranscript, setShowTranscript] = useState(false);
  const [singleVdoSubmit, setSingleVdoSubmit] = useState(false);
  const [questionIdOffError, setQuestionIdOffError] = useState(0);
  const [mcqAndDesTestAndLpId, setMcqAndDesTestAndLpId] = useState({});
  const [currentScore, setCurrentScore] = useState(Alert.totalLearnerScore);

  let [viewFilters, setViewFilters] = useState({
    tq: true,
    qa: false,
    na: false,
    ca: false,
    wa: false,
    ea: false,
    me: false,
    ha: false,
  });

  const [videoQuestionAttempt, setVideoQuestionAttempt] = useState(
    Alert.comingObj.videoqn === 1
  );

  const [vdoQnTimelimit, setVdoQnTimelimit] = useState(
    Alert.comingObj.videoqn === 1
      ? Alert.videoQn.current?.filter((data) => data?.selected)[0]?.qnduration
      : 0
  );

  return (
    <StudentTestPageContext.Provider
      value={{
        curObj,
        selSec,
        sRender,
        prevSec,
        nextSec,
        viewTest,
        mulRevch,
        secIndex,
        evalComp,
        setCurObj,
        giveZeros,
        fSections,
        setSelSec,
        isStudent,
        isLearner,
        apiLoaded,
        videoQnNo,
        isTrainer,
        descScores,
        sectionObj,
        setNextSec,
        setPrevSec,
        showReview,
        setSRender,
        prevButton,
        nextButton,
        startVideo,
        cameraPopup,
        scoreErrors,
        setEvalComp,
        setSecIndex,
        setMulRevch,
        viewFilters,
        errorMessage,
        setApiLoaded,
        setGiveZeros,
        setFSections,
        currentScore,
        disableNxtBtn,
        showSaqLaqAns,
        showSubmitBtn,
        setSectionObj,
        setDescScores,
        setShowReview,
        setStartVideo,
        setPrevButton,
        setNextButton,
        showTranscript,
        hasScoreErrors,
        setCameraPopup,
        setViewFilters,
        showtransition,
        setScoreErrors,
        vdoQnTimelimit,
        setErrorMessage,
        setCurrentScore,
        singleVdoSubmit,
        setShowSaqLaqAns,
        setDisableNxtBtn,
        setHasScoreErrors,
        setshowtransition,
        setShowTranscript,
        setVdoQnTimelimit,
        setSingleVdoSubmit,
        questionIdOffError,
        videoQuestionAttempt,
        mcqAndDesTestAndLpId,
        setQuestionIdOffError,
        setVideoQuestionAttempt,
        setMcqAndDesTestAndLpId,
      }}
    >
      {children}
    </StudentTestPageContext.Provider>
  );
}
