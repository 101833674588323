import "./Details.scss";
import FormData from "form-data";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { Offcanvas } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { getPreSignedUrl, getDataFromStorage } from "../../../../util";
import AlertContext from "../../../../context/AlertContext";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import {
  Typography,
  IconButton,
  Tooltip,
  Card,
  CardActionArea,
  CardContent,
  Backdrop,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import React, { useState, useEffect, useContext, useRef } from "react";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import constants from "../../../../constants";
import { makeStyles } from "@material-ui/core/styles";
import ZoomAdminView from "../../../Zoom/ZoomAdminView";
import Checkbox from "../../../CommonComponents/Checkbox";
import Radio from "../../../CommonComponents/Radio";
import ToggleButton from "../../../CommonComponents/ToggleButton";

const useStyles = makeStyles({
  checkbox: {
    "&.Mui-checked": {
      color: "#f55533", // Change the color when checked here
    },
  },
});
const emailBody=`<table style="border-collapse: collapse;border:0">
                                        <tr>
                                            <td>
                                                <table style="border-collapse: collapse;">
                                                    <tr>
                                                        <td
                                                            style="font-weight: 400; font-size: 14px; line-height: 24px;">
                                                            Welcome to the Gradious Learning Platform (LEAP)! We are
                                                            thrilled to
                                                            have you on board and excited to help you embark on your
                                                            learning
                                                            journey with us.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style="font-weight: 400; font-size: 14px; line-height: 24px; padding: 8px 0 0;">
                                                            Our Learning Management System (LMS) is designed to provide
                                                            you with an
                                                            exceptional educational experience, offering a wide range of
                                                            resources,
                                                            interactive tools, and support to help you achieve your
                                                            learning goals.
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding: 16px 0 0;">
                                                <table style="border-collapse: collapse;">
                                                    <tr>
                                                        <td
                                                            style="font-weight: 400; font-size: 14px; line-height: 24px;">
                                                            You can get started by:
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <ol style="margin: 0; padding-top: 8px;">
                                                                <li
                                                                    style="font-weight: 400; font-size: 14px; line-height: 24px; padding-bottom: 8px;">
                                                                    Visit our LMS platform at
                                                                    <span>
                                                                        <a href="https://leap.gradious.com"
                                                                            data-saferedirecturl="https:leap.gradious.com"
                                                                            target="_blank" rel="noopener noreferrer"
                                                                            style="color: #175CD3; text-decoration: none;">
                                                                            [leap.gradious.com]
                                                                        </a>.
                                                                    </span>
                                                                </li>
                                                                <li
                                                                    style="font-weight: 400; font-size: 14px; line-height: 24px;">
                                                                    Login using “Sign-in with Google” with the same
                                                                    Google account
                                                                    you received this mail in.
                                                                </li>
                                                            </ol>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style="font-weight: 400; font-size: 14px; line-height: 24px; padding-top: 16px;">
                                                Thank you for choosing Gradious Technologies as your learning partner.
                                                Let’s begin
                                                this exciting journey together!
                                            </td>
                                        </tr>
                                    </table>`
const Details = (Props) => {
  const classes = useStyles();
  const {
    setMsg,
    setShow,
    setTitle,
    setIsLoaded,
    setIsUnAuth,
    batchDetails,
    setBatchDetails,
  } = useContext(AlertContext);
  const axios = useAxiosPrivate();
  const [isSuccess, setIsSuccess] = useState(false);
  const [savebtnHide, setSavebtnHide] = useState(false);
  const [courseDetails, setCourseDetails] = useState({});
  const [learningPaths, setLearningPaths] = useState([]);
  const [showlive, setshowlive] = useState(false);
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [updatedThumbnail, setUpdatedThumbnail] = useState("");
  const [userDetailByEmail, setUserDetailByEmail] = useState([]);
  const [showUserFormPopup, setShowUserFormPopup] = useState(false);
  const [isChangeNewDetails, setIsChangeNewDetails] = useState(false);
  const [isChangeOldDetails, setIsChangeOldDetails] = useState(false);
  const [openUserUploadModal, setOpenUploadUserModal] = useState(false);
  const [trainerData, setTrainerData] = useState([]);
  let [courseNameForExitLp, setCourseNameForExistLp] = useState(
    batchDetails && batchDetails.learningpath?.filter((lp) => lp.lpid !== null)
  );

  const [role, setRole] = useState(constants.Roles.trainer);

  const [newCourseDetails, setNewCourseDetails] = useState({
    learningpath: "",
    newcoursename: "",
    learningpathid: "",
    learningpathname: "",
  });

  const [checkExistEmail, setCheckExistEmail] = useState({
    batchid: batchDetails.id,
    emailBody:
      emailBody,
  });

  const [newUserInput, setNewUserInput] = useState({
    batchid: batchDetails.id,
    emailBody:
      emailBody,
    role: "trainer",
  });
  const [settings, setSettings] = useState({
    archived: batchDetails.archived,
    useorder: batchDetails.useorder,
    type: batchDetails.batchtype,
    slackid: batchDetails.slackid,
  });

  /* Maintain order ref */
  const inputRef = useRef(null);

  const courseLpOption = [
    { label: "FullStack", value: "1" },
    { label: "MernStack", value: "2" },
  ];

  const customStylesForCourseLp = {
    control: (base, state) => ({
      ...base,
      width: "246px",
      height: "20px !important",
      color: "000000",
      textAlign: "left",
      borderRadius: "4px",
      paddingLeft: "10px",
      marginBottom: "-5px",
      backgroundColor: "#ffffff",
      border: "1px solid #BDC1C6",
      innerHeight: "20px !important",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        padding: "8px",
        fontSize: "12px",
        fontWeight: "600",
        textAlign: "left",
        lineHeight: "15px",
        fontFamily: "Inter",
        color: isSelected ? "#000000" : undefined,
        backgroundColor: isSelected
          ? "#ffffff"
          : isFocused
          ? "#e9ecef"
          : undefined,
      };
    },
    singleValue: (styles) => ({
      ...styles,
      color: "#000000",
      fontSize: "12px",
      fontWeight: "600",
      lineHeight: "15px",
      fontFamily: "Inter",
      fontStyle: "normal",
      textAlign: "center",
      width: "max-content",
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "14px",
      color: "#BDC1C6",
      fontWeight: "400",
      lineHeight: "20px",
      fontFamily: "Inter",
      fontStyle: "normal",
    }),
    menuList: (base) => ({
      ...base,
      height: "150px",
    }),
  };

  // ----------------add user and file---------------------//
  const hanldeAddUser = () => {
    setNewUserInput({ ...newUserInput, role: "trainer" });
    setShowUserFormPopup(true);
    setCheckExistEmail({
      batchid: batchDetails.id,
      emailBody: newUserInput.emailBody,
    });
  };

  const handleClosePopup = () => {
    setShowUserFormPopup(false);
    setIsExistingUser(false);
    setCheckExistEmail({
      batchid: null,
      emailBody:
        emailBody,
    });
    setNewUserInput({
      batchid: batchDetails.id,
      emailBody:
        emailBody,
    });
    setUserDetailByEmail([]);
  };
  const handleUploadUserfile = (event, file) => {
    if (!file.size) return;

    let reader = new FileReader();

    reader.onload = async (e) => {
      if (!e.target.result.includes("data:text/csv")) {
        setShow(true);
        setTitle("Warning !");
        setMsg("Wrong file type - CSV only.");
      } else {
        setBatchDetails({
          ...batchDetails,
          csv: file,
          from: "uploadusersinsidebatch",
        });
        setOpenUploadUserModal(true);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleSelectRole = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setRole(value);
    setNewUserInput({ ...newUserInput, [name]: value });
  };

  const handleInputEmailChange = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;

    setCheckExistEmail({ ...checkExistEmail, [name]: value });
    setNewUserInput({ ...newUserInput, [name]: value });
  };

  const handleInputChange = (e, field) => {
    if (field === "emailBody") setNewUserInput({ ...newUserInput, [field]: e });
    else {
      let target = e.target;
      let name = target.name;
      let value = target.value;

      setNewUserInput({ ...newUserInput, [name]: value });
    }
  };

  const handleCreateUser = (event) => {
    event.preventDefault();

    let role = "trainer";
    let num = document.uservalidateForm.mobile.value;
    let emailid = document.uservalidateForm.email.value;
    let lname = document.uservalidateForm.lastname.value;
    let fname = document.uservalidateForm.firstname.value;
    let college = document.uservalidateForm.college.value;

    if (role !== "") {
      if (fname !== "") {
        if (/^[A-Za-z. ]+$/.test(fname)) {
          if (/^[A-Za-z. ]+$/.test(lname) || lname === "") {
            if (emailid !== "") {
              if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailid)) {
                if (/^[A-Za-z. ]+$/.test(college) || college === "") {
                  if (
                    /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(num) ||
                    num === ""
                  ) {
                    setIsLoaded(true);
                    axios
                      .post(
                        "node/admin/batch/user/add",
                        JSON.stringify(newUserInput),
                        {
                          headers: {
                            "Content-type": "application/json",
                          },
                        }
                      )
                      .then((res) => {
                        setIsLoaded(false);

                        if (res.data.resultCode === 1000) {
                          setShow(true);
                          setTitle("Success");
                          setMsg(res.data.data);
                          handleClosePopup();
                        } else if (
                          res.data.resultCode === 2050 &&
                          res.data.msg.includes("Duplicate entry")
                        ) {
                          setShow(true);
                          setTitle("Warning !");
                          setMsg("This email has already exists");
                        } else {
                          setIsLoaded(false);
                          setShow(true);
                          setTitle("Error !");
                          setMsg("Network error");
                        }
                      })
                      .catch((error) => {
                        setIsLoaded(false);
                        if (error.message.includes("403")) {
                          setShow(true);
                          setIsUnAuth(true);
                          setTitle("Error");
                          setMsg(
                            "You have been logged-out due to inactivity. Login again."
                          );
                        }
                      });
                  } else {
                    setShow(true);
                    setTitle("Info");
                    setMsg("You have entered an invalid mobile number!");
                  }
                } else {
                  setShow(true);
                  setTitle("Info");
                  setMsg("You have entered invalid college name !");
                }
              } else {
                setShow(true);
                setTitle("Info");
                setMsg("You have entered an invalid email !");
              }
            } else {
              setShow(true);
              setTitle("Info");
              setMsg("Email can't be blank !");
            }
          } else {
            setShow(true);
            setTitle("Info");
            setMsg("Invalid lastname");
          }
        } else {
          setShow(true);
          setTitle("Info");
          setMsg("Invalid firstname");
        }
      } else {
        setShow(true);
        setTitle("Info");
        setMsg("Please enter your firstname");
      }
    } else {
      setShow(true);
      setTitle("Info");
      setMsg("Please select the role");
    }
  };

  const handleNewCourseDetails = (e, field) => {
    let newDetails = { ...newCourseDetails };

    if (field === "learningpath") {
      newDetails.learningpath = e;
      newDetails.batchid = batchDetails.id;
      newDetails.learningpathname = e.label;
      newDetails.batchname = batchDetails.name;
      newDetails.learningpathid = Number(e.value);
      setNewCourseDetails(newDetails);
    } else {
      let fieldName = e.target.name;
      let fieldValue = e.target.value;

      newDetails[fieldName] = fieldValue;
      newDetails.batchid = batchDetails.id;
      newDetails.batchname = batchDetails.name;
      setNewCourseDetails(newDetails);
    }

    if (
      newDetails.hasOwnProperty("newcoursename") &&
      newDetails.hasOwnProperty("learningpathname")
    ) {
      if (
        newDetails.newcoursename !== "" &&
        newDetails.learningpathname !== ""
      ) {
        setIsChangeNewDetails(true);
      } else {
        setIsChangeNewDetails(false);
      }
    } else {
      setIsChangeNewDetails(false);
    }
  };

  const handleCourseName = (e, lpid) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;

    setCourseDetails({
      lpid: lpid,
      [fieldName]: fieldValue,
      batchid: batchDetails.id,
    });
    setCourseNameForExistLp((courseNameForExitLp) =>
      courseNameForExitLp.map((lp) => {
        if (lp.lpid === lpid) {
          lp[fieldName] = fieldValue;
        }

        return lp;
      })
    );
  };

  let MAX_SIZE = 150000;

  const handleChangeThumbnail = (event) => {
    let reader = new FileReader();

    reader.onloadend = async () => {
      let fileType = undefined;

      if (reader.result.includes("data:image/jpeg")) fileType = "image/jpeg";
      else if (reader.result.includes("data:image/png")) fileType = "image/png";
      else {
        setShow(true);
        setTitle("Warning !");
        setMsg("Supporting formats are JPEG and PNG.");
        event.target.value = "";
      }

      if (
        event.target.files[0] != undefined &&
        event.target.files[0].size > MAX_SIZE
      ) {
        setShow(true);
        setTitle("Warning !");
        setMsg("Image size should be below 150kb ");
        event.target.value = "";
        fileType = undefined;
      }

      if (fileType !== undefined) {
        setIsLoaded(true);
        axios
          .post(
            "node/admin/batch/updatethumbnail",
            JSON.stringify({
              newimage: reader.result,
              batchid: batchDetails.id,
            }),
            {
              headers: {
                "Content-type": "application/json",
              },
            }
          )
          .then((res) => {
            if (res.data.resultCode === 1000) {
              setShow(true);
              setIsSuccess(true);
              setTitle("Success");
              setMsg("Thumbnail is changed");
              setUpdatedThumbnail(reader.result);
            } else {
              setShow(true);
              setTitle("Error");
              setMsg("Network error");
            }

            setIsLoaded(false);
            fileType = undefined;
            event.target.value = "";
          })
          .catch((err) => {
            setIsLoaded(false);

            if (err.message.includes("403")) {
              setShow(true);
              setIsUnAuth(true);
              setTitle("Error");
              setMsg(
                "You have been logged-out due to inactivity. Login again."
              );
            } else {
              setShow(true);
              setTitle("Error");
              setMsg(err.message);
            }
          });
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleAddCourseNametoDB = () => {
    if (courseDetails.coursename !== "") {
      setIsLoaded(true);
      axios
        .post(
          "node/admin/batch/coursename/create",
          JSON.stringify(courseDetails),
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.resultCode === 1000) {
            setShow(true);
            setTitle("Success");
            setMsg(res.data.data);

            let batchDetail = { ...batchDetails };

            for (let i = 0; i < batchDetail.learningpath.length; i++) {
              if (batchDetail.learningpath[i].lpid === courseDetails.lpid) {
                batchDetail.learningpath[i].coursename =
                  courseDetails.coursename;
                batchDetail.learningpath[i].lpname = courseDetails.coursename;
              }
            }

            setBatchDetails(batchDetail);
            setCourseDetails({});
          } else if (
            res.data.resultCode === 2050 &&
            res.data.msg.includes("Duplicate entry")
          ) {
            setShow(true);
            setTitle("Warning !");
            setMsg(
              "This course name is already exist. Please give a different name"
            );
          } else {
            setShow(true);
            setTitle("Error");
            setMsg("Network error");
          }

          setIsLoaded(false);
        })
        .catch((err) => {
          setIsLoaded(false);

          if (err.message.includes("403")) {
            setIsUnAuth(true);
            setShow(true);
            setTitle("Error");
            setMsg("You have been logged-out due to inactivity. Login again.");
          }
        });
    }

    let id = courseNameForExitLp.length + 1;
    let element = document.getElementById(String(id));

    element.focus();
    setCourseDetails({});
  };

  const handleAddNewCourseDetails = () => {
    if (
      newCourseDetails.hasOwnProperty("newcoursename") &&
      newCourseDetails.hasOwnProperty("learningpathid")
    ) {
      if (
        newCourseDetails.newcoursename !== "" &&
        newCourseDetails.learningpathid !== ""
      ) {
        setIsLoaded(true);
        axios
          .post(
            "node/admin/batch/learningpath/create",
            JSON.stringify(newCourseDetails),
            {
              headers: {
                "Content-type": "application/json",
              },
            }
          )
          .then((res) => {
            if (res.data.resultCode === 1000) {
              setShow(true);
              setIsSuccess(true);
              setUpdatedThumbnail(
                isSuccess ? updatedThumbnail : batchDetails.thumbnail
              );

              let response = res.data.data;

              setIsChangeNewDetails(false);
              response.coursename = newCourseDetails.newcoursename;
              response.lpname = newCourseDetails.newcoursename;

              let batchDetail = { ...batchDetails };

              batchDetail.learningpath.push(response);

              for (let i = 0; i < batchDetail.learningpath.length; i++) {
                if (batchDetail.learningpath[i].lpid === null)
                  batchDetail.learningpath.splice(i, 1);
              }

              setBatchDetails(batchDetail);
              setTitle("Success");
              setMsg(
                "New learning path is created for " +
                  batchDetails.name +
                  " batch"
              );
              setIsLoaded(false);
              setCourseDetails({});
              setNewCourseDetails({
                learningpath: "",
                newcoursename: "",
                learningpathid: "",
                learningpathname: "",
              });
            } else if (
              res.data.resultCode === 2050 &&
              res.data.msg.includes("Duplicate entry")
            ) {
              setIsLoaded(false);
              setShow(true);
              setTitle("Warning !");
              setMsg(
                "This course name is already exists. Please give a different name"
              );
            } else {
              setIsLoaded(false);
              setShow(true);
              setTitle("Error");
              setMsg("Network error");
            }
          })
          .catch((err) => {
            setIsLoaded(false);

            if (err.message.includes("403")) {
              setShow(true);
              setIsUnAuth(true);
              setTitle("Error");
              setMsg(
                "You have been logged-out due to inactivity. Login again."
              );
            }
          });
      } else {
        setShow(true);
        setTitle("Warning !");
        setMsg("Please give the course name");
      }
    } else if (!newCourseDetails.hasOwnProperty("newcoursename")) {
      setShow(true);
      setTitle("Warning !");
      setMsg("Please give the course name");
    } else {
      setShow(true);
      setTitle("Warning !");
      setMsg("Please give the learning path");
    }
  };

  const checkEmail = (event) => {
    event.preventDefault();

    if (Object.keys(checkExistEmail).length === 3) {
      setIsLoaded(true);
      axios
        .post("node/admin/user/get", JSON.stringify(checkExistEmail), {
          headers: {
            "Content-type": "application/json",
          },
        })
        .then((res) => {
          setIsLoaded(false);

          if (res.data.resultCode === 1000 && res.data.data !== null) {
            setIsExistingUser(true);

            if (res.data.data.batchid === null) {
              setSavebtnHide(true);
              setUserDetailByEmail(res.data.data);
              setShow(true);
              setTitle("Info");
              if (res.data.data.role === "admin") {
                setMsg("User is An admin,You cannot add as a trainer");
                setUserDetailByEmail([]);
                setSavebtnHide(false);
              } else if (res.data.data.role === "learner") {
                setMsg("User is learner,You cannot add as a trainer");
                setUserDetailByEmail([]);
                setSavebtnHide(false);
              } else if (res.data.data.role === "trainer") {
                setRole(res.data.data.role);
                setMsg(
                  "User belongs to some other batch as trainer . if you click on the save button user will be added to this batch."
                );
              }
              else if (res.data.data.role === "hr") {
                setRole(res.data.data.role);
                setMsg(
                  "User belongs to some other batch as T.P.O . if you click on the save button user will be added as T.P.O to this batch."
                );
              }
            } else {
              setSavebtnHide(false);
              setUserDetailByEmail(res.data.data);
              setShow(true);
              setTitle("Info");
              setRole(res.data.data.role);
              setMsg(
                "User Already exists in this batch as " + res.data.data.role
              );
            }
          } else if (
            res.data.resultCode === 1000 &&
            res.data.msg.includes("No data")
          ) {
            setSavebtnHide(true);
            setUserDetailByEmail([]);
            setIsExistingUser(false);
          } else {
            setIsLoaded(false);
            setShow(true);
            setTitle("Warning");
            setMsg("Network Error");
          }
        })
        .catch((err) => {
          setIsLoaded(false);

          if (err.message.includes("403")) {
            setShow(true);
            setIsUnAuth(true);
            setTitle("Error");
            setMsg(err.message);
          }
        });
    } else {
      setShow(true);
      setTitle("Info");
      setMsg("Please Enter email");
    }
  };

  /* dowmload template */

  const downloadUserTemplate = async () => {
    let URL = "";

    if (process.env.REACT_APP_DEPLOYMENT === "LOCAL")
      URL = "../../../assets/files/TEMPLATE_USER.csv";
    else URL = await getPreSignedUrl("Templates/TEMPLATE_USER.csv");
    window.location.href = URL;
  };

  useEffect(() => {
    setIsLoaded(true);

    axios
      .get(`node/admin/batchtrainers/get/` + batchDetails.id, {
        headers: {
          "Content-type": "application/json",
        },
        // signal:controller.signal
      })
      .then((response) => {
        if (response.data.resultCode === 1000) {
          if (response.data.data !== null) {
            let res = response.data.data;
            setTrainerData(res);
          }
        }
        setIsLoaded(false);
      })
      .catch((err) => {
        setIsLoaded(false);

        if (err.message.includes("403")) {
          setShow(true);
          setIsUnAuth(true);
          setTitle("Error");
          setMsg("You have been logged-out due to inactivity. Login again.");
        } else {
          setShow(true);
          setTitle("Error");
          setMsg(err.message);
        }
      });
  }, []);

  useEffect(() => {
    setNewUserInput({ ...newUserInput, role: "learner" });
    setIsLoaded(true);
    // const controller=new AbortController()
    axios
      .get("node/admin/learningpath/get", {
        headers: {
          "Content-type": "application/json",
        },
        // signal:controller.signal
      })
      .then((response) => {
        if (response.data.resultCode === 1000) {
          if (response.data.data !== null) {
            let res = response.data.data.filter((lp) => lp.archived == 0);

            for (let i = 0; i < batchDetails.learningpath.length; i++) {
              for (let j = 0; j < res.length; j++) {
                if (res[j].id === batchDetails.learningpath[i].lpid) {
                  res.splice(j, 1);
                }
              }
            }

            setLearningPaths(res);
          } else {
            setLearningPaths([]);
            setShow(true);
            setTitle("Warning !");
            setMsg("There is no Learning Path");
          }
        } else {
          setShow(true);
          setTitle("Error");
          setMsg("Network error");
        }

        setIsLoaded(false);
      })
      .catch((err) => {
        setIsLoaded(false);

        if (err.message.includes("403")) {
          setShow(true);
          setIsUnAuth(true);
          setTitle("Error");
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      });
    // controller.abort()
  }, []);

  useEffect(() => {
    if (courseDetails.hasOwnProperty("coursename")) {
      if (courseDetails.coursename !== "") {
        setIsChangeOldDetails(true);
      } else {
        setIsChangeOldDetails(false);
      }
    } else {
      setIsChangeOldDetails(false);
    }
  }, [handleCourseName]);

  const removeTrainer = async (userId) => {
    setIsLoaded(true);

    try {
      let res = await axios.post(
        "node/admin/batch/users/status",
        JSON.stringify({
          userIds: [userId],
          active: false,
          batchId: batchDetails.id,
          removeTrainer: true,
        }),
        { headers: { "Content-type": "application/json" } }
      );

      if (res.data.resultCode === 1000) {
        setIsLoaded(false);
        setShow(true);
        setTitle("Info");
        setMsg(res.data.msg);
        setTrainerData(res.data.data);
      } else {
        setIsLoaded(false);
        setShow(true);
        setTitle("Warning");
        setMsg(res.data.message);
      }
    } catch (error) {
      setIsLoaded(false);
      if (error.message.includes("403")) {
        setShow(true);
        setIsUnAuth(true);
        setMsg("You have been logged-out due to inactivity. Login again.");
        setTitle("Warning !");
      } else {
        console.error(error);
        setShow(true);
        setMsg(error.message);
        setTitle("Error!");
      }
    }
  };

  useEffect(() => {
    setIsLoaded(true);
    axios
      .put(
        `node/admin/batch/settings/${batchDetails.id}`,
        { ...settings },
        {
          headers: { "Content-type": "application/json" },
        }
      )
      .then((response) => {
        if (response.data.resultCode !== 1000) {
          setShow(true);
          setTitle("Error !");
          setMsg("Error in update batch settings.");
        }
      })
      .catch((error) => {
        setShow(true);
        if (error.message.includes("403")) {
          setIsUnAuth(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
          setTitle("Warning !");
        } else {
          setShow(true);
          setMsg("Something went wrong. Please try again later.");
          setTitle("Error!");
        }
      })
      .finally(() => setIsLoaded(false));
  }, [settings]);

  return (
    <div>
      <div className="bdBatchDetails">
        <div className="bdInBatchName">{batchDetails.name}</div>
        <div className="bdInBatchDes">{batchDetails.description}</div>
        <div style={{ display: "flex" }}>
          <p className="bdInBatchStartDateHead">Start date :</p>
          <p className="bdInBatchStartDate">
            {(new Date(batchDetails.startdate).getDate() > 9
              ? new Date(batchDetails.startdate).getDate()
              : `0${new Date(batchDetails.startdate).getDate()}`) +
              "/" +
              (new Date(batchDetails.startdate).getMonth() + 1 > 9
                ? new Date(batchDetails.startdate).getMonth() + 1
                : `0${new Date(batchDetails.startdate).getMonth() + 1}`) +
              "/" +
              new Date(batchDetails.startdate).getFullYear()}
          </p>
          {/* <div className="zoom-live-watch">
             <Button
              variant="primary"
              id="bdAddUserBtn"
              onClick={() => {
                // document.getElementById('zmmtg-root').style.visibility = 'hidden';
                setshowlive(!showlive);
              }}
            >
              Live Watch
            </Button> 
            {showlive && <ZoomAdminView showlive={showlive} />}
          </div> */}
        </div>
        {parseInt(getDataFromStorage("accountType")) ===
        constants.ACCOUNT_TYPE.LMS ? (
          <div className="bdUserHeadSecIn">
            {/* <Tooltip title="TEMPLATE_USER.csv">
            <IconButton>
              <svg
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 16 16"
                onClick={downloadUserTemplate}
                id="batchInDownloadUserTemplate"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#0671E0"
                  d="M14.75 9.5C14.5511 9.5 14.3603 9.57902 14.2197 9.71967C14.079 9.86032 14 10.0511 14 10.25V13.25C14 13.4489 13.921 13.6397 13.7803 13.7803C13.6397 13.921 13.4489 14 13.25 14H2.75C2.55109 14 2.36032 13.921 2.21967 13.7803C2.07902 13.6397 2 13.4489 2 13.25V10.25C2 10.0511 1.92098 9.86032 1.78033 9.71967C1.63968 9.57902 1.44891 9.5 1.25 9.5C1.05109 9.5 0.860322 9.57902 0.71967 9.71967C0.579018 9.86032 0.5 10.0511 0.5 10.25V13.25C0.5 13.8467 0.737053 14.419 1.15901 14.841C1.58097 15.2629 2.15326 15.5 2.75 15.5H13.25C13.8467 15.5 14.419 15.2629 14.841 14.841C15.2629 14.419 15.5 13.8467 15.5 13.25V10.25C15.5 10.0511 15.421 9.86032 15.2803 9.71967C15.1397 9.57902 14.9489 9.5 14.75 9.5ZM7.4675 10.7825C7.53883 10.8508 7.62294 10.9043 7.715 10.94C7.80478 10.9797 7.90185 11.0002 8 11.0002C8.09815 11.0002 8.19522 10.9797 8.285 10.94C8.37706 10.9043 8.46117 10.8508 8.5325 10.7825L11.5325 7.7825C11.6737 7.64127 11.7531 7.44973 11.7531 7.25C11.7531 7.05027 11.6737 6.85873 11.5325 6.7175C11.3913 6.57627 11.1997 6.49693 11 6.49693C10.8003 6.49693 10.6087 6.57627 10.4675 6.7175L8.75 8.4425V1.25C8.75 1.05109 8.67098 0.860322 8.53033 0.71967C8.38968 0.579018 8.19891 0.5 8 0.5C7.80109 0.5 7.61032 0.579018 7.46967 0.71967C7.32902 0.860322 7.25 1.05109 7.25 1.25V8.4425L5.5325 6.7175C5.46257 6.64757 5.37955 6.5921 5.28819 6.55426C5.19682 6.51641 5.09889 6.49693 5 6.49693C4.90111 6.49693 4.80318 6.51641 4.71181 6.55426C4.62045 6.5921 4.53743 6.64757 4.4675 6.7175C4.39757 6.78743 4.3421 6.87045 4.30426 6.96181C4.26641 7.05318 4.24693 7.15111 4.24693 7.25C4.24693 7.34889 4.26641 7.44682 4.30426 7.53819C4.3421 7.62955 4.39757 7.71257 4.4675 7.7825L7.4675 10.7825Z"
                />
              </svg>
            </IconButton>
          </Tooltip>
          <div
            className="upload-btn-wrapper"
            style={{
              display: "inline-block",
              height: "34px",
              width: "36px",
              margin: "0 0 0 10px",
            }}
          >
            <button className="bdUserUploadIconBtn">
              <FontAwesomeIcon id="bdUserUploadIcon" icon={faUpload} />
            </button>
            <input
              type="file"
              name="myfile"
              ref={inputRef}
              style={{ width: "45px", height: "35px" }}
              accept=".csv"
              onChange={(event) =>
                handleUploadUserfile(event, event.target.files[0])
              }
            />
          </div> */}
            <UploadUser
              openUploadUserModal={openUserUploadModal}
              setOpenUploadUserModal={setOpenUploadUserModal}
              batchDetails={batchDetails}
              setBatchDetails={setBatchDetails}
              setIsLoaded={setIsLoaded}
              setIsUnAuth={setIsUnAuth}
              setMsg={setMsg}
              setTitle={setTitle}
              setShow={setShow}
              setUpdatedThumbnail={setUpdatedThumbnail}
              setIsSuccess={setIsSuccess}
              inputRef={inputRef}
            />
            <div style={{ display: "inline-block",position:"relative",right:"25px" }}>
              <div className="addUserBtn">
                <Button
                  variant="primary"
                  id="bdAddUserBtn"
                  onClick={hanldeAddUser}
                >
                  Add Administrator
                </Button>
              </div>
              <Offcanvas
                placement="end"
                id="addUserContainer"
                show={showUserFormPopup}
                onHide={handleClosePopup}
              >
                <div className="addUserTitleSection">
                  <p className="addUserTitle">New Trainer</p>
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={handleClosePopup}
                    className="addUserCloseIcon"
                  />
                </div>
                <div className="addUserHrLine"></div>
                <Form
                  noValidate
                  className="addUserform"
                  name="uservalidateForm"
                >
                  <div className="userDetails">
                    {/* <Form.Group className="mb-3" id="add-user-select-role">
                    <div id="roleLabel">
                      <b> Role </b>
                    </div>
                    <div className="add-user-select-role-option">
                      <input
                        name="role"
                        type="radio"
                        value="learner"
                        checked={
                          isExistingUser
                            ? userDetailByEmail?.role === "learner"
                              ? true
                              : false
                            : newUserInput["role"] == "learner"
                        }
                        onChange={(e) => handleSelectRole(e)}
                      />
                      <div className="add-user-select-role-label">Learner</div>
                    </div>
                    <div className="add-user-select-role-option">
                      <input
                        name="role"
                        type="radio"
                        value="trainer"
                        checked={
                          isExistingUser
                            ? userDetailByEmail?.role === "trainer"
                              ? true
                              : false
                            : newUserInput["role"] == "trainer"
                        }
                        disabled={getDataFromStorage("role") == "trainer"}
                        onChange={(e) => handleSelectRole(e)}
                      />
                      <div className="add-user-select-role-label">Trainer</div>
                    </div>
                    
                  </Form.Group> */}

                    <Form.Group className="mb-3" id="add-user-select-role">
                      <div id="roleLabel">
                        <b> Role </b>
                      </div>
                      <div className="add-user-select-role-option">
                        <input
                          name="role"
                          type="radio"
                          value="trainer"
                          checked={
                            role == constants.Roles.trainer
                            // isExistingUser
                            //   ? userDetailByEmail?.role === "trainer"
                            //     ? true
                            //     : false
                            //   : newUserInput["role"] == "trainer"
                          }
                          onChange={(e) => handleSelectRole(e)}
                        />
                        <div className="add-user-select-role-label">
                          Trainer
                        </div>
                      </div>
                      <div className="add-user-select-role-option">
                        <input
                          name="role"
                          type="radio"
                          value="hr"
                          checked={
                            // isExistingUser
                            //   ? userDetailByEmail?.role === "hr"
                            //     ? true
                            //     : false
                            //   : newUserInput["role"] == "trainer"
                            role == constants.Roles.hr
                          }
                          // disabled={getDataFromStorage("role") == "trainer"}
                          onChange={(e) => handleSelectRole(e)}
                        />
                        <div className="add-user-select-role-label">Hr</div>
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label id="userEmailLabel">
                        <b> Email </b>
                      </Form.Label>
                      <Form.Control
                        required
                        type="email"
                        name="email"
                        id="userEmail"
                        onBlur={checkEmail}
                        placeholder="Enter email..."
                        onChange={handleInputEmailChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label id="userFirstNameLabel">
                        <b> First Name </b>
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="firstname"
                        id="userFirstname"
                        placeholder="Enter firstname..."
                        onChange={(e) => handleInputChange(e)}
                        defaultValue={
                          isExistingUser ? userDetailByEmail?.firstname : ""
                        }
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label id="userLastNameLabel">
                        <b>Last Name </b>
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="lastname"
                        id="userLastname"
                        placeholder="Enter lastname..."
                        onChange={(e) => handleInputChange(e, "lastname")}
                        defaultValue={
                          isExistingUser ? userDetailByEmail?.lastname : ""
                        }
                      />
                    </Form.Group>

                    {/* <Form.Group className="mb-3">
                      <Form.Label id="userBranchLabel">
                        <b>Branch </b>
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="branch"
                        id="userBranch"
                        placeholder="Enter your branch..."
                        onChange={(e) => handleInputChange(e, "branch")}
                        defaultValue={
                          isExistingUser ? userDetailByEmail?.branch : ""
                        }
                      />
                    </Form.Group> */}

                    {role == constants.Roles.hr && (
                      <Form.Group className="mb-3">
                        <Form.Label id="userCollegeLabel">
                          <b>College </b>
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="college"
                          id="userCollege"
                          placeholder="Enter your college..."
                          onChange={(e) => handleInputChange(e, "college")}
                          defaultValue={
                            isExistingUser ? userDetailByEmail?.college : ""
                          }
                        />
                      </Form.Group>
                    )}

                    <Form.Group className="mb-3">
                      <Form.Label id="userPhoneLabel">
                        <b> Phone </b>
                      </Form.Label>
                      <Form.Control
                        required
                        type="tel"
                        name="mobile"
                        id="userPhone"
                        onChange={handleInputChange}
                        placeholder="Enter phone..."
                        defaultValue={
                          isExistingUser ? userDetailByEmail?.mobile : ""
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label id="batchDesLabel">
                        <b>Email </b>
                      </Form.Label>
                      <Editor
                        tinymceScriptSrc="/tinymce/tinymce.min.js"
                        textareaName="emailBody"
                        value={newUserInput.emailBody}
                        onEditorChange={(event) =>
                          handleInputChange(event, "emailBody")
                        }
                        init={{
                          license_key: "gpl",
                          height: 500,
                          menubar: false,
                          branding: false,
                          statusbar: false,
                          elementpath: false,
                          selector: "textarea",
                          allow_html_in_named_anchor: true,
                          plugins: [
                            "code",
                            "help",
                            "lists",
                            "media",
                            "table",
                            "anchor",
                            "charmap",
                            "preview",
                            "advlist",
                            "wordcount",
                            "fullscreen",
                            "autoresize",
                            "visualblocks",
                            "searchreplace",
                            "insertdatetime",
                          ],
                          toolbar:
                            "undo redo | blocks | " +
                            "bold italic underline forecolor  | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help |",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                      />
                    </Form.Group>
                  </div>
                  <hr className="line" />
                  <Button
                    type="submit"
                    id="saveBatchBtn"
                    disabled={!savebtnHide}
                    onClick={handleCreateUser}
                  >
                    Save
                  </Button>
                  <Button id="cancelBatchBtn" onClick={handleClosePopup}>
                    Cancel
                  </Button>
                </Form>
              </Offcanvas>
            </div>
          </div>
        ) : null}
      </div>
      <div className="bdBatchThumbnailSec">
        <div className="bdBatchThumbnailHead">Thumbnail</div>
        <div className="bdBatchThumbnailDetail">
          <img
            alt="Thumbnail"
            className="bdBatchThumbnail"
            src={isSuccess ? updatedThumbnail : batchDetails.thumbnail}
          />
          <div style={{ marginLeft: "63px" }}>
            <div style={{ textAlign: "left" }}>
              <div className="bdInBatchDetails">
                <p className="bdModuleName">Note </p>
                <p className="bdTopicName">
                  {" "}
                  : This image will be used to represent this batch across all
                  your academy's landing pages.
                </p>
              </div>
              <div className="bdInBatchDetails">
                <p className="bdModuleName">Allowed formats </p>
                <p className="bdTopicName">: JPEG or PNG</p>
              </div>
              <div className="bdInBatchDetails">
                <p className="bdModuleName">Preferred minimum resolution </p>
                <p className="bdTopicName">: 480 * 270</p>
              </div>
              <div className="bdInBatchDetails">
                <p className="bdModuleName">Maximum size </p>
                <p className="bdTopicName">: 150kb</p>
              </div>
            </div>
            <div className="bdInBatchDetails">
              <input
                hidden
                type="file"
                accept="image/*"
                id="bdChangeThumbnail"
                onChange={(event) => handleChangeThumbnail(event)}
              />
              <label
                htmlFor="bdChangeThumbnail"
                className="bdChangeThumbnailBtn"
              >
                Change Image
              </label>
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "left" }}>
        <Typography id="bdDetailsSettingTitle">Settings</Typography>
        {getDataFromStorage("role") === "admin" ? (
          <div className="bdDetailsMaintainOrderSec">
            <Checkbox
              size="sm"
              text="Mandate order"
              supportingText="Learner can only access course details in order"
              checked={settings.useorder}
              onChange={(checked) => {
                setSettings({
                  ...settings,
                  useorder: checked,
                });
                setBatchDetails((pre) => ({ ...pre, useorder: checked }));
              }}
            />
          </div>
        ) : null}
        <div className="archivebatchswitch-div">
          <ToggleButton
            size="sm"
            text="Archive"
            pressed={settings.archived}
            onChange={(value) => {
              setSettings({ ...settings, archived: value });
              setBatchDetails((pre) => ({
                ...pre,
                archived: value,
              }));
            }}
          />
        </div>
        {/* <div className="archivebatchswitch-div">
          <Form.Check
            type="switch"
            className="archivebatchswitch"
            id="archive-batch-switch"
            name="archived"
            // disabled={isButtonDisabled}
            checked={settings.archived}
            onChange={(e) => {
              setSettings({ ...settings, [e.target.name]: e.target.checked });
              setBatchDetails((pre) => ({
                ...pre,
                [e.target.name]: e.target.checked,
              }));
            }}
            style={{ boxShadow: "none" }}
          />
          Archive
        </div> */}
        {parseInt(getDataFromStorage("accountType")) ===
          constants.ACCOUNT_TYPE.LMS && (
          <React.Fragment>
            <div className="bdBatchTypeSec">
              <Typography id="bdDetailsSettingTypeTitle">Type :</Typography>
              <Radio
                size="sm"
                checked={settings.type === constants.BATCH_TYPE.LEARNING}
                text="Learning"
                onChange={(checked) => {
                  setSettings({
                    ...settings,
                    type: constants.BATCH_TYPE.LEARNING,
                  });
                  setBatchDetails((pre) => ({
                    ...pre,
                    batchtype: constants.BATCH_TYPE.LEARNING,
                  }));
                }}
              />
              <div style={{ marginLeft: "20px" }}>
                <Radio
                  size="sm"
                  checked={settings.type === constants.BATCH_TYPE.TESTING}
                  text="Evaluation"
                  onChange={(checked) => {
                    setSettings({
                      ...settings,
                      type: constants.BATCH_TYPE.TESTING,
                    });
                    setBatchDetails((pre) => ({
                      ...pre,
                      batchtype: constants.BATCH_TYPE.TESTING,
                    }));
                  }}
                />
              </div>
            </div>

            <div className="bdBatchSlackIdSec">
              <Typography id="bdInSlackIdLabel">Slack Id :</Typography>
              <Form.Control
                required
                type="text"
                name="slackid"
                value={settings.slackid ? settings.slackid : ""}
                // maxLength="100"
                id="bdInSlackId"
                onChange={(e) => {
                  setSettings({
                    ...settings,
                    [e.target.name]: e.target.value,
                  });
                  setBatchDetails((pre) => ({
                    ...pre,
                    slackid: e.target.value,
                  }));
                }}
                placeholder="Enter slack id for batch"
              />
            </div>
          </React.Fragment>
        )}
      </div>

      <div>
        <div className="bdCourseName">Courses</div>
        {courseNameForExitLp.map(
          (lp, index) =>
            lp.lpid &&
            lp.lpname && (
              <div key={lp.lpid} className="bdCourseDetails">
                <input
                  type="text"
                  id={index + 1}
                  name="coursename"
                  placeholder="Name"
                  className="bdCourseNameInput"
                  value={lp.coursename === null ? "" : lp.coursename}
                  onChange={(e) => handleCourseName(e, lp.lpid)}
                />
                <Select
                  isDisabled
                  name="learningpath"
                  options={courseLpOption}
                  id="courseLearningPathSelect"
                  styles={customStylesForCourseLp}
                  placeholder="Select Learningpath"
                  value={[{ label: lp.lpname, value: lp.lpid }]}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
                <div key={lp.lpid} style={{ display: "flex" }}>
                  {isChangeOldDetails && courseDetails.lpid === lp.lpid && (
                    <>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="bdCheckIcon"
                        onClick={handleAddCourseNametoDB}
                      />
                    </>
                  )}
                </div>
              </div>
            )
        )}

        {(parseInt(getDataFromStorage("accountType")) ===
          constants.ACCOUNT_TYPE.LMS ||
          (parseInt(getDataFromStorage("accountType")) ===
            constants.ACCOUNT_TYPE.CLIENT_EVAL &&
            courseNameForExitLp.length === 0)) && (
          <div className="bdCourseDetails">
            <input
              type="text"
              placeholder="Name"
              name="newcoursename"
              className="bdCourseNameInput"
              id={courseNameForExitLp.length + 1}
              value={newCourseDetails.newcoursename}
              onChange={(e) => handleNewCourseDetails(e)}
            />
            <Select
              required
              name="learningpath"
              styles={customStylesForCourseLp}
              placeholder="Select Learningpath"
              value={newCourseDetails.learningpath}
              id={`courseLearningPathSelect ${courseNameForExitLp.length + 2}`}
              options={learningPaths.map((lp) => {
                return { label: lp.name, value: lp.id };
              })}
              onChange={(selectedLp) =>
                handleNewCourseDetails(selectedLp, "learningpath")
              }
            />
            {isChangeNewDetails && (
              <>
                <FontAwesomeIcon
                  icon={faCheck}
                  className="bdCheckIcon"
                  onClick={handleAddNewCourseDetails}
                ></FontAwesomeIcon>
              </>
            )}
          </div>
        )}
      </div>
      {trainerData?.length > 0 &&
      parseInt(getDataFromStorage("accountType")) ===
        constants.ACCOUNT_TYPE.LMS ? (
        <div>
          <div className="bdTrainers">Trainers</div>
          <div className="allTrainers">
            {trainerData.map((trainer, index) => (
              <div className="tritem" key={index}>
                <span className="nameodTrainer">
                  {trainer.firstname + " " + trainer.lastname}{" "}
                </span>
                <FontAwesomeIcon
                  className="faXmark"
                  title="remove Trainer"
                  icon={faTrashCan}
                  onClick={() => removeTrainer(trainer.userid)}
                />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Details;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  border: "1px solid #F55533",
  p: 4,
};

export const UploadUser = ({
  openUploadUserModal,
  setOpenUploadUserModal,
  batchDetails,
  setBatchDetails,
  setIsLoaded,
  setIsUnAuth,
  setMsg,
  setTitle,
  setShow,
  setUpdatedThumbnail,
  setIsSuccess,
  inputRef,
}) => {
  const axios = useAxiosPrivate();

  const handleClose = (event) => {
    event.preventDefault();
    inputRef.current.value = "";
    setBatchDetails({
      ...batchDetails,
      emailBody:
        emailBody,
    });
    setOpenUploadUserModal(false);
  };

  const handleSave = () => {
    const form = new FormData();
    form.append("csv", batchDetails.csv);
    form.append("from", "uploadusersinsidebatch");
    form.append("batchid", batchDetails.id);
    form.append("emailBody", batchDetails.emailBody);

    setOpenUploadUserModal(true);
    setIsLoaded(true);
    axios
      .post("node/admin/batch/userviacsv", form, {
        mode: "no-cors",
        headers: {
          "Content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        setIsLoaded(false);
        if (response.data.resultCode === 1000) {
          setShow(true);
          setTitle("Success");
          setMsg(response.data.msg);
          setUpdatedThumbnail(batchDetails.thumbnail);
          setIsSuccess(true);
        } else if (response.data.resultCode === 2050) {
          setShow(true);
          setTitle("Warning !");
          setMsg(response.data.msg);
          setIsSuccess(true);
        } else {
          setShow(true);
          setTitle("Error");
          setMsg("Something went wrong. Please try again later.");
        }

        inputRef.current.value = "";

        if (response.data.hasOwnProperty("filedata")) {
          const url = window.URL.createObjectURL(
            new Blob([response.data.filedata])
          );
          const link = document.createElement("a");

          link.href = url;
          link.setAttribute(
            "download",
            `${new Date().toISOString().split("T")[0]}.csv`
          );
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((error) => {
        setIsLoaded(false);
        if (error.message.includes("403")) {
          setShow(true);
          setIsUnAuth(true);
          setTitle("Error");
          setMsg("You have been logged-out due to inactivity. Login again.r");
        }

        if (error.data.hasOwnProperty("filedata")) {
          const url = window.URL.createObjectURL(
            new Blob([error.data.filedata])
          );
          const link = document.createElement("a");

          link.href = url;
          link.setAttribute(
            "download",
            `${new Date().toISOString().split("T")[0]}.csv`
          );
          document.body.appendChild(link);
          link.click();
        }
        inputRef.current.value = "";
      })
      .finally(() => setOpenUploadUserModal(false));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openUploadUserModal}
      // onClose={handleClose}

      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openUploadUserModal}>
        <Box sx={style}>
          <Card
            sx={{
              minWidth: 200,
              height: 50,
              display: "flex",
              alignItems: "center",
              background: "#f2f3f5",
            }}
          >
            <CardActionArea
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                mt: 1,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
                <InsertDriveFileOutlinedIcon />
              </Box>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  id="bdUploadUserFile"
                >
                  {batchDetails?.csv?.name}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <div className="bdUploadUserWarningMsgForEmailTextSec">
            <ErrorOutlineOutlinedIcon id="bdUploadUserWarningMsgForEmailIcon" />
            <p className="bdUploadUserWarningMsgForEmailText">
              Email will be sent to all users
            </p>
          </div>
          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            textareaName="emailBody"
            value={batchDetails.emailBody}
            onEditorChange={(event) =>
              setBatchDetails({ ...batchDetails, emailBody: event })
            }
            init={{
              license_key: "gpl",
              height: 500,
              menubar: false,
              branding: false,
              statusbar: false,
              elementpath: false,
              selector: "textarea",
              allow_html_in_named_anchor: true,
              plugins: [
                "code",
                "help",
                "lists",
                "media",
                "table",
                "anchor",
                "charmap",
                "preview",
                "advlist",
                "wordcount",
                "fullscreen",
                "autoresize",
                "visualblocks",
                "searchreplace",
                "insertdatetime",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic underline forecolor  | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help |",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          <div className="bdUserUploadModalBtn">
            <Button
              variant="outlined"
              id="bdUserUploadModalCancelBtn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              id="bdUserUploadModalSaveBtn"
              onClick={handleSave}
            >
              Upload
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
