import React from "react";
import IconButton from "./IconButton";
import PropTypes from "prop-types";

const Modal = ({ isOpen, onClose, children, showCloseIcon }) => {
  if (!isOpen) return null;
  const onClickXMark = (event) => {
    if (onClose) onClose(event);
  };

  return (
    <div className="modalContainer" onClick={onClickXMark}>
      <div
        className="modalContent"
        onClick={(event) => event.stopPropagation()}
      >
        {showCloseIcon && (
          <div className="modalCloseIcon">
            <IconButton
              onClick={onClickXMark}
              size="sm"
              hierarchy="tertiaryGrey"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 3L3 9M3 3L9 9"
                  stroke="#101828"
                  strokeWwidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  showCloseIcon: PropTypes.bool,
};

Modal.defaultProps = {
  isOpen: false,
  children: null,
  showCloseIcon: true,
};
