import React, { useContext } from "react";
import constants from "../../../constants";
import TestSvgIcon from "../../../Svg/TestSvgIcon";
import TimeSvgIcon from "../../../Svg/TimeSvgIcon";
import TopicSvgIcon from "../../../Svg/TopicSvgIcon";
import AlertContext from "../../../context/AlertContext";
import MaxScoreSvgIcon from "../../../Svg/MaxScoreSvgIcon";
import QuestionInfoSvgIcon from "../../../Svg/QuestionInfoSvgIcon";
import Badge from "../../CommonComponents/Badge";

export default function TestDetail() {
  const { topic, testData, codingTestData } = useContext(AlertContext);

  const getTime = (seconds) => {
    const min = Math.floor(seconds / 60) % 60;
    const hrs = Math.floor(seconds / (60 * 60));

    return hrs !== 0
      ? " " + hrs + " hrs " + min !== 0 && min + " mins"
      : " " + min + " mins";
  };

  const getLanguageName = (languageId) => {
    let langName = "";

    if (typeof languageId === "number") {
      languageId = JSON.stringify(languageId);
    }

    for (let i = 0; i < languageId.length; i++) {
      if (languageId.charAt(i) === "1") {
        langName += constants.getLangName.Java + ", ";
      }

      if (languageId.charAt(i) === "2") {
        langName += constants.getLangName.Javascript + ", ";
      }

      if (languageId.charAt(i) === "3") {
        langName += constants.getLangName.C + ", ";
      }

      if (languageId.charAt(i) === "4") {
        langName += constants.getLangName.Cpp + ", ";
      }

      if (languageId.charAt(i) === "5") {
        langName += constants.getLangName.Python + ", ";
      }
    }

    return langName.substring(0, langName.length - 2);
  };

  return (
    <div className="testDetailMainContainer">
      <div className="testDetail">
        {/* <div className="testDetailInfo">
          <TimeSvgIcon />
          <span className="testDetailInnerInfo">
            Duration:
            {getTime(
              testData.testtype === constants.libTypeIds.CODING_CHALLENGE
                ? codingTestData.configdata.duration
                : testData.configdata.duration
            )}
          </span>
        </div> */}
        <Badge
          size="sm"
          label={`Duration:${getTime(
            testData.testtype === constants.libTypeIds.CODING_CHALLENGE
              ? codingTestData.configdata.duration
              : testData.configdata.duration
          )}`}
          icon={{ icon: <TimeSvgIcon />, position: "start" }}
          color="error"
        />
        <Badge
          size="sm"
          label={
            testData.testtype === constants.libTypeIds.CODING_CHALLENGE
              ? "Category: " + topic.name
              : "No. of Questions: " + testData.configdata.questionscount
          }
          icon={{
            icon:
              testData.testtype === constants.libTypeIds.CODING_CHALLENGE ? (
                <TopicSvgIcon />
              ) : (
                <QuestionInfoSvgIcon />
              ),
            position: "start",
          }}
          color="error"
        />
        {/* <div className="testDetailInfo">
          {testData.testtype === constants.libTypeIds.CODING_CHALLENGE ? (
            <TopicSvgIcon />
          ) : (
            <QuestionInfoSvgIcon />
          )}
          <sapn className="testDetailInnerInfo">
            {testData.testtype === constants.libTypeIds.CODING_CHALLENGE
              ? "Category: " + topic.name
              : "No. of Questions: " + testData.configdata.questionscount}
          </sapn>
        </div> */}
        <Badge
          size="sm"
          label={testData.testtype === constants.libTypeIds.CODING_CHALLENGE
            ? "Coding Languages: " +
              getLanguageName(codingTestData.configdata.languageid)
            : "Maximum score: " + testData.configdata.marks}
          icon={{
            icon:
            testData.testtype === constants.libTypeIds.CODING_CHALLENGE ? (
              <TestSvgIcon component={"testDetail"} libType={testData.testtype} />
            ) : (
              <MaxScoreSvgIcon />
            ),
            position: "start",
          }}
          color="error"
        />
        {/* <div className="testDetailInfo">
          {testData.testtype === constants.libTypeIds.CODING_CHALLENGE ? (
            <TestSvgIcon component={"testDetail"} libType={testData.testtype} />
          ) : (
            <MaxScoreSvgIcon />
          )}
          <span className="testDetailInnerInfo">
            {testData.testtype === constants.libTypeIds.CODING_CHALLENGE
              ? "Coding Languages: " +
                getLanguageName(codingTestData.configdata.languageid)
              : "Maximum score: " + testData.configdata.marks}
          </span>
        </div> */}
      </div>
      <hr className="testDetailHorizontalLine" />
    </div>
  );
}
