import axios from "axios";
import jwt_decode from "jwt-decode";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import constants from "./constants";

const { getSignedUrl } = require("@aws-sdk/s3-request-presigner");

export async function apiCall(specs) {
  const payload = {
    timeout: 30000,
    mode: "no-cors",
    method: specs.method,
    url: getAPIurl(specs),
    headers: {
      "Content-type": "application/json",
    },
  };

  if (
    (specs.body.language === "Java" ||
      specs.body.language === "Javascript" ||
      specs.body.language === "C" ||
      specs.body.language === "CPP" ||
      specs.body.language === "Python" ||
      specs.body.type === "testcode") &&
    (specs.body.type === "runcode" ||
      specs.body.type === "submitcode" ||
      specs.body.type === "testcode")
  ) {
    payload.data = specs.body;
  }

  return new Promise((resolve, reject) => {
    axios(payload)
      .then((result) => {
        if (result.data) {
          resolve(result.data);
        }

        if (result.data.message) {
          resolve(result.data.message);
        }
      })
      .catch((error) => {
        if (error) {
          reject(error);
        }

        reject(error);
      });
  });
}

const getAPIurl = (specs) => {
  if (specs.method === "post") {
    if (specs.language) {
      if (
        specs.language === "javascript" &&
        (specs.api === "runcode" || specs.api === "testcode")
      ) {
        return process.env.REACT_APP_NODE_API + "node/learner/code/js/test";
      }

      if (specs.language === "javascript" && specs.api === "submitcode") {
        return process.env.REACT_APP_NODE_API + "node/learner/code/submit";
      }

      if (specs.language === "c" && specs.api === "submitcode") {
        return (
          process.env.REACT_APP_CONTAINER_API + "node/learner/code/c/submit"
        );
      }

      if (specs.language === "cpp" && specs.api === "submitcode") {
        return (
          process.env.REACT_APP_CONTAINER_API + "node/learner/code/cpp/submit"
        );
      }

      if (specs.language === "python" && specs.api === "submitcode") {
        return (
          process.env.REACT_APP_CONTAINER_API +
          "node/learner/code/python/submit"
        );
      }

      if (specs.language === "java" && specs.api === "submitcode") {
        return (
          process.env.REACT_APP_CONTAINER_API + "node/learner/code/java/submit"
        );
      }

      if (
        specs.language === "java" &&
        (specs.api === "runcode" || specs.api === "testcode")
      ) {
        return (
          process.env.REACT_APP_CONTAINER_API + "node/learner/code/java/test"
        );
      }

      if (
        specs.language === "c" &&
        (specs.api === "runcode" || specs.api === "testcode")
      ) {
        return process.env.REACT_APP_CONTAINER_API + "node/learner/code/c/test";
      }

      if (
        specs.language === "cpp" &&
        (specs.api === "runcode" || specs.api === "testcode")
      ) {
        return (
          process.env.REACT_APP_CONTAINER_API + "node/learner/code/cpp/test"
        );
      }

      if (
        specs.language === "python" &&
        (specs.api === "runcode" || specs.api === "testcode")
      ) {
        return (
          process.env.REACT_APP_CONTAINER_API + "node/learner/code/python/test"
        );
      }
    }
  }
};

export function getDataFromStorage(key) {
  //decoding the jwt access token comming from server

  // if (key === "learnerid") {
  //   return "14";
  // }

  if (window.localStorage.getItem("tokenKey")) {
    let result = jwt_decode(window.localStorage.getItem("tokenKey"));

    if (result.hasOwnProperty(key)) {
      return result[key].toString();
    } else {
      return null;
    }
  }

  // localStorage.setItem("img", result.picture);
  // localStorage.setItem("name", result.name);
  // // localStorage.setItem("batchid", result.batchid);
  // localStorage.setItem("learnerid", result.learnerid);
  // localStorage.setItem("role", result.role);
  // // setUserAuthInfo(result);
  // // setUserRole(result.role);
  // localStorage.setItem("lastlogin",result.lastlogin)
}

//export default apiCall;

export const isEnableProfileDetailsEdit = ()=>{
  return (getDataFromStorage("role")===constants.Roles.admin || getDataFromStorage("role")===constants.Roles.trainer || getDataFromStorage("role")===constants.Roles.learner)
}

export const getPreSignedUrl = (filePath, from) => {
  return new Promise((resolve, reject) => {
    const client = new S3Client({
      region: process.env.REACT_APP_REGION,
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
      },
    });

    const getObjectParams = {
      Key: filePath,
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    };

    const command = new GetObjectCommand(getObjectParams);

    getSignedUrl(client, command, { expiresIn: from === "resume" ? 3600 : 60 })
      .then((url) => {
        resolve(url);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function generateBoilerPlateJSCode(
  functionName,
  argTypes,
  args,
  columns,
  returnType,
  isNoTestCases
) {
  if (!isNoTestCases) {
    let printLogicBeg = ``;
    let printLogicEnd = ``;

    if (
      returnType?.value === "array-1int" ||
      returnType?.value === "array-2int" ||
      returnType?.value === "array-1string" ||
      returnType?.value === "array-2string"
    ) {
      printLogicBeg = `JSON.stringify(`;
      printLogicEnd = `)`;
    }

    const buildInputArgs = (item, index, arr) => {
      let appendStr;
      let actIndex = index + 2;

      if (item === "int") appendStr = `parseInt(process.argv[${actIndex}])`;
      else if (item === "float")
        appendStr = `parseFloat(process.argv[${actIndex}])`;
      else if (item === "String") appendStr = `process.argv[${actIndex}]`;
      else {
        appendStr = `eval(process.argv[${actIndex}])`;
      }

      arr[index] = appendStr;
    };

    argTypes.forEach(buildInputArgs);

    let code = `function ${functionName} (${args.toString()}) {    // Don't change the number of parameters
    //Please write your return statement here
}

//Please don't modify the below code

(function () {
    console.log(${printLogicBeg}${functionName}(${argTypes.toString()})${printLogicEnd});
})();`;
    return code;
  } else {
    return `// Start to write code from here`;
  }
}

export function generateBoilerPlateJavaCode(
  methodName,
  argTypes,
  args,
  columns,
  returnType,
  isNoTestCases
) {
  if (!isNoTestCases) {
    let oneDArrIntInputProcLogic = ``,
      twoDArrIntInputProcLogic = ``,
      oneDArrStrInputProcLogic = ``,
      twoDArrStrInputProcLogic = ``,
      methodArgs = argTypes.map((i) => i);

    let printLogicBeg = ``;
    let printLogicEnd = ``;

    const buildInputArgs = (item, index, arr) => {
      let appendStr;

      if (item === "int") appendStr = `Integer.parseInt(args[${index}])`;

      if (item === "float") appendStr = `Float.parseFloat(args[${index}])`;

      if (item === "String") appendStr = `args[${index}]`;

      if (item === "array-1int") {
        oneDArrIntInputProcLogic =
          oneDArrIntInputProcLogic +
          `String ${args[index]}Str = args[${index}];
          String[] ${args[index]}StrArr;
          if(${args[index]}Str.equals("[]")){
            ${args[index]}StrArr = new String[0];
          }
          else {
            ${args[index]}StrArr = ${args[index]}Str.replaceAll("[\\\\[\\\\]\\"\\\\s]", "").split(",");
          }

        int[] ${args[index]} = new int[${args[index]}StrArr.length];

        for (int i = 0; i < ${args[index]}StrArr.length; i++) {
            ${args[index]}[i] = Integer.parseInt(${args[index]}StrArr[i].trim());
        }
        
        `;
        appendStr = args[index];
      }

      if (item === "array-2int") {
        twoDArrIntInputProcLogic =
          twoDArrIntInputProcLogic +
          `\n` +
          `String ${args[index]}Str = args[${index}];
          String[] ${args[index]}RowStrArr = ${args[index]}Str.split("],");

          int m = ${args[index]}RowStrArr.length;
          int n = ${args[index]}RowStrArr[0].split(",").length;

          int[][] ${args[index]} = new int[m][n];

          for (int i = 0; i < m; i++) {
            String[] ${args[index]}ColStrArr;
            if(${args[index]}RowStrArr[i].equals("[]")){
              ${args[index]}ColStrArr = new String[0];
            }
            else {
              ${args[index]}ColStrArr = ${args[index]}RowStrArr[i].replaceAll("[\\\\[\\\\]\\"\\\\s]", "").split(",");
            }
              
              for (int j = 0; j < n; j++) {
                  ${args[index]}[i][j] = Integer.parseInt(${args[index]}ColStrArr[j].trim());
              }
          }`;
        appendStr = args[index];
      }

      //--------------- String start --------------------------------//

      if (item === "array-1string") {
        oneDArrStrInputProcLogic += `String ${args[index]}Str = args[${index}];

        String[] ${args[index]};
        if(${args[index]}Str.equals("[]")){
          ${args[index]} = new String[0];
        }
        else {
          ${args[index]} = ${args[index]}Str.replaceAll("[\\\\[\\\\]\\"\\\\s]", "").split(",");
        }      
      `;
        appendStr = args[index];
      }

      if (item === "array-2string") {
        twoDArrIntInputProcLogic =
          twoDArrIntInputProcLogic +
          `\n` +
          `String ${args[index]}Str = args[${index}];
              String[] ${args[index]}RowStrArr = ${args[index]}Str.split("],");
              
              int m = ${args[index]}RowStrArr.length;
              int n = ${args[index]}RowStrArr[0].split(",").length;
              
              String[][] ${args[index]} = new String[m][n];
      
              for (int i = 0; i < m; i++) {
                String[] ${args[index]}ColStrArr;
                if(${args[index]}RowStrArr[i].equals("[]")){
                  ${args[index]}ColStrArr = new String[0];
                }
                else {
                  ${args[index]}ColStrArr = ${args[index]}RowStrArr[i].replaceAll("[\\\\[\\\\]\\"\\\\s]", "").split(",");
                }
                  
                  for (int j = 0; j < n; j++) {
                      ${args[index]}[i][j] = ${args[index]}ColStrArr[j].trim();
                  }
              }`;
        appendStr = args[index];
      }

      //--------------- String end --------------------------------//

      arr[index] = appendStr;
    };

    argTypes.forEach(buildInputArgs);

    const buildMethodArgs = (item, index, arr) => {
      let appendStr;

      if (item === "array-1int") {
        appendStr = "int " + args[index] + "[]";
        arr[index] = appendStr;
      } else if (item === "array-2int") {
        appendStr = "int " + args[index] + "[][]";
        arr[index] = appendStr;
      } else if (item === "array-1string") {
        appendStr = "String " + args[index] + "[]";
        arr[index] = appendStr;
      } else if (item === "array-2string") {
        appendStr = "String " + args[index] + "[][]";
        arr[index] = appendStr;
      } else {
        appendStr = item + " " + args[index];
        arr[index] = appendStr;
      }
    };

    methodArgs.forEach(buildMethodArgs);

    let changeRetype =
      returnType?.value === "string"
        ? "String"
        : returnType?.value === "array-1int"
        ? "int[]"
        : returnType?.value === "array-2int"
        ? "int[][]"
        : returnType?.value === "array-1string"
        ? "String[]"
        : returnType?.value === "array-2string"
        ? "String[][]"
        : returnType?.value;

    if (returnType?.value === "array-1int") {
      printLogicBeg = `Arrays.toString(`;
      printLogicEnd = `)`;
    } else if (
      returnType?.value === "array-2int" ||
      returnType?.value === "array-1string" ||
      returnType?.value === "array-2string"
    ) {
      printLogicBeg = `Arrays.deepToString(`;
      printLogicEnd = `)`;
    }

    const code = `${
      returnType?.value === "array-1int" ||
      returnType?.value === "array-1string"
        ? "import java.util.Arrays;\n"
        : ""
    }
public class TestClass {
  public static ${changeRetype} ${methodName} (${methodArgs.toString()}) {  // Don't change the number of parameters
      // Please write your return statement here
  }

  //Please don't modify the below code

  public static void main (String[] args) {
      ${
        oneDArrIntInputProcLogic +
        twoDArrIntInputProcLogic +
        oneDArrStrInputProcLogic +
        twoDArrStrInputProcLogic
      }System.out.print(${printLogicBeg}${methodName}(${argTypes.toString()})${printLogicEnd});
  } 
}`;

    return code;
  } else {
    const code = `public class TestClass {
      public static void main (String[] args) {
        // Start to write code from here
      } 
}`;

    return code;
  }
}

export function generateBoilerPlatePythonCode(
  functionName,
  argTypes,
  args,
  columns,
  returnType,
  isNoTestCases
) {
  if (!isNoTestCases) {
    let oneDArrIntInputProcLogic = ``,
      twoDArrIntInputProcLogic = ``,
      oneDArrStrInputProcLogic = ``,
      twoDArrStrInputProcLogic = ``;

    const buildInputArgs = (item, index, arr) => {
      let appendStr,
        actIndex = index + 1;

      if (item === "int") appendStr = `int(sys.argv[${actIndex}])`;
      else if (item === "float") appendStr = `float(sys.argv[${actIndex}])`;
      else if (item === "String") appendStr = `sys.argv[${actIndex}]`;
      else {
        appendStr = `eval(sys.argv[${actIndex}])`;
      }

      arr[index] = appendStr;
    };

    argTypes.forEach(buildInputArgs);

    const code = `def ${functionName} (${args.toString()}):   #Don't change the number of parameters
    #Please write your return statement here

#Please don't modify the below code

import sys
${
  oneDArrIntInputProcLogic +
  twoDArrIntInputProcLogic +
  oneDArrStrInputProcLogic +
  twoDArrStrInputProcLogic
}
print(${functionName}(${argTypes.toString()}), end = ' ')`;
    return code;
  } else {
    return `# Start to write code from here`;
  }
}

export function generateBoilerPlateCCode(
  methodName,
  argTypes,
  args,
  columns,
  returnType,
  isNoTestCases
) {
  if (!isNoTestCases) {
    let oneDArrIntInputProcLogic = ``,
      twoDArrIntInputProcLogic = ``,
      oneDArrStrInputProcLogic = ``,
      twoDArrStrInputProcLogic = ``,
      multi_tok_Logic = ``,
      methodArgs = argTypes.map((i) => i);

    let printLogic = ``;

    const buildInputArgs = (item, index, arr) => {
      let appendStr,
        actIndex = index + 1;

      if (item === "int") appendStr = `atoi(argv[${actIndex}])`;

      if (item === "float") appendStr = `atof(argv[${actIndex}])`;

      if (item === "String") appendStr = `argv[${actIndex}]`;

      if (item === "boolean") {
        oneDArrIntInputProcLogic += `   int ${args[index]}[100];
        char *${args[index]}Str = argv[${actIndex}];
        int ${index === 0 ? "length" : `length${index}`} = 0;
    
        char *${index === 0 ? "token" : `token${index}`} = strtok(${
          args[index]
        }Str, " ,[]");
        
        while (${index === 0 ? "token" : `token${index}`} != NULL) {
            ${args[index]}[${index === 0 ? "length" : `length${index}`}]=atoi(${
          index === 0 ? "token" : `token${index}`
        });
            ${index === 0 ? "length" : `length${index}`}++;
            ${index === 0 ? "token" : `token${index}`} = strtok(NULL, " ,[]");
        }
        ${
          index === 0 && arr.length === 1
            ? "\n     int newLength;\n"
            : index === arr.length - 1 && arr.length > 1
            ? "\n    int newLength;\n"
            : ""
        }`;

        item = "int";
        appendStr = `${args[index]},${
          index === 0 ? "length" : `length${index}`
        }${
          index === 0 && arr.length === 1
            ? ", &newLength"
            : index === arr.length - 1 && arr.length > 1
            ? ", &newLength"
            : ""
        }`;
      }

      if (item === "array-1int") {
        oneDArrIntInputProcLogic += `int ${args[index]}${
          returnType?.value === "boolean" ? `Str${index}` : ""
        }[100];
      char *${args[index]}Str = argv[${actIndex}];
      int ${index === 0 ? "length" : `length${index}`} = 0;

      char *${index === 0 ? "token" : `token${index}`} = strtok(${
          args[index]
        }Str, " ,[]");
      
      while (${index === 0 ? "token" : `token${index}`} != NULL) {
          ${args[index]}${
          returnType?.value === "boolean" ? `Str${index}` : ""
        }[${index === 0 ? "length" : `length${index}`}]=atoi(${
          index === 0 ? "token" : `token${index}`
        }); ${index === 0 ? "length" : `length${index}`}++;
          ${index === 0 ? "token" : `token${index}`} = strtok(NULL, " ,[]");
      }
      ${
        returnType?.value === "boolean"
          ? `
      int ${args[index]}[${index === 0 ? "length" : `length${index}`}];
      int ${index === 0 ? "size" : `size${index}`} = 0;

      while (${index === 0 ? "size" : `size${index}`} < ${
              index === 0 ? "length" : `length${index}`
            }) {
          ${args[index]}[${index === 0 ? "size" : `size${index}`}] = ${
              args[index]
            }${returnType?.value === "boolean" ? `Str${index}` : ""}[${
              index === 0 ? "size" : `size${index}`
            }]; ${index === 0 ? "size" : `size${index}`}++;
      }\n`
          : ""
      }
      ${
        index === 0 &&
        arr.length === 1 &&
        (returnType?.value === "array-1int" ||
          returnType?.value === "array-1string")
          ? "int newLength;\n"
          : index === arr.length - 1 &&
            arr.length > 1 &&
            (returnType?.value === "array-1int" ||
              returnType?.value === "array-1string")
          ? "\n    int newLength;\n"
          : ""
      }`;

        item = "int";
        appendStr = `${args[index]}, ${
          index === 0 ? "length" : `length${index}`
        }${
          index === 0 &&
          arr.length === 1 &&
          (returnType?.value === "array-1int" ||
            returnType?.value === "array-1string")
            ? ", &newLength"
            : index === arr.length - 1 &&
              arr.length > 1 &&
              (returnType?.value === "array-1int" ||
                returnType?.value === "array-1string")
            ? ", &newLength"
            : ""
        }`;
      }

      if (item === "array-2int") {
        // twoDArrIntInputProcLogic = `
        //       int twoDArrElement${index}[50];
        //       char * token${index} = strtok(argv[${actIndex}], " ");
        //       int size${index}=0;
        //       while( token${index} != NULL ) {
        //           twoDArrElement${index}[size${index}]=atoi(token${index});
        //           token${index} = strtok(NULL, " ");
        //           size${index}++;
        //       }
        //       int arrayLength${index} = size${index} / ${columns[index]};
        //       int twoDArr${index}[arrayLength${index}][${columns[index]}];
        //       for (int i = 0; i <arrayLength${index}; i++) {
        //           for (int j = 0; j < ${columns[index]}; j++) {
        //               twoDArr${index}[i][j] = twoDArrElement${index}[(i *${columns[index]}) + j ];
        //           }
        //       }
        //   `;
        twoDArrIntInputProcLogic =
          twoDArrIntInputProcLogic +
          `\n` +
          `char *${args[index]}Str = argv[${actIndex}];
        int length = 0;
    
        char *tokenrow = multi_tok(${args[index]}Str, "],");
        
        while (tokenrow != NULL) {
            length++;
            tokenrow = strtok(NULL, "],");
        }
        
        char *tokenrowstr = multi_tok(${args[index]}Str, "],");
        int ${args[index]}RowStr[length];
        int size = 0;
        while (tokenrowstr != NULL) {
          ${args[index]}RowStr[size++] = atoi(tokenrowstr);
          tokenrowstr = multi_tok(NULL, "],");
      }
      int rowlength = sizeof(${args[index]}RowStr) / sizeof(${args[index]}RowStr[0]);
      
      int collength = 0;
    
        char *tokencol = strtok(${args[index]}RowStr[0], " ,[]\"");
        
        while (tokencol != NULL) {
          collength++;
          tokencol = strtok(NULL, " ,[]\"");
        }
        int ${args[index]}[rowlength][collength];
        int rowsize=0;
        int colsize=0;

        char *tokenrowstr = multi_tok(${args[index]}Str, "],");
        while (tokenrowstr != NULL) {
          char *tokencol = strtok(tokenrowstr, " ,[]\"");
          while (tokencol != NULL) {
          ${args[index]}[rowsize++][colsize++] = atoi(tokenrowstr);
          tokencol = strtok(NULL, " ,[]\"");
          }
          tokenrowstr = multi_tok(NULL, "],");
      }`;

        multi_tok_Logic = `char *multi_tok(char *input, char *delimiter) {
          static char *string;
          if (input != NULL)
              string = input;
      
          if (string == NULL)
              return string;
      
          char *end = strstr(string, delimiter);
          if (end == NULL) {
              char *temp = string;
              string = NULL;
              return temp;
          }
      
          char *temp = string;
      
          *end = '\0';
          string = end + strlen(delimiter);
          return temp;
      }`;
        item = "int";
        // appendStr = `twoDArr` + index;
        appendStr = args[index];
      }

      //--------------- String start --------------------------------//

      if (item === "array-1string") {
        oneDArrStrInputProcLogic += `char *${
          args[index]
        }Str = strdup(argv[${actIndex}]);
      int ${index === 0 ? "length" : `length${index}`} = 0;
  
      char *${index === 0 ? "token" : `token${index}`} = strtok(${
          args[index]
        }Str, ",[]");
      
      while (${index === 0 ? "token" : `token${index}`} != NULL) {
        ${index === 0 ? "length" : `length${index}`}++;
        ${index === 0 ? "token" : `token${index}`} = strtok(NULL, " ,[]");
      }
      
      char *${
        index === 0 ? "tokenstr" : `tokenstr${index}`
      } = strdup(argv[${actIndex}]);

      char **${args[index]} = (char **)malloc(${
          index === 0 ? "length" : `length${index}`
        } * sizeof(char *));
      int ${index === 0 ? "size" : `size${index}`} = 0;

      ${index === 0 ? "token" : `token${index}`} = strtok(${
          index === 0 ? "tokenstr" : `tokenstr${index}`
        }, ",[]");

      while (${index === 0 ? "token" : `token${index}`} != NULL) {
        ${args[index]}[${
          index === 0 ? "size" : `size${index}`
        }] = (char *)malloc((strlen(${
          index === 0 ? "token" : `token${index}`
        }) + 1) * sizeof(char));
        strcpy(${args[index]}[${index === 0 ? "size" : `size${index}`}++], ${
          index === 0 ? "token" : `token${index}`
        });
        ${index === 0 ? "token" : `token${index}`} = strtok(NULL, ",[]");
      }
      ${
        index === 0 &&
        arr.length === 1 &&
        (returnType?.value === "array-1int" ||
          returnType?.value === "array-1string")
          ? "\n     int newLength;\n"
          : index === arr.length - 1 &&
            arr.length > 1 &&
            (returnType?.value === "array-1int" ||
              returnType?.value === "array-1string")
          ? "\n    int newLength;\n"
          : ""
      }`;

        appendStr = `${args[index]}, ${index === 0 ? "size" : `size${index}`}${
          index === 0 &&
          arr.length === 1 &&
          (returnType?.value === "array-1int" ||
            returnType?.value === "array-1string")
            ? ", &newLength"
            : index === arr.length - 1 &&
              arr.length > 1 &&
              (returnType?.value === "array-1int" ||
                returnType?.value === "array-1string")
            ? ", &newLength"
            : ""
        }`;
      }

      if (item === "array-2string") {
        // twoDArrStrInputProcLogic = `
        //       char * twoDArrElement${index}[50];
        //       char * token${index} = strtok(argv[${actIndex}], " ");
        //       int size${index}=0;
        //       while( token${index} != NULL ) {
        //           twoDArrElement${index}[size${index}]=token${index};
        //           token${index} = strtok(NULL, " ");
        //           size${index}++;
        //       }
        //       int arrayLength${index} = size${index} / ${columns[index]};
        //       char* twoDArr${index}[arrayLength${index}][${columns[index]}];
        //       for (int i = 0; i < arrayLength${index}; i++) {
        //           for (int j = 0; j <${columns[index]} ; j++) {
        //               twoDArr${index}[i][j] = twoDArrElement${index}[(i * ${columns[index]}) + j ];
        //           }
        //       }`;
        twoDArrStrInputProcLogic =
          twoDArrStrInputProcLogic +
          `\n` +
          `char *${args[index]}Str = argv[${actIndex}];
        int length = 0;
    
        char *tokenrow = multi_tok(${args[index]}Str, "],");
        
        while (tokenrow != NULL) {
            length++;
            tokenrow = strtok(NULL, "],");
        }
        
        char *tokenrowstr = multi_tok(${args[index]}Str, "],");
        int ${args[index]}RowStr[length];
        int size = 0;
        while (tokenrowstr != NULL) {
          ${args[index]}RowStr[size++] = atoi(tokenrowstr);
          tokenrowstr = multi_tok(NULL, "],");
      }
      int rowlength = sizeof(${args[index]}RowStr) / sizeof(${args[index]}RowStr[0]);
      
      int collength = 0;
    
        char *tokencol = strtok(${args[index]}RowStr[0], ",[]");
        
        while (tokencol != NULL) {
          collength++;
          tokencol = strtok(NULL, " ,[]\"");
        }
        char *${args[index]}[rowlength][collength];
        int rowsize=0;
        int colsize=0;

        char *tokenrowstr = multi_tok(${args[index]}Str, "],");
        while (tokenrowstr != NULL) {
          char *tokencol = strtok(tokenrowstr, ",[]");
          while (tokencol != NULL) {
          ${args[index]}[rowsize++][colsize++] = atoi(tokenrowstr);
          tokencol = strtok(NULL, ",[]");
          }
          tokenrowstr = multi_tok(NULL, "],");
      }`;

        multi_tok_Logic = `char *multi_tok(char *input, char *delimiter) {
          static char *string;
          if (input != NULL)
              string = input;
      
          if (string == NULL)
              return string;
      
          char *end = strstr(string, delimiter);
          if (end == NULL) {
              char *temp = string;
              string = NULL;
              return temp;
          }
      
          char *temp = string;
      
          *end = '\0';
          string = end + strlen(delimiter);
          return temp;
      }`;
        // appendStr = `twoDArr${index}`;
        appendStr = args[index];
      }

      //--------------- String end --------------------------------//

      arr[index] = appendStr;
    };

    argTypes.forEach(buildInputArgs);

    const buildMethodArgs = (item, index, arr) => {
      let appendStr;

      appendStr = `${item} ${args[index]}`;

      if (item === "String") appendStr = `char * ${args[index]}`;

      if (item === "array-1int")
        appendStr =
          index === 0
            ? `int  ${args[index]}[], int size`
            : `int  ${args[index]}[], int size${[index]}`;

      if (item === "array-2int")
        appendStr = `int ${args[index]}[][${columns[index]}]`;

      if (item === "array-1string")
        appendStr =
          index === 0
            ? `char **${args[index]}, int size`
            : `char **${args[index]}, int size${[index]}`;

      if (item === "array-2string")
        appendStr = `char *${args[index]}[][${columns[index]}]`;

      arr[index] = appendStr;
    };

    methodArgs.forEach(buildMethodArgs);

    let changeRetype =
      returnType?.value === "string"
        ? "char*"
        : returnType?.value === "boolean"
        ? "bool"
        : returnType?.value === "array-1int"
        ? "int*"
        : returnType?.value === "array-2int"
        ? "int**"
        : returnType?.value === "array-1string"
        ? "char**"
        : returnType?.value === "array-2string"
        ? "char***"
        : returnType?.value;

    if (returnType?.value === "int" || returnType?.value === "boolean") {
      printLogic = `printf("%d", `;
    }

    if (returnType?.value === "string") {
      printLogic = `printf("%s", `;
    }

    if (returnType?.value === "float") {
      printLogic = `printf("%.2f",output)`;
    }

    if (returnType?.value === "array-1int") {
      printLogic = `  
      printf("[");
        
      for (int i = 0; i < newLength-1; i++) {
        printf("%d,", output[i]);
      }

      if (newLength > 0) {
        printf("%d", output[newLength - 1]);
      }

      printf("]");`;
    } else if (returnType?.value === "array-2int") {
      printLogic = `int rowsize = sizeof(output)/sizeof(output[0]);
          int colsize = sizeof(output[0])/sizeof(int);
          
          for (int i = 0; i < rowsize; i++) 
            {
                for (int j = 0; j < colsize; j++) {
                if(i==0 && j==0){
                    printf("%s", "[[");
                }
              else if(j==0){
                    printf(",%s", "[");
                }
              
                if(j==colsize-1){
                    if(output[i][j] != 0){
                      printf(",%d", output[i][j]);
                    }
                    printf("%s", "]");
                }
                else if(output[i][j] != 0){
                    if(j != 0){
                      printf(",%d", output[i][j]);
                    }
                    else {
                        printf("%d", output[i][j]);
                    }
                }
                if(i==rowsize-1 && j==colsize-1){
                    printf("%s", "]");
                }
                
            }
            }`;
    } else if (returnType?.value === "array-1string") {
      printLogic = `
      printf("[");
      
      for (int i = 0; i < newLength; i++) {
          printf("%s", output[i]);
          
          if (i != newLength - 1) {
              printf(",");
          }
      }
      
      printf("]");`;
    } else if (returnType?.value === "array-2string") {
      printLogic = `int rowsize = sizeof(output)/sizeof(output[0]);
          int colsize = sizeof(output[0])/sizeof(output[0][0]);
          
          for (int i = 0; i < rowsize; i++) 
            {
                for (int j = 0; j < colsize; j++) {
                if(i==0 && j==0){
                    printf("%s", "[[");
                }
              else if(j==0){
                    printf(",%s", "[");
                }
              
                if(j==colsize-1 ){
                    if(strlen(output[i][j]) != 0){
                      printf(",%s", output[i][j]);
                    }
                    printf("%s", "]");
                }
                else if(strlen(output[i][j]) != 0){
                    if(j != 0){
                    printf(",%s", output[i][j]);
                    }
                    else {
                        printf("%s", output[i][j]);
                    }
                }
                else {
          
                }
                if(i==rowsize-1 && j==colsize-1){
                    printf("%s", "]");
                }
            }
            }`;
    }

    const code = `#include <stdio.h>
#include <string.h>
#include <stdlib.h>${changeRetype === "bool" ? "\n#include <stdbool.h>" : ""}

${changeRetype} ${methodName} (${methodArgs.toString()}${
      changeRetype === "char**" || changeRetype === "int*"
        ? ", int *newLength"
        : ""
    }) { //Don't change the number of parameters
    //Please write your return statement here
}

//Please don't modify the below code

int main(int argc, char * argv[]) {
      ${
        oneDArrIntInputProcLogic +
        twoDArrIntInputProcLogic +
        oneDArrStrInputProcLogic +
        twoDArrStrInputProcLogic
      }${
      returnType?.value === "string" ||
      returnType?.value === "int" ||
      returnType?.value === "boolean"
        ? `${printLogic} ${methodName}(${argTypes.toString()}));`
        : returnType?.value === "float"
        ? "float"
        : returnType?.value === "array-1int"
        ? "      int *"
        : returnType?.value === "array-2int"
        ? "int **"
        : returnType?.value === "array-1string"
        ? "      char **"
        : "char ***"
    } ${
      returnType?.value !== "string" &&
      returnType?.value !== "int" &&
      returnType?.value !== "boolean"
        ? `output = ${methodName} (${argTypes.toString()});
      ${printLogic}`
        : ""
    }

      return 0;
}${multi_tok_Logic}`;
    return code;
  } else {
    const code = `#include <stdio.h>

int main() {
  // Start to write code from here
  
  return 0;
}`;
    return code;
  }
}

export function generateBoilerPlateCPPCode(
  methodName,
  argTypes,
  args,
  columns,
  returnType,
  isNoTestCases
) {
  if (!isNoTestCases) {
    let oneDArrIntInputProcLogic = ``,
      twoDArrIntInputProcLogic = ``,
      oneDArrStrInputProcLogic = ``,
      twoDArrStrInputProcLogic = ``,
      methodArgs = argTypes.map((i) => i);

    let printLogic = ``;

    const buildInputArgs = (item, index, arr) => {
      let appendStr,
        actIndex = index + 1;

      if (item === "int") appendStr = `atoi(argv[${actIndex}])`;

      if (item === "float") appendStr = `atof(argv[${actIndex}])`;

      if (item === "String") appendStr = `argv[${actIndex}]`;

      if (item === "array-1int") {
        oneDArrIntInputProcLogic += `int ${args[index]}${[index]} [100];
    char * arrStr = argv[${actIndex}];
    int ${index === 0 ? "length" : `length${index}`} = 0;

    char *${index === 0 ? "token" : `token${index}`} = strtok(arrStr, " ,[]");

    while (${index === 0 ? "token" : `token${index}`} != NULL) {
      ${args[index]}${[index]}[${
          index === 0 ? "length" : `length${index}`
        }]=atoi(${index === 0 ? "token" : `token${index}`});
      ${index === 0 ? "length" : `length${index}`}++;
      ${index === 0 ? "token" : `token${index}`} = strtok(NULL, " ,[]");
    }

    int ${args[index]}[${index === 0 ? "length" : `length${index}`}];
    int ${index === 0 ? "size" : `size${index}`} = 0;

    while (${index === 0 ? "size" : `size${index}`} < ${
          index === 0 ? "length" : `length${index}`
        }) {
      ${args[index]}[${index === 0 ? "size" : `size${index}`}] = ${
          args[index]
        }${[index]}[${index === 0 ? "size" : `size${index}`}];
      ${index === 0 ? "size" : `size${index}`}++;
    }
      ${
        index === 0 &&
        arr.length === 1 &&
        (returnType?.value === "array-1int" ||
          returnType?.value === "array-1string")
          ? "\n    int newSize;"
          : index === arr.length - 1 &&
            arr.length > 1 &&
            (returnType?.value === "array-1int" ||
              returnType?.value === "array-1string")
          ? "\n    int newSize;"
          : ""
      }`;
        appendStr = `${args[index]}, ${index === 0 ? "size" : `size${index}`}${
          index === 0 &&
          arr.length === 1 &&
          (returnType?.value === "array-1int" ||
            returnType?.value === "array-1string")
            ? ", newSize"
            : index === arr.length - 1 &&
              arr.length > 1 &&
              (returnType?.value === "array-1int" ||
                returnType?.value === "array-1string")
            ? ", newSize"
            : ""
        }`;
      }

      if (item === "array-2int") {
        twoDArrIntInputProcLogic =
          twoDArrIntInputProcLogic +
          `\n` +
          `
              int twoDArrElement${index}[100];
              char * token${index} = strtok(argv[${actIndex}], " ");
              int size${index}=0;
              while( token${index} != NULL ) {
                  twoDArrElement${index}[size${index}]=atoi(token${index});
                  token${index} = strtok(NULL, " ");
                  size${index}++;
              }
              int arrayLength${index} = size${index} / ${columns[index]};
              int twoDArr${index}[arrayLength${index}][${columns[index]}];
              for (int i = 0; i <arrayLength${index}; i++) {
                  for (int j = 0; j < ${columns[index]}; j++) {
                      twoDArr${index}[i][j] = twoDArrElement${index}[(i *${columns[index]}) + j ];
                  }
              }`;
        appendStr = `twoDArr${index}`;
      }

      //--------------- String start --------------------------------//

      if (item === "array-1string") {
        oneDArrStrInputProcLogic =
          oneDArrStrInputProcLogic +
          `char* ${args[index]}Str = strdup(argv[${actIndex}]);
    int ${index === 0 ? "length" : `length${index}`} = 0;

    char* ${index === 0 ? "token" : `token${index}`} = strtok(${
            args[index]
          }Str, ",[]");

    while (${index === 0 ? "token" : `token${index}`} != NULL) {
        ${index === 0 ? "length" : `length${index}`}++;
        ${index === 0 ? "token" : `token${index}`} = strtok(NULL, ",[]");
    }

    char* ${
      index === 0 ? "tokenStr" : `tokenStr${index}`
    } = strdup(argv[${actIndex}]);

    char** ${args[index]} = (char**)malloc(${
            index === 0 ? "length" : `length${index}`
          } * sizeof(char*));
    int ${index === 0 ? "size" : `size${index}`} = 0;

    ${index === 0 ? "token" : `token${index}`} = strtok(${
            index === 0 ? "tokenStr" : `tokenStr${index}`
          }, ",[]");

    while (${index === 0 ? "token" : `token${index}`} != NULL) {
        ${args[index]}[${
            index === 0 ? "size" : `size${index}`
          }] = (char*)malloc((strlen(${
            index === 0 ? "token" : `token${index}`
          }) + 1) * sizeof(char));
        strcpy(${args[index]}[${index === 0 ? "size" : `size${index}`}++], ${
            index === 0 ? "token" : `token${index}`
          });
        ${index === 0 ? "token" : `token${index}`} = strtok(NULL, ",[]");
    }

      ${
        index === 0 &&
        arr.length === 1 &&
        (returnType?.value === "array-1int" ||
          returnType?.value === "array-1string")
          ? "int newSize;"
          : index === arr.length - 1 &&
            arr.length > 1 &&
            (returnType?.value === "array-1int" ||
              returnType?.value === "array-1string")
          ? "int newSize;"
          : ""
      }`;
        appendStr = `${args[index]}, ${index === 0 ? "size" : `size${index}`}${
          index === 0 &&
          arr.length === 1 &&
          (returnType?.value === "array-1int" ||
            returnType?.value === "array-1string")
            ? ", newSize"
            : index === arr.length - 1 &&
              arr.length > 1 &&
              (returnType?.value === "array-1int" ||
                returnType?.value === "array-1string")
            ? ", newSize"
            : ""
        }`;

        // int newLength;
        // char** output = removeDuplicates(${args[index]}, ${index === 0 ? "size" : `size${index}`} = 0;, newLength);`
        // `\n` +
        // `
        //     char * oneDArr${index}[100];
        //     char * token${index} = strtok(argv[${actIndex}], " ");
        //     int size${index}=0;
        //     while( token${index} != NULL ) {
        //         oneDArr${index}[size${index}]=token${index};
        //         token${index} = strtok(NULL, " ");
        //         size${index}++;
        //     } `;
        // appendStr = `oneDArr${index}`;
      }

      if (item === "array-2string") {
        twoDArrStrInputProcLogic =
          twoDArrStrInputProcLogic +
          `\n` +
          `
              char * twoDArrElement${index}[100];
              char * token${index} = strtok(argv[${actIndex}], " ");
              int size${index}=0;
              while( token${index} != NULL ) {
                  twoDArrElement${index}[size${index}]=token${index};
                  token${index} = strtok(NULL, " ");
                  size${index}++;
              }
              int arrayLength${index} = size${index} / ${columns[index]};
              char* twoDArr${index}[arrayLength${index}][${columns[index]}];
              for (int i = 0; i < arrayLength${index}; i++) {
                  for (int j = 0; j <${columns[index]}; j++) {
                      twoDArr${index}[i][j] = twoDArrElement${index}[(i * ${columns[index]}) + j ];
                  }
              } `;
        appendStr = `twoDArr${index}`;
      }
      arr[index] = appendStr;
    };

    argTypes.forEach(buildInputArgs);

    const buildMethodArgs = (item, index, arr) => {
      let appendStr;

      appendStr = `${item} ${args[index]}`;

      if (item === "String") appendStr = `char* ${args[index]}`;

      if (item === "float") appendStr = `float ${args[index]}`;

      if (item === "array-1int")
        appendStr = `int ${args[index]}[], int ${
          index === 0 ? "size" : `size${index}`
        }${
          index === 0 && arr.length === 1 && returnType?.value === "array-1int"
            ? ", int &newSize"
            : index === arr.length - 1 &&
              arr.length > 1 &&
              returnType?.value === "array-1int"
            ? ", int &newSize"
            : ""
        }`;

      if (item === "array-2int") appendStr = `int ${args[index]}[][2]`;

      if (item === "array-1string")
        appendStr = `char* ${args[index]}[], int ${
          index === 0 ? "size" : `size${index}`
        }${
          index === 0 &&
          arr.length === 1 &&
          returnType?.value === "array-1string"
            ? ", int &newSize"
            : index === arr.length - 1 &&
              arr.length > 1 &&
              returnType?.value === "array-1string"
            ? ", int &newSize"
            : ""
        }`;

      if (item === "array-2string") appendStr = `char* ${args[index]}[][2]`;

      arr[index] = appendStr;
    };

    methodArgs.forEach(buildMethodArgs);

    if (returnType?.value === "array-1int") {
      printLogic = `
    cout << "[";
  
    for (int i = 0; i < newSize - 1; i++) {
        cout << result[i] << ",";
    }
  
    if (newSize > 0) {
        cout << result[newSize - 1];
    }
  
    cout << "]" << endl;`;
    } else if (returnType?.value === "array-2int") {
      printLogic = `int rowsize = sizeof(output)/sizeof(output[0]);
          int colsize = sizeof(output[0])/sizeof(int);
          
          for (int i = 0; i < rowsize; i++) 
            {
                for (int j = 0; j < colsize; j++) {
                if(i==0 && j==0){
                    printf("%s", "[[");
                }
              else if(j==0){
                    printf(",%s", "[");
                }
              
                if(j==colsize-1){
                    if(output[i][j] != 0){
                      printf(",%d", output[i][j]);
                    }
                    printf("%s", "]");
                }
                else if(output[i][j] != 0){
                    if(j != 0){
                      printf(",%d", output[i][j]);
                    }
                    else {
                        printf("%d", output[i][j]);
                    }
                }
                if(i==rowsize-1 && j==colsize-1){
                    printf("%s", "]");
                }
                
            }
            }`;
    } else if (returnType?.value === "array-1string") {
      printLogic = `
    cout << "[";

    for (int i = 0; i < newSize; ++i) {
        cout << result[i];

        if (i != newSize - 1) {
            cout << ",";
        }
    }

    cout << "]";`;
    } else if (returnType?.value === "array-2string") {
      printLogic = `int rowsize = sizeof(output)/sizeof(output[0]);
          int colsize = sizeof(output[0])/sizeof(output[0][0]);
          
          for (int i = 0; i < rowsize; i++) 
            {
                for (int j = 0; j < colsize; j++) {
                if(i==0 && j==0){
                    printf("%s", "[[");
                }
              else if(j==0){
                    printf(",%s", "[");
                }
              
                if(j==colsize-1 ){
                    if(strlen(output[i][j]) != 0){
                      printf(",%s", output[i][j]);
                    }
                    printf("%s", "]");
                }
                else if(strlen(output[i][j]) != 0){
                    if(j != 0){
                    printf(",%s", output[i][j]);
                    }
                    else {
                        printf("%s", output[i][j]);
                    }
                }
                else {
          
                }
                if(i==rowsize-1 && j==colsize-1){
                    printf("%s", "]");
                }
            }
            }`;
    }

    const code = `${
      returnType?.value === "int"
        ? `#include <iostream>\n#include <cstring>`
        : returnType?.value === "string"
        ? `#include <iostream>
#include <cstring>`
        : returnType?.value === "boolean"
        ? `#include <iostream>\n#include <cstring>\n#include <sstream>`
        : returnType?.value === "array-1int" ||
          returnType?.value === "array-1string"
        ? `#include <iostream>\n#include <cstring>`
        : `#include <iostream>
#include <cstdlib>
#include <string>
#include <cstring>
#include <bits/stdc++.h>`
    }

using namespace std;

${
  returnType?.value === "string"
    ? "string"
    : returnType?.value === "boolean"
    ? "bool"
    : returnType?.value === "array-1int"
    ? "int* "
    : returnType?.value === "array-1string"
    ? "char**"
    : returnType?.value
} ${methodName} (${methodArgs.toString()}) { //Don't change the number of parameters
    //Please write your return statement here
}

//Please don't modify the below code

int main(int argc, char * argv[]) {
    ${
      oneDArrIntInputProcLogic +
      twoDArrIntInputProcLogic +
      oneDArrStrInputProcLogic +
      twoDArrStrInputProcLogic
    }
    ${
      returnType?.value === "array-1int"
        ? `int * result = `
        : returnType?.value === "array-1string"
        ? "char** result = "
        : `cout << `
    }${
      returnType?.value === "array-1int" ||
      returnType?.value === "array-1string"
        ? `${methodName}(${argTypes.toString()});
        ${printLogic}`
        : `${methodName}(${argTypes.toString()});`
    }

    return 0;
}`;
    return code;
  } else {
    const code = `#include <iostream>

using namespace std;

int main() {
  //Start to write code here

  return 0;
}`;

    return code;
  }
}

export const changeFavicon = (favicon) => {
  if (favicon && favicon !== "") {
    let link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = favicon;
    document.getElementsByTagName("head")[0].appendChild(link);
  }
};

export const emailBody = `<table style="border-collapse: collapse;border:0">
<tr>
    <td>
        <table style="border-collapse: collapse;">
            <tr>
                <td
                    style="font-weight: 400; font-size: 14px; line-height: 24px;">
                    Welcome to the Gradious Learning Platform (LEAP)! We are
                    thrilled to
                    have you on board and excited to help you embark on your
                    learning
                    journey with us.
                </td>
            </tr>
            <tr>
                <td
                    style="font-weight: 400; font-size: 14px; line-height: 24px; padding: 8px 0 0;">
                    Our Learning Management System (LMS) is designed to provide
                    you with an
                    exceptional educational experience, offering a wide range of
                    resources,
                    interactive tools, and support to help you achieve your
                    learning goals.
                </td>
            </tr>
        </table>
    </td>
</tr>
<tr>
    <td style="padding: 16px 0 0;">
        <table style="border-collapse: collapse;">
            <tr>
                <td
                    style="font-weight: 400; font-size: 14px; line-height: 24px;">
                    You can get started by:
                </td>
            </tr>
            <tr>
                <td>
                    <ol style="margin: 0; padding-top: 8px;">
                        <li
                            style="font-weight: 400; font-size: 14px; line-height: 24px; padding-bottom: 8px;">
                            Visit our LMS platform at
                            <span>
                                <a href="https://leap.gradious.com"
                                    data-saferedirecturl="https:leap.gradious.com"
                                    target="_blank" rel="noopener noreferrer"
                                    style="color: #175CD3; text-decoration: none;">
                                    [leap.gradious.com]
                                </a>.
                            </span>
                        </li>
                        <li
                            style="font-weight: 400; font-size: 14px; line-height: 24px;">
                            Login using “Sign-in with Google” with the same
                            Google account
                            you received this mail in.
                        </li>
                    </ol>
                </td>
            </tr>
        </table>
    </td>
</tr>
<tr>
    <td
        style="font-weight: 400; font-size: 14px; line-height: 24px; padding-top: 16px;">
        Thank you for choosing Gradious Technologies as your learning partner.
        Let’s begin
        this exciting journey together!
    </td>
</tr>
</table>`;
