import React from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Whatsapp from "../../Svg/Whatsapp";
import Gmail from "../../Svg/Gmail";
import Slack from "../../Svg/Slack";
import CopyIconSvg from "../../Svg/CopyIconSvg";
import RedirectSvg from "../../Svg/RedirectSvg";
import Tooltip from "./Tooltip";

export default function Trainers(props) {
  // Function to copy email data to clipboard
  const copyEmailToClipboard = (email) => {
    navigator.clipboard.writeText(email);
  };

  const redirectToSlack = (slackid) => {
    window.open(slackid);
  };

  return (
    <div className="trainers-div">
      {props.trainerData?.map((data) => (
        <Card className="trainers-card">
          <Grid className="trainers-card-grid">
            <Avatar
              style={{ width: "48px", height: "48px", padding: 0 }}
              alt={data?.firstname}
              src={data?.picture}
            />
            <CardContent className="trainers-card-content">
              <Box className="trainer-name">
                {data.firstname} {data.lastname}
              </Box>
              <Box className="trainers-card-content-whatsapp-box">
                <Typography component="div">
                  <Whatsapp />
                </Typography>
                <Typography
                  className="trainers-card-content-box-data"
                  component="div"
                >
                  <a
                    href={`https://wa.me/${data.mobile}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {data.mobile}
                  </a>
                </Typography>
              </Box>
              <Box className="trainers-card-content-gmail-box">
                <Typography component="div">
                  <Gmail />
                </Typography>
                <Typography
                  className="trainers-card-content-box-data"
                  component="div"
                >
                  <a
                    href={`mailto:${data.email}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {data.email}
                  </a>
                </Typography>
                <Typography
                  className="trainers-card-content-box-svg"
                  component="div"
                >
                  <IconButton
                    className="trainers-card-content-box-svg-iconbtn"
                    onClick={() => copyEmailToClipboard(data.email)}
                  >
                    <CopyIconSvg />
                  </IconButton>
                </Typography>
              </Box>
              <Box className="trainers-card-content-slack-box">
                <Typography component="div">
                  <Slack />
                </Typography>
                <Typography
                  className="trainers-card-content-box-data"
                  component="div"
                >
                  Send a slack message
                </Typography>
                <Typography
                  className="trainers-card-content-box-svg"
                  component="div"
                >
                  <IconButton
                    disabled={data.slackId === null}
                    className="trainers-card-content-box-svg-iconbtn"
                    onClick={() => redirectToSlack(data.slackId)}
                  >
                    <RedirectSvg />
                  </IconButton>
                </Typography>
              </Box>
            </CardContent>
            {props.icon && (
              <Box className="trainers-remove-icon-box">
                <span onClick={() => props.icon.onClick(data.userid)}>
                  <Tooltip text="Delete" position="bottomCenter" theme="dark">
                    {props.icon.icon}
                  </Tooltip>
                </span>
              </Box>
            )}
          </Grid>
        </Card>
      ))}
    </div>
  );
}
