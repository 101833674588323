import useApiCall from "../useApiCall";
import constants from "../../constants";
import { getDataFromStorage } from "../../util";
import CodingPageHooks from "./CodingPageHooks";
import useAxiosPrivate from "../useAxiosPrivate";
import AlertContext from "../../context/AlertContext";
import NotifyBody from "../../Components/Notify/NotifyBody";
import React, { useContext, useRef, useState } from "react";
import useCommonFunctionHooks from "../useCommonFunctionHooks";
import McqAndDescPageAnsFnHooks from "../McqAndDescPage/McqAndDescPageAnsFnHooks";
import MockTestLibraryHooks from "../Mock/Student/MockTestLibraryHooks";

export default function CodingHeaderLearnerSideHooks() {
  const Alert = useContext(AlertContext);

  const {apiCall} = useApiCall();
  const axios = useAxiosPrivate();
  const { handleCodingInfo } = MockTestLibraryHooks();
  const { handleAssignNextQnToLearner } = CodingPageHooks();
  const { handleShowAnsforPrac } = McqAndDescPageAnsFnHooks();

  const {
    handleClose,
    handleNavigate,
    getCatchBlockDetails,
    getHeaderDetailsForAxios,
  } = useCommonFunctionHooks();

  let successfulMsgForLearnerSubmission = [
    "Good, you cleared this assignment.",
    "Awesome! Your code passed our test.",
    "Well done! the code submitted is right.",
    "That's right, your code worked and you cleared this assignment.",
  ];

  let timeoutId = useRef({});
  let switchCount = useRef(0);

  const focusOutSec = useRef(0);
  const focusOutTimer = useRef(null);

  const [timer, setTimer] = useState(0);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const pad = (num) => {
    return ("0" + num).slice(-2);
  };

  const getSuccessMsg = () => {
    let i = Math.floor(
      Math.random() * successfulMsgForLearnerSubmission.length
    );

    return successfulMsgForLearnerSubmission[i];
  };

  const handleShowSubmitDiv = () => {
    if (
      Alert.status === constants.PROGRESS_STATUS.UNLOCKED ||
      Alert.status === constants.PROGRESS_STATUS.REATTEMPT ||
      Alert.status === constants.PROGRESS_STATUS.QN_CONTINUE
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleShowCommentsButton = () => {
    if (
      Alert.codeDetails.hasOwnProperty("commentsdata") &&
      Alert.codeDetails.commentsdata.length !== 0
    ) {
      Alert.setCommentsData(Alert.codeDetails.commentsdata);

      return true;
    } else if (Alert.comingObj.hasOwnProperty("commentsdata")) {
      Alert.setCommentsData(Alert.comingObj.commentsdata);

      return true;
    } else {
      return false;
    }
  };

  const handleShowScore = () => {
    if (Alert.testData.viewtest === constants.DISPLAY_TEST_RESULT.ALL) {
      return true;
    } else {
      return false;
    }
  };

  const handleNetworkError = (from) => {
    Alert.setResultCode(4000);

    let newShowNotify = {
      show: true,
    };

    if (from === "Submit" && Alert.showNotify.primaryButton !== "Save") {
      newShowNotify.primaryButton = from;
    } else {
      newShowNotify.primaryButton = "Save";
    }

    newShowNotify.title = "Warning";
    newShowNotify.primaryButtonFunction =
      Alert.testData.testtype === constants.libTypeIds.CODING_CHALLENGE
        ? handleSaveTest
        : submitAnswers;
    newShowNotify.msg =
      "There appears to be an issue with your network. Please exit fullscreen mode, check your network, and then click the save button to save your answer.";

    Alert.setShowNotify(newShowNotify);
  };

  const handleToGetLearnerTimeTakenToSubmit = () => {
    let secs = Alert.codeDetails.timelimit * 60 - Alert.timeLimit;
    let minutes = Math.floor(secs / 60);

    secs = secs % 60;

    let hours = Math.floor(minutes / 60);

    minutes = minutes % 60;

    return pad(hours) + " : " + pad(minutes) + " : " + pad(secs);
  };

  const onFocus = () => {
    focusOutSec.current = 0;
    clearInterval(focusOutTimer.current);
    window.removeEventListener("blur", onBlur);
  };

  const onBlur = () => {
    Alert.tabSwitchCount.current = Alert.tabSwitchCount.current + 1;

    focusOutTimer.current = setInterval(() => {
      let second = focusOutSec.current;

      if (second === 10 || Alert.tabSwitchCount.current === 10) {
        clearInterval(focusOutTimer.current);
        Alert.isLearnerQuitQns.current = true;
        handleSubmitLearnerCodeEvaluation(true, true, true, false);
      } else {
        second++;
        focusOutSec.current = second;
      }
    }, 1000);
  };

  const handleQuitTest = () => {
    Alert.setLpData({});
    handleClose();
    Alert.isLearnerQuitQns.current = true;
    Alert.tabSwitchCount.current = 0;
    // Alert.leaveMeeting();
    handleSubmitLearnerCodeEvaluation(false, false, true, false);
  };

  const handleSaveTest = () => {
    handleClose();
    // Alert.leaveMeeting();
    handleSubmitLearnerCodeEvaluation(false, false, false, false);
  };

  const handleTimeOut = () => {
    handleClose();
    Alert.setLpData({});
    // Alert.leaveMeeting();
    handleSubmitLearnerCodeEvaluation(false, false, false, true);
  };

  const checkFullScreen = () => {
    Alert.isLearnerQuitQns.current = true;
    handleSubmitLearnerCodeEvaluation(true, true, true, false);
  };

  const handleNavigateToHome = () => {
    Alert.setFromCodeEditor(true);
    Alert.setLpData({});
    Alert.setShowInstruction(false);
    Alert.setIsShowTimeAlert(false);
    handleClose();

    if (
      Alert.testData.mockuse &&
      Alert.testData.testtype === constants.libTypeIds.CODING_CHALLENGE &&
      Alert.codeDetails.secData.completedcount + 1 !==
        Alert.codeDetails.secData.totalCount
    ) {
      let test = {
        ...Alert.testData,
        lmtid: Alert.codeDetails.lmtid,
        lmpid: Alert.codeDetails.lmpid,
      };
      Alert.navigate("/instruction");
      handleCodingInfo(test);
    } else {
      handleNavigate();
    }

    Alert.setResultCode("");
    Alert.tabSwitchCount.current = 0;
    // Alert.leaveMeeting();

    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    Alert.setAssignQnDetailsForLearner([]);
  };

  const getSaveAnsPayload = () => {
    let payload = {
      testid: Alert.testData.testid,
      libtype: Alert.testData.testtype,
      testmode: Alert.testData.testmode,
      lpconfigid: Alert.testData.lpconfigid,
      learnerid: getDataFromStorage("learnerid"),
    };

    if (Alert.testData.testtype === constants.libTypeIds.CODING_CHALLENGE) {
      payload.answer = Alert.learnerCode;
      payload.timeleft = Alert.timeLimit;
      payload.questionid = Alert.codeDetails.qnid;
      payload.languageid = Alert.selectedLanguage.value;
    } else {
      let studentAnswers = [];

      for (const key in Alert.answers) {
        studentAnswers.push({
          qnid: key,
          answer: Alert.answers[key],
        });
      }

      if (
        Alert.testData.testtype === constants.libTypeIds.MCQ ||
        Alert.testData.testtype === constants.libTypeIds.DESCRIPTIVE
      ) {
        payload.answers = studentAnswers;
        payload.timeleft = Alert.duration.current;
      }
    }

    return JSON.stringify(payload);
  };

  const saveCurrentAnswer = () => {
    axios.post(
      "node/learner/question/saveans",
      getSaveAnsPayload(),
      getHeaderDetailsForAxios()
    );
  };

  const handleSubmitLearnerCodeEvaluation = async (
    isAutoSubmit,
    isSwitched,
    isQuit,
    isTimeOut,
    actionType
  ) => {
    if (navigator.onLine) {
      Alert.isLearnerQuitQns.current = isQuit;

      let newCodeDetials = JSON.parse(JSON.stringify(Alert.codeDetails));

      let isUserCodeEMpty = true;

      if (newCodeDetials.status === constants.QUESTION_STATUS.ASSIGNED) {
        let learnerNewCode = newCodeDetials.templatecode.filter(
          (codeDetail) => codeDetail.langid === Alert.selectedLanguage.value
        );

        if (learnerNewCode[0].userCode.trim().length === 0) {
          isUserCodeEMpty = false;
        }
      } else if (
        newCodeDetials.status === constants.QUESTION_STATUS.RESUBMIT ||
        newCodeDetials.status === constants.QUESTION_STATUS.CONTINUE
      ) {
        if (Alert.learnerCode.trim().length === 0) {
          isUserCodeEMpty = false;
        }
      }

      if (isUserCodeEMpty) {
        if (
          !isSwitched &&
          !isAutoSubmit &&
          !newCodeDetials.isChangeCode &&
          Alert.resultCode !== constants.RESULT_STATUS.NETWORK_ISSUE
        ) {
          Alert.setShowNotify({
            show: true,
            title: "Warning",
            titleSvg: "warning",
            msg: "We do not see any change in the code. Write your code and then submit.",
          });
        } else {
          let specs = {};
          let learnerNewCode =
            newCodeDetials.status === constants.QUESTION_STATUS.ASSIGNED
              ? newCodeDetials.templatecode.filter(
                  (codeDetail) =>
                    codeDetail.langid === Alert.selectedLanguage.value
                )
              : [];

          specs.method = "post";
          specs.api = "submitcode";
          specs.language = Alert.selectedLanguage.label.toLowerCase();
          specs.time = handleToGetLearnerTimeTakenToSubmit();
          specs.body = {
            code:
              newCodeDetials.status === constants.QUESTION_STATUS.RESUBMIT ||
              newCodeDetials.status === constants.QUESTION_STATUS.CONTINUE
                ? Alert.learnerCode
                : learnerNewCode[0].userCode,
            type: "submitcode",
            timedout: isTimeOut,
            switched: isSwitched,
            autoSubmit: isAutoSubmit,
            timeleft: Alert.timeLimit,
            proctored: Alert.camAvailable,
            lmtid: Alert.codeDetails.lmtid,
            lmpid: Alert.codeDetails.lmpid,
            mockuse: Alert.testData.mockuse,
            questionid: Alert.codeDetails.qnid,
            quit: Alert.isLearnerQuitQns.current,
            mocklp: localStorage.getItem("mockLp"),
            testid: Alert.codeDetails.secData.testId,
            languageid: Alert.selectedLanguage.value,
            lpconfigid: Alert.codeDetails.lpConfigId,
            learnerid: getDataFromStorage("learnerid"),
            switchedcount: Alert.tabSwitchCount.current,
            testmode: Alert.codeDetails.secData.testMode,
            totalqncount: Alert.codeDetails.secData.totalCount,
            language: Alert.selectedLanguage.label.toLowerCase(),
          };

          try {
            Alert.setIsLoaded(true);

            let response = await apiCall(specs);

            if (!actionType)
              handleResponseForSubmittedLearnerCodefunction(
                response,
                isAutoSubmit,
                isSwitched,
                isQuit,
                isTimeOut,
                actionType
              );
            else {
              if (actionType === constants.TEST_ACTIONS.REMOVE_FROM_TEST) {
                // Alert.leaveMeeting();
                handleNavigateToHome();
              } else if (
                actionType === constants.TEST_ACTIONS.REMOVE_FROM_BATCH
              ) {
                // Alert.leaveMeeting();
                Alert.handleDisableUser();
                Alert.navigate("/batch");
                localStorage.removeItem("batchid");
                localStorage.removeItem("lpid");
              }
            }
          } catch (error) {
            if (
              Alert.resultCode === constants.RESULT_STATUS.NETWORK_ISSUE &&
              error.message !== "Network Error"
            ) {
              // Alert.leaveMeeting();
              handleNavigateToHome();
            } else {
              if (
                (error.message === "timeout of 30000ms exceeded" ||
                  error.message.includes("504")) &&
                navigator.onLine
              ) {
                Alert.setShowNotify({
                  show: true,
                  title: "Error",
                  titleSvg: "error",
                  secondaryButton: "Submit",
                  primaryButton: "Try Again",
                  secondaryButtonFunction: handleTimeOut,
                  msg: (
                    <NotifyBody component="codingPageNotifyMsgForInfiniteLoopWhileSubmitting" />
                  ),
                });
              } else if (
                !navigator.onLine ||
                error.message === "Network Error"
              ) {
                handleNetworkError("Submit");
              } else {
                getCatchBlockDetails(error);
              }
            }
          } finally {
            Alert.setIsLoaded(false);
          }
        }
      } else {
        Alert.setShowNotify({
          size: "sm",
          show: true,
          title: "Error",
          titleSvg: "error",
          msg: "There is no code to submit",
        });
      }
    } else {
      handleNetworkError("Submit");
    }
  };

  /* mcq */

  const submitAnswers = (auto, time, from, actionType) => {
    if (navigator.onLine) {
      setHasSubmitted(true);

      let testTypeId = Alert.comingObj.sections[0].qnconfig[0].libtypeid;

      if (
        testTypeId === constants.libTypeIds.SAQ ||
        testTypeId === constants.libTypeIds.LAQ
      ) {
        testTypeId = constants.libTypeIds.DESCRIPTIVE;
      }

      if (
        testTypeId === constants.libTypeIds.MCQ_MULTI ||
        testTypeId === constants.libTypeIds.MCQ_SINGLE
      ) {
        testTypeId = constants.libTypeIds.MCQ;
      }

      clearInterval(Alert.interval.current);
      handleClose();

      let studentAnswers = [];

      if (
        Alert.comingObj.videoqn === 1 &&
        Alert.videoQn.current !== undefined &&
        Alert.videoQn.current.length > 0
      ) {
        for (const key of Alert.videoQn.current) {
          studentAnswers.push({
            qnid: key.id,
            answer: key.answer ? key.answer : "",
          });
        }
      } else {
        for (const key in Alert.answers) {
          studentAnswers.push({
            qnid: key,
            answer: Alert.answers[key],
          });
        }
      }

      axios
        .post(
          "node/learner/course/test/submit",
          JSON.stringify({
            testtype: testTypeId,
            answers: studentAnswers,
            lmtid: Alert.comingObj.lmtid,
            lmpid: Alert.comingObj.lmpid,
            mockuse: Alert.testData.mockuse,
            timeleft: Alert.duration.current,
            switchedcount: switchCount.current,
            mocklp: localStorage.getItem("mockLp"),
            autoeval: Alert.mcqAndDesTestAndLpId.autoEval,
            lpid: parseInt(Alert.mcqAndDesTestAndLpId.lpid),
            lpconfigid: Alert.mcqAndDesTestAndLpId.lpconfigid,
            testid: parseInt(Alert.mcqAndDesTestAndLpId.testid),
            learnerid: parseInt(getDataFromStorage("learnerid")),
            switched: auto === undefined || auto === null ? false : true,
            autosubmit: auto === undefined || auto === null ? false : true,
            proctored:
              Alert.mcqAndDesTestAndLpId.proctored ===
              constants.PROCTORING_MODE.TAB_CAM
                ? true
                : false,
          }),
          {
            timeout: 30000,
            mode: "no-cors",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // if (Alert.resultCode === constants.RESULT_STATUS.NETWORK_ISSUE) {
          //   handleNavigateToHome();
          //   Alert.leaveMeeting();
          // } else {
          //   Alert.setResultCode(res.data.resultCode);

          if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
            Alert.setShowNotify({
              show: true,
              title: "Info",
              msg: res.data.message,
            });
          } else {
            // if (actionType === constants.TEST_ACTIONS.REMOVE_FROM_BATCH) {
            //   Alert.handleDisableUser();
            //   Alert.leaveMeeting();
            //   Alert.navigate("/batch");
            //   localStorage.removeItem("batchid");
            //   localStorage.removeItem("lpid");
            // } else if (
            //   actionType === constants.TEST_ACTIONS.REMOVE_FROM_TEST
            // ) {
            //   handleNavigateToHome();
            //   Alert.leaveMeeting();
            // }
            // if (!actionType || actionType === constants.TEST_ACTIONS.WARN) {

            if (time) {
              // Alert.leaveMeeting();
              Alert.setShowNotify({
                show: true,
                title: "Time lapsed",
                primaryButton: "Back to the learning path",
                primaryButtonFunction: handleNavigateToHome,
                msg: (
                  <NotifyBody component="codingPageNotifyMsgForTimeLapsedsWhileSubmitting" />
                ),
              });
            } else if (auto) {
              // Alert.leaveMeeting();
              Alert.setShowNotify({
                show: true,
                title: "Assignment Auto-submitted",
                primaryButton: "Back to the learning path",
                primaryButtonFunction: handleNavigateToHome,
                msg: (
                  <NotifyBody component="codingPageNotifyMsgForTabSwitchesWhileSubmitting" />
                ),
              });
            } else {
              Alert.setShowNotify({
                size: "sm",
                show: true,
                titleSvg: "success",
                msg: (
                  <NotifyBody
                    msg={res.data.message}
                    component="mcqAndDescPageNotifyMsgForSuccessWhileSubmitting"
                  />
                ),
                primaryButtonFunction:
                  Alert.testData.testmode === constants.MODE.TEST_MODE
                    ? handleNavigateToHome
                    : handleShowAnsforPrac,
              });
            }
          }
        })
        .catch((error) => {
          if (
            error.message === "timeout of 30000ms exceeded" ||
            (error.message.includes("504") && navigator.onLine)
          ) {
            Alert.setShowNotify({
              show: true,
              title: "Error",
              msg: "Something went wrong…. Please, try again",
            });
          } else if (!navigator.onLine || error.message === "Network Error") {
            handleNetworkError();
          } else {
            getCatchBlockDetails(error);
          }
        })
        .finally(() => Alert.setIsLoaded(false));
    } else {
      handleNetworkError();
    }
  };

  const handleResponseForSubmittedLearnerCodefunction = (
    response,
    isAutoSubmit,
    isSwitched,
    isQuit,
    timedout
  ) => {
    if (Alert.resultCode === constants.RESULT_STATUS.NETWORK_ISSUE) {
      // Alert.leaveMeeting();
      handleNavigateToHome();
    } else {
      Alert.setResultCode(response.resultCode);
      if (response.resultCode === constants.RESULT_STATUS.SUCCESS) {
        Alert.setIsTakeTest(false);

        localStorage.removeItem("splitPos");
        localStorage.removeItem("splitWidthPos");

        let newCodeDetails = JSON.parse(JSON.stringify(Alert.codeDetails));

        newCodeDetails.secData.completedcount =
          newCodeDetails.secData.completedcount + 1;

        let filterNewCodeDetails;

        if (newCodeDetails.secData.topics !== undefined) {
          filterNewCodeDetails = newCodeDetails.secData?.topics.filter(
            (topic) =>
              topic.topicid === newCodeDetails.topics.topicid &&
              topic.totalcount
          );
        }

        Alert.setCodeDetails(newCodeDetails);

        if (
          filterNewCodeDetails !== undefined &&
          newCodeDetails.secData.completedcount !==
            filterNewCodeDetails[0].totalcount
        ) {
          Alert.setCount(0);
          Alert.setShowNotify({
            show: true,
            title: "Success",
            primaryButton: "Go to next coding challenge",
            secondaryButton: "Back to the learning path",
            secondaryButtonFunction: handleNavigateToHome,
            primaryButtonFunction: handleAssignNextQnToLearner,
            msg: (
              <NotifyBody
                timedOut={timedout}
                codeDetails={newCodeDetails}
                component="codingPageNotifyMsgForSuccessWhileSubmitting"
              />
            ),
          });
        } else {
          Alert.setIsShowTimeAlert(true);
          Alert.setShowNotify({
            show: true,
            title: "Success",
            primaryButton: "Back to the learning path",
            primaryButtonFunction: handleNavigateToHome,
            msg: (
              <NotifyBody
                timedOut={timedout}
                codeDetails={newCodeDetails}
                component="codingPageNotifyMsgForSuccessWhileSubmitting"
              />
            ),
          });
        }
      } else if (
        response.resultCode === constants.RESULT_STATUS.FAILURE_ATTEMPT
      ) {
        Alert.setShowNotify({
          show: true,
          title: "Warning",
          titleSvg: "warning",
          primaryButton: "Try Again",
          msg: (
            <NotifyBody
              response={"response"}
              component="codingPageNotifyMsgForFailedTestCasesWhileSubmitting"
            />
          ),
        });
      } else if (
        response.resultCode === constants.RESULT_STATUS.REPLACE_QUESTION ||
        isQuit
      ) {
        localStorage.removeItem("splitPos");
        localStorage.removeItem("splitWidthPos");

        if (isAutoSubmit && isSwitched && isQuit) {
          Alert.setIsTakeTest(false);
          Alert.setIsShowTimeAlert(true);
          Alert.setShowNotify({
            show: true,
            title: "Assignment Auto-submitted",
            primaryButton: "Back to the learning path",
            primaryButtonFunction: handleNavigateToHome,
            msg: (
              <NotifyBody component="codingPageNotifyMsgForTabSwitchesWhileSubmitting" />
            ),
          });
        } else if (isAutoSubmit && !isSwitched && isQuit) {
          Alert.setIsTakeTest(false);
          Alert.setIsShowTimeAlert(true);
          Alert.setShowNotify({
            show: true,
            title: "Time lapsed",
            primaryButton: "Back to the learning path",
            primaryButtonFunction: handleNavigateToHome,
            msg: (
              <NotifyBody component="codingPageNotifyMsgForTimeLapsedsWhileSubmitting" />
            ),
          });
        } else if (isQuit) {
          // Alert.leaveMeeting();
          handleNavigateToHome();
        } else {
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: response.data,
          });
        }
      } else if (
        response.resultCode === constants.RESULT_STATUS.INCORRECT_ANSWER &&
        !isQuit
      ) {
        Alert.setShowNotify({
          show: true,
          secondaryButton: "Submit",
          title: "We found an issue",
          primaryButton: "Try Again",
          secondaryButtonFunction: handleQuitTest,
          msg: (
            <NotifyBody component="codingPageNotifyMsgForFailedTestCasesStaticWhileSubmitting" />
          ),
        });
      } else if (
        response.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR
      ) {
        Alert.setShowNotify({
          show: true,
          title: "Error",
          msg: response.msg,
        });
      } else if (response.message) {
        Alert.setShowNotify({
          show: true,
          title: "Error",
          msg: response.message,
        });
      } else {
        Alert.setShowNotify({
          show: true,
          msg: response,
          title: "Error",
        });
      }
    }
  };

  return {
    timer,
    onBlur,
    onFocus,
    setTimer,
    timeoutId,
    focusOutSec,
    switchCount,
    hasSubmitted,
    getSuccessMsg,
    handleTimeOut,
    submitAnswers,
    handleSaveTest,
    handleQuitTest,
    setHasSubmitted,
    checkFullScreen,
    handleShowScore,
    saveCurrentAnswer,
    handleNetworkError,
    handleShowSubmitDiv,
    handleNavigateToHome,
    handleShowCommentsButton,
    handleSubmitLearnerCodeEvaluation,
  };
}
