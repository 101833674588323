import { useContext } from "react";
import constants from "../constants";
import LeftNavBarHooks from "./LeftNavBarHooks";
import AlertContext from "../context/AlertContext";

export default function WarningNoticeHooks() {
  const { lpData, module, testId, setModule } = useContext(AlertContext);

  const { openTest } = LeftNavBarHooks();

  const goToNextTest = () => {
    let modules = lpData.modules;

    let moduleIndex = modules.findIndex(
      (modules) => modules.modid === module.id
    );

    if (moduleIndex === -1) {
      console.error("Invalid module ID");
      return;
    }

    let testIndex = modules[moduleIndex].data.findIndex(
      (test) => test.testid === testId || test.libid === testId
    );

    if (testIndex === -1) {
      console.error("Invalid test ID");
      return;
    }

    testIndex++;

    if (testIndex >= modules[moduleIndex].data.length) {
      testIndex = 0;
      moduleIndex++;

      if (moduleIndex >= modules.length) {
        moduleIndex = 0;
      }
    }

    while (
      modules[moduleIndex].data[testIndex].status ===
      constants.PROGRESS_STATUS.LOCKED
    ) {
      testIndex++;

      if (testIndex >= modules[moduleIndex].data.length) {
        testIndex = 0;
        moduleIndex++;

        if (moduleIndex >= modules.length) {
          moduleIndex = 0;
        }
      }
    }

    setModule({
      id: modules[moduleIndex].modid,
      name: modules[moduleIndex].modname,
    });

    openTest(modules[moduleIndex].data[testIndex]);
  };

  return { goToNextTest };
}
