import Button from "../../Button";
import Score from "../../Score/Score";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import useCollapse from "react-collapsed";
import React, { useContext } from "react";
import constants from "../../../constants";
import RejectSvg from "../../../Svg/RejectSvg";
import Offcanvas from "react-bootstrap/Offcanvas";
import ProctorEyeSvg from "../../../Svg/ProctorEyeSvg";
import Checkbox from "../../CommonComponents/Checkbox";
import CommentsHooks from "../../../Hooks/CommentsHooks";
import AlertContext from "../../../context/AlertContext";
import ApproveSvgIcon from "../../../Svg/ApproveSvgIcon";
import CommentSvgIcon from "../../../Svg/CommentSvgIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../Notification-Loading/Loading/Loading";
import CodingHeaderAdminSideHooks from "../../../Hooks/CodingPage/CodingHeaderAdminSideHooks";

import {
  faXmark,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export default function CodingHeaderAdminSide() {
  const { profile, isLoaded, showBreadCrumbsData } = useContext(AlertContext);

  const { toggleDrawer } = CommentsHooks();

  const {
    showProfile,
    deleteImages,
    handleReject,
    handleApprove,
    showProctoring,
    adminSubmitTest,
    setDeleteImages,
    handleDeleteImg,
    showSubmitButton,
    handleSelectImage,
    handleProfileShow,
    handleProfileClose,
    handleShowProctoring,
    handleProctoringClosePopup,
  } = CodingHeaderAdminSideHooks();

  const TrackTimelineDetails = (Props) => {
    const changeTimeandDate = (timestamp) => {
      const dateObj = new Date(timestamp);

      const date = dateObj.toLocaleString();

      return date;
    };

    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

    return (
      <div className="collapsible_userDetails">
        <div className="header" {...getToggleProps()}>
          <span className="timeline-header">Activity</span>
          <span className="timeline-header-arrowIcon">
            <FontAwesomeIcon
              className="arrowIcon"
              icon={isExpanded ? faChevronDown : faChevronRight}
            />
          </span>
        </div>
        <div className="details-collapse" {...getCollapseProps()}>
          <div className="content">
            {Props.eventLogData != null &&
              Props.eventLogData.map((data) => (
                <div className="activity-log">
                  <span className="activity-log-timestamp">
                    {changeTimeandDate(data.createdTimeStamp)}
                  </span>
                  <span className="activity-log-eventtext">
                    {data.eventtext}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="codingHeaderAdminSideMainContainer">
      <div className="codingHeaderAdminSideHeader">
        {showSubmitButton() && (
          <Button
            size={"sm"}
            onClick={adminSubmitTest}
            hierarchy={{
              type: "primary",
              buttonText: "Submit",
            }}
          />
        )}
        {(showBreadCrumbsData.status === constants.PROGRESS_STATUS.EVALUATED ||
          showBreadCrumbsData.status ===
            constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
          showBreadCrumbsData.status ===
            constants.PROGRESS_STATUS.PARTIAL_EVALUATED) && <Score />}
        {(showBreadCrumbsData.testMode === constants.MODE.TEST_MODE ||
          showBreadCrumbsData.type === constants.libTypeIds.ASSIGNMENT) && (
          <>
            {showBreadCrumbsData.type !== constants.libTypeIds.MCQ &&
              showBreadCrumbsData.type !== constants.libTypeIds.DESCRIPTIVE && (
                <ApproveSvgIcon onClick={handleApprove} />
              )}
            <RejectSvg onClick={handleReject} />
          </>
        )}
        <CommentSvgIcon onClick={toggleDrawer("right", true)} />
        {showBreadCrumbsData.type !== constants.libTypeIds.ASSIGNMENT && (
          <ProctorEyeSvg onClick={handleShowProctoring} />
        )}
      </div>

      <div style={{ marginTop: "55px" }}>
        <Offcanvas
          placement="top"
          show={showProctoring}
          id="proctoringDetailContainer"
          onHide={() => {
            handleProctoringClosePopup();
            setDeleteImages({ withKey: [], withOutKey: [] });
          }}
        >
          {isLoaded && <Loading />}

          <div className="proctoringTitleSection">
            <FontAwesomeIcon
              icon={faXmark}
              className="proctoringCloseIcon"
              onClick={() => {
                handleProctoringClosePopup();
                setDeleteImages({ withKey: [], withOutKey: [] });
              }}
            />
          </div>

          <Form noValidate name="userProctoringForm" className="proctoringForm">
            <div className="details-img-seperate">
              <div className="user-profile">
                <span className="userImage" onClick={handleProfileShow}>
                  <img
                    width={"150"}
                    height={"150"}
                    alt="userImage"
                    src={profile.registerUrl}
                  />
                </span>
              </div>
              <Modal
                show={showProfile}
                onHide={handleProfileClose}
                className="proctoringProfile"
              >
                <img
                  width={"100%"}
                  height={"300"}
                  alt="userImage"
                  src={profile.registerUrl}
                />
              </Modal>
              <div className="student-details-proctor">
                <span className="userName">Student Name</span>:
                <span className="userName-durn">
                  {showBreadCrumbsData.studentName}
                </span>
                <div className="proctoringDetails">
                  <div>
                    <span className="userName">No of tab switches</span>:
                    <span className="userName-durn">
                      {profile.switched ? profile.switched : 0}
                    </span>
                  </div>
                  <div>
                    <span className="userName">Creditibility score</span>:
                    <span className="userName-durn">
                      {profile.credibilityScore ? profile.credibilityScore : 0}
                    </span>
                  </div>

                  <div>
                    <span className="userName">Duration</span>:
                    <span className="userName-durn">
                      {profile.duration !== "" ? profile.duration : "No Data"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <TrackTimelineDetails eventLogData={profile.eventdata} />
            </div>
            <div className="proctoringImagesTitleContainer">
              <div className="proctoringImagesTitleSec">
                <Checkbox
                  size="md"
                  text="Images"
                  disabled={!profile.proctoringdatas?.length > 0}
                  onChange={(checked) => handleSelectImage(checked, "all")}
                  checked={
                    profile.proctoringdatas?.length ===
                    deleteImages.withKey.length
                  }
                />
              </div>
              {profile.proctoringdatas?.length > 0 && (
                <div>
                  <Button
                    size={"sm"}
                    onClick={handleDeleteImg}
                    disabled={!deleteImages.withOutKey.length > 0}
                    hierarchy={{
                      type: "primary",
                      buttonText: "Delete",
                    }}
                  />
                </div>
              )}
            </div>
            {profile.proctoringdatas ? (
              <div className="ProctoringImagesContainer">
                {profile.proctoringdatas?.map((data, index) => {
                  let utcDate = new Date(data.timestamp);

                  const istFormatter = utcDate.toLocaleString("en-IN", {
                    hour12: true,
                    day: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    month: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  });

                  return (
                    <div className="proctoringImgDiv" key={index}>
                      <div className="proctoringImgTimestampSec">
                        <Checkbox
                          size="sm"
                          text={istFormatter}
                          onChange={(checked) =>
                            handleSelectImage(checked, data.imagename)
                          }
                          checked={data.isChecked}
                        />
                      </div>
                      <img
                        width={"50px"}
                        height={"100"}
                        alt="proctoringFacedetected"
                        src={data.imagePresignedUrl}
                        className={
                          data.faceorientationnormal === 0
                            ? "proctoringFacedetected"
                            : "proctoringNotdetected"
                        }
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p className="proctoringTimeStamp">No proctoring data</p>
              </div>
            )}
            <hr className="proctoringLine" />
            {/* <Button
              size={"sm"}
              onClick={() => {
                handleProctoringClosePopup();
                setDeleteImages({ withKey: [], withOutKey: [] });
              }}
              hierarchy={{
                type: "primary",
                buttonText: "Cancel",
              }}
            /> */}
          </Form>
        </Offcanvas>
      </div>
    </div>
  );
}
