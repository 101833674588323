import React, { useState, useContext } from "react";
import Radio from "../../../CommonComponents/Radio";
import Button from "../../../Button";
import DownloadIconSvg from "../../../../Svg/DownloadIconSvg";
import DownloadSvgIcon from "../../../../Svg/DownloadSvgIcon";
import UploadSvg from "../../../../Svg/UploadIconsvg";
import DownloadSvg from "../../../../Svg/Downloadsvg";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import AlertContext from "../../../../context/AlertContext";
import useCommonFunctionHooks from "../../../../Hooks/useCommonFunctionHooks";

function UploadGcatFb(Props) {
  console.log(Props);
  const { setIsUnAuth, setMsg, setShow, setIsLoaded, setTitle } =
    useContext(AlertContext);
  const [fileLoaded, setFileLoaded] = useState(null);
  const {  handleClose } = useCommonFunctionHooks();
  const commonFunction = useCommonFunctionHooks();
  const axios = useAxiosPrivate();
  const [gcat, setGcat] = useState(1);
  const [isNotSupoortedFile, setIsNotSupportedFile] = useState(false);

  function generateAndDownloadCSV(headers, firstColumnData, filename) {
    let csvContent = headers.join(",") + "\n";
    firstColumnData.forEach((item) => {
      csvContent += item + "\n";
    });
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const handleChangeOpt = (e) => {
    console.log(e);
    setGcat(!gcat);
    setFileLoaded(null);
  };

  const handleClearFile = () => {
    setFileLoaded(null);
  };

  const handleLoadFile = (e) => {
    const file = e.target?.files[0];
    console.log("file details",file);
    if (file) {
      setFileLoaded(file);
    }
    e.target.value = "";
  };

  const handleUploadFile = () => {
    if (!fileLoaded?.size) return;
    let reader = new FileReader();
    reader.onload = async (e) => {
      if (!e.target.result.includes("data:text/csv")) {
        // setShow(true);
        // setTitle("Warning !");
        // setMsg("Wrong file type - CSV only.");
        setIsNotSupportedFile(true);
      } else {
        const form = new FormData();
        form.append("csv", fileLoaded);
        form.append("batchid",Props.batchId)
        axios
          .post(
            gcat
              ? `node/admin/batch/profile/upload/gcat`
              : `node/admin/batch/profile/upload/feedback`,
            form,
            commonFunction.getHeaderDetailsForAxios("multipart")
          )
          .then((res) => {
            console.log("res ", res);
            handleClose();
            if (res.data.resultCode === 1000) {
              setShow(true);
              setTitle("Success");
              setMsg(res.data.msg);
              setFileLoaded(null);
            } else if (res.data.resultCode === 2050) {
              setShow(true);
              setTitle("Warning !");
              setMsg(res.data.msg);
            } else {
              setShow(true);
              setTitle("Error");
              setMsg("Something went wrong. Please try again later.");
            }

            if (res.data?.hasOwnProperty("filedata")) {
              const url = window.URL.createObjectURL(
                new Blob([res.data.filedata])
              );
              const link = document.createElement("a");

              link.href = url;
              link.setAttribute(
                "download",
                `${new Date().toISOString().split("T")[0]}.csv`
              );
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch((error) => {
            console.log("err ", error);
            if (error.message.includes("403")) {
              setShow(true);
              setIsUnAuth(true);
              setTitle("Error");
              setMsg(
                "You have been logged-out due to inactivity. Login again."
              );
            }

            if (error.data?.hasOwnProperty("filedata")) {
              const url = window.URL.createObjectURL(
                new Blob([error.data.filedata])
              );
              const link = document.createElement("a");

              link.href = url;
              link.setAttribute(
                "download",
                `${new Date().toISOString().split("T")[0]}.csv`
              );
              document.body.appendChild(link);
              link.click();
            }
          });
      }
    };

    reader.readAsDataURL(fileLoaded);
  };

  const handleTemplateDownload = async () => {
    let skillsRes = await axios.get(
      `node/admin/batch/${Props.batchId}/skills`,
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    let batchSkills = skillsRes.data.data.map((i) => i.skillname);
    let gcatHeaders = [
      "EMAIL",
      "GRAMMAR_SCORE",
      "APTITUDE_SCORE",
      "CODING_SCORE",
    ];
    let feedbackHeaders = ["EMAIL", ...batchSkills, "OVERALL_FEEDBACK"];
    let allBatchUsersRes = await axios.get(`node/admin/getemails/2/${Props.batchId}`, {
      headers: {
        "Content-type": "application/json",
      },
    });
    let allEmails = allBatchUsersRes.data.data.map((i) => i.email);
    let headers, fileName;
    if (gcat) {
      headers = gcatHeaders;
      fileName = `GCAT_Batch_${Props.batchId}`;
    } else {
      headers = feedbackHeaders;
      fileName = `Feedback_Batch_${Props.batchId}`;
    }
    generateAndDownloadCSV(headers, allEmails, fileName);
  };

  return (
    <div className="uploadgcatfbcont">
      <div className="prcont">
        <p className="desc">Select the type of file you want to upload:</p>
        <div className="radiobtnCont">
          <Radio
            checked={gcat}
            value={1}
            size="md"
            text="GCAT Score"
            disabled={false}
            onChange={handleChangeOpt}
          />
          <Radio
            checked={!gcat}
            value={0}
            size="md"
            text="Feedback"
            disabled={false}
            onChange={handleChangeOpt}
          />
        </div>
      </div>
      <div className="downloadTemplate">
        <div className="textForTemplateCont">
          <p className="btextfortemplate">
            {`${gcat ? "GCAT Score" : "Feedback"}` + " Template"}
          </p>
          <p className="ntextfortemplate">
            We recommend you to use this template to upload the score.
          </p>
        </div>
        <Button
          disable={false}
          icon={{
            type: "leading",
            icon: <DownloadSvg stroke="#667085" />,
          }}
          size={"sm"}
          onClick={handleTemplateDownload} //to be changed
          hierarchy={{
            buttonText: "Download",
            type: "tertiaryGrey",
          }}
        />
      </div>
      {/* ------------------------------------------- */}
      <div>
      <div className={fileLoaded==null?"upLoadTemplate":"upLoadTemplateAfter"}>
        <div className="textForTemplateCont">
          <p className="btextfortemplate">
            {"Upload " + `${gcat ? " GCAT Score" : "Feedback"}`}
          </p>
          <p className="ntextfortemplate">Supported format: .csv</p>
          {isNotSupoortedFile && (
            <p className="etextfortemplate">
              The uploaded file is not supported.
            </p>
          )}
        </div>

        {fileLoaded ==null &&<div className="upload-div">
          <Button
            disable={false}
            icon={{
              type: "leading",
              icon: <UploadSvg stroke="#344054" />,
            }}
            size={"sm"}
            onClick={handleLoadFile}
            hierarchy={{
              buttonText: "Upload",
              type: "secondaryGrey",
            }}
          />

          <input
            className="upload-div-input"
            type="file"
            accept=".csv"
            // hidden
            name="evaluation"
            onChange={handleLoadFile}
          />
        </div>}
      </div>
      {fileLoaded != null && (
        <div className="afterfileloadedcont">
          {/* <div className="innercontafterfileloaded"> */}
            <p>{fileLoaded.name}</p>
            <svg
              onClick={handleClearFile}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 4L4 12M4 4L12 12"
                stroke="#667085"
                stroke-width="1.33"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          {/* </div> */}
        </div>
      )}
      </div>
      <div className="btnCont">
        <Button
          disable={false}
          size={"lg"}
          onClick={handleClose}
          hierarchy={{
            buttonText: "Cancel",
            type: "secondaryGrey",
          }}
        />
        <Button
          disable={fileLoaded==null}
          size={"lg"}
          onClick={handleUploadFile}
          hierarchy={{
            buttonText: "Submit",
            type: "primary",
          }}
        />
      </div>
    </div>
  );
}

export default UploadGcatFb;
