import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import constants from "../../constants";
import { getDataFromStorage } from "../../util";

export default function Batch(props) {
  return (
    <div>
      <div className="batch-div">
        <div className="batch-div-batches">
          {props.batch.data.filter(data => (getDataFromStorage("role")==constants.Roles.parent && data.type==constants.BATCH_TYPE.LEARNING) || (getDataFromStorage("role")!=constants.Roles.parent &&(data.type==constants.BATCH_TYPE.LEARNING || data.type == constants.BATCH_TYPE.TESTING)) ).map((data) => (
            <div key={data.id} className="card-container">
              <Card
                className="Batch-card-div"
                sx={{ width: 310, height: 359 }}
                onClick={() => {
                  props.navigatehome(data.id);
                  props.handleSetLocalStorage(data);
                }}
              >
                <CardMedia
                  className="batch-div-batchimage"
                  sx={{ width: 310, height: 191 }}
                  component="img"
                  image={data.thumbnail}
                  alt="Logo"
                  style={{ objectFit: "fill" }}
                />
                <CardContent className="card-condainer-content">
                  <Typography
                    className="card-condainer-batchname"
                    component="div"
                  >
                    {data.name}
                  </Typography>
                  <Typography
                    className="card-condainer-batch-type"
                    component="div"
                  >
                    {constants.BATCH_TYPE.LEARNING === data.type
                      ? "Learning Batch"
                      : "Evaluation Batch"}
                  </Typography>
                  <Typography
                    className="card-condainer-batch-desc"
                    variant="body2"
                  >
                    {data.description}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          ))}
        </div>
        <div className="make-height-div"></div>
      </div>
    </div>
  );
}
