import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import SplitPane from "react-split-pane";
import constants from "../../../constants";
import Pane from "react-split-pane/lib/Pane";
import CustomTabPanel from "./CustomTabPanel";
import { getDataFromStorage } from "../../../util";
import Scrollbars from "react-custom-scrollbars-2";
import CustomFileExplorer from "./CustomFileExplorer";
import AlertContext from "../../../context/AlertContext";
import { sandpackDark } from "@codesandbox/sandpack-themes";
import Loading from "../../Notification-Loading/Loading/Loading";
import useUploadLargeFileToS3 from "../../../Hooks/useUploadLargeFileToS3";
import SandPackEditorSplitHooks from "../../../Hooks/SandPackEditorSplitHooks";

import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";

import {
  useSandpack,
  SandpackLayout,
  SandpackCodeEditor,
  SandpackThemeProvider,
} from "@codesandbox/sandpack-react";

export default function SandPackEditorTools(Props) {
  const {
    handleDragFinished,
    outputWindowHeight,
    problemWindowHeight,
    setOutputWindowHeight,
    setProblemWindowHeight,
    handleProblemDragFinished,
  } = SandPackEditorSplitHooks();

  const { isAdmin, sandpackFiles, setSandpackFiles, openSandpackEditor,learnerId } =
    useContext(AlertContext);

  const { uploadFileToS3, getS3Object } = useUploadLargeFileToS3();

  const { sandpack } = useSandpack();

  const preValue = useRef(0);
  const previewRef = useRef(null);

  const [value, setValue] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [previewUrl, setPreviewUrl] = useState("");
  const [filesLoaded, setFilesLoaded] = useState(false);
  const [previewLoaded, setPreviewLoaded] = useState(false);
  const [consoleMessages, setConsoleMessages] = useState([]);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.message) {
        setConsoleMessages((prevMessages) => [
          ...prevMessages,
          event.data.message,
        ]);
      }
    };

    if (window.addEventListener) {
      window.addEventListener("message", handleMessage);
    } else {
      window.attachEvent("onmessage", handleMessage);
    }
  }, [previewUrl]);

  const refreshIframe = () => {
    // setConsoleMessages([]);
    setRefreshKey((prevKey) => prevKey + 1);
    for (let file in sandpack.files) {
      uploadFileToS3(
        sandpack.files[file].code,
        isAdmin && Props.isqueReport
          ? `${process.env.REACT_APP_WEBSITE_HOST}/library/${Props.libDetails.id}${file}`
          : `${process.env.REACT_APP_WEBSITE_HOST}/${Props.libDetails.id}/${
              isAdmin
                ? learnerId
                : getDataFromStorage("learnerid")
            }${file}`,
        file.split(".")[1],
        "uploadAssignment"
      );
    }
    setSandpackFiles(sandpack.files);
    setPreviewUrl(
      isAdmin && Props.isqueReport
        ? `https://gradiouslmsapp.s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_WEBSITE_HOST}/library/${Props.libDetails.id}`
        : `https://gradiouslmsapp.s3.ap-south-1.amazonaws.com/${
            process.env.REACT_APP_WEBSITE_HOST
          }/${Props.libDetails.id}/${
            isAdmin
              ? learnerId
              : getDataFromStorage("learnerid")
          }/index.html`
    );
  };

  // Use a ref to track whether getSandpackFiles has been called
  const getSandpackFilesCalled = useRef(false);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    preValue.current = newValue;
    setValue(newValue);
  };

  const getSandpackFiles = useCallback(async () => {
    if (
      (isAdmin && Props.isqueReport) ||
      (!isAdmin &&
        Props.isAddSubmission &&
        Props.status === constants.PROGRESS_STATUS.UNLOCKED)
    ) {
      const filePath = `${process.env.REACT_APP_WEBSITE_HOST}/library/${Props.libDetails.id}`;
      let fileData = await getS3Object(filePath);
      sandpack.addFile(fileData);
      setFilesLoaded(true);
    } else if (Props.status !== constants.PROGRESS_STATUS.UNLOCKED) {
      const filePath = `${process.env.REACT_APP_WEBSITE_HOST}/${
        Props.libDetails.id
      }/${
        isAdmin ? learnerId : getDataFromStorage("learnerid")
      }`;

      let fileData = await getS3Object(filePath);
      sandpack.addFile(fileData);
      setFilesLoaded(true);
    } else {
      return;
    }
  }, [
    isAdmin,
    Props.status,
    Props.libDetails,
    Props.isqueReport,
    Props.isAddSubmission,
  ]);

  useEffect(() => {
    const savedSize = localStorage.getItem("splitPos");
    const savedWidthSize = localStorage.getItem("splitWidthPos");

    if (savedSize) {
      const [,] = savedSize.split(","); // Extract the height percentage value
      setOutputWindowHeight(8.4);
    }

    if (savedWidthSize) {
      const [widthPercentage] = savedWidthSize.split(",");
      const parsedWidth = parseFloat(widthPercentage.replace("%", ""));

      setProblemWindowHeight(parsedWidth);
    }
    const editorHeightdiv = document.getElementById("sandpackCodeEditor");

    if (editorHeightdiv) {
      editorHeightdiv.style.height = `90vh`;
    }
  }, []);

  useEffect(() => {
    if (previewLoaded) {
      setTimeout(() => {
        setPreviewLoaded(false);
      }, 1000);
    }
  }, [previewLoaded]);

  useEffect(() => {
    if (!getSandpackFilesCalled.current) {
      if (Props.libDetails.id) {
        getSandpackFiles();
      }

      getSandpackFilesCalled.current = true;
    }
  }, [getSandpackFiles]);

  useEffect(() => {
    setSandpackFiles(sandpack.files);
  }, [sandpack, setSandpackFiles]);

  return (
    <SandpackLayout>
      <SandpackThemeProvider theme={sandpackDark}>
        <div className="sandpackEditor">
          <SplitPane
            size={100}
            minSize={30}
            split="vertical"
            primary="first"
            className="fileExplorerDiv"
            onChange={handleProblemDragFinished}
          >
            <Pane
              minSize="20%"
              maxSize="30%"
              className="fileExplorerpane"
              paneStyle={{ backgroundColor: "red" }}
              initialSize={`${problemWindowHeight}%`}
              iniinitialSize="30%"
            >
              <Scrollbars autoHide>
                <CustomFileExplorer
                  sandpack={sandpack}
                  setSandpackFiles={setSandpackFiles}
                  sandpackFiles={sandpackFiles}
                  isopenEditor={openSandpackEditor}
                  setOpenSandpackEditor={Props.setOpenSandpackEditor}
                  filesLoaded={filesLoaded}
                  setFilesLoaded={setFilesLoaded}
                />
              </Scrollbars>
            </Pane>
            <SplitPane
              split="horizontal"
              onChange={handleDragFinished}
              primary="second"
            >
              <Pane minSize="2%" maxSize="90%" onChange={handleDragFinished}>
                <>
                  <SandpackCodeEditor
                    showTabs
                    showRunButton={false}
                    closableTabs
                    showInlineErrors
                    wrapContent={true}
                    editorHeight={`100vh`}
                    showLineNumbers={true}
                    id="sandpackCodeEditor"
                  />
                  <div
                    className="sandpackRunSec"
                    onClick={() => {
                      setPreviewLoaded(!previewLoaded);
                      refreshIframe();
                    }}
                  >
                    <svg
                      id="sandpackRunIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M4.16675 2.5L15.8334 10L4.16675 17.5V2.5Z"
                        stroke="#F96343"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <button id="sandpackRunText">Run</button>
                  </div>
                </>
              </Pane>
              <Pane
                id="outputParentWindowContainer"
                minSize={"5%"}
                maxSize={"95%"}
                initialSize={`${outputWindowHeight}%`}
              >
                <div className="outputandpreview">
                  <Box sx={{ bgcolor: "background.paper" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        indicatorColor="secondary"
                      >
                        <Tab label="Output" {...a11yProps(0)} />

                        <Tab label="Console" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                      {previewLoaded ? (
                        <div
                          style={{
                            position: "relative",
                            transform: "translate(-70px,70px)",
                          }}
                        >
                          <Loading />
                        </div>
                      ) : (
                        <iframe
                          ref={previewRef}
                          title="un"
                          key={refreshKey}
                          src={previewUrl}
                          width="100%"
                          height="100%"
                        />
                      )}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      <div className="sandpackConsoleSec">
                        <button
                          className="sandpackConsoleClearBtn"
                          title="Clear"
                          onClick={() => setConsoleMessages([])}
                        >
                          Clear
                        </button>
                        {consoleMessages.map((message, index) => (
                          <div key={index}>{JSON.stringify(message)}</div>
                        ))}
                      </div>
                    </CustomTabPanel>
                  </Box>
                </div>
              </Pane>
            </SplitPane>
          </SplitPane>
        </div>
      </SandpackThemeProvider>
    </SandpackLayout>
  );
}
