import { useContext } from "react";
import useApiCall from "../useApiCall";
import constants from "../../constants";
import { getDataFromStorage } from "../../util";
import useAxiosPrivate from "../useAxiosPrivate";
import AlertContext from "../../context/AlertContext";
import useCommonFunctionHooks from "../useCommonFunctionHooks";
import CodingHeaderLearnerSideHooks from "./CodingHeaderLearnerSideHooks";

export default function CodingOutputRunButtonHooks() {
  const Alert = useContext(AlertContext);

  const { apiCall } = useApiCall();
  const axios = useAxiosPrivate();
  const { getCatchBlockDetails } = useCommonFunctionHooks();
  const { handleNetworkError } = CodingHeaderLearnerSideHooks();

  const handleRunTestCase = async (testCaseCode) => {
    if (navigator.onLine) {
      let temp = testCaseCode;
      let isRunAllTestCases = false;
      let isUserCodeEMpty = true;

      temp[0].actualoutput = "";
      temp[0].language = Alert.selectedLanguage.label;
      testCaseCode = temp;

      /* Checking code is empty or not */

      if (
        (Alert.codeDetails.status === constants.QUESTION_STATUS.SUBMITTED &&
          Alert.codeDetails.isChangeCode) ||
        (Alert.codeDetails.status ===
          (constants.QUESTION_STATUS.RESUBMIT ||
            constants.QUESTION_STATUS.CONTINUE ||
            constants.QUESTION_STATUS.IN_PROGRESS ||
            constants.QUESTION_STATUS.TO_BE_EVALUATED) &&
          Alert.codeDetails.isChangeCode)
      ) {
        if (Alert.learnerCode.trim().length === 0) {
          isUserCodeEMpty = false;
        }
      } else if (
        Alert.codeDetails.status === constants.QUESTION_STATUS.SUBMITTED ||
        Alert.codeDetails.status === constants.QUESTION_STATUS.RESUBMIT ||
        Alert.codeDetails.status === constants.QUESTION_STATUS.CONTINUE ||
        Alert.codeDetails.status === constants.QUESTION_STATUS.IN_PROGRESS ||
        Alert.codeDetails.status === constants.QUESTION_STATUS.TO_BE_EVALUATED
      ) {
        if (Alert.learnerCode == null || Alert.learnerCode === undefined) {
          isUserCodeEMpty = false;
        } else if (Alert.learnerCode.trim().length === 0) {
          isUserCodeEMpty = false;
        }
      } else if (
        Alert.codeDetails.status === constants.QUESTION_STATUS.ASSIGNED
      ) {
        let gettingLearnerNewCode =
          Alert.codeDetails.status === constants.QUESTION_STATUS.ASSIGNED
            ? Alert.codeDetails.templatecode.filter(
              (codeDetail) =>
                codeDetail.langid === Alert.selectedLanguage.value
            )
            : [];

        if (gettingLearnerNewCode[0].userCode.trim().length === 0) {
          isUserCodeEMpty = false;
        }
      } else if (Alert.learnerCode.length > 0) {
        if (Alert.learnerCode[0].userCode.trim().length === 0) {
          isUserCodeEMpty = false;
        }
      } else {
        if (Alert.learnerCode.trim().length === 0) {
          isUserCodeEMpty = false;
        }
      }

      if (isUserCodeEMpty) {
        /* for getting output for user testcase */

        if (!testCaseCode[0].hasOwnProperty("isexample")) {
          try {
            Alert.setIsLoaded(true);

            let baseurl =
              Alert.solutionLanguageId.current === constants.LANG_ID.Javascript
                ? process.env.REACT_APP_NODE_API
                : process.env.REACT_APP_CONTAINER_API;
            let url =
              Alert.solutionLanguageId.current === constants.LANG_ID.Javascript
                ? "node/learner/code/js/getoutput"
                : Alert.solutionLanguageId.current === constants.LANG_ID.C
                  ? "node/learner/code/c/getoutput"
                  : Alert.solutionLanguageId.current === constants.LANG_ID.Cpp
                    ? "node/learner/code/cpp/getoutput"
                    : Alert.solutionLanguageId.current === constants.LANG_ID.Python
                      ? "node/learner/code/python/getoutput"
                      : "node/learner/code/java/getoutput";
            const res = await axios({
              url: url,
              method: "POST",
              baseURL: baseurl,
              data: JSON.stringify({
                custominput: testCaseCode,
                questionid: Alert.codeDetails.qnid,
                languageid: Alert.solutionLanguageId.current,
                learnerid: getDataFromStorage("learnerid"),
              }),
              timeout: 30000,
              mode: "no-cors",
              headers: {
                "Content-Type": "application/json",
              },
            });

            let gettingId = res.data.testcases;
            let userdefinedTestcaseOutput =
              typeof gettingId == "string" ? JSON.parse(gettingId) : gettingId;

            testCaseCode[0].output = userdefinedTestcaseOutput[0].output;
            isRunAllTestCases = true;
          } catch (error) {
            if (
              (error.message === "timeout of 30000ms exceeded" ||
                error.message.includes("504")) &&
              navigator.onLine
            ) {
              Alert.setShowNotify({
                show: true,
                title: "Error",
                msg: "Something went wrong… program is taking too long to finish. Please, try again",
              });
            } else {
              getCatchBlockDetails(error);
            }
          } finally {
            Alert.setIsLoaded(false);
          }
        } else {
          isRunAllTestCases = true;
        }

        if (isRunAllTestCases) {
          let newCodeDetials = JSON.parse(JSON.stringify(Alert.codeDetails));
          let learnerNewCode =
            newCodeDetials.status === constants.QUESTION_STATUS.ASSIGNED ||
              (!Alert.isAdmin &&
                newCodeDetials.status === constants.QUESTION_STATUS.RESUBMIT)
              ? newCodeDetials.templatecode.filter(
                (codeDetail) =>
                  codeDetail.langid === Alert.selectedLanguage.value
              )
              : [];
          let specs = {};

          specs.method = "post";
          specs.api = "testcode";
          specs.language = Alert.selectedLanguage.label.toLowerCase();
          specs.body = {
            type: "testcode",
            testCases: testCaseCode,
            questionid: Alert.codeDetails.qnid,
            languageid: Alert.selectedLanguage.value,
            language: Alert.selectedLanguage.label.toLowerCase(),
            learnerid: getDataFromStorage("learnerid"),
            solutionCode:
              (newCodeDetials.status === constants.QUESTION_STATUS.SUBMITTED ||
                newCodeDetials.status === constants.QUESTION_STATUS.RESUBMIT ||
                newCodeDetials.status === constants.QUESTION_STATUS.CONTINUE ||
                newCodeDetials.status ===
                constants.QUESTION_STATUS.IN_PROGRESS ||
                newCodeDetials.status ===
                constants.QUESTION_STATUS.TO_BE_EVALUATED) &&
                newCodeDetials.isChangeCode
                ? Alert.learnerCode
                : newCodeDetials.status ===
                  constants.QUESTION_STATUS.SUBMITTED ||
                  newCodeDetials.status ===
                  constants.QUESTION_STATUS.TO_BE_EVALUATED ||
                  newCodeDetials.status ===
                  constants.QUESTION_STATUS.CONTINUE ||
                  newCodeDetials.status ===
                  constants.QUESTION_STATUS.IN_PROGRESS ||
                  newCodeDetials.status ===
                  constants.QUESTION_STATUS.TO_BE_EVALUATED
                  ? Alert.learnerCode /* newCodeDetials.answer */
                  : newCodeDetials.status ===
                    constants.QUESTION_STATUS.ASSIGNED ||
                    (!Alert.isAdmin &&
                      newCodeDetials.status ===
                      constants.QUESTION_STATUS.RESUBMIT)
                    ? learnerNewCode[0].userCode
                    : !Alert.isAdmin && Alert.learnerCode.length > 0
                      ? Alert.learnerCode[0].userCode
                      : Alert.learnerCode,
          };

          try {
            Alert.setIsLoaded(true);

            let response = await apiCall(specs);

            /* For assign actualoutput to the testcase */

            if (
              parseInt(response.resultCode) ===
              constants.RESULT_STATUS.TECHNICAL_ERROR
            ) {
              Alert.setShowNotify({
                show: true,
                title: "Info",
                msg: response.message,
              });
            } else {
              let getresponse;
              let updatedTestCases;

              getresponse =
                typeof response.resultCode === "string"
                  ? JSON.parse(response.result)
                  : response.result;
              updatedTestCases = Alert.testCasesRef.current.map((testCase) => {
                if (testCase.id === getresponse.testcases?.[0]?.id) {
                  if (testCase.hasOwnProperty("isexample")) {
                    return {
                      ...testCase,
                      actualoutput: getresponse.testcases[0]?.actualoutput,
                    };
                  } else {
                    return {
                      ...testCase,
                      actualoutput: getresponse.testcases[0]?.actualoutput,
                      output: getresponse.testcases[0]?.output,
                    };
                  }
                }

                return testCase;
              });

              /* For showing right and wrong icon */

              const runtestcasedata = {
                id: testCaseCode[0].id,
                passed: getresponse.testcases[0].passed,
              };

              const removeDuplicate = Alert.runTestResultCode.some(
                (test) => test.id === testCaseCode[0].id
              );

              if (removeDuplicate) {
                let temp = runtestcasedata;
                let filterData = Alert.runTestResultCode.filter(
                  (data) => data.id !== testCaseCode[0].id
                );

                filterData = [...filterData, temp];
                Alert.setRunTestResultCode(filterData);
              } else {
                const newtestcasedata = { ...runtestcasedata };

                Alert.setRunTestResultCode([
                  ...Alert.runTestResultCode,
                  newtestcasedata,
                ]);
              }

              Alert.testCasesRef.current = updatedTestCases;
            }
          } catch (error) {
            let showNotify = {
              show: true,
              title: "Error",
            };

            if (
              (error.message === "timeout of 30000ms exceeded" ||
                error.message.includes("504")) &&
              navigator.onLine
            ) {
              showNotify.msg =
                "Something went wrong… program is taking too long to finish. Please, try again";

              Alert.setShowNotify(showNotify);
            } else if (
              JSON.parse(error.config.data).hasOwnProperty("solutionCode") &&
              (JSON.parse(error.config.data)?.solutionCode.includes(
                "input()"
              ) ||
                JSON.parse(error.config.data)?.solutionCode.includes("scanf") ||
                JSON.parse(error.config.data)?.solutionCode.includes("cin") ||
                JSON.parse(error.config.data)?.solutionCode.includes("Scanner"))
            ) {
              showNotify.msg =
                "Don't use the command line argument as an input";

              Alert.setShowNotify(showNotify);
            } else if (!navigator.onLine || error.message === "Network Error") {
              handleNetworkError();
            } else {
              getCatchBlockDetails(error);
            }
          } finally {
            Alert.setIsLoaded(false);
          }
        }
      } else {
        Alert.setShowNotify({
          size: "sm",
          show: true,
          title: "Error",
          titleSvg: "error",
          msg: "There is no code to test",
        });
      }
    } else {
      handleNetworkError();
    }
  };
  return { handleRunTestCase };
}
