import _ from "lodash";
import "./CodeSection.scss";
import moment from "moment";
import { nanoid } from "nanoid";
import Reject from "../Reject/Reject";
import Form from "react-bootstrap/Form";
import Approve from "../Approve/Approve";
import SplitPane from "react-split-pane";
import Drawer from "@mui/material/Drawer";
import useCollapse from "react-collapsed";
import Select from "@mui/material/Select";
import Problems from "../Problem/Problems";
import { FormControl } from "@mui/material";
import Pane from "react-split-pane/lib/Pane";
import MenuItem from "@mui/material/MenuItem";
import constants from "../../../../constants";
import ScoreInfoSvg from "./Svg/ScoreInfoSvg";
import ProctoringSvg from "./Svg/ProctoringSvg";
import { Modal, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import CodeEditor from "../CodeEditor/CodeEditor";
import Score from "../../CodeSection/Score/Score";
import OutputWindow from "../Output/OutputWindow";
import Scrollbars from "react-custom-scrollbars-2";
import { getDataFromStorage } from "../../../../util";
import useAddGrade from "../../../../Hooks/useAddGrade";
import CodeSectionHooks from "./Hooks/CodeSectionHooks";
import AlertContext from "../../../../context/AlertContext";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect, useContext } from "react";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import Notify from "../../../Notification-Loading/Notify/Notify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GradiousLogoSvg from "./Svg/GradiousLogo/GradiousLogoSvg";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../Notification-Loading/Loading/Loading";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useUploadLargeFileToS3 from "../../../../Hooks/useUploadLargeFileToS3";
import ConfirmNotify from "../../../Notification-Loading/ConfirmNotify/ConfirmNotify";
import CompanyLogo from "../../../CommonComponents/CompanyLogo";

// import Webcam from "react-webcam";
// import ProctorGridView from "../ProctorGridView/ProctorGridView";

const TrackTimelineDetails = (Props) => {
  const changeTimeandDate = (timestamp) => {
    const dateObj = new Date(timestamp);

    const date = dateObj.toLocaleString();

    return date;
  };

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <div className="collapsible_userDetails">
      <div className="header" {...getToggleProps()}>
        <span className="timeline-header">Activity</span>
        <span className="timeline-header-arrowIcon">
          <FontAwesomeIcon
            className="arrowIcon"
            icon={isExpanded ? faChevronDown : faChevronRight}
          />
        </span>
      </div>
      <div className="details-collapse" {...getCollapseProps()}>
        <div className="content">
          {Props.eventLogData != null &&
            Props.eventLogData.map((data) => (
              <div className="activity-log">
                <span className="activity-log-timestamp">
                  {changeTimeandDate(data.createdTimeStamp)}
                </span>
                <span className="activity-log-eventtext">{data.eventtext}</span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

function CodeSection(Props) {
  const {
    msg,
    svg,
    show,
    title,
    setMsg,
    setSvg,
    setShow,
    isAdmin,
    btnText,
    libType,
    cellobj,
    filccld,
    btnText2,
    navigate,
    setTitle,
    isUnAuth,
    isLoaded,
    learnerid,
    setStatus,
    setBtnText,
    isTakeTest,
    testData,
    lpConfigId,
    fromReport,
    resultCode,
    setBtnText2,
    setIsLoaded,
    setIsUnAuth,
    handleClose,
    setResultCode,
    selectedQuestion,
    handleUnAuthAlert,
    showConfirmNotify,
    setShowConfirmNotify,
    assignQuestionToLearner,
    assignQnDetailsForLearner,
    batchLearnerListForEvaluation,
    fromCodeEditor,
    setFromCodeEditor,
    seconds,
    setSeconds,
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();

  const {
    score,
    count,
    PHtext,
    onBlur,
    profile,
    onFocus,
    classes,
    details,
    setCount,
    language,
    timeLimit,
    setProfile,
    scorevalid,
    showreject,
    fullScreen,
    showProfile,
    languageRef,
    changeScore,
    codeDetails,
    setLanguage,
    commetsData,
    showapprove,
    learnerCode,
    isinputvars,
    testCasesRef,
    selectedlang,
    setTimeLimit,
    showingDelay,
    deleteImages,
    minExtenDate,
    inputvarsmap,
    scoreChanged,
    setshowreject,
    setFullScreen,
    ProfileReturn,
    extensiondate,
    showSubmitBtn,
    handleTimeOut,
    handleSaveTest,
    setshowapprove,
    setCommetsData,
    showProctoring,
    callAllTestRun,
    updateTestCase,
    handleQuitTest,
    setLearnerCode,
    solutionLangid,
    setCodeDetails,
    adminSubmitTest,
    makeRunTestcase,
    isShowTimeAlert,
    setDeleteImages,
    setInputVarsMap,
    setShowingDelay,
    checkFullScreen,
    setMinExtenDate,
    setextensiondate,
    setShowSubmitBtn,
    preventCopyAndCut,
    currentLearnerque,
    handleScoreSelect,
    saveCurrentAnswer,
    handleProfileShow,
    setIsOutputWindow,
    minExtenDateValue,
    runTestResultCode,
    handleRunTestCase,
    handleProfileClose,
    handleDragFinished,
    outputWindowHeight,
    setMakeRunTestCase,
    haslanguagechanged,
    problemWindowHeight,
    setCurrentLearnerque,
    handleShowProctoring,
    isExpandOutputWindow,
    setRunTestResultCode,
    handleNavigateToHome,
    setMinExtenDateValue,
    isAdminSubmitTimeout,
    setOutputWindowHeight,
    setProblemWindowHeight,
    toggleOutputWindowHeight,
    handleProblemDragFinished,
    handleProctoringClosePopup,
    handleAssignNextQnToLearner,
    handleSubmitLearnerCodeEvaluation,
  } = CodeSectionHooks();

  const { slideScore, toggleDrawerScore } = useAddGrade();
  const { deleteS3Objects } = useUploadLargeFileToS3();

  const currentLearnerStatus = Props.learnerList?.filter(
    (learner) => learner.apiattributes.learnerid === Props.learnerid && learner
  );

  const currentLearnerList = Props.learnerList?.filter(
    (learner) => learner.status === currentLearnerStatus[0].status && learner
  );

  const [ccomm, setccomm] = useState("");
  const [currentLearner, setCurrentLearner] = useState({});

  window.onload = () =>
    isAdmin ? navigate(`/batches/${localStorage.getItem("abatchid")}/report#`) : navigate("/home");

  !isAdmin &&
    (window.onpopstate = () => {
      window.history.pushState({}, "", navigate("/home"));
    });

  const addGrade = (score) => {
    if (score >= 0) {
      setShow(false);
      axios
        .post(
          "node/admin/test/score",
          JSON.stringify({
            newscore: score,
            commentdata: ccomm,
            libtypeid: libType,
            learnerid: learnerid,
            lpconfigid: lpConfigId,
            testid: assignQnDetailsForLearner.testid,
            questionid: assignQnDetailsForLearner.qnid,
          }),
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
            setshowreject(false);
            setshowapprove(false);
            assignQnDetailsForLearner.actualscore = score;

            if (ccomm !== "") {
              addComment("score");
            } else {
              setShow(true);
              setTitle("Info ");
              setMsg("Score added successfully");
            }
          }
        })
        .catch(function (error) {
          if (error.message.includes("403")) {
            setShow(true);
            setMsg("You have been logged-out due to inactivity. Login again.");
            setIsUnAuth(true);
          } else {
            setShow(true);
            setMsg(error.message);
          }
        });
    } else {
      setShow(true);
      setTitle("Info ");
      setMsg("Invalid marks");
    }
  };

  useEffect((e) => {
    const handleKeyPress = (e) => {
      if (
        (e.ctrlKey && e.key === "r") ||
        (e.ctrlKey && e.shiftKey && e.key === "R") ||
        e.key === "F5"
      ) {
        e.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleResubmission = (rejectwithnull, reset, newtime) => {
    let apiurl, apidata, successmsg;

    if (rejectwithnull === true) {
      apiurl = "node/admin/test/score";
      apidata = {
        libtypeid: libType,
        learnerid: learnerid,
        reject: rejectwithnull,
        testid: currentLearner.testid,
        lpconfigid: currentLearner.lpconfigid,
        questionid: assignQnDetailsForLearner.qnid,
      };
      successmsg = "Test rejected and score updated to 0";
    } else {
      apiurl = "node/admin/test/resubmit";
      apidata = {
        reset: reset,
        timeleft: newtime,
        libtypeid: libType,
        learnerid: learnerid,
        testid: currentLearner.testid,
        exdate: extensiondate.toString(),
        lpconfigid: currentLearner.lpconfigid,
        questionid: assignQnDetailsForLearner.qnid,
      };
      successmsg = "Resubmission request successfull!";
    }

    setIsLoaded(true);

    axios
      .post(apiurl, JSON.stringify(apidata), {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          setIsLoaded(false);
          setshowapprove(false);
          setshowreject(false);
          setShow(true);
          setTitle("Info ");
          setMsg(successmsg);
          setMinExtenDate(extensiondate);
        } else {
          setIsLoaded(false);
          setShow(true);
          setTitle("Info ");
          setMsg("Something went wrong. Please, try again");
          setshowapprove(false);
          setshowreject(false);
        }
      })
      .catch(function (error) {
        setIsLoaded(false);
        if (error.message.includes("403")) {
          setShow(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
          setIsUnAuth(true);
        } else {
          setShow(true);
          setMsg(error.message);
        }
      });
  };

  const changeComment = (e) => {
    setccomm(e.target.value);
  };

  const addComment = (from) => {
    let form = new FormData();

    form.append("comments", ccomm);
    form.append("lpid", Props.lpId);
    form.append("libtype", Props.libType);
    form.append("learnerid", Props.learnerid);
    form.append("creatorid", getDataFromStorage("learnerid"));
    form.append("testid", currentLearner.apiattributes.testid);
    form.append("questionid", currentLearner.apiattributes.questionid);
    axios({
      data: form,
      method: "post",
      url: "node/admin/test/comment",
      headers: {
        "Content-type": "multipart/form-data",
      },
    })
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          commetsData.push({
            comments: ccomm,
            date: String(new Date()),
            id: res.data.data.insertId,
            createdby: getDataFromStorage("name"),
            creatorid: getDataFromStorage("learnerid"),
          });

          if (from === "score") {
            setccomm("");
            Props.setIsComment(true);
            setShow(true);
            setTitle("Info");
            Props.setComment(ccomm);
            setMsg("Score added successfully");
          } else if (from === "Resubmit") {
            setccomm("");
            Props.setIsComment(true);
            setShow(true);
            setTitle("Info ");
            Props.setComment(ccomm);
            setMsg("Resubmission request successfull!");
          } else {
            setccomm("");
            Props.setIsComment(true);
            setShow(true);
            setTitle("Info");
            Props.setComment(ccomm);
            setMsg("Comment added successfully");
          }
        }
      })
      .catch(function (error) {
        if (error.message.includes("403")) {
          setShow(true);
          setIsUnAuth(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
        } else {
          setShow(true);
          setMsg(error.message);
        }
      });
  };

  const handleQueChange = (e) => {
    let temp = currentLearnerque[e.target.value];

    setCurrentLearner(temp);
    changeLearner(temp);
  };

  const changeLearner = (currentLearner) => {
    if (fromReport) {
      setIsLoaded(true);
      axios
        .post(
          "node/learner/report/question",
          JSON.stringify({
            lpid: currentLearner.apiattributes.lpid,
            testid: currentLearner.apiattributes.testid,
            testqnno: currentLearner.apiattributes.testqnno,
            learnerid: currentLearner.apiattributes.learnerid,
            questionid: currentLearner.apiattributes.questionid,
          }),
          {
            mode: "no-cors",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
            setIsLoaded(false);
            setShow(true);
            setTitle("Info");
            setMsg(res.data.msg);
          } else {
            let response = res.data.data;

            /* while question changing testcases change  */

            let testCases =
              response.testcases !== null ? JSON.parse(response.testcases) : [];

            for (let testCase = 0; testCase < testCases.length; testCase++) {
              if (testCase === 0) testCases[testCase].isActive = true;
              testCases[testCase].isSystemTestCase = true;
              testCases[testCase].id = nanoid();
            }

            testCasesRef.current = testCases;
            response.status = currentLearner.status;
            assignQuestionToLearner(response);
            setIsLoaded(false);
            selectedQuestion(
              res.data,
              { secname: currentLearner.modName },
              {
                topicid: currentLearner.topicid,
                topicname: currentLearner.topicname,
                totalcount: currentLearner.questionscount,
              },
              currentLearner.apiattributes.learnerid,
              currentLearner.status,
              currentLearner.lname,
              currentLearner.apiattributes.testqnno,
              currentLearner.apiattributes.lpid,
              currentLearner.apiattributes.testqnno,
              batchLearnerListForEvaluation,
              true,
              currentLearner.filterattributes.libtype,
              currentLearner.lpconfigid,
              filccld.current,
              currentLearner.accessor,
              currentLearner
            );
            setStatus(currentLearner.status);
          }
        })
        .catch((error) => {
          setIsLoaded(false);
          setShow(true);
          setTitle("Error");

          if (error.message.includes("403")) {
            setIsUnAuth(true);
            setMsg("You have been logged-out due to inactivity. Login again.");
          } else {
            setMsg(error.message);
          }
        });
    }
  };

  const extensiondatechange = (exdate) => {
    setextensiondate(moment(exdate).format("YYYY-MM-DD HH:mm:ss"));
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const savedSize = localStorage.getItem("splitPos");
    const savedWidthSize = localStorage.getItem("splitWidthPos");

    if (savedSize) {
      const [, heightPercentage] = savedSize.split(","); // Extract the height percentage value
      const parsedHeight = parseFloat(heightPercentage.replace("%", "")); // Convert the height value to a float

      setOutputWindowHeight(parsedHeight);
    }

    if (savedWidthSize) {
      const [widthPercentage] = savedWidthSize.split(",");
      const parsedWidth = parseFloat(widthPercentage.replace("%", ""));

      setProblemWindowHeight(parsedWidth);
    }
  }, []);

  /* Proctoring delayed picture and details */

  // window.onclick = function (event) {
  //   if (event.target.id != "image_in_modal_div") {
  //     $("#modal_div").hide();
  //     setCameraPopup(false);
  //   }
  // };

  // const closeWarningCam = () => {
  //   setCameraPopup(false);
  // };

  /* To update states */

  useEffect(() => {
    const handleAssignDetailsToState = () => {
      setCodeDetails(details !== null ? details : []);
      setLanguage(
        details.status === constants.QUESTION_STATUS.ASSIGNED ||
          (!isAdmin && details.status === constants.QUESTION_STATUS.RESUBMIT)
          ? details?.hasOwnProperty("templatecode") &&
            details.templatecode !== null &&
            details.templatecode !== undefined &&
            details.templatecode.length > 0
            ? {
                id: details.templatecode[0].langid,
                name: details.templatecode[0].langname,
              }
            : { id: "", name: "" }
          : { id: details.langid, name: details.langname }
      );
      setTimeLimit(details?.timelimit);
      setInputVarsMap(
        details.hasOwnProperty("inputvars") &&
          details.inputvars !== null &&
          details.inputvars !== undefined
          ? details.inputvars.split(",")
          : details.testcases !== "[]" &&
              details.testcases !== null &&
              JSON.parse(details.testcases)[0].input.split("\n")
      );
      setLearnerCode(
        details.status === constants.QUESTION_STATUS.ASSIGNED ||
          (!isAdmin && details.status === constants.QUESTION_STATUS.RESUBMIT)
          ? details?.hasOwnProperty("templatecode")
            ? details.templatecode[0].userCode
            : ""
          : details.answer !== null
          ? details.answer
          : ""
      );
      setMakeRunTestCase(true);

      /* update testcase */

      if (!isAdmin) {
        let testCases =
          details?.hasOwnProperty("testcases") &&
          details.testcases !== null &&
          details.testcases !== undefined
            ? JSON.parse(details.testcases)
            : [];

        testCases = testCases.filter((example) => example.isexample === 1);

        for (let testCase = 0; testCase < testCases.length; testCase++) {
          if (testCase === 0) testCases[testCase].isActive = true;
          testCases[testCase].isSystemTestCase = true;
          testCases[testCase].id = nanoid();
        }

        testCasesRef.current = testCases;
      }

      if (isAdmin) {
        let temp_filteredld = JSON.parse(JSON.stringify(Props.filteredld));

        let temp = _.filter(temp_filteredld, function (o) {
          return o[Props.accessor]["learnerid"] === Props.learnerid;
        });

        _.forOwn(currentLearnerque, function (value, key) {});

        callAllTestRun.current = true;

        let curleaind = _.findIndex(currentLearnerList, (learner) => {
          return learner.apiattributes.learnerid === Props.learnerid;
        });

        if (details?.hasOwnProperty("commentsdata").length !== 0) {
          setCommetsData(details.commentsdata);
        }

        setCurrentLearner(currentLearnerList[curleaind]);
        setCurrentLearnerque(temp[0]);
      }
    };

    handleAssignDetailsToState();

    return () => handleAssignDetailsToState();
  }, [assignQnDetailsForLearner]);

  useEffect(() => {
    if (isTakeTest) {
      setShowingDelay(true);
    }
  }, [isTakeTest]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if(!isAdmin)preventCopyAndCut(event);
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  /* handle tab switch  */

  useEffect(() => {
    if (
      !isAdmin &&
      codeDetails.status !== constants.QUESTION_STATUS.SUBMITTED &&
      codeDetails.status !== constants.QUESTION_STATUS.TO_BE_EVALUATED &&
      codeDetails.secData.testMode === constants.MODE.TEST_MODE &&
      resultCode !== constants.RESULT_STATUS.NETWORK_ISSUE
    ) {
      window.addEventListener("blur", onBlur);
      window.addEventListener("focus", onFocus);

      return () => {
        window.removeEventListener("blur", onBlur);
        window.removeEventListener("focus", onFocus);
      };
    }
  }, [codeDetails.status, onBlur, resultCode]);

  // // /* Handle check Full-Screen  */

  useEffect(() => {
    if (
      !isAdmin &&
      codeDetails.status !== constants.QUESTION_STATUS.SUBMITTED &&
      codeDetails.status !== constants.QUESTION_STATUS.TO_BE_EVALUATED &&
      codeDetails.secData.testMode === constants.MODE.TEST_MODE &&
      resultCode !== constants.RESULT_STATUS.NETWORK_ISSUE
    ) {
      if (!document.fullscreenElement) {
        checkFullScreen();
      }
    }
  }, [codeDetails.status, isAdmin, document.fullscreenElement, resultCode]);

  /* handle time limit */

  useEffect(() => {
    if (
      !isAdmin &&
      !isLoaded &&
      codeDetails.status !== constants.QUESTION_STATUS.SUBMITTED &&
      codeDetails.status !== constants.QUESTION_STATUS.TO_BE_EVALUATED &&
      codeDetails.secData.testMode === constants.MODE.TEST_MODE &&
      !show &&
      !showConfirmNotify &&
      resultCode !== constants.RESULT_STATUS.NETWORK_ISSUE
    ) {
      const handleTimeLimit = setInterval(() => {
        if (timeLimit === 0) {
          handleSubmitLearnerCodeEvaluation(true, false, true, false);
          clearInterval(handleTimeLimit);
        } else {
          setTimeLimit((prevSeconds) => prevSeconds - 1);

          if (timeLimit !== 0 && timeLimit % 30 === 0) {
            saveCurrentAnswer();
          }
        }
      }, 1000);

      return () => {
        clearInterval(handleTimeLimit);
      };
    }

    if (
      !isAdmin &&
      !isLoaded &&
      codeDetails.status !== constants.QUESTION_STATUS.SUBMITTED &&
      codeDetails.status !== constants.QUESTION_STATUS.TO_BE_EVALUATED &&
      codeDetails.secData.testMode !== constants.MODE.TEST_MODE &&
      learnerCode !== "" &&
      !show &&
      !showConfirmNotify &&
      resultCode !== constants.RESULT_STATUS.NETWORK_ISSUE
    ) {
      if (count === 0) {
        const handleTimeLimit = setInterval(() => {
          setCount(1);
          saveCurrentAnswer();
        }, 1000);

        return () => {
          clearInterval(handleTimeLimit);
        };
      } else {
        const handleTimeLimit = setInterval(() => {
          saveCurrentAnswer();
        }, 30000);

        return () => {
          clearInterval(handleTimeLimit);
        };
      }
    }

    if (fromReport && minExtenDateValue) {
      let currentDuedate, currentDate;

      if (cellobj.hasOwnProperty("learnerduedate")) {
        currentDuedate = new Date(cellobj.learnerduedate);
      } else {
        currentDuedate = new Date(cellobj.duedate);
      }

      currentDate = new Date();

      if (currentDuedate < currentDate) {
        setextensiondate(moment(currentDate).format("YYYY-MM-DD HH:mm:ss"));
        setMinExtenDate(moment(currentDate).format("YYYY-MM-DD HH:mm:ss"));
      } else {
        setextensiondate(
          moment(
            cellobj.hasOwnProperty("learnerduedate")
              ? cellobj.learnerduedate
              : cellobj.duedate
          ).format("YYYY-MM-DD HH:mm:ss")
        );

        setMinExtenDate(
          moment(
            cellobj.hasOwnProperty("learnerduedate")
              ? cellobj.learnerduedate
              : cellobj.duedate
          ).format("YYYY-MM-DD HH:mm:ss")
        );
      }
    }
  }, [
    show,
    count,
    isAdmin,
    isLoaded,
    timeLimit,
    resultCode,
    learnerCode,
    showConfirmNotify,
    codeDetails.status,
  ]);

  useEffect(() => {
    if (isAdmin) {
      let temp_filteredld = JSON.parse(JSON.stringify(Props.filteredld));

      let temp = _.filter(temp_filteredld, function (o) {
        return o[Props.accessor]["learnerid"] === Props.learnerid;
      });

      _.forOwn(currentLearnerque, function (value, key) {});

      let curleaind = _.findIndex(currentLearnerList, (learner) => {
        return learner.apiattributes.learnerid === Props.learnerid;
      });

      setCurrentLearner(currentLearnerList[curleaind]);
      setCurrentLearnerque(temp[0]);
    }
  }, [Props.accessor]);

  /* To Select proctoring images for delete */

  const handleSelectImage = (event, imageName) => {
    let details = JSON.parse(JSON.stringify(profile));
    let imageNames = JSON.parse(JSON.stringify(deleteImages));

    if (event.target.checked) {
      if (imageName === "all") {
        imageNames.withKey = details.proctoringdatas.map((detail) => {
          return {
            Key: `Proctoring/${assignQnDetailsForLearner.testid}/${assignQnDetailsForLearner.qnid}/${learnerid}/${detail.imagename}`,
          };
        });
        imageNames.withOutKey = details.proctoringdatas.map((detail) => {
          return detail.imagename;
        });
        details.proctoringdatas = details.proctoringdatas.map((detail) => {
          detail.isChecked = true;
          return detail;
        });
        setProfile(details);
        setDeleteImages(imageNames);
      } else {
        details.proctoringdatas = details.proctoringdatas.map((detail) => {
          if (detail.imagename === imageName) detail.isChecked = true;

          return detail;
        });
        imageNames.withKey.push({
          Key: `Proctoring/${assignQnDetailsForLearner.testid}/${assignQnDetailsForLearner.qnid}/${learnerid}/${imageName}`,
        });
        imageNames.withOutKey.push(imageName);
        setDeleteImages(imageNames);
        setProfile(details);
      }
    } else {
      if (imageName === "all") {
        imageNames.withKey = [];
        imageNames.withOutKey = [];
        details.proctoringdatas = details.proctoringdatas.map((detail) => {
          detail.isChecked = false;

          return detail;
        });
      } else {
        imageNames.withKey = imageNames.withKey.filter(
          (imgName) => !imgName.Key.includes(imageName)
        );
        imageNames.withOutKey = imageNames.withOutKey.filter(
          (imgName) => imgName !== imageName
        );
        details.proctoringdatas = details.proctoringdatas.map((detail) => {
          if (detail.imagename === imageName) detail.isChecked = false;

          return detail;
        });
      }

      setProfile(details);
      setDeleteImages(imageNames);
    }
  };

  /* delete proctoring image */

  const handleDeleteImg = async () => {
    try {
      setIsLoaded(true);
      let res = await axios.post(
        "node/admin/userproctoring/image",
        { imageNames: deleteImages.withOutKey },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
        let result = await deleteS3Objects(deleteImages);

        if (result === "Success") {
          setShow(true);
          setTitle("Success");
          setMsg(res.data.msg);
          let details = JSON.parse(JSON.stringify(profile));

          details.proctoringdatas = details.proctoringdatas?.filter(
            (detail) => !deleteImages.withOutKey.includes(detail.imagename)
          );
          setProfile(details);
          setDeleteImages({ withKey: [], withOutKey: [] });
        }
      } else if (
        res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR
      ) {
        setShow(true);
        setTitle("Error !");
        setMsg(res.data.msg);
      } else {
        setShow(true);
        setTitle("Error");
        setMsg("Something went wrong. Please try again later.");
      }
    } catch (err) {
      setShow(true);
      setTitle("Error");
      if (err.message.includes("403")) {
        setIsUnAuth(true);
        setMsg("You have been logged-out due to inactivity. Login again.");
      } else setMsg("Something went wrong. Please try again later.");
    } finally {
      setIsLoaded(false);
    }
    // let result = await deleteS3Objects(deleteImages);

    // if (result === "Success") {
    //   let details = JSON.parse(JSON.stringify(profile));

    //   details.proctoringdatas = details.proctoringdatas?.filter(
    //     (detail) => !deleteImages.withOutKey.includes(detail.imagename)
    //   );
    //   setProfile(details);
    //   setDeleteImages({ withKey: [], withOutKey: [] });
    // }
  };

  console.log(testData);

  return (
    <body
      onContextMenu={handleContextMenu}
      className={isAdmin ? "codeSectionContainerAdmin" : "codeSectionContainer"}
    >
      {/* side popup camera during exam*/}

      {/* <div id="proctoring-cam-show" className="proctoring-cam-show-div">
        {cameraPopup && (
          <div id="modal_div">
            <div className="modal_div_header">
              <h6>Your Cam</h6>
              <div className="cam_close_icon">
                <FontAwesomeIcon onClick={closeWarningCam} icon={faXmark} />
              </div>
            </div>
            <div className="webcam-warning-info">{cameraWarningMsg}</div>
            <Webcam
              width={500}
              height={281}
              audio={false}
              id="image_in_modal_div"
              screenshotFormat="image/jpeg"
              style={{ borderRadius: "7px" }}
              videoConstraints={videoConstraints}
            />
          </div>
        )}
      </div> */}
      {/* <div> */}
      {!fullScreen && isAdmin && (
        <div
          className={
            !showapprove && !showreject
              ? "codesection-header-section"
              : "codesection-header-section2"
          }
        >
          <div className="codesection-header-section-logo-and-question-selection">
            <GradiousLogoSvg />
            <div className="codesection-utils-container-select-learner-question">
              {isAdmin && Props.isfromreport && (
                <>
                  {Props.filteredld[0][Props.accessor].modName +
                    " / " +
                    Props.filteredld[0][Props.accessor].testname +
                    " /"}
                  <FormControl
                    variant="standard"
                    sx={{ m: 0, minWidth: 60, color: "#AEB0B2" }}
                  >
                    <Select
                      value={Props.accessor}
                      label="Select learner"
                      className={classes.select}
                      onChange={handleQueChange}
                      labelId="learner-select-label"
                      id="report-learner-select-codindPage"
                      IconComponent={KeyboardArrowDownIcon}
                      sx={{
                        m: 0,
                        maxWidth: 160,
                        maxHeight: 30,
                        color: "#AEB0B2",
                      }}
                    >
                      {Object.keys(currentLearnerque).map((pro, index) => (
                        <MenuItem
                          name={currentLearnerque[pro].accessor}
                          value={currentLearnerque[pro].accessor}
                          disabled={
                            currentLearnerque[pro].apiattributes.questionid ===
                            null
                              ? true
                              : false
                          }
                        >
                          &nbsp;
                          {currentLearnerque[pro].topicname +
                            " - " +
                            currentLearnerque[pro].repqueno}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </div>
          </div>
          <div className="codesection-header-section-scoring-section">
            {isAdmin && assignQnDetailsForLearner.actualscore !== null && (
              <div className="codesection-header-section-scoring-section-scoreandinfo">
                <div className="codesection-header-section-scoring-section-admin-score">
                  Score &nbsp;&nbsp;{assignQnDetailsForLearner.actualscore}{" "}
                  /&nbsp;{assignQnDetailsForLearner.marks}
                </div>
                <div
                  onClick={toggleDrawerScore("right", true)}
                  className="codesection-header-section-scoring-section-admin-score-info"
                >
                  <ScoreInfoSvg />
                </div>

                <Drawer
                  anchor={"right"}
                  open={slideScore}
                  onClose={toggleDrawerScore("right", false)}
                >
                  <Score
                    lpId={Props.lpId}
                    isAdmin={isAdmin}
                    slideScore={slideScore}
                    learnerid={Props.learnerid}
                    toggleDrawerScore={toggleDrawerScore}
                    scoreData={
                      assignQnDetailsForLearner?.hasOwnProperty("actualscore")
                        ? {
                            qnid: assignQnDetailsForLearner.qnid,
                            marks: assignQnDetailsForLearner.marks,
                            testid: assignQnDetailsForLearner.testid,
                            actualscore: assignQnDetailsForLearner.actualscore,
                          }
                        : []
                    }
                  />
                </Drawer>

                {!isAdmin && (
                  <span className="codesection-header-section-scoring-section-learner-score">
                    <h6>
                      Score :&nbsp;&nbsp;
                      {assignQnDetailsForLearner.hasOwnProperty(
                        "actualscore"
                      ) &&
                        assignQnDetailsForLearner.actualscore != null &&
                        assignQnDetailsForLearner.actualscore +
                          "/" +
                          assignQnDetailsForLearner.marks}
                    </h6>
                  </span>
                )}
              </div>
            )}

            <div
              onClick={handleShowProctoring}
              className="codesection-header-section-scoring-section-admin-proctoring-button"
            >
              {isAdmin && <ProctoringSvg />}
            </div>

            {/* Proctoring details */}

            {isAdmin ? (
              <div style={{ marginTop: "55px" }}>
                <Offcanvas
                  placement="top"
                  show={showProctoring}
                  id="proctoringDetailContainer"
                  onHide={() => {
                    handleProctoringClosePopup();
                    setDeleteImages({ withKey: [], withOutKey: [] });
                  }}
                >
                  {isLoaded && <Loading />}

                  <div className="proctoringTitleSection">
                    <FontAwesomeIcon
                      icon={faXmark}
                      className="proctoringCloseIcon"
                      onClick={() => {
                        handleProctoringClosePopup();
                        setDeleteImages({ withKey: [], withOutKey: [] });
                      }}
                    />
                  </div>

                  <Form
                    noValidate
                    name="userProctoringForm"
                    className="proctoringForm"
                  >
                    <div>
                      {
                        <span className="userImage" onClick={handleProfileShow}>
                          <img
                            width={"150"}
                            height={"150"}
                            alt="userImage"
                            src={profile.registerUrl}
                          />
                        </span>
                      }
                    </div>
                    <Modal
                      show={showProfile}
                      onHide={handleProfileClose}
                      className="proctoringProfile"
                    >
                      <img
                        width={"100%"}
                        height={"300"}
                        alt="userImage"
                        src={profile.registerUrl}
                      />
                    </Modal>
                    <span className="userName">Student Name</span>:
                    <span className="userName-durn">{Props.learnername}</span>
                    <div className="proctoringDetails">
                      <div>
                        <span className="userName">No of tab switches</span>:
                        <span className="userName-durn">
                          {profile.switched ? profile.switched : 0}
                        </span>
                      </div>
                      <div>
                        <span className="userName">Creditibility score</span>:
                        <span className="userName-durn">
                          {profile.credibilityScore
                            ? profile.credibilityScore
                            : 0}
                        </span>
                      </div>

                      <div>
                        <span className="userName">Duration</span>:
                        <span className="userName-durn">
                          {profile.duration !== ""
                            ? profile.duration
                            : "No Data"}
                        </span>
                      </div>
                    </div>
                    <div>
                      <TrackTimelineDetails eventLogData={profile.eventdata} />
                    </div>
                    {/* <p className="unmatched">Unmatched Images</p>
                    <div className="ProctoringImagesContainer">
                      {profile.proctoringdatas?.map((data) => (
                        <div className="proctoringImgDiv">
                          {data.facedetected === 0 ? (
                            <p className="proctoringTimeStamp">
                              {new Date(data.timestamp).toLocaleString()}
                            </p>
                          ) : null}
                          {data.facedetected === 0 ? (
                            <img
                              className={
                                data.faceorientationnormal === 0
                                  ? "proctoringFacedetected"
                                  : "proctoringNotdetected"
                              }
                              alt="proctoringNotdetected"
                              src={
                                process.env.REACT_APP_PYTHON_API +
                                "Images/Identified_faces/" +
                                data.imagename
                              }
                              width={"50px"}
                              height={"100"}
                            />
                          ) : null}
                        </div>
                      ))}
                    </div>

                    <p className="matched">Matched Images</p>
                    <div className="ProctoringImagesContainer">
                      {profile.proctoringdatas?.map((data) => (
                        <div className="proctoringImgDiv">
                          {data.facedetected === 1 ? (
                            <p className="proctoringTimeStamp">
                              {new Date(data.timestamp).toLocaleString()}
                            </p>
                          ) : null}
                          {data.facedetected === 1 ? (
                            <img
                              className={
                                data.faceorientationnormal === 0
                                  ? "proctoringFacedetected"
                                  : "proctoringNotdetected"
                              }
                              alt="proctoringFacedetected"
                              src={
                                process.env.REACT_APP_PYTHON_API +
                                "Images/Identified_faces/" +
                                data.imagename
                              }
                              width={"50px"}
                              height={"100"}
                            />
                          ) : null}
                        </div>
                      ))}
                    </div> */}
                    <div className="proctoringImagesTitleContainer">
                      <div className="proctoringImagesTitleSec">
                        <input
                          type="checkbox"
                          id="proctoringImagesCheckbox"
                          disabled={!profile.proctoringdatas?.length > 0}
                          onChange={(event) => handleSelectImage(event, "all")}
                          checked={
                            profile.proctoringdatas?.length ===
                            deleteImages.withKey.length
                          }
                        />
                        <label className="matched">Images</label>
                      </div>
                      <div>
                        <Button
                          className="btnMedium"
                          onClick={handleDeleteImg}
                          disabled={!deleteImages.withOutKey.length > 0}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                    {profile.proctoringdatas ? (
                      <div className="ProctoringImagesContainer">
                        {/* <ProctorGridView/> */}
                        {profile.proctoringdatas?.map((data, index) => {
                          var utcDate = new Date(data.timestamp);

                          // utcDate.setHours(utcDate.getHours() + 5); // Add 5 hours
                          // utcDate.setMinutes(utcDate.getMinutes() + 30); // Add 30 minutes

                          const istFormatter = utcDate.toLocaleString("en-IN", {
                            hour12: true,
                            day: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            month: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          });

                          return (
                            <div className="proctoringImgDiv" key={index}>
                              <div className="proctoringImgTimestampSec">
                                <input
                                  type="checkbox"
                                  checked={data.isChecked}
                                  id="proctoringImagesCheckbox"
                                  onChange={(event) =>
                                    handleSelectImage(event, data.imagename)
                                  }
                                />
                                <label className="proctoringTimeStamp">
                                  {istFormatter}
                                </label>
                              </div>
                              <img
                                width={"50px"}
                                height={"100"}
                                alt="proctoringFacedetected"
                                src={data.imagePresignedUrl}
                                className={
                                  data.faceorientationnormal === 0
                                    ? "proctoringFacedetected"
                                    : "proctoringNotdetected"
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <p className="proctoringTimeStamp">
                          No proctoring data
                        </p>
                      </div>
                    )}
                    <hr className="proctoringLine" />
                    <Button
                      id="cancelProctoringBtn"
                      onClick={handleProctoringClosePopup}
                    >
                      Cancel
                    </Button>
                  </Form>
                </Offcanvas>
              </div>
            ) : null}
            {Props.cellobj.testmode == constants.MODE.TEST_MODE && (
              <button
                disabled={showreject ? true : false}
                onClick={() => setshowapprove(!showapprove)}
                className={
                  !showapprove
                    ? "comments-score-section-score-approve-button"
                    : "comments-score-section-score-approve-button-hover"
                }
              >
                Approve
              </button>
            )}
            {showapprove && (
              <div className="comments-score-section-score-approve-box">
                <Approve
                  ccomm={ccomm}
                  score={score}
                  PHtext={PHtext}
                  isAdmin={isAdmin}
                  addGrade={addGrade}
                  scorevalid={scorevalid}
                  showreject={showreject}
                  changeScore={changeScore}
                  showapprove={showapprove}
                  scoreChanged={scoreChanged}
                  changeComment={changeComment}
                  setshowreject={setshowreject}
                  setshowapprove={setshowapprove}
                  handleScoreSelect={handleScoreSelect}
                  marks={assignQnDetailsForLearner.marks}
                />
              </div>
            )}
            {Props.cellobj.testmode == constants.MODE.TEST_MODE && (
              <button
                disabled={showapprove ? true : false}
                onClick={() => setshowreject(!showreject)}
                className={
                  !showreject
                    ? "comments-score-section-score-reject-button"
                    : "comments-score-section-score-reject-button-hover"
                }
              >
                Reject
              </button>
            )}
            {showreject && (
              <div className="comments-score-section-score-reject-box">
                <Reject
                  ccomm={ccomm}
                  cellobj={cellobj}
                  libType={libType}
                  showreject={showreject}
                  showapprove={showapprove}
                  setIsLoaded={setIsLoaded}
                  minExtenDate={minExtenDate}
                  setshowreject={setshowreject}
                  changeComment={changeComment}
                  extensiondate={extensiondate}
                  setshowapprove={setshowapprove}
                  testobj={assignQnDetailsForLearner}
                  setextensiondate={setextensiondate}
                  handleResubmission={handleResubmission}
                  extensiondatechange={extensiondatechange}
                  setMinExtenDateValue={setMinExtenDateValue}
                />
              </div>
            )}
          </div>
        </div>
      )}

      <SplitPane
        size={100}
        minSize={50}
        split="vertical"
        primary="second"
        className="problemCondainerDiv"
        onChange={handleProblemDragFinished}
      >
        <Pane
          minSize="30%"
          maxSize="50%"
          className="problemCondainerpane"
          initialSize={`${problemWindowHeight}%`}
        >
          <Scrollbars autoHide>
            {!fullScreen && (
              <>
                <Problems
                  lpId={Props.lpId}
                  isAdmin={isAdmin}
                  navigate={navigate}
                  setLpId={Props.setLpId}
                  codeDetails={codeDetails}
                  isinputvars={isinputvars}
                  inputvarsmap={inputvarsmap}
                  fromCodeEditor={fromCodeEditor}
                  setFromCodeEditor={setFromCodeEditor}
                />
              </>
            )}
          </Scrollbars>
        </Pane>
        <SplitPane split="horizontal" onChange={handleDragFinished}>
          <Pane minSize="20%" maxSize="90%">
            <CodeEditor
              isAdmin={isAdmin}
              lpId={Props.lpId}
              language={language}
              timeLimit={timeLimit}
              fullScreen={fullScreen}
              slideScore={slideScore}
              languageRef={languageRef}
              setLanguage={setLanguage}
              learnerCode={learnerCode}
              codeDetails={codeDetails}
              accessor={Props.accessor}
              commetsData={commetsData}
              learnerid={Props.learnerid}
              selectedlang={selectedlang}
              filteredld={Props.filteredld}
              setFullScreen={setFullScreen}
              setCommetsData={setCommetsData}
              setLearnerCode={setLearnerCode}
              setCodeDetails={setCodeDetails}
              callAllTestRun={callAllTestRun}
              learnerList={Props.learnerList}
              isfromreport={Props.isfromreport}
              adminSubmitTest={adminSubmitTest}
              question={assignQnDetailsForLearner}
              toggleDrawerScore={toggleDrawerScore}
              haslanguagechanged={haslanguagechanged}
              outputWindowHeight={outputWindowHeight}
              toggleOutputWindowHeight={toggleOutputWindowHeight}
            />
          </Pane>
          <Pane
            id="outputParentWindowContainer"
            initialSize={`${outputWindowHeight}%`}
            minSize={
              details.status === constants.QUESTION_STATUS.SUBMITTED ||
              details.status === constants.QUESTION_STATUS.TO_BE_EVALUATED
                ? "9%"
                : (details.status === constants.QUESTION_STATUS.RESUBMIT ||
                    details.status === constants.QUESTION_STATUS.CONTINUE) &&
                  showSubmitBtn
                ? "17%"
                : details.status === constants.QUESTION_STATUS.ASSIGNED &&
                  showSubmitBtn
                ? "17%"
                : "8%"
            }
            maxSize={
              details.status === constants.QUESTION_STATUS.SUBMITTED ||
              details.status === constants.QUESTION_STATUS.TO_BE_EVALUATED
                ? "70%"
                : "80%"
            }
          >
            {!fullScreen && (
              <OutputWindow
                show={show}
                setMsg={setMsg}
                setShow={setShow}
                isAdmin={isAdmin}
                setTitle={setTitle}
                language={language}
                learnerCode={learnerCode}
                isinputvars={isinputvars}
                codeDetails={codeDetails}
                inputvarsmap={inputvarsmap}
                testCasesRef={testCasesRef}
                showSubmitBtn={showSubmitBtn}
                setCodeDetails={setCodeDetails}
                updateTestCase={updateTestCase}
                callAllTestRun={callAllTestRun}
                solutionLangid={solutionLangid}
                makeRunTestcase={makeRunTestcase}
                setShowSubmitBtn={setShowSubmitBtn}
                runTestResultCode={runTestResultCode}
                setIsOutputWindow={setIsOutputWindow}
                handleRunTestCase={handleRunTestCase}
                setMakeRunTestCase={setMakeRunTestCase}
                outputWindowHeight={outputWindowHeight}
                setRunTestResultCode={setRunTestResultCode}
                isExpandOutputWindow={isExpandOutputWindow}
                isAdminSubmitTimeout={isAdminSubmitTimeout}
                toggleOutputWindowHeight={toggleOutputWindowHeight}
                handleSubmitLearnerCodeEvaluation={
                  handleSubmitLearnerCodeEvaluation
                }
              />
            )}
          </Pane>
        </SplitPane>
      </SplitPane>

      {isTakeTest && showingDelay && ProfileReturn()}

      <Notify
        svg={svg}
        show={show}
        title={title}
        message={msg}
        btnText={btnText}
        resultCode={resultCode}
        onHide={
          isUnAuth && isAdmin
            ? handleUnAuthAlert
            : resultCode === constants.RESULT_STATUS.NETWORK_ISSUE && !isAdmin
            ? handleSaveTest
            : isShowTimeAlert
            ? handleNavigateToHome
            : handleClose
        }
      />
      {isLoaded && <Loading />}
      <ConfirmNotify
        svg={svg}
        title={title}
        message={msg}
        btnText={btnText}
        btnText2={btnText2}
        resultCode={resultCode}
        show={showConfirmNotify}
        onHide2={
          resultCode === constants.RESULT_STATUS.INCORRECT_ANSWER
            ? handleQuitTest
            : resultCode === constants.RESULT_STATUS.TIMEOUT
            ? handleTimeOut
            : handleNavigateToHome
        }
        onHide={
          resultCode === constants.RESULT_STATUS.SUCCESS
            ? handleAssignNextQnToLearner
            : () => {
                setMsg("");
                setSvg("");
                setBtnText("");
                setBtnText2("");
                setResultCode("");
                setShowConfirmNotify(false);
              }
        }
      />
    </body>
  );
}

export default CodeSection;
