import constants from "../../constants";
import React, { useContext } from "react";
import TestDueHooks from "../../Hooks/TestDueHooks";
import AlertContext from "../../context/AlertContext";

export default function StatusLabel(Props) {
  const { status, isAdmin, testData, approveData } = useContext(AlertContext);

  const { currentDueLabel } = TestDueHooks();

  return (
    <div
      className={
        Props.component === "rightMain"
          ? "codingStatusLabelForCodingRightMain"
          : Props.component === "codingTable"
          ? "codingStatusLabelForCodingTable"
          : "codingStatusLabel"
      }
    >
      {(currentDueLabel(testData.duedate) === "Past Due" &&
        status !== constants.PROGRESS_STATUS.EVALUATED &&
        status !== constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
        status !== constants.PROGRESS_STATUS.PARTIAL_EVALUATED) ||
      status === constants.PROGRESS_STATUS.UNLOCKED ||
      Props.status === constants.PROGRESS_STATUS.UNLOCKED ? (
        <span className="testPending">
          {!localStorage.getItem("mockLp") &&
          currentDueLabel(testData.duedate) === "Past Due"
            ? currentDueLabel(testData.duedate)
            : "Pending"}
        </span>
      ) : status === constants.PROGRESS_STATUS.REATTEMPT ||
        status === constants.PROGRESS_STATUS.IN_PROGRESS ||
        (Props.component !== "codingTable" &&
          status === constants.PROGRESS_STATUS.QN_CONTINUE) ||
        (Props.component === "codingTable" &&
          Props.status === constants.PROGRESS_STATUS.QN_CONTINUE) ||
        Props.status === constants.PROGRESS_STATUS.IN_PROGRESS ||
        Props.status === constants.PROGRESS_STATUS.QN_CONTINUE ? (
        <span className="testInProgress">
          {Props.status === constants.PROGRESS_STATUS.IN_PROGRESS ||
            (Props.component === "rightMain" &&
              (isAdmin
                ? "Resubmission"
                : status === constants.PROGRESS_STATUS.REATTEMPT ||
                  status === constants.PROGRESS_STATUS.IN_PROGRESS
                ? "In Progress"
                : "Ongoing"))}
        </span>
      ) : Props.status === constants.PROGRESS_STATUS.REATTEMPT ? (
        <span className="testEvaluated">Evaluated</span>
      ) : (
        <span className="testSubmitted">
          {status === constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
          approveData.hasOwnProperty("actualscore") &&
          (approveData.actualscore === null ||
            approveData.actualscore === undefined)
            ? "To be evaluated"
            : "Submitted"}
        </span>
      )}
    </div>
  );
}
