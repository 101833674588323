import "./Attendance.scss";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import { useContext } from "react";
import PlusIcon from "./Svg/PlusIcon";
import Card from "react-bootstrap/Card";
import constants from "../../../../constants";
import { Dropdown } from "react-bootstrap";
import Loading from "../../../Notification-Loading/Loading/Loading";
import ButtonMedium from "../../../Buttons/ButtonMedium/ButtonMedium";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AlertContext from "../../../../context/AlertContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import sessionCreate from "../Attendance/Json/1.json";
import ModuleHooks from "../../../../Hooks/ModuleHooks";
import Popup from "../../../CommonComponents/Popup";
export default function Attendance() {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const { handlePopupStates, clearPopupStates } = ModuleHooks();
  const {id} = useParams();

  const dropStyle = {
    gap: "10px",
    width: "157px",
    height: "40px",
    outline: "none",
    display: "flex",
    flexShrink: "0",
    color: "#373739",
    fontSize: "16px",
    boxShadow: "none",
    fontWeight: "400",
    lineHeight: "150%",
    fontStyle: "normal",
    fontFamily: "Inter",
    padding: "8px 16px",
    borderRadius: "4px",
    flexDirection: "row",
    borderColor: "#FAFAFA",
    letterSpacing: "0.07px",
    justifyContent: "center",
    alignItems: "flex-start",
    border: "0px solid white",
    backgroundColor: "#FAFAFA",
    background: "var(--grey-grey-50, #FAFAFA)",
  };

  const menuStyle = {
    color: "black",
    marginTop: "0px",
    borderRadius: "0px",
    borderColor: "#f2f4f7",
    backgroundColor: "#f2f4f7",
  };

  const {
    setMsg,
    setShow,
    setTitle,
    setIsLoaded,
    setIsUnAuth,
    batchDetails,
    setSessionDetails,
    isEditAttendance,
    setIsEditAttendance,
    isCommonPopup,
    isLoaded
  } = useContext(AlertContext);
  const [isEdit, setIsEdit] = useState(false);
  const [sessionList, setSessionList] = useState([]);
  const [flagOffline, setFlagOffline] = useState(false);
  const [confirmDeletion, setConfirmDeletion] = useState(false);

  console.log("sessionCreate", sessionCreate);
  console.log("sessionList", sessionList);

  const handleRecordOnline = (data) => {
    console.log("handleRecordOnline", data);
    setSessionDetails(data);
    navigate(`/batches/${id}/event/record`);
  };
  const handleRecordOffline = (data) => {
    setSessionDetails(data);

    console.log("data^^^^^",data);

    axios({
      method: "post",
      url: "node/admin/attendance/record/" + data.id,
      data: { expiryTime: data.expiryTime },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.data.resultCode === 1000) {
          setFlagOffline(!flagOffline);
          setShow(true);
          setTitle("Sucess");
          setMsg("Recording Started");
        }
        setSessionDetails({});
      })
      .catch((err) => {
        if (err.message.includes("403")) {
          setIsUnAuth(true);
          setShow(true);
          setTitle("Error")
          setMsg("You have been logged-out due to inactivity. Login again.");
        } 
        setSessionDetails({});
      });
  };

  const handleViewDetailsOnline = (data) => {
    setSessionDetails(data);
    navigate(`/batches/${id}/event/online`);
  };

  const handleViewDetailsOffline = (data) => {
    setSessionDetails(data);
    navigate(`/batches/${id}/event/offline`);
  };

  const handleAdd = () => {
    navigate(`/batches/${id}/event/add`);
  };

  const handleEdit = (data) => {
    setIsEditAttendance(true);
    console.log("dataaaaaaaaaa", data);
    setSessionDetails(data);
    navigate(`/batches/${id}/event/add`);
  };

  const confirmDelete = (data) => {
    // setConfirmDeletion(true);

    let popupdata = {};
    popupdata.showpopup = true;
    popupdata.title = "Confirm Delete Session";
    popupdata.bodydesc =
      "Are you sure you want to delete this session. Once deleted this cannot be pulled down";
    popupdata.singlebtnsize = "medium";
    popupdata.primbtntxt = "Confirm";
    popupdata.secbtntxt = "Cancel";
    popupdata.primbtnfunt = () => {
      handleDelete(data);
    };
    popupdata.secbtnfunt = handleClosePopup;
    handlePopupStates(popupdata);
  };

  const handleClosePopup = () => {
    clearPopupStates();
  };

  const handleClosePopupAfter = () => {
    clearPopupStates();
    setFlagOffline(!flagOffline);
  };

  const handleDelete = async (data) => {
    handleClosePopup();
    setIsLoaded(true);
    try {
      console.log("sessionData----", data);
      let sessionId = data.id;
      let response = await axios.put(
        `node/admin/attendance/session/delete`,
        { sessionId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.resultCode == constants.RESULT_STATUS.SUCCESS) {
        setIsLoaded(false);
        let popupdata = {};
        popupdata.showpopup = true;
        popupdata.title = "Success";
        popupdata.bodydesc = response.data.msg;
        popupdata.singlebtn = true;
        popupdata.singlebtntxt = "Ok";
        popupdata.singlebtnsize = "small";
        popupdata.singlebtnfunt = handleClosePopupAfter;
        handlePopupStates(popupdata);
      }
      console.log("response after soft deleting session", response.data);
    } catch (err) {

      let popupdata = {};
        popupdata.showpopup = true;
        popupdata.title = "Error";
        popupdata.bodydesc = err.msg;
        popupdata.singlebtn = true;
        popupdata.singlebtntxt = "Ok";
        popupdata.singlebtnsize = "small";
        popupdata.singlebtnfunt = handleClosePopup;
        handlePopupStates(popupdata);

    }
  };

  const sortSessionsByStartDateAsc = () => {
    const sortedSessions = sessionList.slice().sort((a, b) => {
      return new Date(a.startdate) - new Date(b.startdate);
    });
    setSessionList(sortedSessions);
  };

  const sortSessionsByStartDateDesc = () => {
    const sortedSessions = sessionList.slice().sort((a, b) => {
      return new Date(b.startdate) - new Date(a.startdate);
    });
    setSessionList(sortedSessions);
  };

  const formatTimestamp = (inputTimestamp) => {
    const timestamp = new Date(inputTimestamp);
    const formattedTime = timestamp.toLocaleString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });
    const formattedDate = timestamp.toLocaleString("en-US", {
      day: "2-digit",
      year: "2-digit",
      month: "2-digit",
    });
    return `${formattedTime}  ${formattedDate}`;
  };

  const getStatus = (data) => {
    const modifiedDateObject = new Date(data.modifieddate);
    const expirationDate = new Date(
      modifiedDateObject.getTime() + data.expiry_time * 60000
    );
    const currentDate = new Date();

    if (
      data.status === constants.SESSION_STATUS.ATTENDANCE_NOT_RECORDED ||
      data.status === null
    )
      return "Attendance Not Recorded";

    if (data.mode === constants.SESSION_MODE.ONLINE_SESSION) {
      if (
        data.status === constants.SESSION_STATUS.ATTENDANCE_NOT_RECORDED ||
        data.status === null
      )
        return "Attendance Not Recorded";
      else if (data.status === constants.SESSION_STATUS.ATTENDANCE_INITIATED)
        return `${data.totalPresent}/${data.totalSessionInvites}`;
    } else if (data.mode === constants.SESSION_MODE.OFFLINE_SESSION) {
      if (expirationDate > currentDate) {
        return "Accepting Responses";
      } else return `${data.totalPresent}/${data.totalSessionInvites}`;
    }
  };

  useEffect(() => {
    setIsLoaded(true);
    axios
      .get("node/admin/attendance/session/" + batchDetails.id, {
        timeout: 60000,
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.resultCode !== 1000) {
          setIsLoaded(false);
          setShow(true);
          setTitle("Info");
          setMsg(res.data.msg);
        } else {
          setIsLoaded(false);
          setSessionList(res.data.data);
        }
      })
      .catch((error) => {
        setIsLoaded(false);
        setShow(true);
        setTitle("Error");

        if (error.message === "timeout of 60000ms exceeded") {
          setMsg(
            "Something went wrong… program is taking too long to finish. Please, try again"
          );
        } else if (error.message.includes("403")) {
          setIsUnAuth(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
        } else {
          setMsg(error.message);
        }
      });
  }, [flagOffline]);

  return (
    <>
      {isCommonPopup && (
        <div className="popup-overlay">
          <Popup />
        </div>
      )}
      <div className="attendanceBtnDiv">
        <div>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" style={dropStyle}>
              Sort
            </Dropdown.Toggle>

            <Dropdown.Menu style={menuStyle}>
              <Dropdown.Item onClick={sortSessionsByStartDateDesc}>
                Newest to Oldest
              </Dropdown.Item>
              <Dropdown.Item onClick={sortSessionsByStartDateAsc}>
                Oldest to Newest
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="addEventBtn" onClick={handleAdd}>
          <PlusIcon />
          Add Event
        </div>
      </div>
      <h4 className="eventsTitle">Events</h4>
      <div style={{ marginTop: "8%" }}></div>
      {sessionList.map((d) => (
        <Card key={d.id} className="sessionList">
          <div className="sessionHeadDiv">
            <h5 className="sessionHead">{d.name}</h5>
            <h5
              className={
                d.status === null ||
                d.status === constants.SESSION_STATUS.ATTENDANCE_NOT_RECORDED
                  ? "o-rec-btn orange"
                  : "o-rec-btn"
              }
            >
              {getStatus(d)}
            </h5>
          </div>
          <div className="sessionBody">
            <Card.Text className="sessionBodyFields1">
              Org By : {d.organizedBy}
            </Card.Text>
            <Card.Text className="sessionBodyFields">
              Mode :{" "}
              {d.mode === constants.SESSION_MODE.ONLINE_SESSION
                ? "Online"
                : "Offline"}
            </Card.Text>
            <Card.Text className="sessionBodyFields">
              Date & Time : {formatTimestamp(d.startdate)}
            </Card.Text>
            <span
              className={
                d.status === null ||
                d.status === constants.SESSION_STATUS.ATTENDANCE_NOT_RECORDED
                  ? "x"
                  : "d-none"
              }
              style={{
                marginTop: "55px",
                marginLeft: "800px",
                position: "absolute",
              }}
              onClick={() => handleEdit(d)}
            >
              <FontAwesomeIcon
                size="xl"
                icon={faPenToSquare}
                style={{ color: "#000000" }}
              />
            </span>

            <span
              className={
                d.status === null ||
                d.status === constants.SESSION_STATUS.ATTENDANCE_NOT_RECORDED
                  ? "x"
                  : "d-none"
              }
              style={{
                marginTop: "55px",
                marginLeft: "760px",
                position: "absolute",
              }}
              onClick={() => confirmDelete(d)}
            >
              <FontAwesomeIcon
                size="xl"
                icon={faTrashAlt}
                style={{ color: "#000000" }}
              />
            </span>

            {/* <button className="check">Ok</button> */}
            {console.log("d", d)}
            {/* <Button
              variant="primary"
              className={
                d.status === null || d.status === "0"
                  ? "mx-0 btn-card"
                  : "d-none"
              }
              onClick={
                d.mode === 0
                  ? () => handleRecordOnline(d)
                  : () => handleRecordOffline(d)
              }
            >
              Record
            </Button> */}
            <div
              className={
                d.status === null ||
                d.status === constants.SESSION_STATUS.ATTENDANCE_NOT_RECORDED
                  ? "mx-0 btn-card"
                  : "d-none"
              }
            >
              <ButtonMedium
                btnText="Record"
                onHide={
                  d.mode === constants.SESSION_MODE.ONLINE_SESSION
                    ? () => handleRecordOnline(d)
                    : () => handleRecordOffline(d)
                }
              />
            </div>

            <div
              className={
                d.status === constants.SESSION_STATUS.ATTENDANCE_INITIATED
                  ? "mx-0 btn-card"
                  : "d-none"
              }
            >
              <ButtonMedium
                btnText="View Details"
                onHide={
                  d.mode === constants.SESSION_MODE.ONLINE_SESSION
                    ? () => handleViewDetailsOnline(d)
                    : () => handleViewDetailsOffline(d)
                }
              />
            </div>

            {/* <Button
              variant="primary"
              className={
                d.status === "2" || d.status === "1"
                  ? "mx-0 btn-card"
                  : "d-none"
              }
              onClick={
                d.mode === 0
                  ? () => handleViewDetailsOnline(d)
                  : () => handleViewDetailsOffline(d)
              }
            >
              View Details
            </Button> */}
          </div>
        </Card>
      ))}

{isLoaded && <Loading />}
    </>
  );
}
