import constants from "../../../constants";
import Profile from "../Home/LandingPage/Profile";
import StudentTestBodyPage from "./StudentTestBodyPage";
import AlertContext from "../../../context/AlertContext";
import React, { useContext, useEffect, useMemo } from "react";
import StudentTestQuestionPage from "./StudentTestQuestionPage";
import StudentTestVideoSection from "./StudentTestVideoSection";
import Loading from "../../Notification-Loading/Loading/Loading";
import { StudentTestPageContext } from "../../../context/StudentTestPageContext";
import StudentTestPageHooks from "../../../Hooks/StudentTestPage/StudentTestPageHooks";

export default function StudentTestContainer() {
  const Alert = useContext(AlertContext);
  const StudentTestPageCxt = useContext(StudentTestPageContext);

  const StudentTestPageHks = StudentTestPageHooks();

  /* change the questions view list and grid */

  const changeQuestionVisible = (visible) => {
    if (visible === "single") {
      if (Alert.questionContainerSingle === false) {
        Alert.updateQuestionContainerSingle(true);
      }
    } else if (visible === "multiple") {
      Alert.comingObj.visible = Alert.comingObj.previsited;

      if (Alert.questionContainerSingle === true) {
        Alert.updateQuestionContainerSingle(false);
      }
    }
  };

  useEffect(() => {
    if (
      StudentTestPageCxt.curObj !== null &&
      StudentTestPageCxt.curObj !== undefined &&
      Object.keys(StudentTestPageCxt.curObj).length > 0 &&
      StudentTestPageCxt.curObj.hasOwnProperty("review") &&
      StudentTestPageCxt.curObj.review === -1
    ) {
      StudentTestPageCxt.setShowReview(false);
    }

    if (
      StudentTestPageCxt.curObj !== null &&
      StudentTestPageCxt.curObj !== undefined &&
      Object.keys(StudentTestPageCxt.curObj).length > 0 &&
      StudentTestPageCxt.curObj.hasOwnProperty("review") &&
      StudentTestPageCxt.curObj.review === 0
    ) {
      StudentTestPageCxt.setShowReview(true);
    }
  }, [StudentTestPageCxt.curObj]);

  useEffect(() => {
    StudentTestPageCxt.setCurObj(Alert.queObj);
  }, [Alert.queObj, StudentTestPageCxt.videoQuestionAttempt]);

  /* changing currentScore score  */

  useEffect(() => {
    if (Alert.isAdmin) {
      Alert.setAnsStats(StudentTestPageCxt.ansStats);
      StudentTestPageCxt.setCurrentScore(Alert.totalLearnerScore);
      Alert.setBackupTestData(Alert.comingObj);
    }
  }, [Alert, StudentTestPageCxt]);

  useEffect(() => {
    if (Alert.isAdmin) {
      StudentTestPageCxt.setCurrentScore(Alert.totalLearnerScore);
    }
  }, [Alert, StudentTestPageCxt]);

  /* assign question for list view */

  useEffect(() => {
    if (
      Alert.hasOwnProperty("comingObj") &&
      Alert.comingObj.hasOwnProperty("sections") &&
      Alert.comingObj.sections?.length > 0 &&
      StudentTestPageCxt.sectionObj !== undefined &&
      StudentTestPageCxt.sectionObj.length > 0
    ) {
      StudentTestPageCxt.setSectionObj(Alert.comingObj.sections[0]);

      if (Alert.comingObj.sections?.length > 1) {
        StudentTestPageCxt.setNextSec(true);
      }
    }
  }, [Alert, StudentTestPageCxt]);

  useEffect(() => {
    let noOfQue = 0;

    if (
      Alert.mcqAndDesTestAndLpId.status === constants.PROGRESS_STATUS.EVALUATED
    ) {
      if (
        Alert.hasOwnProperty("backupTestDataq") &&
        Object.keys(Alert.comingObj).length !== 0
      ) {
        for (let i = 0; i < Alert.comingObj.sections?.length; i++) {
          noOfQue = noOfQue + Alert.comingObj.sections[i].questions.length;
        }

        if (Alert.backupTestDataq?.current.sections?.length === 1) {
          StudentTestPageCxt.setNextSec(false);
          StudentTestPageCxt.setPrevSec(false);
        }
      }
    }

    if (
      Alert.hasOwnProperty("comingObj") &&
      Alert.comingObj.hasOwnProperty("sections")
    ) {
      if (Alert.comingObj.sections?.length > 1) {
        StudentTestPageCxt.setNextSec(true);
      }

      StudentTestPageCxt.setSectionObj(Alert.comingObj.sections[0]);

      if (
        Alert.comingObj.sections?.length > 1 &&
        Alert.comingObj.sections[0].questions.length > 1
      ) {
        if (
          Alert.comingObj.sections?.length === Alert.secInd + 1 &&
          Alert.comingObj.sections[Alert.secInd].questions.length ===
            Alert.queInd + 1
        ) {
          StudentTestPageCxt.setNextButton(false);
          StudentTestPageCxt.setPrevButton(false);
        } else {
          StudentTestPageCxt.setNextButton(true);
          StudentTestPageCxt.setPrevButton(false);
        }
      }

      if (
        Alert.comingObj.sections?.length === 1 &&
        Alert.comingObj.sections[0].questions.length === 1
      ) {
        StudentTestPageCxt.setNextButton(false);
        StudentTestPageCxt.setPrevButton(false);
      }
    }
  }, [Alert.comingObj]);

  useEffect(() => {
    if (
      Alert.mcqAndDesTestAndLpId.status ===
        constants.PROGRESS_STATUS.UNLOCKED ||
      Alert.mcqAndDesTestAndLpId.status ===
        constants.PROGRESS_STATUS.REATTEMPT ||
      Alert.mcqAndDesTestAndLpId.status ===
        constants.PROGRESS_STATUS.QN_CONTINUE
    ) {
      StudentTestPageHks.questionUpdate(
        Alert.secInd,
        StudentTestPageCxt.sRender,
        Alert.comingObj
      );
    }
  }, [StudentTestPageCxt.mulRevch]);

  useEffect(() => {
    let sections;

    if (
      Alert.mcqAndDesTestAndLpId.status === constants.PROGRESS_STATUS.EVALUATED
    ) {
      if (StudentTestPageCxt.selSec === "all") {
        sections = Alert.comingObj.sections;
      } else {
        sections = Alert.comingObj?.sections?.filter(
          (sect, sindex) => sindex === StudentTestPageCxt.selSec
        );
      }

      StudentTestPageCxt.setFSections(JSON.parse(JSON.stringify(sections)));
    } else {
      if (StudentTestPageCxt.selSec === "all") {
        sections = Alert.comingObj?.sections?.filter(
          (sect, sindex) => sindex === 0
        );
      } else {
        sections = Alert.comingObj?.sections?.filter(
          (sect, sindex) => sindex === StudentTestPageCxt.selSec
        );
      }
    }

    StudentTestPageCxt.setFSections(sections);

    if (
      Alert.comingObj.sections?.length > 1 &&
      Alert.comingObj.sections?.length > StudentTestPageCxt.selSec + 1
    ) {
      StudentTestPageCxt.setNextSec(true);
      StudentTestPageCxt.setPrevSec(true);
    }

    if (Alert.comingObj.sections?.length === StudentTestPageCxt.selSec + 1) {
      if (Alert.comingObj.sections.length === 1) {
        StudentTestPageCxt.setNextSec(false);
        StudentTestPageCxt.setPrevSec(false);
      } else {
        StudentTestPageCxt.setNextSec(false);
        StudentTestPageCxt.setPrevSec(true);
      }
    }

    if (StudentTestPageCxt.selSec === 0) {
      if (Alert.comingObj.sections.length === 1) {
        StudentTestPageCxt.setNextSec(false);
        StudentTestPageCxt.setPrevSec(false);
      } else {
        StudentTestPageCxt.setNextSec(true);
        StudentTestPageCxt.setPrevSec(false);
      }
    }
  }, [StudentTestPageCxt.selSec, Alert.comingObj]);

  const setFilters = (e) => {
    let tempFilter;
    let tempFilterData;
    let name = e === undefined || e == null ? "null" : e.target.name;

    if (StudentTestPageCxt.viewFilters[name] === undefined) {
      tempFilter = StudentTestPageCxt.viewFilters;
      tempFilter = { ...StudentTestPageCxt.viewFilters, [name]: true };
      StudentTestPageCxt.setViewFilters(tempFilter);
    } else {
      tempFilter = StudentTestPageCxt.viewFilters;

      let value = StudentTestPageCxt.viewFilters[name];

      tempFilter = { ...StudentTestPageCxt.viewFilters, [name]: !value };
      StudentTestPageCxt.setViewFilters(tempFilter);
    }

    if (
      (tempFilter["tq"] === true || tempFilter["tq"] === false) &&
      tempFilter["qa"] === false &&
      tempFilter["na"] === false &&
      tempFilter["ca"] === false &&
      tempFilter["wa"] === false &&
      tempFilter["ea"] === false &&
      tempFilter["me"] === false &&
      tempFilter["ha"] === false
    ) {
      tempFilterData = JSON.stringify(Alert.backupTestData);
      tempFilterData = JSON.parse(tempFilterData);
      Alert.setMcqTestData(tempFilterData);
      Alert.updateComingObj(tempFilterData);
    } else {
      tempFilterData = JSON.stringify(Alert.backupTestData);
      tempFilterData = JSON.parse(tempFilterData);

      for (let i = 0; i < Alert.backupTestData.sections?.length; i++) {
        tempFilterData.sections[i].questions = [];

        if (
          StudentTestPageCxt.selSec === "all"
            ? true
            : i === StudentTestPageCxt.selSec
        ) {
          for (
            let j = 0;
            j < Alert.backupTestData.sections[i].questions.length;
            j++
          ) {
            if (tempFilter["qa"] === true) {
              if (
                Alert.backupTestData.sections[i].questions[j].answer !== "" &&
                Alert.backupTestData.sections[i].questions[j].answer != null
              ) {
                if (
                  !StudentTestPageHks.checkIfObjectExistsInArray(
                    Alert.backupTestData.sections[i].questions[j],
                    tempFilterData.sections[i].questions
                  )
                ) {
                  tempFilterData.sections[i].questions.push(
                    Alert.backupTestData.sections[i].questions[j]
                  );
                }
              }
            }

            if (tempFilter["na"] === true) {
              if (
                Alert.backupTestData.sections[i].questions[j].answer === "" ||
                Alert.backupTestData.sections[i].questions[j].answer == null
              ) {
                if (
                  !StudentTestPageHks.checkIfObjectExistsInArray(
                    Alert.backupTestData.sections[i].questions[j],
                    tempFilterData.sections[i].questions
                  )
                ) {
                  tempFilterData.sections[i].questions.push(
                    Alert.backupTestData.sections[i].questions[j]
                  );
                }
              }
            }

            if (tempFilter["ca"] === true) {
              if (
                Alert.backupTestData.sections[i].questions[j].actualscore ===
                Alert.backupTestData.sections[i].questions[j].targetscore
              ) {
                if (
                  !StudentTestPageHks.checkIfObjectExistsInArray(
                    Alert.backupTestData.sections[i].questions[j],
                    tempFilterData.sections[i].questions
                  )
                ) {
                  tempFilterData.sections[i].questions.push(
                    Alert.backupTestData.sections[i].questions[j]
                  );
                }
              }
            }

            if (tempFilter["wa"] === true) {
              if (
                Alert.backupTestData.sections[i].questions[j].actualscore !==
                Alert.backupTestData.sections[i].questions[j].targetscore
              ) {
                if (
                  !StudentTestPageHks.checkIfObjectExistsInArray(
                    Alert.backupTestData.sections[i].questions[j],
                    tempFilterData.sections[i].questions
                  )
                ) {
                  tempFilterData.sections[i].questions.push(
                    Alert.backupTestData.sections[i].questions[j]
                  );
                }
              }
            }

            if (tempFilter["ea"] === true) {
              if (
                Alert.backupTestData.sections[i].questions[j].complexityid ===
                constants.complexityid.EASY
              ) {
                if (
                  !StudentTestPageHks.checkIfObjectExistsInArray(
                    Alert.backupTestData.sections[i].questions[j],
                    tempFilterData.sections[i].questions
                  )
                ) {
                  tempFilterData.sections[i].questions.push(
                    Alert.backupTestData.sections[i].questions[j]
                  );
                }
              }
            }

            if (tempFilter["me"] === true) {
              if (
                Alert.backupTestData.sections[i].questions[j].complexityid ===
                constants.complexityid.MEDIUM
              ) {
                if (
                  !StudentTestPageHks.checkIfObjectExistsInArray(
                    Alert.backupTestData.sections[i].questions[j],
                    tempFilterData.sections[i].questions
                  )
                ) {
                  tempFilterData.sections[i].questions.push(
                    Alert.backupTestData.sections[i].questions[j]
                  );
                }
              }
            }

            if (tempFilter["ha"] === true) {
              if (
                Alert.backupTestData.sections[i].questions[j].complexityid ===
                constants.complexityid.HARD
              ) {
                if (
                  !StudentTestPageHks.checkIfObjectExistsInArray(
                    Alert.backupTestData.sections[i].questions[j],
                    tempFilterData.sections[i].questions
                  )
                ) {
                  tempFilterData.sections[i].questions.push(
                    Alert.backupTestData.sections[i].questions[j]
                  );
                }
              }
            }
          }
        }
      }
    }

    let tempTq = 0;

    for (let i = 0; i < tempFilterData.sections?.length; i++) {
      for (let j = 0; j < tempFilterData.sections[i].questions.length; j++) {
        tempTq++;
      }
    }

    Alert.setFilterQuestionCount(tempTq);
    Alert.setMcqTestData(tempFilterData);

    if (StudentTestPageCxt.selSec !== "all") {
      StudentTestPageCxt.setSectionObj(
        tempFilterData.sections[StudentTestPageCxt.selSec]
      );
    }

    StudentTestPageCxt.setFSections(tempFilterData.sections);
    Alert.updateComingObj(tempFilterData);
  };

  const handlesingleVdoQnSubmit = () => {
    StudentTestPageCxt.setSingleVdoSubmit(true);
  };

  const memoizedCommonTestPageQuestions = useMemo(
    () => (
      <StudentTestQuestionPage
        secInd={Alert.secInd}
        queInd={Alert.queInd}
        videoQn={Alert.videoQn}
        setFilters={setFilters}
        duration={Alert.duration}
        testMode={Alert.testMode}
        comingObj={Alert.comingObj}
        setQueObj={Alert.setQueObj}
        setSecInd={Alert.setSecInd}
        ids={Alert.mcqAndDesTestAndLpId}
        setShowTimer={Alert.setShowTimer}
        viewTest={StudentTestPageCxt.viewTest}
        updateComingObj={Alert.updateComingObj}
        isLearner={StudentTestPageCxt.isLearner}
        isTrainer={StudentTestPageCxt.isTrainer}
        setSelSec={StudentTestPageCxt.setSelSec}
        testAndLpId={Alert.mcqAndDesTestAndLpId}
        setAction={Alert.setAction}
        status={Alert.mcqAndDesTestAndLpId.status}
        setNextSec={StudentTestPageCxt.setNextSec}
        setPrevSec={StudentTestPageCxt.setPrevSec}
        viewFilters={StudentTestPageCxt.viewFilters}
        setHideSubmitVideoQn={Alert.setHideSubmitVideoQn}
        singleVdoSubmit={StudentTestPageCxt.singleVdoSubmit}
        setCurrentScore={StudentTestPageCxt.setCurrentScore}
        questionContainerSingle={Alert.questionContainerSingle}
        setshowtransition={StudentTestPageCxt.setshowtransition}
        setVdoQnTimelimit={StudentTestPageCxt.setVdoQnTimelimit}
        setSingleVdoSubmit={StudentTestPageCxt.setSingleVdoSubmit}
        setVideoQuestionAttempt={StudentTestPageCxt.setVideoQuestionAttempt}
      />
    ),
    [
      Alert.queObj,
      Alert.secObj,
      Alert.secInd,
      Alert.queInd,
      Alert.duration,
      Alert.comingObj,
      Alert.mcqAndDesTestAndLpId,
      Alert.setAction,
      StudentTestPageCxt.setSelSec,
      Alert.questionContainerSingle,
      StudentTestPageCxt.singleVdoSubmit,
    ]
  );

  return (
    <div className="Mcq-desc-div-wrapper">
      {!StudentTestPageCxt.videoQuestionAttempt ||
      Alert.isAdmin ||
      (StudentTestPageCxt.videoQuestionAttempt &&
        (Alert.mcqAndDesTestAndLpId.status ===
          constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
          Alert.mcqAndDesTestAndLpId.status ===
            constants.PROGRESS_STATUS.EVALUATED)) ? (
        <div className="Mcq-desc-div">
          <div className="Mcq-desc-mainpage-div">
            <StudentTestBodyPage
              {...Alert}
              setFilters={setFilters}
              selSec={StudentTestPageCxt.selSec}
              curObj={StudentTestPageCxt.curObj}
              prevSec={StudentTestPageCxt.prevSec}
              nextSec={StudentTestPageCxt.nextSec}
              viewTest={StudentTestPageCxt.viewTest}
              secIndex={StudentTestPageCxt.secIndex}
              calcStats={StudentTestPageHks.calcStats}
              isLearner={StudentTestPageCxt.isLearner}
              isTrainer={StudentTestPageCxt.isTrainer}
              setSelSec={StudentTestPageCxt.setSelSec}
              isStudent={StudentTestPageCxt.isStudent}
              fSections={StudentTestPageCxt.fSections}
              setNextSec={StudentTestPageCxt.setNextSec}
              setPrevSec={StudentTestPageCxt.setPrevSec}
              sectionObj={StudentTestPageCxt.sectionObj}
              setSecIndex={StudentTestPageCxt.setSecIndex}
              changeQuestionVisible={changeQuestionVisible}
              currentScore={StudentTestPageCxt.currentScore}
              setFSections={StudentTestPageCxt.setFSections}
              setSectionObj={StudentTestPageCxt.setSectionObj}
              setShowReview={StudentTestPageCxt.setShowReview}
              vdoQnTimelimit={StudentTestPageCxt.vdoQnTimelimit}
              setCurrentScore={StudentTestPageCxt.setCurrentScore}
              setVdoQnTimelimit={StudentTestPageCxt.setVdoQnTimelimit}
              // video qn
              videoQn={Alert.videoQn}
              showTimer={Alert.showTimer}
              setShowTimer={Alert.setShowTimer}
              status={Alert.mcqAndDesTestAndLpId.status}
              setBackupTestData={Alert.setBackupTestData}
              handlesingleVdoQnSubmit={handlesingleVdoQnSubmit}
              showtransition={StudentTestPageCxt.showtransition}
              setshowtransition={StudentTestPageCxt.setshowtransition}
            />
          </div>
          <div className="Mcq-desc-question-page-div">
            {memoizedCommonTestPageQuestions}
          </div>
        </div>
      ) : (
        !Alert.isAdmin && (
          <>
            <StudentTestVideoSection
              showTimer={Alert.showTimer}
              setShowTimer={Alert.setShowTimer}
              setHideSubmitVideoQn={Alert.setHideSubmitVideoQn}
              showtransition={StudentTestPageCxt.showtransition}
              setshowtransition={StudentTestPageCxt.setshowtransition}
              setVdoQnTimelimit={StudentTestPageCxt.setVdoQnTimelimit}
              setVideoQuestionAttempt={
                StudentTestPageCxt.setVideoQuestionAttempt
              }
            />
          </>
        )
      )}

      {Alert.isTakeTest && Alert.comingObj.videoqn !== 1 && (
        <Profile
          isTakeTest={Alert.isTakeTest}
          questionid={Alert.comingObj.testid}
          setCameraPopup={StudentTestPageCxt.setCameraPopup}
          cameraWarningMsg={StudentTestPageCxt.cameraWarningMsg}
          setCameraWarningMsg={StudentTestPageCxt.setCameraWarningMsg}
          libtypeid={
            [
              constants.libTypeIds.LAQ,
              constants.libTypeIds.SAQ,
              constants.libTypeIds.DESCRIPTIVE,
            ].includes(Alert.comingObj.sections[0].qnconfig[0].libtypeid)
              ? constants.libTypeIds.DESCRIPTIVE
              : constants.libTypeIds.MCQ
          }
        />
      )}
      {/* {Alert.isTakeTest && (
              <ZoomStudentView
                testname={Alert.comingObj.testname}
                learnerid={getDataFromStorage("learnerid")}
                zoomdata={JSON.stringify({
                  // testtype: testtypeid,
                  lpid: Alert.lpId,
                  testid: Alert.comingObj.testid,
                  lpconfigid: Alert.mcqAndDesTestAndLpId.lpconfigid,
                  learnerid: parseInt(getDataFromStorage("learnerid")),
                })}
              />
            )} */}
      {Alert.isLoaded && <Loading />}
    </div>
  );
}
