import React, { useContext, useEffect, useRef, useState } from 'react';
import Avatar from "@mui/material/Avatar";
import GlopeSvg from '../../../../Svg/GlopeSvg';
import Progressbar from '../../../CommonComponents/Progressbar';
import GrammerSvg from '../../../../Svg/GrammerSvg';
import Coding_R_Svg from '../../../../Svg/Coding_R_Svg';
import VideoSvg from '../../../../Svg/VideoSvg';
import QuotesSvg from '../../../../Svg/QuotesSvg';
import PhoneSvg from '../../../../Svg/Resume/PhoneSvg';
import Mail from '../../../../Svg/Resume/Mail';
import Linkedin from '../../../../Svg/Resume/Linkedin';
import Git from '../../../../Svg/Resume/Git';
import Github from '../../../../Svg/Resume/Github';
import Leetcode from '../../../../Svg/Resume/Leetcode';
import Codechef from '../../../../Svg/Resume/Codechef';
import Hackerrank from '../../../../Svg/Resume/Hackerrank';
import Hackerearth from '../../../../Svg/Resume/Hackerearth';
import Exclude from '../../../../Svg/Resume/Exclude';
import constants from '../../../../constants';
import AlertContext from '../../../../context/AlertContext';
import Loading from '../../../Notification-Loading/Loading/Loading';
import ResumeHooks from './ResumeHooks';
import DisableVideo from '../../../../Svg/Resume/DisableVideo';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DownloadSvgIcon from "../../../../Svg/DownloadSvgIcon";
import IconButton from '../../../CommonComponents/IconButton';
import TestSvgIcon from '../../../../Svg/TestSvgIcon';
import Link from '../../../../Svg/Resume/Link';
import PDFDoc from './PDFDoc';
import {PDFDownloadLink } from '@react-pdf/renderer';
import { getDataFromStorage } from '../../../../util';

export default function Resume(props) {
    const { isLoaded } = useContext(AlertContext);
    const {resumeData, setResumeData, getResumeData, getlistFromParagraph, getYear, getSkillPercentage, formatPercentage, handleProject, getTodayDate, getValue, handleVideoClick, addSpaceToParagraph} = ResumeHooks();

    useEffect(()=>{
        if(resumeData){
            setResumeData();
            getResumeData(props.from,props.learnerId, props.role);
        } else {
            getResumeData(props.from,props.learnerId, props.role);
        }
    },[props.learnerId]);

    let PersonalDetail = resumeData && typeof resumeData?.personalData?.personalinfo == "string" ? JSON.parse(resumeData?.personalData?.personalinfo) : resumeData?.personalData?.personalinfo;
    let socialprofile = resumeData && typeof resumeData?.personalData?.socialprofile == "string" ? JSON.parse(resumeData?.personalData?.socialprofile) : resumeData?.personalData?.socialprofile;
    let academic = resumeData && typeof resumeData?.personalData?.academic == "string" ? JSON.parse(resumeData?.personalData?.academic) : resumeData?.personalData?.academic;
    let educationDetails = resumeData && typeof resumeData?.personalData?.education == "string"? JSON.parse(resumeData?.personalData?.education) :resumeData?.personalData?.education;

    const order = [
        "pgDetail",
        "ugDetail",
        "class12Detail",
        "class10Detail",
    ];
    const sortedEntries =  resumeData?.personalData?.education && order
                            .filter(key => educationDetails && educationDetails[key]) // Check that educationDetails exists and has the key
                            .map(key => [key, educationDetails[key]]);

//-- checking with footer
const handleExportToPDF = () => {
    const doc = new jsPDF("p", "pt", "a4");
    const marginTop = 15; // Top margin
    const marginBottom = 20; // Bottom margin for content
    const marginLeft = 20; // Left margin
    const marginRight = 20; // Right margin
    const footerHeight = 20; // Default footer height (not stretched)
    const pageWidth = doc.internal.pageSize.getWidth() - marginLeft - marginRight;
    const pageHeight = doc.internal.pageSize.getHeight() - marginTop - marginBottom - footerHeight; // Adjusting for footer

    const fileName = `resume_${PersonalDetail.firstName}.pdf`;

    const table = document.querySelector(".resume");
    const footerDiv = document.querySelector(".footer"); // Footer div

    html2canvas(footerDiv, { scale: 2, useCORS: true }).then((footerCanvas) => {
        const footerImgWidth = footerCanvas.width;
        const footerImgHeight = footerCanvas.height;

        // Scale the footer to fit the page width while maintaining aspect ratio
        const footerScale = pageWidth / footerImgWidth;
        const scaledFooterHeight = footerImgHeight * footerScale;

        const footerImgData = footerCanvas.toDataURL("image/png", 1.0);

        html2canvas(table, { scale: 2, useCORS: true }).then((canvas) => {
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;

            const scale = pageWidth / imgWidth;
            const scaledWidth = imgWidth * scale;
            let scaledHeight = imgHeight * scale;

            let heightLeft = scaledHeight;
            let position = 0;

            const imgData = canvas.toDataURL("image/png", 1.0);

            while (heightLeft > 0) {
                const pageCanvas = document.createElement("canvas");
                pageCanvas.width = imgWidth;
                pageCanvas.height = Math.min(imgHeight - position, pageHeight / scale);

                const ctx = pageCanvas.getContext("2d");

                ctx.drawImage(
                    canvas,
                    0,
                    position / scale,
                    imgWidth,
                    pageCanvas.height,
                    0,
                    0,
                    imgWidth,
                    pageCanvas.height
                );

                const pageData = pageCanvas.toDataURL("image/png", 1.0);
                doc.addImage(pageData, "PNG", marginLeft, marginTop, scaledWidth, pageCanvas.height * scale);

                // Adding footer at the bottom of the page without stretching
                doc.addImage(footerImgData, "PNG", marginLeft, doc.internal.pageSize.getHeight() - scaledFooterHeight - marginBottom, pageWidth, scaledFooterHeight);

                heightLeft -= pageHeight;
                position += pageHeight;

                if (heightLeft > 0) {
                    doc.addPage();
                }
            }

            doc.save(fileName);
        });
    });
};

  return (
    <>
    {(!isLoaded && resumeData != undefined && resumeData) ? 
    (<div className="resume_withbtn">
        <div className="download_resume">
        { (getDataFromStorage("role") !== "learner" && getDataFromStorage("role") !== "hr") && <PDFDownloadLink document={
            <PDFDoc 
                PersonalDetail={PersonalDetail}
                resumeData={resumeData} 
                socialprofile={socialprofile}
                academic={academic}
                sortedEntries={sortedEntries}
                from={props.from}
                learnerId={props.learnerId} 
                role={props.role}
            />} fileName={(`Resume_${PersonalDetail?.firstName}.pdf`)}>
            {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' :<DownloadSvgIcon/>
            }
        </PDFDownloadLink>}
        {/* <IconButton children={<DownloadSvgIcon onClick={() => handleExportToPDF()}  />}  hierarchy={"tertiaryColor"}/> */}
        </div>
        <div className='resume' /* ref={targetRef} */>
        <div className='resume_body'> 
            {/* <div className='resume_date'>{getTodayDate()}</div> */}
            <div className='header'>
                <div className='personal_detail'>
                    <div className='name_role'>
                        {PersonalDetail && <div className='personal_detail_name'><span className='name'>{`${PersonalDetail?.firstName} ${(PersonalDetail?.lastName!= undefined && PersonalDetail?.lastName!= null && PersonalDetail?.lastName!= "") ? PersonalDetail?.lastName : ""}`}</span> { PersonalDetail?.intro && <span className='profile_intro' onClick={()=>handleVideoClick(PersonalDetail?.intro)}><VideoSvg/></span> /* : <span><DisableVideo/></span> */}</div>}
                        {resumeData?.roleTitle && <span className='role'>{resumeData?.roleTitle}</span>}
                    </div>
                    <div className='social_details'>
                        {PersonalDetail && <a className='mobile' 
                         href={`https://wa.me/${PersonalDetail?.phone}`}
                         target="_blank"
                         rel="noopener noreferrer"
                         style={{ textDecoration: "none" }} >
                            <PhoneSvg/> {PersonalDetail?.phone}
                        </a>}
                        {PersonalDetail && <a className='email'
                         href={`mailto:${PersonalDetail?.email}`}
                         style={{ textDecoration: "none"}} >
                            <Mail/> {PersonalDetail?.email}
                        </a>}
                        {socialprofile && (socialprofile["linkedIn"] != undefined && socialprofile["linkedIn"] != null && socialprofile["linkedIn"] != "") && <span onClick={() => handleProject(socialprofile["linkedIn"])} className='linkedin'><Linkedin/></span>}
                        {socialprofile && (socialprofile["git"] != undefined && socialprofile["git"] != null && socialprofile["git"] != "") && <span onClick={() => handleProject(socialprofile["git"])} className='social_links git'><Git/></span>}
                        {socialprofile && (socialprofile["github"] != undefined && socialprofile["github"] != null && socialprofile["github"] != "") && <span onClick={() => handleProject(socialprofile["github"])} className='social_links'><Github/></span>}
                        {socialprofile && (socialprofile["leetCode"] != undefined && socialprofile["leetCode"] != null && socialprofile["leetCode"] != "") && <span onClick={() => handleProject(socialprofile["leetCode"])} className='social_links'><Leetcode/></span>}
                        {socialprofile && (socialprofile["codeChef"] != undefined && socialprofile["codeChef"] != null && socialprofile["codeChef"] != "") && <span onClick={() => handleProject(socialprofile["codeChef"])} className='social_links'><Codechef/></span>}
                        {socialprofile && (socialprofile["hackerRank"] != undefined && socialprofile["hackerRank"] != null && socialprofile["hackerRank"] != "") && <span onClick={() => handleProject(socialprofile["hackerRank"])} className='social_links'><Hackerrank/></span>}
                        {socialprofile && (socialprofile["hackerEarth"] != undefined && socialprofile["hackerEarth"] != null && socialprofile["hackerEarth"] != "") && <span onClick={() => handleProject(socialprofile["hackerEarth"])} className='social_links'><Hackerearth/></span>}
                    </div>
                </div>
                <div className='profile'>
                    <Avatar
                        alt={PersonalDetail?.firstName}
                        src={resumeData?.personalData?.picture}
                        style={{ width: "92px", height: "92px", padding: 0 }}
                    />
                </div>
            </div>
            <div className='body'>
                <div className='body_left_pane'>
                    {PersonalDetail && (PersonalDetail?.careerObj !== "" && PersonalDetail?.careerObj !== null && PersonalDetail?.careerObj !== undefined)  && <div className='left_pane_section_carrer'>
                        <div className='left_pane_title'>CAREER OBJECTIVE</div>
                        <div className='carrer_objective'>{PersonalDetail?.careerObj}</div>
                    </div>}
                    <div className='left_pane_section_education'>
                    {sortedEntries?.length > 0 && <> 
                            <div className='left_pane_title'>EDUCATION</div>
                            {sortedEntries.map(([key, data]) => (
                                data && Object.keys(data).length > 0 && (
                                    <div key={key} className='education_details'>
                                    <div className='institutions'>
                                        <div className='institution_name'>{data.schoolName}</div>
                                        <div className='institution_course'>{data.branch/* `${"B.Tech"} (${"CSE"} - ${"AI & ML"})` */}</div>
                                    </div>
                                    <div className='year_score'>
                                        <div className='year'>{`${data.startdate ? getYear(data.startdate,"year") : ""} - ${data.enddate ? getYear(data.enddate, "year") : ""}`}</div>
                                        <div className='score'>{`CGPA : ${data.cgpa}`}</div>
                                    </div>
                                </div>
                                )))}
                        </>}
                    </div>
                    <div className='left_pane_section_projects'>
                    {academic
                    ?.filter(data => data.id === 1)
                    .map(data => {
                        if (data.details && data.details.length > 0) {
                        return (
                            <div key={data.id}>
                            <div className='left_pane_title'>PROJECTS</div>
                            {data.details.map(detail => (
                                <div className='projects' key={detail.id}>
                                <div className='project_name'><div>{detail.title} { detail.projectLink && <span onClick={()=>handleProject(detail.projectLink)}  /* onClick={()=>handleVideoClick(detail.projectLink)} */><VideoSvg/></span> /* : <span><DisableVideo/></span> */} {detail.githubLink && <span onClick={() => handleProject(detail.githubLink)}><TestSvgIcon libType={5} disabled = {true}/></span>} {detail.hostLink && <span onClick={() => handleProject(detail.hostLink)}><Link /></span>}</div>{detail?.startddate && <div className='project_duration'>{getYear(detail.startddate, "year")}</div>}</div>
                                {detail.technologies && <div className='project_tags'>{detail.technologies.map(tech=>(<span>{`${tech}`}</span>))}</div>}
                                <div className='project_detail'>
                                    {addSpaceToParagraph(detail.explaination,detail.contribution,detail.learn)}
                                </div>
                                {/* <span className='project_detail_view' onClick={() => handleProject(detail.githubLink)}>View code</span> */}
                                </div>
                            ))}
                            </div>
                        );
                        }
                        return null; // Return null if details are empty
                    })}
                    </div>
                    <div className='left_pane_section_internship'>
                    {academic
                    ?.filter(data => data.id === 4)
                    .map(data => {
                        if (data.details && data.details.length > 0) {
                            return (
                                <div key={data.id}>
                                <div className='left_pane_title'>INTERNSHIPS</div>
                                    {data.details.map(detail => (<>
                                    <div className='internship_details' key={detail.id}>
                                        <div className='internship_institutions'>
                                            <div className='internship_name'>{detail.title}</div>
                                            <div className='internship_course'>{`${detail.organisation}`}</div>
                                        </div>
                                        <div className='internship_month'>
                                            <div className='month'>{`${data.startdate?getYear(data.startdate,"month"):""} - ${data.startdate?getYear(data.startdate,"month"):""}`}</div>
                                            <div className='city'>{detail.city}</div>
                                        </div>
                                    </div>
                                    <div className='internship_description'>{detail.description}</div>
                                    </>))}
                                </div>
                             );
                         }
                         return null;
                    })}
                    </div>
                    <div className='left_pane_section_certificates'>
                    {academic
                    ?.filter(data => data.id === 2)
                    .map(data => {
                        if (data.details && data.details.length > 0) {
                            return (
                                <div key={data.id}>
                                    <div className='left_pane_title'>CERTIFICATES</div>
                                    {data.details.map(detail => (<>
                                    <div className='certificate_details' key={detail.id}>
                                        <div className='certificates'>
                                            <div className='certificate_name'>{detail.title}</div>
                                            <div className='certificate_institute'>{detail.organisation}</div>
                                        </div>
                                        <div className='certificate_year'>
                                            <div className='year'>{detail.year}</div>
                                        </div>
                                    </div>
                                    </>))}
                                </div>
                                );
                        }
                        return null;
                    })}
                    </div>

                    <div className='left_pane_section_certificates'>
                    {academic
                    ?.filter(data => data.id === 3)
                    .map(data => {
                        if (data.details && data.details.length > 0) {
                            return (
                                <div key={data.id}>
                                    <div className='left_pane_title'>ACHIEVEMENTS</div>
                                    {data.details.map(detail => (<>
                                    <div className='achivements_details' key={detail.id}>
                                        <div className='achivements'>
                                            <li className='achivements_name'>{detail.title}</li>
                                        </div>
                                    </div>
                                    </>))}
                                </div>
                                );
                        }
                        return null;
                    })}
                    </div>
                    
                </div>
                <div className='body_right_pane'>
                    <div className='right_pane_section'>
                        {resumeData?.GCAT && Object.keys(typeof resumeData?.GCAT == "string"? JSON.parse(resumeData?.GCAT) : resumeData?.GCAT).length>0 && <>
                        <div className='right_pane_title'>GRADIOUS EVALUATION</div>
                        <div className='wrap_geval_box'>
                        {Object.entries(typeof resumeData?.GCAT == "string"? JSON.parse(resumeData?.GCAT) : resumeData?.GCAT).map(([skill,data])=>( <div className='geval_box'>
                            <div className='geval_header'>
                                <div className='header_title'><span>{skill == constants.PROFILE_ATTRIBUTES.APTITUDE_SCORE ? <GlopeSvg/> : skill == constants.PROFILE_ATTRIBUTES.GRAMMAR_SCORE ? <GrammerSvg/> : skill == constants.PROFILE_ATTRIBUTES.CODING_SCORE ? <Coding_R_Svg/> : ""}</span>{skill == constants.PROFILE_ATTRIBUTES.APTITUDE_SCORE ? "Aptitude" : skill == constants.PROFILE_ATTRIBUTES.GRAMMAR_SCORE ? "Grammar" : skill == constants.PROFILE_ATTRIBUTES.CODING_SCORE ? "Coding" : ""}</div>
                            </div>
                            {/* <div className='geval_body'><span>{`${data.overrideValue != null && data.overrideValue != "" && data.overrideValue != undefined ? data.overrideValue : data.value != null && data.value != undefined && data.value != "" ? data.value :0}%`}</span></div> */}
                            <div className='geval_body'><span>{`${(typeof data.overrideValue === "string" ? data.overrideValue.trim() == "" ? 0 : data.overrideValue.trim() : data.overrideValue) ||
                                      (typeof data.value === "string" ? data.value.trim() =="" ? 0 : data.value.trim() : data.value) ||
                                      0}%`}</span></div>
                            <div className='geval_footer'><Progressbar from={"resume"} percentage={(typeof data.overrideValue === "string" ? data.overrideValue.trim() == "" ? 0 : data.overrideValue.trim() : data.overrideValue) ||
                                      (typeof data.value === "string" ? data.value.trim() =="" ? 0 : data.value.trim() : data.value) ||
                                      0}/></div>
                        </div>))}
                        </div> 
                        </> }
                    </div>
                    <div className='skill_section'>
                       {resumeData?.skills && Object.keys(resumeData?.skills).length>0 &&  <>
                        <div className='skill_section_title'>SKILL LEARNED</div>
                       
                        <div className='skill_box'>
                        {Object.entries(resumeData?.skills).map(([skillid,skillData], index, arr)=>{
                            let hasRelevantData = skillData.attributes[constants.PROFILE_ATTRIBUTES.ASSIGNMENT_SCORE] || skillData.attributes[constants.PROFILE_ATTRIBUTES.ASSESSMENT_SCORE];
                            if (hasRelevantData) {
                                return (<>
                                    <div className='skill_header'>
                                        <div className='skill_name'>{skillData.skillname} { skillData.videoLink && <span onClick={()=>handleVideoClick(skillData.videoLink)}><VideoSvg/></span> /* : <span><DisableVideo/></span> */}</div>
                                        <span className='skill_percentage'>{`${formatPercentage(getSkillPercentage(skillData.attributes))}%`}</span>
                                    </div>
                                    <div className='skill_test_details'>
                                        <li className='skill_tests'>{`Submitted ${getValue(skillData.attributes,constants.PROFILE_ATTRIBUTES.ASSIGNMENT_COMPLETED) +'/'+ getValue(skillData.attributes,constants.PROFILE_ATTRIBUTES.ASSIGNMENT_COMPLETED,"totalCount")} assignments & Scored ${getValue(skillData.attributes,constants.PROFILE_ATTRIBUTES.ASSIGNMENT_SCORE) +'/'+ getValue(skillData.attributes,constants.PROFILE_ATTRIBUTES.ASSIGNMENT_SCORE,"targetScore")}.`}</li>
                                        {/* <li className='skill_tests'>{`Scored perfectly in ${getValue(skillData,constants.PROFILE_ATTRIBUTES.ASSIGNMENT_SCORE) +'/'+ getValue(skillData,constants.PROFILE_ATTRIBUTES.ASSIGNMENT_SCORE)} assignments`}</li> */}
                                        <li className='skill_tests'>{`Score ${getValue(skillData.attributes,constants.PROFILE_ATTRIBUTES.ASSESSMENT_SCORE) +'/'+ getValue(skillData.attributes,constants.PROFILE_ATTRIBUTES.ASSESSMENT_SCORE, "targetScore")} in all assessments combined.`}</li>
                                    </div>
                                    <div className='skill_remark'>
                                        {/* <div className='remark_title'>Remark by trainer</div> */}
                                        {skillData.attributes[constants.PROFILE_ATTRIBUTES.SKILL_FEEDBACK] &&(getlistFromParagraph(skillData.attributes[constants.PROFILE_ATTRIBUTES.SKILL_FEEDBACK]).map((data,index)=>( <div className='remark'><li key={index}>{data}</li></div>)))}
                                    </div>
                                    {index !== arr.length-1 && <div className='skill_endline'></div>}
                                </>
                                )
                            }
                            return null;
                        })}
                        </div>
                        </>}
                    </div>
                    {(resumeData?.overallFeedback !== "" && resumeData?.overallFeedback !== null && resumeData?.overallFeedback !== undefined) && <div className='overall_remark'>
                        <div className='quotes'><QuotesSvg/></div>
                        <div className='overall_remark_title'>Remark</div>
                        {getlistFromParagraph(resumeData?.overallFeedback).map((data,index)=>(<li key={index} className='overall_remark_points'>{data.trim()}</li>))}
                    </div>}
                </div>
            </div>
            
        </div>
        <div className='exclude_svg'><Exclude/></div>
    </div>
    <div className='footer'>
                <span>Copyright Gradious Technologies (P) Ltd - 2024</span>
                {/* <span>1</span> */}
                {/* <span>Student ID : {resumeData && resumeData?.personalData?.userid}</span> */}
                <span>Download Date : {getTodayDate()}</span>
            </div>
    </div>) : props.forLoad ? <Loading /> : <></>
    }
    </>
  )
}
